import {
  Box,
  Input,
  InputGroup,
  InputRightElement,
  useColorMode,
} from "@chakra-ui/react";
import { css } from "@emotion/react";
import { isoStringToDate } from "@intentsify/utils";
import { DateTime } from "luxon";
import React from "react";
import {
  ActiveModifiers,
  DayPicker as ReactDayPicker,
  UseInputOptions,
  useInput,
} from "react-day-picker";
import "react-day-picker/dist/style.css";
import { FiCalendar } from "react-icons/fi";
import { Popover } from "../../Popover";
import { getDatePickerCssModifiers } from "../DatePicker.utils";
import "../date-picker.css";
import { OnChangeDay } from "./DayPicker.types";

const options: UseInputOptions = {
  fromYear: 2021,
  toYear: 2025,
  format: "PP",
  required: true,
};

type DayPickerProps = {
  initialValue?: string;
  onChange: OnChangeDay;
  isDisabled?: boolean;
};

const DayPicker = ({ initialValue, onChange, isDisabled }: DayPickerProps) => {
  const { inputProps, dayPickerProps } = useInput({
    ...options,
    defaultSelected: initialValue ? isoStringToDate(initialValue) : undefined,
  });
  const { onDayClick } = dayPickerProps;
  const { colorMode } = useColorMode();

  return (
    <Box
      css={css`
        ${getDatePickerCssModifiers(colorMode)}
      `}
    >
      <Popover
        isDisabled={isDisabled}
        width="20rem"
        popoverTrigger={
          <InputGroup size="sm" alignItems={"center"}>
            <Input
              {...inputProps}
              shadow="sm"
              w="full"
              rounded="md"
              isReadOnly
              cursor={"pointer"}
              isDisabled={isDisabled}
              placeholder={""}
            />
            <InputRightElement pointerEvents="none" alignContent={"center"}>
              <FiCalendar />
            </InputRightElement>
          </InputGroup>
        }
        popoverBody={
          <ReactDayPicker
            {...dayPickerProps}
            onDayClick={(
              day: Date,
              activeModifiers: ActiveModifiers,
              e: React.MouseEvent
            ) => {
              const formatted = DateTime.fromJSDate(new Date(day)).toISODate();
              onChange(formatted);
              onDayClick?.(day, activeModifiers, e);
            }}
          />
        }
      />
    </Box>
  );
};

export { DayPicker };
