import { YourCampaignsScreen } from "types";
import { createScreen } from "utils/createScreen";

const YourCampaignsScreenDefinition = createScreen({
  name: YourCampaignsScreen,
  path: "/intent-models",
  routePath: ["/"],
  accessPath: "campaign",
});

export { YourCampaignsScreenDefinition };
