import { useDisclosure, useToast } from "@chakra-ui/react";
import { FiEdit3, FiTrash2 } from "react-icons/fi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { EditBusinessEntityScreenDefinition } from "../EditBusinessEntity";
import { removeBusinessEntity } from "./TableActions.requests";
import { ActionItems } from "types";
import { Actions, Button, Modal } from "components";

interface TableActionsProps {
  id: number;
}

const TableActions = ({ id }: TableActionsProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const toast = useToast();

  const { mutate, isLoading } = useMutation(removeBusinessEntity, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["listPaginatedBusinessEntities"],
      });
      toast({
        title: "Business entity removed successfully.",
        status: "success",
      });
      onClose();
    },
  });

  const handleRemove = () => {
    mutate(id);
  };

  const items: ActionItems[] = [
    {
      label: EditBusinessEntityScreenDefinition.name,
      accessPath: "businessEntities",
      icon: <FiEdit3 />,
      onClick: () =>
        navigate(
          EditBusinessEntityScreenDefinition.navigate({ id: Number(id) })
        ),
    },
    {
      label: "Delete",
      accessPath: "businessEntities",
      icon: <FiTrash2 />,
      onClick: onOpen,
    },
  ];

  return (
    <>
      <Actions items={items} />
      <Modal
        title={"Delete"}
        body={"Are you sure you want to delete this business entity?"}
        secondaryButton={
          <Button aria-label="Cancel" onClick={onClose}>
            Cancel
          </Button>
        }
        primaryButton={
          <Button
            aria-label="Delete"
            variant="warning"
            leftIcon={<FiTrash2 />}
            onClick={handleRemove}
            isLoading={isLoading}
          >
            Delete
          </Button>
        }
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
};

export { TableActions };
