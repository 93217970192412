import { CheckCircleIcon, QuestionIcon, WarningIcon } from "@chakra-ui/icons";
import {
  Box,
  Stack,
  Tag,
  TagLabel,
  TagLeftIcon,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { AutodiscoveryUrl } from "@intentsify/types";
import { HttpStatus, Nullable } from "types";
import { getFileNameFromUrl } from "../../AutodiscoveryFiles/AutodiscoveryFiles.utils";

type UrlsResultsProps = {
  urls: AutodiscoveryUrl[];
  files: AutodiscoveryUrl[];
};

const UrlsResults = ({ urls, files }: UrlsResultsProps) => {
  const renderTooltip = (
    label: string,
    status: Nullable<number>,
    index: number,
    isFile = false
  ) => {
    const processedSubject = isFile ? "file" : "url";
    return (
      <Tooltip
        key={`${label}_${index}`}
        label={
          status !== null
            ? status === HttpStatus.OK
              ? `The ${processedSubject} was successfully processed.`
              : `The ${processedSubject} was not processed correctly. Status: ${
                  status ? status : "Unknown Error"
                }`
            : `The processing status of the ${processedSubject} is unknown.`
        }
      >
        <Tag m={1}>
          <TagLeftIcon
            as={
              status !== null
                ? status === HttpStatus.OK
                  ? CheckCircleIcon
                  : WarningIcon
                : QuestionIcon
            }
            color={
              status !== null
                ? status === HttpStatus.OK
                  ? "green.200"
                  : "red.200"
                : ""
            }
          />
          <TagLabel>{label}</TagLabel>
        </Tag>
      </Tooltip>
    );
  };

  return (
    <Stack mb={3}>
      <Text fontSize="md" fontWeight="semibold">
        Processed marketing materials and URLs
      </Text>
      <Box borderBottomRadius={4}>
        <>
          {urls?.map(({ url, status }, index) =>
            renderTooltip(url, status, index)
          )}
          {files?.map(({ url, status }, index) =>
            renderTooltip(getFileNameFromUrl(url) || "", status, index, true)
          )}
        </>
      </Box>
    </Stack>
  );
};

export { UrlsResults };
