import { TalColumnTemplate } from "@intentsify/utils";
import { Endpoints } from "api";
import { ReactNode } from "react";
import { CsvUploadTus, CsvUploadTusProps } from "../CsvUploadTus/CsvUploadTus";

export const DomainsUploadTus = (
  props: Omit<CsvUploadTusProps, "validationEndpoint" | "columnsTemplate"> & {
    dropzoneSubHeader?: string | ReactNode;
  }
) => {
  const {
    dropzoneSubHeader = `
      Please upload a CSV file of up to 200MB in size, containing a "Domain" column.
    `,
  } = props;

  return (
    <CsvUploadTus
      {...props}
      validationEndpoint={Endpoints.AccountsUpload.All.Validate}
      columnsTemplate={TalColumnTemplate}
      dropzoneSubHeader={dropzoneSubHeader}
    />
  );
};
