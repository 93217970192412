import { WeeklySnapshotDTO, PingContentRO } from "@intentsify/dto";
import { apiService, Endpoints } from "api";

export const getPingContent = async (
  campaignId: number,
  filters: WeeklySnapshotDTO
) => {
  const { data } = await apiService.get<PingContentRO[]>(
    Endpoints.Campaigns.Get.WeeklySnapshot.PingContent(campaignId),
    {
      params: {
        yearTo: filters.yearTo,
        weekTo: filters.weekTo,
      },
    }
  );

  return data;
};
