import { Tabs as ChakraTabs, Tab, TabList, TabsProps } from "@chakra-ui/react";
import { useState } from "react";

type TabsPropsWithoutChildren = Omit<TabsProps, "children">;

export enum ResultsTableTab {
  Topics = "Topics",
  Keywords = "Keywords",
  Products = "Products",
}

const resultsTableTabValues = Object.values(ResultsTableTab);

interface TabsInnerProps extends TabsPropsWithoutChildren {
  categoryIndex: number;
  setCategoryIndex: (index: number) => void;
}

const TabsInner = ({
  categoryIndex,
  setCategoryIndex,
  ...props
}: TabsInnerProps) => (
  <ChakraTabs {...props} index={categoryIndex} onChange={setCategoryIndex}>
    <TabList w="100%" mt="0">
      {resultsTableTabValues.map((tab) => (
        <Tab key={tab}>{tab}</Tab>
      ))}
    </TabList>
  </ChakraTabs>
);

export const useTabs = () => {
  const [categoryIndex, setCategoryIndex] = useState(0);
  const selectedTab = resultsTableTabValues[categoryIndex];
  const Tabs = (props: TabsPropsWithoutChildren) => (
    <TabsInner
      {...props}
      categoryIndex={categoryIndex}
      setCategoryIndex={setCategoryIndex}
    />
  );

  return [Tabs, selectedTab] as const;
};
