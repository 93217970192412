import {
  chakra,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  Input,
  SimpleGrid,
  useToast,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { CompanyForGet } from "@intentsify/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button } from "components";
// import { useConfirm } from "components/ConfirmDialog/useConfirm";
import { useInvalidateCompany } from "queries/companies/useCompanyQueryOptions";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useComponentColors } from "theme";
import { DataPackageSelector } from "../../components/DataPackageSelector";
import { CompaniesScreenDefinition } from "../Companies/Companies.definition";
import { editCompany } from "./EditCompany.requests";
import {
  EditCompanyFormSchema,
  editCompanyFormSchema,
} from "./EditCompanyFormSchema";

type EditCompanyFormProps = {
  id: string;
  company?: CompanyForGet;
  dataPackageId?: number;
};

const EditCompanyForm = ({
  id,
  company,
  dataPackageId,
}: EditCompanyFormProps) => {
  const queryClient = useQueryClient();
  const componentColors = useComponentColors();
  const toast = useToast();
  const navigate = useNavigate();
  const invalidateCompany = useInvalidateCompany();

  const {
    register,
    handleSubmit,
    control,
    // watch,
    // resetField,
    // setValue,
    formState: { errors },
  } = useForm<EditCompanyFormSchema>({
    mode: "onBlur",
    resolver: zodResolver(editCompanyFormSchema),
    defaultValues: {
      id,
      dataPackageId,
      // isOnTrial: Boolean(company?.trialEndDate),
      displayName: company?.displayName,
    },
  });
  // const defaultIsOnTrial = Boolean(company?.trialEndDate);
  // const isOnTrial = watch("isOnTrial");

  // const confirmRemoveTrial = useConfirm({
  //   title: "Remove Trial",
  //   description: "Are you sure you want to remove the trial?",
  //   confirmText: "Remove Trial",
  //   onCancel: () => resetField("isOnTrial"),
  // });
  // const confirmStartTrial = useConfirm({
  //   title: "Start Trial",
  //   description: "Are you sure you want to start the 90-day trial?",
  //   confirmText: "Start Trial",
  //   onCancel: () => resetField("isOnTrial"),
  // });

  const { mutate, isLoading } = useMutation(editCompany, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["companiesList"],
        exact: false,
      });
      invalidateCompany(company?.companyId);

      toast({
        title: "Company edited successfully.",
        status: "success",
      });

      navigate(CompaniesScreenDefinition.navigate());
    },
  });

  return (
    <SimpleGrid>
      <chakra.form
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit((values) => {
          // if (defaultIsOnTrial && !isOnTrial && !(await confirmRemoveTrial())) {
          //   return;
          // }

          // if (!defaultIsOnTrial && isOnTrial && !(await confirmStartTrial())) {
          //   return;
          // }

          mutate(values);
        })}
      >
        <SimpleGrid>
          <FormControl
            isInvalid={!!errors?.displayName?.message}
            as={GridItem}
            isRequired
          >
            <FormLabel
              color={componentColors.form.formLabelColor}
              fontSize="sm"
              fontWeight="md"
            >
              Company Name
            </FormLabel>

            <Input
              {...register("displayName")}
              type="name"
              name="displayName"
              placeholder="Name"
              shadow="sm"
              size="sm"
              w="300px"
              rounded="md"
              autoComplete="off"
            />

            <FormErrorMessage>{errors?.displayName?.message}</FormErrorMessage>
          </FormControl>

          {/* <FormControl
            mt={4}
            as={GridItem}
            isInvalid={!!errors?.isOnTrial?.message}
          >
            <FormLabel
              htmlFor="isOnTrial"
              fontSize="sm"
              fontWeight="md"
              color={componentColors.form.formLabelColor}
            >
              Company is on 90-day trial period
            </FormLabel>

            <Switch
              {...register("isOnTrial", {
                onChange: (e) => {
                  if (e.currentTarget.checked) {
                    setValue("dataPackageId", DataPackageId.DataSubscription);
                  }
                },
              })}
              id="isOnTrial"
              name="isOnTrial"
              w="300px"
              size="md"
              rounded="md"
              shadow="sm"
              colorScheme="teal"
            />

            <FormErrorMessage>{errors?.isOnTrial?.message}</FormErrorMessage>
          </FormControl> */}

          <FormControl
            isRequired
            isInvalid={!!errors?.dataPackageId?.message}
            as={GridItem}
            w={"300px"}
            mt={4}
          >
            <FormLabel
              color={componentColors.form.formLabelColor}
              fontSize="sm"
              fontWeight="md"
            >
              Company Package
            </FormLabel>

            <Controller
              name="dataPackageId"
              control={control}
              render={({ field: { onChange, value } }) => (
                <DataPackageSelector
                  value={value}
                  onChange={(option) => onChange(Number(option?.value))}
                />
              )}
            />

            <FormErrorMessage>
              {errors?.dataPackageId?.message}
            </FormErrorMessage>
          </FormControl>
        </SimpleGrid>

        <Flex mt={4}>
          <Button
            type="submit"
            variant="primary-teal"
            isLoading={isLoading}
            isDisabled={!!errors.displayName}
          >
            Save Changes
          </Button>
        </Flex>
      </chakra.form>
    </SimpleGrid>
  );
};

export { EditCompanyForm };
