import { isNumeric } from "@intentsify/utils";
import chroma from "chroma-js";
import { DateTime } from "luxon";
import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import {
  disabledDataKeysFamily,
  disabledLegendItemsFamily,
  hoveredLineAtomFamily,
  legendDataSourceHoverFamily,
} from "./Timeseries.state";
import {
  TimeseriesItem,
  TimeseriesItemDataRecord,
  TimeseriesRange,
} from "types";

interface UseTimeseriesDataOptions {
  secondYAxisDataKeyLabel?: string;
  startDate?: Date;
  defaultTimeGap?: number;
}

export const useTimeseriesData = <K extends string, T extends string>(
  data: Array<TimeseriesItem<K, TimeseriesItemDataRecord<T>>>,
  timeseries: TimeseriesRange[],
  uniqueKey: string,
  {
    secondYAxisDataKeyLabel,
    startDate,
    defaultTimeGap,
  }: UseTimeseriesDataOptions = {}
) => {
  const disabledLegendItems = useRecoilValue(
    disabledLegendItemsFamily(uniqueKey)
  );

  const disabledDataKeys = useRecoilValue(disabledDataKeysFamily(uniqueKey));

  const dataWithUniqueKeys = useMemo(() => {
    return data.map((i) => ({
      ...i,
      timeseriesItemId: Math.random().toString(),
    }));
  }, [data]);

  const dataKeys = useMemo(() => {
    return Array.from(
      new Set(
        dataWithUniqueKeys.flatMap((i) =>
          i.timeseries.flatMap((s) => {
            const keys = Object.keys(s.data) as T[];
            return keys.filter((k) => isNumeric(s.data[k]));
          })
        )
      )
    );
  }, [dataWithUniqueKeys]);

  const secondDataItemValues = useMemo(() => {
    const disabledKeys = disabledLegendItems.map((i) => i.timeseriesItemId);

    const secondDataItem = dataWithUniqueKeys.find(
      (i) =>
        (i as unknown as { label: string }).label === secondYAxisDataKeyLabel &&
        !disabledKeys.includes(i.timeseriesItemId)
    );

    if (!secondDataItem) {
      return [];
    }

    return secondDataItem.timeseries.flatMap((s) => {
      const vals = dataKeys
        .filter((k) => !disabledDataKeys.includes(k))
        .map((key) => (s.data[key] ? s.data[key] : 0))
        .filter(isNumeric);

      return vals;
    }) as number[];
  }, [
    disabledLegendItems,
    dataWithUniqueKeys,
    secondYAxisDataKeyLabel,
    dataKeys,
    disabledDataKeys,
  ]);

  const allValues = useMemo(() => {
    const disabledKeys = disabledLegendItems.map((i) => i.timeseriesItemId);

    const withoutDisabled = dataWithUniqueKeys.filter(
      (i) => !disabledKeys.includes(i.timeseriesItemId)
    );

    return withoutDisabled.flatMap((i) =>
      i.timeseries.flatMap((s) => {
        const vals = dataKeys
          .filter((k) => !disabledDataKeys.includes(k))
          .map((key) => (s.data[key] ? s.data[key] : 0))
          .filter(isNumeric);

        return vals;
      })
    ) as number[];
  }, [dataKeys, dataWithUniqueKeys, disabledLegendItems, disabledDataKeys]);

  const timeValues = timeseries.map((i) =>
    DateTime.fromISO(i.start).toUTC().valueOf()
  );

  const calculatedGap =
    timeValues.length > 1 ? timeValues[1] - timeValues[0] : defaultTimeGap;

  if (
    startDate &&
    timeValues.length &&
    startDate.valueOf() < timeValues[0].valueOf()
  ) {
    if (typeof calculatedGap === "number") {
      let nextFirstTimeVal = timeValues[0] - calculatedGap;
      while (nextFirstTimeVal >= startDate.valueOf() && nextFirstTimeVal > 0) {
        timeValues.unshift(nextFirstTimeVal);
        nextFirstTimeVal -= calculatedGap;
      }
    }
  }

  const lineStyles = dataKeys.map((k, i) => {
    return {
      dateKey: k,
      style: {
        strokeDasharray: timeseries.length > 1 ? (i * 10) / 3.5 : 0,
        strokeLinecap: timeseries.length > 1 ? "butt" : undefined,
        strokeLinejoin: "bevel",
      } as const,
    };
  });

  return {
    dataWithUniqueKeys,
    dataKeys,
    allValues,
    secondDataItemValues,
    timeValues,
    lineStyles,
    calculatedGap,
  };
};

export const useStrokeColor = (uniqueKey: string) => {
  const hoveredLine = useRecoilValue(hoveredLineAtomFamily(uniqueKey));
  const hoveredDataSource = useRecoilValue(
    legendDataSourceHoverFamily(uniqueKey)
  );

  const disabledLegendItems = useRecoilValue(
    disabledLegendItemsFamily(uniqueKey)
  );

  const disabledDataSources = useRecoilValue(disabledDataKeysFamily(uniqueKey));

  const color = (
    item: TimeseriesItem<string, TimeseriesItemDataRecord<string>>,
    dataKey: string,
    chartColors: string[],
    i: number
  ) => {
    const hoveredItem = item.timeseriesItemId === hoveredLine?.timeseriesItemId;
    const hoveredItemIsDisabled = disabledLegendItems.find(
      (i) => i.timeseriesItemId === hoveredLine?.timeseriesItemId
    );

    if (hoveredItemIsDisabled) {
      return chartColors[i];
    }

    if (hoveredLine && !hoveredItem) {
      return chroma(chartColors[i]).alpha(0.1).hex();
    }

    const hoveredDataSourceIsDisabled = disabledDataSources.find(
      (i) => i === hoveredDataSource
    );

    if (hoveredDataSourceIsDisabled) {
      return chartColors[i];
    }

    if (hoveredDataSource && hoveredDataSource !== dataKey) {
      return chroma(chartColors[i]).alpha(0.1).hex();
    }

    return chartColors[i];
  };

  return color;
};
