import { atom } from "recoil";
import { LeadsFetchDataParams } from "./components/CampaignsLeadsTable/CampaignsLeadsTable.types";

export const leadsHasResultsAtom = atom<boolean>({
  key: `leadsHasResultsAtom`,
  default: false,
});

export const downloadFetchDataParamsAtom = atom<
  Omit<LeadsFetchDataParams, "page" | "per_page"> | undefined
>({
  key: "downloadFetchDataParamsAtom",
  default: undefined,
});
