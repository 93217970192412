import {
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorMode,
} from "@chakra-ui/react";
import { formatDateRange, isNumeric } from "@intentsify/utils";
import { useMemo } from "react";
import { CellWithChange } from "../../../CellWithChange";
import { TimeseriesProps } from "../Timeseries";
import { TimeseriesItem, TimeseriesItemDataRecord } from "types";

const SimpleSeriesTable = <K extends string, T extends string>({
  timeseries,
  item,
  data,
  dataKeyLabels,
}: Pick<TimeseriesProps<K, T>, "timeseries" | "data" | "dataKeyLabels"> & {
  item: TimeseriesItem<K, TimeseriesItemDataRecord<T>>;
}) => {
  const { colorMode } = useColorMode();

  const textColor = colorMode === "dark" ? "gray.100" : "gray.700";

  const dataKeys = useMemo(() => {
    return Array.from(
      new Set(
        data.map((i) => i.timeseries.map((s) => Object.keys(s.data))).flat(2)
      )
    ) as T[];
  }, [data]);

  return (
    <Table size="xs" variant="simple" textColor={textColor}>
      <Thead>
        <Tr border="none">
          <Th border="none" color={textColor} p={1} pr={3} pb={2}>
            Date Range
          </Th>

          {dataKeys.map((key) => {
            const label = dataKeyLabels ? dataKeyLabels[key] : key;

            return (
              <Th key={key} border="none" color={textColor} p={1} pr={3} pb={2}>
                {label}
              </Th>
            );
          })}
        </Tr>
      </Thead>
      <Tbody>
        {timeseries.map((_series, i) => {
          const label = formatDateRange(timeseries[i]);

          return (
            <Tr key={i} height="25px">
              <Td p={1} pr={3} border="none">
                {label}
              </Td>

              {dataKeys.map((key) => {
                if (isNumeric(item.timeseries[i]?.data[key])) {
                  const value = Number(item.timeseries[i]?.data[key]);
                  const previousValue = Number(
                    item.timeseries[i - 1]?.data[key]
                  );

                  return (
                    <Td key={key} border="none" p={1} pr={3}>
                      <CellWithChange
                        value={value}
                        previousValue={previousValue}
                      />
                    </Td>
                  );
                }

                if (key === "accounts") {
                  const accounts = item.timeseries[i].data[key] as {
                    account: string;
                    count: number;
                  }[];

                  return (
                    <Td key={key} border="none" p={1} pr={3}>
                      {accounts
                        .slice(0, 5)
                        .map((a) => `${a.account} (${a.count})`)
                        .join(", ")}
                    </Td>
                  );
                }

                <Td key={key} border="none" p={1} pr={3}>
                  -
                </Td>;
              })}
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};

export { SimpleSeriesTable };
