import { CampaignTopic } from "@intentsify/types";
import { Endpoints, apiService } from "api";
import { UploadDomainFile } from "types";

export const verifyTopics = async ({
  file,
}: {
  file: File;
  toMergeWith?: CampaignTopic[];
}): Promise<string[]> => {
  const formData = new FormData();
  formData.append("file", file);

  const result = await apiService.post(
    Endpoints.Signals.Post.Topics.Verify,
    formData,
    {
      headers: { "content-type": "multipart/form-data" },
    }
  );

  return result.data;
};

export const uploadCampaignTopics = async ({
  id,
  file,
}: UploadDomainFile & {
  toMergeWith?: CampaignTopic[];
}): Promise<CampaignTopic[]> => {
  const formData = new FormData();
  formData.append("file", file);

  const result = await apiService.post<CampaignTopic[]>(
    Endpoints.Campaigns.Post.Signals.TopicsUpload(id),
    formData,
    {
      headers: { "content-type": "multipart/form-data" },
    }
  );

  return result.data;
};
