import { Campaign, Company } from "@intentsify/types";
import { useMutation } from "@tanstack/react-query";
import { Duration } from "luxon";
import { apiService, Endpoints } from "api";

interface Data {
  campaignId: Campaign["campaignId"];
}

const unAssignCampaigns = async (
  companyId: Company["companyId"],
  integrationId: number,
  data: Data[]
) => {
  const results = await apiService.delete(
    Endpoints.Companies.Delete.Integrations.UnAssignCampaigns(),
    {
      data: data.map((item) => ({
        integrationId,
        campaignId: item.campaignId,
      })),
    }
  );

  return results;
};

export const useUnAssignCampaigns = (
  companyId: Company["companyId"],
  integrationId: number
) => {
  return useMutation<any, unknown, Data[]>(
    (data) => unAssignCampaigns(companyId, integrationId, data),
    {
      cacheTime: Duration.fromDurationLike({ minutes: 5 }).as("millisecond"),
    }
  );
};
