import type { Spotlight } from "@intentsify/types";
import { apiService, Endpoints } from "api";

export const getCampaignDomainSpotlight = async (
  campaignId: number | string,
  domainId: number
): Promise<Spotlight> => {
  const { data } = await apiService.get(
    Endpoints.CampaignDetails.Get.Spotlight(campaignId, domainId)
  );
  return data;
};
