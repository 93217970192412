import { InfoIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  HStack,
  Text,
  useDisclosure,
  useToast,
  UseToastOptions,
} from "@chakra-ui/react";
import { Domain } from "@intentsify/types";
import { isValidDomain, serializeRawDomain } from "@intentsify/utils";
import { useMutation } from "@tanstack/react-query";
import {
  Button,
  ShouldOverwritePrompt,
  StatusModal,
  Tooltip,
  Upload,
} from "components";
import capitalize from "lodash/capitalize";
import uniqBy from "lodash/uniqBy";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { useComponentColors } from "theme";
import { HttpStatus } from "types";
import {
  CustomApiError,
  formatApiError,
  getToastSettingsFromHttpStatusCode,
  parseCSV,
  ParseRowError,
} from "utils";
import { DomainsDuplicates } from "./components";
import { UploadedFileEntry } from "./components/UploadedFiles/UploadedFileEntry";
import { UploadedFiles } from "./components/UploadedFiles/UploadedFiles";
import { verifyDomains } from "./DomainsUpload.requests";
import { DomainsUploadProps } from "./DomainsUpload.types";

/**
 * This is becoming legacy, it uses patterns that should be deprecated. Do not use.
 *
 * If you need domains upload feature, consider DomainsUpload.tsx which is storing files in s3.
 */
const DomainsUploadLegacy = ({
  domainsData,
  uploadedFiles,
  onUploadedDomainsChange,
  showInfoAboutTemplate = false,
}: DomainsUploadProps) => {
  const componentColors = useComponentColors();

  const toast = useToast();
  const {
    isOpen: isDuplicatesModalOpen,
    onOpen: onDuplicatesModalOpen,
    onClose: onDuplicatesModalClose,
  } = useDisclosure();

  const {
    isOpen: isShouldOverwriteModalOpen,
    onOpen: onShouldOverwriteModalOpen,
    onClose: onShouldOverwriteModalClose,
  } = useDisclosure();

  const [domainsFiles, setDomainsFiles] = useState<File[] | undefined>();

  const [duplicatedDomains, setDuplicatedDomains] = useState<string[][]>([]);

  const basicCsvDefinition = {
    domain: {
      isOptional: false,
      validator: (value: string) => {
        if (!isValidDomain(serializeRawDomain(value))) {
          return "Invalid domain";
        }
      },
    },
  };

  const { mutate: verifyDomainsMutation, isLoading } = useMutation(
    verifyDomains,
    {
      onSuccess: ({ domains, fileName }, { toMergeWith }) => {
        const unique = uniqBy(
          [...domains, ...(toMergeWith ?? [])],
          (d) => d.displayName
        );
        onUploadedDomainsChange({ domains: unique, fileName });
      },
    }
  );

  const onRemoveInvalidDomains = (
    csvDomains: { domain: string }[],
    errors: ParseRowError[],
    firstFile: File,
    toMergeWith?: Domain[]
  ) => {
    toast.closeAll();

    const invalidRows = errors.map(({ row }) => row - 1);
    const validDomains = csvDomains.filter(
      (_, index) => !invalidRows.includes(index)
    );

    verifyDomainsMutation({
      domains: validDomains.map((d) => serializeRawDomain(d.domain)),
      fileName: firstFile.name,
      toMergeWith,
    });
  };

  const processDomainsFile = (files: File[], toMergeWith?: Domain[]) => {
    const [firstFile] = files;

    const reader = new FileReader();
    reader.onload = async (event) => {
      if (event?.target?.result) {
        const { data, errors, duplicates } = await parseCSV(
          event.target.result.toString(),
          basicCsvDefinition
        );

        const dataWithoutDuplicates = data;

        if (duplicates.length > 0) {
          setDuplicatedDomains(duplicates);
          const duplicatesLength = duplicates.length;

          toast({
            title: (
              <Text mb={2} textAlign="left">
                {duplicatesLength} duplicate
                {duplicatesLength > 1 ? "s" : ""}{" "}
                {duplicatesLength > 1 ? "were" : "was"} found in the uploaded
                CSV file and automatically removed. For more details:{" "}
                <Button
                  onClick={onDuplicatesModalOpen}
                  variant="link"
                  size="md"
                >
                  click here
                </Button>
              </Text>
            ),
            status: "warning",
            isClosable: true,
            duration: null,
          });
        }

        if (errors.length > 0) {
          const onlyInvalidDomainErrors = errors.every(
            (e) => e.error.toLowerCase() === "invalid domain"
          );

          const toastConfig: Partial<UseToastOptions> =
            getToastSettingsFromHttpStatusCode(HttpStatus.BAD_REQUEST);
          const parsedErrors = errors.map((error) =>
            Object.entries(error)
              .map((x) =>
                capitalize(`${x.join(": ").replace(/([A-Z])/g, " $1")}`)
              )
              .join(", ")
          );

          toast({
            title: (
              <>
                {formatApiError(
                  // If we pass the original err it will be interpreted using internalCode
                  // and the error messages won't be shown
                  new CustomApiError("Domain validation error", {
                    statusCode: HttpStatus.BAD_REQUEST,
                    message: parsedErrors,
                  }),
                  `There was an error while uploading the file. Please verify its correctness.`
                )}

                {onlyInvalidDomainErrors && (
                  <Text mt={3} mb={2} textAlign="left" fontWeight="500">
                    If you want to ignore invalid domains and proceed anyway,{" "}
                    <Button
                      onClick={() =>
                        onRemoveInvalidDomains(
                          data,
                          errors,
                          firstFile,
                          toMergeWith
                        )
                      }
                      variant="link"
                      size="md"
                    >
                      click here
                    </Button>
                    . Invalid domains will be disregarded.
                  </Text>
                )}
              </>
            ),
            ...toastConfig,
            duration: null,
          });
        } else {
          verifyDomainsMutation({
            domains: dataWithoutDuplicates.map((d) =>
              serializeRawDomain(d.domain)
            ),
            fileName: firstFile.name,
            toMergeWith,
          });
        }
      }
    };
    reader.readAsText(firstFile);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (files) => {
      if (domainsData?.domains && domainsData.domains.length > 0) {
        setDomainsFiles(files);
        onShouldOverwriteModalOpen();
      } else {
        processDomainsFile(files);
      }
    },
  });

  return (
    <>
      <HStack spacing={8}>
        <Box h="100%" flex="1">
          <Flex alignItems="center" justifyContent="space-between" mb={4}>
            <Flex alignItems="center">
              <Text
                color={componentColors.form.formLabelColor}
                fontWeight="semibold"
              >
                Upload domains
              </Text>

              <Tooltip
                aria-label="Upload domains"
                label={`Upload domains as a CSV file with a single column: "Domain". Each row should contain a single domain (e.g., "google.com").`}
              >
                <InfoIcon
                  color={componentColors.form.formLabelColor}
                  w={4}
                  h={4}
                  ml={2}
                />
              </Tooltip>
            </Flex>

            <Box>
              {showInfoAboutTemplate && (
                <Text fontSize="sm" color={componentColors.form.formLabelColor}>
                  Start by downloading our template to the right.
                </Text>
              )}
            </Box>
          </Flex>

          <Upload
            testId="domains-upload-zone"
            getInputProps={getInputProps}
            getRootProps={getRootProps}
            subHeader={"CSV up to 100MB"}
          />
        </Box>

        {uploadedFiles && (
          <Flex h="100%" flex="1">
            <UploadedFiles>
              {uploadedFiles.map((uploadedFileEntryProps) => (
                <UploadedFileEntry
                  key={uploadedFileEntryProps.fileName}
                  {...uploadedFileEntryProps}
                />
              ))}
            </UploadedFiles>
          </Flex>
        )}
      </HStack>

      <DomainsDuplicates
        isOpen={isDuplicatesModalOpen}
        onClose={onDuplicatesModalClose}
        duplicates={duplicatedDomains}
        columns={Object.keys(basicCsvDefinition)}
      />

      <ShouldOverwritePrompt
        subjectPlural="domains"
        isOpen={isShouldOverwriteModalOpen}
        onReplace={() => {
          if (domainsFiles) {
            onShouldOverwriteModalClose();
            processDomainsFile(domainsFiles);
          }
        }}
        onMerge={() => {
          if (domainsFiles) {
            onShouldOverwriteModalClose();
            processDomainsFile(domainsFiles, domainsData?.domains ?? []);
          }
        }}
        onClose={onShouldOverwriteModalClose}
      />

      <StatusModal status="Processing..." isOpen={isLoading} />
    </>
  );
};

export { DomainsUploadLegacy };
