import { InfoIcon } from "@chakra-ui/icons";
import { Flex, Heading, Tooltip } from "@chakra-ui/react";
import { useHasAccessTo } from "@intentsify/authorization/dist/react";
import { ErrorBoundary } from "components";
import { PropsWithChildren } from "react";
import { useRecoilValue } from "recoil";
import { screenAtom, screenOptionsAtom } from "utils";
import { Notifications } from "./components";
import { ProfileMenu } from "./components/ProfileMenu/ProfileMenu";
import { UserAgencyCompanySelector } from "./components/UserAgencyCompanySelector/UserAgencyCompanySelector";

const TopBar = (props: PropsWithChildren) => {
  const { name, info } = useRecoilValue(screenAtom);
  const { subtitle } = useRecoilValue(screenOptionsAtom);

  const hasAccessToNotifications = useHasAccessTo(
    "miscellaneous.notifications"
  );

  return (
    <>
      <Flex id="top-bar" bg={"brand.400"} p={4} flexDir="column">
        <Flex alignItems="center" justifyContent="space-between">
          {props.children ? (
            props.children
          ) : (
            <Heading color="gray.300" as="h2" size="sm" lineHeight="inherit">
              {name}{" "}
              {info ? (
                <Tooltip label={info}>
                  <InfoIcon w={4} h={4} ml={2} />
                </Tooltip>
              ) : null}
            </Heading>
          )}

          <Flex>
            <UserAgencyCompanySelector />

            {hasAccessToNotifications && (
              <Flex ml={5}>
                <ErrorBoundary>
                  <Notifications />
                </ErrorBoundary>
              </Flex>
            )}

            <Flex ml={2}>
              <ProfileMenu />
            </Flex>
          </Flex>
        </Flex>

        {subtitle && (
          <Heading
            color="gray.300"
            opacity={0.8}
            as="h3"
            size="xs"
            lineHeight="inherit"
          >
            {subtitle}
          </Heading>
        )}
      </Flex>
    </>
  );
};

export { TopBar };
