import { Box, HStack, useColorModeValue } from "@chakra-ui/react";
import { NodeValue, OnCheckNode, OnExpandNode } from "@intentsify/types";
import { WithChildren } from "types";
import { CollapseButton } from "./CollapseButton";
import { TreeItem } from "./TreeItem";

type TreeNodeProps = WithChildren<{
  checked: number;
  disabled: boolean;
  expanded: boolean;
  isLeaf: boolean;
  isParent: boolean;
  label: string;
  optimisticToggle: boolean;
  treeDepth: number;
  value: NodeValue;
  showCheckbox: boolean;
  onCheck: (node: OnCheckNode) => void;
  onExpand: (node: OnExpandNode) => void;
  expandOnClick: boolean;
  title?: string;
}>;

const TreeNode = (treeNodeProps: TreeNodeProps) => {
  const getCheckState = ({ toggle }: { toggle?: boolean }) => {
    const { checked, optimisticToggle } = treeNodeProps;

    // Toggle off state to checked
    if (checked === 0 && toggle) {
      return true;
    }

    // Node is already checked and we are not toggling
    if (checked === 1 && !toggle) {
      return true;
    }

    // Get/toggle partial state based on cascade model
    if (checked === 2) {
      return optimisticToggle;
    }

    return false;
  };

  const onCheck = () => {
    const { value, disabled, label, title } = treeNodeProps;

    treeNodeProps.onCheck({
      disabled,
      label,
      value,
      title,
      checked: getCheckState({ toggle: true }),
    });
  };

  const onExpand = () => {
    const { expanded, value, disabled, label, title } = treeNodeProps;

    treeNodeProps.onExpand({
      disabled,
      label,
      title,
      value,
      expanded: !expanded,
    });
  };

  const renderChildren = () => {
    if (!treeNodeProps.expanded) {
      return null;
    }

    return treeNodeProps.children;
  };

  const bgHover = useColorModeValue("gray.50", "gray.750");
  const bgLeafHover = useColorModeValue("gray.50", "gray.750");
  const borderBottomColor = useColorModeValue("gray.100", "gray.600");

  return (
    <>
      <Box key={treeNodeProps.value} pl={`${treeNodeProps.treeDepth * 16}px`}>
        <HStack
          justifyContent="space-between"
          px={2}
          py={1}
          borderBottom="1px solid"
          borderBottomColor={
            treeNodeProps.isLeaf ? "transparent" : borderBottomColor
          }
          _hover={{ bg: treeNodeProps.isLeaf ? bgLeafHover : bgHover }}
        >
          <TreeItem
            checked={treeNodeProps.checked}
            disabled={treeNodeProps.disabled}
            value={treeNodeProps.value}
            isParent={treeNodeProps.isParent}
            label={treeNodeProps.label}
            onCheck={onCheck}
            onExpand={onExpand}
          />

          <CollapseButton
            isLeaf={treeNodeProps.isLeaf}
            expanded={treeNodeProps.expanded}
            onExpand={onExpand}
          />
        </HStack>
      </Box>

      {renderChildren()}
    </>
  );
};

export { TreeNode };
