import { useCallback } from "react";
import { SelectionMode } from "types/Keyword";
import { useKeywordsAndTopicsState } from "../../KeywordsAndTopics.hooks";
import { SummaryTableEntry } from "./SummaryTableEntry";

export const useUnselect = () => {
  const { actions, state } = useKeywordsAndTopicsState();

  return useCallback(
    (selectedRows: SummaryTableEntry[]) => {
      const keywordsToUnselect = selectedRows
        .filter((row) => row.category === "Keyword")
        .map((k) => k.name);

      actions.setSelectedKeywords(
        state.selectedKeywords.filter((k) =>
          keywordsToUnselect.includes(k.label)
        ),
        SelectionMode.UNSELECT
      );

      const topicIdsToDeSelect = selectedRows
        .filter((row) => row.category === "Topic")
        .map((t) => t.id);

      actions.setSelectedTopics(
        state.selectedTopics.filter((topic) =>
          topicIdsToDeSelect.includes(topic.topicId)
        ),
        SelectionMode.UNSELECT
      );
    },
    [actions, state.selectedKeywords, state.selectedTopics]
  );
};
