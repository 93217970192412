import { Flex } from "@chakra-ui/react";
import { Option, SignalType } from "@intentsify/types";
import { Grid, NoData, ScreenBar, ViewContainer } from "components";
import { useNavigate, useParams } from "react-router";
import { useUnmount, useUpdateEffect } from "react-use";
import {
  CampaignActionsItems,
  CampaignMetaHeader,
  CampaignSelector,
  WithIntentDataAvailable,
  useCampaignMeta,
  useCampaignSelectorSelectedCampaign,
} from "shared/components";
import { useScreen } from "utils";
import { TrendingTopicsAndKeywordsScreenDefinition } from "./CampaignsTrendingSignals.definition";
import { useResetState } from "./CampaignsTrendingSignals.hooks";
import { TopSignals, TrendingKeywords, TrendingTopics } from "./components";

const CampaignsTrendingSignals = () => {
  useScreen(TrendingTopicsAndKeywordsScreenDefinition);
  const campaignId = Number(useParams<"campaignId">().campaignId);
  const navigate = useNavigate();
  const resetState = useResetState();

  useUnmount(() => {
    resetState();
  });

  useUpdateEffect(() => {
    resetState();
  }, [campaignId]);

  const { selected, setSelected } = useCampaignSelectorSelectedCampaign(
    Number(campaignId)
  );

  const { campaignMetaIsLoading, campaignMeta } = useCampaignMeta({
    campaignId,
  });

  const onCampaignChange = (c: Option<number> | undefined) => {
    setSelected(c);

    if (!c) {
      navigate(
        TrendingTopicsAndKeywordsScreenDefinition.navigate({
          campaignId: undefined,
        })
      );
    } else {
      resetState();
      navigate(
        TrendingTopicsAndKeywordsScreenDefinition.navigate({
          campaignId: Number(c?.value),
        })
      );
    }
  };

  return (
    <>
      <ScreenBar
        filters={[
          {
            label: "Intent Models",
            width: "100%",
            node: (
              <CampaignSelector
                selected={selected}
                onChange={onCampaignChange}
              />
            ),
          },
        ]}
      />

      {!campaignId && (
        <ViewContainer>
          <NoData message="Please select an intent model" />
        </ViewContainer>
      )}

      {campaignId && (
        <>
          <Flex justifyContent="space-between" p={4} alignItems="top">
            <CampaignMetaHeader />
            <CampaignActionsItems
              isLoading={campaignMetaIsLoading}
              campaignId={Number(campaignId)}
              companyId={campaignMeta?.companyId}
              campaignCreatedDate={campaignMeta?.campaignCreateDate}
              hasBeenMappedToConvertr={Boolean(campaignMeta?.bookedLeads)}
              hasLeads={Boolean(campaignMeta?.numberOfLeads)}
              hasSpotlight={campaignMeta?.hasSpotlight}
              campaignStatusId={campaignMeta?.campaignStatusId}
              numberOfVisibleItems={1}
              showCampaignDetailsAction={true}
              hasRelatedProgrammaticCampaigns={Boolean(
                campaignMeta?.hasRelatedProgrammaticCampaigns
              )}
              hasPixel={Boolean(campaignMeta?.hasPixel)}
              campaignStartDate={campaignMeta?.campaignStartDate}
              campaignEndDate={campaignMeta?.campaignEndDate ?? undefined}
            />
          </Flex>
          <WithIntentDataAvailable>
            <Grid>
              <TrendingTopics />
              <TrendingKeywords />
              <TopSignals
                signalType={SignalType.Topics}
                headerText="Most Researched Topics"
              />
              <TopSignals
                signalType={SignalType.Keywords}
                headerText="Most Researched Keywords"
              />
            </Grid>
          </WithIntentDataAvailable>
        </>
      )}
    </>
  );
};

export { CampaignsTrendingSignals };
