import { useLayoutEffect, useState } from "react";

function usePageVisibility() {
  const [isPageVisible, setIsPageVisible] = useState(!document.hidden);

  useLayoutEffect(() => {
    const handleVisibility = () => {
      setIsPageVisible(!document.hidden);
    };
    document.addEventListener("visibilitychange", handleVisibility);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibility);
    };
  }, []);

  return { isPageVisible };
}

const calculateRequiredUpdates = ({
  clientVersion,
  apiVersion,
}: {
  clientVersion: string;
  apiVersion: string;
}) => {
  const clientMajor = Number(clientVersion.split(".")[0]);
  const apiMajor = Number(apiVersion.split(".")[0]);

  return {
    requiredUpdate: clientMajor < apiMajor,
    recommendUpdate: clientVersion !== apiVersion,
  };
};

export { usePageVisibility, calculateRequiredUpdates };
