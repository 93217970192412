import { ColorMode } from "@chakra-ui/react";
import { isDefined } from "@intentsify/utils";
import { DateTime } from "luxon";
import { colors } from "theme";

export const getNumberOfLeadsExpectedForToday = (
  startDate: string | null,
  endDate: string | null,
  goal: number
) => {
  if (!startDate) {
    return undefined;
  }

  const start = DateTime.fromISO(startDate);

  if (start > DateTime.now()) {
    return undefined;
  }

  const end = endDate
    ? DateTime.fromISO(endDate)
    : DateTime.now().plus({ year: 1 });

  const durationInDays = end.diff(start).as("days");

  const daysElapsed =
    (DateTime.now().valueOf() - start.valueOf()) / (1000 * 3600 * 24);

  const paceToHitTheGoal = goal / durationInDays;
  const expectedNumberOfLeadsForToday = paceToHitTheGoal * daysElapsed;
  return Math.round(expectedNumberOfLeadsForToday);
};

export const getProgressValue = (current: number, goal: number) => {
  const progressValue = (current * 100) / goal;

  if (Number.isNaN(progressValue)) {
    return 0;
  }

  if (progressValue > 100) {
    return 100;
  }

  if (progressValue < 0) {
    return 0;
  }

  return progressValue;
};

const progresColors = {
  light: [colors.red[500], colors.yellow[500], colors.green[500]],
  dark: [colors.red[300], colors.yellow[300], colors.green[300]],
};

/**
 * Returns the campagin progress bar colour based on the campaign progressScore.
 */
export const getProgressColor = (
  progressScore: number | null,
  colorMode: ColorMode
) => {
  if (!isDefined(progressScore)) {
    return undefined;
  }

  const [red, yellow, green] = progresColors[colorMode];

  switch (true) {
    case progressScore >= 95:
      return green;
    case progressScore < 95 && progressScore >= 85:
      return yellow;
    case !progressScore || progressScore < 85:
      return red;
  }
};
