import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { matchPath, useLocation } from "react-router";
import {
  CreateCampaignScreenDefinition,
  EditCampaignScreenDefinition,
} from "screens";
import { talUpload } from "upload/talUpload";

export const useWatchLocation = () => {
  const { pathname } = useLocation();
  const queryClient = useQueryClient();
  const [pathNameHistory, setPathNameHistory] = useState<string[]>([]);

  useEffect(() => {
    setPathNameHistory((prev) => [pathname, ...prev].slice(0, 2));
  }, [pathname]);

  /**
   * User's campaign data might be stale, invalidate it every time Edit Campaign screen is loaded
   * but not when user navigates tabs within the screen
   */
  useEffect(() => {
    const currentPathName = pathNameHistory[0];
    const previousPathName = pathNameHistory[1];

    if (currentPathName && previousPathName) {
      const currentMatch = matchPath(
        EditCampaignScreenDefinition.path,
        currentPathName
      );

      const previousMatch = previousPathName
        ? matchPath(EditCampaignScreenDefinition.path, previousPathName)
        : undefined;

      if (
        previousMatch === null &&
        currentMatch &&
        currentMatch.params.campaignId
      ) {
        queryClient.invalidateQueries({
          queryKey: ["campaign", Number(currentMatch.params.campaignId)],
        });
      }
    }
  }, [pathNameHistory, queryClient]);

  const removeAllTalUploadFiles = talUpload.useRemoveAllFiles();

  /**
   * Remove all TAL uploaded but not saved files when user navigates away from
   * Create or Edit Campaign screens.
   */
  useEffect(() => {
    const currentPathName = pathNameHistory[0];
    const previousPathName = pathNameHistory[1];

    if (currentPathName && previousPathName) {
      const currentEditMatch = matchPath(
        EditCampaignScreenDefinition.path,
        currentPathName
      );
      const currentCreateMatch = matchPath(
        CreateCampaignScreenDefinition.path,
        currentPathName
      );

      const previousEditMatch = previousPathName
        ? matchPath(EditCampaignScreenDefinition.path, previousPathName)
        : undefined;
      const previousCreateMatch = previousPathName
        ? matchPath(CreateCampaignScreenDefinition.path, previousPathName)
        : undefined;

      if (
        currentEditMatch === null &&
        previousEditMatch &&
        previousEditMatch.params.campaignId
      ) {
        removeAllTalUploadFiles();
      }

      if (
        currentCreateMatch === null &&
        previousCreateMatch &&
        previousCreateMatch.params.campaignId
      ) {
        removeAllTalUploadFiles();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathNameHistory]);
};
