import { Flex } from "@chakra-ui/react";
import ChocPagination from "@choc-ui/paginator";

type PaginationProps = {
  hidePageSize: boolean;
  total: number;
  current: number;
  pageSize: number;
  goToPage: (page: number) => void;
  setPageSize: (pageSize: number) => void;
};

const Pagination = ({
  hidePageSize,
  total,
  current,
  pageSize,
  goToPage,
  setPageSize,
}: PaginationProps) => {
  if (total === 0) {
    return null;
  }

  return (
    <Flex
      key="pagination"
      w="full"
      bg="transparent"
      p={2}
      alignItems="center"
      justifyContent="flex-end"
      borderBottomRadius={4}
      overflow="hidden"
    >
      <ChocPagination
        size="sm"
        paginationProps={{ display: "flex" }}
        baseStyles={{
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: "gray.400",
          borderRadius: 0,
          backgroundColor: "transparent",
        }}
        current={current}
        total={total}
        pageSize={pageSize}
        hoverStyles={{
          backgroundColor: "gray.400",
        }}
        onChange={(currentPage) => {
          typeof currentPage !== "undefined" && goToPage(currentPage - 1);
        }}
        onShowSizeChange={(currentPage, size) => {
          typeof currentPage !== "undefined" && size && setPageSize(size);
        }}
        showSizeChanger={!hidePageSize}
        colorScheme="gray"
        responsive={true}
        pageNeighbours={2}
      />
    </Flex>
  );
};

export { Pagination };
