import { BusinessUnit, CampaignSettings, Option } from "@intentsify/types";
import { useMemo } from "react";
import { Control, useWatch } from "react-hook-form";
import { haveSettingsChanged } from "screens/Campaigns/screens/YourCampaigns/YourCampaigns.utils";
import { CampaignSettingsInputs } from "./SettingsStep.types";

export const useWatchSettings = (
  campaignSettings: CampaignSettings | undefined,
  checkedCountries: (string | number)[],
  control: Control<CampaignSettingsInputs, any>,
  businessUnits: Option[]
) => {
  const values = useWatch({
    control,
  });

  const formState = useMemo(() => {
    if (!campaignSettings) {
      return undefined;
    }

    return {
      ...campaignSettings,
      ...values,
      countries: checkedCountries.map((countryId) => ({
        countryId: Number(countryId),
        displayName: "",
      })),
      businessUnits: businessUnits.map(
        ({ value, label }) =>
          ({
            displayName: label,
            businessUnitId: Number(value),
          } as BusinessUnit)
      ),
    };
  }, [campaignSettings, values, checkedCountries, businessUnits]);

  const haveChanged = useMemo(() => {
    if (
      campaignSettings &&
      formState &&
      haveSettingsChanged(campaignSettings, formState)
    ) {
      return true;
    }

    return false;
  }, [formState, campaignSettings]);

  return haveChanged;
};
