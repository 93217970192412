import { Flex } from "@chakra-ui/react";
import { useHasAccessTo } from "@intentsify/authorization/dist/react";
import { ProgrammaticDomainFileForList } from "@intentsify/types";
import { useQuery } from "@tanstack/react-query";
import { Button, Search, Table } from "components";
import { useCallback, useState } from "react";
import { FiUpload } from "react-icons/fi";
import { useNavigate } from "react-router";
import { useTrackSearched } from "tracking/useTrackSearched";
import { FetchDataParamsWithToken } from "types";
import { PaginationType } from "../../../../../../../components/Table/Table/Table.types";
import { ProgrammaticDomainsUploadScreenDefinition } from "../../ProgrammaticDomainsUpload/ProgrammaticDomainsUpload.definition";
import { useProgrammaticDomainsTableColumns } from "./ProgrammaticDomainsTable.columns";
import {
  defaultListProgrammaticDomainFilesParams,
  listProgrammaticDomainFiles,
} from "./ProgrammaticDomainsTable.requests";

const ProgrammaticDomainsTable = () => {
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);

  const [fetchDataParams, setFetchDataParams] = useState<
    FetchDataParamsWithToken<keyof ProgrammaticDomainFileForList>
  >(defaultListProgrammaticDomainFilesParams);

  const { data, isFetching } = useQuery(
    ["listProgrammaticDomainFiles", fetchDataParams],
    () => listProgrammaticDomainFiles(fetchDataParams)
  );

  const handleOnFetchDataChange = useCallback(
    (params: FetchDataParamsWithToken<keyof ProgrammaticDomainFileForList>) => {
      setFetchDataParams(params);
    },
    []
  );

  const navigate = useNavigate();
  const trackSearched = useTrackSearched();

  const handleRedirectToUploadFile = () => {
    navigate(ProgrammaticDomainsUploadScreenDefinition.navigate());
  };

  const hasAccessToUploadProgrammaticDomains = useHasAccessTo(
    "programmaticCampaign.programmaticDomain"
  );

  return (
    <Flex width="100%" flexDirection="column">
      <Flex width="100%" alignItems="center">
        <Search
          currentValue={searchValue}
          onSearch={(v) => {
            setSearchValue(v);
            trackSearched({
              term: v,
              collocation: "Programmatic domains table",
            });
          }}
        />
        <Flex grow={1} />

        {hasAccessToUploadProgrammaticDomains && (
          <Button
            variant="primary-teal"
            leftIcon={<FiUpload />}
            onClick={handleRedirectToUploadFile}
          >
            Upload Segments
          </Button>
        )}
      </Flex>

      <Table
        searchValue={searchValue}
        defaultSort={[
          {
            id: "programmaticDomainsFileId",
            desc: true,
          },
        ]}
        columns={useProgrammaticDomainsTableColumns()}
        variant="intentsifyNew"
        isFetching={isFetching}
        data={data?.programmaticDomainFiles || []}
        onFetchDataChange={handleOnFetchDataChange}
        paginationType={PaginationType.TOKEN}
        nextPageToken={data?.nextPageToken}
        resetPaginationDependencies={[searchValue]}
      />
    </Flex>
  );
};

export { ProgrammaticDomainsTable };
