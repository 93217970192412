import { WeeklySnapshotDTO } from "@intentsify/dto";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { DateTime, Duration } from "luxon";
import { getTrendingTopics } from "./TrendingTopics.requests";
import { useTrendingTopicsTimeseriesData } from "./TrendingTopics.utils";
import { Card, MIN_MODAL_CONTENT_HEIGHT, Select, Timeseries } from "components";
import { GenericSnapToTooltip } from "components/Charts/Timeseries/components/TimeseriesTooltip/GenericSnapToTooltip";
import { ChartExport, useExportableChart } from "shared/components";

type TrendingTopicsProps = {
  campaignId: number;
  filters: WeeklySnapshotDTO;
};

const TrendingTopics = ({ campaignId, filters }: TrendingTopicsProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const { data, isFetching } = useQuery(
    ["getTrendingTopics", campaignId, filters],
    () => getTrendingTopics(campaignId, filters)
  );

  const { selectedTopics, setSelectedTopics, chartData, options } =
    useTrendingTopicsTimeseriesData(data ?? []);

  const trendingTopicsChart = useExportableChart({
    title: "Trending Topics Last 30-Days",
    campaignId,
    chart: (
      <Timeseries
        minHeight={isExpanded ? MIN_MODAL_CONTENT_HEIGHT : undefined}
        data={chartData.data}
        dataKeyLabels={{ value: "Signals" }}
        dataMainKey="label"
        timeseries={chartData.timeseries}
        scale="linear"
        snapToDateTime
        tooltipRenderer={GenericSnapToTooltip}
        stretch
        noDataMessage="Select topics to see data trends"
        startDate={DateTime.fromObject({
          weekNumber: filters.weekTo,
          weekYear: filters.yearTo,
        })
          .endOf("week")
          .minus({
            days: 30,
          })
          .toJSDate()}
        defaultTimeGap={Duration.fromObject({ weeks: 1 }).as("milliseconds")}
      />
    ),
  });

  return (
    <Card
      onExpandedChange={setIsExpanded}
      isLoading={isFetching}
      title={trendingTopicsChart.title}
      hasData={!isFetching && Boolean(data?.length)}
      noDataMessage="Trending data is configuring and will be available after two weeks from campaign launch."
      isExpandable
      actions={
        <ChartExport
          size="small"
          campaignId={campaignId}
          title={trendingTopicsChart.title}
          onExportPNG={trendingTopicsChart.downloadAsPng}
          data={chartData.data}
        />
      }
    >
      <Select
        placeholder="Select topics"
        isMulti={true}
        options={options}
        value={options.filter((c) => selectedTopics.includes(c.value))}
        onChange={(options) => {
          setSelectedTopics(options.map((s) => s.value));
        }}
      />

      {trendingTopicsChart.component}
      {trendingTopicsChart.exportComponent}
    </Card>
  );
};

export { TrendingTopics };
