import {
  Box,
  Button,
  ButtonProps,
  Flex,
  SpaceProps,
  useColorModeValue,
} from "@chakra-ui/react";
import { LayoutGroup, motion } from "framer-motion";
import { colors } from "theme";

export type ButtonGroupItem<T> = {
  label: string;
  value: T;
  isLoading?: boolean;
};

type ButtonGroupProps<T> = {
  items: ButtonGroupItem<T>[];
  isDisabled?: boolean;
  selectedValue?: T;
  setSelectedValue?: (value: T) => void;
  colorScheme?: ButtonProps["colorScheme"];
  size?: ButtonProps["size"];
} & SpaceProps;

const ButtonGroup = <T,>({
  items,
  selectedValue,
  isDisabled,
  setSelectedValue,
  colorScheme,
  size = "sm",
  ...spaceProps
}: ButtonGroupProps<T>) => {
  const borderColor = useColorModeValue(colors.gray["100"], colors.gray["800"]);

  const underlineColor = useColorModeValue(
    colors.teal["600"],
    colors.teal["400"]
  );

  const id = Math.random().toString();

  return (
    <Flex flexDir="column" {...spaceProps}>
      <Flex>
        {items.map((i) => {
          const selected = selectedValue === i.value;

          return (
            <Flex key={i.label} flexDir="column" position="relative" mr={2}>
              <LayoutGroup id={id}>
                <motion.div
                  onClick={() => setSelectedValue && setSelectedValue(i.value)}
                  animate
                >
                  <Button
                    colorScheme={colorScheme}
                    pointerEvents={selected ? "none" : "all"}
                    size={size}
                    variant="ghost"
                    isDisabled={isDisabled}
                    isLoading={i.isLoading}
                  >
                    {i.label}
                  </Button>

                  {selected && (
                    <motion.div
                      layoutId="underline"
                      style={{
                        backgroundColor: underlineColor,
                        position: "absolute",
                        width: "100%",
                        height: "5px",
                        bottom: "-5px",
                      }}
                    />
                  )}
                </motion.div>
              </LayoutGroup>
            </Flex>
          );
        })}
      </Flex>

      <Box mt={"2px"} w="100%" borderTop={`1px solid ${borderColor}`} />
    </Flex>
  );
};

export { ButtonGroup };
