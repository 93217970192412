import {
  Box,
  Flex,
  ListItem,
  Text,
  UnorderedList,
  useColorMode,
  useMediaQuery,
} from "@chakra-ui/react";
import {
  BuyerResearchStage,
  BuyerResearchStageMap,
  IntegratedCampaignReportReached,
} from "@intentsify/types";
import { decimalToPercentage, toNumberDisplayValue } from "@intentsify/utils";
import { useQuery } from "@tanstack/react-query";
import {
  Card,
  ErrorBoundary,
  Grid,
  MIN_MODAL_CONTENT_HEIGHT,
  NoData,
  PieChart,
  StackedGroupedBarChart,
  StatCardsContainer,
  Timeseries,
  getChartColors,
} from "components";
import { groupBy, partition } from "lodash";
import { DateTime } from "luxon";
import { useMemo, useState } from "react";
import { FaHashtag } from "react-icons/fa";
import { ChartExport, useExportableChart } from "shared/components";
import { TimeseriesItem, TimeseriesRange } from "types";
import { DisplayPerformanceFilters } from "../../DisplayPerformance";
import { NOT_A_CPA_CAMPAIGN_MESSAGE } from "../../DisplayPerformance.utils";
import {
  getIntegratedCampaignReport,
  getStatistics,
} from "../CampaignEngagement/CampaignEngagement.requests";
import {
  getAccountAndPersonasInsights,
  getAccountEngagement,
  getAccountInBuyerResearchStage,
  getAccountLifts,
} from "./AccountsPerformance.requests";
import { TopAccounts } from "./components";
import { EngagementAcrossPersonas } from "./components/EngagementAcrossPersonas";

const MIN_NUMBER_OF_ACCOUNTS_FOR_INSIGHTS = 50;

type OvertimeTimeseriesItem = TimeseriesItem<"label", { value: number }>;

type AccountsPerformanceProps = {
  campaignName: string;
  id: string;
  companyId?: number | string;
  filters: DisplayPerformanceFilters;
};

const AccountsPerformance = ({
  campaignName,
  id,
  companyId,
  filters,
}: AccountsPerformanceProps) => {
  const { colorMode } = useColorMode();
  const [isExpandedReached, setIsExpandedReached] = useState(false);
  const [isExpandedInBuyerResearchStage, setIsExpandedInBuyerResearchStage] =
    useState(false);
  const [isExpandedMovement, setIsExpandedMovement] = useState(false);
  const [
    isExpandedIntegratedCampaignReport,
    setIsExpandedIntegratedCampaignReport,
  ] = useState(false);

  const [isLargerThan1800] = useMediaQuery("(min-width: 1800px)");

  const { data: dataLifts, isFetching: isLoadingLifts } = useQuery(
    ["getAccountLifts", id, filters],
    () => getAccountLifts(id, filters)
  );

  const {
    data: buyerResearchStageData,
    isFetching: isLoadingBuyerResearchStageData,
  } = useQuery(["getAccountInBuyerResearchStage", id, filters], () =>
    getAccountInBuyerResearchStage(id, filters)
  );

  const { data: engagementData, isFetching: isLoadingEngagementData } =
    useQuery(["getAccountEngagement", id, filters], () =>
      getAccountEngagement(id, filters)
    );

  const { data: statisticsData } = useQuery(
    ["impressionsClicksPerformance-statistics", id, filters],
    () => getStatistics(id, filters)
  );

  const {
    data: integratedCampaignReportData,
    isFetching: isLoadingCampaignIntegratedReportData,
  } = useQuery(["campaignIntegratedReport", id, filters], () =>
    getIntegratedCampaignReport(id, filters)
  );

  const {
    data: accountAndPersonaInsightsData,
    isFetching: isLoadingAccountAndPersonasInsightsData,
  } = useQuery({
    queryKey: ["accountAndPersonasInsights", id, filters],
    queryFn: async () => await getAccountAndPersonasInsights(id, filters),
  });

  const insightsColor = colorMode === "dark" ? "gray.25" : "gray.600";

  const dates = useMemo(() => {
    return {
      start: DateTime.fromObject({
        weekNumber: filters.weekFrom,
        weekYear: filters.yearFrom,
      }).toISO(),
      end: DateTime.fromObject({
        weekNumber: filters.weekTo,
        weekYear: filters.yearTo,
      }).toISO(),
    };
  }, [filters]);

  const tsData: {
    data: OvertimeTimeseriesItem[];
    timeseries: TimeseriesRange[];
  } = useMemo(() => {
    const reach: OvertimeTimeseriesItem = {
      label: "Accounts Reached",
      timeseries: [],
    };

    const timeseries: TimeseriesRange[] = [];

    if (dataLifts?.accountsReachedOverTime) {
      dataLifts?.accountsReachedOverTime.forEach((i) => {
        reach.timeseries.push({
          isoDate: i.date,
          data: { value: i.accountsReached },
        });

        timeseries.push({
          start: i.date,
          end: DateTime.fromISO(i.date).endOf("week").toISO(),
        });
      });
    }

    return {
      data: [reach],
      timeseries,
    };
  }, [dataLifts?.accountsReachedOverTime]);

  const reachedOverTimeChart = useExportableChart({
    title: "Accounts Reached Over Time",
    campaignId: Number(id),
    dates,
    chart: (
      <Timeseries
        minHeight={isExpandedReached ? MIN_MODAL_CONTENT_HEIGHT : undefined}
        stretch
        isLoading={isLoadingLifts}
        data={tsData.data}
        dataMainKey="label"
        timeseries={tsData.timeseries}
        variant="bars"
        pallete={["green"]}
        disableLegend
        dataKeyLabels={{
          value: "Accounts Reached",
        }}
      />
    ),
  });

  const accountsInBuyerResearchStage = useExportableChart({
    title: "Accounts Reached In Buyer Research Stage",
    campaignId: Number(id),
    dates,
    chart: (
      <PieChart
        minHeight={
          isExpandedInBuyerResearchStage ? MIN_MODAL_CONTENT_HEIGHT : undefined
        }
        stretch
        isLoading={isLoadingBuyerResearchStageData}
        legendPosition={isLargerThan1800 ? "right" : "bottom"}
        data={(buyerResearchStageData?.accountsInBuyerResearchStage ?? []).map(
          (i) => ({
            value: i.countAccounts,
            label: i.buyerResearchStage,
          })
        )}
      />
    ),
  });

  const formattedEngagementData = useMemo(() => {
    if (!engagementData) {
      return [];
    }

    const AllAccounts = engagementData.activeCampaignAccountEngagements.find(
      (i) => i.buyerResearchStage === BuyerResearchStage.All
    );
    const Decision = engagementData.activeCampaignAccountEngagements.find(
      (i) => i.buyerResearchStage === BuyerResearchStage.Decision
    );
    const Consideration = engagementData.activeCampaignAccountEngagements.find(
      (i) => i.buyerResearchStage === BuyerResearchStage.Consideration
    );
    const Interest = engagementData.activeCampaignAccountEngagements.find(
      (i) => i.buyerResearchStage === BuyerResearchStage.Interest
    );
    const Awareness = engagementData.activeCampaignAccountEngagements.find(
      (i) => i.buyerResearchStage === BuyerResearchStage.Awareness
    );

    return [
      {
        groupLabel: "Targeted",
        groupItems: [
          {
            label: !isLargerThan1800 ? "All" : "All Accounts",
            data: [
              {
                label: "All Accounts",
                value: AllAccounts?.targetedAllAccounts ?? 0,
              },
            ],
          },
          {
            label: "Trending",
            data: [
              { label: "Decision", value: Decision?.targetedTrending ?? 0 },
              {
                label: "Consideration",
                value: Consideration?.targetedTrending ?? 0,
              },
              { label: "Interest", value: Interest?.targetedTrending ?? 0 },
              { label: "Awareness", value: Awareness?.targetedTrending ?? 0 },
            ],
          },
        ],
      },
      {
        groupLabel: "Reached",
        groupItems: [
          {
            label: !isLargerThan1800 ? "All" : "All Accounts",
            data: [
              {
                label: "All Accounts",
                value: AllAccounts?.reachedAllAccounts ?? 0,
              },
            ],
          },
          {
            label: "Trending",
            data: [
              { label: "Decision", value: Decision?.reachedTrending ?? 0 },
              {
                label: "Consideration",
                value: Consideration?.reachedTrending ?? 0,
              },
              { label: "Interest", value: Interest?.reachedTrending ?? 0 },
              { label: "Awareness", value: Awareness?.reachedTrending ?? 0 },
            ],
          },
        ],
      },
      {
        groupLabel: "Engaged",
        groupItems: [
          {
            label: !isLargerThan1800 ? "All" : "All Accounts",
            data: [
              {
                label: "All Accounts",
                value: AllAccounts?.engagedAllAccounts ?? 0,
              },
            ],
          },
          {
            label: "Trending",
            data: [
              { label: "Decision", value: Decision?.engagedTrending ?? 0 },
              {
                label: "Consideration",
                value: Consideration?.engagedTrending ?? 0,
              },
              { label: "Interest", value: Interest?.engagedTrending ?? 0 },
              { label: "Awareness", value: Awareness?.engagedTrending ?? 0 },
            ],
          },
        ],
      },
    ];
  }, [engagementData, isLargerThan1800]);

  const accountMovement = useExportableChart({
    title: "Account Movement by Buyer Research Stage",
    campaignId: Number(id),
    dates,
    chart: (
      <StackedGroupedBarChart
        minHeight={isExpandedMovement ? MIN_MODAL_CONTENT_HEIGHT : undefined}
        limitedWidth={!isLargerThan1800}
        isLoading={isLoadingEngagementData}
        stretch
        data={formattedEngagementData}
        legendConfig={{
          domain: [
            BuyerResearchStage.Decision,
            BuyerResearchStage.Consideration,
            BuyerResearchStage.Interest,
            BuyerResearchStage.Awareness,
          ],
          range: getChartColors(colorMode, 1).reverse(),
        }}
      />
    ),
  });

  const formatedIntegratedCampaignReportData = useMemo(() => {
    if (!integratedCampaignReportData) {
      return {
        reached: [],
        withoutImpressions: [],
      };
    }

    const data = integratedCampaignReportData.integratedCampaignReport.map(
      (item) => {
        return {
          ...item,
          date: DateTime.fromISO(item.date).toLocaleString({
            month: "short",
            day: "numeric",
          }),
        };
      }
    );

    const [reached, withoutImpressions] = partition(data, (element) => {
      return (
        element.reached === IntegratedCampaignReportReached.AccountsReached
      );
    });

    const groupedReached = groupBy(reached, "date");
    const groupedWithoutImpressions = groupBy(withoutImpressions, "date");

    return {
      maxYValue: Math.max(
        ...Object.keys(groupedReached).map((key) => {
          const values = groupedReached[key];

          return values.reduce((acc, prev) => {
            return acc + prev.domainsCount;
          }, 0);
        }),
        ...Object.keys(groupedWithoutImpressions).map((key) => {
          const values = groupedWithoutImpressions[key];

          return values.reduce((acc, prev) => {
            return acc + prev.domainsCount;
          }, 0);
        })
      ),
      reached: Object.keys(groupedReached).map((key) => {
        return {
          groupLabel: key,
          groupItems: [
            {
              label: key,
              data: [
                {
                  label: BuyerResearchStage.Decision,
                  value:
                    groupedReached[key].find(
                      (el) => el.bestStage === BuyerResearchStageMap.Decision
                    )?.domainsCount || 0,
                },
                {
                  label: BuyerResearchStage.Consideration,
                  value:
                    groupedReached[key].find(
                      (el) =>
                        el.bestStage === BuyerResearchStageMap.Consideration
                    )?.domainsCount || 0,
                },
                {
                  label: BuyerResearchStage.Interest,
                  value:
                    groupedReached[key].find(
                      (el) => el.bestStage === BuyerResearchStageMap.Interest
                    )?.domainsCount || 0,
                },
                {
                  label: BuyerResearchStage.Awareness,
                  value:
                    groupedReached[key].find(
                      (el) => el.bestStage === BuyerResearchStageMap.Awareness
                    )?.domainsCount || 0,
                },
              ],
            },
          ],
        };
      }),
      withoutImpressions: Object.keys(groupedWithoutImpressions).map((key) => {
        return {
          groupLabel: key,
          groupItems: [
            {
              label: key,
              data: [
                {
                  label: BuyerResearchStage.Decision,
                  value:
                    groupedWithoutImpressions[key].find(
                      (el) => el.bestStage === BuyerResearchStageMap.Decision
                    )?.domainsCount || 0,
                },
                {
                  label: BuyerResearchStage.Consideration,
                  value:
                    groupedWithoutImpressions[key].find(
                      (el) =>
                        el.bestStage === BuyerResearchStageMap.Consideration
                    )?.domainsCount || 0,
                },
                {
                  label: BuyerResearchStage.Interest,
                  value:
                    groupedWithoutImpressions[key].find(
                      (el) => el.bestStage === BuyerResearchStageMap.Interest
                    )?.domainsCount || 0,
                },
                {
                  label: BuyerResearchStage.Awareness,
                  value:
                    groupedWithoutImpressions[key].find(
                      (el) => el.bestStage === BuyerResearchStageMap.Awareness
                    )?.domainsCount || 0,
                },
              ],
            },
          ],
        };
      }),
    };
  }, [integratedCampaignReportData]);

  const integratedCampaignReport = useExportableChart({
    title: "integratedCampaignReport",
    campaignId: Number(id),
    dates,
    chart: (
      <>
        <StackedGroupedBarChart
          minHeight={
            isExpandedIntegratedCampaignReport
              ? MIN_MODAL_CONTENT_HEIGHT
              : undefined
          }
          title="Accounts Reached with Display Ad Impressions"
          limitedWidth={!isLargerThan1800}
          isLoading={isLoadingCampaignIntegratedReportData}
          stretch
          data={formatedIntegratedCampaignReportData.reached}
          valueAxisLabel="Count of Accounts"
          maxYValue={formatedIntegratedCampaignReportData.maxYValue}
          hideGroupTicks={true}
          showTitle={true}
        />
        <StackedGroupedBarChart
          minHeight={
            isExpandedIntegratedCampaignReport
              ? MIN_MODAL_CONTENT_HEIGHT
              : undefined
          }
          title="Accounts Not Reached with Display Ad Impressions"
          limitedWidth={!isLargerThan1800}
          isLoading={isLoadingCampaignIntegratedReportData}
          stretch
          data={formatedIntegratedCampaignReportData.withoutImpressions}
          valueAxisLabel="Count of Accounts"
          legendPosition={"bottom"}
          legendConfig={{
            domain: [
              BuyerResearchStage.Decision,
              BuyerResearchStage.Consideration,
              BuyerResearchStage.Interest,
              BuyerResearchStage.Awareness,
            ],
            range: getChartColors(colorMode, 0).reverse(),
          }}
          maxYValue={formatedIntegratedCampaignReportData.maxYValue}
          legendTitle="Stage"
          hideGroupTicks={true}
          showTitle={true}
        />
      </>
    ),
  });

  const numberOfUnengagedAccounts =
    accountAndPersonaInsightsData?.accountAndPersonaInsights
      ?.numberOfUnengagedAccounts ?? 0;

  return (
    <>
      <StatCardsContainer
        items={[
          {
            isLoading: isLoadingLifts,
            title: "Target Accounts",
            value: dataLifts?.targetAccounts,
            valueFormatter: (value) => toNumberDisplayValue(value),
            titleTooltip:
              "Total list of accounts Intentsify monitors for intent activity and programmatic targeting.",
          },
          {
            isLoading: isLoadingLifts,
            title: "Accounts Reached",
            value: dataLifts?.accountsReached,
            previousPeriodChange: dataLifts?.accountsReachedPreviousPeriod,
            valueFormatter: (value) => toNumberDisplayValue(value),
            titleTooltip:
              "Accounts that have been served an ad programmatically.",
          },
          {
            isLoading: isLoadingLifts,
            title: "Accounts Engaged",
            value: dataLifts?.accountsEngaged,
            previousPeriodChange: dataLifts?.accountsEngagedPreviousPeriod,
            valueFormatter: (value) => toNumberDisplayValue(value),
            titleTooltip:
              "Accounts that have interacted with a programmatic ad.",
          },
          {
            isLoading: isLoadingLifts,
            title: "Account Engagement Rate",
            value: dataLifts?.accountEngagementRate,
            previousPeriodChange:
              dataLifts?.accountEngagementRatePreviousPeriod,
            valueFormatter: (value) =>
              `${toNumberDisplayValue(decimalToPercentage(value))}%`,
            titleTooltip: "The rate at which accounts have engaged with an ad.",
          },
          {
            isLoading: isLoadingLifts,
            title: "Accounts With Conversions",
            value: dataLifts?.accountsWithConversion,
            previousPeriodChange:
              dataLifts?.accountsWithConversionPreviousPeriod,
            valueFormatter: (value) => toNumberDisplayValue(value),
            titleTooltip: "An account that has converted based on the KPI.",
            hasData: statisticsData?.totalConversions !== 0,
            noDataMessage: NOT_A_CPA_CAMPAIGN_MESSAGE,
            noDataIcon: FaHashtag,
          },
        ]}
      />

      <Flex w="100%" gap={4}>
        <Card
          onExpandedChange={setIsExpandedReached}
          isLoading={isLoadingLifts}
          title={reachedOverTimeChart.title}
          isExpandable
          actions={
            <ChartExport
              size="small"
              dates={dates}
              campaignId={Number(id)}
              title={reachedOverTimeChart.title}
              onExportPNG={reachedOverTimeChart.downloadAsPng}
              data={dataLifts?.accountsReachedOverTime ?? []}
            />
          }
        >
          {reachedOverTimeChart.component}
          {reachedOverTimeChart.exportComponent}
        </Card>

        <Card
          onExpandedChange={setIsExpandedInBuyerResearchStage}
          isLoading={isLoadingBuyerResearchStageData}
          title={accountsInBuyerResearchStage.title}
          isExpandable
          actions={
            <ChartExport
              size="small"
              dates={dates}
              campaignId={Number(id)}
              title={accountsInBuyerResearchStage.title}
              onExportPNG={accountsInBuyerResearchStage.downloadAsPng}
              data={(
                buyerResearchStageData?.accountsInBuyerResearchStage ?? []
              ).map((i) => ({
                value: i.countAccounts,
                label: i.buyerResearchStage,
              }))}
            />
          }
        >
          {accountsInBuyerResearchStage.component}
          {accountsInBuyerResearchStage.exportComponent}
        </Card>

        <Card
          onExpandedChange={setIsExpandedMovement}
          isLoading={isLoadingEngagementData}
          title={accountMovement.title}
          isExpandable
          actions={
            <ChartExport
              size="small"
              dates={dates}
              campaignId={Number(id)}
              title={accountMovement.title}
              onExportPNG={accountMovement.downloadAsPng}
              data={formattedEngagementData}
              disableCSVExport
            />
          }
        >
          {accountMovement.component}
          {accountMovement.exportComponent}
        </Card>
      </Flex>
      <Flex w="100%" gap={4} marginY={4}>
        <ErrorBoundary>
          <EngagementAcrossPersonas campaignId={id} filters={filters} />
        </ErrorBoundary>
        <Card
          isLoading={isLoadingAccountAndPersonasInsightsData}
          title={"Account & Persona Insights"}
        >
          <Box fontSize="sm" padding={2} color={insightsColor}>
            {accountAndPersonaInsightsData?.accountAndPersonaInsights ? (
              <UnorderedList spacing={3}>
                {accountAndPersonaInsightsData?.accountAndPersonaInsights
                  ?.mostEngagedPersona && (
                  <ListItem>
                    <Text>
                      The{" "}
                      {
                        accountAndPersonaInsightsData?.accountAndPersonaInsights
                          .mostEngagedPersona
                      }{" "}
                      Persona is more likely to engage with your digital ads.
                    </Text>
                  </ListItem>
                )}
                {numberOfUnengagedAccounts >=
                  MIN_NUMBER_OF_ACCOUNTS_FOR_INSIGHTS && (
                  <ListItem>
                    <Text>
                      There are {numberOfUnengagedAccounts} accounts that have
                      yet to engage with your creative ads - we recommend
                      optimizing your campaign by reallocating spend from top
                      domains that are not capturing engagements to top
                      performing accounts as well as moving budget into mobile
                      devices.
                    </Text>
                  </ListItem>
                )}
                <ListItem>
                  <Text>
                    We've reached{" "}
                    {decimalToPercentage(
                      accountAndPersonaInsightsData?.accountAndPersonaInsights
                        ?.accountsReached || 0
                    )}
                    % of your Target Account List,{" "}
                    {decimalToPercentage(
                      accountAndPersonaInsightsData?.accountAndPersonaInsights
                        ?.accountsEngaged || 0
                    )}
                    % of those accounts engaged with your digital ads.
                  </Text>
                </ListItem>
              </UnorderedList>
            ) : (
              <NoData height="250px" />
            )}
          </Box>
        </Card>
      </Flex>
      <Flex w="100%" gap={4} marginY={4}>
        <Card
          onExpandedChange={setIsExpandedIntegratedCampaignReport}
          isLoading={isLoadingCampaignIntegratedReportData}
          title={"Integrated Campaign Report"}
          isExpandable
        >
          {integratedCampaignReport.component}
          {integratedCampaignReport.exportComponent}
        </Card>
      </Flex>

      <Grid>
        {getShouldShowTopAccounts(companyId) && (
          <TopAccounts
            campaignName={campaignName}
            campaignId={id}
            filters={filters}
          />
        )}
      </Grid>
    </>
  );
};

const getShouldShowTopAccounts = (companyId?: number | string) => {
  if (!companyId) {
    return false;
  }

  // https://intentsify.atlassian.net/browse/IP-2405
  // We don't want to show the top accounts for the following companies
  if (companyId === 276) {
    return false;
  }

  return true;
};

export { AccountsPerformance };
