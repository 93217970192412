import { CompaniesScreen } from "types";
import { createScreen } from "utils/createScreen";

const CompaniesScreenDefinition = createScreen({
  name: CompaniesScreen,
  path: "/admin/companies",
  routePath: ["/"],
  accessPath: "company.edit",
});

export { CompaniesScreenDefinition };
