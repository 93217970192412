import { ViewContainer } from "components";
import { useScreen } from "utils";
import { YourCampaignsScreenDefinition } from "../CampaignsWizard/YourCampaigns.definition";
import { CampaignsTable } from "./CampaignsTable";

const YourCampaigns = () => {
  useScreen(YourCampaignsScreenDefinition);

  return (
    <ViewContainer>
      <CampaignsTable />
    </ViewContainer>
  );
};

export { YourCampaigns };
