import {
  BlacklistedFirmographic,
  CampaignDomainSource,
  Domain,
  DomainsData,
  DomainStatus,
  Firmographic,
} from "@intentsify/types";
import uniqBy from "lodash/uniqBy";
import { useMemo } from "react";

type CombinedCsvDownloadDomains = {
  domain: string;
};

type UseCombineDomainsReturnType = {
  combinedSelectedDomains: Domain[];
  combinedCsvDownloadDomains: CombinedCsvDownloadDomains[];
  firmographicsMinusBlacklisted: Array<Firmographic | Partial<Firmographic>>;
};

const useCombineDomains = (
  selectedFirmographics: Array<Firmographic | Partial<Firmographic>>,
  blacklistedFirmographics: BlacklistedFirmographic[],
  uploadedDomains: DomainsData
): UseCombineDomainsReturnType => {
  return useMemo(() => {
    const blacklisted = blacklistedFirmographics.map((b) => b.domain);
    const firmographicsMinusBlacklisted = selectedFirmographics.filter(
      (i) => !blacklisted.includes(String(i.domain))
    );

    const combined = uniqBy(
      [
        ...(uploadedDomains?.domains || []),
        ...firmographicsMinusBlacklisted.map((item) => {
          const domain: Domain = {
            domainId: undefined,
            displayName: String(item.domain),
            domainStatus: DomainStatus.Good,
            source: CampaignDomainSource.Firmographic,
          };
          return domain;
        }),
      ],
      (item) => item.displayName
    );

    return {
      combinedSelectedDomains: combined,
      combinedCsvDownloadDomains: combined.map((d) => ({
        domain: d.displayName,
      })),
      firmographicsMinusBlacklisted,
    };
  }, [uploadedDomains, blacklistedFirmographics, selectedFirmographics]);
};

export { useCombineDomains };
