import { useCallback, useEffect, useMemo, useState } from "react";
import { UseTokenPagination } from "../Table.types";

const useTokenPagination = (pageNumber = 1): UseTokenPagination => {
  const [mapping, setMapping] = useState<{ [key: number]: string }>({});

  const updateToken = useCallback(
    (nextToken: string) => {
      setMapping((m) => ({
        ...m,
        [pageNumber + 1]: nextToken,
      }));
    },
    [pageNumber]
  );

  const useUpdateToken = useCallback(
    function useUpdateToken(nextToken: string) {
      useEffect(() => updateToken(nextToken), [nextToken]);
    },
    [updateToken]
  );

  return useMemo(
    () => ({
      currentToken: mapping[pageNumber],
      useUpdateToken,
      updateToken,
      hasToken: (pageNumber: number) => pageNumber in mapping,
    }),
    [mapping, pageNumber, updateToken, useUpdateToken]
  );
};

export { useTokenPagination };
