import { useMutation, useQueryClient } from "@tanstack/react-query";
import { disableImpersonationMode } from "api";
import { useNavigate } from "react-router";
import { YourCampaignsScreenDefinition } from "screens";
import { ImpersonationTransitionDefinition } from "screens/ImpersonationTransition/ImpersonationTransition.definition";
import { useAppStore } from "store/useAppStore";
import { isCustomApiError } from "types";

export const useDisableImpersonationModeMutation = () => {
  const client = useQueryClient();
  const navigate = useNavigate();
  const setImpersonation = useAppStore.use.setImpersonation();
  const setIsProcessingImpersonation =
    useAppStore.use.setIsImpersonationProcessing();

  return useMutation(disableImpersonationMode, {
    onMutate: () => {
      setIsProcessingImpersonation(true);
      navigate(ImpersonationTransitionDefinition.navigate());
    },
    onSettled: () => {
      // Add some delay so the screen transition isn't too jarring
      setTimeout(() => {
        setImpersonation(null);
        client.clear();
        navigate(YourCampaignsScreenDefinition.navigate());
        setIsProcessingImpersonation(false);
      }, 1000);
    },
    onError: (err) => {
      if (
        isCustomApiError(err) &&
        err.response.internalCode ===
          "400CannotDisableImpersonationNotImpersonated"
      ) {
        /**
         * This error is expected when user has impersonation on multiple tabs, exits on one tab
         * and tries to exit on another tab. When it happens, onSettled will handle it without
         * interupting the user.
         */
        return;
      }
    },
  });
};
