import { apiService, Endpoints } from "api";

interface UpdateAgreementParams {
  userId: number;
  agreement: boolean;
}

export const updateAgreement = async ({
  userId,
  agreement,
}: UpdateAgreementParams) => {
  const { data } = await apiService.put(Endpoints.Users.Put.Agreement(userId), {
    agreement,
  });

  return data;
};
