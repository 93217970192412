import { useMemo } from "react";
import { TagCampaign } from "./ProviderCard";

const useIds = (campaigns: TagCampaign[]) => {
  return useMemo(
    () => new Set((campaigns || []).map((obj) => obj.campaignId)),
    [campaigns]
  );
};

export type CampaignsDiff = { added: TagCampaign[]; deleted: TagCampaign[] };

export const useCampaignsDiff = (
  initialCampaigns: TagCampaign[],
  finalCampaigns: TagCampaign[]
): CampaignsDiff => {
  const initialIds = useIds(initialCampaigns);
  const finalIds = useIds(finalCampaigns);

  const added: TagCampaign[] = useMemo(() => {
    const added = [];

    for (const obj of finalCampaigns || []) {
      if (!initialIds.has(obj.campaignId)) {
        added.push(obj);
      }
    }

    return added;
  }, [finalCampaigns, initialIds]);

  const deleted: TagCampaign[] = useMemo(() => {
    const deleted = [];

    for (const obj of initialCampaigns || []) {
      if (!finalIds.has(obj.campaignId)) {
        deleted.push(obj);
      }
    }

    return deleted;
  }, [finalIds, initialCampaigns]);

  return useMemo(() => ({ added, deleted }), [added, deleted]);
};
