import {
  Box,
  Flex,
  Grid,
  GridItem,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { Option } from "@intentsify/types";
import { useQuery } from "@tanstack/react-query";
import {
  BarChart,
  Card,
  Loader,
  PieChart,
  ScreenBar,
  ViewContainer,
} from "components";
import { DateTime } from "luxon";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { NoEntitySelected } from "screens/CampaignPacing/components";
import {
  CampaignSelector,
  ChartExport,
  useCampaignMeta,
  useCampaignSelectorSelectedCampaign,
  useExportableChart,
} from "shared/components";
import { useScreen } from "utils";
import { CampaignLeadPerformanceScreenDefinition } from "./CampaignLeadPerformance.definition";
import { getLeadPerformance } from "./CampaignLeadPerformance.requests";
import { Filters } from "./Filters";

export type PerformanceFilters = {
  usStates: Option<number>[];
  jobLevels: Option<string>[];
  industries: Option<string>[];
  startWeekNumber: number;
  startYearNumber: number;
  endWeekNumber: number;
  endYearNumber: number;
};

const CampaignLeadPerformance = () => {
  useScreen(CampaignLeadPerformanceScreenDefinition);
  const navigate = useNavigate();
  const campaignId = Number(useParams<"campaignId">().campaignId);

  const topbarColor = useColorModeValue("white", "brand.375");
  const colorGrey = useColorModeValue("gray.25", "brand.375");

  const { selected, setSelected } =
    useCampaignSelectorSelectedCampaign(campaignId);

  const { campaignMeta, campaignMetaIsLoading } = useCampaignMeta({
    campaignId,
  });

  const [filters, setFilters] = useState<PerformanceFilters | undefined>(
    undefined
  );

  useEffect(() => {
    if (!campaignMeta) {
      return;
    }

    const defaultStartWeek = {
      start: DateTime.fromISO(campaignMeta.campaignCreateDate)
        .startOf("week")
        .toJSDate(),
      end: DateTime.fromISO(campaignMeta.campaignCreateDate)
        .endOf("week")
        .toJSDate(),
    };

    const defaultEndWeek = {
      start: DateTime.now().minus({ weeks: 1 }).startOf("week").toJSDate(),
      end: DateTime.now().minus({ weeks: 1 }).endOf("week").toJSDate(),
    };

    setFilters({
      jobLevels: [],
      usStates: [],
      industries: [],
      startWeekNumber: DateTime.fromJSDate(defaultStartWeek.start).weekNumber,
      startYearNumber: DateTime.fromJSDate(defaultStartWeek.start).year,
      endWeekNumber: DateTime.fromJSDate(defaultEndWeek.start).weekNumber,
      endYearNumber: DateTime.fromJSDate(defaultEndWeek.start).year,
    });
  }, [campaignMeta, setFilters]);

  const { data, isFetching } = useQuery(
    ["leadPerformance", campaignId, filters],
    () => {
      if (campaignId && filters) {
        return getLeadPerformance({ campaignId: campaignId, filters });
      }
    },
    { enabled: Boolean(campaignId && filters) }
  );

  const dates = useMemo(() => {
    if (!filters) {
      return {
        start: DateTime.utc().toISO(),
        end: DateTime.utc().toISO(),
      };
    }

    return {
      start: DateTime.fromObject({
        weekNumber: filters.startWeekNumber,
        weekYear: filters.startYearNumber,
      }).toISO(),
      end: DateTime.fromObject({
        weekNumber: filters.endWeekNumber,
        weekYear: filters.endYearNumber,
      }).toISO(),
    };
  }, [filters]);

  const isLoading = isFetching || campaignMetaIsLoading;

  const companySizeChart = useExportableChart({
    title: "Leads by company size",
    campaignId: campaignId,
    dates,
    chart: (
      <PieChart
        stretch
        legendPosition="right"
        isLoading={isLoading}
        data={data?.byCompanySize ?? []}
      />
    ),
  });

  const jobFunctionChart = useExportableChart({
    title: "Leads by job function",
    campaignId: campaignId,
    dates,
    chart: (
      <BarChart
        stretch
        orientation={
          (data?.byJobFunction ?? []).length > 5 ? "horizontal" : "vertical"
        }
        isLoading={isLoading}
        data={data?.byJobFunction ?? []}
        valueAxisLabel={"Sum of Leads Delivered"}
      />
    ),
  });

  const jobLevelChart = useExportableChart({
    title: "Leads by job level",
    campaignId: campaignId,
    dates,
    chart: (
      <BarChart
        stretch
        orientation={
          (data?.byJobLevel ?? []).length > 5 ? "horizontal" : "vertical"
        }
        isLoading={isLoading}
        data={data?.byJobLevel ?? []}
        valueAxisLabel={"Sum of Leads Delivered"}
      />
    ),
  });

  const byIndustryChart = useExportableChart({
    title: "Leads by industry",
    campaignId: campaignId,
    dates,
    chart: (
      <BarChart
        stretch
        orientation={
          (data?.byIndustry ?? []).length > 5 ? "horizontal" : "vertical"
        }
        isLoading={isLoading}
        data={data?.byIndustry ?? []}
        valueAxisLabel={"Sum of Leads Delivered"}
      />
    ),
  });

  const byAssetChart = useExportableChart({
    title: "Leads by asset",
    campaignId: campaignId,
    dates,
    chart: (
      <BarChart
        stretch
        orientation={
          (data?.byAsset ?? []).length > 5 ? "horizontal" : "vertical"
        }
        isLoading={isLoading}
        data={data?.byAsset ?? []}
        valueAxisLabel={"Sum of Leads Delivered"}
      />
    ),
  });

  const onCampaignChange = (c: Option<number> | undefined) => {
    setFilters(undefined);
    setSelected(c);

    if (!c) {
      navigate(
        CampaignLeadPerformanceScreenDefinition.navigate({
          campaignId: undefined,
        })
      );
    } else {
      navigate(
        CampaignLeadPerformanceScreenDefinition.navigate({
          campaignId: Number(c.value),
        })
      );
    }
  };

  return (
    <ViewContainer withBackground noPadding stretch>
      <Box bg={topbarColor}>
        <ScreenBar
          justifyContent="space-between"
          filters={[
            {
              width: "400",
              node: (
                <Text fontWeight={"bold"} marginRight={3}>
                  {selected?.label || "Overall Lead Performance"}
                </Text>
              ),
            },
            {
              label: "Intent Models",
              width: "100%",
              node: (
                <CampaignSelector
                  selected={selected}
                  onChange={onCampaignChange}
                  params={{ hasLeads: true }}
                />
              ),
            },
          ]}
        />
      </Box>

      {!campaignId && (
        <ViewContainer>
          <NoEntitySelected entityName="campaign" />
        </ViewContainer>
      )}

      {campaignId && (
        <>
          {campaignMetaIsLoading && (
            <ViewContainer>
              <Loader />
            </ViewContainer>
          )}

          {!campaignMetaIsLoading && filters && (
            <>
              <Flex
                direction="column"
                borderTop={2}
                borderTopStyle={"solid"}
                borderTopColor={colorGrey}
              >
                <Box px={2} bg={topbarColor} flex={1}>
                  <Filters selectedFilters={filters} onChange={setFilters} />
                </Box>

                <Box p={4}>
                  <Flex gap={4} direction="column">
                    <Flex gap={4}>
                      <Card
                        title={companySizeChart.title}
                        isExpandable
                        actions={
                          <ChartExport
                            size="small"
                            dates={dates}
                            campaignId={campaignId}
                            title={companySizeChart.title}
                            onExportPNG={companySizeChart.downloadAsPng}
                            data={data?.byCompanySize}
                          />
                        }
                      >
                        {companySizeChart.component}
                        {companySizeChart.exportComponent}
                      </Card>

                      <Card
                        title={jobFunctionChart.title}
                        isExpandable
                        actions={
                          <ChartExport
                            size="small"
                            dates={dates}
                            campaignId={campaignId}
                            title={jobFunctionChart.title}
                            onExportPNG={jobFunctionChart.downloadAsPng}
                            data={data?.byJobFunction}
                          />
                        }
                      >
                        {jobFunctionChart.component}
                        {jobFunctionChart.exportComponent}
                      </Card>
                    </Flex>
                    <Grid
                      gap={4}
                      gridTemplateColumns="repeat(auto-fit, minmax(400px, 1fr))"
                    >
                      <GridItem>
                        <Card
                          title={jobLevelChart.title}
                          isExpandable
                          actions={
                            <ChartExport
                              size="small"
                              dates={dates}
                              campaignId={campaignId}
                              title={jobLevelChart.title}
                              onExportPNG={jobLevelChart.downloadAsPng}
                              data={data?.byJobLevel}
                            />
                          }
                        >
                          {jobLevelChart.component}
                          {jobLevelChart.exportComponent}
                        </Card>
                      </GridItem>

                      <GridItem>
                        <Card
                          title={byIndustryChart.title}
                          isExpandable
                          actions={
                            <ChartExport
                              size="small"
                              dates={dates}
                              campaignId={campaignId}
                              title={byIndustryChart.title}
                              onExportPNG={byIndustryChart.downloadAsPng}
                              data={data?.byIndustry}
                            />
                          }
                        >
                          {byIndustryChart.component}
                          {byIndustryChart.exportComponent}
                        </Card>
                      </GridItem>

                      <GridItem>
                        <Card
                          title={byAssetChart.title}
                          isExpandable
                          actions={
                            <ChartExport
                              size="small"
                              dates={dates}
                              campaignId={campaignId}
                              title={byAssetChart.title}
                              onExportPNG={byAssetChart.downloadAsPng}
                              data={data?.byAsset}
                            />
                          }
                        >
                          {byAssetChart.component}
                          {byAssetChart.exportComponent}
                        </Card>
                      </GridItem>
                    </Grid>
                  </Flex>
                </Box>
              </Flex>
            </>
          )}
        </>
      )}
    </ViewContainer>
  );
};

export { CampaignLeadPerformance };
