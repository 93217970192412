import { useHasAccessTo } from "@intentsify/authorization/dist/react";
import {
  TargetPersonaDynamic1,
  TargetPersonaKeyword,
  TargetPersonaTopic,
} from "@intentsify/types";
import { useQuery } from "@tanstack/react-query";
import { apiService, Endpoints } from "api";
import { AvailableSignalTypeResponse } from "screens/IntentAnalysis/screens/CampaignsTrendingDomains/components/SingleAccountDetails/SingleAccountDetails.types";

export const useBuyingGroupPersonaAvailableSignals = (options: {
  accountId: number;
  campaignId: number;
}) => {
  const hasAccess = useHasAccessTo("buyingGroup.insights");

  return useQuery({
    suspense: true,
    enabled: hasAccess,
    queryKey: ["buyingGroupAvailableSignals", options],
    queryFn: async () => {
      const { data } = await apiService.get<
        AvailableSignalTypeResponse[] | null
      >(
        Endpoints.Campaigns.Get.BuyingGroupAvailableSignals(
          options.campaignId,
          options.accountId
        )
      );

      return data;
    },
  });
};

export const useTopics = (options: {
  accountId: number;
  campaignId: number;
}) => {
  return useQuery({
    suspense: true,
    queryKey: ["buyingGroupTopics", options],
    queryFn: async () => {
      const { data } = await apiService.get<TargetPersonaTopic[]>(
        Endpoints.Campaigns.Get.BuyingGroupTopics(
          options.campaignId,
          options.accountId
        )
      );

      return data;
    },
  });
};

export const useKeywords = (options: {
  accountId: number;
  campaignId: number;
}) => {
  return useQuery({
    suspense: true,
    queryKey: ["buyingGroupKeywords", options],
    queryFn: async () => {
      const { data } = await apiService.get<TargetPersonaKeyword[]>(
        Endpoints.Campaigns.Get.BuyingGroupKeywords(
          options.campaignId,
          options.accountId
        )
      );

      return data;
    },
  });
};

export const useDynamic1 = (options: {
  accountId: number;
  campaignId: number;
}) => {
  return useQuery({
    suspense: true,
    queryKey: ["buyingGroupDynamic1", options],
    queryFn: async () => {
      const { data } = await apiService.get<TargetPersonaDynamic1[]>(
        Endpoints.Campaigns.Get.BuyingGroupDynamic1(
          options.campaignId,
          options.accountId
        )
      );

      return data;
    },
  });
};

export const useListAllPersonas = (campaignId: number, accountId: number) => {
  return useQuery({
    suspense: true,
    queryKey: ["listAllPersonas", campaignId, accountId],
    queryFn: async () => {
      const { data } = await apiService.get<{ persona: string }[]>(
        Endpoints.Campaigns.Get.BuyingGroupListAllPersonas(
          campaignId,
          accountId
        )
      );

      return data.map(({ persona }) => ({
        value: persona,
        label: persona,
      }));
    },
  });
};
