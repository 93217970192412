import { ColumnDef, RowData } from "@tanstack/react-table";
import { useMemo } from "react";
import { Selectable } from "./TableVirtualised";
import { useSelectColumn } from "./useSelectColumn";

interface UseColumnsOptions<Data extends RowData> {
  selectable: Selectable | boolean;
  selectColumnProps?: Partial<ColumnDef<Data>>;
}

export const useColumns = <Data extends RowData>(
  unMemoisedColumns: ColumnDef<Data>[],
  options?: UseColumnsOptions<Data>
) => {
  const selectColumn = useSelectColumn<Data>(options?.selectColumnProps);
  const columns = useMemo<ColumnDef<Data>[]>(() => {
    const finalColumns = [...unMemoisedColumns];

    if (
      (typeof options?.selectable === "boolean" &&
        options?.selectable === true) ||
      (typeof options?.selectable === "string" &&
        options.selectable === "checkbox-multi-select")
    ) {
      finalColumns.unshift(selectColumn);
    }

    return finalColumns;
  }, [unMemoisedColumns, options?.selectable, selectColumn]);

  return columns;
};
