import { EditUserScreen } from "types";
import { createScreen } from "utils/createScreen";

const EditUserScreenDefinition = createScreen({
  name: EditUserScreen,
  path: "/admin/users/edit/:id",
  routePath: ["/edit/:id"],
  accessPath: "user.edit",
});

export { EditUserScreenDefinition };
