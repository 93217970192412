import { Box, Divider, Flex } from "@chakra-ui/layout";
import { Heading } from "@chakra-ui/react";
import { TempAccountFileDTO } from "@intentsify/dto";
import { GenericUserJobType } from "@intentsify/types";
import { isPopulatedArray } from "@intentsify/utils";
import { useState } from "react";
import { isTempAccountFileDTO } from "screens/Campaigns/screens/CampaignsWizard/components/DomainsStep/DomainsForm";
import { DomainsUploadTus } from "shared/components/DomainsUploadTus/DomainsUploadTus";
import { UploadedFileEntry } from "shared/components/UploadedFiles/UploadedFileEntry";
import { UploadedFiles } from "shared/components/UploadedFiles/UploadedFiles";
import { UploadedFilesWrapper } from "shared/components/UploadedFiles/UploadedFilesWrapper";
import { talToolsUpload } from "upload/talToolsUpload";
import { useUploadedFiles } from "../../hooks/useUploadedFiles";
import { ResultsTable } from "../ResultsTable/ResultsTable";
import { BusinessDataAppendForm } from "./components/BusinessDataAppendForm";

export const BusinessDataAppend = () => {
  const [selectedFile, setSelectedFile] = useState<
    TempAccountFileDTO | undefined
  >();

  const { files, filesForList, setFiles } = useUploadedFiles();

  return (
    <Flex direction="column" gap="4">
      <Box>
        <Heading as="h2" size="sm" my="4">
          Instructions
        </Heading>
        <Box as="ol" fontSize="xs" ml="8">
          <li>
            Upload a CSV file with one column containing domains and a header
            row with single value "Domain".
          </li>

          <li>
            Select from the drop downs either/or firmographics, technographics,
            business events you need appended.
          </li>

          <li>Click "Process domains".</li>
          <li>
            You'll receive an email when the list has been processed containing
            the link to download.
          </li>
        </Box>
      </Box>

      <Box mt={4}>
        <DomainsUploadTus
          tusUpload={talToolsUpload}
          uploadedFileNames={files.map((i) => i.fileName)}
          onFileUpload={(tempFileName, fileName, validationResults) => {
            const newFile = {
              tempFileName,
              fileName,
              accountsCount: validationResults.validRowCount,
            };

            setFiles((prev) => {
              return [
                ...prev.filter((file) => file.fileName !== fileName),
                newFile,
              ];
            });

            setSelectedFile(newFile);
          }}
        />

        {isPopulatedArray(filesForList) && (
          <UploadedFilesWrapper variant="plain">
            <UploadedFiles hideUploadedAt selectable>
              {filesForList.map((uploadedFileEntryProps) => (
                <UploadedFileEntry
                  isSelected={
                    uploadedFileEntryProps.file.fileName ===
                    selectedFile?.fileName
                  }
                  isSelectable
                  onSelect={(file) => {
                    if (file && isTempAccountFileDTO(file)) {
                      setSelectedFile(file);
                    } else {
                      setSelectedFile(undefined);
                    }
                  }}
                  hideDownload
                  hideUploadedAt
                  key={uploadedFileEntryProps.file.fileName}
                  file={uploadedFileEntryProps.file}
                />
              ))}
            </UploadedFiles>
          </UploadedFilesWrapper>
        )}
      </Box>

      <BusinessDataAppendForm selectedFile={selectedFile} />

      <Divider />

      <ResultsTable toolType={GenericUserJobType.BUSINESS_DATA_APPEND} />
    </Flex>
  );
};
