import { ContactSearchResult } from "@intentsify/types";
import { ColumnDef } from "@tanstack/react-table";
import { EMPTY_CELL } from "components";
import { useMemo } from "react";

export const useColumns = () => {
  return useMemo<ColumnDef<ContactSearchResult>[]>(
    () => [
      {
        header: "Company Name",
        accessor: "companyName",
        cell: ({ row }) => {
          const { companyName } = row.original;

          if (!companyName) {
            return EMPTY_CELL;
          }

          return <>{companyName}</>;
        },
      },
      {
        header: "Company Domain",
        accessor: "companyDomain",
        cell: ({ row }) => {
          const { companyDomain } = row.original;

          if (!companyDomain) {
            return EMPTY_CELL;
          }

          return <>{companyDomain}</>;
        },
      },
      {
        header: "First Name",
        accessor: "firstName",
        cell: ({ row }) => {
          const { firstName } = row.original;

          if (!firstName) {
            return EMPTY_CELL;
          }

          return <>{firstName}</>;
        },
      },
      {
        header: "Last Name",
        accessor: "lastName",
        cell: ({ row }) => {
          const { lastName } = row.original;

          if (!lastName) {
            return EMPTY_CELL;
          }

          return <>{lastName}</>;
        },
      },
      {
        header: "Business Email",
        accessor: "businessEmail",
        cell: ({ row }) => {
          const { businessEmail } = row.original;

          if (!businessEmail) {
            return EMPTY_CELL;
          }

          return <>{businessEmail}</>;
        },
      },
      {
        header: "Mobile Phone",
        accessor: "mobilePhone",
        cell: ({ row }) => {
          const { mobilePhone } = row.original;

          if (!mobilePhone) {
            return EMPTY_CELL;
          }

          return <>{mobilePhone}</>;
        },
      },
      {
        header: "Direct Number",
        accessor: "directNumber",
        cell: ({ row }) => {
          const { directNumber } = row.original;

          if (!directNumber) {
            return EMPTY_CELL;
          }

          return <>{directNumber}</>;
        },
      },
      {
        header: "Job Title",
        accessor: "jobTitle",
        cell: ({ row }) => {
          const { jobTitle } = row.original;

          if (!jobTitle) {
            return EMPTY_CELL;
          }

          return <>{jobTitle}</>;
        },
      },
      {
        header: "Job Level",
        accessor: "jobLevel",
        cell: ({ row }) => {
          const { jobLevel } = row.original;

          if (!jobLevel) {
            return EMPTY_CELL;
          }

          return <>{jobLevel}</>;
        },
      },
      {
        header: "Job Function",
        accessor: "jobFunction",
        cell: ({ row }) => {
          const { jobFunction } = row.original;

          if (!jobFunction) {
            return EMPTY_CELL;
          }

          return <>{jobFunction}</>;
        },
      },
      {
        header: "Social URL",
        accessor: "socialUrl",
        cell: ({ row }) => {
          const { socialUrl } = row.original;

          if (!socialUrl) {
            return EMPTY_CELL;
          }

          return <>{socialUrl}</>;
        },
      },
    ],
    []
  );
};
