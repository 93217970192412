/* eslint-disable no-console */
import { useToast } from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getUserById } from "api";
import { Loader, ViewContainer } from "components";
import { useParams } from "react-router-dom";
import { useScreen } from "utils";
import { UserForm } from "../../components";
import { EditUserScreenDefinition } from "./EditUser.definition";
import { editUser } from "./EditUser.requests";

const EditUser = () => {
  useScreen(EditUserScreenDefinition);
  const { id: userId } = useParams<"id">();
  const queryClient = useQueryClient();
  const toast = useToast();

  if (!userId) {
    throw new Error("404");
  }

  const { data } = useQuery(["getUserById", Number(userId)], () =>
    getUserById(userId)
  );

  const { mutate, isLoading } = useMutation(editUser(Number(userId)), {
    onSuccess: (updatedUser) => {
      queryClient.setQueryData(["getUserById", Number(userId)], updatedUser);

      queryClient.invalidateQueries({ queryKey: ["getCampaignManagers"] });
      queryClient.invalidateQueries({
        queryKey: ["getCampaignRepresentatives"],
      });
      toast({
        title: "User has been updated.",
        status: "success",
      });
    },
  });

  if (!data?.user) {
    return (
      <ViewContainer>
        <Loader />
      </ViewContainer>
    );
  }

  const {
    businessUnits,
    companyId,
    displayName,
    email,
    saasRoleId,
    businessEntityId,
    isSalesRepresentative,
    isCampaignManager,
  } = data.user;

  return (
    <ViewContainer>
      <UserForm
        initialState={{
          businessUnits,
          companyId,
          displayName,
          email,
          saasRoleId,
          businessEntityId,
          isSalesRepresentative,
          isCampaignManager,
        }}
        isLoading={isLoading}
        onSubmit={mutate}
        submitLabel="Save Changes"
      />
    </ViewContainer>
  );
};

export { EditUser };
