import { Alert, Flex, Text } from "@chakra-ui/react";
import { CampaignDomainEnhancementStatus } from "@intentsify/types";
import { isTalProcessing } from "@intentsify/utils";
import { Endpoints } from "api";
import { SomethingWrong } from "components";
import { PropsWithChildren } from "react";
import { MdOutlineError, MdOutlineSchedule } from "react-icons/md";
import { useParams } from "react-router";
import { useCampaign } from "screens/Campaigns/screens/CampaignsWizard/CampaignsWizard.hooks";
import { DomainsValidation } from "shared/components";
import { UploadedFileEntry } from "shared/components/UploadedFiles/UploadedFileEntry";
import { UploadedFiles } from "shared/components/UploadedFiles/UploadedFiles";
import { UploadedFilesWrapper } from "shared/components/UploadedFiles/UploadedFilesWrapper";
import { useDownloadFile } from "utils";

const SummaryWrapper = ({ children }: PropsWithChildren) => {
  return (
    <Flex mt={8} flexDir="column" position="relative">
      <Alert
        position="static"
        display="block"
        variant="top-accent"
        status="info"
        px={4}
        py={4}
      >
        <Text fontSize="lg">Summary</Text>
        {children}
      </Alert>
    </Flex>
  );
};

const Summary = () => {
  const campaignId = Number(useParams<"campaignId">().campaignId);
  const { download } = useDownloadFile();
  const { campaign } = useCampaign({ campaignId: Number(campaignId) });

  if (!campaign) {
    return;
  }

  if (campaign.state.includes("error")) {
    return (
      <SummaryWrapper>
        <Flex
          height="200px"
          width="full"
          align="center"
          justifyContent="center"
          textAlign="center"
          mb="30px"
        >
          <Flex flexDir="column" alignItems={"center"} gap={4}>
            <MdOutlineError size="36px" />
            <SomethingWrong />
          </Flex>
        </Flex>
      </SummaryWrapper>
    );
  }

  if (
    isTalProcessing(campaign.state) ||
    campaign.domainEnhancementStatus ===
      CampaignDomainEnhancementStatus.PROCESSING
  ) {
    return (
      <SummaryWrapper>
        <Flex
          height="200px"
          width="full"
          align="center"
          justifyContent="center"
          textAlign="center"
          mb="30px"
        >
          <Flex flexDir="column" alignItems={"center"}>
            <MdOutlineSchedule size="36px" />
            <Text mt={4} display="block" textAlign="center" maxWidth="500px">
              Accounts summary will be available after processing is complete.
              It usually takes up to 15 minutes.
            </Text>
          </Flex>
        </Flex>
      </SummaryWrapper>
    );
  }

  if (!campaign.accounts.accountsSummary) {
    return;
  }

  return (
    <>
      <SummaryWrapper>
        <DomainsValidation
          accountsSummary={campaign.accounts.accountsSummary}
          domainEnhancementStrategy={
            campaign.accounts.domainEnhancementStrategy
          }
          onDownload={(type) => {
            download({
              url: Endpoints.Campaigns.Get.Domains.DownloadTALSummary(
                campaignId
              ),
              params: {
                type,
              },
              fileName: `tal_${campaignId}_${type}.csv`,
            });
          }}
        />
      </SummaryWrapper>
      <UploadedFilesWrapper variant="blue">
        <UploadedFiles>
          <UploadedFileEntry
            file={{
              fileName: `tal_${campaignId}.csv`,
              accountsCount:
                campaign.accounts.accountsSummary.totalAccountsCount,
            }}
            onDownloadClick={() => {
              download({
                url: Endpoints.Campaigns.Get.Domains.DownloadTAL(campaignId),
                fileName: `tal_${campaignId}.csv`,
              });
            }}
          />
        </UploadedFiles>
      </UploadedFilesWrapper>
    </>
  );
};

export { Summary };
