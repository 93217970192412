import { useToken } from "@chakra-ui/system";
import { LayerProps } from "react-map-gl";
import { LayerVisibility } from "../ImpressionsAndTopicsMap.types";

export const useImpressionsPointsLayerProps = (
  max: number,
  visibility: LayerVisibility
): LayerProps => {
  const circleColor = useToken("colors", "teal.400");

  return {
    layout: {
      visibility: visibility,
    },
    type: "circle",
    filter: ["!", ["has", "point_count"]],
    paint: {
      "circle-radius": [
        "interpolate",
        ["linear"],
        ["zoom"],
        0,
        ["interpolate", ["linear"], ["get", "count"], 0, 1, max, 5],
        14,
        ["interpolate", ["linear"], ["get", "count"], 0, 10, max, 30],
      ],
      "circle-color": circleColor,
    },
  };
};
