import { Text } from "@chakra-ui/react";

const SomethingWrong = () => {
  return (
    <Text fontSize="lg" fontWeight="bold">
      Something went wrong. Our engineers have been notified about the problem.
    </Text>
  );
};

export { SomethingWrong };
