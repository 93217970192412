import {
  BatchDeleteReviewedAssetsClientDTO,
  ListReviewedAssetsRO,
} from "@intentsify/dto";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Endpoints, apiService } from "api";

export const useDeleteReviewedAssets = (
  removeSelection: (id: string | number) => void
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["contentLibrary", "reviewedAssets", "delete"],
    mutationFn: async (
      reviewedAssetIds: BatchDeleteReviewedAssetsClientDTO["reviewedAssetIds"]
    ) => {
      await apiService.delete(
        Endpoints.ContentLibrary.ReviewedAssets.Delete.Batch,
        { data: { reviewedAssetIds } }
      );
    },
    onSuccess: (data, reviewedAssetIds) => {
      reviewedAssetIds.forEach((id) => removeSelection(id));

      queryClient.setQueriesData<ListReviewedAssetsRO>(
        { queryKey: ["contentLibrary", "reviewedAssets"], exact: false },
        (oldData) => ({
          ...oldData,
          reviewedAssets:
            oldData?.reviewedAssets?.filter(
              (asset) => !reviewedAssetIds.includes(asset.id)
            ) || [],
        })
      );
    },
  });
};
