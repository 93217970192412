import { Text, useDisclosure } from "@chakra-ui/react";
import { ReviewedAsset } from "@intentsify/types";
import { pluralize } from "@intentsify/utils";
import { Button } from "components";
import { useConfirm } from "components/ConfirmDialog/useConfirm";
import {
  ContentLibraryModal,
  ContentLibraryModalBody,
  ContentLibraryModalHeader,
} from "../Modal";
import { CompletedAssetForm } from "./CompletedAssetForm";
import { useUpdateReviewedAssets } from "./useUpdateReviewedAssets";

export const BatchUpdateModal = (props: {
  reviewedAssets: ReviewedAsset[];
}) => {
  const confirm = useConfirm();
  const disclosure = useDisclosure();
  const updateReviewedAssets = useUpdateReviewedAssets(disclosure.onClose);

  return (
    <>
      <ContentLibraryModal {...disclosure}>
        <ContentLibraryModalHeader>
          Bulk edit {props.reviewedAssets.length}{" "}
          {pluralize(props.reviewedAssets.length, "asset", "assets")}
          <Text
            fontSize="sm"
            mt="2"
            color="blackAlpha.700"
            _dark={{ color: "whiteAlpha.700" }}
          >
            {props.reviewedAssets.map((r) => r.name).join(", ")}
          </Text>
        </ContentLibraryModalHeader>

        <ContentLibraryModalBody>
          <CompletedAssetForm
            omitName
            optional
            isLoading={updateReviewedAssets.isLoading}
            onSubmit={async (data) => {
              const assets = pluralize(
                props.reviewedAssets.length,
                "asset",
                "assets"
              );

              if (
                !(await confirm({
                  title: "Do you really want to bulk edit?",
                  description: `You are about to update ${props.reviewedAssets.length} ${assets}. This action cannot be undone.`,
                  confirmText: `Yes, update ${props.reviewedAssets.length} ${assets}`,
                }))
              ) {
                return;
              }

              updateReviewedAssets.mutate(
                props.reviewedAssets.map((r) => ({
                  ...data,
                  id: r.id,
                  name: r.name,
                }))
              );
            }}
          />
        </ContentLibraryModalBody>
      </ContentLibraryModal>

      <Button onClick={disclosure.onOpen}>
        Update {props.reviewedAssets.length}{" "}
        {pluralize(props.reviewedAssets.length, "asset", "assets")}
      </Button>
    </>
  );
};
