import { BusinessEventsFiltersRO } from "@intentsify/dto";
import { useQuery } from "@tanstack/react-query";
import { Endpoints, apiService } from "api";

export const useBusinessEvents = () => {
  return useQuery(["useBusinessEvents"], async () => {
    const response = await apiService.get<BusinessEventsFiltersRO>(
      Endpoints.TalTools.BusinessDataAppend.Get.BusinessEventsFilters
    );
    return response.data;
  });
};
