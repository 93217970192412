import { isPopulatedArray } from "@intentsify/utils";
import { ColumnDef } from "@tanstack/react-table";
import { Table } from "components";
import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { useComponentColors } from "theme";
import { topPerformingDomainsStateSelector } from "../../TopPerformingDomains.state";
import { TopPerformingDomainsWithTimeseriesResponse } from "../../TopPerformingDomains.types";
import { getCampaignsTopChangesColumns } from "./TopPerformingDomainsTable.columns";

type TopPerformingDomainsTableProps = {
  data: TopPerformingDomainsWithTimeseriesResponse | undefined;
  isLoading: boolean;
};

const TopPerformingDomainsTable = ({
  data,
  isLoading,
}: TopPerformingDomainsTableProps) => {
  const { selectedDomains } = useRecoilValue(topPerformingDomainsStateSelector);

  const {
    form: { stackBg },
  } = useComponentColors();

  const columns = useMemo<ColumnDef<any>[]>(
    () => (data ? getCampaignsTopChangesColumns(data?.timeseries) : []),
    [data]
  );

  const tableData = data ? data.data : [];

  return (
    <Table
      stretch={true}
      noDataMessage={
        !isPopulatedArray(selectedDomains) ? "No data found" : undefined
      }
      bg={stackBg}
      columns={columns}
      isFetching={isLoading}
      data={tableData}
      onFetchDataChange={() => undefined}
    />
  );
};

export { TopPerformingDomainsTable };
