import { Box, Flex, HStack, Stack, Text } from "@chakra-ui/react";
import {
  AutodiscoveryKeyword,
  AutodiscoveryProduct,
  AutodiscoveryTopic,
  AutodiscoveryUrl,
} from "@intentsify/types";
import { Search } from "components";
import { useState } from "react";
import { FiFile } from "react-icons/fi";
import { useTrackSearched } from "tracking/useTrackSearched";
import { AutodiscoveryMetadataDownload } from "../AutodiscoveryMetadataDownload";
import { UrlsResults } from "./components";
import { ResultsTable } from "./components/ResultsTable";

type AutodiscoveryResultsProps = {
  campaignId: number;
  urls: AutodiscoveryUrl[];
  keywords: AutodiscoveryKeyword[];
  topics: AutodiscoveryTopic[];
  products: AutodiscoveryProduct[];
  files: AutodiscoveryUrl[];
};

export const AutodiscoveryResults = ({
  campaignId,
  urls,
  keywords,
  topics,
  products,
  files,
}: AutodiscoveryResultsProps) => {
  const [search, setSearch] = useState("");
  const trackSearched = useTrackSearched();

  if (keywords.length === 0 && topics.length === 0 && products.length === 0) {
    return null;
  }

  return (
    <>
      <Box flex="1" textAlign="left">
        <HStack>
          <FiFile />
          <Text fontWeight={"bold"}>Processing Results</Text>
          <Text as="i">(top results)</Text>
        </HStack>
      </Box>

      <Stack spacing={6} mt={4}>
        <Stack alignItems="flex-start" justifyContent="flex-end">
          <UrlsResults urls={urls} files={files} />
          <Flex w="100%" justifyContent="space-between">
            <Search
              onSearch={(v) => {
                setSearch(v);
                trackSearched({
                  term: v,
                  collocation: "Autodiscovery results",
                });
              }}
              mb={0}
            />
            <AutodiscoveryMetadataDownload campaignId={campaignId} />
          </Flex>
        </Stack>

        <ResultsTable search={search} />
      </Stack>
    </>
  );
};
