import { FirmographicsFiltersDTO } from "@intentsify/dto";
import { NaicsCodeNode, NaicsCodeOption } from "@intentsify/types";
import { nest } from "@intentsify/utils";
import isEqual from "lodash/isEqual";
import { defaultFirmoFilters } from "./Firmographics.const";

export const filtersOrNull = (filters: FirmographicsFiltersDTO) => {
  const hasNotUsedFilters = isEqual(filters, defaultFirmoFilters);

  return hasNotUsedFilters ? null : filters;
};

const NaicsGroups = [
  [31, 32, 33],
  [44, 45],
  [48, 40],
];

/**
 * Some of root nodes in Naics codes have the same name, e.g.: 31 Manufacturing, 32 Manufacturing and 33 Manufacturing.
 * These are combined into a single node for user's convenience.
 */
export const buildNaicsCodesNodeTree = <T extends NaicsCodeNode>(
  naicsCodes: NaicsCodeOption[],
  /**
   * Business wanted us to group naics2 codes with the same name, 31-33 for example (manufacturing).
   * To maintain backwords compatibility with previously selected naics2 codes (e.g.: someone could select just 32)
   * we don't group them.
   */
  shouldGroup: boolean
): T[] => {
  const data = naicsCodes.map((i) => ({
    ...i,
    label: `${i.value} - ${i.label}`,
  })) as unknown as T[];

  const tree = nest(data, null);

  if (!shouldGroup) {
    return tree;
  }

  const groups = NaicsGroups.map((group) => {
    const nodes = tree.filter((i) => group.includes(Number(i.value)));

    const label = `${nodes[0].value}-${
      nodes[nodes.length - 1].value
    }${nodes[0].label.slice(2, Infinity)}`;

    const node = {
      label,
      value: group.join("_"),
      children: nodes
        .flatMap((i) => i.children)
        .map((i) => ({
          ...i,
          meta: {
            ...i?.meta,
            parent: group.join("_"),
          },
        })),
    } as unknown as T;

    return node;
  });

  return [
    ...groups,
    ...tree.filter((i) => !NaicsGroups.flat().includes(Number(i.value))),
  ].sort((a, b) => {
    return (
      Number(String(a.value).slice(0, 2)) - Number(String(b.value).slice(0, 2))
    );
  });
};
