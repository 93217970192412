import { Box, Text } from "@chakra-ui/react";
import { WeeklySnapshotRangeDTO } from "@intentsify/dto";
import { ErrorBoundary } from "components";
import {
  AccountAnalysis,
  CreativeAndImpressions,
  SectionHeader,
  TopicsAndContentAnalysis,
} from "screens/IntentAnalysis/screens/WeeklySnapshot/components";

type WeeklySnapshotProps = {
  campaignId: number;
  weeklySnapshotFilters: WeeklySnapshotRangeDTO;
};

const WeeklySnapshot = ({
  campaignId,
  weeklySnapshotFilters,
}: WeeklySnapshotProps) => {
  return (
    <Box px={4} width={"full"}>
      <SectionHeader>Account Analysis</SectionHeader>
      <ErrorBoundary>
        <AccountAnalysis
          campaignId={Number(campaignId)}
          filters={weeklySnapshotFilters}
        />
      </ErrorBoundary>

      <SectionHeader>Content and Topic Analysis</SectionHeader>
      <ErrorBoundary>
        <TopicsAndContentAnalysis
          campaignId={Number(campaignId)}
          filters={weeklySnapshotFilters}
        />
      </ErrorBoundary>

      <SectionHeader>
        Display and Lead Generation Overview
        <Text fontStyle="italic" fontSize="sm" fontWeight="normal" as="p">
          All visual components below represent data campaign to date
        </Text>
      </SectionHeader>

      <ErrorBoundary>
        <CreativeAndImpressions
          campaignId={Number(campaignId)}
          filters={weeklySnapshotFilters}
        />
      </ErrorBoundary>
    </Box>
  );
};

export { WeeklySnapshot };
