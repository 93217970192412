import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from "@chakra-ui/react";
import { useRef } from "react";
import { useConfirmDialogState } from "./ConfirmDialog.state";
import {
  DialogId,
  useSetConfirmDialogIdsState,
} from "./ConfirmDialog.stateIds";
import { Button } from "components/Button";

// Taken from / inspired by
// https://medium.com/@kch062522/useconfirm-a-custom-react-hook-to-prompt-confirmation-before-action-f4cb746ebd4e
/**
 * This is a component that allows you to show a confirmation dialog before performing an action.
 *
 * See [`useConfirm`](./useConfirm.tsx) for an example of how to use this component.
 */
export const ConfirmDialog = (props: { dialogId: DialogId }) => {
  const cancelRef = useRef<HTMLButtonElement | null>(null);
  const [state, setState] = useConfirmDialogState(props.dialogId);
  const setConfirmDialogIdsState = useSetConfirmDialogIdsState();

  const handleClose = () => {
    setState((state) => ({ ...state, resolve: undefined }));
    setConfirmDialogIdsState(
      (state) => new Set([...state].filter((id) => id !== props.dialogId))
    );
  };

  const handleConfirm = () => {
    state.promise?.resolve(true);
    handleClose();
  };

  const handleCancel = () => {
    state.promise?.resolve(false);
    handleClose();
    state.onCancel?.();
  };

  return (
    <AlertDialog
      isCentered
      autoFocus
      onClose={handleCancel}
      isOpen={Boolean(state.promise)}
      leastDestructiveRef={cancelRef}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="xl" fontWeight="bold">
            {state.title}
          </AlertDialogHeader>

          <AlertDialogBody fontSize="sm" pt="0">
            {state.description}
          </AlertDialogBody>

          <AlertDialogFooter gap="2" mt="4">
            <Button onClick={handleConfirm} variant="primary-teal">
              {state.confirmText}
            </Button>

            <Button ref={cancelRef} onClick={handleCancel} variant="ghost">
              Cancel
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
