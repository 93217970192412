import { useColorModeValue } from "@chakra-ui/color-mode";
import { useToken } from "@chakra-ui/system";
import { LayerProps } from "react-map-gl";
import { LayerVisibility } from "../ImpressionsAndTopicsMap.types";

export const useTopicsPointsLayerProps = (
  max: number,
  visibility: LayerVisibility
): LayerProps => {
  const circleColor = useToken(
    "colors",
    useColorModeValue("orange.100", "orange.300")
  );

  return {
    layout: {
      visibility: visibility,
    },
    type: "circle",
    filter: ["!", ["has", "point_count"]],
    paint: {
      "circle-radius": [
        // eslint-disable-next-line
        "interpolate", ["linear"], ["zoom"],
        // https://docs.mapbox.com/mapbox-gl-js/style-spec/expressions/
        // when zoom is 0 interpolate radius size linearly: 0 - size 1px, max - size 5px
        // eslint-disable-next-line
        0, ["interpolate", ["linear"], ["get", "count"], 0, 1, max, 5],
        // when zoom is 14 or greater interpolate radius size linearly 0 - size 10px, max - size 30px
        // eslint-disable-next-line
        14, ["interpolate", ["linear"], ["get", "count"], 0, 10, max, 30],
      ],
      "circle-color": circleColor,
      "circle-opacity": 0.7,
    },
  };
};
