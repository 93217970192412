import { BulkEditCampaignsDTO } from "@intentsify/dto";
import { Endpoints, apiService } from "api";

export const updateCampaignsInBulk = async ({
  dto,
}: {
  dto: BulkEditCampaignsDTO;
}) => {
  const result = await apiService.post(
    Endpoints.BulkOperations.Post.BulkEditCampaigns,
    dto
  );

  return result.data;
};
