import { DateTime } from "luxon";

export const NOT_A_CPA_CAMPAIGN_MESSAGE =
  "This visualization is designated for event pixel implementation and/or cost-per-action programs";

export const NO_LIFT_DATA_MESSAGE =
  "This visualization is designated for event pixel implementation";

export const getProgrammaticCampaignDatesBasedOnStatus = ({
  weekFrom,
  yearFrom,
  weekTo,
  yearTo,
  isEnded,
}: {
  weekFrom: number;
  yearFrom: number;
  weekTo: number;
  yearTo: number;
  isEnded: boolean;
}) => {
  if (isEnded) {
    // End date of the campaign for ended campaigns
    return {
      weekFrom,
      yearFrom,
      weekTo,
      yearTo,
    };
  }

  // Current date for the active campaigns as end date.
  const now = DateTime.now();

  return {
    weekFrom,
    yearFrom,
    weekTo: now.weekNumber,
    yearTo: now.weekYear,
  };
};
