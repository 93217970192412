import { Icon as CharkaIcon } from "@chakra-ui/icon";
import { Flex } from "@chakra-ui/layout";
import { noop } from "@intentsify/utils";
import { ColumnDef } from "@tanstack/react-table";
import { useMemo } from "react";
import { FiDownload, FiTrash } from "react-icons/fi";
import { filesize } from "filesize";
import { SimpleCsvFile } from "./SimpleCsvFile";
import { IconButton } from "components/IconButton";

const RowActions = ({ fileName, downloadCsvLink, onDelete }: SimpleCsvFile) => {
  return (
    <Flex gap="2" justifyContent="flex-end">
      {onDelete && (
        <IconButton
          tooltip="Delete file"
          icon={<CharkaIcon as={FiTrash} fontSize="md" />}
          onClick={onDelete}
        />
      )}

      {downloadCsvLink && (
        <IconButton
          as="a"
          icon={<CharkaIcon as={FiDownload} fontSize="md" />}
          onClick={noop}
          tooltip="Download file"
          download={fileName}
          href={downloadCsvLink}
        />
      )}
    </Flex>
  );
};

export interface UseColumnsOptions {
  includeSize?: boolean;
  includeDomains?: boolean;
}

export const useColumns = ({
  includeSize,
  includeDomains,
}: UseColumnsOptions) => {
  return useMemo(() => {
    const columns: ColumnDef<SimpleCsvFile>[] = [
      {
        header: "File name",
        accessorKey: "fileName",
      },
    ];

    if (includeSize) {
      columns.push({
        header: "Size",
        accessorFn: ({ size }) => filesize(size),
      });
    }

    if (includeDomains) {
      columns.push({
        header: "Domains",
        accessorKey: "domainsCount",
      });
    }

    columns.push({
      id: "actions",
      header: () => null,
      cell: ({ row }) => {
        if (!row.original) {
          return null;
        }

        return <RowActions {...row.original} />;
      },
    });

    return columns;
  }, [includeSize, includeDomains]);
};
