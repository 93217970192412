import { useToast } from "@chakra-ui/react";
import { ListAudienceSegmentsRO } from "@intentsify/dto";
import {
  AudienceSegment,
  AudienceSegmentDefinition,
  AudienceSegmentOption,
} from "@intentsify/types";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { apiService, Endpoints } from "api";

const createAudienceSegment = async (
  companyId: number,
  params: AudienceSegment
) => {
  const audienceSegment = await apiService.post<{
    audienceSegment: AudienceSegment;
  }>(Endpoints.Companies.Post.CreateAudienceSegment(companyId), params);

  return audienceSegment.data.audienceSegment;
};

export const useCreateAudienceSegment = (
  onCreate: (value: AudienceSegmentOption) => void
) => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation(
    ({ companyId, params }: { companyId: number; params: AudienceSegment }) =>
      createAudienceSegment(companyId, params),
    {
      onSuccess: (data: AudienceSegment) => {
        queryClient.invalidateQueries({ queryKey: ["audienceSegments"] });
        toast({
          title:
            "Segment has been created successfully. You can now use it in your campaigns.",
          status: "success",
        });

        onCreate({
          value: data.id,
          label: data.name,
          meta: { definition: data.definition },
        });
      },
      onError: () => {
        toast({
          title: "Please pick a unique name for the segment.",
          status: "error",
        });
      },
      onMutate: () => {
        toast({
          title: "Creating segment...",
          status: "info",
        });
      },
    }
  );
};

export const listAudienceSegments = async (
  companyId: number
): Promise<AudienceSegmentOption[]> => {
  const { data } = await apiService.get<ListAudienceSegmentsRO>(
    Endpoints.Companies.Get.ListAudienceSegments(companyId)
  );
  return data.audienceSegments.map(({ name, id, definition }) => ({
    label: name,
    value: id,
    meta: {
      definition,
    },
  }));
};

export const useListAudienceSegments = (companyId: number) => {
  return useQuery(["audienceSegments", companyId], () =>
    listAudienceSegments(companyId)
  );
};

const updateAudienceSegment = async (
  companyId: number,
  id: number,
  { definition }: { definition: AudienceSegmentDefinition }
) => {
  await apiService.put(Endpoints.Companies.Put.AudienceSegment(companyId, id), {
    definition,
  });
};

export const useUpdateAudienceSegment = () => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation(
    ({
      companyId,
      id,
      definition,
    }: {
      companyId: number;
      id: number;
      definition: AudienceSegmentDefinition;
    }) => updateAudienceSegment(companyId, id, { definition }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["audienceSegments"] });
        toast({
          title: "Audience segment has been updated successfully.",
          status: "success",
        });
      },
      onError: () => {
        toast({
          title: "Failed to update the segment.",
          status: "error",
        });
      },
      onMutate: () => {
        toast({
          title: "Updating segment...",
          status: "info",
        });
      },
    }
  );
};

const deleteAudienceSegment = async (companyId: number, id: number) => {
  await apiService.delete(
    Endpoints.Companies.Delete.AudienceSegment(companyId, id)
  );
};

export const useDeleteAudienceSegment = (
  companyId: number,
  id: number,
  onDelete: () => void
) => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation(
    ["deleteAudienceSegment"],
    () => deleteAudienceSegment(companyId, id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["audienceSegments"] });
        toast({
          title:
            "Segment has been deleted successfully. It will no longer be available in your campaigns.",
          status: "success",
        });
        onDelete();
      },
      onError: () => {
        toast({
          title: "Failed to delete the segment.",
          status: "error",
        });
      },
      onMutate: () => {
        toast({
          title: "Deleting segment...",
          status: "info",
        });
      },
    }
  );
};
