import { useToast, UseToastOptions } from "@chakra-ui/react";
import * as Sentry from "@sentry/react";
import { Dispatch, SetStateAction } from "react";
import { NavigateFunction } from "react-router";
import { HttpStatus, isCustomApiError, isError } from "types";
import { getToastSettingsFromHttpStatusCode } from "./errors";
import { formatApiError } from "./formatApiError";

// TODO: it shouldn't be used explicitly
// but in the query client config

/**
 * Handles the api errors:
 * - logs the user out if unauthorized
 * - report to Sentry when appropriate
 * - show toasts
 */
export const handleApiError = (
  err: unknown,
  navigate: NavigateFunction,
  toast:
    | ReturnType<typeof useToast>
    | Dispatch<SetStateAction<Partial<UseToastOptions> | undefined>>,
  report = false
) => {
  if (isCustomApiError(err)) {
    if (report) {
      Sentry.captureException(err);
    }

    if (err.response.statusCode === HttpStatus.UNAUTHORIZED) {
      return;
    }

    toast({
      ...getToastSettingsFromHttpStatusCode(err.response.statusCode),
      description: formatApiError(err),
    });

    return;
  }

  // Handle errors that took place in Axios, e.g.: Network Error
  if (isError(err)) {
    if (report) {
      Sentry.captureException(err);
    }

    const toastConfig: Partial<UseToastOptions> =
      getToastSettingsFromHttpStatusCode(HttpStatus.INTERNAL_SERVER_ERROR);

    toast({
      ...toastConfig,
      description: err.message,
    });
  }
};
