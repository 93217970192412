import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import { isDefined } from "@intentsify/utils";
import { useComponentColors } from "theme";
import { Progress } from "../Progress";
import { Modal } from "./Modal";

type StatusModalProps = {
  isOpen: boolean;
  progressValue?: number;
  status?: string;
  statusSubtitle?: string;
  onExit?: () => void;
};

const StatusModal = ({
  isOpen,
  progressValue,
  status = "Processing...",
  statusSubtitle,
  onExit,
}: StatusModalProps) => {
  const componentColors = useComponentColors();

  return (
    <Modal
      canClose={Boolean(onExit)}
      onClose={() => onExit?.()}
      body={
        <Box>
          <Flex
            height="250px"
            width="full"
            align="center"
            justifyContent="center"
            textAlign="center"
          >
            <Box>
              <Spinner
                thickness="4px"
                speed="0.75s"
                emptyColor={componentColors.spinner.emptyColor}
                color={componentColors.spinner.color}
                size="xl"
                label="Loading data"
                mr={8}
              />
            </Box>
            <Flex flexDir="column" maxW="250px">
              <Text
                display="block"
                textAlign="left"
                fontWeight="bold"
                fontSize="2xl"
                lineHeight={1.25}
              >
                {status}
              </Text>
              {statusSubtitle && (
                <Text
                  display="block"
                  textAlign="left"
                  fontSize="1xl"
                  minW={150}
                >
                  {statusSubtitle}
                </Text>
              )}
            </Flex>
          </Flex>
          {isDefined(progressValue) && (
            <Box mt={-4}>
              <Progress value={progressValue} colorScheme="green" />
            </Box>
          )}
        </Box>
      }
      isOpen={isOpen}
    />
  );
};

export { StatusModal };
