import { Button, Modal } from "components";

type ShouldOverwritePromptProps = {
  subjectPlural: string;
  isOpen: boolean;
  onReplace: () => void;
  onMerge: () => void;
  onClose?: () => void;
};

const ShouldOverwritePrompt = ({
  subjectPlural,
  isOpen,
  onReplace,
  onMerge,
  onClose,
}: ShouldOverwritePromptProps) => {
  return (
    <Modal
      title={`New ${subjectPlural}`}
      body={`You've uploaded a new set of ${subjectPlural}! Would you like to merge these ${subjectPlural} with the existing file or replace altogether?`}
      secondaryButton={
        <Button variant="secondary" onClick={onReplace}>
          Replace
        </Button>
      }
      primaryButton={
        <Button variant="primary" onClick={onMerge}>
          Merge
        </Button>
      }
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};

export { ShouldOverwritePrompt };
