import { useToast } from "@chakra-ui/react";
import type { DefaultOptions, QueryClient } from "@tanstack/react-query";
import { LoginScreenDefinition } from "auth/screens/Login/Login.definition";
import { isAxiosError } from "axios";
import { Duration } from "luxon";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router";
import { sessionExpiredToastOptions } from "shared/sessionExpiredToastOptions";
import { HttpStatus, isCustomApiError } from "types";
import { CustomApiError, handleApiError } from "utils";
import { useIsUserEditingOrCreatingCampaign } from "./useIsUserEditingOrCreatingCampaign";

const shouldRetry = (failureCount: number, error: unknown) => {
  // if auth is refreshing retry until it's done
  if (
    isAxiosError(error) &&
    error.response?.status === HttpStatus.UNAUTHORIZED
  ) {
    return true;
  }

  // retry five times on network errors
  if (failureCount <= 4 && !isAxiosError(error)) {
    return true;
  }

  return false;
};

export const useSetQueryClientDefaultsEffect = (queryClient: QueryClient) => {
  const toast = useToast();
  const navigate = useNavigate();
  const onError = useCallback(
    (error: unknown) => {
      if (
        error instanceof CustomApiError &&
        error.response.statusCode === HttpStatus.UNAUTHORIZED &&
        error.response.message !== "401UnauthorizedUserAgencyCompanyExpired" &&
        error.response.internalCode !== "401PersonalInfoExpiredToken" &&
        error.response.internalCode !== "400PersonalInfoInvalidToken"
      ) {
        queryClient.clear();
        navigate(LoginScreenDefinition.navigate());
        toast(sessionExpiredToastOptions);
      }

      handleApiError(error, navigate, toast);
    },
    [navigate, queryClient, toast]
  );

  const queryClientDefaultOptions = queryClient.getDefaultOptions();
  const isUserEditingOrCreatingCampaign = useIsUserEditingOrCreatingCampaign();
  const defaultOptions = useMemo<DefaultOptions>(() => {
    const staleTime = isUserEditingOrCreatingCampaign
      ? Infinity
      : Duration.fromObject({ minutes: 10 }).as("milliseconds");
    const refetchOnMount = isUserEditingOrCreatingCampaign ? false : true;
    const refetchOnWindowFocus = isUserEditingOrCreatingCampaign ? false : true;

    return {
      ...queryClientDefaultOptions,
      mutations: {
        ...queryClientDefaultOptions.mutations,
        retry: shouldRetry,
        onError: onError,
      },
      queries: {
        ...queryClientDefaultOptions.queries,
        retry: shouldRetry,
        onError: onError,
        staleTime,
        refetchOnMount,
        refetchOnWindowFocus,
        useErrorBoundary: (error: unknown) => {
          // If we throw a 401 into error boundary we won't be able to recover after token is refreshed.
          // This matters when a query received a 401 and is unmounted while refershing hasn't completed yet.
          if (
            isCustomApiError(error) &&
            error.response.statusCode === HttpStatus.UNAUTHORIZED
          ) {
            return false;
          }

          return true;
        },
      },
    };
  }, [isUserEditingOrCreatingCampaign, onError, queryClientDefaultOptions]);

  queryClient.setDefaultOptions(defaultOptions);
};
