import {
  chakra,
  Input,
  InputGroup,
  InputGroupProps,
  InputProps,
  InputRightElement,
  Stack,
} from "@chakra-ui/react";
import { HTMLInputTypeAttribute, useState } from "react";
import { Button } from "../../Button";

export type DynamicInputFormProps<T extends string | number> = {
  addItem: (item: T) => void;
  inputType: HTMLInputTypeAttribute;
  placeholder?: string;
  mt?: InputProps["mt"];
  w?: InputGroupProps["w"];
  size?: "sm" | "md";
};

const DynamicInputForm = <T extends string | number>({
  w = "100%",
  addItem,
  inputType,
  placeholder,
  size,
}: DynamicInputFormProps<T>) => {
  const [value, setValue] = useState<T>("" as T);

  const handleSubmit = (
    e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();

    if (!value) {
      return;
    }

    addItem(value);
    setValue("" as T);
  };

  return (
    <Stack direction="row" spacing="4" w={w}>
      <chakra.form onSubmit={handleSubmit} w={w}>
        <InputGroup size="md" w={w}>
          <Input
            type={inputType}
            shadow="sm"
            size={size}
            rounded="md"
            value={value}
            onChange={(e) => setValue(e.target.value as T)}
            placeholder={placeholder}
          />
          <InputRightElement>
            <Button mt="-7px" isDisabled={!value} onClick={handleSubmit}>
              Add
            </Button>
          </InputRightElement>
        </InputGroup>
      </chakra.form>
    </Stack>
  );
};

export { DynamicInputForm };
