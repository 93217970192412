import { chakra } from "@chakra-ui/react";
import { LinePath } from "@visx/shape";
import { ScaleLinear, ScaleBand } from "d3-scale";
import { CSSProperties, useState } from "react";
import { animated, useSpring } from "react-spring";
import {
  TimeseriesDataItem,
  TimeseriesLegendItemFormatted,
  TimeseriesTooltipItem,
} from "../../Charts.types";
import { handleMouseOver, handleNearestPoint } from "./Line.utils";

type LineProps = {
  style?: CSSProperties;
  data: {
    data: TimeseriesDataItem[];
    legend: TimeseriesLegendItemFormatted;
  };
  strokeColor: string;
  timeScale: ScaleBand<string> | undefined;
  timeValues: string[];
  valuesScale: ScaleLinear<number, number, never> | undefined;
  showTooltip: (args: {
    tooltipLeft: number;
    tooltipTop: number;
    tooltipData: {
      isoDate: string;
      data: TimeseriesTooltipItem[];
    };
  }) => void;
  hideTooltip: () => void;
  offsetLeft: number;
  offsetTop: number;
};

const Line = ({
  style,
  data,
  strokeColor,
  timeScale,
  valuesScale,
  showTooltip,
  hideTooltip,
  timeValues,
  offsetLeft,
  offsetTop,
}: LineProps) => {
  const [hoveredNearestPoint, setHoveredNearestPoint] = useState<
    { x: number; y: number } | undefined
  >();

  const [{ opacity, transform }] = useSpring(
    {
      to: { opacity: 1, transform: "translateY(0px)" },
      from: { opacity: 0, transform: "translateY(-10px)" },
      reset: true,
      delay: 200,
    },
    []
  );

  if (!timeScale || !valuesScale) {
    return null;
  }

  const AnimatedLinePath = animated(LinePath);
  const bandwith = timeScale.bandwidth();

  return (
    <>
      {hoveredNearestPoint && (
        <chakra.rect
          onMouseMove={() => {
            hideTooltip();
            setHoveredNearestPoint(undefined);
          }}
          fill="transparent"
          w="100%"
          h="100%"
        />
      )}

      {hoveredNearestPoint && (
        <circle
          cx={hoveredNearestPoint.x}
          cy={hoveredNearestPoint.y}
          r={8}
          fill={strokeColor}
          pointerEvents="none"
        />
      )}

      <AnimatedLinePath
        style={{
          fill: "none",
          ...style,
          opacity,
          transform,
        }}
        key={Math.random()}
        strokeWidth={3}
        data={data.data}
        // ts and ts-lint gets confused when wrapped in react-spring animated
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        x={(d) => {
          // @ts-ignore
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          return (timeScale(d.isoDate) as number) + bandwith / 2;
        }}
        y={(d) => {
          // ts and ts-lint gets confused when wrapped in react-spring animated
          // @ts-ignore
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          return valuesScale(d.value);
        }}
        stroke={strokeColor}
      />

      <LinePath
        style={{
          fill: "none",
        }}
        onMouseMove={(event) => {
          handleMouseOver({
            event,
            timeScale,
            timeValues,
            valuesScale,
            offsetLeft,
            offsetTop,
            data,
            showTooltip,
          });

          handleNearestPoint({
            event,
            timeScale,
            valuesScale,
            timeValues,
            offsetLeft,
            items: data.data,
            setHoveredNearestPoint,
          });
        }}
        onMouseLeave={() => {
          hideTooltip();
          setHoveredNearestPoint(undefined);
        }}
        key={Math.random()}
        strokeWidth={9}
        data={data.data}
        // ts and ts-lint gets confused when wrapped in react-spring animated
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        x={(d) => {
          // @ts-ignore
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          return (timeScale(d.isoDate) as number) + bandwith / 2;
        }}
        y={(d) => {
          // ts and ts-lint gets confused when wrapped in react-spring animated
          // @ts-ignore
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          return valuesScale(d.value);
        }}
        stroke={"transparent"}
      />
    </>
  );
};

export { Line };
