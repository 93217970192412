import { Text, Tooltip, TooltipProps } from "@chakra-ui/react";
import { useState } from "react";
import { useTrackTooltipHovered } from "tracking/useTrackTooltipHovered";

type DelayedTooltipProps = Omit<TooltipProps, "aria-label"> &
  Required<Pick<TooltipProps, "aria-label">>;

export const DelayedTooltip = ({
  children,
  label,
  ...props
}: DelayedTooltipProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const trackTooltipHovered = useTrackTooltipHovered();

  const shouldWrapLabel = typeof label === "string";

  return (
    <Tooltip
      isOpen={isOpen}
      onOpen={() => {
        setIsOpen(true);
        trackTooltipHovered({ ariaLabel: props["aria-label"] });
      }}
      onClose={() => {
        setIsOpen(false);
      }}
      hasArrow
      openDelay={1100}
      offset={[0, 24]}
      maxW="200px"
      rounded="md"
      bg="brand.300"
      border="1px solid"
      borderColor="whiteAlpha.300"
      boxShadow="dark-lg"
      color="whiteAlpha.900"
      _light={{
        bg: "#eee",
        borderColor: "blackAlpha.100",
        color: "blackAlpha.900",
      }}
      sx={{
        ".chakra-tooltip__arrow": {
          _light: {
            bg: "#eee !important",
          },
        },
      }}
      label={
        shouldWrapLabel ? (
          <Text fontSize="xs" p="0.5">
            {label}
          </Text>
        ) : (
          label
        )
      }
      {...props}
    >
      {children}
    </Tooltip>
  );
};
