import { Flex } from "@chakra-ui/layout";
import { useState } from "react";
import { CampaignSelector, SelectedCampaign } from "shared/components";
import { UploadContent } from "../UploadContent";
import { Table } from "./Table";
import { useSearch } from "./useSearch";

export const Completed = () => {
  const search = useSearch();
  const [campaigns, setCampaigns] = useState<SelectedCampaign[]>([]);
  const campaignIds = campaigns.map((c) => c.value);

  return (
    <Flex flexDir="column" rowGap="4">
      <Flex justifyContent="space-between" alignItems="flex-end">
        <Flex columnGap="4" alignItems="flex-end">
          <search.Input />
          <Flex>
            <CampaignSelector
              isMulti
              showOnlyCurrentUserCompanyCampaigns
              w="300px"
              selected={campaigns as any}
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              onChange={(options) => setCampaigns(options as any)}
            />
          </Flex>
        </Flex>

        <Flex>
          <UploadContent />
        </Flex>
      </Flex>

      <Table searchValue={search.value} campaignIds={campaignIds} />
    </Flex>
  );
};
