import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import { ChakraProps, Flex, Th, Tr, useColorModeValue } from "@chakra-ui/react";
import {
  Header,
  HeaderGroup as HeaderGroupType,
  RowData,
  flexRender,
} from "@tanstack/react-table";

const DEFAULT_HEADER_SIZE = 0;

const Sortable = <T extends RowData, V>({
  header,
  isCentered,
}: {
  header: Header<T, V>;
  isCentered: boolean;
}) => {
  const canSort = header.column.getCanSort();

  if (!canSort) {
    return (
      <Flex
        alignItems="center"
        justifyContent={isCentered ? "center" : undefined}
      >
        {header.isPlaceholder
          ? null
          : flexRender(header.column.columnDef.header, header.getContext())}
      </Flex>
    );
  }

  return (
    <Flex
      cursor="pointer"
      alignItems="center"
      onClick={() => {
        header.column.toggleSorting();
      }}
      justifyContent={isCentered ? "center" : undefined}
    >
      <>
        {header.isPlaceholder
          ? null
          : flexRender(header.column.columnDef.header, header.getContext())}
        {{
          asc: <TriangleUpIcon ml={2} aria-label="sorted ascending" />,
          desc: <TriangleDownIcon ml={2} aria-label="sorted descending" />,
        }[header.column.getIsSorted() as string] ?? null}
      </>
    </Flex>
  );
};

const HeaderGroup = <T extends RowData>({
  headerGroup,
  isSticky = false,
  isSortDisabled = false,
  isCentered = false,
  verticalAlign = "center",
}: {
  headerGroup: HeaderGroupType<T>;
  isSticky?: boolean;
  isSortDisabled?: boolean;
  isCentered?: boolean;
  verticalAlign?: ChakraProps["verticalAlign"];
}) => {
  const tableHeaderBackground = useColorModeValue("gray.700", "brand.600");
  const tableHeaderColor = useColorModeValue("gray.100", "gray.100");

  return (
    <Tr
      rounded={"md"}
      key={headerGroup.id}
      style={{ ...(isSticky ? { position: "sticky", top: 0, zIndex: 1 } : {}) }}
    >
      {headerGroup.headers.map((header) => {
        const headerSize = header.getSize();

        return (
          <Th
            colSpan={header.colSpan}
            key={header.id}
            bg={tableHeaderBackground}
            color={tableHeaderColor}
            p={4}
            style={{
              minWidth:
                headerSize !== DEFAULT_HEADER_SIZE ? headerSize : undefined,
            }}
            textAlign={isCentered ? "center" : undefined}
            verticalAlign={verticalAlign}
          >
            {!isSortDisabled ? (
              <Sortable header={header} isCentered={isCentered} />
            ) : (
              flexRender(header.column.columnDef.header, header.getContext())
            )}
          </Th>
        );
      })}
    </Tr>
  );
};

export { HeaderGroup };
