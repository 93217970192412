import { Box, Flex } from "@chakra-ui/layout";
import { useColorMode } from "@chakra-ui/react";
import { DateRangeFormat } from "@intentsify/types";
import {
  formatDateRange,
  isDefined,
  isPopulatedArray,
} from "@intentsify/utils";
import { AxisBottom, AxisLeft, AxisRight, Orientation } from "@visx/axis";
import { Group } from "@visx/group";
import { ParentSize } from "@visx/responsive";
import { scaleLinear, scaleLog, scaleTime } from "@visx/scale";
import { Line } from "@visx/shape";
import { Text } from "@visx/text";
import { useTooltip, useTooltipInPortal } from "@visx/tooltip";
import { ScaleLinear, ScaleTime } from "d3-scale";
import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useMeasure } from "react-use";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { getChartColours } from "theme";
import { TimeseriesItem, TimeseriesItemDataRecord } from "types";
import { useChartColors } from "utils";
import { ErrorBoundary } from "../../ErrorBoundary";
import { Loader } from "../../Loader";
import { NoData } from "../../NoData";
import {
  EXPORT_LEGEND_WIDTH,
  EXPORT_TITLE_HEIGHT,
  MIN_HEIGHT,
} from "../Charts.const";
import { formatTickValue, getWrapperDirection } from "../Charts.utils";
import { TimeseriesProps } from "./Timeseries";
import { useTimeseriesData } from "./Timeseries.hooks";
import {
  disabledDataKeysFamily,
  disabledLegendItemsFamily,
  hoveredLineAtomFamily,
  hoveredNearestPointAtomFamily,
  hoveredXAxisLabelFamily,
  legendHoverAtomFamily,
} from "./Timeseries.state";
import {
  findXAxisLabelFrequency,
  getMinMax,
  renderLegendTooltip,
} from "./Timeseries.utils";
import { DataSources, Legend, Lines, Tooltip } from "./components";
import { TimeseriesTooltip } from "./components/TimeseriesTooltip/TimeseriesTooltip";

type RegularProps<
  Keys extends string,
  DataKeys extends string
> = TimeseriesProps<Keys, DataKeys>;

const Regular = <K extends string, T extends string>({
  timeseries,
  data,
  dataMainKey,
  dataKeyLabels,
  id,
  title,
  exportMode = undefined,
  disableLegend = false,
  disableLegendTooltip = true,
  legendItemActions,
  disableDataSources = false,
  legendPosition = undefined,
  isLoading = false,
  dateFormat = DateRangeFormat.StartDateMonth,
  margins = {
    t: 20,
    b: 80,
    r: 10,
    l: 60,
  },
  stretch = false,
  noDataMessage,
  pallete,
  shufflePallete = false,
  includePalleteShades = false,
  snapToDateTime = false,
  fixedTooltip = false,
  tooltipRenderer = TimeseriesTooltip,
  useTwoYAxes = false,
  secondYAxisDataKeyLabel,
  scale = "linear",
  minHeight = MIN_HEIGHT,
  startDate,
  defaultTimeGap,
}: RegularProps<K, T>) => {
  const [uniqueKey] = useState(Math.random().toString());

  const [ref, { width: containerWidth }] = useMeasure<HTMLDivElement>();

  const {
    dataWithUniqueKeys,
    allValues,
    secondDataItemValues,
    dataKeys,
    timeValues,
    calculatedGap,
  } = useTimeseriesData(data, timeseries, uniqueKey, {
    secondYAxisDataKeyLabel: secondYAxisDataKeyLabel
      ? `${secondYAxisDataKeyLabel}s`
      : undefined,
    startDate,
    defaultTimeGap,
  });

  const timeScale = useRef<ScaleTime<number, number, never> | undefined>();
  const valuesScale = useRef<ScaleLinear<number, number, never> | undefined>();
  const secondValuesScale = useRef<
    ScaleLinear<number, number, never> | undefined
  >();

  const legendHoverItem = useRecoilValue(legendHoverAtomFamily(uniqueKey));
  const setNearestHoveredItem = useSetRecoilState(
    hoveredNearestPointAtomFamily(uniqueKey)
  );
  const setHoveredLine = useSetRecoilState(hoveredLineAtomFamily(uniqueKey));

  const disabledLegendItems = useRecoilValue(
    disabledLegendItemsFamily(uniqueKey)
  );

  const disabledDataKeys = useRecoilValue(disabledDataKeysFamily(uniqueKey));

  const [hoveredXAxisLabel, setHoveredXAxisLabel] = useRecoilState(
    hoveredXAxisLabelFamily(uniqueKey)
  );

  const { labelColor, axisColors } = useChartColors();

  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip<TimeseriesItem<K, TimeseriesItemDataRecord<T>>>();

  const { colorMode } = useColorMode();
  const chartColors = useMemo(() => {
    return getChartColours(
      colorMode,
      pallete,
      shufflePallete,
      includePalleteShades
    );
  }, [colorMode, pallete, shufflePallete, includePalleteShades]);

  const twoAxisColors = useMemo(() => {
    return getChartColours(
      "light",
      pallete,
      shufflePallete,
      includePalleteShades
    );
  }, [pallete, shufflePallete, includePalleteShades]);

  useEffect(() => {
    renderLegendTooltip(
      legendHoverItem,
      disabledLegendItems,
      showTooltip,
      hideTooltip
    );
  }, [legendHoverItem, disabledLegendItems, hideTooltip, showTooltip]);

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    detectBounds: !fixedTooltip,
    scroll: true,
  });

  const resetInteractions = useCallback(() => {
    if (tooltipOpen) {
      hideTooltip();
    }
    setHoveredLine(undefined);
    setNearestHoveredItem(undefined);
    setHoveredXAxisLabel(undefined);
  }, [
    tooltipOpen,
    hideTooltip,
    setHoveredLine,
    setNearestHoveredItem,
    setHoveredXAxisLabel,
  ]);

  useEffect(() => {
    window.addEventListener("scroll", resetInteractions);

    return () => {
      window.removeEventListener("scroll", resetInteractions);
    };
  }, [resetInteractions]);

  if (isLoading) {
    return <Loader minHeight={minHeight} stretch={stretch} />;
  }

  if (!isPopulatedArray(dataWithUniqueKeys)) {
    return (
      <NoData height={minHeight} message={noDataMessage} stretch={stretch} />
    );
  }

  const legendPositionComputed = legendPosition
    ? legendPosition
    : containerWidth > 1200
    ? "right"
    : "bottom";

  return (
    <ErrorBoundary>
      <Flex ref={ref} width="100%" height="100%">
        <Flex
          flexDirection={getWrapperDirection(legendPositionComputed)}
          width="100%"
        >
          <Flex
            width="100%"
            flexGrow={stretch ? 1 : 0}
            minHeight={minHeight}
            overflow="hidden"
          >
            <ParentSize>
              {(parent) => {
                const { width, height } = parent;

                const baseYMax = height - margins.t - margins.b;
                const baseXMax = width - margins.l - margins.r;

                const spaceForSecondAxisTickText = useTwoYAxes
                  ? margins.r * 5
                  : 0;

                const yMax = !exportMode
                  ? baseYMax
                  : baseYMax - EXPORT_TITLE_HEIGHT;

                const xMax = !exportMode
                  ? baseXMax - spaceForSecondAxisTickText
                  : baseXMax - EXPORT_LEGEND_WIDTH;

                timeScale.current = scaleTime<number>({
                  domain: getMinMax(timeValues),
                  range: [0, xMax],
                  nice: false,
                });

                valuesScale.current =
                  scale === "linear"
                    ? scaleLinear<number>({
                        domain: [0, Math.max(...allValues)],
                        range: [yMax, 0],
                        nice: true,
                      })
                    : scaleLog<number>({
                        domain: [
                          Math.min(...allValues),
                          Math.max(...allValues),
                        ],
                        range: [yMax, 0],
                      });

                secondValuesScale.current =
                  scale === "linear"
                    ? scaleLinear<number>({
                        domain: [0, Math.max(...secondDataItemValues)],
                        range: [yMax, 0],
                        nice: true,
                      })
                    : scaleLog<number>({
                        domain: [
                          Math.min(...secondDataItemValues),
                          Math.max(...secondDataItemValues),
                        ],
                        range: [yMax, 0],
                        nice: true,
                      });

                const xLabelFrequency = findXAxisLabelFrequency({
                  width: xMax,
                  itemsCount: timeValues.length,
                });

                return (
                  <Box
                    width={width}
                    height={height}
                    position="relative"
                    onMouseLeave={() => resetInteractions()}
                  >
                    <svg
                      width={width}
                      height={height}
                      ref={containerRef}
                      id={id}
                      style={{
                        fontFamily: "var(--chakra-fonts-body)",
                      }}
                    >
                      <rect
                        x={0}
                        y={0}
                        width={width}
                        height={height}
                        fill={"transparent"}
                        id="wrapper"
                        onMouseMove={(e: React.MouseEvent) => {
                          if (
                            e.currentTarget.getAttribute("id") === "wrapper"
                          ) {
                            resetInteractions();
                          }
                        }}
                      />

                      {exportMode && title && (
                        <Group top={30} left={22}>
                          <Text
                            verticalAnchor="middle"
                            fontSize={22}
                            fontWeight="semibold"
                            fill={labelColor}
                          >
                            {title}
                          </Text>
                        </Group>
                      )}

                      <Group>
                        <g
                          transform={`translate(${margins.l},${
                            !exportMode
                              ? margins.t
                              : margins.t + EXPORT_TITLE_HEIGHT
                          })`}
                        >
                          {!useTwoYAxes && (
                            <Lines
                              chartColors={chartColors}
                              data={dataWithUniqueKeys}
                              uniqueKey={uniqueKey}
                              timeScale={timeScale.current}
                              valuesScale={valuesScale.current}
                              showTooltip={showTooltip}
                              hideTooltip={hideTooltip}
                              timeseries={timeseries}
                              snapToDateTime={snapToDateTime}
                              fixedTooltip={fixedTooltip}
                              margins={margins}
                            />
                          )}

                          {useTwoYAxes && (
                            <>
                              <Lines
                                chartColors={chartColors}
                                data={[dataWithUniqueKeys[0]]}
                                uniqueKey={uniqueKey}
                                timeScale={timeScale.current}
                                valuesScale={valuesScale.current}
                                showTooltip={showTooltip}
                                hideTooltip={hideTooltip}
                                timeseries={timeseries}
                                snapToDateTime={snapToDateTime}
                                fixedTooltip={fixedTooltip}
                                margins={margins}
                              />

                              <Lines
                                chartColors={chartColors.slice(1, -1)}
                                data={[dataWithUniqueKeys[1]]}
                                uniqueKey={uniqueKey}
                                timeScale={timeScale.current}
                                valuesScale={secondValuesScale.current}
                                showTooltip={showTooltip}
                                hideTooltip={hideTooltip}
                                timeseries={timeseries}
                                snapToDateTime={snapToDateTime}
                                fixedTooltip={fixedTooltip}
                                margins={margins}
                              />
                            </>
                          )}

                          <Text
                            x={-yMax / 2}
                            y={-margins.l + 12}
                            textAnchor="middle"
                            verticalAnchor="end"
                            transform="rotate(-90)"
                            fontSize={14}
                            fill={labelColor}
                          >
                            {dataKeys
                              .filter((k) => !disabledDataKeys.includes(k))
                              .map((k) =>
                                dataKeyLabels ? dataKeyLabels[k] : k
                              )
                              .join(" / ")}
                          </Text>

                          <AxisLeft
                            stroke={useTwoYAxes ? twoAxisColors[0] : axisColors}
                            tickStroke={axisColors}
                            scale={valuesScale.current}
                            labelProps={{ fill: labelColor }}
                            tickFormat={
                              scale === "log"
                                ? (v) => {
                                    const asString = `${String(v)}`;
                                    // label only major ticks
                                    return asString.match(/^[.01?[\]]*$/)
                                      ? formatTickValue(Number(v))
                                      : "";
                                  }
                                : (v) => formatTickValue(Number(v))
                            }
                            numTicks={10}
                            tickComponent={(props) => {
                              const {
                                formattedValue,
                                x,
                                y,
                                textAnchor,
                                verticalAnchor,
                                dx,
                                dy,
                              } = props;

                              return (
                                <Text
                                  x={x}
                                  y={y}
                                  dx={dx}
                                  dy={dy}
                                  fontSize={12}
                                  textAnchor={textAnchor}
                                  verticalAnchor={verticalAnchor}
                                  fill={labelColor}
                                >
                                  {formattedValue}
                                </Text>
                              );
                            }}
                          />

                          {useTwoYAxes && secondDataItemValues.length > 0 && (
                            <AxisRight
                              left={xMax}
                              stroke={twoAxisColors[1]}
                              tickStroke={axisColors}
                              scale={secondValuesScale.current}
                              label={`${secondYAxisDataKeyLabel || ""} volume`}
                              labelProps={{
                                fill: labelColor,
                                fontSize: 12,
                                textAnchor: "middle",
                                verticalAnchor: "middle",
                                y: -55,
                              }}
                              tickFormat={
                                scale === "log"
                                  ? (v) => {
                                      const asString = `${String(v)}`;
                                      // label only major ticks
                                      return asString.match(/^[.01?[\]]*$/)
                                        ? asString
                                        : "";
                                    }
                                  : undefined
                              }
                              numTicks={10}
                              tickComponent={(props) => {
                                const {
                                  formattedValue,
                                  x,
                                  y,
                                  textAnchor,
                                  verticalAnchor,
                                  dx,
                                  dy,
                                } = props;

                                return (
                                  <Text
                                    x={x}
                                    y={y}
                                    dx={dx}
                                    dy={dy}
                                    fontSize={12}
                                    textAnchor={textAnchor}
                                    verticalAnchor={verticalAnchor}
                                    fill={labelColor}
                                  >
                                    {formattedValue}
                                  </Text>
                                );
                              }}
                            />
                          )}

                          <AxisBottom
                            top={yMax}
                            orientation={Orientation.bottom}
                            tickValues={timeValues}
                            scale={timeScale.current}
                            numTicks={4}
                            // @ts-ignore
                            tickFormat={(val) => val}
                          >
                            {(props) => {
                              const tickLabelSize = 10;
                              const tickRotate = -45;

                              const { axisFromPoint, axisToPoint } = props;

                              return (
                                <g>
                                  <Line
                                    from={axisFromPoint}
                                    to={axisToPoint}
                                    stroke={axisColors}
                                  />

                                  {props.ticks.map((tick, i) => {
                                    const series = timeseries.find(
                                      (s) =>
                                        DateTime.fromISO(s.start)
                                          .toUTC()
                                          .valueOf() ===
                                        Number(tick.formattedValue)
                                    );

                                    let label = series
                                      ? formatDateRange(series, dateFormat)
                                      : undefined;

                                    // if startDate provided it means that more ticks may be created
                                    if (
                                      !label &&
                                      startDate &&
                                      calculatedGap &&
                                      Number(tick.formattedValue) <
                                        DateTime.fromISO(timeseries[0].start)
                                          .toUTC()
                                          .valueOf()
                                    ) {
                                      label = formatDateRange(
                                        {
                                          start: DateTime.fromMillis(
                                            Number(tick.formattedValue)
                                          ).toISO(),
                                          end: DateTime.fromMillis(
                                            Number(tick.formattedValue) +
                                              calculatedGap -
                                              1
                                          ).toISO(),
                                        },
                                        dateFormat
                                      );
                                    }

                                    const isHovered =
                                      series?.start === hoveredXAxisLabel;

                                    const tickX = tick.to.x;
                                    const tickY = tick.to.y + 10;

                                    return (
                                      <Group
                                        key={`vx-tick-${String(
                                          tick.value
                                        )}-${i}`}
                                        className={"vx-axis-tick"}
                                      >
                                        <Line
                                          from={tick.from}
                                          to={tick.to}
                                          stroke={axisColors}
                                        />

                                        {i % xLabelFrequency === 0 && (
                                          <text
                                            cursor="default"
                                            transform={`translate(${tickX}, ${tickY}) rotate(${tickRotate})`}
                                            fontSize={tickLabelSize}
                                            textAnchor="end"
                                            fill={labelColor}
                                            onMouseEnter={() => {
                                              setHoveredXAxisLabel(
                                                series?.start
                                              );
                                            }}
                                            onMouseLeave={() => {
                                              setHoveredXAxisLabel(undefined);
                                            }}
                                            style={{
                                              opacity:
                                                hoveredXAxisLabel && !isHovered
                                                  ? 0.1
                                                  : 1,
                                            }}
                                          >
                                            {label}
                                          </text>
                                        )}
                                      </Group>
                                    );
                                  })}
                                </g>
                              );
                            }}
                          </AxisBottom>
                        </g>
                      </Group>

                      {exportMode && (
                        <Group
                          width={`${EXPORT_LEGEND_WIDTH}px`}
                          top={margins.t + EXPORT_TITLE_HEIGHT}
                          left={width - margins.r / 2 - EXPORT_LEGEND_WIDTH}
                        >
                          {data.map((item, index) => {
                            return (
                              <>
                                <rect
                                  x={0}
                                  y={index * 32 - 8}
                                  width={16}
                                  height={16}
                                  fill={chartColors[index]}
                                />

                                <Text
                                  verticalAnchor="middle"
                                  fontSize={14}
                                  key={index}
                                  width={EXPORT_LEGEND_WIDTH}
                                  x={24}
                                  y={index * 32}
                                  fill={labelColor}
                                >
                                  {item[dataMainKey]}
                                </Text>
                              </>
                            );
                          })}
                        </Group>
                      )}
                    </svg>
                    {tooltipOpen &&
                      tooltipData &&
                      isDefined(tooltipLeft) &&
                      isDefined(tooltipTop) && (
                        <Tooltip
                          tooltipRenderer={tooltipRenderer}
                          TooltipInPortal={TooltipInPortal}
                          tooltipLeft={tooltipLeft}
                          tooltipTop={tooltipTop}
                          uniqueKey={uniqueKey}
                          tooltipData={tooltipData}
                          dataMainKey={dataMainKey}
                          data={data}
                          dataKeyLabels={dataKeyLabels}
                          timeseries={timeseries}
                        />
                      )}
                  </Box>
                );
              }}
            </ParentSize>
          </Flex>

          {!disableLegend && !exportMode && (
            <Flex
              flexDirection="column"
              width={legendPositionComputed === "bottom" ? "100%" : undefined}
              mt={legendPositionComputed === "bottom" ? 5 : undefined}
              ml={legendPositionComputed === "right" ? 5 : undefined}
            >
              {!disableDataSources && (
                <DataSources
                  uniqueKey={uniqueKey}
                  timeseries={timeseries}
                  data={dataWithUniqueKeys}
                  dataKeyLabels={dataKeyLabels}
                  legendPosition={legendPositionComputed}
                />
              )}

              <Legend
                dataMainKey={dataMainKey}
                uniqueKey={uniqueKey}
                legendPosition={legendPositionComputed}
                data={dataWithUniqueKeys}
                chartColors={chartColors}
                legendItemActions={legendItemActions}
                disableLegendTooltip={disableLegendTooltip}
              />
            </Flex>
          )}
        </Flex>
      </Flex>
    </ErrorBoundary>
  );
};

export { Regular };
