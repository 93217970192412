import { Flex } from "@chakra-ui/react";
import { SpaceProps } from "@chakra-ui/styled-system";
import { chakra } from "@chakra-ui/system";
import { ReactNode } from "react";
import { useComponentColors } from "theme";
import { Item } from "types";
import { Button } from "../Button";

type SelectedTagsHeaderProps = {
  items: Item[];
  title: string;
  onReset?: () => void;
  actions?: ReactNode;
  hideCount?: boolean;
} & SpaceProps;

const SelectedTagsHeader = ({
  items,
  title,
  onReset,
  actions,
  hideCount = false,
  ...spaceProps
}: SelectedTagsHeaderProps) => {
  const componentColors = useComponentColors();

  return (
    <Flex
      mb={4}
      justifyContent="space-between"
      alignItems="center"
      flexWrap="wrap"
      {...spaceProps}
    >
      <chakra.span
        fontSize="md"
        fontWeight="bold"
        color={componentColors.form.formLabelColor}
      >
        {title} {!hideCount && `(${items.length})`}
      </chakra.span>

      {onReset && items.length > 0 && (
        <Button variant="outline" size="xs" ml={2} onClick={onReset}>
          Remove all selected
        </Button>
      )}

      {actions}
    </Flex>
  );
};

export { SelectedTagsHeader };
