import { Box, Flex, Progress } from "@chakra-ui/react";
import type { TusUpload } from "@intentsify/tus/dist/react";

type TusUploadProgressProps = {
  tusUpload: TusUpload;
};

export const TusUploadProgress = ({ tusUpload }: TusUploadProgressProps) => {
  const progress = tusUpload.useProgress();
  const isUploading = tusUpload.useIsUploading();

  return (
    <>
      {!isNaN(progress.secondsRemaining) &&
        progress.secondsRemaining !== 0 &&
        !progress.isFinalizing &&
        isUploading && (
          <Flex alignItems="center" gap="2">
            <Progress
              colorScheme="teal"
              value={progress.progress}
              w="50px"
              borderRadius="md"
            />
            <Box
              color="blackAlpha.700"
              _dark={{ color: "whiteAlpha.700" }}
              fontSize="xs"
              style={{ fontVariantNumeric: "tabular-nums" }}
            >
              ({progress.secondsRemaining.toFixed(1)}s left)
            </Box>
          </Flex>
        )}

      {progress.isFinalizing &&
        isUploading &&
        progress.secondsRemaining <= 1 &&
        progress.secondsRemaining >= 0 && (
          <Box
            color="blackAlpha.700"
            _dark={{ color: "whiteAlpha.700" }}
            fontSize="xs"
          >
            Finalizing upload...
          </Box>
        )}
    </>
  );
};
