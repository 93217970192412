import { Box, Stack, Text } from "@chakra-ui/react";
import { DateRange as DateRangeEnum, Option } from "@intentsify/types";
import { Select } from "../Select";

type DateRangeProps<K extends string | number> = {
  options: Option<K>[];
  dateRange: DateRangeEnum;
  onChange: (value: K) => void;
  width?: number | string;
  isLoading?: boolean;
  isDisabled?: boolean;
  showLabel?: boolean;
};

const DateRangeFilter = <T extends string>({
  onChange,
  options,
  dateRange,
  isLoading,
  isDisabled,
  width = "100%",
  showLabel = true,
}: DateRangeProps<T>) => {
  const handleDateRangeChange = (option: Option<T>) => {
    onChange(option.value);
  };

  return (
    <Stack minW={width}>
      {showLabel && (
        <Text fontSize="xs" fontWeight="semibold">
          Date range:
        </Text>
      )}
      <Box minWidth={250}>
        <Select
          isMulti={false}
          isLoading={isLoading}
          isDisabled={isDisabled}
          options={options}
          value={options.find((dr: Option<T>) => dr.value === dateRange)}
          placeholder="Select a date range"
          onChange={(option) => {
            if (option) {
              handleDateRangeChange(option);
            }
          }}
        />
      </Box>
    </Stack>
  );
};

export { DateRangeFilter };
