import { Stack, Text } from "@chakra-ui/react";
import { WeeklySnapshotDTO } from "@intentsify/dto";
import { FiltersContainer, WeekPicker } from "components";
import { DateTime } from "luxon";

type FilterProps = {
  selectedFilters: WeeklySnapshotDTO;
  onChange: (filters: WeeklySnapshotDTO) => void;
  minDate?: string;
  maxDate?: string;
  isLoading?: boolean;
};

export const Filters = ({
  selectedFilters,
  onChange,
  minDate,
  maxDate,
  isLoading,
}: FilterProps) => {
  let maxDateCondition: DateTime;
  const parsedMaxDate = maxDate ? DateTime.fromISO(maxDate) : null;

  if (parsedMaxDate && parsedMaxDate.valueOf() < Date.now()) {
    maxDateCondition = parsedMaxDate.endOf("week");
  } else {
    maxDateCondition = DateTime.now().endOf("week");
  }

  return (
    <FiltersContainer
      withMarginBottom={false}
      items={[
        {
          component: (
            <Stack>
              <Text fontSize="xs" fontWeight="semibold">
                Selected Week:
              </Text>

              <WeekPicker
                isDisabled={isLoading}
                selected={DateTime.fromObject({
                  weekNumber: selectedFilters.weekTo,
                  weekYear: selectedFilters.yearTo,
                }).toISO()}
                onChange={({ weekNumber, yearNumber }) => {
                  onChange({
                    weekTo: weekNumber,
                    yearTo: yearNumber,
                  });
                }}
                disabledDays={
                  minDate
                    ? [
                        {
                          gt: DateTime.fromISO(minDate)
                            .minus({
                              week: 1,
                            })
                            .startOf("week"),
                          lt: maxDateCondition,
                        },
                      ]
                    : undefined
                }
              />
            </Stack>
          ),
        },
      ]}
    />
  );
};
