import { createScreen } from "utils/createScreen";
import { ImpersonationTransitionScreen } from "../../types";

const ImpersonationTransitionDefinition = createScreen({
  name: ImpersonationTransitionScreen,
  path: "/impersonation",
  routePath: ["/impersonation"],
});

export { ImpersonationTransitionDefinition };
