import { useToast } from "@chakra-ui/react";
import { useGetHasAccessTo } from "@intentsify/authorization/dist/react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { atom, useSetRecoilState } from "recoil";
import { YourCampaignsScreenDefinition } from "screens";
import { useAppStore } from "store/useAppStore";
import { Screen, ScreenDefinition, UnsafeScreenDefinition } from "types";
import { registerCustomTag } from "./clarity";
import { useDeepEffect } from "./useDeepEffect";

export const screenAtom = atom<{ name: Screen | undefined; info?: string }>({
  key: "screenAtom",
  default: {
    name: undefined,
    info: undefined,
  },
});

export const screenOptionsAtom = atom<{ subtitle?: string }>({
  key: "screenOptionsAtom",
  default: {},
});

export const useScreen = <
  S extends { name: Screen | undefined; info?: string } & (
    | Omit<ScreenDefinition, "name">
    | Omit<UnsafeScreenDefinition, "name">
  )
>(
  screenDefinition: S,
  options?: { subtitle?: string }
) => {
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const impersonation = useAppStore.use.impersonation();
  const setScreen = useSetRecoilState(screenAtom);
  const setScreenOptions = useSetRecoilState(screenOptionsAtom);

  const isScreenUnsafe = !("accessPath" in screenDefinition);
  const getHasAccessTo = useGetHasAccessTo();
  const hasAccessToScreen = isScreenUnsafe
    ? true
    : getHasAccessTo(screenDefinition.accessPath);
  const shouldAlertUser = !isScreenUnsafe && !hasAccessToScreen;

  useDeepEffect(() => {
    if (shouldAlertUser) {
      return;
    }

    setScreen({ name: screenDefinition.name, info: screenDefinition.info });

    if (screenDefinition.name) {
      registerCustomTag("screen", screenDefinition.name);
    }
  }, [shouldAlertUser, screenDefinition]);

  useDeepEffect(() => {
    if (shouldAlertUser) {
      return;
    }

    setScreenOptions(options ?? {});
  }, [shouldAlertUser, options]);

  useEffect(() => {
    if (!shouldAlertUser) {
      return;
    }

    const screenName = screenDefinition.name || location.pathname;

    if (impersonation) {
      toast({
        title: `As an ${impersonation.impersonates.displayName} (${impersonation.impersonates.email}) user you don't have access to "${screenName}".`,
        status: "error",
        position: "top",
      });

      return;
    }

    toast({
      title: `You don't have access to "${screenName}".`,
      status: "error",
      position: "top",
    });

    navigate(YourCampaignsScreenDefinition.navigate());
  }, [
    navigate,
    shouldAlertUser,
    screenDefinition.name,
    impersonation,
    toast,
    location.pathname,
  ]);
};
