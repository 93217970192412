import { useToast } from "@chakra-ui/react";
import { WeeklySnapshotRangeDTO } from "@intentsify/dto";
import { useMutation } from "@tanstack/react-query";
import { ampli } from "tracking/amplitude";
import { isCustomApiError } from "types";
import { appErrors } from "utils";
import { requestWeeklySnapshotReport } from "./WeeklySnapshot.requests";

export const useWeeklySnapsotReportRequest = (
  rawCampaignId: number | string | undefined,
  params: WeeklySnapshotRangeDTO
) => {
  const toast = useToast();
  const campaignId = Number(rawCampaignId);
  const { mutate, isLoading } = useMutation(
    () => requestWeeklySnapshotReport(campaignId, params),
    {
      onSuccess: () => {
        ampli.requestWeeklySnapshotReport({ campaignId, ...params });

        toast({
          title: `Your request for the weekly snapshot report has been received. You will be notified once it's ready."`,
          status: "success",
        });
      },
      onError: (err) => {
        if (isCustomApiError(err)) {
          toast({
            title:
              err.response.internalCode === "409ReportAlreadyRequested"
                ? appErrors["409ReportAlreadyRequested"]
                : appErrors["SomethingWentWrong"],
            status: "warning",
          });
        }
      },
    }
  );

  return {
    requestWeeklySnapshotReport: mutate,
    isWeeklySnapshotReportLoading: isLoading,
  };
};
