import { BusinessEntitiesScreen } from "types";
import { createScreen } from "utils/createScreen";

const BusinessEntitiesScreenDefinition = createScreen({
  name: BusinessEntitiesScreen,
  path: "/admin/business-entities",
  routePath: ["/"],
  accessPath: "businessEntities",
});

export { BusinessEntitiesScreenDefinition };
