import { GetAutomatedProgrammaticSegmentRO } from "@intentsify/dto";
import { apiService, Endpoints } from "api";

export const getAutomatedProgrammaticSegment = async (segmentId: number) => {
  const { data } = await apiService.get<
    Promise<GetAutomatedProgrammaticSegmentRO>
  >(
    Endpoints.AutomatedProgrammaticSegment.Get.AutomatedProgrammaticSegment(
      segmentId
    )
  );

  return data;
};
