import {
  Box,
  Flex,
  Heading,
  Skeleton,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { DateTime } from "luxon";
import { useParams } from "react-router";
import { DateWeekAndYear } from "types";
import { useCampaignMeta } from "./CampaignMetaHeader.state";

type CampaignDetailsHeaderProps = {
  date?: DateWeekAndYear;
};

const CampaignMetaHeader = ({ date }: CampaignDetailsHeaderProps) => {
  const campaignId = Number(useParams<"campaignId">().campaignId);

  const skeletonStart = useColorModeValue("blackAlpha.500", "whiteAlpha.400");
  const skeletonEnd = useColorModeValue("blackAlpha.200", "whiteAlpha.100");

  const { campaignMetaIsLoading, campaignMeta } = useCampaignMeta({
    campaignId,
    date,
  });

  if (!campaignId) {
    return null;
  }

  if (campaignMetaIsLoading) {
    return (
      <Flex flexDirection="row">
        <Flex
          w={"300px"}
          h={"115px"}
          flexDirection="column"
          justifyContent="space-around"
        >
          {Array(2)
            .fill(undefined)
            .map((_, i) => (
              <Skeleton
                key={i}
                startColor={skeletonStart}
                endColor={skeletonEnd}
                height="18px"
              />
            ))}
        </Flex>
        <Box flexGrow={2} />
      </Flex>
    );
  }

  if (!campaignMeta) {
    return null;
  }

  const { campaignName, campaignStartDate } = campaignMeta;

  return (
    <Box flexDirection="row">
      <Flex justifyContent={"space-between"}>
        <Stack>
          <Heading as="h2" size="md">
            {campaignName}
          </Heading>
          <Stack direction="row">
            <Text fontWeight="semibold">Campaign Start Date:</Text>
            <Text>
              {DateTime.fromISO(campaignStartDate).toLocaleString(
                DateTime.DATE_FULL
              )}
            </Text>
          </Stack>
        </Stack>
      </Flex>
    </Box>
  );
};

export { CampaignMetaHeader };
