import {
  NotificationSetting,
  NotificationTransportMethods,
} from "@intentsify/types";
import find from "lodash/find";
import sortBy from "lodash/sortBy";
import { SerializedNotificationSetting } from "./NotificationSettings.types";

export const serializeNotificationSettings = (
  notificationSettings: NotificationSetting[]
): SerializedNotificationSetting[] => {
  const uniqueNotificationSettings = Array.from(
    new Set(notificationSettings?.map(({ notificationId }) => notificationId))
  );

  return uniqueNotificationSettings.map((notificationId) => ({
    notificationId,
    notificationDescription:
      find(notificationSettings, {
        notificationId,
      })?.description ?? "",
    notificationName:
      find(notificationSettings, {
        notificationId,
      })?.displayName ?? "",
    settings: sortBy(
      notificationSettings
        .filter(
          ({ notificationId: _notificationId }) =>
            notificationId === _notificationId
        )
        .map(({ notificationId, isDisabled, transportMethod }) => ({
          notificationId,
          disabledNotification: isDisabled,
          transportMethod,
        })),
      ["transportMethod"]
    ),
  }));
};

export const getTransportMethodDescription = (
  transportMethod: NotificationTransportMethods
) => {
  switch (true) {
    case transportMethod === NotificationTransportMethods.EMAIL:
      return "Check to receive e-mail notifications.";
    case transportMethod === NotificationTransportMethods.PORTAL:
      return "Check to receive notifications within the Portal.";
    default:
      return "";
  }
};
