export const getArrowType = (
  previousPeriodChange: number | null | undefined
) => {
  if (previousPeriodChange === 0 || !previousPeriodChange) {
    return undefined;
  }

  if (previousPeriodChange > 0) {
    return "increase";
  }

  if (previousPeriodChange < 0) {
    return "decrease";
  }
};

export const getColorScheme = (type: ReturnType<typeof getArrowType>) => {
  if (type === "decrease") {
    return "red";
  }

  if (type === "increase") {
    return "green";
  }

  return undefined;
};
