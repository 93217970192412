import { Flex } from "@chakra-ui/react";
import { WeeklySnapshotRangeDTO } from "@intentsify/dto";
import { LeadByAssetPerformance } from "../CreativeAndImpressions";
import {
  MostResearchedTopics,
  TrendingTopics,
  PingContent,
  ContentAlignmentAnalysis,
} from "./components";

type TopicsAndContentAnalysisProps = {
  campaignId: number;
  filters: WeeklySnapshotRangeDTO;
};

const TopicsAndContentAnalysis = ({
  campaignId,
  filters,
}: TopicsAndContentAnalysisProps) => {
  return (
    <>
      <Flex gap={4} mb={4} minH="350px">
        <Flex flex={1}>
          <LeadByAssetPerformance campaignId={campaignId} filters={filters} />
        </Flex>

        <Flex w="calc(33.33% - 10px)">
          <PingContent campaignId={campaignId} filters={filters} />
        </Flex>
      </Flex>

      <Flex gap={4} mb={4} minH="500px">
        <TrendingTopics campaignId={campaignId} filters={filters} />
        <MostResearchedTopics campaignId={campaignId} filters={filters} />
      </Flex>

      <Flex gap={4} mb={4} minH="400px">
        <ContentAlignmentAnalysis campaignId={campaignId} filters={filters} />
      </Flex>
    </>
  );
};

export { TopicsAndContentAnalysis };
