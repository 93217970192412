import { Flex, Modal, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { useRef, useState, ReactNode } from "react";
import { useMount } from "react-use";
import { Body } from "./Body";
import { WithChildren } from "types";

/**
 * The children of a modal can be quite slow to render. The table component with a lot of
 * ChangeIndicators and CollapsibleCells in particular.
 * DelayRender makes sure that the modal itself is fully rendered before the children are.
 * This helps the UI feel a bit more responsive. This should be a temporary solution.
 *
 * why it should be a temporary solution?
 */
const DelayRender = ({ children }: WithChildren) => {
  const [isMounted, setIsMounted] = useState(false);
  useMount(() => setTimeout(() => setIsMounted(true), 0));

  if (!isMounted) {
    return null;
  }

  return <>{children}</>;
};

type ViewContainerProps = {
  title?: string;
  titleSize?: string;
  subtitle?: string;
  actionItems?: ReactNode;
  expandable?: boolean;
  stretch?: boolean;
  noPadding?: boolean;
  withBackground?: boolean;
  /**
   * Limits the the width of children, useful for readability of content like forms.
   */
  compact?: boolean;
  children: ReactNode | ((expanded: boolean) => ReactNode);
  overwriteBackgroundColor?: string;
};

const ViewContainer = ({
  actionItems,
  children,
  title,
  titleSize = "sm",
  subtitle,
  expandable = false,
  stretch = false,
  noPadding = false,
  withBackground = false,
  compact = false,
  overwriteBackgroundColor = "",
}: ViewContainerProps) => {
  const [expanded, setExpanded] = useState(false);
  const ref = useRef<any>();

  return (
    <>
      <Body
        actionItems={actionItems}
        title={title}
        subtitle={subtitle}
        titleSize={titleSize}
        setExpanded={setExpanded}
        expandable={expandable}
        expanded={expanded}
        stretch={stretch}
        noPadding={noPadding}
        withBackground={withBackground}
        overwriteBackgroundColor={overwriteBackgroundColor}
      >
        <Flex
          flexGrow={1}
          flexDir="column"
          maxWidth={compact ? 1400 : undefined}
        >
          {typeof children === "function" ? children(expanded) : children}
        </Flex>
      </Body>

      {expandable && (
        <Modal
          trapFocus={false}
          motionPreset="none"
          size="viewContainer"
          isOpen={expanded}
          onClose={() => setExpanded(false)}
        >
          <ModalOverlay />
          <ModalContent background="none" height="100%" overflow="hidden">
            <Flex p={10} flexGrow={1} overflow="hidden" width="100%">
              <Flex ref={ref} flexDirection="column" width="100%">
                <Body
                  actionItems={actionItems}
                  title={title}
                  subtitle={subtitle}
                  titleSize={titleSize}
                  setExpanded={setExpanded}
                  expandable={expandable}
                  expanded={expanded}
                  stretch={stretch}
                  noPadding={noPadding}
                  withBackground={withBackground}
                >
                  <Flex
                    flexGrow={1}
                    flexDir="column"
                    maxWidth={compact ? 1400 : undefined}
                  >
                    {expanded && (
                      <DelayRender>
                        {typeof children === "function"
                          ? children(expanded)
                          : children}
                      </DelayRender>
                    )}
                  </Flex>
                </Body>
              </Flex>
            </Flex>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export { ViewContainer };
