import { ButtonGroup, Flex, Image, Stack, Toast } from "@chakra-ui/react";
import {
  HasAccess,
  useHasAccessTo,
} from "@intentsify/authorization/dist/react";
import {
  BuyerResearchStage,
  BuyingGroupContactsAccounts,
  BuyingGroupFilters,
  BuyingGroupFiltersWithDate,
  CampaignDetails,
  CampaignMeta,
  SortDirection,
  defaultAudienceSegmentDefinition,
  defaultBuyingGroupFilters,
} from "@intentsify/types";
import { ColumnDef } from "@tanstack/react-table";
import { Endpoints } from "api";
import {
  Badge,
  Button,
  EmptyCell,
  InfiniteScrollTable,
  Loader,
  SomethingWrong,
} from "components";
import { PaginationType } from "components/Table/Table/Table.types";
import { useState } from "react";
import { RiDownloadLine } from "react-icons/ri";
import { useCampaignBuyingGroup } from "shared/components/campaign/Campaign.state";
import { useTrackCampaignFileDownloaded } from "tracking/useTrackCampaignFileDownloaded";
import { FetchDataParams } from "types";
import {
  combineParamsAndFilters,
  paramsToQueryUrl,
  useDeepEffect,
  useDownloadFile,
} from "utils";
import { listPaginatedCampaignDetails } from "../CampaignDetails.requests";
import { useCampaignSignalsAndFilters } from "../CampaignDetailsTable/CampaignDetailsTable.hooks";
import { PaginatedCampaignDetailsParams } from "../CampaignDetailsTable/CampaignDetailsTable.types";
import { FiltersContainer } from "../components/FiltersContainer";
import { AccountsTableExpandedRow } from "./AccountsTableExpandedRow";
import { Filters } from "./components/Filters";

type AccountsTableProps = {
  campaignId: number;
  weekNumber: number;
  yearNumber: number;
  campaignMeta?: CampaignMeta;
};

export function AccountsTable(props: AccountsTableProps) {
  const companyId = props.campaignMeta?.companyId;

  const trackCampaignFileDownloaded = useTrackCampaignFileDownloaded();
  const hasAccessToBuyingGroup = useHasAccessTo("buyingGroup");
  const { data: buyingGroupSettings } = useCampaignBuyingGroup(
    props.campaignId,
    hasAccessToBuyingGroup
  );
  const hasAccessToBuyingGroupIntentFeed = useHasAccessTo(
    "campaign.buyingGroupIntentFeed"
  );
  const isBuyingGroupDownloadEnabled =
    props.campaignMeta?.hasBuyingGroup &&
    buyingGroupSettings &&
    buyingGroupSettings.buyingGroupContactsAccounts !==
      BuyingGroupContactsAccounts.NONE;

  const [campaignDetailsParams, setCampaignDetailsParams] =
    useState<PaginatedCampaignDetailsParams>(defaultParams);

  const [persistedCampaignDetailsFilters, setPersistedCampaignDetailsFilters] =
    useState<BuyingGroupFiltersWithDate>({
      ...defaultBuyingGroupFilters,
      weekNumber: props.weekNumber,
      yearNumber: props.yearNumber,
    });

  const [appliedBuyingGroupFilters, setAppliedBuyingGroupFilters] =
    useState<BuyingGroupFilters>(defaultBuyingGroupFilters);

  const didUserUseFilters =
    JSON.stringify(defaultBuyingGroupFilters) !==
    JSON.stringify(appliedBuyingGroupFilters);

  const { error, filters } = useCampaignSignalsAndFilters(
    props.campaignId,
    props.weekNumber,
    props.yearNumber
  );

  useDeepEffect(() => {
    setCampaignDetailsParams({
      ...campaignDetailsParams,
      page: 1,
    });
    setPersistedCampaignDetailsFilters((prev) => ({
      ...prev,
      weekNumber: props.weekNumber,
      yearNumber: props.yearNumber,
    }));

    filters.remove();
  }, [props.weekNumber, props.yearNumber]);

  const [resultsCount, setResultsCount] = useState<number | undefined>(
    undefined
  );
  const [isLoadingResults, setIsLoadingResults] = useState(true);

  const fetchData = (params?: FetchDataParams<keyof CampaignDetails>) => {
    return listPaginatedCampaignDetails(props.campaignId)(
      combineParamsAndFilters(
        {
          ...campaignDetailsParams,
          order: params?.order || defaultParams.order,
          order_by: params?.order_by || defaultParams.order_by,
          page: params?.page || defaultParams.page,
          per_page: params?.per_page || defaultParams.per_page,
        },
        persistedCampaignDetailsFilters
      )
    );
  };

  const { download } = useDownloadFile();
  const handleDownloadBuyingGroup = async () => {
    const contactsType = String(
      buyingGroupSettings?.buyingGroupContactsAccounts ??
        BuyingGroupContactsAccounts.NONE
    );
    await download({
      url: `${Endpoints.CampaignDetails.Get.DownloadBuyingGroup(
        props.campaignId
      )}?${paramsToQueryUrl({
        params: combineParamsAndFilters(
          campaignDetailsParams,
          persistedCampaignDetailsFilters
        ),
        includeFirstAmpersand: false,
      })}&contactAccountsType=${contactsType}`,
      fileName: `${props.campaignId}_buying_group`,
      fileExtension: "zip",
    });
    trackCampaignFileDownloaded({
      type: "campaign details with buying group",
      campaignIds: [props.campaignId],
    });
  };

  const handleDownloadBuyingGroupIntentFeed = async () => {
    await download({
      url: `${Endpoints.Campaigns.Get.DownloadBuyingGroupIntentFeed(
        props.campaignId
      )}`,
      fileName: `${props.campaignId}_buying_group_intent_feed`,
      fileExtension: "zip",
    });
    trackCampaignFileDownloaded({
      type: "campaign details with buying group",
      campaignIds: [props.campaignId],
    });
  };

  if (error) {
    Toast({
      title: "Cannot get campaign details",
      status: "error",
    });

    return <SomethingWrong />;
  }

  return (
    <Stack gap={2}>
      <FiltersContainer
        resultsCount={resultsCount}
        isLoadingResults={isLoadingResults}
        title="Filters"
        areFiltersApplied={didUserUseFilters}
      >
        {filters.data ? (
          <Filters
            filtersOptions={filters.data}
            initialValues={defaultAudienceSegmentDefinition}
            onApply={(val) => {
              setPersistedCampaignDetailsFilters({
                ...persistedCampaignDetailsFilters,
                ...val,
              });
              setAppliedBuyingGroupFilters(val);
            }}
          />
        ) : (
          <Loader minHeight={"100px"} />
        )}
      </FiltersContainer>

      <HasAccess to="campaign.downloadDetails">
        <ButtonGroup>
          {isBuyingGroupDownloadEnabled && hasAccessToBuyingGroupIntentFeed && (
            <Button
              variant="ghost"
              rightIcon={<RiDownloadLine />}
              size="sm"
              onClick={() => {
                handleDownloadBuyingGroupIntentFeed();
              }}
              isDisabled={isLoadingResults}
            >
              Download Buying Group Intent Feed CSV
            </Button>
          )}
          {isBuyingGroupDownloadEnabled && (
            <Button
              variant="primary-teal"
              rightIcon={<RiDownloadLine />}
              size="sm"
              onClick={() => {
                handleDownloadBuyingGroup();
              }}
              isDisabled={isLoadingResults}
            >
              Request to Download Buying Group CSV
            </Button>
          )}
        </ButtonGroup>
      </HasAccess>

      <InfiniteScrollTable<CampaignDetails, PaginationType.LIMIT_OFFSET>
        columns={columns}
        fetchData={fetchData}
        height={900}
        name={`accountsWithPersonaDetails${props.campaignId}`}
        estimatedRowHeight={100}
        renderExpandableRowComponent={(row) => (
          <AccountsTableExpandedRow
            companyId={Number(companyId)}
            accountId={row.original.domainId}
            campaignId={props.campaignId}
            buyingGroupContactsAccounts={
              buyingGroupSettings?.buyingGroupContactsAccounts ||
              BuyingGroupContactsAccounts.NONE
            }
          />
        )}
        defaultSort={[{ id: "buyerResearchStage", desc: false }]}
        paginationType={PaginationType.LIMIT_OFFSET}
        filters={persistedCampaignDetailsFilters}
        disableVirtualization={true}
        itemsPerPage={defaultParams.per_page}
        noDataMessage={
          !didUserUseFilters
            ? "Data is processing."
            : "No results. Please adjust filters."
        }
        onLoadingStateChange={(isLoading, data) => {
          setIsLoadingResults(isLoading);
          setResultsCount(data?.pages[0]?.meta?.total_items);
        }}
      />
    </Stack>
  );
}

const defaultParams = {
  page: 1,
  per_page: 25,
  order_by: "buyerResearchStage",
  order: SortDirection.ASC,
} satisfies FetchDataParams<keyof CampaignDetails>;

const columns = [
  {
    header: "",
    accessorKey: "logoUrl",
    enableSorting: false,
    cell: ({ row }) => (
      <Flex display="flex">
        {row.original?.logoUrl && (
          <Image
            maxHeight={"50px"}
            maxWidth={"50px"}
            src={row.original?.logoUrl}
            mr={2}
          />
        )}
      </Flex>
    ),
  },
  {
    header: "Account",
    accessorKey: "domainName",
  },
  {
    header: "Buyer Research Stage",
    accessorKey: "buyerResearchStage",
    cell: ({ row }) => {
      return (
        <Badge fontWeight="bold">
          {row.original?.buyerResearchStage ?? BuyerResearchStage.Unspecified}
        </Badge>
      );
    },
  },
  {
    header: "Contacts",
    accessorKey: "contacts",
    cell: ({ row }) => {
      return row.original?.contacts ?? <EmptyCell />;
    },
  },
] satisfies ColumnDef<CampaignDetails>[];
