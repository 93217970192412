import { createScreen } from "utils/createScreen";
import { DownloadContentLibraryAssetScreen } from "../../types";

const DownloadContentLibraryAssetDefinition = createScreen({
  name: DownloadContentLibraryAssetScreen,
  path: "/download/content-library/:fileName/:id",
  routePath: ["/download/content-library/:fileName/:id"],
});

export { DownloadContentLibraryAssetDefinition };
