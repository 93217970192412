import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  chakra,
  useToast,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { ChangePersonalInfoDTO } from "@intentsify/dto";
import { PersonalInfo } from "@intentsify/types";
import { Turnstile } from "@marsidev/react-turnstile";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Endpoints, apiService } from "api";
import { Button, Tooltip } from "components";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { getZodDefaults } from "utils/getZodDefaults";

const defaultUserInfoValues = getZodDefaults(PersonalInfo);

type OptOutInfoFormProps = {
  token: string;
  readOnly?: boolean;
};

export function OptOutInfoForm(props: OptOutInfoFormProps) {
  const [hasPassedChallenge, setHasPassedChallenge] = useState(false);
  const userInfo = useUserInfo(props.token, props.readOnly);
  const updateUserInfo = useUpdateUserInfo(props.token);
  const form = useForm<PersonalInfo>({
    resolver: zodResolver(PersonalInfo),
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: defaultUserInfoValues,
    values: userInfo.data,
  });

  const hasChanged = form.formState.isDirty;
  const unsavedTooltip = hasChanged
    ? ""
    : "In order to submit the form, you must make changes";

  const challengeTooltip = hasPassedChallenge
    ? ""
    : "In order to submit the form, please click the checkbox on the left and follow the instructions";

  return (
    <chakra.form
      display="flex"
      flexDir="column"
      gap="4"
      onSubmit={(e) => {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        const token = formData.get("cf-turnstile-response");
        if (!token || typeof token !== "string") {
          throw new Error("No token found in form data");
        }

        form.handleSubmit((data) => {
          updateUserInfo.mutate({
            firstName: data.first_name,
            lastName: data.last_name,
            companyDomain: data.company_domain,
            companyName: data.company_name,
            directNumber: data.direct_number,
            jobFunction: data.job_function,
            jobLevel: data.job_level,
            jobTitle: data.job_title,
            linkedinUrl: data.linkedin_url,
            mobilePhone: data.mobile_phone,
            cfTurnstileToken: token,
          });
        })(e);
      }}
    >
      <FormControl
        isInvalid={Boolean(form.formState.errors.business_email?.message)}
        isRequired
        isReadOnly
      >
        <FormLabel>Email</FormLabel>
        <Input
          {...form.register("business_email")}
          type="email"
          required
          placeholder="john.doe@example.com"
        />
        <FormErrorMessage>
          {form.formState.errors.business_email?.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl
        isInvalid={Boolean(form.formState.errors.first_name?.message)}
        isRequired
        isReadOnly={props.readOnly}
      >
        <FormLabel>First name</FormLabel>
        <Input {...form.register("first_name")} required placeholder="John" />
        <FormErrorMessage>
          {form.formState.errors.first_name?.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl
        isInvalid={Boolean(form.formState.errors.last_name?.message)}
        isRequired
        isReadOnly={props.readOnly}
      >
        <FormLabel>Last name</FormLabel>
        <Input {...form.register("last_name")} required placeholder="Doe" />
        <FormErrorMessage>
          {form.formState.errors.last_name?.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl
        isInvalid={Boolean(form.formState.errors.mobile_phone?.message)}
        isReadOnly={props.readOnly}
      >
        <FormLabel>Mobile phone number</FormLabel>
        <Input
          {...form.register("mobile_phone")}
          placeholder="+1-951-239-052"
        />
        <FormErrorMessage>
          {form.formState.errors.mobile_phone?.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl
        isInvalid={Boolean(form.formState.errors.direct_number?.message)}
        isReadOnly={props.readOnly}
      >
        <FormLabel>Direct phone number</FormLabel>
        <Input
          {...form.register("direct_number")}
          placeholder="+1-951-239-052"
        />
        <FormErrorMessage>
          {form.formState.errors.direct_number?.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl
        isInvalid={Boolean(form.formState.errors.company_name?.message)}
        isReadOnly={props.readOnly}
      >
        <FormLabel>Company name</FormLabel>
        <Input {...form.register("company_name")} />
        <FormErrorMessage>
          {form.formState.errors.company_name?.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl
        isInvalid={Boolean(form.formState.errors.company_domain?.message)}
        isReadOnly={props.readOnly}
      >
        <FormLabel>Company domain</FormLabel>
        <Input {...form.register("company_domain")} />
        <FormErrorMessage>
          {form.formState.errors.company_domain?.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl
        isInvalid={Boolean(form.formState.errors.job_title?.message)}
        isReadOnly={props.readOnly}
      >
        <FormLabel>Job title</FormLabel>
        <Input {...form.register("job_title")} />
        <FormErrorMessage>
          {form.formState.errors.job_title?.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl
        isInvalid={Boolean(form.formState.errors.job_level?.message)}
        isReadOnly={props.readOnly}
      >
        <FormLabel>Job level</FormLabel>
        <Input {...form.register("job_level")} />
        <FormErrorMessage>
          {form.formState.errors.job_level?.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl
        isInvalid={Boolean(form.formState.errors.job_function?.message)}
        isReadOnly={props.readOnly}
      >
        <FormLabel>Job function</FormLabel>
        <Input {...form.register("job_function")} />
        <FormErrorMessage>
          {form.formState.errors.job_function?.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl
        isInvalid={Boolean(form.formState.errors.linkedin_url?.message)}
        isReadOnly={props.readOnly}
      >
        <FormLabel>LinkedIn URL</FormLabel>
        <Input
          {...form.register("linkedin_url")}
          placeholder="linkedin.com/in/john-doe-1234"
        />
        <FormErrorMessage>
          {form.formState.errors.linkedin_url?.message}
        </FormErrorMessage>
      </FormControl>

      {!props.readOnly && (
        <Flex justifyContent="space-between">
          <Turnstile
            siteKey={String(process.env.VITE_CF_TURNSTILE_SITE_KEY)}
            onSuccess={() => setHasPassedChallenge(true)}
          />
          <Flex alignSelf="end">
            <Tooltip
              aria-label={challengeTooltip ?? unsavedTooltip}
              label={challengeTooltip ?? unsavedTooltip}
            >
              <Button
                variant="primary-teal"
                type="submit"
                px="5"
                py="5"
                isDisabled={!hasChanged || !hasPassedChallenge}
                isLoading={updateUserInfo.isLoading}
              >
                Submit
              </Button>
            </Tooltip>
          </Flex>
        </Flex>
      )}
    </chakra.form>
  );
}

function useUserInfo(token: string, readOnly?: boolean) {
  return useQuery({
    suspense: true,
    queryKey: ["userInfo", token, readOnly],
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    queryFn: readOnly
      ? async () => {
          const res = await apiService.get<PersonalInfo>(
            Endpoints.PersonalInfo.GetInfo(token)
          );

          return res.data;
        }
      : async () => {
          const res = await apiService.get<PersonalInfo>(
            Endpoints.PersonalInfo.GetInfoChange(token)
          );

          return res.data;
        },
  });
}

function useUpdateUserInfo(token: string) {
  const toast = useToast();

  return useMutation({
    mutationFn: async (data: ChangePersonalInfoDTO) => {
      const res = await apiService.put(
        Endpoints.PersonalInfo.Edit(token),
        data
      );

      return res.data;
    },
    onSuccess: () => {
      toast({
        title: `Your change request has been submitted!`,
        status: "success",
      });
    },
  });
}
