import { Route, Routes } from "react-router";
import { Navigate } from "react-router-dom";
import { Companies, CreateCompany, EditCompany } from "screens";
import { CompaniesScreenDefinition } from "screens/Admin/screens/Companies/screens/Companies/Companies.definition";
import { CreateCompanyScreenDefinition } from "screens/Admin/screens/Companies/screens/CreateCompany/CreateCompany.definition";
import { EditCompanyScreenDefinition } from "screens/Admin/screens/Companies/screens/EditCompany/EditCompany.definition";

const CompaniesRouter = () => {
  return (
    <Routes>
      {CompaniesScreenDefinition.routePath.map((path) => (
        <Route key={path} path={path} element={<Companies />} />
      ))}

      {CreateCompanyScreenDefinition.routePath.map((path) => (
        <Route key={path} path={path} element={<CreateCompany />} />
      ))}

      {EditCompanyScreenDefinition.routePath.map((path) => (
        <Route key={path} path={path} element={<EditCompany />} />
      ))}

      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>
  );
};

export { CompaniesRouter };
