import { Box, Flex, Text } from "@chakra-ui/layout";
import { Divider, Tab, TabList, Tabs } from "@chakra-ui/react";
import { RowSelectionState } from "@tanstack/react-table";
import { Button, Search, TableVirtualised, Tooltip } from "components";
import { memo, useMemo, useState } from "react";
import { useComponentColors } from "theme";
import { useTrackSearched } from "tracking/useTrackSearched";
import { SummaryTableEntry } from "./SummaryTableEntry";
import { useColumns } from "./useColumns";
import { useData } from "./useData";
import { useSetFilters } from "./useFilters";
import { useUnselect } from "./useUnselect";

export const useSummaryTable = () => {
  const [categoryIndex, setCategoryIndex] = useState(0);

  return {
    categoryIndex,
    onCategoryIndexChange: setCategoryIndex,
    SummaryTable: () => (
      <SummaryTable
        categoryIndex={categoryIndex}
        onCategoryIndexChange={setCategoryIndex}
      />
    ),
  };
};

const SummaryTable = memo(
  (props: {
    categoryIndex: number;
    onCategoryIndexChange: (categoryIndex: number) => void;
  }) => {
    const { data, isDataAvailable } = useData(
      props.categoryIndex === 0 ? "topics" : "keywords"
    );
    const columns = useColumns();
    const setFilters = useSetFilters();
    const unselect = useUnselect();
    const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
    const isAnySelected = useMemo(
      () => Object.keys(rowSelection).length > 0,
      [rowSelection]
    );
    const { form } = useComponentColors();
    const trackSearched = useTrackSearched();

    if (!isDataAvailable) {
      return null;
    }

    return (
      <>
        <Flex w="100%" justifyContent="space-between" mb="1">
          <Text fontWeight="semibold" color={form.formLabelColor}>
            Selected items
          </Text>

          <Search
            onChange={(v) => {
              setFilters.setSearch(v);
              trackSearched({
                term: v,
                collocation: "Keywords and topics summary table ",
              });
            }}
          />
        </Flex>

        <Flex>
          <Tabs
            w="200px"
            variant="corporate"
            orientation="vertical"
            index={props.categoryIndex}
            onChange={props.onCategoryIndexChange}
          >
            <TabList w="100%" mt="0">
              <Tab>Topics</Tab>
              <Tab>Keywords</Tab>
            </TabList>
          </Tabs>

          <Box w="100%" data-testId="summary-table-selection-area">
            <TableVirtualised<SummaryTableEntry>
              selectable="checkbox-multi-select"
              data={data}
              columns={columns}
              rowSelection={rowSelection}
              setRowSelection={setRowSelection}
              onSortingChange={setFilters.setSorting}
              containerProps={{ width: "100%", height: "400px" }}
            />

            {data.length > 0 && (
              <Box mt="2">
                <Tooltip
                  aria-label="Delete selected items"
                  label="To delete items, select desired rows"
                  isDisabled={isAnySelected}
                >
                  <Button
                    variant="outline"
                    isDisabled={!isAnySelected}
                    onClick={() => {
                      setRowSelection({});
                      unselect(
                        Object.keys(rowSelection).map(
                          (rowIndex) => data[rowIndex as unknown as number]
                        )
                      );
                    }}
                  >
                    Delete
                  </Button>
                </Tooltip>
              </Box>
            )}
          </Box>
        </Flex>

        <Divider mt={8} />
      </>
    );
  }
);
