import { useResetTopPerformingState } from "./components/TopPerformingDomains/TopPerformingDomains.state";
import { useResetTopTrendingState } from "./components/TopTrendingDomains/TopTrendingAccounts.state";

export const useResetState = () => {
  const resetTopPerforming = useResetTopPerformingState();
  const resetTopTrending = useResetTopTrendingState();

  return () => {
    resetTopPerforming();
    resetTopTrending();
  };
};
