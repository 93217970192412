import { ColumnDef, RowData } from "@tanstack/react-table";
import { useMemo } from "react";
import { IndeterminateCheckbox } from "components";

export const useSelectColumn = <Data extends RowData>(
  props?: Partial<ColumnDef<Data>>
) => {
  const selectColumn = useMemo<ColumnDef<Data>>(
    () => ({
      id: "select",
      header: ({ table }) => (
        <IndeterminateCheckbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: table.getToggleAllRowsSelectedHandler(),
          }}
        />
      ),
      cell: ({ row }) => (
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      ),
      ...props,
    }),
    [props]
  );

  return selectColumn;
};
