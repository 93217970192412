import { useHasAccessTo } from "@intentsify/authorization/dist/react";
import { useQuery } from "@tanstack/react-query";
import { listCompanies } from "api";

type UseCompaniesParams = {
  hasCampaigns?: boolean;
  enabled?: boolean;
};

export const useCompanies = ({
  hasCampaigns,
  enabled = true,
}: UseCompaniesParams = {}) => {
  const canListCompanies = useHasAccessTo("campaign");

  const { data: dataCompanies, isLoading } = useQuery(
    ["companies", hasCampaigns],
    () => listCompanies({ hasCampaigns }),
    {
      enabled: enabled && canListCompanies,
    }
  );

  return {
    companies: dataCompanies?.companies,
    isLoading,
  };
};
