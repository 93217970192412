import { MeasureTextProps, measureTextWidth } from "../Charts.utils";
import { BarChartItem } from "./BarChart.types";

/**
 * Decides how much vertical space is needed for horizontal bar chart labels
 */
export const estimateSpaceForLabelHorizontal = (
  data: BarChartItem[],
  props: MeasureTextProps
) => {
  const longestLabel = data
    .map((i) => i.label)
    .sort((a, b) => b.length - a.length)[0];

  const longestWord = data
    .flatMap((i) => i.label.split(" "))
    .sort((a, b) => b.length - a.length)[0];

  if (!longestLabel || !longestWord) {
    return 100;
  }

  const forTotal = measureTextWidth(longestLabel, props);
  const forWord = measureTextWidth(longestWord, props);

  return Math.ceil(forWord > forTotal ? forWord : forTotal);
};

/**
 * Decides how many lines of text are needed to fit xAxis labels
 */
export const estimateLinesNeededForLabelXAxis = (
  labels: string[],
  props: MeasureTextProps & { widthAvailable: number }
) => {
  const longestLabel = labels.sort((a, b) => b.length - a.length)[0];
  const words = longestLabel.split(" ");

  const { lines } = words.slice(1, Infinity).reduce(
    (prev, curr) => {
      const withNextWord = `${prev.currentLine} ${curr}`;

      if (measureTextWidth(withNextWord, props) >= props.widthAvailable) {
        return {
          lines: prev.lines + 1,
          currentLine: curr,
        };
      }

      return { lines: prev.lines, currentLine: withNextWord };
    },
    { lines: 1, currentLine: words[0] }
  );

  return { lines };
};

type MultiLineSplitOptions = {
  maxMultiLineHeight: number;
  lineHeight: number;
};

const addEllipsis = (
  text: string,
  props: MeasureTextProps &
    MultiLineSplitOptions & {
      widthAvailable: number;
    }
): string => {
  const [firstWord, ...words] = text.split(/(?:(?!\u00A0+)\s+)/);
  const spaceWidth = measureTextWidth(" ", props);

  let currentLine = {
    position: 1,
    text: firstWord.trim(),
    width: measureTextWidth(firstWord.trim(), props),
    prevText: "",
  };
  const lines = [currentLine];

  for (const word of words) {
    const normalizedWord = word.trim();
    const currentWordWidth = measureTextWidth(normalizedWord, props);
    const appendedSize = currentLine.width + spaceWidth + currentWordWidth;

    if (appendedSize > props.widthAvailable) {
      if (currentLine.position * props.lineHeight > props.maxMultiLineHeight) {
        const lastLine = currentLine.text.replace(/-$/, ""); // we don't want to have ellipsis after word break
        const ellipsis = "...";
        const ellipsisWidth = measureTextWidth(ellipsis, props);

        return (
          [
            currentLine.prevText,
            currentLine.width + ellipsisWidth > props.widthAvailable
              ? lastLine.substring(0, currentLine.text.length - 3)
              : lastLine,
          ]
            .filter(Boolean)
            .join(" ") + ellipsis
        );
      }

      currentLine = {
        position: currentLine.position + 1,
        text: normalizedWord,
        width: currentWordWidth,
        prevText: currentLine.prevText
          ? `${currentLine.prevText} ${currentLine.text}`
          : currentLine.text,
      };
      lines.push(currentLine);
    } else {
      currentLine.text = `${currentLine.text} ${normalizedWord}`;
      currentLine.width = appendedSize;
    }
  }

  return text;
};

/**
 * If a single word is too long to fit within width constraints, split it into two, separated by "-"
 */
const splitLongLabel = (
  label: string,
  props: MeasureTextProps & {
    widthAvailable: number;
  },
  iteration = 0
): string => {
  if (iteration > 10) {
    return label;
  }

  const words = label.split(" ");

  const everyWordFits = words.every(
    (w) => measureTextWidth(w, props) <= props.widthAvailable
  );

  if (!everyWordFits) {
    const longestWord = [...words].sort((a, b) => b.length - a.length)[0];

    const newLabel = words
      .flatMap((w) => {
        if (longestWord === w) {
          return [
            `${w.slice(0, w.length / 2)}-`,
            `${w.slice(w.length / 2, Infinity)}`,
          ];
        }

        return w;
      })
      .join(" ");

    return splitLongLabel(newLabel, props, iteration + 1);
  }

  return label;
};

export const splitLongLabels = (
  data: BarChartItem[],
  props: MeasureTextProps &
    Partial<MultiLineSplitOptions> & {
      widthAvailable: number;
    }
) => {
  return data.map((i) => {
    let splittedLabel = splitLongLabel(i.label, props);

    if (props.maxMultiLineHeight && props.lineHeight) {
      const linesNumber =
        measureTextWidth(splittedLabel, props) / props.widthAvailable;

      if (linesNumber * props.lineHeight > props.maxMultiLineHeight) {
        splittedLabel = addEllipsis(
          splittedLabel,
          props as MeasureTextProps &
            MultiLineSplitOptions & {
              widthAvailable: number;
            }
        );
      }
    }

    return {
      ...i,
      label: i.label,
      formattedLabel: splittedLabel,
    };
  });
};
