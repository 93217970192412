import { Flex } from "@chakra-ui/layout";
import { Button, Search } from "components";
import { useState } from "react";
import { FiUpload } from "react-icons/fi";
import { useNavigate } from "react-router";
import { useTrackSearched } from "tracking/useTrackSearched";
import { AutomatedProgrammaticSegmentDefinition } from "../../../AutomatedProgrammaticSegmentForm/AutomatedProgrammaticSegmentForm.definition";
import { AutomatedProgrammaticSegmentsTable } from "./components";

const AutomatedProgrammaticSegments = () => {
  const navigate = useNavigate();

  const handleRedirectToUploadFile = () => {
    navigate(AutomatedProgrammaticSegmentDefinition.navigate());
  };

  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const trackSearched = useTrackSearched();

  return (
    <Flex width="100%" flexDirection="column">
      <Flex width="100%" alignItems="center">
        <Search
          currentValue={""}
          onSearch={(v) => {
            setSearchValue(v);
            trackSearched({
              term: v,
              collocation: "Automated Programmatic Segments table",
            });
          }}
        />

        <Flex grow={1} />

        <Button
          variant="primary-teal"
          leftIcon={<FiUpload />}
          onClick={handleRedirectToUploadFile}
        >
          Create Automated Segment
        </Button>
      </Flex>
      <AutomatedProgrammaticSegmentsTable searchValue={searchValue} />
    </Flex>
  );
};

export { AutomatedProgrammaticSegments };
