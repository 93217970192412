import {
  ButtonGroup,
  Flex,
  IconButton,
  IconButtonProps,
  Menu,
  MenuButton,
  MenuGroup,
  MenuList,
} from "@chakra-ui/react";
import { Fragment } from "react";
import { FiMoreHorizontal } from "react-icons/fi";
import { ActionItems } from "types";
import { Tooltip } from "../Tooltip";
import { ActionItem } from "./ActionItem";
import { isActionItem, isActionItemGroup } from "./Actions.utils";
import { MenuDivider } from "./MenuDivider";
import { MenuItem } from "./MenuItem";
import { useSafeActionItems } from "./useSafeActionItems";

export type ActionsProps = {
  items: ActionItems[];
  isLoading?: boolean;
  numberOfVisibleItems?: number;
  buttonIcon?: IconButtonProps["icon"];
  buttonsVariant?: IconButtonProps["variant"];
  iconsOnly?: boolean;
};

const Actions = ({
  items,
  isLoading,
  numberOfVisibleItems = Infinity,
  buttonIcon = <FiMoreHorizontal />,
  buttonsVariant,
  iconsOnly = true,
}: ActionsProps) => {
  const safeItems = useSafeActionItems(items);
  const visibleItems = safeItems.slice(0, numberOfVisibleItems);
  const expandableItems = safeItems.slice(numberOfVisibleItems);

  return (
    <Flex fontSize="sm" lineHeight="initial" zIndex="dropdown">
      <ButtonGroup
        variant="solid"
        size="sm"
        spacing={numberOfVisibleItems !== 1 ? 3 : 1}
      >
        {visibleItems.map((item, i) => {
          if (isActionItem(item)) {
            const {
              label,
              icon,
              onClick,
              dividerBefore,
              isLoading,
              isDisabled,
              isHidden,
              isDisabledTooltip,
              size,
              tootlipPlacement,
            } = item;

            if (isHidden) {
              return null;
            }

            return (
              <ActionItem
                key={label}
                isLoading={isLoading}
                isDisabled={isDisabled}
                isDisabledTooltip={isDisabledTooltip}
                label={label}
                icon={icon}
                size={size}
                onClick={onClick}
                dividerBefore={dividerBefore}
                index={i}
                tootlipPlacement={tootlipPlacement}
                iconOnly={iconsOnly}
                variant={buttonsVariant}
              />
            );
          }

          if (isActionItemGroup(item)) {
            return (
              <Flex key={item.title} gap={1}>
                {item.items.map((groupItem, i) => {
                  const {
                    label,
                    icon,
                    onClick,
                    dividerBefore,
                    isLoading,
                    isDisabled,
                    isDisabledTooltip,
                    size,
                    tootlipPlacement,
                  } = groupItem;

                  return (
                    <ActionItem
                      key={label}
                      isLoading={isLoading}
                      isDisabled={isDisabled}
                      isDisabledTooltip={isDisabledTooltip}
                      label={`${label}`}
                      icon={icon}
                      size={size}
                      onClick={onClick}
                      dividerBefore={dividerBefore}
                      index={i}
                      tootlipPlacement={tootlipPlacement}
                      iconOnly={iconsOnly}
                      variant={buttonsVariant}
                    />
                  );
                })}
              </Flex>
            );
          }
        })}

        {expandableItems.length > 0 && (
          <Menu autoSelect={false} placement="bottom-start">
            <Tooltip
              aria-label="More actions"
              label="More actions"
              placement="bottom-end"
            >
              <MenuButton
                disabled={isLoading}
                as={IconButton}
                icon={buttonIcon}
                variant={buttonsVariant}
              />
            </Tooltip>

            <MenuList shadow={"none"}>
              {expandableItems.map((item, i) => {
                const notFirst = i !== 0;
                const notLast = i !== expandableItems.length - 1;

                const hideDivider = isActionItemGroup(expandableItems[i - 1]);

                if (isActionItem(item)) {
                  const {
                    label,
                    icon,
                    onClick,
                    dividerBefore,
                    isLoading,
                    isDisabled,
                    isDisabledTooltip,
                    isHidden,
                    size,
                  } = item;

                  if (isHidden) {
                    return null;
                  }

                  return (
                    <MenuItem
                      key={label}
                      label={label}
                      isDisabled={isDisabled}
                      isDisabledTooltip={isDisabledTooltip}
                      isLoading={isLoading}
                      icon={icon}
                      size={size}
                      onClick={onClick}
                      dividerBefore={!hideDivider && dividerBefore}
                      index={i}
                    />
                  );
                }

                if (isActionItemGroup(item)) {
                  return (
                    <Fragment key={item.title}>
                      {!hideDivider && notFirst && <MenuDivider />}
                      <MenuGroup title={item.title} textAlign="left">
                        {item.items.map((groupItem, i) => {
                          const {
                            label,
                            icon,
                            onClick,
                            isLoading,
                            isDisabled,
                            isDisabledTooltip,
                            isHidden,
                            size,
                          } = groupItem;

                          if (isHidden) {
                            return null;
                          }

                          return (
                            <MenuItem
                              key={label}
                              label={label}
                              isDisabled={isDisabled}
                              isDisabledTooltip={isDisabledTooltip}
                              isLoading={isLoading}
                              icon={icon}
                              size={size}
                              onClick={onClick}
                              index={i}
                            />
                          );
                        })}
                      </MenuGroup>
                      {notLast && <MenuDivider />}
                    </Fragment>
                  );
                }
              })}
            </MenuList>
          </Menu>
        )}
      </ButtonGroup>
    </Flex>
  );
};

export { Actions };
