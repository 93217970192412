import { ToastId, useInterval, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { Endpoints, apiService } from "api";
import { useRef } from "react";
import { useAppStore } from "store/useAppStore";
import { useDeepEffect } from "utils";
import {
  AUTOMATIC_RELOAD_TIMEOUT,
  CHECK_FOR_NEW_VERSION_INTERVAL_DURATION,
  UPDATE_RECOMMENDED_TOAST_REMINDER_DURATION,
  UPDATE_REQUIRED_TOAST_REMINDER_DURATION,
} from "./CheckForUpdates.const";
import {
  calculateRequiredUpdates,
  usePageVisibility,
} from "./CheckForUpdates.utils";
import { Toast } from "./Toast";

/**
 * Responsible for checking if a new version of the app is available. Manages an interval that requests the /version endpoint
 * hosted from the portal-api.
 *
 * Portal Client and Portal API have semantic versions in sync.
 *
 * 1. If a new version is available that recommended an update:
 *  - show a dismissable toast with an option to reload the tab,
 *  - if dissmissed, remind the user in a later time; keep checking for updates, maybe an urgent update is required.
 *
 * 2. If a new version is available that required an update:
 *  - show a dismissable toast with a countdown, if countdown is reached, reload programatically,
 * - if dismissed, remind the user but fairly quickly.
 *
 */
export const useCheckForUpdates = () => {
  const toast = useToast();
  const toastId = useRef<ToastId | undefined>();

  const latestVersion = useAppStore.use.version();
  const setLatestVersion = useAppStore.use.setVersion();

  const { isPageVisible } = usePageVisibility();

  /**
   * We want to check inspect Portal API version newer than the clients. The reason is:
   * if any of the newer builds recommended or required update, we need to act.
   */

  const recommendUpdate = latestVersion?.recommendUpdate || false;
  const requireUpdate = latestVersion?.requireUpdate || false;

  const { mutate } = useMutation(
    async () => {
      return await apiService.get<string>(Endpoints.Version.Get.Version, {
        headers: {
          // Having these empty will make it so the respone is not served from cache.
          "if-none-match": "",
          "if-modified-since": "",
        },
      });
    },
    {
      onSuccess: (res) => {
        const clientVersion = process.env.VITE_CLIENT_VERSION || "1.0.0";
        const { recommendUpdate, requiredUpdate } = calculateRequiredUpdates({
          clientVersion,
          apiVersion: res.data,
        });
        setLatestVersion({
          versionNumber: res.data,
          recommendUpdate: recommendUpdate,
          requireUpdate: requiredUpdate,
        });
      },
    }
  );

  useInterval(() => {
    if (isPageVisible) {
      mutate();
    }
  }, CHECK_FOR_NEW_VERSION_INTERVAL_DURATION);

  useDeepEffect(() => {
    if (!(recommendUpdate || requireUpdate)) {
      return;
    }

    if (toastId.current) {
      toast.close(toastId.current);
    }

    toastId.current = toast({
      position: "top",
      duration: null,
      render: ({ onClose }) => {
        return (
          <Toast
            onClose={() => {
              onClose();
            }}
            onUserClose={() => {
              setTimeout(
                () => {
                  setLatestVersion(undefined);
                },
                requireUpdate
                  ? UPDATE_REQUIRED_TOAST_REMINDER_DURATION
                  : UPDATE_RECOMMENDED_TOAST_REMINDER_DURATION
              );
              onClose();
            }}
            forceReload={requireUpdate}
            reloadTimeout={AUTOMATIC_RELOAD_TIMEOUT}
          />
        );
      },
    });
  }, [toast, setLatestVersion, recommendUpdate, requireUpdate]);
};
