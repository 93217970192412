import { useAppStore } from "store/useAppStore";

export const useUserProfile = () => {
  const user = useAppStore.use.user();
  const impersonation = useAppStore.use.impersonation();

  return {
    displayName: impersonation
      ? impersonation.impersonates.displayName
      : user?.displayName,
    email: impersonation ? impersonation.impersonates.email : user?.email,
    companyTrialEndDate: impersonation
      ? impersonation.impersonates.companyTrialEndDate
      : user?.companyTrialEndDate,
  };
};
