import { useHasAccessTo } from "@intentsify/authorization/dist/react";
import { ListReviewedAssetsRO } from "@intentsify/dto";
import { BuyerResearchStage, ReviewedAsset } from "@intentsify/types";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Endpoints, apiService } from "api";
import { FetchDataParamsWithToken } from "types";
import { valueToQueryURL } from "utils";

type FetchReviewedAssetParams = FetchDataParamsWithToken<
  keyof ReviewedAsset
> & {
  campaignIds?: number[];
  buyerResearchStages?: BuyerResearchStage[];
};

export const useReviewedAssets = (
  fetchDataParams: FetchReviewedAssetParams
) => {
  const hasAccess = useHasAccessTo("contentLibrary");

  return useQuery({
    retry: false,
    enabled: hasAccess,
    queryKey: getQueryKey(fetchDataParams),
    queryFn: async () => {
      return await fetchReviewedAssets(fetchDataParams);
    },
  });
};

export const usePrefetchReviewedAssets = (
  fetchDataParams: FetchReviewedAssetParams
) => {
  const queryClient = useQueryClient();
  const hasAccess = useHasAccessTo("contentLibrary");

  if (!hasAccess) {
    return;
  }

  return queryClient.prefetchQuery({
    retry: false,
    queryKey: getQueryKey(fetchDataParams),
    queryFn: async () => {
      return await fetchReviewedAssets(fetchDataParams);
    },
  });
};

function getQueryKey(fetchDataParams: FetchReviewedAssetParams) {
  return ["contentLibrary", "reviewedAssets", fetchDataParams];
}

async function fetchReviewedAssets(fetchDataParams: FetchReviewedAssetParams) {
  const params = { ...fetchDataParams };

  if ("search" in params && !params.search) {
    delete params.search;
  }

  if (
    "campaignIds" in params &&
    params.campaignIds &&
    params.campaignIds.length > 0
  ) {
    params.campaignIds = valueToQueryURL(params.campaignIds) as any;
  }

  if (
    "buyerResearchStages" in params &&
    params.buyerResearchStages &&
    params.buyerResearchStages.length > 0
  ) {
    params.buyerResearchStages = valueToQueryURL(
      params.buyerResearchStages
    ) as any;
  }

  const { data } = await apiService.get<ListReviewedAssetsRO>(
    Endpoints.ContentLibrary.ReviewedAssets.Get.ListPaginated,
    { params }
  );

  return data;
}
