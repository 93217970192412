import { ComponentPropsWithoutRef, ElementType } from "react";
import { Text } from "@chakra-ui/react";
import { NoFilesUploadedNonIdealState } from "./NonIdealState";

type NonIdealStateVariant<
  Variant extends string,
  Element extends ElementType
> = {
  variant: Variant;
} & ComponentPropsWithoutRef<Element>;

export type NonIdealStateOptions =
  | undefined
  | boolean
  | NonIdealStateVariant<
      "noUploadedFiles",
      typeof NoFilesUploadedNonIdealState
    >;

const Empty = () => null;

const DefaultNonIdealState = () => {
  return <Text>No data</Text>;
};

export const useNonIdealState = (nonIdealState: NonIdealStateOptions) => {
  if (!nonIdealState) {
    return DefaultNonIdealState;
  }

  if (typeof nonIdealState === "boolean") {
    if (nonIdealState) {
      return DefaultNonIdealState;
    }

    return Empty;
  }

  const { variant, ...props } = nonIdealState;

  if (variant === "noUploadedFiles") {
    return () => <NoFilesUploadedNonIdealState {...props} />;
  }

  return Empty;
};
