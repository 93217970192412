import type {
  AccountOpportunityDisplay as AccountOpportunityDisplayType,
  AccountOpportunityDisplayInfo,
} from "@intentsify/types";
import { Box, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import {
  decimalToPercentage,
  pluralize,
  toNumberDisplayValue,
} from "@intentsify/utils";
import React, { useMemo } from "react";
import { TextWithTooltip } from "components";

interface AccountOpportunityDisplayProps {
  displays: AccountOpportunityDisplayType;
}

export const AccountOpportunityDisplay = ({
  displays,
}: AccountOpportunityDisplayProps) => {
  const locations = useMemo(
    () =>
      (
        Object.values(displays as Record<string, AccountOpportunityDisplayInfo>)
          .find(({ location }) => Boolean(location))
          ?.location?.split("/") ?? []
      ).map((location) => location.trim()),
    [displays]
  );

  const [firstLocation, ...restLocations] = locations;
  const impressionsScore = displays?.impressions?.score;
  const clicksScore = displays?.clicks?.score;
  const videoPlaysScore = displays?.videoPlays?.score;
  const ctrScore = displays?.ctr?.score;
  const conversionScore = displays?.conversions?.score;

  return (
    <>
      {Boolean(firstLocation) && (
        <>
          <Text>
            Generated from <Text as="strong">{firstLocation}</Text>{" "}
            {restLocations.length > 1 && (
              <>
                and{" "}
                <TextWithTooltip
                  renderTooltip={() => (
                    <UnorderedList>
                      {restLocations.map((location) => (
                        <ListItem key={location}>{location}</ListItem>
                      ))}
                    </UnorderedList>
                  )}
                >
                  {restLocations.length} other{" "}
                  {pluralize(restLocations.length, "location")}
                </TextWithTooltip>
              </>
            )}
          </Text>
        </>
      )}

      {typeof clicksScore === "number" && (
        <Box>
          <Text>Total Clicks: {toNumberDisplayValue(clicksScore)}</Text>
        </Box>
      )}

      {typeof impressionsScore === "number" && (
        <Box>
          <Text>
            Total Impressions: {toNumberDisplayValue(impressionsScore)}
          </Text>
        </Box>
      )}

      {typeof videoPlaysScore === "number" && videoPlaysScore > 0 && (
        <Box>
          <Text>
            Total Video Views: {toNumberDisplayValue(videoPlaysScore)}
          </Text>
        </Box>
      )}

      {typeof ctrScore === "number" && (
        <Box>
          <Text>
            Overall CTR: {toNumberDisplayValue(decimalToPercentage(ctrScore))}%
          </Text>
        </Box>
      )}

      {typeof conversionScore === "number" && conversionScore > 0 && (
        <Box>
          <Text>
            Total Conversions: {toNumberDisplayValue(conversionScore)}
          </Text>
        </Box>
      )}
    </>
  );
};
