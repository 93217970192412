import { DownloadLeadActivationSummariesDTO } from "@intentsify/dto";
import { LeadSignalRow, SignalType } from "@intentsify/types";
import { getExportFileName, isPopulatedArray } from "@intentsify/utils";
import { RowSelectionState } from "@tanstack/react-table";
import { Endpoints } from "api";
import { Button } from "components";
import { useState } from "react";
import { useDownloadFile } from "utils";
import { useSignal } from "../../../../CampaignDetailsTable.hooks";
import { SignalsTable } from "../SignalsTable";
import { useLeadsColumns } from "./Leads.columns";

const Leads = ({
  campaignId,
  weekNumber,
  yearNumber,
  domainId,
}: {
  campaignId: number;
  domainId: number;
  weekNumber: number;
  yearNumber: number;
}) => {
  const fileName = getExportFileName({
    prefix: `lead_activation_summaries`,
    data: {
      id: campaignId,
    },
    extension: ".zip",
  });

  const { download } = useDownloadFile();

  const [rowSelectionState, setRowSelectionState] = useState<RowSelectionState>(
    {}
  );

  const selectedLeadIds = Object.keys(rowSelectionState).map(Number);

  const { data } = useSignal<LeadSignalRow[]>({
    campaignId,
    domainId,
    signalId: SignalType.Leads,
    weekNumber,
    yearNumber,
  });

  const columns = useLeadsColumns();

  return (
    <>
      {isPopulatedArray(Object.entries(rowSelectionState)) && (
        <Button
          mb={4}
          variant="primary-teal"
          onClick={() => {
            if (!data) {
              return;
            }

            const params = {
              weekNumber,
              yearNumber,
              summaries: data
                .filter((i) => selectedLeadIds.includes(i.leadId))
                .map((i) => ({
                  leadId: i.leadId,
                  domainId,
                })),
            } satisfies DownloadLeadActivationSummariesDTO;

            download({
              url: Endpoints.CampaignDetails.POST.DownloadLeadActivationSummaries(
                campaignId
              ),
              fileName,
              data: params,
              method: "POST",
            });
          }}
        >
          Download Selected Summaries
        </Button>
      )}

      <SignalsTable
        columns={columns}
        defaultSort={[
          {
            id: "name",
            desc: true,
          },
        ]}
        campaignId={campaignId}
        domainId={domainId}
        signalId={SignalType.Leads}
        weekNumber={weekNumber}
        yearNumber={yearNumber}
        rowSelectionState={rowSelectionState}
        setRowSelectionState={setRowSelectionState}
        uniqueKeyAccessor={(row) => row.leadId}
      />
    </>
  );
};

export { Leads };
