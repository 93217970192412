import { TusStorage, createTusUpload } from "@intentsify/tus/dist/react";
import { Endpoints } from "api";

export const talToolsUpload = createTusUpload({
  uploadUrl: new URL(
    `${process.env.VITE_REACT_APP_API_URL ?? `http://localhost:3001`}${
      Endpoints.AccountsUpload.All.Upload
    }`
  ),
  autoResumeUploads: false,
  storage: new TusStorage<"talToolsUpload">("talToolsUpload"),
});
