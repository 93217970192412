import { useToast } from "@chakra-ui/react";
import { Company, WorkatoConnectionProvider } from "@intentsify/types";
import { useMutation } from "@tanstack/react-query";
import { Endpoints, apiService } from "api";
import { useInvalidateCompaniesList } from "../../../../../../../../queries/companies/useCompanyQueryOptions";

const establishWorkatoIntegration = async ({
  companyId,
  provider,
}: {
  companyId: Company["companyId"];
  provider: WorkatoConnectionProvider;
}) =>
  apiService.post(
    Endpoints.Companies.Post.EstablishWorkatoIntegration(companyId),
    { provider }
  );

export const useEstablishWorkatoIntegration = (
  companyId: Company["companyId"],
  provider: WorkatoConnectionProvider
) => {
  const invalidateCompaniesList = useInvalidateCompaniesList();
  const toast = useToast();
  const results = useMutation(
    () => establishWorkatoIntegration({ companyId, provider }),
    {
      onSuccess: () => {
        invalidateCompaniesList();
        toast({
          title: "Integration established",
          status: "success",
        });
      },
    }
  );

  return results;
};
