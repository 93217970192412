import { Box, DarkMode } from "@chakra-ui/react";
import { IconButton } from "components";
import { MdNotifications } from "react-icons/md";
import { useAppStore } from "store/useAppStore";
import { useUnreadCount } from "../../Notifications.hooks";

const iconColor = "gray.300";

const NotificationIcon = ({ onClick }: { onClick: () => void }) => {
  const unreadCount = useUnreadCount();
  const impersonation = useAppStore.use.impersonation();

  return (
    <Box>
      <DarkMode>
        {!impersonation && (
          <IconButton
            size="sm"
            color={iconColor}
            tooltip={
              unreadCount > 0
                ? `You have unread notifications`
                : "You have no unread notifications"
            }
            icon={<MdNotifications size="24px" />}
            onClick={onClick}
            tooltipPlacement="bottom-end"
          />
        )}

        {impersonation && (
          <IconButton
            isDisabled
            size="sm"
            color={iconColor}
            tooltip="Notifications are not available while impersonating."
            icon={<MdNotifications size="24px" />}
            onClick={() => undefined}
            tooltipPlacement="bottom-end"
          />
        )}
      </DarkMode>
    </Box>
  );
};

export { NotificationIcon };
