import { Text, useColorModeValue } from "@chakra-ui/react";
import { WithChildren } from "types";

const SectionHeader = ({ children }: WithChildren) => {
  const color = useColorModeValue(
    "var(--chakra-colors-gray-600)",
    "var(--chakra-colors-gray-100)"
  );

  return (
    <Text mt={4} mb={6} fontSize="md" color={color} fontWeight="semibold">
      {children}
    </Text>
  );
};

export { SectionHeader };
