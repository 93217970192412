import { GetProgrammaticCampaignTopAccountsRO } from "@intentsify/dto";
import { getColumns } from "./TopAccounts.utils";
import { Table } from "components";

type TopAccountsTableProps = {
  isLoading: boolean;
  data: GetProgrammaticCampaignTopAccountsRO | undefined;
  hasVisits: boolean;
  hasConversions: boolean;
};

const TopAccountsTable = ({
  data,
  isLoading,
  hasVisits,
  hasConversions,
}: TopAccountsTableProps) => {
  return (
    <Table
      variant="intentsifyClean"
      hidePagination={true}
      isControlledByAPI={false}
      isFetching={isLoading}
      data={data?.topAccounts ?? []}
      columns={getColumns(hasVisits, hasConversions)}
      defaultPageSize={25}
    />
  );
};

export { TopAccountsTable };
