import { Flex, Text as ChakraText } from "@chakra-ui/react";
import { formatDateRange } from "@intentsify/utils";
import { useRecoilValue } from "recoil";
import { hoveredNearestPointAtomFamily } from "../../Timeseries.state";
import { TimeseriesTooltipProps } from "./TimeseriesTooltip.types";
import { CellWithChange } from "components";

const GenericSnapToTooltip = <K extends string, DK extends string>({
  tooltipData,
  dataMainKey,
  timeseries,
  textColor,
  uniqueKey,
}: TimeseriesTooltipProps<K, DK>) => {
  const hoveredNearestPoint = useRecoilValue(
    hoveredNearestPointAtomFamily(uniqueKey)
  );

  if (!hoveredNearestPoint) {
    return null;
  }

  const currentDateData = hoveredNearestPoint?.datum.timeseries.find(
    (s) => s.isoDate === hoveredNearestPoint.isoDate
  );

  if (!currentDateData) {
    return null;
  }

  const index = hoveredNearestPoint.datum.timeseries.indexOf(currentDateData);
  const previousDateData = hoveredNearestPoint.datum.timeseries[index - 1];

  const label = formatDateRange(timeseries[index]);

  return (
    <Flex flexDir="column" color={textColor}>
      <ChakraText fontSize="medium" mb={4}>
        <b>{tooltipData[dataMainKey]}</b> - {label}
      </ChakraText>

      <CellWithChange
        value={Number(currentDateData.data.value)}
        previousValue={Number(previousDateData?.data.value)}
      />
    </Flex>
  );
};

export { GenericSnapToTooltip };
