import { DateRange, SignalType } from "@intentsify/types";
import { apiService, Endpoints } from "api";
import { BarChartItem } from "components";

export const getTopSignals = async ({
  campaignId,
  signalType,
  dateRange,
  size,
}: {
  campaignId: number;
  signalType: SignalType;
  dateRange: DateRange;
  size: number;
}): Promise<BarChartItem[]> => {
  const { data } = await apiService.get(
    Endpoints.Campaigns.Get.Signals.TopSignals(Number(campaignId)),
    {
      params: {
        dateRange,
        signalType,
        size,
      },
    }
  );
  return data;
};
