import { Heading, Text } from "@chakra-ui/react";
import { sanitizeKeyword } from "@intentsify/utils";
import { useCallback, useEffect, useMemo } from "react";

import {
  Button,
  Modal,
  StatusModal,
  useWindowedCheckbox,
  WindowedCheckbox,
} from "components";
import { Item } from "types";
import { KeywordItem, VerifiedKeywords } from "types/Keyword";

const shouldVerifiedKeywordBePreSelected = (
  unsafeVerifiedKeyword: VerifiedKeywords
) => {
  if (unsafeVerifiedKeyword.exists) {
    return false;
  }

  const verifiedKeyword = sanitizeKeyword({
    keyword: unsafeVerifiedKeyword.keyword,
  });

  if (verifiedKeyword.length > 6) {
    return true;
  }

  const sanitizedKeywordWords = verifiedKeyword.split(" ");

  if (sanitizedKeywordWords.length === 2) {
    return true;
  }

  return false;
};

const transformVerifiedKeywordIntoItem = (
  verifiedKeyword: VerifiedKeywords
) => ({ label: verifiedKeyword.keyword, value: verifiedKeyword.keyword });

type KeywordsUploadProps = {
  isOpen: boolean;
  verifiedKeywords: {
    data: VerifiedKeywords[];
    toMergeWith: KeywordItem[];
  };
  isLoading: boolean;
  onClose: () => void;
  saveKeywords: (keywords: string[]) => void;
};

export const UploadKeywordsModal = ({
  isOpen,
  onClose,
  isLoading,
  verifiedKeywords,
  saveKeywords,
}: KeywordsUploadProps) => {
  const { checkedItems, onCheck, onCheckBulk } = useWindowedCheckbox<Item>({
    checked: [],
  });

  const preSelectedKeywords = useMemo(
    () =>
      verifiedKeywords.data
        .filter(shouldVerifiedKeywordBePreSelected)
        .map(transformVerifiedKeywordIntoItem),
    [verifiedKeywords.data]
  );

  useEffect(() => {
    onCheckBulk(preSelectedKeywords);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preSelectedKeywords]);

  const nextKeywordsItems = useMemo(
    () =>
      verifiedKeywords.data
        .filter((k) => !k.exists)
        .map(transformVerifiedKeywordIntoItem),
    [verifiedKeywords]
  );

  const handleConfirm = useCallback(() => {
    const nextKeywords = [
      ...verifiedKeywords.data.filter((k) => k.exists).map((k) => k.keyword),
      ...checkedItems.map((k) => k.label),
    ];

    saveKeywords(nextKeywords);
  }, [checkedItems, saveKeywords, verifiedKeywords.data]);

  if (isLoading) {
    return <StatusModal status="Processing..." isOpen={isOpen} />;
  }

  return (
    <Modal
      size="6xl"
      isOpen={isOpen}
      onClose={onClose}
      title={
        <>
          <Heading size="md" mb={2}>
            New keywords
          </Heading>

          <Text fontSize="medium">
            Please verify and confirm that you want to add these keywords.
          </Text>
        </>
      }
      primaryButton={
        <Button size="md" variant="success" onClick={handleConfirm}>
          Confirm
        </Button>
      }
      body={
        <WindowedCheckbox
          checkedItems={checkedItems}
          onCheck={onCheck}
          data={nextKeywordsItems}
          subject="keywords"
        />
      }
    />
  );
};
