import { apiService, Endpoints } from "api";

export const addBusinessUnit = async ({
  companyId,
  displayName,
}: {
  companyId: number;
  displayName: string;
}) =>
  await apiService.post(
    Endpoints.Companies.Post.CreateBusinessUnit(companyId),
    {
      displayName,
    }
  );
