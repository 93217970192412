import { useCallback } from "react";
import { useLocation } from "react-router";
import { RequestReportingProperties, ampli } from "./amplitude";

export const useTrackRequestReporting = () => {
  const location = useLocation();

  return useCallback(
    ({
      requestingDeck,
      campaignId,
    }: Omit<RequestReportingProperties, "pagePath">) => {
      ampli.requestReporting({
        requestingDeck,
        campaignId,
        pagePath: location.pathname,
      });
    },
    [location.pathname]
  );
};
