import { Flex } from "@chakra-ui/layout";
import { Loader, ViewContainer } from "components";
import { useScreen } from "utils";
import { TargetPersonasTable } from "./TargetPersonasTable";
import { TargetPersonasDefinition } from "./TargetPersonas.definition";
import { useSearch } from "./filters";
import { CreateTargetPersonaButton } from "./CreateTargetPersonaButton";
import { TargetPersonaBlocklist } from "./TargetPersonaBlocklist/TargetPersonaBlocklist";
import { useUserAgencyCompany } from "../../queries/companies/useUserAgencyCompany";

export function TargetPersonas() {
  useScreen(TargetPersonasDefinition);
  const { data, isLoading } = useUserAgencyCompany();
  const search = useSearch();

  return (
    <ViewContainer>
      <Flex flexDir="column" rowGap="4">
        {isLoading && <Loader />}
        {data && (
          <>
            <Flex justifyContent="space-between" alignItems="flex-end">
              <Flex columnGap="4" alignItems="flex-end">
                <search.Input />
              </Flex>

              <CreateTargetPersonaButton />
            </Flex>

            <TargetPersonasTable searchValue={search.value} />

            <TargetPersonaBlocklist companyId={data.companyId} />
          </>
        )}
      </Flex>
    </ViewContainer>
  );
}
