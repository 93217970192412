import { useToast } from "@chakra-ui/react";
import { useHasAccessTo } from "@intentsify/authorization/dist/react";
import {
  ListJobLevelsRO,
  ListTargetPersonasRO,
  listJobFunctionsRO,
} from "@intentsify/dto";
import { TargetPersona } from "@intentsify/types";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Endpoints, apiService } from "api";
import { FetchDataParamsWithToken } from "types";
import { TargetPersonaCreate } from "./Form";

export function useTargetPersonas(
  fetchDataParams: FetchDataParamsWithToken<keyof TargetPersona>
) {
  const hasAccess = useHasAccessTo("buyingGroup");

  return useQuery({
    retry: false,
    enabled: hasAccess,
    queryKey: ["targetPersonas", fetchDataParams],
    queryFn: async () => {
      return await getTargetPersonas(fetchDataParams);
    },
  });
}

export async function getTargetPersonas(
  fetchDataParams: FetchDataParamsWithToken<keyof TargetPersona>
) {
  const params = { ...fetchDataParams };

  if ("search" in params && !params.search) {
    delete params.search;
  }

  const { data } = await apiService.get<ListTargetPersonasRO>(
    Endpoints.TargetPersonas.Get.Paginated,
    { params }
  );

  return data;
}

export async function getJobLevels() {
  const {
    data: { jobLevels },
  } = await apiService.get<ListJobLevelsRO>(
    Endpoints.TargetPersonas.JobLevels.Get.All
  );

  return {
    results: jobLevels.map(({ id, name }) => ({ value: id, label: name })),
  };
}

export async function getJobFunctions() {
  const {
    data: { jobFunctions },
  } = await apiService.get<listJobFunctionsRO>(
    Endpoints.TargetPersonas.JobFunctions.Get.All
  );

  return {
    results: jobFunctions.map(({ id, name }) => ({ value: id, label: name })),
  };
}

export const useCreateTargetPersona = ({
  onSuccess,
}: {
  onSuccess: (data: TargetPersonaCreate) => void;
}) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["targetPersonas", "create"],
    mutationFn: async (targetPersona: TargetPersonaCreate) => {
      const { data } = await apiService.post<{
        targetPersona: TargetPersona;
      }>(Endpoints.TargetPersonas.Post.Create, targetPersona);

      return data.targetPersona;
    },
    onSuccess: (data) => {
      onSuccess(data);

      toast({
        title: `A new target persona has been created.`,
        status: "success",
      });
      queryClient.setQueriesData<ListTargetPersonasRO>(
        { queryKey: ["targetPersonas"], exact: false },
        (oldData) => ({
          ...oldData,
          targetPersonas: [data, ...(oldData?.targetPersonas || [])],
        })
      );
    },
  });
};
