import { Company } from "@intentsify/types";
import { useMemo, useState } from "react";
import { IntegrationWithLogo } from "../IntegrationsModal.utils";
import { DisabledProvider } from "./Providers/DisabledProvider";
import { EditingCampaignsProvider } from "./Providers/EditingCampaignsProvider";
import { EnabledProvider } from "./Providers/EnabledProvider";
import { LoadingProvider } from "./Providers/LoadingProvider";
import { useCampaignsAssignedToIntegration } from "./queries/useCampaignsAssignedToIntegrations";

interface IntegrationProviderProps {
  companyId: Company["companyId"];
  integration: IntegrationWithLogo;
}

export const IntegrationProvider = (props: IntegrationProviderProps) => {
  const { data: campaignsAssigned, isLoading } =
    useCampaignsAssignedToIntegration(props.companyId);

  const campaigns = useMemo(
    () =>
      (campaignsAssigned || []).map((campaign) => ({
        campaignId: campaign.campaignId,
        displayName: campaign.displayName,
      })),
    [campaignsAssigned]
  );

  const [isEditing, setIsEditing] = useState(false);

  if (!props.integration.connectionId) {
    return (
      <DisabledProvider
        companyId={props.companyId}
        integration={props.integration}
      />
    );
  }

  if (isLoading) {
    return <LoadingProvider integration={props.integration} />;
  }

  if (isEditing || campaigns.length === 0) {
    return (
      <EditingCampaignsProvider
        companyId={props.companyId}
        integration={props.integration}
        defaultCampaignsAssigned={campaigns}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
      />
    );
  }

  if (campaigns.length > 0) {
    return (
      <EnabledProvider
        integration={props.integration}
        campaignsAssigned={campaigns}
        setIsEditing={setIsEditing}
      />
    );
  }

  throw new Error("IntegrationProvider: Invalid state");
};
