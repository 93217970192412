import { Box, BoxProps, Portal, Stack, Text } from "@chakra-ui/react";
import { isPopulatedArray } from "@intentsify/utils";
import noop from "lodash/noop";
import { HTMLInputTypeAttribute, useRef } from "react";
import { useComponentColors } from "theme";
import { useDeepEffect } from "utils";
import {
  DynamicInputForm,
  DynamicInputFormProps,
  DynamicInputItem,
} from "./components";

type DynamicInputProps<T extends number | string> = {
  label?: string;
  items: T[];
  addItem: (item: T) => void;
  removeItem: (item: T) => void;
  inputType?: HTMLInputTypeAttribute;
  formProps?: Partial<DynamicInputFormProps<T>>;
  containerProps?: BoxProps;
  onChange?: (items: T[]) => void;
  placeholder?: string;
  size?: "sm" | "md";
};

export const DynamicInput = <T extends string | number>({
  label,
  items,
  addItem,
  removeItem,
  inputType = "number",
  formProps = {},
  containerProps = {},
  onChange = noop,
  placeholder,
  size = "sm",
}: DynamicInputProps<T>) => {
  const ref = useRef<HTMLDivElement>(null);
  const componentColors = useComponentColors();

  useDeepEffect(() => {
    onChange(items);
  }, [items]);

  return (
    <>
      <Portal containerRef={ref}>
        <Stack {...containerProps}>
          {label && (
            <Text
              p="0"
              m="0"
              fontSize="sm"
              fontWeight="md"
              color={componentColors.form.formLabelColor}
            >
              {label}:
            </Text>
          )}

          {isPopulatedArray(items) && (
            <Box>
              {items.map((item, index) => {
                return (
                  <DynamicInputItem
                    key={index}
                    item={item}
                    removeItem={removeItem}
                  />
                );
              })}
            </Box>
          )}

          <DynamicInputForm
            {...formProps}
            addItem={addItem}
            inputType={inputType}
            placeholder={placeholder}
            size={size}
          />
        </Stack>
      </Portal>
      <div ref={ref} />
    </>
  );
};
