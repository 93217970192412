import { ReactNode } from "react";
import { atomFamily, useRecoilState, useSetRecoilState } from "recoil";
import { DialogId } from "./ConfirmDialog.stateIds";

export interface PublicConfirmDialogState {
  title: ReactNode;
  description?: ReactNode;
  confirmText: ReactNode;
  onCancel?: () => void;
}

interface ConfirmDialogState extends PublicConfirmDialogState {
  promise: { resolve: (c: boolean) => void } | undefined;
}

const confirmDialogState = atomFamily<ConfirmDialogState, DialogId>({
  key: "confirmDialog",
  default: {
    title: null,
    description: null,
    confirmText: null,
    onCancel: () => undefined,
    promise: undefined,
  },
});

export const useConfirmDialogState = (dialogId: DialogId) => {
  return useRecoilState(confirmDialogState(dialogId));
};

export const useSetConfirmDialogState = (dialogId: DialogId) => {
  return useSetRecoilState(confirmDialogState(dialogId));
};
