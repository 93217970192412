import { ButtonGroup } from "@chakra-ui/react";
import { BsFillDashSquareFill, BsPlusSquareFill } from "react-icons/bs";
import { IconButton } from "../../IconButton";

type ExpandAllProps = {
  onExpandAll: () => void;
  onCollapseAll: () => void;
};

const ExpandAll = ({ onExpandAll, onCollapseAll }: ExpandAllProps) => {
  return (
    <ButtonGroup spacing={0}>
      <IconButton
        tooltip="Expand all"
        icon={<BsPlusSquareFill />}
        variant="ghost"
        colorScheme="cyan"
        onClick={onExpandAll}
      />

      <IconButton
        tooltip="collapse-all"
        icon={<BsFillDashSquareFill />}
        onClick={onCollapseAll}
        variant="ghost"
        colorScheme="cyan"
      />
    </ButtonGroup>
  );
};

export { ExpandAll };
