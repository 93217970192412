import { Alert, AlertIcon } from "@chakra-ui/react";
import { whenWillIntentDataBeAvailable } from "@intentsify/utils";
import { Loader, ViewContainer } from "components";
import { DateTime } from "luxon";
import { useMemo } from "react";
import { useParams } from "react-router";
import { WithChildren } from "types";
import { useCampaignMeta } from "../campaign";

type WithIntentDataAvailableProps = WithChildren;

const WithIntentDataAvailable = ({
  children,
}: WithIntentDataAvailableProps) => {
  const campaignId = Number(useParams<"campaignId">().campaignId);

  const { campaignMeta } = useCampaignMeta({ campaignId });

  const availableAt = useMemo(() => {
    if (!campaignMeta) {
      return undefined;
    }

    return DateTime.fromISO(
      whenWillIntentDataBeAvailable(campaignMeta.campaignCreateDate)
    )
      .toUTC()
      .toISODate();
  }, [campaignMeta]);

  if (!campaignMeta) {
    return (
      <ViewContainer>
        <Loader />
      </ViewContainer>
    );
  }

  const hasData = campaignMeta.isIntentAnalysisDataAvailable;

  if (!hasData) {
    return (
      <Alert borderRadius="md">
        <AlertIcon />
        Data for this campaign will be available on {availableAt}.
      </Alert>
    );
  }

  return <>{children}</>;
};

export { WithIntentDataAvailable };
