import { BulkEditCampaignsSubject } from "@intentsify/types";

export const getContentsFromTab = (
  tab: BulkEditCampaignsSubject
): { label: string; desc: string } => {
  switch (true) {
    case tab === BulkEditCampaignsSubject.TAL:
      return {
        label: "Target Account List (TAL)",
        desc: 'The list below displays the campaigns you are about to update. After you upload the CSV file with an account list, please make sure that the operation is set correctly. "Merge" will add uploaded accounts to the existing Target Account List, "Replace" will remove the current TAL in favor of the one you just uploaded.',
      };

    case tab === BulkEditCampaignsSubject.Keywords:
      return {
        label: "Keywords",
        desc: 'The list below displays the campaigns you are about to update. After you upload the CSV file with keywords, please make sure that the operation is set correctly. "Merge" will add uploaded keywords to the existing keywords, "Replace" will remove the current keywords in favor of the one you just uploaded.',
      };

    case tab === BulkEditCampaignsSubject.Topics:
      return {
        label: "Topics",
        desc: 'The list below displays the campaigns you are about to update. After you upload the CSV file with topics, please make sure that the operation is set correctly. "Merge" will add uploaded topics to the existing topics, "Replace" will remove the current topics in favor of the one you just uploaded.',
      };
  }

  throw new Error("Didn't expect to get here");
};
