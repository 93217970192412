import { z } from "zod";

export const editCompanyFormSchema = z.object({
  id: z.string(),
  displayName: z.string().min(3).max(255),
  dataPackageId: z.number(),
  isOnTrial: z.boolean().optional(),
});

export type EditCompanyFormSchema = z.infer<typeof editCompanyFormSchema>;
