import { Box, Flex, Text } from "@chakra-ui/react";
import { ActiveCampaignForList, SortDirection } from "@intentsify/types";
import { useQuery } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import { listActiveCampaigns } from "api";
import { Search, Select, Table } from "components";
import { PaginationType } from "components/Table/Table/Table.types";
import { useCallback, useMemo, useState } from "react";
import { useComponentColors } from "theme";
import { useTrackSearched } from "tracking/useTrackSearched";
import { FetchDataParamsWithToken } from "types";
import { activeCampaignsTableColumns } from "./ActiveCampaigns.columns";

const defaultListPaginatedCampaignsParams: FetchDataParamsWithToken<
  keyof ActiveCampaignForList
> = {
  pageToken: "",
  order_by: "campaignId",
  order: SortDirection.ASC,
};

type SortByValue = `${keyof ActiveCampaignForList} ${SortDirection}`;
type SortByOption = { label: string; value: SortByValue };

const sortOptions: SortByOption[] = [
  { label: "Progress (desc)", value: "leadsProgressPercentage DESC" },
  { label: "Progress (asc)", value: `leadsProgressPercentage ASC` },

  { label: "Goal (desc)", value: `bookedLeadsNumber DESC` },
  { label: "Goal (asc)", value: "bookedLeadsNumber ASC" },

  { label: "Name (desc)", value: "displayName DESC" },
  { label: "Name (asc)", value: "displayName ASC" },

  { label: "Start date (desc)", value: "startDate DESC" },
  { label: "Start date (asc)", value: "startDate ASC" },

  { label: "End date (desc)", value: "endDate DESC" },
  { label: "End date (asc)", value: "endDate ASC" },
];

const ActiveCampaigns = ({
  companyId,
}: {
  companyId: string | number | undefined;
}) => {
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);

  const [sortBy, setSortBy] = useState<SortByOption>(sortOptions[0]);

  const {
    form: { stackBg },
  } = useComponentColors();

  const columns = useMemo<ColumnDef<ActiveCampaignForList>[]>(
    () => activeCampaignsTableColumns(),
    []
  );

  const [fetchDataParams, setFetchDataParams] = useState<
    FetchDataParamsWithToken<keyof ActiveCampaignForList>
  >(defaultListPaginatedCampaignsParams);

  const { data, isFetching } = useQuery(
    ["activeCampaignsList", fetchDataParams, Number(companyId)],
    () => {
      return listActiveCampaigns(Number(companyId), fetchDataParams);
    }
  );

  const handleOnFetchDataChange = useCallback(
    (params: FetchDataParamsWithToken<keyof ActiveCampaignForList>) => {
      const sortByValues = {
        order_by: sortBy.value.split(" ")[0],
        order: sortBy.value.split(" ")[1],
      } as {
        order_by: keyof ActiveCampaignForList;
        order: SortDirection;
      };

      setFetchDataParams({
        ...params,
        ...sortByValues,
      });
    },
    [sortBy, setFetchDataParams]
  );

  const trackSearched = useTrackSearched();

  return (
    <>
      <Flex
        flexDirection={{ sm: "column", xl: "row" }}
        mb={4}
        alignItems={{ sm: "start", xl: "end" }}
      >
        <Search
          mb={{ sm: 4, xl: 0 }}
          mr={4}
          currentValue={searchValue}
          onSearch={(v) => {
            setSearchValue(v);
            trackSearched({ term: v, collocation: "Active campaigns table" });
          }}
        />
        <Box flexGrow={1}></Box>

        <Flex alignItems="center">
          <Text fontWeight="medium" pr={2}>
            Sort by
          </Text>
          <Box w={200}>
            <Select
              isClearable={false}
              isMulti={false}
              options={sortOptions}
              value={sortBy}
              onChange={(value) => {
                if (value) {
                  setSortBy(value);
                }
              }}
            />
          </Box>
        </Flex>
      </Flex>
      <Table
        noDataMessage="No active campaigns."
        bg={stackBg}
        hideHead={true}
        variant="intentsifyNew"
        searchValue={searchValue}
        defaultSort={[
          { id: defaultListPaginatedCampaignsParams.order_by, desc: true },
        ]}
        columns={columns}
        isFetching={isFetching}
        data={data?.activeCampaigns || []}
        onFetchDataChange={handleOnFetchDataChange}
        resetPaginationDependencies={[sortBy, companyId, searchValue]}
        paginationType={PaginationType.TOKEN}
        nextPageToken={data?.nextPageToken}
      />
    </>
  );
};

export { ActiveCampaigns };
