import { GetProgrammaticCampaignBuyerResearchStageEngagementRO } from "@intentsify/dto";
import { apiService, Endpoints } from "api";
import { DisplayPerformanceFilters } from "screens/CampaignPacing/screens/DisplayPerformance/DisplayPerformance";

export const getBuyerResearchStageEngagement = async (
  id: string,
  filters: DisplayPerformanceFilters
): Promise<GetProgrammaticCampaignBuyerResearchStageEngagementRO> => {
  const { data } =
    await apiService.get<GetProgrammaticCampaignBuyerResearchStageEngagementRO>(
      Endpoints.ProgrammaticCampaigns.CreativeEngagement.Get.BuyerResearchStageEngagement(
        id
      ),
      {
        params: {
          yearFrom: filters.yearFrom,
          yearTo: filters.yearTo,
          weekFrom: filters.weekFrom,
          weekTo: filters.weekTo,
        },
      }
    );

  return data;
};
