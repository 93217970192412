import {
  Node,
  NodeDefinition,
  NodeMap,
  NodesLists,
  NodeValue,
} from "@intentsify/types";
import { isNumeric, isPopulatedArray } from "@intentsify/utils";
import { TreeProps } from "./Tree";
import { CheckModel } from "./Tree.const";

class NodeModel {
  props: TreeProps;
  flatNodes: NodeMap;
  constructor(props: TreeProps, nodes = {}) {
    this.props = props;
    this.flatNodes = nodes;
  }

  setProps(props: TreeProps) {
    this.props = props;
  }

  clone() {
    const clonedNodes: NodeMap = {};

    // Re-construct nodes one level deep to avoid shallow copy of mutable characteristics
    Object.keys(this.flatNodes).forEach((value) => {
      const node = this.flatNodes[value];
      clonedNodes[value] = { ...node };
    });

    return new NodeModel(this.props, clonedNodes);
  }

  getNode(value: string | number) {
    return this.flatNodes[value];
  }

  getAllNodes() {
    return Object.values(this.flatNodes);
  }

  reset() {
    this.flatNodes = {};
  }

  flattenNodes(nodes: Node[], parent: Node | null = null, depth = 0) {
    if (!Array.isArray(nodes) || nodes.length === 0) {
      return;
    }

    const { disabled } = this.props;

    // Flatten the `node` property for internal lookups
    nodes.forEach((node, index) => {
      const isParent = this.nodeHasChildren(node);

      this.flatNodes[node.value] = {
        index,
        parent,
        isParent,
        label: node.label,
        value: node.value,
        title: node.title,
        children: node.children || [],
        isChild: parent?.value !== undefined,
        isLeaf: !isParent,
        disabled: this.getDisabledState(node, parent, !!disabled),
        treeDepth: depth,
        expanded: false,
        checked: false,
        checkState: 0,
      };
      this.flattenNodes(node?.children || [], node, depth + 1);
    });
  }

  nodeHasChildren(node: Node) {
    return isPopulatedArray(node.children);
  }

  getDisabledState(node: Node, parent: Node | null, isDisabled: boolean) {
    if (isDisabled) {
      return true;
    }

    if (parent?.disabled) {
      return true;
    }

    return Boolean(node.disabled);
  }

  deserializeLists(lists: NodesLists) {
    const listKeys = ["checked", "expanded"] as const;

    // Reset values to false
    Object.keys(this.flatNodes).forEach((value) => {
      listKeys.forEach((listKey) => {
        this.flatNodes[value][listKey] = false;
      });
    });

    // Deserialize values and set their nodes to true
    listKeys.forEach((listKey) => {
      lists[listKey].forEach((value) => {
        if (this.flatNodes[value] !== undefined) {
          this.flatNodes[value][listKey] = true;
        }
      });
    });
  }

  serializeList(key: "checked" | "expanded") {
    const list: NodeValue[] = [];

    Object.keys(this.flatNodes).forEach((value) => {
      if (this.flatNodes[value][key]) {
        if (isNumeric(value)) {
          list.push(Number(value) as NodeValue);
          return;
        }

        list.push(value as NodeValue);
      }
    });

    return list;
  }

  expandAllNodes(expand: boolean) {
    Object.keys(this.flatNodes).forEach((value) => {
      if (this.flatNodes[value].isParent) {
        this.flatNodes[value].expanded = expand;
      }
    });

    return this;
  }

  toggleChecked(
    node: Node | NodeDefinition,
    isChecked: boolean,
    checkModel: CheckModel,
    percolateUpward = true
  ) {
    const flatNode = this.flatNodes[node.value];
    const modelHasParents =
      [CheckModel.PARENT, CheckModel.ALL].indexOf(checkModel) > -1;
    const modelHasLeaves =
      [CheckModel.LEAF, CheckModel.ALL].indexOf(checkModel) > -1;

    if (flatNode.isLeaf) {
      if (node.disabled) {
        return this;
      }

      this.toggleNode(node.value, "checked", isChecked);
    } else {
      if (modelHasParents) {
        this.toggleNode(node.value, "checked", isChecked);
      }

      if (modelHasLeaves) {
        // Percolate check status down to all children
        flatNode.children.forEach((child) => {
          this.toggleChecked(child, isChecked, checkModel, false);
        });
      }
    }

    // Percolate check status up to parent
    // The check model must include parent nodes and we must not have already covered the
    // parent (relevant only when percolating through children)
    if (
      percolateUpward &&
      flatNode.parent &&
      flatNode.isChild &&
      modelHasParents
    ) {
      this.toggleParentStatus(flatNode.parent, checkModel);
    }

    return this;
  }

  toggleParentStatus(node: Node, checkModel: CheckModel) {
    const flatNode = this.flatNodes[node.value];

    if (flatNode.isChild && flatNode.parent) {
      if (checkModel === CheckModel.ALL) {
        this.toggleNode(
          node.value,
          "checked",
          this.isEveryChildChecked(flatNode)
        );
      }

      this.toggleParentStatus(flatNode.parent, checkModel);
    } else {
      this.toggleNode(
        node.value,
        "checked",
        this.isEveryChildChecked(flatNode)
      );
    }
  }

  isEveryChildChecked(node: NodeDefinition) {
    return (
      node.children &&
      node.children.every((child) => this.getNode(child.value).checked)
    );
  }

  toggleNode(
    nodeValue: string | number,
    key: "checked" | "expanded",
    toggleValue: boolean
  ) {
    this.flatNodes[nodeValue][key] = toggleValue;

    return this;
  }
}

export { NodeModel };
