import { ImpressionsAndClicksOverTime as ImpressionsAndClicksOverTimeType } from "@intentsify/types";
import { Card, MIN_MODAL_CONTENT_HEIGHT, Timeseries } from "components";
import { GenericSnapToTooltip } from "components/Charts/Timeseries/components/TimeseriesTooltip/GenericSnapToTooltip";
import { DateTime } from "luxon";
import { useMemo, useState } from "react";
import { ChartExport, useExportableChart } from "shared/components";
import { OvertimeTimeseriesItem, TimeseriesRange } from "types";

type ImpressionsAndClicksOverTimeProps = {
  data: ImpressionsAndClicksOverTimeType[];
  campaignId: string | number;
  isLoading: boolean;
};

const ImpressionsAndClicksOverTime = ({
  campaignId,
  isLoading,
  data,
}: ImpressionsAndClicksOverTimeProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const tsData: {
    data: OvertimeTimeseriesItem[];
    timeseries: TimeseriesRange[];
  } = useMemo(() => {
    const impressions: OvertimeTimeseriesItem = {
      label: "Impressions",
      timeseries: [],
    };

    const clicks: OvertimeTimeseriesItem = {
      label: "Clicks",
      timeseries: [],
    };

    const timeseries: TimeseriesRange[] = [];

    if (data) {
      data.forEach((i) => {
        impressions.timeseries.push({
          isoDate: i.date,
          data: { value: i.countImpressions },
        });

        clicks.timeseries.push({
          isoDate: i.date,
          data: { value: i.countClicks },
        });

        timeseries.push({
          start: i.date,
          end: DateTime.fromISO(i.date).endOf("week").toISO(),
        });
      });
    }

    return {
      data: [impressions, clicks],
      timeseries,
    };
  }, [data]);

  const overTimeChart = useExportableChart({
    title: "Impressions And Clicks Over Time",
    campaignId: Number(campaignId),

    chart: (
      <Timeseries
        minHeight={isExpanded ? MIN_MODAL_CONTENT_HEIGHT : undefined}
        useTwoYAxes
        secondYAxisDataKeyLabel="Click"
        data={tsData.data}
        dataMainKey="label"
        timeseries={tsData.timeseries}
        scale="linear"
        dataKeyLabels={{ value: "Impression volume" }}
        snapToDateTime
        tooltipRenderer={GenericSnapToTooltip}
        stretch
      />
    ),
  });

  return (
    <Card
      onExpandedChange={setIsExpanded}
      isLoading={isLoading}
      title={overTimeChart.title}
      isExpandable
      hasData={data.length > 0}
      noDataMessage="This visualization is allocated for display programs associated with this campaign. If the program began recently, data may still be configuring."
      actions={
        <ChartExport
          size="small"
          campaignId={Number(campaignId)}
          title={overTimeChart.title}
          onExportPNG={overTimeChart.downloadAsPng}
          data={data ?? []}
        />
      }
    >
      {overTimeChart.component}
      {overTimeChart.exportComponent}
    </Card>
  );
};

export { ImpressionsAndClicksOverTime };
