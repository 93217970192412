import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { Endpoints, apiService } from "api";
import { useTrackRequestReporting } from "tracking/useTrackRequestReporting";
import { AsyncReturnType } from "type-fest";

type Params = { id: number };

const requestMoatReport = ({ id }: Params) =>
  apiService.post(Endpoints.Reports.MoatReport.Post.Request(id));

type UseRequestMoatReportParams = UseMutationOptions<
  AsyncReturnType<typeof requestMoatReport>,
  unknown,
  Params
>;

export const useRequestMoatReport = (options?: UseRequestMoatReportParams) => {
  const trackRequestReporting = useTrackRequestReporting();

  return useMutation({
    mutationFn: ({ id }: Params) => {
      const request = requestMoatReport({ id });
      trackRequestReporting({ campaignId: id, requestingDeck: "moat" });
      return request;
    },
    ...options,
  });
};
