import { createScreen } from "utils/createScreen";
import { DownloadReportScreen } from "../../types";

const DownloadReportDefinition = createScreen({
  name: DownloadReportScreen,
  path: "/download/:reportType/:campaignId/:correlationId",
  routePath: ["/download/:reportType/:campaignId/:correlationId"],
});

export { DownloadReportDefinition };
