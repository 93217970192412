import { GenericUserJobRecordRO, SmarTALDTO } from "@intentsify/dto";
import { GenericUserJobType } from "@intentsify/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Endpoints, apiService } from "api";

export const useSmarTAL = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (dto: SmarTALDTO) =>
      apiService.post<GenericUserJobRecordRO>(
        Endpoints.TalTools.SmarTAL.Post,
        dto
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["talTools", GenericUserJobType.SMARTAL],
        exact: false,
      });
    },
  });
};
