import { Divider, Flex, Heading, useColorModeValue } from "@chakra-ui/react";

interface SpotlightButtonComponentProps {
  header: string;
  children: React.ReactNode | React.ReactNode[];
  borderColor: React.ComponentProps<typeof Flex>["borderColor"];
}

export const SpotlightBlockSectionComponent = ({
  header,
  children,
  borderColor,
}: SpotlightButtonComponentProps) => {
  const contentColor = useColorModeValue(
    "var(--chakra-colors-gray-500)",
    "var(--chakra-colors-gray-100)"
  );

  return (
    <Flex
      flexDirection="column"
      gap={2}
      color={contentColor}
      fontSize="12px"
      border="3px"
      borderColor={borderColor}
      borderRadius={24}
      borderBottomLeftRadius={0}
      borderTopRightRadius={0}
      borderStyle="solid"
      padding={4}
      flex={1}
    >
      <Heading as="h4" fontSize="lg">
        {header}
      </Heading>
      <Divider color={borderColor} mb={1} />
      <Flex flexDirection="column" gap={1} flex={1}>
        {children}
      </Flex>
    </Flex>
  );
};
