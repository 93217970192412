import { useMemo } from "react";
import { getExportFileName } from "@intentsify/utils";
import { SimpleCsvFile } from "./SimpleCsvFile";
import { useColumns, UseColumnsOptions } from "./useColumns";
import {
  SimpleTable,
  SimpleTablePublicProps,
} from "components/SimpleTable/SimpleTable";
import { makeDownloadCsvLink } from "shared/hooks/useDownloadCsvLink";

interface CsvFilesTableProps extends UseColumnsOptions, SimpleTablePublicProps {
  files: Omit<SimpleCsvFile, "csvdownloadLink" | "size">[];
}

const getNormalisedFileName = (
  fileName: SimpleCsvFile["fileName"],
  campaignId?: number
) => {
  const [fileNameWithoutExtension] = fileName.split(".csv");
  const fileNameWithoutSpaces = fileNameWithoutExtension.split(" ").join("_");

  return getExportFileName({
    prefix: fileNameWithoutSpaces,
    data: { id: campaignId as number },
    extension: ".csv",
  });
};

export const SimpleCsvFilesTable = ({
  files,
  nonIdealState,
  containerProps,
  ...useColumnsOptions
}: CsvFilesTableProps) => {
  const columns = useColumns(useColumnsOptions);
  const filesWithCsvDownloadLink = useMemo(() => {
    return files.map((file) => {
      const [downloadCsvLink, blob] = makeDownloadCsvLink(file.data);
      const fileName = file.normaliseFileName
        ? getNormalisedFileName(file.fileName, file.campaignId)
        : file.fileName;

      return {
        ...file,
        fileName,
        downloadCsvLink,
        size: blob.size,
      };
    });
  }, [files]);

  return (
    <SimpleTable<SimpleCsvFile>
      columns={columns}
      data={filesWithCsvDownloadLink}
      nonIdealState={nonIdealState}
      containerProps={containerProps}
    />
  );
};
