import { AxiosInstance } from "axios";

type MockedResponse = {
  method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
  url: string;
  data: unknown;
};

const mockedResponsed: MockedResponse[] = [];

export const axiosMock = {
  get: (url: string) => {
    const response = mockedResponsed.find(
      (response) => response.url === url && response.method === "GET"
    );
    return Promise.resolve({ data: response?.data ?? null });
  },
  delete: () => Promise.resolve({ data: null }),
  post: () => Promise.resolve({ data: null }),
  put: () => Promise.resolve({ data: null }),
  patch: () => Promise.resolve({ data: null }),
} as unknown as AxiosInstance;

export const mockAxiosGetRequest = (url: string, data: unknown) => {
  mockedResponsed.unshift({ method: "GET", url, data });
};

export const mockAxiosPostRequest = (
  url: string,
  payload: unknown,
  data: unknown
) => {
  mockedResponsed.unshift({ method: "GET", url, data });
};

export const resetAxiosMock = () => {
  mockedResponsed.length = 0;
};
