import { DomainsData } from "@intentsify/types";
import { SimpleCsvFile } from "components/SimpleTable/variant/SimpleCsvFilesTable/SimpleCsvFile";

export type DomainsUploadProps = {
  domainsData: DomainsData;
  domainsUploadScreenType: DomainsUploadScreenType;
  uploadedFiles?: SimpleCsvFile[];
  onUploadedDomainsChange: (data: DomainsData) => void;

  showInfoAboutTemplate?: boolean;
};

export enum DomainsUploadScreenType {
  CAMPAIGN_VIEW = "CAMPAIGN_VIEW",
  DIGITAL_PERFORMANCE_VIEW = "DIGITAL_PERFORMANCE_VIEW",
  FIRMOGRAPHICS_VIEW = "FIRMOGRAPHICS_VIEW",
  PROGRAMMATIC_DOMAINS_VIEW = "PROGRAMMATIC_DOMAINS_VIEW",
}
