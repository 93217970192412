import { Flex, Heading } from "@chakra-ui/layout";
import { Link as ChakraLink } from "@chakra-ui/react";
import { TopBar as LayoutTopBar } from "components/Layout/components/TopBar/TopBar";
import { PropsWithChildren } from "react";
import { useMatch } from "react-router";
import { Link as ReactRouterLink } from "react-router-dom";

export const TopBar = () => {
  return (
    <LayoutTopBar>
      <Flex columnGap="4" alignItems="center">
        <Heading color="gray.300" as="h2" size="sm" lineHeight="inherit">
          Content library
        </Heading>

        <CompletedDraftsSwitch />
      </Flex>
    </LayoutTopBar>
  );
};

const CompletedDraftsSwitch = () => {
  const isCompletedActive = useMatch("/content-library/completed");
  const isDraftsActive = useMatch("/content-library/drafts");

  return (
    <Flex bg="brand.500" p="1" borderRadius="full" columnGap="1" role="group">
      <Link
        to="/content-library/completed"
        isActive={Boolean(isCompletedActive)}
      >
        Completed
      </Link>

      <Link to="/content-library/drafts" isActive={Boolean(isDraftsActive)}>
        Drafts
      </Link>
    </Flex>
  );
};

const Link = (props: PropsWithChildren<{ to: string; isActive?: boolean }>) => (
  <ChakraLink
    as={ReactRouterLink}
    to={props.to}
    py="0.5"
    px="1.5"
    fontSize="xs"
    rounded="full"
    bg={props.isActive ? "whiteAlpha.300" : "transparent"}
    color={props.isActive ? "white" : "gray.300"}
    style={{ textDecoration: "none" }}
    _focus={{ bg: "whiteAlpha.300", color: "white" }}
    _active={{ bg: "whiteAlpha.300", color: "white" }}
    _groupHover={{
      bg: "whiteAlpha.100",
      color: "gray.200",
      _hover: { bg: "whiteAlpha.300", color: "white" },
    }}
  >
    {props.children}
  </ChakraLink>
);
