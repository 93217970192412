import {
  Box,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  useColorMode,
} from "@chakra-ui/react";
import { css } from "@emotion/react";
import { formatDateRange } from "@intentsify/utils";
import enGb from "date-fns/locale/en-GB";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { DayPicker, UseInputOptions, useInput } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { FiCalendar } from "react-icons/fi";
import { Popover } from "../../Popover";
import {
  getDatePickerCssModifiers,
  getWeekDays,
  getWeekRange,
} from "../DatePicker.utils";
import "../date-picker.css";
import { OnChangeWeek } from "./WeekPicker.types";

const options: UseInputOptions = {
  defaultSelected: new Date(),
  fromYear: 2021,
  toYear: new Date().getFullYear(),
  format: "PP",
  required: true,
};

export type WeekPickerProps = {
  selected: string | undefined;
  onChange: OnChangeWeek;
  disabledDays?: { lt?: DateTime; gt?: DateTime }[];
  isDisabled?: boolean;
  minW?: string;
  isLoading?: boolean;
};

const WeekPicker = ({
  selected,
  onChange,
  disabledDays = [],
  isDisabled,
  minW,
  isLoading,
}: WeekPickerProps) => {
  const [selectedDays, setSelectedDays] = useState<{ from: Date; to: Date }>();
  const [hoveredRange, setHoveredRange] = useState<{ from: Date; to: Date }>({
    from: new Date(),
    to: new Date(),
  });
  const [inputValue, setInputValue] = useState<string>("");

  const { colorMode } = useColorMode();

  useEffect(() => {
    if (!selected) {
      return;
    }

    const weekDays = getWeekDays(
      getWeekRange(DateTime.fromISO(selected).toJSDate())
    );

    const formatted = formatDateRange({
      start: DateTime.fromJSDate(weekDays[0]).toISODate(),
      end: DateTime.fromJSDate(weekDays[6]).toISODate(),
    });

    setSelectedDays(getWeekRange(DateTime.fromISO(selected).toJSDate()));
    setInputValue(formatted);
  }, [selected]);

  const input = useInput({
    ...options,
  });
  const { onBlur, onFocus, onChange: onInputChange } = input.inputProps;

  const handleDayChange = (date: Date) => {
    const weekDays = getWeekDays(getWeekRange(date));
    const { weekNumber, year } = DateTime.fromJSDate(date);

    setSelectedDays(getWeekRange(date));
    onChange({ weekNumber, yearNumber: year });

    const formatted = formatDateRange({
      start: DateTime.fromJSDate(weekDays[0]).toISODate(),
      end: DateTime.fromJSDate(weekDays[6]).toISODate(),
    });

    setInputValue(formatted);
  };

  const handleDayEnter = (date: Date) => {
    setHoveredRange(getWeekRange(date));
  };

  const modifiers = {
    hoveredRange: hoveredRange,
    hoverRangeStart: hoveredRange?.from,
    hoverRangeEnd: hoveredRange?.to,
  };

  return (
    <Box
      css={css`
        ${getDatePickerCssModifiers(colorMode)}
      `}
    >
      <Popover
        width="23rem"
        popoverTrigger={
          <InputGroup size="sm" alignItems={"center"}>
            <Input
              minW={minW}
              shadow="sm"
              rounded="md"
              isReadOnly // to update state only through the Date-Picker
              onBlur={onBlur}
              onFocus={onFocus}
              value={inputValue}
              onChange={onInputChange}
              width="100%"
              cursor={"pointer"}
              isDisabled={isDisabled || isLoading}
            />
            <InputRightElement pointerEvents="none" alignContent={"center"}>
              {isLoading ? (
                <Spinner color="gray" size="sm" opacity=".5" />
              ) : (
                <FiCalendar />
              )}
            </InputRightElement>
          </InputGroup>
        }
        popoverBody={
          <>
            <DayPicker
              {...input.dayPickerProps}
              showOutsideDays
              mode="range"
              showWeekNumber
              selected={selectedDays}
              onDayClick={handleDayChange}
              locale={enGb}
              modifiers={{ hoveredRange: modifiers.hoveredRange }}
              modifiersStyles={{
                hoveredRange: {},
              }}
              onDayMouseEnter={handleDayEnter}
              disabled={(d) => {
                const date = DateTime.fromJSDate(d);

                const withinRange = disabledDays.some(({ lt, gt }) => {
                  if (lt && !gt && date <= lt) {
                    return true;
                  }

                  if (gt && !lt && date >= gt) {
                    return true;
                  }

                  if (gt && lt && date >= lt && date <= gt) {
                    return true;
                  }

                  return false;
                });

                if (withinRange) {
                  return true;
                }

                return false;
              }}
            />
          </>
        }
      />
    </Box>
  );
};

export { WeekPicker };
