import { EditBusinessEntityDTO } from "@intentsify/dto";
import { AgencyFormData } from "../../components";
import { apiService, Endpoints } from "api";

export const editBusinessEntity = async ({
  id,
  displayName,
  companyIds,
}: { id: number } & Required<AgencyFormData>) =>
  await apiService.put<any, any, EditBusinessEntityDTO>(
    Endpoints.BusinessEntities.Put.Update(id),
    {
      displayName,
      companyIds,
    }
  );
