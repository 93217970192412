import { Box, Flex, Text } from "@chakra-ui/layout";
import { OptOutForm } from "./Form";

export function OptOut() {
  return (
    <Flex flexDir="column" gap="10">
      <Flex w="full" maxWidth="lg" mx="auto" flexDir="column">
        <Text fontSize="xl">Have full control of your privacy</Text>
        <Text color="blackAlpha.600" _dark={{ color: "whiteAlpha.600" }}>
          Please enter a current email address and full name. Please select the
          type of request you are looking to submit and we will use this email
          address to communicate with you.
        </Text>
      </Flex>

      <Box w="full" maxWidth="lg" mx="auto">
        <OptOutForm />
      </Box>
    </Flex>
  );
}
