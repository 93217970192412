import { Flex } from "@chakra-ui/react";
import { toNumberDisplayValue } from "@intentsify/utils";
import { ChangeIndicator } from "../ChangeIndicator";

const CellWithChange = ({
  value,
  previousValue,
  renderValue = true,
}: {
  value: number | string | undefined | null;
  previousValue: number | string | undefined | null;
  // Set to false if you don't want to render the current value but only the change indicator itself
  renderValue?: boolean;
}) => {
  if (!value) {
    return <>-</>;
  }

  return (
    <Flex alignItems="center" whiteSpace="nowrap" willChange="auto">
      {renderValue && toNumberDisplayValue(Number(value))}
      <ChangeIndicator
        value={Number(value)}
        previousValue={previousValue ? Number(previousValue) : undefined}
        spaceProps={{ ml: 2 }}
      />
    </Flex>
  );
};

export { CellWithChange };
