import { ReactElement, isValidElement } from "react";

export const extractTextFromMaybeJSX = (content: string | ReactElement) => {
  if (typeof content === "string") {
    return content;
  }

  let extractedText = "";
  const elementsToInspect = [content];

  while (elementsToInspect.length > 0) {
    const inspectedElement = elementsToInspect.pop();

    if (
      inspectedElement &&
      isValidElement(inspectedElement) &&
      (inspectedElement as ReactElement).props.children
    ) {
      const inspectedElementChildren = (inspectedElement as ReactElement).props
        .children;
      if (typeof inspectedElementChildren === "string") {
        extractedText += inspectedElementChildren;
      } else {
        if (Array.isArray(inspectedElementChildren)) {
          elementsToInspect.push(
            ...(inspectedElementChildren as ReactElement[])
          );
        } else if (isValidElement(inspectedElementChildren as ReactElement)) {
          elementsToInspect.push(inspectedElementChildren as ReactElement);
        }
      }
    }
  }

  return extractedText;
};
