import { BaseSignalRow } from "@intentsify/types";
import { ColumnDef } from "@tanstack/react-table";
import { useMemo } from "react";
import { getColumns } from "../SignalsTable";
import { Table } from "components";

type TopLocationsSignalsTable = {
  data: BaseSignalRow[];
};

const TopLocationsSignalsTable = ({ data }: TopLocationsSignalsTable) => {
  const tableColumns = useMemo<ColumnDef<BaseSignalRow>[]>(
    () =>
      getColumns({
        showOnlyName: false,
        showLocation: false,
      }),
    []
  );

  return (
    <Table
      columns={tableColumns}
      data={data}
      isScrollable={false}
      isControlledByAPI={false}
      stretch
      overflow={"hidden"}
      defaultSort={[
        {
          id: "baseWeekScore",
          desc: true,
        },
      ]}
    />
  );
};

export { TopLocationsSignalsTable };
