import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { AiOutlineClose, AiOutlineExpand } from "react-icons/ai";
import { ErrorBoundary } from "../ErrorBoundary";
import { IconButton } from "../IconButton";
import { Header } from "./Header";
import { WithChildren } from "types";

type BodyProps = WithChildren<{
  title?: string;
  titleSize?: string;
  subtitle?: string;
  actionItems?: React.ReactNode;
  headerComponent?: React.ReactNode;
  expanded: boolean;
  expandable: boolean;
  setExpanded: (val: boolean) => void;
  stretch: boolean;
  noPadding?: boolean;
  withBackground?: boolean;
  overwriteBackgroundColor?: string;
}>;

const Body = ({
  children,
  expanded,
  actionItems,
  expandable,
  headerComponent,
  title,
  titleSize,
  subtitle,
  setExpanded,
  stretch,
  noPadding,
  withBackground = false,
  overwriteBackgroundColor,
}: BodyProps) => {
  const defaultBackgroundColor = useColorModeValue("gray.25", "brand.400");
  const bg = overwriteBackgroundColor || defaultBackgroundColor;

  return (
    <Flex
      h={stretch ? "100%" : "auto"}
      flexDirection="column"
      flexGrow={expanded ? 1 : undefined}
      bg={withBackground ? bg : undefined}
    >
      <ErrorBoundary>
        <Flex
          flexGrow={1}
          p={noPadding ? 0 : 4}
          overflowX={expanded ? "scroll" : undefined}
        >
          <Flex flexDirection="column" w="100%" h="100%">
            <Flex>
              <Header
                headerComponent={headerComponent}
                titleSize={titleSize}
                title={title}
              />

              <Box flexGrow={1}></Box>

              <Flex maxH={"32px"}>
                {actionItems}
                {expandable && !expanded && (
                  <Box ml={2}>
                    <IconButton
                      variant="solid"
                      tooltip="Expand"
                      onClick={() => setExpanded(true)}
                      icon={<AiOutlineExpand />}
                    />
                  </Box>
                )}
                {expandable && expanded && (
                  <Box ml={2}>
                    <IconButton
                      variant="solid"
                      tooltip="Close"
                      onClick={() => setExpanded(false)}
                      icon={<AiOutlineClose />}
                    />
                  </Box>
                )}
              </Flex>
            </Flex>

            {subtitle && (
              <Text mb={4} mt={-2} fontSize="md">
                {subtitle}
              </Text>
            )}

            {children && <ErrorBoundary>{children}</ErrorBoundary>}
          </Flex>
        </Flex>
      </ErrorBoundary>
    </Flex>
  );
};

export { Body };
