import { Box } from "@chakra-ui/react";
import { Select } from "components";
import { useState } from "react";
import { useListAllPersonas } from "../BuyingGroup.requests";

type PersonasSelectorProps = {
  campaingId: number;
  accountId: number;
  onPersonasChange: (personas: string[]) => void;
};

const PersonasSelector = ({
  campaingId,
  accountId,
  onPersonasChange,
}: PersonasSelectorProps) => {
  const [selectedPersonas, setSelectedPersonas] = useState<string[]>([]);
  const personas = useListAllPersonas(campaingId, accountId);

  return (
    <Box w="50%" p={4}>
      <Select
        isMulti={true}
        value={
          personas.data?.filter((persona) =>
            selectedPersonas.includes(persona.value)
          ) ?? []
        }
        placeholder="Selected Personas"
        options={personas.data ?? []}
        isLoading={personas.isLoading}
        onChange={(options) => {
          setSelectedPersonas(() => [...options.map((option) => option.value)]);
          onPersonasChange(options.map((option) => option.value));
        }}
      />
    </Box>
  );
};

export { PersonasSelector };
