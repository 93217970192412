import {
  AvailableSpotlightDeckAccountsDTO,
  AvailableSpotlightDeckAccountsRO,
  AvailableSpotlightDeckWeeksDTO,
  AvailableSpotlightDeckWeeksRO,
} from "@intentsify/dto";
import { Option } from "@intentsify/types";
import { Endpoints, apiService } from "api";

export const getAvailableSpotlightDeckWeeks = async (
  params: AvailableSpotlightDeckWeeksDTO
) => {
  const { data } = await apiService.get<AvailableSpotlightDeckWeeksRO>(
    Endpoints.Reports.Spotlight.Get.AvailableSpotlightDeckWeeks,
    { params }
  );

  return data;
};

export type AccountOption = Option<number>;

export const getAvailableSpotlightDeckAccounts = async (
  params: AvailableSpotlightDeckAccountsDTO
): Promise<AccountOption[]> => {
  const { data } = await apiService.get<AvailableSpotlightDeckAccountsRO>(
    Endpoints.Reports.Spotlight.Get.AvailableSpotlightDeckAccounts,
    { params }
  );

  const options: AccountOption[] = data.accounts.map((i) => ({
    label: i.accountName,
    value: i.accountId,
  }));

  return options;
};
