import { WeeklySnapshotDTO } from "@intentsify/dto";
import { useQuery } from "@tanstack/react-query";
import { Card, MIN_MODAL_CONTENT_HEIGHT } from "components";
import { TimeseriesNew } from "components/Charts/TimeseriesNew";
import { useMemo, useState } from "react";
import { ChartExport, useExportableChart } from "shared/components";
import { getAccountsReachedOverTime } from "../../WeeklySnapshot.requests";

type AccountsReachedOverTimeProps = {
  campaignId: string | number;
  filters: WeeklySnapshotDTO;
};

const AccountsReachedOverTime = ({
  campaignId,
  filters,
}: AccountsReachedOverTimeProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const { data, isFetching } = useQuery(
    ["getWeeklySnapshotAccountsReachedOverTime", campaignId, filters],
    () => getAccountsReachedOverTime(campaignId, filters)
  );

  const tsData = useMemo(() => {
    if (!data) {
      return {
        accountsReached: [],
      };
    }

    return {
      accountsReached: data?.accountsReached.map((i) => ({
        isoDate: i.date,
        value: i.accounts_reached ?? 0,
      })),
    };
  }, [data]);

  const chart = useExportableChart({
    title: "Accounts Reached Over Time",
    campaignId: campaignId,
    chart: (
      <TimeseriesNew
        minHeight={isExpanded ? MIN_MODAL_CONTENT_HEIGHT : undefined}
        stretch
        leftAxisBars={[
          {
            data: tsData.accountsReached,
            legend: { label: "Sum of Accounts Reached", color: "green" },
          },
        ]}
        leftAxisLabel="Number of Accounts Reached"
        disableLegend={true}
      />
    ),
  });

  return (
    <Card
      isLoading={isFetching}
      onExpandedChange={setIsExpanded}
      title={chart.title}
      hasData={!isFetching && Boolean(data?.accountsReached?.length)}
      noDataMessage="This visualization is allocated for content syndication programs associated with this campaign. If the program began recently, data may still be configuring."
      isExpandable
      actions={
        <ChartExport
          size="small"
          campaignId={1}
          title={chart.title}
          onExportPNG={chart.downloadAsPng}
          data={data?.accountsReached ?? []}
        />
      }
    >
      {chart.component}
      {chart.exportComponent}
    </Card>
  );
};

export { AccountsReachedOverTime };
