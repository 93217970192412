import { DependencyList, EffectCallback, useEffect } from "react";

const isPrimitive = (value: any) =>
  (typeof value !== "object" && typeof value !== "function") || value === null;

/**
 * Can be used like useEffect but on complex data structures
 * like Objects or Arrays
 */
const useDeepEffect = (
  callback: EffectCallback,
  dependencies: DependencyList
) =>
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(
    callback,
    dependencies.map((d) => (isPrimitive(d) ? d : JSON.stringify(d)))
  );

export { useDeepEffect };
