import { Collapse } from "@chakra-ui/react";
import { useState } from "react";
import { Button } from "../../../Button";
import { WithChildren } from "types";

type CollapsingCellProps = { startingHeight?: number } & WithChildren;

const CollapsingCell = ({
  children,
  startingHeight = 48,
}: CollapsingCellProps) => {
  const [show, setShow] = useState(false);
  const handleToggle = () => setShow(!show);

  return (
    <>
      <Collapse in={show} startingHeight={startingHeight}>
        {children}
      </Collapse>
      <Button variant="link-teal" size="xs" mt={3} onClick={handleToggle}>
        Show {show ? "Less" : "More"}
      </Button>
    </>
  );
};

export { CollapsingCell };
