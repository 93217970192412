import { Box, Center, Flex, Text } from "@chakra-ui/layout";
import { Icon, keyframes, useToast } from "@chakra-ui/react";
import { HasAccess } from "@intentsify/authorization/dist/react";
import { pluralize } from "@intentsify/utils";
import { useLayoutEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useDropzone } from "react-dropzone";
import { MdUpload } from "react-icons/md";
import { useLocation } from "react-router";
import { useEvent } from "react-use";
import { useInvalidateDraftAssets } from "screens/ContentLibrary/Drafts/useDraftAssets";
import { listFormat } from "shared/listFormat";
import { contentLibraryUpload } from "upload/contentLibraryUpload";
import {
  contentLibraryAllowedFileExtensions,
  prettyContentLibraryAllowedFileExtensions,
  prettyContentLibraryAllowedFileExtensionsDisjunction,
} from "./contentLibraryAllowedFileExtensions";

const viewKeyframesWithScale = keyframes`  
  50% {
    transform: translateY(-20%);
  }

  0, 100% {
    transform: translateY(0%);
  } 
`;

export const ContentLibraryDropzone = () => {
  const isDropzoneUnsupportedDueToLocation =
    useIsDropzoneUnsupportedDueToLocation();
  if (isDropzoneUnsupportedDueToLocation) {
    return null;
  }

  return (
    <HasAccess to="contentLibrary">
      <UnsafeContentLibraryDropzone />
    </HasAccess>
  );
};

const UnsafeContentLibraryDropzone = () => {
  const toast = useToast();
  const [isDragging, setIsDragging] = useState(false);
  const [container] = useState(document.createElement("div"));
  const uploadFile = contentLibraryUpload.useUploadFile();
  const invalidateDraftAssets = useInvalidateDraftAssets();
  const dropzone = useDropzone({
    accept: contentLibraryAllowedFileExtensions,
    onDrop: (acceptedFiles, fileRejections) => {
      if (fileRejections.length > 0) {
        const files = pluralize(fileRejections.length, "file", "files");
        const are = pluralize(fileRejections.length, "is", "are");
        const rejectedFileNames = listFormat.format(
          fileRejections.map((fileRejection) => fileRejection.file.name)
        );

        toast({
          title: `Only ${prettyContentLibraryAllowedFileExtensions} file formats are supported`,
          description: (
            <Flex flexDir="column" gap="6">
              <Text>
                We have noticed the {files} you are trying to upload:{" "}
                {rejectedFileNames} {are} currently not supported. We are
                actively working to expand our range of supported file formats.
              </Text>

              <Text>
                At this time, we kindly request that you upload files with
                following formats:{" "}
                <Text as="span" fontWeight="medium">
                  {prettyContentLibraryAllowedFileExtensionsDisjunction}
                </Text>
                . Your understanding and compliance are greatly appreciated as
                we strive to improve our services.
              </Text>
            </Flex>
          ),
          status: "info",
          duration: 12000,
          isClosable: true,
          size: "lg",
        });
      }

      acceptedFiles.forEach((file) =>
        uploadFile(file, {
          onSuccess: invalidateDraftAssets,
        })
      );
    },
  });

  useLayoutEffect(() => {
    const [body] = Array.from(document.getElementsByTagName("body"));

    if (body) {
      body.appendChild(container);
    }
  }, [container]);

  useEvent(
    "dragover",
    () => {
      setIsDragging(true);
    },
    window
  );

  useEvent(
    "drop",
    () => {
      setIsDragging(false);
    },
    window
  );

  useEvent(
    "dragleave",
    () => {
      setIsDragging(false);
    },
    window
  );

  return createPortal(
    <Box
      {...dropzone.getRootProps()}
      position="fixed"
      top={0}
      bottom={0}
      left={0}
      right={0}
      pointerEvents={isDragging ? "auto" : "none"}
      opacity={isDragging ? 1 : 0}
      bg={isDragging ? "blackAlpha.700" : "transparent"}
      zIndex="popover"
    >
      <input {...dropzone.getInputProps()} />

      <Center h="full" w="full">
        {isDragging && (
          <Center flexDir="column">
            <Icon
              as={MdUpload}
              w="3rem"
              h="3rem"
              animation={`${viewKeyframesWithScale} 2s both infinite cubic-bezier(0.22, 1, 0.36, 1)`}
            />
            <Text fontWeight="medium" fontSize="lg">
              Drop your content library assets here
            </Text>
          </Center>
        )}
      </Center>
    </Box>,
    container
  );
};

/**
 * This is a hacky solution to prevent the dropzone from being rendered
 * where it could overlay other drag and drop components.
 *
 * I couldn't figure out the z-index issue where <Upload />
 * would be rendered behind the dropzone when it was placed in an accordion.
 */
const useIsDropzoneUnsupportedDueToLocation = () => {
  const location = useLocation();
  const strictPathes = [
    "/intent-models/create/2",
    "/intent-models/create/3",
    "/target-personas",
    "/tal-tools",
  ].some((path) => location.pathname.startsWith(path));

  if (strictPathes) {
    return true;
  }

  return [
    /\/intent-models\/(.*)\/edit\/2/gi,
    /\/intent-models\/(.*)\/edit\/3/gi,
  ].some((regex) => location.pathname.match(regex));
};
