import { useToast } from "@chakra-ui/react";
import {
  AutomatedProgrammaticSegmentFormState,
  Item,
  PaginatedResponse,
  SortDirection,
} from "@intentsify/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiService, Endpoints } from "api";
import { useNavigate } from "react-router";
import { getPaginatedQuery, valueToQueryURL } from "utils";
import { ProgrammaticDomainsScreenDefinition } from "../ProgrammaticDomains";

export const createAutomatedSegment = async ({
  campaignId,
  tag,
  region,
  hasIspData,
  segmentType,
  buyerResearchStages,
  leadsStrategy,
  excludedAccounts,
  filtersRelationship,
}: AutomatedProgrammaticSegmentFormState) => {
  await apiService.post(
    Endpoints.AutomatedProgrammaticSegment.Post.AutomatedProgrammaticSegment,
    {
      campaignId,
      hasIspData,
      tag,
      region: valueToQueryURL(region),
      segmentType,
      buyerResearchStages,
      leadsStrategy,
      excludedAccounts,
      filtersRelationship,
    }
  );
};

export const useCreateAutomatedSegment = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation(createAutomatedSegment, {
    onSuccess: () => {
      toast({
        title: "Automated segment was created successfully.",
        status: "success",
        duration: 9000,
      });
      queryClient.invalidateQueries(["listAutomatedProgrammaticSegments"]);

      navigate(
        ProgrammaticDomainsScreenDefinition.navigate({ view: "automated" })
      );
    },
  });

  return { createAutomatedSegment: mutate, isLoading };
};

const editAutomatedProgrammaticSegment = async ({
  id,
  tag,
  region,
  hasIspData,
  buyerResearchStages,
  leadsStrategy,
  excludedAccounts,
  segmentType,
  campaignId,
  filtersRelationship,
}: AutomatedProgrammaticSegmentFormState & { id: number }) => {
  await apiService.put(
    Endpoints.AutomatedProgrammaticSegment.Put.AutomatedProgrammaticSegment(id),
    {
      tag,
      region: valueToQueryURL(region),
      hasIspData,
      buyerResearchStages,
      leadsStrategy,
      excludedAccounts,
      segmentType,
      campaignId,
      filtersRelationship,
    }
  );
};

export const useEditAutomatedProgrammaticSegment = () => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation(editAutomatedProgrammaticSegment, {
    onSuccess: () => {
      toast({
        title: "Automated segment was updated successfully.",
        status: "success",
        duration: 9000,
      });
      queryClient.invalidateQueries(["getAutomatedProgrammaticSegment"]);

      navigate(
        ProgrammaticDomainsScreenDefinition.navigate({ view: "automated" })
      );
    },
  });

  return { editAutomatedProgrammaticSegment: mutate, isLoading };
};

type PaginatedAccountParams = {
  campaign_id: number;
  page: number;
  per_page: number;
};

export const listPaginatedActiveAccountsList =
  (campaignId: number) =>
  async (params: PaginatedAccountParams): Promise<PaginatedResponse<Item>> => {
    const query = getPaginatedQuery<Item>(
      Endpoints.AutomatedProgrammaticSegment.Get.ActiveAccounts,
      {
        ...{ ...params, campaign_id: campaignId },
        order: SortDirection.ASC,
        order_by: "label",
      }
    );

    const { data } = await apiService.get(query);
    return data;
  };

export const calculateDomainsCount = async ({
  id,
  tag,
  region,
  hasIspData,
  buyerResearchStages,
  leadsStrategy,
  excludedAccounts,
  segmentType,
  campaignId,
  filtersRelationship,
}: AutomatedProgrammaticSegmentFormState & { id: number }) => {
  const { data } = await apiService.post(
    Endpoints.AutomatedProgrammaticSegment.Post.Count(id),
    {
      tag,
      region: valueToQueryURL(region),
      hasIspData,
      buyerResearchStages,
      leadsStrategy,
      excludedAccounts,
      segmentType,
      campaignId,
      filtersRelationship,
    }
  );

  return data;
};
