import { Layer, Source } from "react-map-gl";
import {
  impressionsClusterCountLayerId,
  impressionsClusterLayerId,
  impressionsPointsLayerId,
  impressionsSourceId,
} from "../config";
import { LayerVisibility } from "../ImpressionsAndTopicsMap.types";
import { UseCampaignImpressionsByLocationReturnType } from "../hooks";
import { useImpressionsClusterLayerProps } from "./useImpressionsClusterLayerProps";
import { useImpressionsPointsLayerProps } from "./useImpressionsPointsLayerProps";
import { useImpressionsClusterCountLayerProps } from "./useImpressionsClusterCountLayerProps";
import { WithChildren } from "types";

const ImpressionsLayers = ({
  impressionsByLocation,
  children,
  visibility,
}: WithChildren<{
  impressionsByLocation: UseCampaignImpressionsByLocationReturnType;
  visibility: LayerVisibility;
}>) => {
  const impressionsClusterLayerProps =
    useImpressionsClusterLayerProps(visibility);
  const impressionsPointsLayerProps = useImpressionsPointsLayerProps(
    impressionsByLocation.maxCountImpressions,
    visibility
  );
  const impressionsClusterCountLayerProps =
    useImpressionsClusterCountLayerProps(visibility);

  return (
    <>
      {children}
      <Source
        cluster
        clusterRadius={30}
        clusterMaxZoom={14}
        clusterProperties={{
          sum: ["+", ["get", "count"]],
        }}
        id={impressionsSourceId}
        type="geojson"
        data={impressionsByLocation.geoJson}
      >
        <Layer
          id={impressionsClusterLayerId}
          {...impressionsClusterLayerProps}
        />

        <Layer
          id={impressionsClusterCountLayerId}
          {...impressionsClusterCountLayerProps}
        />

        <Layer id={impressionsPointsLayerId} {...impressionsPointsLayerProps} />
      </Source>
    </>
  );
};

export { ImpressionsLayers };
