import {
  ContactSearchDTO,
  ContactSearchFiltersRO,
  ContactSearchResultsRO,
} from "@intentsify/dto";
import { Endpoints, apiService } from "api";

export const requestContactSearchResults = async (params: ContactSearchDTO) => {
  const { data } = await apiService.post<ContactSearchResultsRO>(
    Endpoints.ContactSearch.Post.Search,
    params
  );

  return data;
};

export const getContactSearchFilters = async () => {
  const { data } = await apiService.get<ContactSearchFiltersRO>(
    Endpoints.ContactSearch.Get.Filters
  );

  return {
    jobLevel: data.jobLevel.map((i) => ({ label: i, value: i })),
    jobFunction: data.jobFunction.map((i) => ({ label: i, value: i })),
  };
};
