import { Heading } from "@chakra-ui/layout";

type HeaderProps = {
  title?: string;
  titleSize?: string;
  headerComponent?: React.ReactNode;
};

const Header = ({ headerComponent, titleSize, title }: HeaderProps) => {
  if (title) {
    return (
      <Heading
        mb={4}
        mt={1}
        as="h2"
        size={titleSize}
        lineHeight="1"
        minH="32px"
      >
        {title}
      </Heading>
    );
  }

  if (headerComponent) {
    return <>{headerComponent}</>;
  }

  return null;
};

export { Header };
