export const JUST_NOW_DATE = "Just now";

type LocalFile = {
  type: "local";
  fileName: string;
  data: Record<string, string>[];
};

export type RemoteFileUploaded = {
  type: "remote";
  fileName: string;
  isUploading: false;
  isDeleting: boolean;
  downloadLink: string;
  size: number;
  lastModified: Date | typeof JUST_NOW_DATE;
};

export type RemoteFileUploading = {
  type: "remote";
  fileName: string;
  isUploading: true;
  size: number | null;
};

type RemoteFileStates = RemoteFileUploaded | RemoteFileUploading;

export type RemoteFile = Omit<
  RemoteFileUploaded,
  "isUploading" | "isDeleting" | "type"
>;

export type TargetPersonaBlocklistFile = LocalFile | RemoteFileStates;
