import { useToast } from "@chakra-ui/react";
import {
  BatchDeleteTargetPersonasDTO,
  ListTargetPersonasRO,
} from "@intentsify/dto";
import { pluralize } from "@intentsify/utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Endpoints, apiService } from "api";

export const useDeleteTargetPersonas = (
  removeSelection?: (id: number) => void
) => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["targetPersonas", "delete"],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    mutationFn: async (
      idsToDelete: BatchDeleteTargetPersonasDTO["personaIds"]
    ) => {
      await apiService.delete(Endpoints.TargetPersonas.Delete.Batch, {
        data: { personaIds: idsToDelete },
      });
    },
    onSuccess: (data, idsToDelete) => {
      toast({
        title: `Target ${pluralize(
          idsToDelete.length,
          "persona",
          "personas"
        )} deleted`,
        status: "success",
      });

      idsToDelete.forEach((id) => removeSelection?.(id));

      queryClient.setQueriesData<ListTargetPersonasRO>(
        { queryKey: ["targetPersonas"], exact: false },
        (oldData) => ({
          ...oldData,
          targetPersonas:
            oldData?.targetPersonas.filter(
              (persona) => !idsToDelete.includes(persona.id)
            ) ?? [],
        })
      );
    },
  });
};
