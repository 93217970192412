import { Flex } from "@chakra-ui/react";
import { WeeklySnapshotRangeDTO } from "@intentsify/dto";
import { TopCreativePerformance } from "./components";
import { Map } from "./components/Map";
import { Performance } from "./components/Performance";

type CreativeAndImpressionsProps = {
  campaignId: number;
  filters: WeeklySnapshotRangeDTO;
};

const CreativeAndImpressions = ({
  campaignId,
  filters,
}: CreativeAndImpressionsProps) => {
  return (
    <>
      <Performance campaignId={campaignId} filters={filters} />
      <Flex gap={4} mb={4} minH="350px" maxH="500px">
        <Map campaignId={campaignId} filters={filters} />
        <TopCreativePerformance campaignId={campaignId} filters={filters} />
      </Flex>
    </>
  );
};

export { CreativeAndImpressions };
