import { isDefined } from "@intentsify/utils";
import { ActionItem, ActionItemGroup, ActionItems } from "types";

export const isActionItem = (item: ActionItems): item is ActionItem => {
  const possiblyActionItem = item as ActionItem;

  return Boolean(possiblyActionItem.label);
};

export const isActionItemGroup = (
  item: ActionItems
): item is ActionItemGroup => {
  const possiblyActionItemGroup = item as ActionItemGroup;

  return (
    Boolean(possiblyActionItemGroup?.title) &&
    possiblyActionItemGroup.items.filter(isDefined).every(isActionItem)
  );
};
