import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { Endpoints, apiService } from "api";
import { AsyncReturnType } from "type-fest";
import { RequestCumulativeCampaignReportDTO } from "@intentsify/dto";

type Params = {
  id: number;
  params: RequestCumulativeCampaignReportDTO;
};

const requestCumulativeCampaignReport = ({ id, params }: Params) =>
  apiService.post(Endpoints.Reports.CumulativeReport.Post.Request(id), {
    ...params,
  });

type UseRequestCumulativeCampaignReportOptions = UseMutationOptions<
  AsyncReturnType<typeof requestCumulativeCampaignReport>,
  unknown,
  { id: number; params: RequestCumulativeCampaignReportDTO }
>;

export const useRequestCumulativeCampaignReport = (
  options?: UseRequestCumulativeCampaignReportOptions
) => {
  return useMutation({
    mutationFn: requestCumulativeCampaignReport,
    ...options,
  });
};
