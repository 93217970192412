import { Flex } from "@chakra-ui/react";
import { WeeklySnapshotDTO } from "@intentsify/dto";
import { StatCardsContainer } from "components";
import { CardColorScheme } from "components/Card/Card.theme";
import _ from "lodash";
import { useAccountAnalysisStats } from "./AccountAnalysis.state";
import {
  BuyerResearchStageMovement,
  CoverageByEmployeeSize,
  CoverageByRevenueSize,
  CoverageByTopIndustries,
  MostEngagedAccounts,
} from "./components";

const buyerResearchStageColorScheme: Record<string, CardColorScheme> = {
  totalActive: "titleBrand",
  decision: "titleBlue",
  consideration: "titleGreen",
  interest: "titleYellow",
  awareness: "titleTeal",
};

const buyerResearchStageTooltips: Record<string, string> = {
  totalActive:
    "Accounts actively showing intent from the monitored account list.",
  decision: "Decision: Comparing specific products and/or vendors",
  consideration: "Consideration: Identifying various solutions to challenges",
  interest: "Interest: Learning about general problems and/or challenges",
  awareness:
    "Awareness: Accounts are showing a low or inchoate level of research activity",
};

type AccountAnalysisProps = {
  campaignId: number;
  filters: WeeklySnapshotDTO;
};

const AccountAnalysis = ({ campaignId, filters }: AccountAnalysisProps) => {
  const { data: statsPerBuyerResearchStage, isFetching: isStatsFetching } =
    useAccountAnalysisStats(campaignId, filters);

  return (
    <>
      <StatCardsContainer
        items={statsPerBuyerResearchStage.map((stats) => ({
          isLoading: isStatsFetching,
          title: `${stats.buyerResearchStage} Accounts`,
          value: stats.countAccounts,
          colorScheme:
            buyerResearchStageColorScheme[
              _.camelCase(stats.buyerResearchStage)
            ],
          previousPeriodChange: stats.countAccountsChange,
          noValueMessage: "0",
          titleTooltip:
            buyerResearchStageTooltips[_.camelCase(stats.buyerResearchStage)],
        }))}
      />

      <Flex gap={4} mb={4} minH="350px">
        <MostEngagedAccounts campaignId={campaignId} filters={filters} />
        <BuyerResearchStageMovement campaignId={campaignId} filters={filters} />
      </Flex>

      <Flex w="100%" gap={4} mb={4} minH="300px">
        <CoverageByTopIndustries campaignId={campaignId} filters={filters} />
        <CoverageByEmployeeSize campaignId={campaignId} filters={filters} />
        <CoverageByRevenueSize campaignId={campaignId} filters={filters} />
      </Flex>
    </>
  );
};

export { AccountAnalysis };
