import { SegmentState } from "@intentsify/types";

export const statusColor = (state: SegmentState) => {
  switch (state) {
    case SegmentState.ERROR:
      return "red";
    case SegmentState.PROCESSED:
      return "black.100";

    default:
      return "gray";
  }
};
