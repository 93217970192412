import {
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { Tooltip } from "components";
import { useEffect, useState } from "react";

type RangeFilterProps = {
  label?: string;
  step: number;
  min: number;
  max: number;
  onChange: (values: [number, number]) => void;
  isDisabled?: boolean;
  value?: [number, number];
};

const RangeFilter = ({
  label,
  min,
  max,
  step,
  onChange,
  isDisabled,
  value,
}: RangeFilterProps) => {
  const thumbColor = useColorModeValue("blue.50", "white");

  const [currentValue, setCurrentValue] = useState<[number, number]>([
    min,
    max,
  ]);

  useEffect(() => {
    if (value) {
      setCurrentValue(value);
    }
  }, [value]);

  return (
    <Stack my={2} justifyContent={"center"}>
      {label && (
        <Text fontSize="xs" fontWeight="semibold">
          {label}:
        </Text>
      )}

      <RangeSlider
        colorScheme="blue"
        min={min}
        max={max}
        step={step}
        isDisabled={isDisabled}
        defaultValue={[min, max]}
        value={currentValue}
        onChange={(value) => {
          setCurrentValue(value as [number, number]);
        }}
        onChangeEnd={(value) => {
          onChange(value as [number, number]);
        }}
      >
        <RangeSliderTrack>
          <RangeSliderFilledTrack />
        </RangeSliderTrack>

        <Tooltip
          aria-label={label}
          label={`${currentValue[0]}`}
          placement="top"
        >
          <RangeSliderThumb index={0} bg={thumbColor} />
        </Tooltip>
        <Tooltip
          aria-label={label}
          label={`${currentValue[1]}`}
          placement="top"
        >
          <RangeSliderThumb index={1} bg={thumbColor} />
        </Tooltip>
      </RangeSlider>
    </Stack>
  );
};

export { RangeFilter };
