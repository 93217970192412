import {
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
  SpaceProps,
} from "@chakra-ui/react";
import { forwardRef, useMemo } from "react";

export type ButtonVariant =
  | "primary"
  | "primary-teal"
  | "secondary"
  | "outline"
  | "success"
  | "ghost"
  | "warning"
  | "link"
  | "outline-teal"
  | "link-teal";

type ButtonProps = {
  children: ChakraButtonProps["children"];
  variant?: ButtonVariant;
  fullWidth?: boolean;
  onClick?: ChakraButtonProps["onClick"];
  rightIcon?: ChakraButtonProps["rightIcon"];
  leftIcon?: ChakraButtonProps["leftIcon"];
  justifyContent?: ChakraButtonProps["justifyContent"];
  pointerEvents?: ChakraButtonProps["pointerEvents"];
  h?: ChakraButtonProps["h"];
  w?: ChakraButtonProps["w"];
  size?: "md" | "sm" | "xs";
  type?: ChakraButtonProps["type"];
  isDisabled?: ChakraButtonProps["isDisabled"];
  isActive?: ChakraButtonProps["isActive"];
  isLoading?: ChakraButtonProps["isLoading"];
  as?: ChakraButtonProps["as"];
  download?: string;
  href?: string;
} & SpaceProps;

const getPropsPerVariant = (variant: ButtonVariant): ChakraButtonProps => {
  switch (variant) {
    case "primary":
      return {
        variant: "solid",
        colorScheme: "yellow",
      };

    case "primary-teal":
      return {
        variant: "solid",
        colorScheme: "teal",
        color: "black",
      };

    case "secondary":
      return {
        variant: "solid",
        colorScheme: "gray",
      };

    case "outline":
      return {
        variant: "outline",
        colorScheme: "gray",
      };

    case "success":
      return {
        variant: "solid",
        colorScheme: "green",
      };

    case "warning":
      return {
        variant: "solid",
        colorScheme: "red",
      };

    case "ghost":
      return {
        variant: "ghost",
        colorScheme: "gray",
      };

    case "outline-teal":
      return {
        variant: "outline",
        colorScheme: "teal",
        color: "brand.900",
        borderColor: "teal.500",
        _dark: {
          color: "white",
        },
        fontWeight: "400",
      };

    case "link":
      return {
        variant: "link",
        colorScheme: "black",
        textDecoration: "underline",
        fontWeight: "bold",
      };

    case "link-teal":
      return {
        variant: "link",
        colorScheme: "teal",
        textDecoration: "none",
        fontWeight: "bold",
      };
  }
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      isDisabled,
      isActive,
      isLoading,
      type,
      children,
      onClick,
      rightIcon,
      leftIcon,
      justifyContent,
      h,
      w,
      pointerEvents,
      size = "sm",
      variant = "secondary",
      fullWidth = false,
      as,
      download,
      href,
      ...spaceProps
    },
    ref
  ) => {
    const variantProps = useMemo(() => {
      return getPropsPerVariant(variant);
    }, [variant]);

    return (
      <ChakraButton
        ref={ref}
        w={fullWidth ? "100%" : w}
        h={h}
        isDisabled={isLoading || isDisabled}
        isActive={isActive}
        isLoading={isLoading}
        type={type}
        fontWeight="semibold"
        onClick={onClick}
        size={size}
        rightIcon={rightIcon}
        leftIcon={leftIcon}
        justifyContent={justifyContent}
        pointerEvents={pointerEvents}
        {...variantProps}
        {...spaceProps}
        as={as}
        download={download}
        href={href}
      >
        {children}
      </ChakraButton>
    );
  }
);

export { Button };
