import { ColumnDef } from "@tanstack/react-table";
import { PageTracking } from "../PageTracking.types";

const pageTrackingTableColumns: ColumnDef<PageTracking>[] = [
  {
    header: "Page Identifier",
    accessorKey: "pageIdentifier",
  },
  {
    header: "First Time Seen",
    accessorKey: "firstTimeSeen",
  },

  {
    header: "Last Time Seen",
    accessorKey: "lastTimeSeen",
  },
];

export { pageTrackingTableColumns };
