import { Box, Flex, Text } from "@chakra-ui/layout";
import { BaseErrorBoundary } from "components/ErrorBoundary/SimpleErrorBoundary";
import { Suspense } from "react";
import { useParams } from "react-router";
import { OptOutDisplayError } from "./OptOutDisplayError";
import { OptOutDisplayLoading } from "./OptOutDisplayLoading";
import { OptOutError } from "./OptOutError";
import { OptOutInfoForm } from "./OptOutInfoForm";

export function OptOutDisplay() {
  const { token } = useParams<"token">();
  if (!token || typeof token !== "string") {
    throw new Error("Invalid token");
  }

  return (
    <Flex flexDir="column" gap="10">
      <BaseErrorBoundary
        fallback={<OptOutDisplayError />}
        customApiErrorFallback={<OptOutError />}
      >
        <Flex w="full" maxWidth="lg" mx="auto" flexDir="column">
          <Text fontSize="xl">Your information</Text>
        </Flex>

        <Box w="full" maxWidth="lg" mx="auto">
          <Suspense fallback={<OptOutDisplayLoading />}>
            <OptOutInfoForm readOnly token={token} />
          </Suspense>
        </Box>
      </BaseErrorBoundary>
    </Flex>
  );
}
