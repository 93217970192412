import { WorkatoConnection } from "./IntegrationSettings.types";
import { apiService, Endpoints } from "api";

export const listWorkatoConnections = async ({
  companyId,
  workatoCustomerId,
}: {
  companyId: number;
  workatoCustomerId: number;
}): Promise<WorkatoConnection[]> => {
  const { data } = await apiService.get<WorkatoConnection[]>(
    Endpoints.Companies.Get.listWorkatoConnections(companyId, workatoCustomerId)
  );

  return data;
};

export const getWorkatoToken = async ({
  companyId,
  workatoCustomerId,
}: {
  companyId: number;
  workatoCustomerId: number;
}): Promise<string> => {
  const { data } = await apiService.get<{ token: string }>(
    Endpoints.Companies.Get.getWorkatoToken(companyId, workatoCustomerId)
  );

  return data.token;
};
