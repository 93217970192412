import {
  IconButton as ChakraIconButton,
  IconButtonProps as ChakraIconButtonProps,
  TooltipProps as ChakraTooltipProps,
  SpaceProps,
} from "@chakra-ui/react";
import { MouseEvent } from "react";
import { Tooltip, TooltipProps } from "../Tooltip";

type IconButtonProps = {
  icon: ChakraIconButtonProps["icon"];
  tooltip: string;
  onClick: (e: MouseEvent) => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  isActive?: boolean;
  tooltipPlacement?: TooltipProps["placement"];
  tooltipBackground?: TooltipProps["background"];
  tooltipTextColor?: TooltipProps["color"];
  tooltipShouldWrapChildren?: TooltipProps["shouldWrapChildren"];
  variant?: ChakraIconButtonProps["variant"];
  size?: ChakraIconButtonProps["size"];
  colorScheme?: ChakraIconButtonProps["colorScheme"];
  color?: ChakraIconButtonProps["color"];
  as?: ChakraIconButtonProps["as"];
  display?: ChakraIconButtonProps["display"];
  _groupHover?: ChakraIconButtonProps["_groupHover"];
  transition?: ChakraIconButtonProps["transition"];
  download?: string;
  href?: string;
  openDelay?: ChakraTooltipProps["openDelay"];
  target?: string;
  ["aria-label"]?: ChakraTooltipProps["aria-label"];
} & SpaceProps;

const IconButton = ({
  icon,
  tooltip,
  onClick,
  isLoading = false,
  isDisabled = false,
  isActive = false,
  tooltipPlacement = undefined,
  tooltipBackground = undefined,
  tooltipTextColor = undefined,
  variant = "ghost",
  size = "sm",
  colorScheme = undefined,
  color = undefined,
  as = undefined,
  display = undefined,
  _groupHover = undefined,
  transition = undefined,
  "aria-label": ariaLabel,
  download = undefined,
  href = undefined,
  tooltipShouldWrapChildren = true,
  openDelay,
  ...spaceProps
}: IconButtonProps) => {
  return (
    <Tooltip
      aria-label={ariaLabel}
      shouldWrapChildren={tooltipShouldWrapChildren}
      label={tooltip}
      placement={tooltipPlacement}
      background={tooltipBackground}
      color={tooltipTextColor}
      openDelay={openDelay}
    >
      <ChakraIconButton
        isDisabled={isDisabled}
        isActive={isActive}
        aria-label={tooltip}
        size={size}
        isLoading={isLoading}
        variant={variant}
        icon={icon}
        onClick={(e) => onClick(e)}
        colorScheme={colorScheme}
        color={color}
        as={as}
        display={display}
        _groupHover={_groupHover}
        transition={transition}
        href={href}
        download={download}
        {...spaceProps}
      />
    </Tooltip>
  );
};

export { IconButton };
export type { IconButtonProps };
