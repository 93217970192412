import {
  ColumnDef,
  RowSelectionState,
  SortingState,
} from "@tanstack/react-table";
import { Loader, NoData, RowSelectionProps, Table } from "components";
import { cloneElement, useMemo } from "react";
import { useSignal } from "screens/Campaigns/screens/CampaignDetails/CampaignDetailsTable/CampaignDetailsTable.hooks";

type BaseSignalsTableProps<T extends Record<string, unknown>> = {
  columns: Array<ColumnDef<T>>;
  defaultSort: SortingState;
  campaignId: number;
  signalId: number;
  domainId: number;
  weekNumber: number;
  yearNumber: number;
};

type SignalsTableProps<T extends Record<string, unknown>> =
  BaseSignalsTableProps<T> & {
    rowSelectionState?: never;
    setRowSelectionState?: never;
    uniqueKeyAccessor?: never;
  };

type SelectableSignalsTablePropss<T extends Record<string, unknown>> =
  BaseSignalsTableProps<T> & {
    rowSelectionState: RowSelectionState;
    setRowSelectionState: React.Dispatch<
      React.SetStateAction<RowSelectionState>
    >;
    uniqueKeyAccessor: (row: T) => T[keyof T];
  };

const SignalsTable = <T extends Record<string, unknown>>({
  defaultSort,
  columns,
  campaignId,
  domainId,
  signalId,
  weekNumber,
  yearNumber,
  rowSelectionState,
  setRowSelectionState,
  uniqueKeyAccessor,
}: SignalsTableProps<T> | SelectableSignalsTablePropss<T>) => {
  const tableColumns = useMemo<ColumnDef<T>[]>(() => columns, [columns]);

  const signalData = useSignal<T[]>({
    campaignId,
    domainId,
    signalId,
    weekNumber,
    yearNumber,
  });

  if (signalData.isLoading || signalData.isRefetching) {
    return <Loader minHeight={0} height="200" />;
  }

  if (!signalData.data) {
    return <NoData />;
  }

  const component = (
    <Table
      columns={tableColumns}
      data={signalData.data}
      isScrollable={false}
      isControlledByAPI={false}
      stretch
      overflow={"hidden"}
      defaultSort={defaultSort}
    />
  );

  if (rowSelectionState) {
    const rowSelectionProps: RowSelectionProps<T> = {
      selectable: true,
      uniqueKeyAccessor,
      rowSelection: rowSelectionState,
      setRowSelection: setRowSelectionState,
    };

    return cloneElement(component, rowSelectionProps);
  }

  return component;
};

export { SignalsTable };
