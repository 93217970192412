import { Flex } from "@chakra-ui/layout";
import { Button } from "components/Button";
import { contentLibraryAllowedFileExtensionsAsAcceptString } from "components/ContentLibrary/contentLibraryAllowedFileExtensions";
import { ChangeEvent, useRef } from "react";
import { MdUploadFile } from "react-icons/md";

export const FileUploadButton = (props: {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  return (
    <Flex>
      <Button
        variant="primary-teal"
        leftIcon={<MdUploadFile />}
        onClick={() => {
          if (fileInputRef.current) {
            fileInputRef.current.value = "";
            fileInputRef.current.click();
          }
        }}
      >
        Upload content
      </Button>

      <input
        ref={fileInputRef}
        multiple
        type="file"
        style={{ display: "none" }}
        accept={contentLibraryAllowedFileExtensionsAsAcceptString}
        onChange={props.onChange}
      />
    </Flex>
  );
};
