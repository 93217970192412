import { ColorMode } from "@chakra-ui/color-mode";
import { DateTime } from "luxon";

const getWeekDays = (weekStart: { from: Date; to: Date }) => {
  const days = [weekStart.from];
  for (let i = 1; i < 7; i += 1) {
    days.push(DateTime.fromJSDate(weekStart.from).plus({ days: i }).toJSDate());
  }
  return days;
};

const getWeekRange = (date: Date) => {
  return {
    from: DateTime.fromJSDate(date).startOf("week").toJSDate(),
    to: DateTime.fromJSDate(date).endOf("week").toJSDate(),
  };
};

const getDatePickerCssModifiers = (colorMode: ColorMode) => {
  if (colorMode === "dark") {
    return `
--rdp-cell-size: 2.5rem;
--rdp-accent-color-dark: var(--chakra-colors-blue-100);
--rdp-accent-color: var(--chakra-colors-brand-100);
--rdp-background-color: var(--chakra-colors-brand-300);
--rdp-click-color: var(--chakra-colors-brand-200);
--rdp-outline-selected: 0px;
--rdp-outline: 2px solid var(--rdp-accent-color);
.rdp-day_selectedRange {
  color: pink
};
.rdp-weeknumber {
  color: rgba(255, 255, 255, 0.3);
}
  `;
  }

  return `
--rdp-cell-size: 2.5rem;
--rdp-accent-color-dark: var(--chakra-colors-blue-100);
--rdp-accent-color: var(--chakra-colors-brand-100);
--rdp-background-color: var(--chakra-colors-gray-100);
--rdp-click-color: var(--chakra-colors-gray-50);
--rdp-outline-selected: 0px;
--rdp-outline: 2px solid var(--rdp-accent-color);
.rdp-day_selectedRange {
  color: pink
};
.rdp-weeknumber {
  color: rgba(0, 0, 0, 0.3);
}
  `;
};

export { getWeekDays, getWeekRange, getDatePickerCssModifiers };
