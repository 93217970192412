import {
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import { ErrorBoundary } from "@sentry/react";
import { PropsWithChildren } from "react";

interface TargetPersonasModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const TargetPersonasModal = ({
  children,
  isOpen,
  onClose,
}: PropsWithChildren<TargetPersonasModalProps>) => {
  return (
    <Modal isCentered size="3xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent
        px="6"
        py="5"
        bg="white"
        _dark={{ bg: "brand.450" }}
        borderRadius="xl"
        border="2px solid"
        borderColor="whiteAlpha.100"
      >
        <VStack spacing="12" alignItems="flex-start">
          {children}
        </VStack>
      </ModalContent>
    </Modal>
  );
};

export const TargetPersonasModalHeader = (props: PropsWithChildren) => (
  <ModalHeader p="0" w="100%">
    <VStack spacing="1" alignItems="flex-start">
      <Heading fontSize="lg" color="black" _dark={{ color: "white" }}>
        {props.children}
      </Heading>
    </VStack>

    <ModalCloseButton
      color="blackAlpha.700"
      _hover={{ color: "black", bg: "blackAlpha.100" }}
      _focus={{ color: "black", bg: "blackAlpha.100" }}
      _dark={{
        color: "whiteAlpha.600",
        _hover: { color: "whiteAlpha.800", bg: "whiteAlpha.100" },
        _focus: { color: "whiteAlpha.800", bg: "whiteAlpha.100" },
      }}
    />
  </ModalHeader>
);

export const TargetPersonasModalBody = (props: PropsWithChildren) => (
  <ModalBody p="0" w="100%">
    <ErrorBoundary>{props.children}</ErrorBoundary>
  </ModalBody>
);
