import { GetCompanyRO } from "@intentsify/dto";
import { Unsafe } from "@intentsify/types";
import { useQueryClient, UseQueryOptions } from "@tanstack/react-query";
import { apiService, Endpoints } from "api";
import { Duration } from "luxon";
import { AsyncReturnType } from "type-fest";

const getCompanyById = async (companyId: string) => {
  const { data } = await apiService.get<GetCompanyRO>(
    Endpoints.Companies.Get.ById(companyId)
  );

  return data.company;
};

const queryKey = (companyId: Unsafe<string | number>) => [
  "getCompanyById",
  String(companyId),
];

export const useInvalidateCompany = () => {
  const queryClient = useQueryClient();

  return (companyId: Unsafe<string | number>) => {
    queryClient.invalidateQueries({
      queryKey: queryKey(companyId),
      exact: true,
    });
  };
};

export const useInvalidateCompaniesList = () => {
  const queryClient = useQueryClient();

  return () => {
    queryClient.invalidateQueries({ queryKey: ["companiesList"] });
  };
};

type UseCompanyQueryOptions = UseQueryOptions<
  AsyncReturnType<typeof getCompanyById>
>;

export const useCompanyQueryOptions = (
  companyId: Unsafe<string | number>,
  queryOptions?: UseCompanyQueryOptions
) =>
  ({
    queryFn: () => getCompanyById(String(companyId)),
    queryKey: queryKey(companyId),
    staleTime: Duration.fromObject({ minutes: 5 }).as("millisecond"),
    ...queryOptions,
  } satisfies UseCompanyQueryOptions);
