import { atom } from "recoil";

export const DomainsStepState = {
  isLoadingFirmographicsAtom: atom<boolean>({
    key: `domainsStepState_isLoadingFirmographicsAtom`,
    default: false,
  }),
  numberOfSelectedDomains: atom<number>({
    key: `domainsStepState_numberOfSelectedDomainsAtom`,
    default: 0,
  }),
};
