import { useHasAccessTo } from "@intentsify/authorization/dist/react";
import { WeeklySnapshotRangeDTO } from "@intentsify/dto";
import { Button } from "components";
import { useWeeklySnapsotReportRequest } from "../../WeeklySnapshot.state";

type RequestReportButtonProps = {
  campaignId: number;
  isCampaignSelected: boolean;
  filters: WeeklySnapshotRangeDTO;
};

const RequestReportButton = ({
  campaignId,
  filters,
  isCampaignSelected,
}: RequestReportButtonProps) => {
  const hasAccessToExportPdf = useHasAccessTo(
    "campaign.weeklySnapshotExportPdf"
  );
  const { requestWeeklySnapshotReport, isWeeklySnapshotReportLoading } =
    useWeeklySnapsotReportRequest(campaignId, filters);

  if (!hasAccessToExportPdf) {
    return null;
  }

  return (
    <Button
      variant="primary-teal"
      onClick={() => requestWeeklySnapshotReport()}
      isDisabled={!isCampaignSelected}
      isLoading={isWeeklySnapshotReportLoading}
    >
      Export PDF
    </Button>
  );
};

export { RequestReportButton };
