import { Option as IntentsifyOption } from "@intentsify/types";
import ReactSelect, { GroupBase, Props } from "react-select";
import { useSimpleSelectComponents } from "./useSimpleSelectComponents";
import { useSimpleSelectStyles } from "./useSimpleSelectStyles";

type SimpleSelectProps<
  Option extends IntentsifyOption,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
> = Omit<
  Props<Option, IsMulti, Group>,
  "styles" | "components" | "onFetchDataChange" | "totalPages" | "currentPage"
>;

export const SimpleSelect = <
  Option extends IntentsifyOption,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: SimpleSelectProps<Option, IsMulti, Group>
) => {
  const simpleSelectStyles = useSimpleSelectStyles<Option, IsMulti, Group>();
  const simpleSelectComponents = useSimpleSelectComponents<
    Option,
    IsMulti,
    Group
  >();

  return (
    <ReactSelect<Option, IsMulti, Group>
      {...props}
      styles={simpleSelectStyles}
      components={simpleSelectComponents}
    />
  );
};
