import { DataPackageMap, Unsafe } from "@intentsify/types";
import { UseQueryOptions } from "@tanstack/react-query";
import { Endpoints, apiService } from "api";
import { Duration } from "luxon";
import { AsyncReturnType } from "type-fest";

const getCompanyDataPackage = async (companyId: string) => {
  const { data } = await apiService.get<DataPackageMap>(
    Endpoints.Companies.Get.DataPackage(companyId)
  );

  return data;
};

const queryKey = (companyId: Unsafe<string | number>) => [
  "getCompanyDataPackage",
  companyId,
];

type UseCompanyDataPackageQueryOptions = UseQueryOptions<
  AsyncReturnType<typeof getCompanyDataPackage>
>;

export const useCompanyDataPackageQueryOptions = (
  companyId: Unsafe<string | number>,
  queryOptions?: UseCompanyDataPackageQueryOptions
) =>
  ({
    queryFn: () => getCompanyDataPackage(String(companyId)),
    queryKey: queryKey(companyId),
    staleTime: Duration.fromObject({ minutes: 5 }).as("millisecond"),
    ...queryOptions,
  } satisfies UseCompanyDataPackageQueryOptions);
