import { Divider, Flex } from "@chakra-ui/react";
import { MutableRefObject } from "react";
import { useNavigate } from "react-router";
import { CreateCampaignScreenDefinition } from "../../CreateCampaign.definition";
import { SubmitTrigger } from "../SettingsStep/SettingsForm/SettingsForm";
import { CreateControls } from "./CreateControls";
import { EditControls } from "./EditControls";

type ControlsProps = {
  isDisabled: boolean;
  editing: boolean;
  formRef: MutableRefObject<SubmitTrigger | undefined>;
  onNextLabel?: string;
  step: number;
  campaignId?: number;
};

const Controls = ({
  editing,
  formRef,
  isDisabled = false,
  onNextLabel = undefined,
  step,
  campaignId,
}: ControlsProps) => {
  const currentStep = Number(step);
  const navigate = useNavigate();

  const previousStepHandler = () =>
    navigate(
      CreateCampaignScreenDefinition.navigate({
        campaignId: Number(campaignId),
        step: currentStep - 1,
      })
    );

  return (
    <>
      <Divider mt={20} mb={4} />

      <Flex justifyContent="flex-end">
        {editing ? (
          <EditControls
            isDisabled={isDisabled}
            onSave={() => {
              formRef.current?.submit({ shouldExitOnSuccess: false });
            }}
            onSaveAndExit={() => {
              formRef.current?.submit({ shouldExitOnSuccess: true });
            }}
          />
        ) : (
          <CreateControls
            onNextLabel={onNextLabel}
            isDisabled={isDisabled}
            onNext={() => {
              formRef.current?.submit({ shouldExitOnSuccess: false });
            }}
            onPrevious={currentStep > 1 ? previousStepHandler : undefined}
          />
        )}
      </Flex>
    </>
  );
};

export { Controls };
