import bbox from "@turf/bbox";

export const useMapInitialBounds = (
  coordinates: { longitude: number; latitude: number }[]
) => {
  const longitudeLatitudes = coordinates.map(({ longitude, latitude }) => {
    return [longitude, latitude];
  });

  return bbox({
    type: "LineString" as const,
    coordinates: longitudeLatitudes,
  }) as [number, number, number, number];
};
