import { AutoCompleteInput } from "components";

import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { ContactSearchDomainRO } from "@intentsify/dto";
import { type ContactSearchFilters as ContactSearchFiltersType } from "@intentsify/types";
import { isPopulatedArray, isValidDomain } from "@intentsify/utils";
import { useMutation } from "@tanstack/react-query";
import { Endpoints, apiService } from "api";
import { DynamicInputItem } from "components/DynamicInput/components";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDebounce } from "react-use";
import { useComponentColors } from "theme";
import { z } from "zod";

export const domainSchema = z.object({
  domain: z
    .string()
    .min(3, { message: "Must be 3 or more characters long" })
    .max(253)
    .default("")
    .refine((str) => isValidDomain(str), {
      message: "Must be a valid domain",
    }),
});

const AccountsSelector = ({
  filters,
  onChange,
}: {
  filters: ContactSearchFiltersType;
  onChange: (domains: string[]) => void;
}) => {
  const componentColors = useComponentColors();
  const [suggestions, setSuggestions] = useState<string[]>([]);

  const {
    control,
    formState: { errors },
    watch,
    reset,
  } = useForm<{ domain: string }>({
    mode: "all",
    resolver: zodResolver(domainSchema),
    defaultValues: {
      domain: "",
    },
  });

  const inputValue = watch("domain");

  const { mutate, isLoading: isLoadingMutation } = useMutation(
    async (query: string) => {
      const { data } = await apiService.get<ContactSearchDomainRO>(
        Endpoints.ContactSearch.Get.SearchDomains(query)
      );

      return data.results;
    },
    {
      onSuccess: (data) => {
        if (isPopulatedArray(data)) {
          setSuggestions(data);
        }
      },
    }
  );

  useDebounce(
    () => {
      if (inputValue.length >= 3) {
        mutate(inputValue);
      } else {
        setSuggestions([]);
      }
    },
    250,
    [inputValue]
  );

  return (
    <FormControl isRequired isInvalid={!!errors?.domain?.message}>
      <FormLabel
        fontSize="sm"
        fontWeight="md"
        color={componentColors.form.formLabelColor}
      >
        Domain
      </FormLabel>

      {isPopulatedArray(filters.domain) && (
        <Box mb={2}>
          {filters.domain.map((item, index) => {
            return (
              <DynamicInputItem
                key={index}
                item={item}
                removeItem={(item) =>
                  onChange(filters.domain.filter((i) => i !== item))
                }
              />
            );
          })}
        </Box>
      )}

      <Controller
        name="domain"
        control={control}
        render={({ field: { onChange: onChangeController } }) => (
          <AutoCompleteInput
            placeholder="Enter a domain"
            suggestions={suggestions}
            onSelection={(domain) => {
              if (!isValidDomain(domain)) {
                return;
              }

              onChange([...filters.domain, domain]);
              reset({ domain: "" });
            }}
            isLoading={isLoadingMutation}
            inputValue={inputValue}
            onInputValueChange={(value) => onChangeController(value)}
          />
        )}
      />

      <FormErrorMessage>{errors.domain?.message}</FormErrorMessage>
    </FormControl>
  );
};

export { AccountsSelector };
