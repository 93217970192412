import { apiService, Endpoints } from "api";
import { Nullable } from "@intentsify/types";

export const getAdSolutionsReportDateRange = async (campaignId: number) => {
  const { data } = await apiService.get<{
    startDate: Nullable<string>;
    endDate: Nullable<string>;
  }>(
    Endpoints.Reports.CumulativeReport.Get.AdSolutionsReportDateRange(
      campaignId
    )
  );

  return data;
};
