import { z } from "zod";

import { Flex, Text } from "@chakra-ui/layout";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { HasAccess } from "@intentsify/authorization/dist/react";
import { MAX_JOB_TITLES_COUNT, TargetPersona } from "@intentsify/types";
import {
  ProgressEventDetails,
  buildZodCsvValidator,
  validateCsvStream,
} from "@intentsify/utils/csvStream/browser";
import { Button, FilterAsyncTokenBased, Select, Upload } from "components";
import { useConfirm } from "components/ConfirmDialog/useConfirm";
import { useMemo, useState } from "react";
import { ErrorCode, useDropzone } from "react-dropzone";
import { Controller, DefaultValues, useForm } from "react-hook-form";
import { getJobFunctions, getJobLevels } from "./useTargetPersonas";

export function TargetPersonasForm<
  Optional extends boolean,
  Data = Optional extends true
    ? TargetPersonaPartialUpdate
    : TargetPersonaCreate
>(props: {
  optional?: Optional;
  omitName?: boolean;
  defaultValues?: DefaultValues<Data>;
  onSubmit: (data: Data) => void | Promise<void>;
  isLoading?: boolean;
}) {
  const resolver = useMemo(
    () => (props.optional ? TargetPersonaPartialUpdate : TargetPersonaCreate),
    [props.optional]
  );
  const form = useForm<TargetPersonaUpdate | TargetPersonaCreate>({
    resolver: zodResolver(resolver),
    defaultValues: props.defaultValues
      ? {
          name: "",
          jobFunctions: [],
          jobLevels: [],
          jobTitles: [],
          jobTitleKeywords: [],
          ...props.defaultValues,
        }
      : undefined,
  });

  const errors = form.formState.errors as typeof form.formState.errors & {
    general?: { message: string };
  };
  const jobTitles = form.watch("jobTitles");
  const jobTitleKeywords = form.watch("jobTitleKeywords");

  const toast = useToast();
  const confirm = useConfirm();
  const [validationProgress, setValidationProgress] = useState<
    number | undefined
  >(undefined);
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: { "text/csv": [".csv"] },
    maxFiles: 1,
    minSize: 0,
    disabled: props.isLoading,
    maxSize: 60 * 1024,
    onDropRejected: (issues) => {
      const isFileTooLarge = issues.find((rejection) =>
        rejection.errors.find((error) => error.code === ErrorCode.FileTooLarge)
      );

      if (isFileTooLarge) {
        toast({
          status: "error",
          description: "File too large. Maximum size allowed: 60KB.",
          duration: null,
          isClosable: true,
        });

        return;
      }

      toast({
        status: "error",
        description: issues[0].errors[0].message,
        duration: null,
        isClosable: true,
      });
    },
    onDrop: (files) => {
      const [file] = files;

      if (!file) {
        return;
      }

      const fileSize = file.size;
      const { events, whenComplete } = validateCsvStream(
        file.stream().pipeThrough(new TextDecoderStream()),
        jobTitlesCsvValidator()
      );
      events.addEventListener("progress", ((
        e: CustomEvent<ProgressEventDetails>
      ) => {
        setValidationProgress(e.detail.proceed / fileSize);
      }) as EventListener);

      async function handleFile() {
        setValidationProgress(undefined);
        const text = await file.text();
        const results = text
          .split(/\r?\n|\r|\n/g)
          .filter(Boolean)
          .map((line) => line.trim().replace(/['"]/g, ""));
        // remove header
        results.shift();

        if (results.length === 0) {
          toast({
            status: "info",
            description: "No job titles found in the file.",
            duration: 4000,
            isClosable: true,
          });
          return;
        }

        if (results.length > MAX_JOB_TITLES_COUNT) {
          toast({
            status: "info",
            description: `Maximum number of job titles is ${MAX_JOB_TITLES_COUNT}. We will only use the first ${MAX_JOB_TITLES_COUNT} job titles.`,
            duration: null,
            isClosable: true,
          });
        }

        if (jobTitles.length === MAX_JOB_TITLES_COUNT) {
          if (
            await confirm({
              title: `Job titles override`,
              description: `Are you sure you want to override the job titles? This action will replace the existing job titles with the newly uploaded ones because there are already ${MAX_JOB_TITLES_COUNT} job titles.`,
              confirmText: "Yes, override",
            })
          ) {
            form.setValue("jobTitles", results.slice(0, MAX_JOB_TITLES_COUNT));
          }
          return;
        }

        if (jobTitles.length > 0) {
          if (
            await confirm({
              title: `Job titles merge`,
              description: `Are you sure you want to merge job titles? This action will merge the existing job titles with the newly uploaded ones. If there are more than ${MAX_JOB_TITLES_COUNT} job titles, we will only merge the first ${MAX_JOB_TITLES_COUNT} job titles.`,
              confirmText: "Yes, merge",
            })
          ) {
            form.setValue(
              "jobTitles",
              (jobTitles ?? []).concat(results).slice(0, MAX_JOB_TITLES_COUNT)
            );
          }
          return;
        }

        form.setValue("jobTitles", results.slice(0, MAX_JOB_TITLES_COUNT));
      }

      whenComplete
        .finally(() => {
          void handleFile();
        })
        .catch((err: Error) => {
          toast({
            status: "error",
            description: "CSV is invalid: " + err.message,
            duration: null,
            isClosable: true,
          });
        });
    },
  });

  return (
    <Flex
      as="form"
      gap="4"
      py="2"
      flexDir="column"
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onSubmit={form.handleSubmit((data) => {
        props.onSubmit(data as Data);
      })}
    >
      <Flex mt="-4" gap="4">
        {!props.omitName && (
          <FormControl
            isRequired={!props.optional}
            isInvalid={Boolean(errors?.name?.message)}
          >
            <FormLabel>Name</FormLabel>
            <Input
              {...form.register("name")}
              placeholder="Persona name"
              shadow="sm"
              size="sm"
              rounded="md"
              autoComplete="off"
            />
            <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
          </FormControl>
        )}

        <FormControl
          isRequired={!props.optional}
          isInvalid={Boolean(errors.jobLevels?.message)}
        >
          <FormLabel>Job levels</FormLabel>
          <Controller
            name="jobLevels"
            control={form.control}
            render={({ field }) => (
              <FilterAsyncTokenBased
                isMulti
                currentValue={
                  field.value?.map((t) => ({
                    label: t.name,
                    value: t.id,
                  })) ?? []
                }
                defaultOptions={[]}
                dataRequest={getJobLevels}
                label="Job levels"
                showLabel={false}
                placeholder="Select job levels"
                onFilterValuesChange={(options) => {
                  field.onChange(
                    (options ?? []).map((option) => ({
                      id: option.value,
                      name: option.label,
                    }))
                  );
                }}
              />
            )}
          />
          <FormErrorMessage>{errors.jobLevels?.message}</FormErrorMessage>
        </FormControl>
      </Flex>

      <Flex>
        <FormControl
          isRequired={!props.optional}
          isInvalid={Boolean(errors.jobFunctions?.message)}
        >
          <FormLabel>Job functions</FormLabel>
          <Controller
            name="jobFunctions"
            control={form.control}
            render={({ field }) => (
              <FilterAsyncTokenBased
                isMulti
                currentValue={
                  field.value?.map((t) => ({
                    label: t.name,
                    value: t.id,
                  })) ?? []
                }
                defaultOptions={[]}
                label="Job functions"
                showLabel={false}
                dataRequest={getJobFunctions}
                placeholder="Select job functions"
                onFilterValuesChange={(options) => {
                  field.onChange(
                    (options ?? []).map((option) => ({
                      id: option.value,
                      name: option.label,
                    }))
                  );
                }}
              />
            )}
          />
          <FormErrorMessage>{errors.jobFunctions?.message}</FormErrorMessage>
        </FormControl>
      </Flex>

      <Flex gap="4">
        <FormControl isInvalid={Boolean(errors.jobTitles?.message)}>
          <FormLabel>Job titles</FormLabel>
          <Flex flexDir="column" gap="4">
            <Controller
              name="jobTitles"
              control={form.control}
              render={({ field }) => (
                <Select
                  isMulti
                  isCreatable
                  placeholder="Add job titles"
                  options={
                    jobTitles?.map((title) => ({
                      label: title,
                      value: title,
                    })) ?? []
                  }
                  value={
                    field.value
                      ?.filter((title) => jobTitles?.includes(title))
                      .map((title) => ({
                        label: title,
                        value: title,
                      })) ?? []
                  }
                  onChange={(value) => {
                    if (value && Array.isArray(value)) {
                      field.onChange(value.map((option) => option.value));
                    }
                  }}
                />
              )}
            />
          </Flex>

          <FormErrorMessage>{errors.jobTitles?.message}</FormErrorMessage>
        </FormControl>

        <HasAccess to="targetPersona.jobTitleKeywords">
          <FormControl isInvalid={Boolean(errors.jobTitleKeywords?.message)}>
            <FormLabel>Job title keywords</FormLabel>
            <Controller
              name="jobTitleKeywords"
              control={form.control}
              render={({ field }) => (
                <Select
                  isMulti
                  isCreatable
                  placeholder="Add job title keywords"
                  options={
                    jobTitleKeywords?.map((titleKeyword) => ({
                      label: titleKeyword,
                      value: titleKeyword,
                    })) ?? []
                  }
                  value={
                    field.value
                      ?.filter((titleKeyword) =>
                        jobTitleKeywords?.includes(titleKeyword)
                      )
                      .map((titleKeyword) => ({
                        label: titleKeyword,
                        value: titleKeyword,
                      })) ?? []
                  }
                  onChange={(value) => {
                    if (value && Array.isArray(value)) {
                      field.onChange(value.map((option) => option.value));
                    }
                  }}
                />
              )}
            />

            <FormErrorMessage>
              {errors.jobTitleKeywords?.message}
            </FormErrorMessage>
          </FormControl>
        </HasAccess>
      </Flex>

      <HasAccess to="targetPersona.jobTitlesCsv">
        <Flex>
          <Upload
            getInputProps={getInputProps}
            getRootProps={getRootProps}
            validationProgress={validationProgress}
            subHeader={`CSV file with a "Job Title" column with up to 300 job titles.`}
          />
        </Flex>
      </HasAccess>

      <Flex alignItems="center" gap="4" alignSelf="end" mt="8">
        {errors.general?.message && (
          <Text color="red.500" fontSize="sm" _dark={{ color: "red.200" }}>
            {errors.general?.message}
          </Text>
        )}

        <Button
          type="submit"
          variant="primary-teal"
          isLoading={props.isLoading}
        >
          Save
        </Button>
      </Flex>
    </Flex>
  );
}

const jobTitlesCsvValidator = () =>
  buildZodCsvValidator(
    z.object({
      "Job Title": z.string().min(1, "Job title must be at least 1 character"),
    }),
    {
      quoteMode: "auto",
      caseInsensitiveHeader: true,
      validationMode: "validateAll",
      separator: "\t",
    }
  );

export const TargetPersonaUpdate = TargetPersona.pick({
  id: true,
  name: true,
  jobTitles: true,
  jobTitleKeywords: true,
  jobLevels: true,
  jobFunctions: true,
});
type TargetPersonaUpdate = z.infer<typeof TargetPersonaUpdate>;

const TargetPersonaPartialUpdate = TargetPersonaUpdate.partial().refine(
  (data) => !Object.values(data ?? {}).every((value) => value === undefined),
  { message: "Please update at least one field", path: ["general"] }
);
type TargetPersonaPartialUpdate = z.infer<typeof TargetPersonaPartialUpdate>;

export const TargetPersonaCreate = TargetPersonaUpdate.omit({
  id: true,
}).extend({ companyId: z.number().optional() });
export type TargetPersonaCreate = z.infer<typeof TargetPersonaCreate>;
