type ClarityTags = "userId-intentsify" | "screen" | "companyId" | "agencyId";

export const registerCustomTag = (tag: ClarityTags, value: string) => {
  try {
    // @ts-expect-error
    if (typeof clarity !== "undefined") {
      // @ts-expect-error
      clarity("set", tag, value);
    }
  } catch (err) {
    if (err) {
      console.error(err);
    }
  }
};
