import { Flex } from "@chakra-ui/react";
import { ActiveCampaignForList, SortDirection } from "@intentsify/types";
import { useQuery } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import { listActiveCampaigns } from "api";
import { Table } from "components";
import { useMemo, useState } from "react";
import { useParams } from "react-router";
import { useMount } from "react-use";
import {
  CampaignActionsItems,
  CampaignMetaHeader,
  useCampaignMeta,
} from "shared/components";
import { useComponentColors } from "theme";
import { FetchDataParamsWithToken } from "types";
import { useScreen } from "utils";
import { activeCampaignsTableColumns } from "../CompanyLeadPacing/components/ActiveCampaigns/ActiveCampaigns.columns";
import { LeadPacingScreenDefinition } from "./LeadPacing.definition";

const defaultListPaginatedCampaignsParams: FetchDataParamsWithToken<
  keyof ActiveCampaignForList
> = {
  pageToken: "",
  order_by: "campaignId",
  order: SortDirection.ASC,
};

const LeadPacing = () => {
  useScreen(LeadPacingScreenDefinition);

  const { companyId } = useParams<"companyId">();
  const campaignId = Number(useParams<"campaignId">().campaignId);

  const {
    form: { stackBg },
  } = useComponentColors();

  const columns = useMemo<ColumnDef<ActiveCampaignForList>[]>(
    () => activeCampaignsTableColumns(),
    []
  );

  const [fetchDataParams] = useState<
    FetchDataParamsWithToken<keyof ActiveCampaignForList>
  >(defaultListPaginatedCampaignsParams);

  const { data, isFetching } = useQuery(
    ["activeCampaignsList", companyId, campaignId],
    () => {
      return listActiveCampaigns(Number(companyId), {
        ...fetchDataParams,
        search: String(campaignId),
      });
    }
  );

  const { campaignMetaIsLoading, campaignMeta } = useCampaignMeta({
    campaignId,
  });

  const [portalNode, setPortalNode] = useState<HTMLElement | null>(
    document.getElementById("top-actions-portal")
  );

  useMount(() => {
    if (!portalNode) {
      setPortalNode(document.getElementById("top-actions-portal"));
    }
  });

  return (
    <>
      <Flex justifyContent="space-between" p={4} alignItems="top">
        <CampaignMetaHeader />
        <CampaignActionsItems
          isLoading={campaignMetaIsLoading}
          campaignId={Number(campaignId)}
          companyId={campaignMeta?.companyId}
          campaignCreatedDate={campaignMeta?.campaignCreateDate}
          hasBeenMappedToConvertr={Boolean(campaignMeta?.bookedLeads)}
          hasLeads={Boolean(campaignMeta?.numberOfLeads)}
          hasSpotlight={campaignMeta?.hasSpotlight}
          campaignStatusId={campaignMeta?.campaignStatusId}
          numberOfVisibleItems={1}
          showCampaignDetailsAction={true}
          hasRelatedProgrammaticCampaigns={Boolean(
            campaignMeta?.hasRelatedProgrammaticCampaigns
          )}
          hasPixel={Boolean(campaignMeta?.hasPixel)}
          campaignStartDate={campaignMeta?.campaignStartDate}
          campaignEndDate={campaignMeta?.campaignEndDate ?? undefined}
        />
      </Flex>
      <Table
        bg={stackBg}
        hideHead={true}
        variant="intentsifyNew"
        defaultSort={[
          { id: defaultListPaginatedCampaignsParams.order_by, desc: true },
        ]}
        columns={columns}
        isFetching={isFetching}
        data={data?.activeCampaigns || []}
        onFetchDataChange={() => undefined}
        hidePagination
      />
    </>
  );
};

export { LeadPacing };
