import { toNumberDisplayValue } from "@intentsify/utils";
import { AnyColour, parseModifiableColour } from "theme";

export const getChartColorsByPalette = (
  colorMode: "light" | "dark",
  anyColour: AnyColour
) => {
  const [palette, modifier] = parseModifiableColour(anyColour);
  const toNum = colorMode === "light" ? 300 + modifier : 200 - modifier;

  return Array(4)
    .fill(undefined)
    .map(
      (_, index) => `var(--chakra-colors-${palette}-${toNum + 200 * index})`
    );
};

export const getLegendDirection = (
  legendPosition: "bottom" | "right" | undefined
) => {
  switch (legendPosition) {
    case "bottom":
      return "row";
    case "right":
      return "column";
  }
};

export const getWrapperDirection = (
  legendPosition: "bottom" | "right" | undefined
) => {
  switch (legendPosition) {
    case "bottom":
      return "column";
    case "right":
      return "row";
  }
};

export const formatTickValue = (value: number) =>
  toNumberDisplayValue(value, { compact: true });

const ctx =
  process.env.NODE_ENV !== "test"
    ? document.createElement("canvas").getContext("2d")
    : null;

export type MeasureTextProps = {
  fontFamily?: string;
  fontSize: number;
};

export const measureTextWidth = (
  text: string,
  { fontFamily = "--chakra-fonts-body", fontSize }: MeasureTextProps
) => {
  if (!ctx) {
    console.error("Browser has not support for 2D canvas");
    return 100;
  }

  const resolvedFontFamily = getComputedStyle(
    document.documentElement
  ).getPropertyValue(fontFamily);

  ctx.font = `${fontSize}px ${resolvedFontFamily}`;

  return ctx.measureText(text).width;
};

interface TrimTextByWidthProps extends MeasureTextProps {
  maxWidth: number;
  trimReplacement?: string;
}

export const trimTextByWidth = (
  text: string,
  options: TrimTextByWidthProps
) => {
  const { maxWidth, trimReplacement = "..." } = options;
  const textReplacementWidth = measureTextWidth(trimReplacement, options);
  const textWidth = measureTextWidth(text, options);
  if (textWidth <= maxWidth) {
    return text;
  }
  const desiredWidth = maxWidth - textReplacementWidth;
  let potentialTrimIndex = Math.round(text.length * (textWidth / desiredWidth));
  let potentiallyTrimmedText =
    text.slice(0, potentialTrimIndex) + trimReplacement;
  let potentialTrimWidth = measureTextWidth(potentiallyTrimmedText, options);

  if (potentialTrimWidth > desiredWidth) {
    do {
      potentialTrimIndex -= 1;
      potentiallyTrimmedText =
        text.slice(0, potentialTrimIndex) + trimReplacement;
      potentialTrimWidth = measureTextWidth(potentiallyTrimmedText, options);
    } while (potentialTrimWidth > desiredWidth);
  } else if (potentialTrimWidth < desiredWidth) {
    let nextPotentialTrimIndex = potentialTrimIndex;
    let nextPotentiallyTrimmedText = potentiallyTrimmedText;
    let nextPotentialTrimWidth = potentialTrimWidth;
    do {
      potentiallyTrimmedText = nextPotentiallyTrimmedText;
      potentialTrimWidth = measureTextWidth(potentiallyTrimmedText, options);

      nextPotentialTrimIndex += 1;
      nextPotentiallyTrimmedText =
        text.slice(0, nextPotentialTrimIndex) + trimReplacement;
      nextPotentialTrimWidth = measureTextWidth(
        nextPotentiallyTrimmedText,
        options
      );
    } while (nextPotentialTrimWidth < desiredWidth);
  }

  return potentiallyTrimmedText;
};
