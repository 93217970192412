import {
  Flex,
  LayoutProps,
  Spinner,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

interface LoaderProps {
  minHeight?: LayoutProps["minHeight"];
  height?: LayoutProps["height"];
  stretch?: boolean;
  label?: string | false;
}

const Loader = ({
  minHeight = "50vh",
  height = "auto",
  stretch = false,
  label = "Loading data...",
}: LoaderProps) => {
  return (
    <Flex
      minHeight={minHeight}
      height={stretch ? "100%" : height}
      alignItems="center"
      justifyContent="center"
      width="100%"
      borderRadius={4}
      flexGrow={stretch ? 1 : 0}
    >
      <Spinner
        thickness="4px"
        speed="0.75s"
        emptyColor={useColorModeValue("gray.100", "gray.500")}
        color={useColorModeValue("gray.500", "gray.100")}
        size="xl"
        label={label || undefined}
      />
      {label && (
        <Text
          ml={4}
          display="block"
          textAlign="center"
          fontWeight="bold"
          fontSize="2xl"
        >
          {label}
        </Text>
      )}
    </Flex>
  );
};

export { Loader };
