import { Box, HStack, Text, useColorModeValue, VStack } from "@chakra-ui/react";
import { PropsWithChildren } from "react";
import { useComponentColors } from "theme";

const UploadedFiles = ({ children }: PropsWithChildren) => {
  const componentColors = useComponentColors();

  return (
    <>
      <Box h="100%" w="100%" alignItems="center" mb={2}>
        <HStack
          alignItems="center"
          mb={1}
          pb={1}
          w="100%"
          fontSize="sm"
          textTransform="uppercase"
          borderBottom="1px solid"
          borderColor={useColorModeValue("gray.100", "gray.700")}
        >
          <Text w="280px" color={componentColors.form.formLabelColor}>
            FILE NAME
          </Text>

          <Text w="200px" color={componentColors.form.formLabelColor}>
            DOMAIN COUNT
          </Text>
        </HStack>

        <VStack spacing={2} alignItems="flex-start">
          {children}
        </VStack>
      </Box>
    </>
  );
};

export { UploadedFiles };
