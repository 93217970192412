import { Box, Flex, useColorModeValue } from "@chakra-ui/react";
import { WithChildren } from "types";

const AuthFormContainer = ({ children }: WithChildren) => {
  return (
    <Flex
      minHeight="100vh"
      width="full"
      align="center"
      justifyContent="center"
      bg={useColorModeValue("brand.300", "brand.300")}
    >
      <Box
        pt={12}
        pb={16}
        px={16}
        width="full"
        maxWidth="450px"
        borderRadius={4}
        textAlign="center"
        boxShadow="2xl"
        bg={useColorModeValue("brand.400", "brand.400")}
      >
        {children}
      </Box>
    </Flex>
  );
};

export { AuthFormContainer };
