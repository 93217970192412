import { useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { Modal } from "components";

import { ModalBody } from "./ModalBody";

import { RequestSpotlightDeckDTO } from "@intentsify/dto";
import { requestSpotlightDeck } from "api";
import { DateTime } from "luxon";
import { atomFamily, useRecoilState } from "recoil";
import { useUser } from "store/store.hooks";

export const requestSpotlightReportModalAtom = atomFamily<
  {
    campaignId: number | undefined;
  },
  number
>({
  key: "requestSpotlightReportModal",
  default: {
    campaignId: undefined,
  },
});

const Wrapper = ({ campaignId }: { campaignId: number }) => {
  if (!campaignId) {
    return null;
  }

  return <RequestDeckModal campaignId={campaignId} />;
};

const RequestDeckModal = ({ campaignId }: { campaignId: number }) => {
  const toast = useToast();

  const [{ campaignId: isRequesting }, setRequestSpotlightReportModal] =
    useRecoilState(requestSpotlightReportModalAtom(campaignId));

  const user = useUser();

  const {
    mutate: requestSpotlightDeckMutation,
    isLoading: isLoadingSpotlightDeckMutation,
  } = useMutation<unknown, unknown, RequestSpotlightDeckDTO>(
    requestSpotlightDeck,
    {
      onSuccess: () => {
        setRequestSpotlightReportModal({ campaignId: undefined });
        toast({
          title: `Your request for the spotlight report has been received. You will be notified once it's ready.`,
          status: "success",
        });
      },
    }
  );

  return (
    <Modal
      overflow="visible"
      onClose={() => setRequestSpotlightReportModal({ campaignId: undefined })}
      size="2xl"
      isOpen={Boolean(isRequesting)}
      title="Spotlight Report"
      subtitle={`Requesting a Spotlight report for campaign: ${campaignId}. Please specify the week you wish the report to cover and select up to 10 accounts.`}
      body={
        <ModalBody
          campaignId={campaignId}
          isSubmitting={isLoadingSpotlightDeckMutation}
          onSubmit={({ accounts, selectedWeek }) => {
            if (user) {
              requestSpotlightDeckMutation({
                campaignId,
                domainIds: accounts,
                userId: user.userId,
                weekNumber: DateTime.fromISO(selectedWeek).weekNumber,
                yearNumber: DateTime.fromISO(selectedWeek).weekYear,
              });
            }
          }}
        />
      }
    />
  );
};

export { Wrapper as RequestSpotlightReportModal };
