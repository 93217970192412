import { Box } from "@chakra-ui/react";
import { NodeValue, OnCheckNode } from "@intentsify/types";
import { SelectedTags } from "../../SelectedTags";
import { SelectedTagsHeader } from "../../SelectedTagsHeader";
import { NodeModel } from "../Node.model";

type SelectedProps = {
  model: NodeModel;
  checked: NodeValue[];
  subject: string;
  onNodeCheck: (node: OnCheckNode) => void;
  onReset?: () => void;
};

const Selected = ({
  model,
  checked,
  subject,
  onNodeCheck,
  onReset,
}: SelectedProps) => {
  const selected: OnCheckNode[] = model
    .getAllNodes()
    .map(({ label, value, checked }) => ({
      label,
      value,
      checked,
    }))
    .filter((i) => {
      return checked.includes(i.value);
    });

  return (
    <Box maxW="100%" data-testId={`selected-${subject}`}>
      <SelectedTagsHeader
        title={`Selected ${subject}`}
        items={selected}
        onReset={onReset}
      />

      <SelectedTags
        items={selected}
        subject={subject}
        onRemoveByItem={(item) => {
          const node = model.getNode(item.value);
          onNodeCheck({ ...node, checked: !node.checked });
        }}
      />
    </Box>
  );
};

export { Selected };
