import { Search } from "components";
import { useState } from "react";
import { useTrackSearched } from "tracking/useTrackSearched";

export function useSearch() {
  const [searchValue, setSearchValue] = useState("");
  const trackSearched = useTrackSearched();

  return {
    value: searchValue,
    Input: () => (
      <Search
        mb={0}
        currentValue={searchValue}
        onSearch={(value: string) => {
          setSearchValue(value);
          trackSearched({ term: value, collocation: "Target personas table" });
        }}
      />
    ),
  };
}
