import { Box, Divider, Flex } from "@chakra-ui/layout";
import { Heading } from "@chakra-ui/react";
import { TempAccountFileDTO } from "@intentsify/dto";
import { GenericUserJobType } from "@intentsify/types";
import { CompanyNameColumnTemplate, isPopulatedArray } from "@intentsify/utils";
import { Endpoints } from "api";
import { useState } from "react";
import { isTempAccountFileDTO } from "screens/Campaigns/screens/CampaignsWizard/components/DomainsStep/DomainsForm";
import { CsvUploadTus } from "shared/components/CsvUploadTus/CsvUploadTus";
import { UploadedFileEntry } from "shared/components/UploadedFiles/UploadedFileEntry";
import { UploadedFiles } from "shared/components/UploadedFiles/UploadedFiles";
import { UploadedFilesWrapper } from "shared/components/UploadedFiles/UploadedFilesWrapper";
import { talToolsUpload } from "upload/talToolsUpload";
import { useUploadedFiles } from "../../hooks/useUploadedFiles";
import { ResultsTable } from "../ResultsTable/ResultsTable";
import { CompanyNameToDomainForm } from "./components/CompanyNameToDomainForm";

export const CompanyNameToDomain = () => {
  const [selectedFile, setSelectedFile] = useState<
    TempAccountFileDTO | undefined
  >();

  const { files, filesForList, setFiles } = useUploadedFiles();

  return (
    <Flex direction="column" gap="4">
      <Box>
        <Heading as="h2" size="sm" my="4">
          Instructions
        </Heading>
        <Box as="ol" fontSize="xs" ml="8">
          <li>
            Upload a CSV file with one column containing company names and a
            header row with a single value "Company Name".
          </li>
          <li>Click "Process Company Names".</li>
          <li>
            You'll receive an email when the list has been processed containing
            the link to download.
          </li>
        </Box>
      </Box>

      <Box mt={4}>
        <CsvUploadTus
          dropzoneSubHeader={
            'Please upload a CSV file of up to 200MB in size, containing a "Company Name" column.'
          }
          validationEndpoint={
            Endpoints.TalTools.CompanyNameToDomain.All.Validate
          }
          columnsTemplate={CompanyNameColumnTemplate}
          tusUpload={talToolsUpload}
          uploadedFileNames={files.map((i) => i.fileName)}
          onFileUpload={(tempFileName, fileName, validationResults) => {
            const newFile = {
              tempFileName,
              fileName,
              accountsCount: validationResults.validRowCount,
            };

            setFiles((prev) => {
              return [
                ...prev.filter((file) => file.fileName !== fileName),
                newFile,
              ];
            });

            setSelectedFile(newFile);
          }}
        />

        {isPopulatedArray(filesForList) && (
          <UploadedFilesWrapper variant="plain">
            <UploadedFiles hideUploadedAt selectable>
              {filesForList.map((uploadedFileEntryProps) => (
                <UploadedFileEntry
                  isSelected={
                    uploadedFileEntryProps.file.fileName ===
                    selectedFile?.fileName
                  }
                  isSelectable
                  onSelect={(file) => {
                    if (file && isTempAccountFileDTO(file)) {
                      setSelectedFile(file);
                    } else {
                      setSelectedFile(undefined);
                    }
                  }}
                  hideDownload
                  hideUploadedAt
                  key={uploadedFileEntryProps.file.fileName}
                  file={uploadedFileEntryProps.file}
                />
              ))}
            </UploadedFiles>
          </UploadedFilesWrapper>
        )}
      </Box>

      <CompanyNameToDomainForm selectedFile={selectedFile} />

      <Divider />

      <ResultsTable toolType={GenericUserJobType.COMPANY_NAME_TO_DOMAIN} />
    </Flex>
  );
};
