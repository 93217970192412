import { Divider, Flex, Heading, Stack } from "@chakra-ui/react";
import { ContactSearchExportDTO } from "@intentsify/dto";
import { ContactSearchFilters } from "@intentsify/types";
import {
  getExportFileName,
  isPopulatedArray,
  pluralize,
} from "@intentsify/utils";
import { useQuery } from "@tanstack/react-query";
import { Endpoints } from "api";
import { Button, Table } from "components";
import { PaginationType } from "components/Table/Table/Table.types";
import { useState } from "react";
import { AiOutlineDownload } from "react-icons/ai";
import { useDownloadFile } from "utils";
import { requestContactSearchResults } from "../../ContactSearch.requests";
import { useColumns } from "./useColumns";

export const REQUEST_SEARCH_RESULTS_QUERY_KEY = "requestContactSearchResults";

const ContactSearchTable = ({
  filters,
}: {
  filters: ContactSearchFilters | undefined;
}) => {
  const columns = useColumns();
  const [pagination, setPagination] = useState({ pageSize: 10, page: 1 });
  const { download } = useDownloadFile();

  const { data, isFetching } = useQuery({
    queryKey: [REQUEST_SEARCH_RESULTS_QUERY_KEY, filters, pagination],
    queryFn: () => {
      if (!filters) {
        return Promise.resolve(null);
      }

      return requestContactSearchResults({ ...filters, ...pagination });
    },
    enabled: !!filters,
  });

  const isValid = isPopulatedArray(filters?.domain);

  return (
    <>
      {data && (
        <Stack gap={4}>
          <Divider />

          <Flex justifyContent="space-between" alignItems="center">
            <Heading as="h2" size="sm" noOfLines={1}>
              Results: found {data.total}{" "}
              {pluralize(data.results.length, "contact", "contacts")}
            </Heading>

            <Button
              size="sm"
              leftIcon={<AiOutlineDownload />}
              ml={2}
              isDisabled={!isValid}
              onClick={() => {
                if (!filters) {
                  return;
                }

                const data = filters satisfies ContactSearchExportDTO;

                void download({
                  url: Endpoints.ContactSearch.Post.Export,
                  data,
                  fileName: getExportFileName({
                    prefix: "contact_search",
                    extension: ".zip",
                  }),
                  method: "POST",
                });
              }}
              variant="secondary"
            >
              Export
            </Button>
          </Flex>

          <Table
            isFetching={isFetching}
            columns={columns}
            variant="intentsifyNew"
            data={data.results}
            onFetchDataChange={({ per_page, page }) => {
              setPagination({ page, pageSize: per_page });
            }}
            stretch
            isControlledByAPI
            total={data.total}
            defaultPageSize={pagination.pageSize}
            resetPaginationDependencies={[filters]}
            paginationType={PaginationType.LIMIT_OFFSET}
          />
        </Stack>
      )}
    </>
  );
};

export { ContactSearchTable };
