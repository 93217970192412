import { useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ViewContainer } from "components";
import { useState } from "react";
import { useNavigate } from "react-router";
import { useScreen } from "utils";
import { AgencyFormData, BusinessEntityForm } from "../../components";
import { BusinessEntitiesScreenDefinition } from "../BusinessEntities";
import { CreateBusinessEntityScreenDefinition } from "./CreateBusinessEntity.definition";
import { createBusinessEntity } from "./CreateBusinessEntity.requests";

const CreateBusinessEntity = () => {
  useScreen(CreateBusinessEntityScreenDefinition);

  // TODO: we should depend on react-hook-form for this
  // for the best DX and performance
  // https://react-hook-form.com/advanced-usage#FormProvider
  const [formData, setFormData] = useState<AgencyFormData>({
    displayName: undefined,
    companyIds: [],
  });

  const toast = useToast();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation(createBusinessEntity, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["companiesList"] });
      toast({
        title: "Business Entity changes saved.",
        status: "success",
      });

      navigate(BusinessEntitiesScreenDefinition.navigate());
    },
  });

  const onSubmit = (data: AgencyFormData) =>
    data.displayName && mutate(data as Required<AgencyFormData>);

  return (
    <ViewContainer>
      <BusinessEntityForm
        isSubmitting={isLoading}
        data={formData}
        onChange={setFormData}
        onSubmit={onSubmit}
        submitButtonLabel="Create Business Entity"
      />
    </ViewContainer>
  );
};

export { CreateBusinessEntity };
