import { useEffect } from "react";
import { useLocation } from "react-router";
import { useAppStore } from "store/useAppStore";
import { registerCustomTag } from "./clarity";

export const useRegisterClarityMetadata = () => {
  const { pathname } = useLocation();
  const user = useAppStore.use.user();

  useEffect(() => {
    if (user) {
      registerCustomTag("userId-intentsify", String(user.userId));
      registerCustomTag("companyId", String(user.companyId));
      registerCustomTag("agencyId", String(user.agencyId));
    }
  }, [user, pathname]);
};
