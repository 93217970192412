import { useColorModeValue } from "@chakra-ui/color-mode";
import {
  Box,
  Center,
  CloseButton,
  Divider,
  Icon,
  Portal,
  Spinner,
  Tag,
  TagCloseButton,
  TagLabel,
  useColorMode,
  useFormControlContext,
  useStyles,
  useTheme,
} from "@chakra-ui/react";
import { Option } from "@intentsify/types";
import omit from "lodash/omit";
import { FiChevronDown } from "react-icons/fi";
import ReactSelect, {
  ActionMeta,
  MultiValueGenericProps,
  Props,
} from "react-select";
import CreatableSelect from "react-select/creatable";
import { Control } from "./Control";
import { ListItem } from "./ListItem";
import { MenuList } from "./MenuList";

type SelectProps<
  V extends string | number,
  O extends Option<V>,
  M extends boolean = false
> = {
  options: O[];
  value: Props<O, M>["value"];
  onChange: (
    value: M extends true ? O[] : O | null,
    action: ActionMeta<O>
  ) => void;
  isMulti: M;
  onFetchDataChange?: () => void;
  totalPages?: number;
  currentPage?: number;
  isClearable?: Props<O, M>["isClearable"];
  isLoading?: Props<O, M>["isLoading"];
  isSearchable?: Props<O, M>["isSearchable"];
  isDisabled?: Props<O, M>["isDisabled"];
  isCreatable?: boolean;
  onInputChange?: Props<O, M>["onInputChange"];
  filterOption?: Props<O, M>["filterOption"];
  placeholder?: Props<O, M>["placeholder"];
  onMenuClose?: Props<O, M>["onMenuClose"];
  placeholderFontWeight?: number | string;
  inputValue?: Props<O, M>["inputValue"];
  testId?: string;
  size?: "sm" | "md";
  onBlur?: () => void;
};

const Select = <
  V extends string | number,
  O extends Option<V>,
  M extends boolean
>({
  options,
  value,
  onChange,
  isMulti,
  onInputChange,
  filterOption,
  placeholder,
  onMenuClose,
  onFetchDataChange,
  totalPages,
  currentPage,
  testId,
  isClearable = true,
  isSearchable = true,
  isLoading = false,
  isDisabled = false,
  isCreatable,
  placeholderFontWeight = "normal",
  inputValue = undefined,
  size = "sm",
  onBlur,
}: SelectProps<V, O, M>) => {
  const chakraTheme = useTheme();
  const field = useFormControlContext();

  const inputTextColor = useColorModeValue("gray.800", "whiteAlpha.900");

  const { colorMode } = useColorMode();

  const Component = isCreatable ? CreatableSelect : ReactSelect;

  return (
    <Component
      inputValue={inputValue}
      inputId={field?.id}
      aria-invalid={field?.isInvalid}
      onInputChange={onInputChange}
      // @ts-ignore
      filterOption={filterOption}
      placeholder={placeholder}
      value={value}
      isClearable={isClearable}
      isLoading={isLoading}
      isSearchable={isSearchable}
      isMulti={isMulti ?? false}
      isDisabled={isDisabled}
      options={options}
      onMenuClose={onMenuClose}
      // @ts-ignore
      onFetchDataChange={onFetchDataChange}
      totalPages={totalPages}
      currentPage={currentPage}
      styles={{
        placeholder: (base) => {
          return {
            ...base,
            color:
              colorMode === "dark"
                ? "rgba(255,255,255,0.24)"
                : chakraTheme.colors.gray[500],
            fontSize: chakraTheme.fontSizes.sm,
            fontWeight: placeholderFontWeight,
            whiteSpace: "nowrap",
          };
        },
        menu: (base) => {
          return {
            ...base,
            backgroundColor: "transparent",
            boxShadow: "none",
            zIndex: 3,
          };
        },
        input: (base) => {
          return {
            ...base,
            paddingTop: 0,
            paddingBottom: 0,
            color: inputTextColor,
          };
        },
        valueContainer: (base) => {
          return {
            ...base,
            padding: "0 0.7rem",
            minHeight: size === "sm" ? "1.9rem" : "2.5rem",
            maxHeight: "400px",
            overflow: "auto",
          };
        },
        singleValue: (base) => {
          return {
            ...base,
            color:
              colorMode === "dark"
                ? chakraTheme.colors.gray[500]
                : chakraTheme.colors.gray[400],
            fontSize: chakraTheme.fontSizes.sm,
          };
        },
      }}
      components={{
        MenuList: (props) => {
          // @ts-ignore
          return <MenuList {...props} testId={testId} />;
        },
        Option: (props: MultiValueGenericProps<O>) => (
          // TODO
          // @ts-ignore
          <ListItem {...props} colorMode={colorMode} />
        ),
        Control,
        MultiValueContainer: ({ children }: MultiValueGenericProps<O>) => (
          <Tag height="22px" size="sm" m="2px">
            {children}
          </Tag>
        ),
        MultiValueLabel: ({ children }) => <TagLabel>{children}</TagLabel>,
        MultiValueRemove: ({ children, innerProps }) => {
          const { ref, ...restInnerProps } = innerProps;

          return (
            <Box ref={ref} {...omit(restInnerProps, "className", "css")}>
              {/* css exists on innerProps */}
              {/* @ts-ignore */}
              <TagCloseButton css={innerProps.css}>{children}</TagCloseButton>
            </Box>
          );
        },
        IndicatorSeparator: () => (
          <Divider orientation="vertical" opacity="0.2" />
        ),
        ClearIndicator: ({ innerProps }) => {
          const { ref, ...restInnerProps } = innerProps;

          return (
            <Box ref={ref} {...restInnerProps}>
              <CloseButton size="sm" mx={2} />
            </Box>
          );
        },
        DropdownIndicator: ({ innerProps }) => {
          const { addon } = useStyles();

          return (
            <Center
              {...innerProps}
              sx={{
                ...addon,
                h: "100%",
                borderRadius: 0,
                borderWidth: 0,
                background: "none",
                cursor: "pointer",
              }}
            >
              <Icon
                color={useColorModeValue("gray.700", "gray.200")}
                as={FiChevronDown}
                h={5}
                w={5}
              />
            </Center>
          );
        },
        MenuPortal: ({ children }) => <Portal>{children}</Portal>,
        GroupHeading: ({ children }) => {
          const { groupTitle } = useStyles();
          return <Box sx={groupTitle}>{children}</Box>;
        },

        LoadingIndicator: () => {
          const color = useColorModeValue("gray.500", "gray.100");

          return <Spinner mr={2} color={color} size="sm" />;
        },
      }}
      onChange={(newValue, action) => {
        /**
         * Types here are overwritten intentionally. react-select wraps the values in a custom type
         * `OnChangeValue<Option, M>` which brings no benefits, it only makes things more complex. We had the
         * same issue before where we had to use react-select custom types like `OptionsType<Option>` or `ValueType`.
         * It's better to just use `Option` everywhere and remove tight coupling with `react-select`.
         */
        if (Array.isArray(newValue)) {
          // @ts-ignore
          onChange(newValue as unknown as O[], action);
        }
        // @ts-ignore
        onChange(newValue as O | null, action);
      }}
      onBlur={onBlur}
    />
  );
};

export { Select };
