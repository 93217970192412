import { getExportFileName, isPopulatedArray } from "@intentsify/utils";
import { useQuery } from "@tanstack/react-query";
import { DataToCsvDownload, ViewContainer } from "components";
import { useMemo } from "react";
import { DisplayPerformanceFilters } from "screens/CampaignPacing/screens/DisplayPerformance";
import { getTopAccounts } from "../../AccountsPerformance.requests";
import { useDataForExport } from "./TopAccounts.utils";
import { TopAccountsTable } from "./TopAccountsTable";

type TopAccountsProps = {
  campaignName: string;
  campaignId: string | number;
  filters: DisplayPerformanceFilters;
};

const TopAccounts = ({
  campaignName,
  campaignId,
  filters,
}: TopAccountsProps) => {
  const { data, isFetching: isLoading } = useQuery(
    ["getTopAccounts", campaignId, filters],
    () => getTopAccounts(campaignId, filters)
  );

  const { hasVisits, hasConversions } =
    useMemo(() => {
      if (!isPopulatedArray(data?.topAccounts)) {
        return {
          hasVisits: false,
          hasConversions: false,
        };
      }

      return {
        hasVisits:
          data?.topAccounts.some((i) => Number(i.totalSiteVisits) > 0) ?? false,
        hasConversions:
          data?.topAccounts.some((i) => Number(i.conversions) > 0) ?? false,
      };
    }, [data?.topAccounts]) ?? false;

  const exportFileName = getExportFileName({
    prefix: `top_25_accounts_${campaignName}`,
    data: { id: campaignId },
    extension: ".csv",
  });

  const dataForExport = useDataForExport(
    data?.topAccounts ?? [],
    hasVisits,
    hasConversions
  );

  return (
    <ViewContainer
      withBackground
      title={"Top 25 Accounts"}
      actionItems={
        <DataToCsvDownload
          fileName={exportFileName}
          data={dataForExport}
          downloadLabel="Top 25 Accounts"
          iconSize={"xs"}
          withLabel
          subject="account"
          subjectPlural="accounts"
          tooltipPlacement="bottom-end"
        />
      }
    >
      <TopAccountsTable
        data={data}
        isLoading={isLoading}
        hasVisits={hasVisits}
        hasConversions={hasConversions}
      />
    </ViewContainer>
  );
};

export { TopAccounts };
