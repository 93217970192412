import {
  Text as ChakraText,
  Flex,
  Table,
  Tbody,
  Td,
  Tr,
} from "@chakra-ui/react";
import { formatDateRange, isPopulatedArray } from "@intentsify/utils";
import { useRecoilValue } from "recoil";
import { CellWithChange } from "../../../../CellWithChange";
import { hoveredNearestPointAtomFamily } from "../../../Timeseries/Timeseries.state";
import { TimeseriesTooltip } from "./TimeseriesTooltip";
import { TimeseriesTooltipProps } from "./TimeseriesTooltip.types";

const AccountChangeTooltip = <
  K extends string,
  DK extends "accounts" | "value"
>({
  tooltipData,
  dataMainKey,
  dataKeyLabels,
  timeseries,
  data,
  textColor,
  uniqueKey,
}: TimeseriesTooltipProps<K, DK>) => {
  const hoveredNearestPoint = useRecoilValue(
    hoveredNearestPointAtomFamily(uniqueKey)
  );

  if (!hoveredNearestPoint) {
    return (
      <TimeseriesTooltip
        dataKeyLabels={dataKeyLabels}
        tooltipData={tooltipData}
        dataMainKey={dataMainKey}
        timeseries={timeseries}
        textColor={textColor}
        data={data}
        uniqueKey={uniqueKey}
      />
    );
  }

  const currentDateData = hoveredNearestPoint?.datum.timeseries.find(
    (s) => s.isoDate === hoveredNearestPoint.isoDate
  );

  if (!currentDateData) {
    return null;
  }

  const index = hoveredNearestPoint.datum.timeseries.indexOf(currentDateData);
  const previousDateData = hoveredNearestPoint.datum.timeseries[index - 1];
  const accounts = currentDateData.data.accounts;
  const previousAccounts = previousDateData?.data.accounts;
  const label = formatDateRange(timeseries[index]);

  if (!isPopulatedArray(accounts)) {
    return (
      <ChakraText
        fontSize="medium"
        pl={1}
        mt={2}
        mb={3}
        fontWeight="bold"
        color={textColor}
      >
        No accounts data available
      </ChakraText>
    );
  }

  return (
    <>
      <ChakraText
        fontSize="medium"
        pl={1}
        mt={2}
        mb={3}
        fontWeight="bold"
        color={textColor}
      >
        {tooltipData[dataMainKey]} - {label}
      </ChakraText>

      <Table size="xs" variant="simple" textColor={textColor}>
        <Tbody>
          {accounts.slice(0, 10).map(({ account, count }, i) => {
            const accountFromPreviousWeek = isPopulatedArray(previousAccounts)
              ? previousAccounts?.find((a) => a.account === account)
              : undefined;

            return (
              <Tr key={i} height="25px">
                <Td p={1} pr={3} border="none">
                  <Flex alignItems={"center"}>{account}</Flex>
                </Td>

                <Td p={1} pr={3} border="none">
                  <Flex alignItems={"center"}>
                    <CellWithChange
                      value={count}
                      previousValue={accountFromPreviousWeek?.count}
                    />
                  </Flex>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </>
  );
};

export { AccountChangeTooltip };
