import { paramsToQueryUrl } from "./paramsToQueryUrl";
import { BaseFilter, FetchDataParams } from "types";

export const getPaginatedQuery = <
  T extends Record<string, unknown>,
  F extends BaseFilter<F> = undefined
>(
  resource: string,
  params: FetchDataParams<keyof T, F>
) => {
  return `${resource}?${paramsToQueryUrl({
    params,
    includeFirstAmpersand: false,
  })}`;
};
