import {
  Box,
  chakra,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  GridItem,
  Input,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  BusinessUnit,
  Option,
  SaasRole,
  SaasRoleId,
  SaasRoleName,
} from "@intentsify/types";
import { isPopulatedArray } from "@intentsify/utils";
import { useQuery } from "@tanstack/react-query";
import {
  getBusinessEntityById,
  listCompanyBusinessUnits,
  listRoles,
} from "api";
import { Button, CompanySelector, Filter, Select } from "components";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useUser } from "store/store.hooks";
import { useComponentColors } from "theme";
import { useDeepEffect } from "utils";
import { z } from "zod";
import { BussinesEntitySelector } from "./BusinessEntitySelector";

const validationSchema = z
  .object({
    displayName: z.string().min(3),
    email: z.string().email(),
    saasRoleId: z.number(),
    companyId: z.number().optional(),
    businessEntityId: z.number().optional(),
    isSalesRepresentative: z.boolean(),
    isCampaignManager: z.boolean(),
  })
  .refine(
    (data) => {
      if (data.businessEntityId || data.companyId) {
        return true;
      }

      return data.businessEntityId && !data.companyId;
    },
    {
      message: "Select company",
      path: ["companyId"],
    }
  )
  .refine(
    (data) => {
      if (data.businessEntityId || data.companyId) {
        return true;
      }

      return !data.businessEntityId && data.companyId;
    },
    {
      message: "Select business entity",
      path: ["businessEntityId"],
    }
  )
  .refine(
    (data) => {
      if (
        !data.email.includes("@intentsify.io") &&
        !data.email.includes("@5x5coop.com") &&
        SaasRoleId.Admin === data.saasRoleId
      ) {
        return false;
      }

      return true;
    },
    {
      message:
        "Only users with intentsify.io and 5x5coop.com emails are allowed the admin role.",
      path: ["saasRoleId"],
    }
  );

export type UserFormState = z.infer<typeof validationSchema> & {
  businessUnits: BusinessUnit[];
};

type UserFormProps = {
  initialState?: UserFormState;
  onSubmit: (user: UserFormState) => void;
  isLoading: boolean;
  submitLabel: "Create" | "Save Changes" | "Clone";
};

const undefinedIfNull = (val: number | undefined) =>
  val === 0 ? undefined : val;

const UserForm = ({
  initialState,
  isLoading,
  onSubmit,
  submitLabel,
}: UserFormProps) => {
  const user = useUser();
  const isPermissionsAdmin = user?.saasRole === SaasRoleName.AccessAdmin;
  const orSeperatorColor = useColorModeValue("gray.700", "brand.100");

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
  } = useForm<UserFormState>({
    mode: "all",
    resolver: zodResolver(validationSchema),
    defaultValues: initialState
      ? {
          displayName: initialState.displayName,
          companyId: undefinedIfNull(initialState.companyId),
          saasRoleId: initialState.saasRoleId,
          email: initialState.email,
          businessEntityId: undefinedIfNull(initialState.businessEntityId),
          isSalesRepresentative: initialState.isSalesRepresentative,
          isCampaignManager: initialState.isCampaignManager,
        }
      : undefined,
  });
  const componentColors = useComponentColors();

  const companyId = watch("companyId");
  const businessEntityId = watch("businessEntityId");

  const { data: businessEntity } = useQuery(
    [businessEntityId],
    () => getBusinessEntityById(Number(businessEntityId)),
    {
      enabled: Boolean(businessEntityId),
    }
  );

  const [roles, setRoles] = useState<SaasRole[]>([]);
  const [businessUnits, setBusinessUnits] = useState<Option[]>([]);
  const [selectedBusinessUnits, setSelectedBusinessUnits] = useState<Option[]>(
    []
  );

  const { data: dataRoles, isSuccess: isSuccessRoles } = useQuery(
    ["roles"],
    listRoles
  );

  useEffect(() => {
    setSelectedBusinessUnits([]);
  }, [companyId]);

  const {
    data: dataCompanyBusinessUnits,
    isSuccess: isSuccessCompanyBusinessUnits,
    isLoading: isLoadingCompanyBusinessUnits,
  } = useQuery(
    ["companies", companyId],
    () => (companyId ? listCompanyBusinessUnits(companyId) : undefined),
    { enabled: Boolean(companyId) }
  );

  useDeepEffect(() => {
    const businessUnitsAsOptions: Option[] =
      initialState?.businessUnits?.map(({ businessUnitId, displayName }) => ({
        value: businessUnitId,
        label: displayName,
      })) ?? [];
    setSelectedBusinessUnits([...businessUnitsAsOptions]);
  }, [initialState?.businessUnits]);

  useDeepEffect(() => {
    const businessUnitsAsOptions: Option[] =
      dataCompanyBusinessUnits?.businessUnits?.map(
        ({ businessUnitId, displayName }) => ({
          value: businessUnitId,
          label: displayName,
        })
      ) ?? [];
    setBusinessUnits([...businessUnitsAsOptions]);
  }, [dataCompanyBusinessUnits?.businessUnits]);

  const roleOptions = useMemo(
    () =>
      roles.map((role) => ({ value: role.roleId, label: role.displayName })),
    [roles]
  );

  const onSubmitForm = (values: UserFormState) => {
    const withBusinessUnits: UserFormState = {
      ...values,
      businessUnits: selectedBusinessUnits?.map(
        ({ value, label }) =>
          ({
            displayName: label,
            businessUnitId: Number(value),
          } as BusinessUnit)
      ),
    };

    onSubmit(withBusinessUnits);
  };

  if (isSuccessRoles && typeof dataRoles !== "undefined" && !roles.length) {
    setRoles([...dataRoles]);
  }

  return (
    <SimpleGrid columns={1}>
      <chakra.form
        onSubmit={(data) => {
          handleSubmit(onSubmitForm)(data);
        }}
      >
        <FormControl
          as={GridItem}
          isInvalid={!!errors?.displayName?.message}
          isRequired
        >
          <FormLabel
            color={componentColors.form.formLabelColor}
            fontSize="sm"
            fontWeight="md"
          >
            Name
          </FormLabel>
          <Input
            {...register("displayName", { disabled: isPermissionsAdmin })}
            type="displayName"
            name="displayName"
            placeholder="Name"
            shadow="sm"
            size="sm"
            w={"300px"}
            rounded="md"
          />
          <FormErrorMessage>{errors?.displayName?.message}</FormErrorMessage>
        </FormControl>
        <FormControl
          isRequired
          w={"300px"}
          mt={4}
          as={GridItem}
          isInvalid={!!errors?.email?.message}
        >
          <FormLabel
            color={componentColors.form.formLabelColor}
            fontSize="sm"
            fontWeight="md"
          >
            Email
          </FormLabel>

          <Controller
            name="email"
            control={control}
            render={() => (
              <Input
                {...register("email")}
                type="email"
                placeholder="Email"
                shadow="sm"
                size="sm"
                rounded="md"
                w={"300px"}
              />
            )}
          />
          <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
        </FormControl>

        <Flex gap={4} alignItems="flex-start">
          <FormControl
            isInvalid={!!errors?.businessEntityId?.message}
            as={GridItem}
            w={"300px"}
            mt={4}
          >
            <FormLabel
              color={componentColors.form.formLabelColor}
              fontSize="sm"
              fontWeight="md"
            >
              Business Entity
            </FormLabel>

            <Controller
              key={businessEntityId} // This is needed to trigger a re-render when selection is cleared from parent
              name="businessEntityId"
              control={control}
              render={({ field: { onChange } }) => {
                return (
                  <BussinesEntitySelector
                    width="300px"
                    isClearable
                    selected={businessEntityId}
                    onChange={(c) => {
                      onChange(Number(c?.value));
                      setValue("companyId", undefined, {
                        shouldValidate: true,
                      });
                    }}
                  />
                );
              }}
            />
            <FormErrorMessage>
              {errors?.businessEntityId?.message}
            </FormErrorMessage>

            <FormHelperText fontSize="xs">
              {businessEntityId === businessEntity?.id && (
                <>
                  {" "}
                  Includes companies:{" "}
                  <b>
                    {businessEntity?.companies
                      .map((i) => i.displayName)
                      .join(", ")}
                  </b>
                </>
              )}
            </FormHelperText>
          </FormControl>

          <Flex w="50px" alignItems="center" justifyContent="center">
            <Flex mt={5} flexDir="column" alignItems="center">
              <Box h="15px" w="1px" bg={orSeperatorColor} />
              <Flex
                fontSize="small"
                h="30px"
                alignItems="center"
                color={orSeperatorColor}
              >
                OR
              </Flex>
              <Box h="15px" w="1px" bg={orSeperatorColor} />
            </Flex>
          </Flex>

          <FormControl
            isInvalid={!!errors?.companyId?.message}
            as={GridItem}
            w={"300px"}
            mt={4}
          >
            <FormLabel
              color={componentColors.form.formLabelColor}
              fontSize="sm"
              fontWeight="md"
            >
              Company
            </FormLabel>

            <Controller
              key={companyId} // This is needed to trigger a re-render when selection is cleared from parent
              name="companyId"
              control={control}
              render={({ field: { onChange } }) => (
                <CompanySelector
                  width="300px"
                  isClearable
                  hasCampaigns
                  selected={companyId}
                  onChange={(c) => {
                    onChange(Number(c?.value));
                    setValue("businessEntityId", undefined, {
                      shouldValidate: true,
                    });
                  }}
                />
              )}
            />
            <FormErrorMessage>{errors?.companyId?.message}</FormErrorMessage>
          </FormControl>

          <FormControl as={GridItem} w={"300px"} mt={4}>
            <FormLabel
              color={componentColors.form.formLabelColor}
              fontSize="sm"
              fontWeight="md"
            >
              Company Subsidiary
            </FormLabel>

            <Filter
              isDisabled={
                !companyId ||
                !isSuccessCompanyBusinessUnits ||
                !isPopulatedArray(businessUnits)
              }
              options={businessUnits}
              isLoading={isLoadingCompanyBusinessUnits && Boolean(companyId)}
              placeholder="Select a subsidiary"
              onFilterValuesChange={(values) => {
                setSelectedBusinessUnits([...values]);
              }}
              selected={selectedBusinessUnits}
            />
          </FormControl>
        </Flex>

        <FormControl
          isInvalid={!!errors?.saasRoleId?.message}
          as={GridItem}
          isRequired
          w={"300px"}
          mt={4}
        >
          <FormLabel
            color={componentColors.form.formLabelColor}
            fontSize="sm"
            fontWeight="md"
          >
            Role
          </FormLabel>
          <Controller
            name="saasRoleId"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Select
                isMulti={false}
                placeholder="Select role"
                options={roleOptions}
                value={roleOptions.find((c) => c.value === value)}
                onChange={(val: any) => onChange(val?.value)}
              />
            )}
          />
          <FormErrorMessage>{errors?.saasRoleId?.message}</FormErrorMessage>
        </FormControl>

        <FormControl
          isInvalid={!!errors?.isSalesRepresentative?.message}
          as={GridItem}
          w={"300px"}
          mt={4}
        >
          <Checkbox {...register("isSalesRepresentative")}>
            Sales Representative
          </Checkbox>
        </FormControl>

        <FormControl
          isInvalid={!!errors?.isCampaignManager?.message}
          as={GridItem}
          w={"300px"}
          mt={4}
        >
          <Checkbox {...register("isCampaignManager")}>
            Campaign Manager
          </Checkbox>
        </FormControl>

        <Divider mt={4} />

        <Flex mt={4} justifyContent="flex-end">
          <Button
            type="submit"
            size="md"
            variant="primary-teal"
            isLoading={isLoading}
            isDisabled={
              !!errors.displayName ||
              !!errors.email ||
              !!errors.companyId ||
              !!errors.saasRoleId
            }
          >
            {submitLabel}
          </Button>
        </Flex>
      </chakra.form>
    </SimpleGrid>
  );
};

export { UserForm };
