import { Flex } from "@chakra-ui/layout";
import { Text as ChakraText, useColorMode } from "@chakra-ui/react";
import { isDefined, isPopulatedArray } from "@intentsify/utils";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { localPoint } from "@visx/event";
import { Group } from "@visx/group";
import { ParentSize } from "@visx/responsive";
import { scaleBand, scaleLinear, scaleOrdinal } from "@visx/scale";
import { BarStack } from "@visx/shape";
import { Text } from "@visx/text";
import { useTooltip, useTooltipInPortal } from "@visx/tooltip";
import { ErrorBoundary } from "components";
import { ScaleBand, ScaleLinear } from "d3-scale";
import omit from "lodash/omit";
import { useRef } from "react";
import { animated, useSpring } from "react-spring";
import { useMeasure } from "react-use";
import { Item } from "types";
import { useChartColors } from "utils";
import { Loader } from "../../Loader";
import { NoData } from "../../NoData";
import { BasicTooltip } from "../BasicTooltip";
import { EXPORT_TITLE_HEIGHT, MIN_HEIGHT } from "../Charts.const";
import { formatTickValue, getWrapperDirection } from "../Charts.utils";
import { StackedGroupedBarChartItem } from "./StackedGroupedBarChart.types";
import { getChartColors } from "./StackedGroupedBarChart.utils";
import { Legend } from "./Legend";

export type StackedGroupedBarChartProps = {
  isLoading?: boolean;
  data: StackedGroupedBarChartItem[];
  margins?: { t: number; b: number; r: number; l: number };
  stretch?: boolean;
  showXAxis?: boolean;
  noDataMessage?: string;
  valueAxisLabel?: string;
  id?: string;
  title?: string;
  exportMode?: true;
  colorRange?: string[];
  minHeight?: string;
  limitedWidth?: boolean;
  legendPosition?: "bottom" | "right";
  legendConfig?: {
    domain: string[];
    range: string[];
  };
  maxYValue?: number;
  legendTitle?: string;
  hideGroupTicks?: boolean;
  showTitle?: boolean;
  hideBarsLabels?: boolean;
};

const MAX_AXIS_LEFT_TICKS = 10;

const StackedGroupedBarChart = ({
  isLoading,
  data,
  margins = {
    t: 20,
    b: 50,
    r: 10,
    l: 50,
  },
  stretch = false,
  showXAxis = true,
  noDataMessage,
  valueAxisLabel,
  id,
  exportMode,
  title,
  legendPosition,
  minHeight = MIN_HEIGHT,
  limitedWidth = false,
  legendConfig,
  maxYValue,
  legendTitle,
  hideGroupTicks = false,
  showTitle = false,
  hideBarsLabels = false,
}: StackedGroupedBarChartProps) => {
  const itemsScale = useRef<ScaleBand<string> | undefined>();
  const valueScale = useRef<ScaleLinear<number, number, never> | undefined>();
  const isTitleVisible = (exportMode || showTitle) && title;

  const [ref, { width: containerWidth }] = useMeasure<HTMLDivElement>();

  const { labelColor, axisColors } = useChartColors();
  const { colorMode } = useColorMode();

  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip<Item<{ additionalTooltipContent: string | undefined }>>();

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    detectBounds: true,
    scroll: true,
  });

  const [{ scale }] = useSpring(
    {
      from: { scale: 0 },
      to: { scale: 1 },
      reset: true,
    },
    [isLoading]
  );

  if (isLoading) {
    return <Loader minHeight={minHeight} stretch={stretch} />;
  }

  if (!isPopulatedArray(data)) {
    return (
      <NoData message={noDataMessage} height={minHeight} stretch={stretch} />
    );
  }

  const values = data
    .flatMap((i) => i.groupItems)
    .map((i) => i.data)
    .map((i) => {
      const vals = i.map((i) => i.value) as number[];

      return vals.reduce((a, b) => a + b, 0);
    });

  const handleMouseOver = (
    event: any,
    item: Item<{ additionalTooltipContent: string | undefined }>
  ) => {
    if (event.target) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const coords = localPoint(event.target.ownerSVGElement, event);
      if (coords) {
        showTooltip({
          tooltipLeft: coords.x,
          tooltipTop: coords.y,
          tooltipData: item,
        });
      }
    }
  };

  const legendPositionComputed = legendPosition
    ? legendPosition
    : containerWidth > 1200
    ? "right"
    : "bottom";

  return (
    <ErrorBoundary>
      <Flex ref={ref} width="100%" height="100%">
        <Flex
          flexDirection={getWrapperDirection(legendPositionComputed)}
          width="100%"
        >
          <Flex width="100%" minHeight={minHeight} flexGrow={stretch ? 1 : 0}>
            <ParentSize>
              {(parent) => {
                const { width, height } = parent;

                const baseYMax = height - margins.t - margins.b;
                const xMax = width - margins.l - margins.r;
                const yMax = !isTitleVisible
                  ? baseYMax
                  : baseYMax - EXPORT_TITLE_HEIGHT;

                itemsScale.current = scaleBand<string>({
                  range: [0, xMax],
                  round: true,
                  domain: data.map((i) => i.groupLabel),
                  paddingInner: 0,
                  paddingOuter: 0,
                });

                valueScale.current = scaleLinear<number>({
                  range: [0, yMax],
                  round: true,
                  domain: [maxYValue ? maxYValue : Math.max(...values), 0],
                });

                return (
                  <svg
                    width={width}
                    height={height}
                    ref={containerRef}
                    id={id}
                    style={{
                      fontFamily: "var(--chakra-fonts-body)",
                    }}
                  >
                    <rect
                      x={0}
                      y={0}
                      width={width}
                      height={height}
                      fill={"transparent"}
                    />

                    {isTitleVisible && (
                      <Group top={30} left={22}>
                        <Text
                          verticalAnchor="middle"
                          fontSize={18}
                          fontWeight="semibold"
                          fill={labelColor}
                        >
                          {title}
                        </Text>
                      </Group>
                    )}

                    <g
                      transform={`translate(${margins.l},${
                        !isTitleVisible
                          ? margins.t
                          : margins.t + EXPORT_TITLE_HEIGHT
                      })`}
                    >
                      <Group>
                        {data.map((d) => {
                          if (!valueScale.current || !itemsScale.current) {
                            return null;
                          }

                          const bandwidth = itemsScale.current.bandwidth();
                          const x = itemsScale.current(d.groupLabel) as number;

                          const subitemScale = scaleBand<string>({
                            range: [x, x + bandwidth],
                            round: true,
                            domain: d.groupItems.map((i) => i.label),
                            paddingInner: 0,
                            paddingOuter: 0.05,
                          });

                          const data = d.groupItems.map((i) => {
                            const data: {
                              label: string;
                              metaData?: {
                                additionalTooltipContent: string | undefined;
                              };
                              [key: string]: any;
                            } = {
                              label: i.label,
                              metaData: {
                                additionalTooltipContent: undefined,
                              },
                            };

                            i.data.forEach((i) => {
                              data[i.label] = i.value;
                              if (typeof i.metaData !== "undefined") {
                                data["metaData"] = i.metaData;
                              }
                            });

                            return data;
                          });

                          return data.map((item, index) => {
                            const keys = Object.keys(
                              omit(item, "label", "metaData")
                            ).reverse();

                            if (!valueScale.current || !itemsScale.current) {
                              return null;
                            }

                            const colorScale = scaleOrdinal<string, string>({
                              domain: keys,
                              range: getChartColors(colorMode, index),
                            });

                            return (
                              <>
                                <BarStack
                                  key={`${d.groupLabel}-${item.label}`}
                                  data={[item]}
                                  keys={keys}
                                  x={(d) => String(d.label)}
                                  xScale={subitemScale}
                                  yScale={valueScale.current}
                                  color={colorScale}
                                >
                                  {(barStacks) =>
                                    barStacks.map((barStack) =>
                                      barStack.bars.map((bar) => {
                                        return (
                                          <animated.rect
                                            key={`bar-stack-${barStack.index}-${bar.index}`}
                                            x={bar.x}
                                            y={scale.to(
                                              (s) =>
                                                s * bar.y + (yMax - yMax * s)
                                            )}
                                            height={scale.to(
                                              (s) => s * bar.height
                                            )}
                                            width={bar.width}
                                            fill={bar.color}
                                            onMouseMove={(e) => {
                                              const item = {
                                                label: bar.key,
                                                value: bar.bar.data[bar.key],
                                                metaData: bar.bar.data.metaData,
                                              };

                                              handleMouseOver(e, item);
                                            }}
                                            onMouseLeave={() => {
                                              hideTooltip();
                                            }}
                                          />
                                        );
                                      })
                                    )
                                  }
                                </BarStack>

                                {!hideBarsLabels && (
                                  <Text
                                    x={
                                      Number(subitemScale(String(item.label))) +
                                      subitemScale.bandwidth() / 2
                                    }
                                    y={yMax + 10}
                                    textAnchor="middle"
                                    verticalAnchor="start"
                                    fontSize={limitedWidth ? 8 : 10}
                                    fill={labelColor}
                                  >
                                    {item.label}
                                  </Text>
                                )}
                              </>
                            );
                          });
                        })}
                      </Group>
                      )
                      {valueAxisLabel && (
                        <Text
                          x={-yMax / 2}
                          y={-margins.l + 12}
                          textAnchor="middle"
                          verticalAnchor="end"
                          transform="rotate(-90)"
                          fontSize={14}
                          fill={labelColor}
                        >
                          {valueAxisLabel}
                        </Text>
                      )}
                      <AxisLeft
                        numTicks={Math.min(
                          data.length > 1 ? data.length : 2,
                          MAX_AXIS_LEFT_TICKS
                        )}
                        tickComponent={(props) => {
                          return (
                            <Text
                              {...omit(props, "formattedValue")}
                              x={props.x}
                            >
                              {props.formattedValue}
                            </Text>
                          );
                        }}
                        stroke={axisColors}
                        scale={valueScale.current}
                        hideTicks
                        tickFormat={(v) => formatTickValue(Number(v))}
                        tickLabelProps={() => ({
                          fill: labelColor,
                          fontSize: 12,
                          textAnchor: "end",
                          verticalAnchor: "middle",
                        })}
                      />
                      {showXAxis && (
                        <AxisBottom
                          stroke={axisColors}
                          tickStroke={axisColors}
                          top={yMax}
                          scale={itemsScale.current}
                          hideTicks={true}
                          tickLabelProps={() => ({
                            display: hideGroupTicks ? "none" : "auto",
                            fill: labelColor,
                            fontSize: 12,
                            textAnchor: "middle",
                            verticalAnchor: "start",
                            lineHeight: 14,
                          })}
                        />
                      )}
                    </g>

                    {tooltipOpen &&
                      tooltipData &&
                      isDefined(tooltipLeft) &&
                      isDefined(tooltipTop) && (
                        <BasicTooltip<
                          Item<{ additionalTooltipContent: string | undefined }>
                        >
                          tooltipRenderer={({ textColor, tooltipData }) => {
                            return (
                              <>
                                <ChakraText fontSize="medium" color={textColor}>
                                  <b>{tooltipData.label}:</b>{" "}
                                  {tooltipData.value}
                                </ChakraText>

                                {tooltipData?.metaData
                                  ?.additionalTooltipContent && (
                                  <ChakraText
                                    fontSize="medium"
                                    color={textColor}
                                  >
                                    {
                                      tooltipData.metaData
                                        .additionalTooltipContent
                                    }
                                  </ChakraText>
                                )}
                              </>
                            );
                          }}
                          TooltipInPortal={TooltipInPortal}
                          tooltipLeft={tooltipLeft}
                          tooltipTop={tooltipTop}
                          tooltipData={tooltipData}
                        />
                      )}
                  </svg>
                );
              }}
            </ParentSize>
          </Flex>

          {!exportMode && legendConfig && (
            <Flex
              flexDirection="column"
              width={legendPositionComputed === "bottom" ? "100%" : undefined}
              mt={legendPositionComputed === "bottom" ? 5 : undefined}
              ml={legendPositionComputed === "right" ? 5 : undefined}
            >
              <Legend
                legendPosition={legendPositionComputed}
                legendConfig={legendConfig}
                legendTitle={legendTitle}
              />
            </Flex>
          )}
        </Flex>
      </Flex>
    </ErrorBoundary>
  );
};

export { StackedGroupedBarChart };
