import { GetTopAccountsBySpendRO } from "@intentsify/dto";
import { Table } from "components";
import { getColumns } from "./TopAccountsBySpendTable.utils";

type TopAccountsBySpendTableProps = {
  data: GetTopAccountsBySpendRO | undefined;
  isLoading: boolean;
};

const TopAccountsBySpendTable = ({
  data,
  isLoading,
}: TopAccountsBySpendTableProps) => {
  return (
    <Table
      variant="intentsifyClean"
      hidePagination={true}
      isControlledByAPI={false}
      isFetching={isLoading}
      data={data?.topAccounts ?? []}
      columns={getColumns()}
      defaultPageSize={25}
    />
  );
};

export { TopAccountsBySpendTable };
