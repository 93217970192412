import { CampaignTopicsByLocationRO } from "@intentsify/dto";
import { CampaignTopicsByLocation } from "@intentsify/types";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

interface UseCampaignTopicsByLocationOptions<T> {
  id: string | number;
  filters: T;
  fetchData: (
    id: string | number,
    filters: T
  ) => Promise<CampaignTopicsByLocationRO>;
}

export type UseCampaignTopicsByLocationReturnType = {
  isFetching: boolean;
  data: CampaignTopicsByLocationRO;
  maxSignalsCount: number;
  geoJson: {
    type: "FeatureCollection";
    features: {
      type: "Feature";
      properties: { count: number };
      geometry: { type: "Point"; coordinates: number[] };
    }[];
  };
};

const campaignTopicsByLocationEntryToGeoJsonFeature = ({
  longitude,
  latitude,
  topic,
  count,
}: CampaignTopicsByLocation) => ({
  type: "Feature" as const,
  properties: { topic, count },
  geometry: {
    type: "Point" as const,
    coordinates: [longitude, latitude],
  },
});

export const useCampaignTopicsByLocation = <T>({
  id,
  filters,
  fetchData,
}: UseCampaignTopicsByLocationOptions<T>): UseCampaignTopicsByLocationReturnType => {
  const { data, isFetching } = useQuery(
    ["getTopicsByLocation", id, filters],
    () => fetchData(id, filters),
    { suspense: true }
  );

  const geoJson = useMemo(() => {
    const features = (data?.topicsByLocation || []).map(
      campaignTopicsByLocationEntryToGeoJsonFeature
    );

    return {
      type: "FeatureCollection" as const,
      features,
    };
  }, [data]);

  const maxSignalsCount = data?.highestSignalsCount || 100;

  return {
    isFetching,
    data: (data || []) as CampaignTopicsByLocationRO,
    geoJson,
    maxSignalsCount,
  };
};
