import { Flex, useColorModeValue } from "@chakra-ui/react";
import { isDefined } from "@intentsify/utils";
import { ReactNode, cloneElement, useEffect, useState } from "react";
import { MenuWithContentItem } from "types";
import { Button } from "../Button";
import { Loader } from "../Loader";

type MenuWithContentProps = {
  items: MenuWithContentItem[];
  currentItemIndex?: number;
  loaderComponent?: ReactNode;
  menuWidth?: number;
  stretch?: boolean;
  isLoading?: boolean;
};

const useContent = (
  items: MenuWithContentItem[],
  selectedItemIndex: number
) => {
  const activeItem = items[selectedItemIndex];

  if (!activeItem) {
    return undefined;
  }

  return cloneElement(activeItem.content, {
    nextItemLabel: activeItem.nextItemLabel,
  });
};

const MenuWithContent = ({
  items,
  currentItemIndex,
  loaderComponent,
  menuWidth = 200,
  stretch = false,
  isLoading = false,
}: MenuWithContentProps) => {
  const [selectedItem, setSelectedItem] = useState(0);
  const menuBg = useColorModeValue("gray.50", "brand.425");

  const selectedItemIndex = isDefined(currentItemIndex)
    ? currentItemIndex
    : selectedItem;

  const content = useContent(items, selectedItemIndex);

  /**
   * Handle a situation when accessing `items[currentItemIndex]` fails, it can happen when e.g.:
   * `currentItemIndex` is controlled by the URL. If so, default to the first item in the menu.
   */
  useEffect(() => {
    if (!items[selectedItemIndex]) {
      const firstItem = items[0];
      firstItem.onClick?.(0);
    }
  }, [selectedItemIndex, items]);

  const isLoadingComponent = loaderComponent ?? <Loader stretch />;

  return (
    <Flex h={stretch ? "100%" : "auto"}>
      <Flex
        flexDir="column"
        minW={menuWidth}
        w={menuWidth}
        background={menuBg}
        p={4}
        height="min-content"
        position="sticky"
        h="100%"
      >
        {items.map((item, index) => {
          return (
            <Button
              mb={1}
              variant="ghost"
              size="sm"
              justifyContent="start"
              isActive={index === selectedItemIndex}
              isDisabled={!item.onClick && index !== selectedItemIndex}
              pointerEvents={!item.onClick ? "none" : undefined}
              key={`${item.label}-${index}`}
              onClick={() => {
                if (item.onClick) {
                  item.onClick(index);
                }

                if (!isDefined(currentItemIndex)) {
                  setSelectedItem(index);
                }
              }}
            >
              {item.label}
            </Button>
          );
        })}
      </Flex>

      <Flex p={4} flexDir="column" w="100%" h={stretch ? "100%" : "auto"}>
        {isLoading ? isLoadingComponent : content}
      </Flex>
    </Flex>
  );
};

export { MenuWithContent };
