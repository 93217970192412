import {
  BaseTargetPersona,
  BuyingGroupContactsAccounts,
  CampaignBuyingGroup,
} from "@intentsify/types";
import isEqual from "lodash/isEqual";
import { useMemo } from "react";

export const haveCampaignsPersonasChanges = (
  oldBuyingGroup: CampaignBuyingGroup,
  selectedPersonas: BaseTargetPersona[],
  maxBuyerGroupSizePerAccount: number,
  buyingGroupContactsAccounts: BuyingGroupContactsAccounts
) => {
  const oldPersonasIds = oldBuyingGroup.selectedPersonas
    .map(({ id }) => id)
    .sort();
  const personaIds = selectedPersonas.map(({ id }) => id).sort();

  return (
    !isEqual(oldPersonasIds, personaIds) ||
    oldBuyingGroup.maxBuyerGroupSizePerAccount !==
      maxBuyerGroupSizePerAccount ||
    oldBuyingGroup.buyingGroupContactsAccounts !== buyingGroupContactsAccounts
  );
};

export const useWatchCampaignPersonas = (
  campaignBuyingGroup: CampaignBuyingGroup,
  selectedPersonas: BaseTargetPersona[],
  maxBuyerGroupSizePerAccount: number,
  buyingGroupContactsAccounts: BuyingGroupContactsAccounts
) => {
  return useMemo(() => {
    return haveCampaignsPersonasChanges(
      campaignBuyingGroup,
      selectedPersonas,
      maxBuyerGroupSizePerAccount,
      buyingGroupContactsAccounts
    );
  }, [
    campaignBuyingGroup,
    selectedPersonas,
    maxBuyerGroupSizePerAccount,
    buyingGroupContactsAccounts,
  ]);
};
