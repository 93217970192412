import { SimpleGrid } from "@chakra-ui/react";
import { ViewContainer } from "components";
import { useUserAgencyCompany } from "queries/companies/useUserAgencyCompany";
import { Suspense } from "react";
import { useScreen } from "utils";
import { CompanyLeadPacingScreenDefinition } from "./CompanyLeadPacing.definition";
import { FallbackSkeleton } from "./FallbackSkeleton";
import { ActiveCampaigns, PastCampaigns } from "./components";

const CompanyLeadPacingInner = () => {
  const userAgencyCompany = useUserAgencyCompany({ suspense: true });

  return (
    <SimpleGrid
      columns={2}
      spacing="2px"
      minChildWidth="800px"
      gridAutoRows="min-content"
    >
      <ViewContainer withBackground title="Active Campaigns">
        <ActiveCampaigns companyId={userAgencyCompany.data?.companyId} />
      </ViewContainer>

      <ViewContainer withBackground title="Past Campaigns">
        <PastCampaigns companyId={userAgencyCompany.data?.companyId} />
      </ViewContainer>
    </SimpleGrid>
  );
};

export const CompanyLeadPacing = () => {
  useScreen(CompanyLeadPacingScreenDefinition);

  return (
    <Suspense fallback={<FallbackSkeleton />}>
      <CompanyLeadPacingInner />
    </Suspense>
  );
};
