import { CloneUserScreen } from "types";
import { createScreen } from "utils/createScreen";

const CloneUserScreenDefinition = createScreen({
  name: CloneUserScreen,
  path: "/admin/users/clone/:id",
  routePath: ["/clone/:id"],
  accessPath: "user.clone",
});

export { CloneUserScreenDefinition };
