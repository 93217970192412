import { Box } from "@chakra-ui/react";
import { defaultStyles } from "@visx/tooltip";
import { TooltipInPortalProps } from "@visx/tooltip/lib/hooks/useTooltipInPortal";
import { FC, isValidElement, ReactElement, ReactNode } from "react";
import { useChartColors } from "utils";

type BasicTooltipPropsWithRenderer<T> = {
  tooltipRenderer: (props: { textColor: string; tooltipData: T }) => ReactNode;
};

type BasicTooltipProps<T> = {
  TooltipInPortal: FC<TooltipInPortalProps>;
  tooltipTop: number;
  tooltipLeft: number;
  tooltipData: T;
} & (T extends ReactElement
  ? NonNullable<unknown>
  : BasicTooltipPropsWithRenderer<T>);

const BasicTooltip = <T,>({
  TooltipInPortal,
  tooltipLeft,
  tooltipTop,
  tooltipData,
  ...props
}: BasicTooltipProps<T>) => {
  const { labelColor, tooltipBackground, tooltipBorderColor } =
    useChartColors();

  return (
    <Box p={1}>
      <TooltipInPortal
        key={Math.random()}
        top={tooltipTop}
        left={tooltipLeft}
        style={{
          ...defaultStyles,
          background: tooltipBackground,
          borderRadius: "var(--chakra-radii-md)",
          border: `1px solid ${String(tooltipBorderColor)}`,
          boxShadow: "var(--chakra-shadows-lg)",
          zIndex: 10000,
        }}
      >
        <Box p={1} lineHeight={1.4}>
          {isValidElement(tooltipData) ? (
            <Box color={labelColor}>{tooltipData}</Box>
          ) : (
            (
              props as unknown as BasicTooltipPropsWithRenderer<T>
            ).tooltipRenderer({
              textColor: labelColor,
              tooltipData,
            })
          )}
        </Box>
      </TooltipInPortal>
    </Box>
  );
};

export { BasicTooltip };
