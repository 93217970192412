import { Box, useBoolean } from "@chakra-ui/react";
import { WeeklySnapshotRangeDTO } from "@intentsify/dto";
import { Card, IconButton, Loader } from "components";
import { Suspense } from "react";
import { RiEarthLine } from "react-icons/ri";
import { ImpressionsAndTopicsMap } from "shared/components";
import { getImpressionsByLocation, getTopicsByLocation } from "./Map.requests";

type Props = {
  campaignId: number;
  filters: WeeklySnapshotRangeDTO;
};

const Map = ({ campaignId, filters }: Props) => {
  const [isGlobe, setIsGlobe] = useBoolean();

  return (
    <Card
      title="Display Delivery & Topic Signal by Location"
      actions={
        <IconButton
          size="xs"
          isActive={isGlobe}
          icon={<RiEarthLine />}
          tooltip={`Show ${isGlobe ? "map" : "globe"}`}
          onClick={setIsGlobe.toggle}
        />
      }
      ignoreTitleMargin
    >
      <Box w="100%" h="440">
        <Suspense fallback={<Loader minHeight="100%" />}>
          <ImpressionsAndTopicsMap
            id={campaignId}
            isGlobe={isGlobe}
            filters={filters}
            getImpressionsByLocation={getImpressionsByLocation}
            getTopicsByLocation={getTopicsByLocation}
            height={400}
          />
        </Suspense>
      </Box>
    </Card>
  );
};

export { Map };
