import { isPopulatedArray } from "@intentsify/utils";
import { useQuery } from "@tanstack/react-query";
import {
  Card,
  MIN_MODAL_CONTENT_HEIGHT,
  VideoCompletionRateChart,
} from "components";
import { useMemo, useState } from "react";
import { MdOutlineTvOff } from "react-icons/md";
import { DisplayPerformanceFilters } from "screens/CampaignPacing/screens/DisplayPerformance";
import { ChartExport, useExportableChart } from "shared/components";
import { getVideoCompletionRate } from "./VideoCompletionRate.requests";

type VideoCompletionRateProps = {
  campaignId: string;
  filters: DisplayPerformanceFilters;
};

const VideoCompletionRate = ({
  campaignId,
  filters,
}: VideoCompletionRateProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const { data, isFetching } = useQuery(
    ["videoCompletionRate", campaignId, filters],
    () => getVideoCompletionRate(campaignId, filters)
  );

  const normalisedData = useMemo(() => {
    return (
      data?.videoCompletionRate.map((v) => ({
        label: v.creativeName,
        value: v.completionRatePct * 100,
      })) ?? []
    );
  }, [data?.videoCompletionRate]);

  const chart = useExportableChart({
    title: "Average % Watched",
    campaignId: Number(campaignId),
    chart: (
      <VideoCompletionRateChart
        minHeight={isExpanded ? MIN_MODAL_CONTENT_HEIGHT : undefined}
        data={normalisedData}
      />
    ),
  });

  return (
    <Card
      onExpandedChange={setIsExpanded}
      hasData={isPopulatedArray(data?.videoCompletionRate)}
      noDataIcon={MdOutlineTvOff}
      noDataMessage="This visualization is designated for campaigns with allocated video formats and creatives."
      isLoading={isFetching}
      title={"Average % Watched"}
      isExpandable
      actions={
        <ChartExport
          size="small"
          campaignId={Number(campaignId)}
          title={chart.title}
          onExportPNG={chart.downloadAsPng}
          data={normalisedData}
        />
      }
    >
      {chart.component}
      {chart.exportComponent}
    </Card>
  );
};

export { VideoCompletionRate };
