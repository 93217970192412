import { Box, Flex, Text, VStack } from "@chakra-ui/layout";
import { useColorModeValue } from "@chakra-ui/react";
import { CampaignKeywordsAndTopics } from "@intentsify/types";
import pick from "lodash/pick";
import { memo } from "react";
import { Autodiscovery } from "./Autodiscovery";
import { ReactComponent as AutodiscoverySvg } from "./Autodiscovery/assets/autodiscovery.svg";
import { ManualSelectionKeywords } from "./ManualSelection/ManualSelectionKeywords";
import { ManualSelectionTopics } from "./ManualSelection/ManualSelectionTopics";

import { Section } from "../../../../../../../../components/Section";
import { UploadList } from "./UploadList/UploadList";

const useColor = () => ({
  helpDescription: useColorModeValue("gray.500", "gray.300"),
});

interface ConfigureProps {
  id: number;
  editing: boolean;
  campaignKeywordsAndTopics: CampaignKeywordsAndTopics;
}

export const Configure = memo(
  ({ id, editing, campaignKeywordsAndTopics }: ConfigureProps) => {
    const color = useColor();

    const autodiscoveryProps = pick(
      campaignKeywordsAndTopics,
      "autodiscoveryDescription",
      "autodiscoveryKeywords",
      "autodiscoveryProducts",
      "autodiscoveryTopics",
      "autodiscoveryUrls",
      "autodiscoveryFiles",
      "autodiscoveryStatus",
      "dynamicOneStatus"
    );

    return (
      <Box>
        <VStack spacing="2" mb="14" mt="10">
          <Text fontSize="2xl">
            {editing
              ? "Need to select more keywords or topics for this intent model?"
              : "It looks like you don't have keywords or topics selected for this intent model."}
          </Text>

          <Text
            fontSize="md"
            fontWeight="semibold"
            color={color.helpDescription}
          >
            We have four easy ways to configure topics and keywords. These
            selections will reflect in your Topic, Keyword, and Social intent
            feeds.
          </Text>
        </VStack>

        <Flex flexDirection="column">
          <Section
            name="Autodiscovery"
            icon={<AutodiscoverySvg />}
            defaultIndex={[0]}
          >
            <Autodiscovery {...autodiscoveryProps} />
          </Section>

          <Section name="Upload a list">
            <UploadList id={id} />
          </Section>

          <Section isLazy name="Manual selection (topics)">
            <ManualSelectionTopics />
          </Section>

          <Section isLazy name="Manual selection (keywords)">
            <ManualSelectionKeywords />
          </Section>
        </Flex>
      </Box>
    );
  }
);
