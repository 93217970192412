import {
  MenuItem as ChakraMenuItem,
  Flex,
  Spinner,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import { Tooltip } from "components";
import { ActionItem as ActionItemType } from "types";
import { MenuDivider } from "./MenuDivider";

type MenuItemProps = ActionItemType & { index: number };

const MenuItem = (props: MenuItemProps) => {
  const {
    label,
    icon,
    onClick,
    dividerBefore,
    index,
    isLoading,
    isDisabled,
    isDisabledTooltip,
  } = props;
  const notFirst = index !== 0;

  const { item: itemStyles } = useMultiStyleConfig("Menu", {});
  const buttonStyles = useMultiStyleConfig("Button", { size: "sm" });

  return (
    <>
      {notFirst && dividerBefore && <MenuDivider />}

      {!isLoading && (
        <>
          {isDisabled && isDisabledTooltip ? (
            <Flex
              //@ts-ignore
              style={{
                ...buttonStyles,
                ...itemStyles,
                // _disabled exists but isn't typed correctly
                //@ts-ignore
                opacity: itemStyles._disabled.opacity,
                cursor: "not-allowed",
              }}
              px={3}
              py={1.5}
            >
              <Tooltip
                aria-label={`${label} (disabled)`}
                label={isDisabledTooltip}
              >
                <Flex alignItems="center">
                  <Flex mr={1.5}>{icon}</Flex>
                  {label}
                </Flex>
              </Tooltip>
            </Flex>
          ) : (
            <ChakraMenuItem
              icon={icon}
              iconSpacing="2"
              isDisabled={isDisabled}
              onClick={() => onClick()}
            >
              <Tooltip aria-label={label} label={isDisabledTooltip}>
                {label}
              </Tooltip>
            </ChakraMenuItem>
          )}
        </>
      )}

      {isLoading && (
        <ChakraMenuItem
          alignContent="center"
          h="36px"
          isDisabled={true}
          iconSpacing="2"
        >
          <Flex w="100%" justifyContent="center" alignContent="center">
            <Spinner size="xs" />
          </Flex>
        </ChakraMenuItem>
      )}
    </>
  );
};

export { MenuItem };
