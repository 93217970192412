import { ColumnDef } from "@tanstack/react-table";
import { useMemo } from "react";
import { SummaryTableEntry } from "./SummaryTableEntry";

export const useColumns = () => {
  return useMemo<ColumnDef<SummaryTableEntry>[]>(
    () => [
      {
        accessorKey: "name",
        header: (arg) => {
          const { flatRows } = arg.table.getRowModel();

          return `Item (${flatRows.length})`;
        },
        size: 120,
        cell: (arg) => arg.getValue(),
      },
      {
        accessorKey: "category",
        header: "Category",
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: "selectionMethod",
        header: "Selection method",
        cell: (info) => info.getValue(),
      },
    ],
    []
  );
};
