import { CloseIcon } from "@chakra-ui/icons";
import { Divider, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { AiOutlineReload } from "react-icons/ai";
import { useMount, useUnmount } from "react-use";
import { Button } from "../Button";
import { IconButton } from "../IconButton";
import { Progress } from "../Progress";
import { usePageVisibility } from "./CheckForUpdates.utils";

type ToastProps = {
  onClose: () => void;
  onUserClose: () => void;
  forceReload: boolean;
  reloadTimeout: number;
};

const Toast = ({
  onClose,
  onUserClose,
  reloadTimeout,
  forceReload,
}: ToastProps) => {
  const [updateNowButtonClicked, setUpdateNowButtonClicked] = useState(false);
  const bgColor = useColorModeValue("gray.100", "gray.900");
  const reloadTimeoutId = useRef<NodeJS.Timeout | undefined>();
  const { isPageVisible } = usePageVisibility();

  const [progress, setProgress] = useState(0);

  useMount(() => {
    // Sometimes set progress is set before item is rendered and CSS animation won't trigger properly.
    // Waiting a split seconds ensures it's stable.
    setTimeout(() => {
      setProgress(100);
    }, 100);

    if (forceReload) {
      reloadTimeoutId.current = setTimeout(() => {
        location.reload();
      }, reloadTimeout);
    }
  });

  useUnmount(() => {
    if (reloadTimeoutId.current) {
      clearTimeout(reloadTimeoutId.current);
    }
  });

  // We want to abort if tab loses focus, reloading in the background can cause the tab to crash.
  useEffect(() => {
    if (!isPageVisible && reloadTimeoutId.current) {
      clearTimeout(reloadTimeoutId.current);
      onClose();
    }
  }, [isPageVisible, reloadTimeoutId, onClose]);

  return (
    <Flex bg={bgColor} borderRadius={4} py={2} px={4} flexDir="column">
      <Flex alignItems="center">
        <Text fontSize="xl" mr={4}>
          🎉
        </Text>
        <Text fontSize="sm" mr={4}>
          New version of the app is available!
        </Text>
        <Button
          isLoading={updateNowButtonClicked}
          rightIcon={<AiOutlineReload />}
          variant="success"
          onClick={() => {
            if (reloadTimeoutId.current) {
              clearTimeout(reloadTimeoutId.current);
            }

            setUpdateNowButtonClicked(true);
            // A little timeout added for dramatic effect.
            setTimeout(() => {
              location.reload();
            }, 1000);
          }}
        >
          Update Now
        </Button>
        <IconButton
          ml={2}
          tooltip="Dismiss"
          size="xs"
          icon={<CloseIcon />}
          onClick={() => onUserClose()}
        />
      </Flex>

      {forceReload && (
        <>
          <Divider mt={3} />
          <Text mt={3} textAlign="center" colorScheme="gray" fontSize={12}>
            Updating...
          </Text>
          <Progress value={progress} transitionDuration={reloadTimeout} />
        </>
      )}
    </Flex>
  );
};

export { Toast };
