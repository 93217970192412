import {
  CompanyNameToDomainRequestDTO,
  GenericUserJobRecordRO,
} from "@intentsify/dto";
import { GenericUserJobType } from "@intentsify/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Endpoints, apiService } from "api";

export const useCompanyNameToDomain = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (
      companyNameToDomainRequestDTO: CompanyNameToDomainRequestDTO
    ) =>
      apiService.post<GenericUserJobRecordRO>(
        Endpoints.TalTools.CompanyNameToDomain.Post,
        companyNameToDomainRequestDTO
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["talTools", GenericUserJobType.COMPANY_NAME_TO_DOMAIN],
        exact: false,
      });
    },
  });
};
