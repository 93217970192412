import { atom, selector } from "recoil";

export const isLoadingPixelIdsAtom = atom<boolean>({
  key: "isLoadingPixelIdsAtom",
  default: false,
});

export const isLoadingAdvancedSettingsSelector = selector<boolean>({
  key: "advancedSettings_isLoadingSelector",
  get: ({ get }) => {
    return get(isLoadingPixelIdsAtom);
  },
});
