import { TooltipInPortalProps } from "@visx/tooltip/lib/hooks/useTooltipInPortal";
import { ScaleBand, ScaleLinear } from "d3-scale";
import { FC, ReactNode } from "react";
import {
  TimeseriesDataItem,
  TimeseriesLegendItemFormatted,
} from "../../Charts.types";
import { Lines } from "./Lines";
import { TimeseriesTooltipProps } from "./TimeseriesTooltip";

type LinesGroupProps = {
  timeValues: string[];
  timeScale: ScaleBand<string>;
  leftAxisValuesScale?: ScaleLinear<number, number, never>;
  rightAxisValuesScale?: ScaleLinear<number, number, never>;
  offsetLeft: number;
  offsetTop: number;
  sorted: {
    leftAxisBars: Array<{
      data: TimeseriesDataItem[];
      legend: TimeseriesLegendItemFormatted;
    }>;
    rightAxisBars: Array<{
      data: TimeseriesDataItem[];
      legend: TimeseriesLegendItemFormatted;
    }>;
    leftAxisLines: Array<{
      data: TimeseriesDataItem[];
      legend: TimeseriesLegendItemFormatted;
    }>;
    rightAxisLines: Array<{
      data: TimeseriesDataItem[];
      legend: TimeseriesLegendItemFormatted;
    }>;
  };
  tooltipInPortal: FC<TooltipInPortalProps>;
  leftAxisLinesTooltipRenderer: (props: TimeseriesTooltipProps) => ReactNode;
  rightAxisLinesTooltipRenderer: (props: TimeseriesTooltipProps) => ReactNode;
};

const LinesGroup = ({
  timeValues,
  timeScale,
  leftAxisValuesScale,
  rightAxisValuesScale,
  offsetLeft,
  offsetTop,
  sorted,
  tooltipInPortal,
  leftAxisLinesTooltipRenderer,
  rightAxisLinesTooltipRenderer,
}: LinesGroupProps) => {
  return (
    <>
      {leftAxisValuesScale && (
        <Lines
          data={sorted.leftAxisLines}
          timeScale={timeScale}
          valuesScale={leftAxisValuesScale}
          timeValues={timeValues}
          offsetLeft={offsetLeft}
          offsetTop={offsetTop}
          tooltipInPortal={tooltipInPortal}
          tooltipRenderer={leftAxisLinesTooltipRenderer}
        />
      )}

      {rightAxisValuesScale && (
        <Lines
          data={sorted.rightAxisLines}
          timeScale={timeScale}
          valuesScale={rightAxisValuesScale}
          timeValues={timeValues}
          offsetLeft={offsetLeft}
          offsetTop={offsetTop}
          tooltipInPortal={tooltipInPortal}
          tooltipRenderer={rightAxisLinesTooltipRenderer}
        />
      )}
    </>
  );
};

export { LinesGroup };
