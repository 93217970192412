import { useEffect, useState } from "react";

export enum Status {
  idle = "idle",
  loading = "loading",
  ready = "ready",
  error = "error",
}

const useScript = (src: string): Status => {
  const [status, setStatus] = useState<Status>(
    src ? Status.loading : Status.idle
  );

  useEffect(() => {
    if (!src) {
      setStatus(Status.idle);
      return;
    }

    let script = document.querySelector(
      `script[src="${src}"]`
    ) as HTMLScriptElement;

    if (!script) {
      script = document.createElement("script");
      script.src = src;
      script.async = true;
      script.setAttribute("data-status", "loading");
      document.body.appendChild(script);

      const setAttributeFromEvent = (event: Event) => {
        script.setAttribute(
          "data-status",
          event.type === "load" ? "ready" : "error"
        );
      };

      script.addEventListener("load", setAttributeFromEvent);
      script.addEventListener("error", setAttributeFromEvent);
    } else {
      setStatus(script.getAttribute("data-status") as Status);
    }

    const setStateFromEvent = (event: Event) => {
      setStatus(event.type === "load" ? Status.ready : Status.error);
    };

    script.addEventListener("load", setStateFromEvent);
    script.addEventListener("error", setStateFromEvent);

    return () => {
      if (script) {
        script.removeEventListener("load", setStateFromEvent);
        script.removeEventListener("error", setStateFromEvent);
      }
    };
  }, [src]);

  return status;
};

export { useScript };
