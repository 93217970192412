import { Box } from "@chakra-ui/react";
import { useLayoutEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useAppStore } from "store/useAppStore";

const transition = `
  visibility 0.34s cubic-bezier(0.25, 1, 0.5, 1),
  opacity 0.34s cubic-bezier(0.25, 1, 0.5, 1)
`;

const ImpersonationOverlay = () => {
  const [modalContainer] = useState(document.createElement("div"));
  const impersonation = useAppStore.use.impersonation();

  useLayoutEffect(() => {
    const [body] = Array.from(document.getElementsByTagName("body"));

    if (body) {
      body.appendChild(modalContainer);
    }
  }, [modalContainer]);

  return createPortal(
    <Box
      position="fixed"
      top={0}
      bottom={0}
      left={0}
      right={0}
      borderWidth={4}
      borderStyle="solid"
      borderColor="yellow.300"
      pointerEvents="none"
      zIndex="popover"
      visibility={impersonation ? "visible" : "hidden"}
      opacity={impersonation ? 1 : 0}
      transition={transition}
    />,
    modalContainer
  );
};

export { ImpersonationOverlay };
