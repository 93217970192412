import { NotificationTransportMethods } from "@intentsify/types";
import { isPopulatedArray } from "@intentsify/utils";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import uniqBy from "lodash/uniqBy";
import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { useAppStore } from "store/useAppStore";
import {
  listPaginatedNotifications,
  markAllAsReadRequest,
  markAsReadRequest,
} from "./Notifications.requests";
import {
  isFetchingSelector,
  isFetchingUnreadNextPageState,
  isFetchingUnreadState,
  nextPageTokenState,
  readNotificationsState,
  showReadState,
  unreadNotificationsState,
  useResetOnClearAll,
} from "./Notifications.state";

export const useUnreadNotifications = () => {
  const setUnreadNotifications = useSetRecoilState(unreadNotificationsState);
  const user = useAppStore.use.user();
  const impersonation = useAppStore.use.impersonation();
  const notificationTypes = useNotificationTypes();

  const { data } = useQuery(
    ["unreadNotifications", user?.userId],
    () => {
      if (impersonation) {
        return undefined;
      }

      return listPaginatedNotifications({
        pageToken: "",
        notificationTypes,
        hasBeenRead: false,
      });
    },
    {
      enabled: notificationTypes.length > 0 && !impersonation?.impersonates,
      onSuccess: (data) => {
        if (!data) {
          return;
        }

        setUnreadNotifications((prev) => {
          const sorted = [...prev, ...data.notifications].sort(
            (a, b) =>
              new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
          );

          return uniqBy(sorted, (i) => i.id);
        });
      },
    }
  );

  return data?.notifications;
};

export const useReadNotifications = () => {
  const setReadNotificationsState = useSetRecoilState(readNotificationsState);
  const setIsFetching = useSetRecoilState(isFetchingUnreadState);
  const user = useAppStore.use.user();
  const impersonation = useAppStore.use.impersonation();
  const notificationTypes = useNotificationTypes();
  const setNextPageToken = useSetRecoilState(nextPageTokenState);

  const { isFetching } = useQuery(
    ["readNotifications", notificationTypes, user?.userId],
    () => {
      if (impersonation) {
        return undefined;
      }

      return listPaginatedNotifications({
        pageToken: "",
        notificationTypes,
        hasBeenRead: true,
      });
    },
    {
      enabled: notificationTypes.length > 0 && !impersonation?.impersonates,
      onSuccess: (result) => {
        if (!result) {
          return;
        }

        const { notifications, nextPageToken } = result;
        setNextPageToken(nextPageToken);
        setReadNotificationsState((previous) => {
          if (previous) {
            return [...previous, ...notifications];
          }

          return notifications;
        });
      },
    }
  );

  useEffect(() => {
    setIsFetching(isFetching);
  }, [isFetching, setIsFetching]);
};

export const useFetchNextPage = () => {
  const notificationTypes = useNotificationTypes();
  const setReadNotificationsState = useSetRecoilState(readNotificationsState);
  const [nextPageToken, setNextPageToken] = useRecoilState(nextPageTokenState);
  const setIsFetchingUnreadNextPage = useSetRecoilState(
    isFetchingUnreadNextPageState
  );

  const { mutate, isLoading } = useMutation(
    () => {
      return listPaginatedNotifications({
        pageToken: nextPageToken,
        notificationTypes,
        hasBeenRead: true,
      });
    },
    {
      onSuccess: (result) => {
        if (!result) {
          return;
        }

        const { notifications, nextPageToken } = result;
        setNextPageToken(nextPageToken);
        setReadNotificationsState((previous) => {
          if (previous) {
            return [...previous, ...notifications];
          }

          return notifications;
        });
      },
    }
  );

  useEffect(() => {
    setIsFetchingUnreadNextPage(isLoading);
  }, [isLoading, setIsFetchingUnreadNextPage]);

  return mutate;
};

export const useOnLoadMore = () => {
  const showRead = useRecoilValue(showReadState);
  const isFetching = useRecoilValue(isFetchingSelector);
  const fetchNextPage = useFetchNextPage();

  const onLoadMore = useCallback(() => {
    if (!isFetching && showRead) {
      fetchNextPage();
    }
  }, [fetchNextPage, isFetching, showRead]);

  return onLoadMore;
};

export const useNotificationTypes = () => {
  const user = useAppStore.use.user();

  const notificationTypes = useMemo(() => {
    const notificationTypes = user?.settings.notifications
      ? user?.settings.notifications
          .filter(
            (i) =>
              i.transportMethod === NotificationTransportMethods.PORTAL &&
              !i.isDisabled
          )
          .map((i) => i.notificationId)
      : [];

    return notificationTypes;
  }, [user?.settings.notifications]);

  return notificationTypes;
};

export const useUnreadCount = () => {
  const unread = useRecoilValue(unreadNotificationsState);
  return unread.length;
};

export const useIsLoading = () => {
  const unread = useRecoilValue(readNotificationsState);
  return !unread;
};

type BroadcastChannelMessage = {
  ids: number[];
};

export const useNotificationChannel = (onMutate: (ids: number[]) => void) => {
  const channel = useRef(
    new BroadcastChannel(
      `mark-as-read-channel_${String(process.env.VITE_NODE_ENV)}`
    )
  );

  channel.current.onmessage = (
    event: MessageEvent<BroadcastChannelMessage>
  ) => {
    if (isPopulatedArray(event.data.ids)) {
      onMutate(event.data.ids);
    }
  };

  return channel.current;
};

export const useMarkAsRead = () => {
  const [unreadNotifications, setUnreadNotifications] = useRecoilState(
    unreadNotificationsState
  );
  const setReadNotifications = useSetRecoilState(readNotificationsState);

  const onMutate = useCallback(
    (ids: number[]) => {
      const items = unreadNotifications.filter((i) => ids.includes(i.id));

      if (isPopulatedArray(items)) {
        setUnreadNotifications((prev) =>
          prev.filter((i) => !ids.includes(i.id))
        );
        setReadNotifications((prev) => [
          ...(prev ?? []),
          ...items.map((i) => ({
            ...i,
            readAt: DateTime.now().toISO(),
          })),
        ]);
      }
    },
    [setReadNotifications, setUnreadNotifications, unreadNotifications]
  );

  const notificationBroadcastChannel = useNotificationChannel(onMutate);
  const queryClient = useQueryClient();

  const { mutate: markAsReadMutation } = useMutation(markAsReadRequest, {
    onMutate,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["notifications"] });
    },
  });

  const markAsRead = useCallback(
    (ids: number[]) => {
      notificationBroadcastChannel.postMessage({ type: "mark-as-read", ids });
      markAsReadMutation(ids);
    },
    [markAsReadMutation, notificationBroadcastChannel]
  );

  return markAsRead;
};

export const useMarkAllAsRead = () => {
  const reset = useResetOnClearAll();

  const { mutate, isLoading } = useMutation(markAllAsReadRequest, {
    onSuccess: reset,
  });

  return { mutate, isLoading };
};
