import { Duration } from "luxon";

/**
 * Returns duration as string. Default The built in Duration.toHuman() method is not
 * formatting it as business requested.
 */
export const toDurationString = (obj: { seconds: number }) => {
  const durationFormatMapping = {
    years: `y'y'`,
    days: `d'd'`,
    hours: `h'h'`,
    minutes: `mm'm'`,
    seconds: `ss's'`,
  };

  const normalized = Duration.fromObject({
    years: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    ...obj,
  })
    .normalize()
    .toObject();

  const s = Object.entries(durationFormatMapping).reduce((prev, curr) => {
    const key = curr[0] as keyof typeof normalized;

    if (normalized[key] === 0) {
      return prev;
    }

    return `${prev} ${curr[1]}`;
  }, "");

  return Duration.fromObject(normalized).toFormat(s);
};
