import { apiService, Endpoints } from "api";
import { Nullable } from "types";

export const excludeDynamic1Article = async ({
  campaignId,
  domainId,
  url,
  logoUrl,
  score,
  exclude,
  weekNumber,
  yearNumber,
  runningWeek,
  exclusionReason = null,
}: {
  campaignId: number;
  domainId: number;
  url: string;
  logoUrl: string;
  score: number;
  exclude: boolean;
  weekNumber: number;
  yearNumber: number;
  runningWeek: number;
  exclusionReason: Nullable<string>;
}): Promise<any> => {
  const { data } = await apiService.put(
    Endpoints.Campaigns.Put.Autodiscovery.ExcludeDynamic1Article(campaignId),
    {
      domainId,
      url,
      logoUrl,
      score,
      exclude,
      weekNumber,
      yearNumber,
      runningWeek,
      exclusionReason,
    }
  );
  return data;
};
