import {
  ContentAlignmentAnalysisRO,
  WeeklySnapshotRangeDTO,
} from "@intentsify/dto";
import { apiService, Endpoints } from "api";

export const getContentAlignmentAnalysis = async (
  campaignId: number,
  filters: WeeklySnapshotRangeDTO,
  limit?: number
) => {
  const { data } = await apiService.get<ContentAlignmentAnalysisRO>(
    Endpoints.Campaigns.Get.WeeklySnapshot.ContentAlignmentAnalysis(campaignId),
    {
      params: {
        ...filters,
        limit,
      },
    }
  );

  return data;
};
