import { Box, Flex, useColorModeValue } from "@chakra-ui/react";
import { AnimateSharedLayout } from "framer-motion";
import { PropsWithChildren } from "react";
import { useAppStore } from "store/useAppStore";
import { ErrorBoundary } from "../ErrorBoundary";
import { MotionBox } from "../FramerMotionChakra";
import { Sidebar } from "./components";
import { TopBar } from "./components/TopBar/TopBar";

export const Layout = ({
  children,
  omitTopBar = false,
}: PropsWithChildren<{ omitTopBar?: boolean }>) => {
  const impersonation = useAppStore.use.impersonation();
  const sectionBg = useColorModeValue("white", "brand.375");
  const sideBarCollapsed = useAppStore.use.sideBarCollapsed();

  return (
    <MotionBox
      as="section"
      layout="position"
      display="flex"
      bg={sectionBg}
      minH="100vh"
    >
      {/* @ts-ignore */}

      <AnimateSharedLayout css="overflow-y: hidden">
        <Box
          position="sticky"
          top="0"
          left="0"
          right="0"
          height={impersonation ? "calc(100vh - 2px)" : "100vh"}
          zIndex="2"
          width={sideBarCollapsed ? "82px" : "220px"}
        >
          <Sidebar />
        </Box>
      </AnimateSharedLayout>

      <Box position="relative" width="100%" overflow="auto">
        <Flex
          h="100%"
          w="100%"
          flexDirection="column"
          overflow="auto"
          as="main"
          pb={impersonation ? "16" : undefined}
        >
          <ErrorBoundary>
            {!omitTopBar && <TopBar />}

            {children}
          </ErrorBoundary>
        </Flex>
      </Box>
    </MotionBox>
  );
};
