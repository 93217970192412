export type PieColor = { color: string; value: string };

const getColours = (shade: number): PieColor[] => [
  {
    color: "green",
    value: `var(--chakra-colors-green-${shade})`,
  },
  {
    color: "blue",
    value: `var(--chakra-colors-blue-${shade})`,
  },
  {
    color: "yellow",
    value: `var(--chakra-colors-yellow-${shade})`,
  },
  {
    color: "orange",
    value: `var(--chakra-colors-orange-${shade})`,
  },
  {
    color: "teal",
    value: `var(--chakra-colors-teal-${shade})`,
  },
  {
    color: "red",
    value: `var(--chakra-colors-red-${shade})`,
  },
  {
    color: "pink",
    value: `var(--chakra-colors-pink-${shade})`,
  },
  {
    color: "gray",
    value: `var(--chakra-colors-gray-${shade})`,
  },
  {
    color: "brand",
    value: `var(--chakra-colors-brand-${shade})`,
  },
];

export const getPieColors = (colorMode: "light" | "dark"): PieColor[] => {
  const shade1 = colorMode === "light" ? 500 : 100;
  const shade2 = colorMode === "light" ? 600 : 300;

  /**
   * Generate 2 sets of colours from our pallete, total of 18 colours. Shuold be enough for the pie.
   */
  return [...getColours(shade1), ...getColours(shade2)];
};
