import { useQuery } from "@tanstack/react-query";
import { getCampaignBrief, getCampaignBuyingGroup } from "api";

export const useCampaignBrief = (campaignId: number) => {
  const { data: campaignBrief, isFetching: campaignBriefIsLoading } = useQuery(
    ["getCampaignBrief", campaignId],
    () => getCampaignBrief(campaignId),
    {
      enabled: Boolean(campaignId),
    }
  );

  return {
    campaignBrief:
      campaignId === campaignBrief?.campaignId ? campaignBrief : undefined,
    campaignBriefIsLoading,
  };
};

export const useCampaignBuyingGroup = (
  campaignId: number,
  hasAccess: boolean
) => {
  return useQuery(
    ["getCampaignBuyingGroup", campaignId],
    () => getCampaignBuyingGroup(campaignId),
    {
      enabled: hasAccess && Boolean(campaignId),
    }
  );
};
