import { isPopulatedArray } from "@intentsify/utils";
import { ColumnDef } from "@tanstack/react-table";
import { Table } from "components";
import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { useComponentColors } from "theme";
import { trendingTopicsStateSelector } from "../../TrendingTopics.state";
import { TrendingTopicsWithTimeseriesResponse } from "../../TrendingTopics.types";
import { getMostActiveTopicsColumns } from "./TrendingTopicsTable.columns";

type MostActiveTopicsTableProps = {
  data: TrendingTopicsWithTimeseriesResponse | undefined;
  isLoading: boolean;
};

const TrendingTopicsTable = ({
  data,
  isLoading,
}: MostActiveTopicsTableProps) => {
  const { selectedTopics } = useRecoilValue(trendingTopicsStateSelector);

  const {
    form: { stackBg },
  } = useComponentColors();

  const columns = useMemo<ColumnDef<any>[]>(
    () => (data ? getMostActiveTopicsColumns(data?.timeseries) : []),
    [data]
  );

  const tableData = data ? data.data : [];

  return (
    <Table
      variant="intentsifyNew"
      noDataMessage={
        !isPopulatedArray(selectedTopics) ? "No data found" : undefined
      }
      stretch={true}
      bg={stackBg}
      columns={columns}
      isFetching={isLoading}
      data={tableData}
      onFetchDataChange={() => undefined}
    />
  );
};

export { TrendingTopicsTable };
