import { Flex } from "@chakra-ui/layout";
import { UploadContent } from "../UploadContent";
import { Table } from "./Table";
import { useSearch, useShowCurrentUserDrafts } from "./filters";

export const Drafts = () => {
  const search = useSearch();
  const showCurrentUserDrafts = useShowCurrentUserDrafts();

  return (
    <Flex flexDir="column" rowGap="4">
      <Flex justifyContent="space-between" alignItems="flex-end">
        <Flex columnGap="4" alignItems="flex-end">
          <search.Input />
          <showCurrentUserDrafts.Input />
        </Flex>

        <Flex>
          <UploadContent />
        </Flex>
      </Flex>

      <Table
        searchValue={search.value}
        showCurrentUserDrafts={showCurrentUserDrafts.value}
      />
    </Flex>
  );
};
