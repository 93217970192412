import { Box } from "@chakra-ui/react";
import { toNumberDisplayValue } from "@intentsify/utils";
import { useMeasure } from "react-use";
import { useEffect } from "react";
import { Cell } from "./Cell";
import { BuyerResearchStageEngagementItem } from "./BuyerResearchStageEngagementTable";

const createGetColour = (max: number) => {
  return (val: number) => {
    return `rgba(176, 147, 0, ${val / max})`;
  };
};

type RowProps = {
  item: BuyerResearchStageEngagementItem;
  maxValue: number;
  onNameWidthChange?: (width: number) => void;
};

const Row = ({ item, maxValue, onNameWidthChange }: RowProps) => {
  const { name, decision, consideration, interest, awareness } = item;
  const total = decision + consideration + interest + awareness;
  const getColour = createGetColour(maxValue);
  const [ref, { width }] = useMeasure<HTMLDivElement>();

  useEffect(() => {
    if (onNameWidthChange) {
      onNameWidthChange(width);
    }
  }, [width, onNameWidthChange]);

  return (
    <>
      <Box ref={ref}>
        <Cell noBorder contentAlignment="left">
          <Box overflowWrap="anywhere">{name}</Box>
        </Cell>
      </Box>
      <Cell bg={getColour(decision)}>{toNumberDisplayValue(decision)}</Cell>
      <Cell bg={getColour(consideration)}>
        {toNumberDisplayValue(consideration)}
      </Cell>
      <Cell bg={getColour(interest)}>{toNumberDisplayValue(interest)}</Cell>
      <Cell bg={getColour(awareness)}>{toNumberDisplayValue(awareness)}</Cell>
      <Cell>{toNumberDisplayValue(total)}</Cell>
    </>
  );
};

export { Row };
