import { createScreen } from "utils/createScreen";
import { CampaignDetailsScreen } from "../../../../types";

const CampaignDetailsScreenDefinition = createScreen({
  name: CampaignDetailsScreen,
  path: "/intent-models/:campaignId/details/:view",
  routePath: [
    "/:campaignId/details",
    "/:campaignId/",
    "/:campaignId/details/:view",
  ],
  accessPath: "campaign.details",
});

export { CampaignDetailsScreenDefinition };
