import { PageTrackingScreenDefinition } from "./PageTracking.definition";
import { PageTrackingTable } from "./PageTracking/PageTrackingTable";
import { ViewContainer } from "components";
import { useScreen } from "utils";

const PageTracking = () => {
  useScreen(PageTrackingScreenDefinition);

  return (
    <ViewContainer>
      <PageTrackingTable />
    </ViewContainer>
  );
};

export { PageTracking };
