import { Box, Stack, Text } from "@chakra-ui/react";
import { Option } from "@intentsify/types";
import {
  DynamicInput,
  Filter,
  FiltersContainer,
  FiltersContainerItem,
} from "components";

import { type ContactSearchFilters as ContactSearchFiltersType } from "@intentsify/types";
import { useQuery } from "@tanstack/react-query";
import { getContactSearchFilters } from "../../ContactSearch.requests";
import { AccountsSelector } from "../AccountsSelector/AccountsSelector";

const ContactSearchFilters = ({
  filters,
  onChange,
}: {
  filters: ContactSearchFiltersType;
  onChange: (filters: ContactSearchFiltersType) => void;
}) => {
  const { data, isFetching: isFetchingFilters } = useQuery(
    ["getContactSearchFilters"],
    getContactSearchFilters
  );

  const items: FiltersContainerItem[] = [
    {
      component: (
        <Filter
          isClearable
          label={"Job Level(s)"}
          options={data?.jobLevel ?? []}
          isLoading={isFetchingFilters}
          isDisabled={isFetchingFilters}
          placeholder="Select job level(s)"
          onFilterValuesChange={(options: Option<string>[]) => {
            onChange({
              ...filters,
              jobLevel: options.map((i) => i.label),
            });
          }}
          selected={filters.jobLevel.map((i) => ({ value: i, label: i }))}
        />
      ),
    },
    {
      component: (
        <Filter
          isClearable
          label={"Job Functions(s)"}
          options={data?.jobFunction ?? []}
          isLoading={isFetchingFilters}
          isDisabled={isFetchingFilters}
          placeholder="Select job function(s)"
          onFilterValuesChange={(options: Option<string>[]) => {
            onChange({
              ...filters,
              jobFunction: options.map((i) => i.label),
            });
          }}
          selected={filters.jobFunction.map((i) => ({ value: i, label: i }))}
        />
      ),
    },
    {
      component: (
        <DynamicInput
          size="sm"
          inputType="text"
          label="Job Title(s)"
          placeholder="Enter job title(s)"
          items={filters.jobTitle}
          addItem={(item) => {
            onChange({
              ...filters,
              jobTitle: [...filters.jobTitle, item],
            });
          }}
          removeItem={(item) => {
            onChange({
              ...filters,
              jobTitle: filters.jobTitle.filter((i) => i !== item),
            });
          }}
        />
      ),
    },
    {
      component: (
        <DynamicInput
          size="sm"
          inputType="text"
          label="Job Title Keyword(s)"
          placeholder="Enter job title keyword(s)"
          items={filters.jobTitleKeyword}
          addItem={(item) => {
            onChange({
              ...filters,
              jobTitleKeyword: [...filters.jobTitleKeyword, item],
            });
          }}
          removeItem={(item) => {
            onChange({
              ...filters,
              jobTitleKeyword: filters.jobTitleKeyword.filter(
                (i) => i !== item
              ),
            });
          }}
        />
      ),
    },
    {
      component: (
        <DynamicInput
          size="sm"
          inputType="text"
          label="Business Email(s)"
          placeholder="Enter business email(s)"
          items={filters.businessEmail}
          addItem={(item) => {
            onChange({
              ...filters,
              businessEmail: [...filters.businessEmail, item],
            });
          }}
          removeItem={(item) => {
            onChange({
              ...filters,
              businessEmail: filters.businessEmail.filter((i) => i !== item),
            });
          }}
        />
      ),
    },
    {
      component: (
        <DynamicInput
          size="sm"
          inputType="text"
          label="First Name"
          placeholder="Enter first name(s)"
          items={filters.firstName}
          addItem={(item) => {
            onChange({
              ...filters,
              firstName: [...filters.firstName, item],
            });
          }}
          removeItem={(item) => {
            onChange({
              ...filters,
              firstName: filters.firstName.filter((i) => i !== item),
            });
          }}
        />
      ),
    },
    {
      component: (
        <DynamicInput
          size="sm"
          inputType="text"
          label="Last Name"
          placeholder="Enter last name(s)"
          items={filters.lastName}
          addItem={(item) => {
            onChange({
              ...filters,
              lastName: [...filters.lastName, item],
            });
          }}
          removeItem={(item) => {
            onChange({
              ...filters,
              lastName: filters.lastName.filter((i) => i !== item),
            });
          }}
        />
      ),
    },
  ];

  return (
    <Box>
      <Stack>
        <Text fontSize="sm">Step 1: select company domain:</Text>

        <AccountsSelector
          filters={filters}
          onChange={(domain) => {
            onChange({
              ...filters,
              domain,
            });
          }}
        />

        <Text fontSize="sm">Step 2: apply search criteria:</Text>

        <FiltersContainer items={items} />
      </Stack>
    </Box>
  );
};

export { ContactSearchFilters };
