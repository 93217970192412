import {
  Tooltip as ChakraTooltip,
  TooltipProps as ChakraTooltipProps,
} from "@chakra-ui/react";
import { useState } from "react";
import { useTrackTooltipHovered } from "tracking/useTrackTooltipHovered";

export type TooltipProps = {
  children: ChakraTooltipProps["children"];
  label?: ChakraTooltipProps["label"];
  ["aria-label"]?: ChakraTooltipProps["aria-label"];
  shouldWrapChildren?: ChakraTooltipProps["shouldWrapChildren"];
  background?: ChakraTooltipProps["background"];
  color?: ChakraTooltipProps["color"];
  isDisabled?: ChakraTooltipProps["isDisabled"];
  placement?: ChakraTooltipProps["placement"];
  w?: ChakraTooltipProps["w"];
  openDelay?: ChakraTooltipProps["openDelay"];
};

const Tooltip = ({
  children,
  label,
  "aria-label": ariaLabel,
  shouldWrapChildren,
  background,
  color,
  w,
  isDisabled = false,
  placement = "bottom",
  openDelay = 0,
}: TooltipProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const trackTooltipHovered = useTrackTooltipHovered();

  return (
    <ChakraTooltip
      aria-label={ariaLabel}
      isOpen={isOpen}
      onOpen={() => {
        setIsOpen(true);
        trackTooltipHovered({ ariaLabel });
      }}
      onClose={() => {
        setIsOpen(false);
      }}
      w={w}
      isDisabled={isDisabled}
      label={label}
      placement={placement}
      hasArrow
      offset={[0, 15]}
      background={background}
      color={color}
      shouldWrapChildren={shouldWrapChildren}
      openDelay={openDelay}
    >
      {children}
    </ChakraTooltip>
  );
};

export { Tooltip };
