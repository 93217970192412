import { chakra, Flex, useColorModeValue } from "@chakra-ui/react";
import { isDefined, isPopulatedArray } from "@intentsify/utils";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { localPoint } from "@visx/event";
import { Group } from "@visx/group";
import { ParentSize } from "@visx/responsive";
import { scaleLinear } from "@visx/scale";
import { Circle, Line } from "@visx/shape";
import { Text } from "@visx/text";
import { useTooltip, useTooltipInPortal } from "@visx/tooltip";
import { voronoi, VoronoiPolygon } from "@visx/voronoi";
import { ErrorBoundary, Loader, NoData } from "components";
import { ScaleLinear } from "d3-scale";
import {
  MouseEvent,
  ReactNode,
  TouchEvent,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { animated, useSpring } from "react-spring";
import { useMeasure } from "react-use";
import { colors } from "theme";
import { useChartColors } from "utils";
import { BasicTooltip } from "../BasicTooltip";
import {
  EXPORT_LEGEND_WIDTH,
  EXPORT_TITLE_HEIGHT,
  MIN_HEIGHT,
} from "../Charts.const";
import { formatTickValue } from "../Charts.utils";

type LegendItem = {
  value: number;
  label: string;
  color: string;
  strokeDasharray: string;
  strokeWidth: string;
};
type BenchmarkItem = LegendItem;

type ScatterProps = {
  data: ScatterItem[];
  id?: string;
  isLoading?: boolean;
  exportMode?: true;
  title?: string;
  margins?: { t: number; b: number; r: number; l: number };
  stretch?: boolean;
  noDataMessage?: string;
  minHeight?: string;
  xAxisLabel?: string;
  yAxisLabel?: string;
  yAxisBenchmark?: BenchmarkItem[];
  tooltipRenderer: (textColor: string, props: ScatterItem) => ReactNode;
};

type ScatterItem = {
  x: number;
  y: number;
  label: string;
};

const spaceForAxisBottom = 0;
const spaceForAxisBottomLabel = 30;

let tooltipTimeout: number;

const Scatter = ({
  data,
  id,
  isLoading,
  exportMode = undefined,
  title,
  margins = {
    t: 20,
    b: 30,
    r: 10,
    l: 60,
  },
  stretch = false,
  minHeight = MIN_HEIGHT,
  noDataMessage,
  xAxisLabel,
  yAxisLabel,
  yAxisBenchmark,
  tooltipRenderer,
}: ScatterProps) => {
  const xScale = useRef<ScaleLinear<number, number, never> | undefined>();
  const yScale = useRef<ScaleLinear<number, number, never> | undefined>();
  const voronoiLayout = useRef<any | undefined>();
  const svgRef = useRef<SVGSVGElement>(null);

  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip<ScatterItem>();

  const { labelColor, axisColors } = useChartColors();

  const [ref] = useMeasure<HTMLDivElement>();

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    detectBounds: true,
    scroll: true,
  });

  const values = useMemo(() => {
    const xValues = data.map((i) => i.x);
    const yValues = [
      ...data.map((i) => i.y),
      ...(yAxisBenchmark?.map((i) => i.value) ?? []),
    ];

    return {
      x: {
        min: Math.min(...xValues),
        max: Math.max(...xValues),
        average: xValues.reduce((a, b) => a + b, 0) / xValues.length,
      },
      y: {
        min: Math.min(...yValues),
        max: Math.max(...yValues),
        average: yValues.reduce((a, b) => a + b, 0) / yValues.length,
      },
    };
  }, [data, yAxisBenchmark]);

  const handleMouseMove = useCallback(
    (event: MouseEvent<SVGElement> | TouchEvent<SVGElement>) => {
      if (tooltipTimeout) {
        clearTimeout(tooltipTimeout);
      }

      if (
        !voronoiLayout.current ||
        !xScale.current ||
        !yScale.current ||
        !svgRef.current
      ) {
        return;
      }

      const point = localPoint(svgRef.current, event);

      if (!point) {
        return;
      }
      const neighborRadius = 100;
      const closest = voronoiLayout.current.find(
        point.x - margins.l,
        point.y - margins.t,
        neighborRadius
      ) as {
        data: ScatterItem;
      } | null;

      if (closest) {
        showTooltip({
          tooltipLeft: xScale.current(closest.data.x),
          tooltipTop: yScale.current(closest.data.y),
          tooltipData: closest.data,
        });
      } else {
        hideTooltip();
      }
    },
    [xScale, yScale, showTooltip, voronoiLayout, hideTooltip, margins]
  );

  const handleMouseLeave = useCallback(() => {
    tooltipTimeout = window.setTimeout(() => {
      hideTooltip();
    }, 300);
  }, [hideTooltip]);

  const [{ opacity, transform }] = useSpring(
    {
      to: { opacity: 1, transform: "translateY(0px)" },
      from: { opacity: 0, transform: "translateY(-10px)" },
      reset: true,
      delay: 200,
    },
    []
  );

  const averagekLineColor = useColorModeValue(
    colors.gray["500"],
    colors.gray["400"]
  );

  if (isLoading) {
    return <Loader minHeight={minHeight} stretch={stretch} />;
  }

  if (!isPopulatedArray(data)) {
    return (
      <NoData message={noDataMessage} height={minHeight} stretch={stretch} />
    );
  }

  const AnimatedLine = animated(Line);
  const AnimatedCircle = animated(Circle);

  const legendItems: LegendItem[] = [
    {
      color: averagekLineColor,
      label: "Average",
      strokeDasharray: "4,4",
      value: 0,
      strokeWidth: "1px",
    },
    ...(yAxisBenchmark ?? []),
  ];

  return (
    <ErrorBoundary>
      <Flex ref={ref} width="100%" height="100%" flexDir="column">
        <Flex
          width="100%"
          height="1px" // https://stackoverflow.com/a/21836870
          flexGrow={stretch ? 1 : 0}
          minHeight={minHeight}
          overflow="hidden"
        >
          <ParentSize>
            {(parent) => {
              const { width, height } = parent;

              const baseYMax =
                height -
                margins.t -
                margins.b -
                spaceForAxisBottom -
                (xAxisLabel ? spaceForAxisBottomLabel : 0);

              const baseXMax = width - margins.l - margins.r;

              const yMax = !exportMode
                ? baseYMax
                : baseYMax - EXPORT_TITLE_HEIGHT;

              const xMax = !exportMode
                ? baseXMax
                : baseXMax - EXPORT_LEGEND_WIDTH;

              xScale.current = scaleLinear<number>({
                domain: [values.x.min, values.x.max],
                range: [0, xMax],
                nice: true,
              });

              yScale.current = scaleLinear<number>({
                domain: [values.y.min, values.y.max],
                range: [yMax, 0],
                nice: true,
              });

              if (!xScale.current || !yScale.current) {
                return null;
              }

              voronoiLayout.current = voronoi<ScatterItem>({
                x: (d) => (xScale.current && xScale.current(d.x)) ?? 0,
                y: (d) => (yScale.current && yScale.current(d.y)) ?? 0,
                width: xMax,
                height: yMax,
              })(data);

              const polygons = voronoiLayout.current.polygons() as [
                number,
                number
              ][][];

              return (
                <>
                  <svg
                    width={width}
                    height={height}
                    id={id}
                    style={{
                      fontFamily: "var(--chakra-fonts-body)",
                    }}
                  >
                    {exportMode && title && (
                      <Group top={30} left={22}>
                        <Text
                          verticalAnchor="middle"
                          fontSize={22}
                          fontWeight="semibold"
                          fill={labelColor}
                        >
                          {title}
                        </Text>
                      </Group>
                    )}

                    <Group>
                      <g
                        transform={`translate(${margins.l},${
                          !exportMode
                            ? margins.t
                            : margins.t + EXPORT_TITLE_HEIGHT
                        })`}
                      >
                        <rect
                          ref={containerRef}
                          width={xMax}
                          height={yMax}
                          fill="transparent"
                        />

                        <g
                          ref={svgRef}
                          onMouseMove={handleMouseMove}
                          onMouseLeave={handleMouseLeave}
                          onTouchMove={handleMouseMove}
                          onTouchEnd={handleMouseLeave}
                        >
                          {polygons.map((polygon, i) => (
                            <VoronoiPolygon
                              key={`polygon-${i}`}
                              polygon={polygon}
                              fill="transparent"
                            />
                          ))}

                          {data.map((item, i) => {
                            if (!xScale.current || !yScale.current) {
                              return null;
                            }

                            const active = tooltipData?.label === item.label;

                            return (
                              <AnimatedCircle
                                key={`point-${item.label}-${i}`}
                                className="dot"
                                cx={xScale.current(item.x)}
                                cy={yScale.current(item.y)}
                                r={active ? 7.5 : 5}
                                fill="#6EB21F"
                                style={{ opacity, transform }}
                              />
                            );
                          })}
                        </g>

                        <Group pointerEvents="none">
                          <AnimatedLine
                            from={{ x: 0, y: yScale.current(values.y.average) }}
                            to={{
                              x: xMax,
                              y: yScale.current(values.y.average),
                            }}
                            stroke={averagekLineColor}
                            strokeDasharray="4,4"
                            style={{ opacity, transform }}
                          />
                          <AnimatedLine
                            from={{ x: xScale.current(values.x.average), y: 0 }}
                            to={{
                              x: xScale.current(values.x.average),
                              y: yMax,
                            }}
                            stroke={averagekLineColor}
                            strokeDasharray="4,4"
                            style={{ opacity, transform }}
                          />

                          {yAxisBenchmark?.map((i) => {
                            if (!yScale.current) {
                              return null;
                            }

                            return (
                              <AnimatedLine
                                strokeWidth={i.strokeWidth}
                                key={i.label}
                                from={{ x: 0, y: yScale.current(i.value) }}
                                to={{
                                  x: xMax,
                                  y: yScale.current(i.value),
                                }}
                                stroke={i.color}
                                strokeDasharray={i.strokeDasharray}
                                style={{ opacity, transform }}
                              />
                            );
                          })}
                        </Group>

                        <AxisLeft
                          stroke={axisColors}
                          tickStroke={axisColors}
                          scale={yScale.current}
                          labelProps={{ fill: labelColor }}
                          tickFormat={(v) => formatTickValue(Number(v))}
                          tickComponent={(props) => {
                            const {
                              formattedValue,
                              x,
                              y,
                              textAnchor,
                              verticalAnchor,
                              dx,
                              dy,
                            } = props;

                            return (
                              <Text
                                x={x}
                                y={y}
                                dx={dx}
                                dy={dy}
                                fontSize={12}
                                textAnchor={textAnchor}
                                verticalAnchor={verticalAnchor}
                                fill={labelColor}
                              >
                                {formattedValue}
                              </Text>
                            );
                          }}
                        />

                        {yAxisLabel && (
                          <Text
                            x={-yMax / 2}
                            y={-margins.l + 12}
                            textAnchor="middle"
                            verticalAnchor="end"
                            transform="rotate(-90)"
                            fontSize={14}
                            fill={labelColor}
                          >
                            {yAxisLabel}
                          </Text>
                        )}

                        <AxisBottom
                          stroke={axisColors}
                          tickStroke={axisColors}
                          top={yMax}
                          scale={xScale.current}
                          tickLabelProps={() => ({
                            fill: labelColor,
                            fontSize: 11,
                            textAnchor: "middle",
                          })}
                        />

                        {xAxisLabel && (
                          <Text
                            x={xMax / 2}
                            y={
                              yMax +
                              spaceForAxisBottom +
                              spaceForAxisBottomLabel
                            }
                            textAnchor="middle"
                            verticalAnchor="start"
                            fontSize={14}
                            fill={labelColor}
                          >
                            {xAxisLabel}
                          </Text>
                        )}
                      </g>
                    </Group>

                    {tooltipOpen &&
                      tooltipData &&
                      isDefined(tooltipLeft) &&
                      isDefined(tooltipTop) && (
                        <BasicTooltip
                          tooltipRenderer={({ textColor, tooltipData }) => {
                            return (
                              <>{tooltipRenderer(textColor, tooltipData)}</>
                            );
                          }}
                          TooltipInPortal={TooltipInPortal}
                          tooltipLeft={tooltipLeft}
                          tooltipTop={tooltipTop}
                          tooltipData={tooltipData}
                        />
                      )}

                    {exportMode && (
                      <Group
                        width={`${EXPORT_LEGEND_WIDTH}px`}
                        top={margins.t + EXPORT_TITLE_HEIGHT}
                        left={width - margins.r / 2 - EXPORT_LEGEND_WIDTH}
                      >
                        {legendItems.map((item, index) => {
                          return (
                            <>
                              <Line
                                strokeWidth={item.strokeWidth}
                                from={{ x: 0, y: index * 32 }}
                                to={{
                                  x: 32,
                                  y: index * 32,
                                }}
                                stroke={item.color}
                                strokeDasharray={item.strokeDasharray}
                              />

                              <Text
                                verticalAnchor="middle"
                                fontSize={14}
                                key={index}
                                width={EXPORT_LEGEND_WIDTH}
                                x={40}
                                y={index * 32}
                                fill={labelColor}
                              >
                                {item.label}
                              </Text>
                            </>
                          );
                        })}
                      </Group>
                    )}
                  </svg>
                </>
              );
            }}
          </ParentSize>
        </Flex>

        {!exportMode && (
          <Flex>
            {legendItems.map((item, index) => {
              return (
                <Flex key={index} alignItems="center">
                  <chakra.svg ml={4} mr={2} w="32px" h="16px">
                    <Line
                      strokeWidth={item.strokeWidth}
                      from={{ x: 0, y: 8 }}
                      to={{
                        x: 32,
                        y: 8,
                      }}
                      stroke={item.color}
                      strokeDasharray={item.strokeDasharray}
                    />
                  </chakra.svg>

                  {item.label}
                </Flex>
              );
            })}
          </Flex>
        )}
      </Flex>
    </ErrorBoundary>
  );
};

export { Scatter };
