import { Box, Heading, useColorModeValue } from "@chakra-ui/react";
import { ViewContainer } from "components";

const NotFound = () => (
  <ViewContainer>
    <Heading
      fontSize={"6xl"}
      fontWeight="extrabold"
      letterSpacing="tight"
      color={useColorModeValue("gray.700", "gray.400")}
    >
      <Box color={useColorModeValue("gray.300", "gray.200")}>404</Box>
      <Box color={useColorModeValue("gray.600", "gray.400")}>Not Found.</Box>
    </Heading>
  </ViewContainer>
);

export { NotFound };
