import { Box, Flex } from "@chakra-ui/react";
import { MostEngagedAccountsRO, WeeklySnapshotDTO } from "@intentsify/dto";
import { MostEngagedAccountsRow } from "@intentsify/types";
import { useQuery } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import { ParentSize } from "@visx/responsive";
import { Card, EmptyCell, TableVirtualised } from "components";
import { getMostEngagedAccounts } from "./MostEngagedAccount.requests";

type Props = {
  campaignId: number;
  filters: WeeklySnapshotDTO;
};

const columns: ColumnDef<MostEngagedAccountsRow>[] = [
  {
    header: "Account",
    accessorKey: "account",
    cell: (info) => {
      return <Box fontSize="xs">{String(info.getValue())}</Box>;
    },
  },
  {
    header: "Buyer Research Stage",
    accessorKey: "buyerResearchStage",
    cell: (info) => {
      if (!info.getValue()) {
        return <EmptyCell />;
      }
      return (
        <Box fontSize="xs" whiteSpace="nowrap">
          {String(info.getValue())}
        </Box>
      );
    },
  },
  {
    header: "Dynamic1 Ranking",
    accessorKey: "dynamic1Ranking",
    cell: (info) => {
      if (!info.getValue()) {
        return <EmptyCell />;
      }
      return <Box fontSize="xs">{String(info.getValue())}</Box>;
    },
  },
  {
    header: "Topics",
    accessorKey: "topics",
    cell: (info) => {
      const topics = info.getValue() as string;
      if (!topics) {
        return <EmptyCell />;
      }
      return <Box fontSize="xs">{topics}</Box>;
    },
  },
];

const MostEngagedAccounts = ({ campaignId, filters }: Props) => {
  const { data, isFetching } = useQuery<MostEngagedAccountsRO>(
    ["getMostEngagedAccounts", campaignId, filters],
    () => getMostEngagedAccounts(campaignId, filters)
  );

  return (
    <Card
      isLoading={isFetching}
      title="Most Engaged Accounts"
      hasData={!isFetching && Boolean(data?.mostEngagedAccounts?.length)}
      titleTooltip="Accounts showing intent across multiple signals and/or at a higher frequency in comparison to other target accounts."
    >
      <ParentSize>
        {(parent) => {
          const { width, height } = parent;

          return (
            <Flex w={width} h={height}>
              <TableVirtualised
                isSortDisabled={true}
                variant="corporateCompact"
                data={data?.mostEngagedAccounts || []}
                columns={columns}
                containerProps={{
                  width,
                  maxHeight: height,
                }}
              />
            </Flex>
          );
        }}
      </ParentSize>
    </Card>
  );
};

export { MostEngagedAccounts };
