const agreementText = `
1.	  DEFINITIONS
1.1	  “Agreement” means this subscription agreement and the Hosted Services Order.
1.2	  “Confidential Information” shall mean any and all information or proprietary materials (in every form and media) not generally known to the public and which has been or is hereafter disclosed or made available by either party (the “disclosing party”) to the other (the “receiving party”) in connection with the efforts contemplated hereunder, including (i) all trade secrets and (ii) the underlying software components of the Hosted Services.
1.3	  “Customer” shall have the meaning set forth in the introductory paragraph of Customer’s Hosted Services Order.
1.4	  “Customer Data” means information, data, and other content, in any form or medium, that is submitted, posted, or otherwise transmitted by or on behalf of Customer or a User through the Hosted Services.
1.5	  “Effective Date” shall have the meaning set forth in the introductory paragraph of Customer’s Hosted Services Order. 
1.6	  “Fees” means Subscription Fees and Implementation Fees.
1.7	  “Hosted Services” means the Intentsify’s SaaS platform described in the Hosted Services Order.
1.8	  “Hosted Services Order” means the form signed by the parties describing the services to be provided by Intentsify and the associated pricing.
1.9	  “Implementation Fees” means the implementation fees set forth on the Hosted Services Order, if any.
1.10	“Intellectual Property Rights” means any patent, patent application, copyright, moral right, trade name, trademark, service mark, trade secret, and any applications or right to apply for registration therefore, computer software programs or applications, tangible or intangible proprietary information, or any other intellectual property right, in any media, whether registered or unregistered, and whether first made or created before or after the Effective Date.   
1.11	“Intent Activated Lead(s)” is an account that has been activated and meets the LAC of the Customer.
1.12	“intent Data” is data that signals an increase in an account’s propensity to buy.
1.13	“Lead Activation Criteria” (“LAC”) is set forth on the Hosted Services Order and outlines the criteria defining Marketing Campaign(s) on behalf of Customer to generate Intent Activated Leads consistent with the terms of the LAC. The LAC specifies the individual advertisements or promotions to be run (or who will provide them), the sizes of the advertisements, the beginning and end dates, and costs. The LAC may also include targeting criteria and total volume, as well as allowed marketing methods, day-parting and geographic location or other limitations.
1.14	“Marketing Campaign” or “Campaign” means a paid advertising offer on a performance model or a coordinated series of advertisements that (i) focus on one or a few brands or products, (ii) are directed at a particular identified segment of the general population (target audience), and (iii) are aimed at achieving a specific objective. Types of campaigns include, but are not limited to: (a) Cost per Lead or “CPL “; (b) Cost per Acquisition or “CPA”; (c) Cost per Click or “CPC”; and (d) number of Impressions. Campaigns will be created by Intentsify on behalf of Customer based on the parameters provided in the Hosted Service Order. Types of campaigns offered may be expanded at any time by Intentsify.
1.15	“Specifications” means the specifications for the Hosted Services set forth on the Hosted Services Order.
1.16	“Subscription Fees” means the subscription fees set forth on the Hosted Services Order.
1.17	“User” means any employee, agent and/or representative acting on behalf of Customer for whom Customer has issued a username and password supplied by Intentsify. Each User shall be issued a separate username and password which shall be used only by such User.
   
2.	PROVISION OF HOSTED SERVICES
2.1	Grant of Access Rights.  Intentsify grants to Customer a non-transferable, non-sublicensable, non-exclusive right to access and use the Hosted Services for Customer’s internal business purposes and solely by Users. 
2.2	Users; Login Credentials.  Customer shall be permitted concurrent access and use of the Hosted Services for the number of concurrent Users set forth on the Hosted Services Order.  Intentsify will assign an initial password to Customer and Customer must then assign a username and password to each individual User. Customer’s assigned username and passwords shall be maintained as confidential by Customer and shall not be distributed or disclosed, except to employees of Intentsify with a need to know in accordance with this Agreement. Customer agrees to employ all physical, administrative and technical controls, screening and security procedures and other safeguards necessary to securely administer the distribution and use of all usernames and passwords to protect against any unauthorized access to or use of the Services.
2.3	Restrictions.  Customer shall not, and shall not permit any person, entity, or Users to: (i) use the Hosted Services on a service bureau, time sharing or any similar basis, or for the benefit of any other person or entity other than Customer or its Users; (ii) alter, enhance, or make derivative works of the Hosted Services; (iii) reverse engineer, reverse assemble or decompile, or otherwise attempt to derive or gain access to any software component of the Hosted Services, in whole or in part; (iv) use, or allow the use of, the Hosted Services for any unfair or deceptive practices or in contravention of any federal, state, local, foreign, or other applicable law, or rules and regulations of regulatory or administrative organizations; (v) introduce in or to the Hosted Services any virus or  implement a denial of service attack or introduce or implement any  other code or routine which results in disruption or damage to the Hosted Services, alter, damage or delete any data or retrieve or record information about the Hosted Services or its uses; and (vi) act in a fraudulent, tortious malicious, or negligent manner when using the Hosted Services.  
2.4	Equipment.  Customer shall, at its own cost and expense, provide all equipment, operating systems, and software (including Web browser) needed to use and access the Hosted Services in accordance with the technical requirements in Intentsify’s documentation.  Customer shall also provide, at its own cost and expense, all connections from its computer systems to the Hosted Services.
2.5	Implementation.  Intentsify agrees to implement the Hosted Services for Customer, in exchange for the Implementation Fees.  Intentsify further agrees to provide phone support during its normal business hours, at no additional charge, during the Term.

3.	PAYMENT
3.1	Fees.  Fees shall be payable in accordance with the Hosted Services Order. If Customer fails to make any payment when due, without limiting Intentsify’s other rights and remedies: (i) Intentsify may charge interest on the past due amount at the rate of 1.5% per month calculated daily and compounded monthly or, if lower, the highest rate permitted under applicable law; (ii) Customer shall reimburse Intentsify for all costs incurred by Intentsify in collecting any late payments or interest, including attorneys' fees, court costs, and collection agency fees; and (iii) if such failure continues for fifteen (15) days or more, Intentsify may suspend Customer’s and its Users' access to any portion or all of the Hosted Services until such amounts are paid in full. 
3.2	Returns: Customer is eligible for credits, in the form of replacement Intent Activated Leads, for Invalid Leads. This is a commitment for a credit, not a refund, and Customer will still be charged for all Intent Activated Leads purchased from Intentsify. “Invalid Leads” is an Intent Activated Lead that: (i) has invalid or erroneous contact information; (ii) does not match the target criteria for the LAC in which it was generated; or (iii) has been previously provided (i.e., a duplicate Intent Activated Lead). If Customer believes it received an Invalid Lead, Customer must notify Intentsify within thirty (30) days via email to support@intentsify.com with the following information: (a) a statement regarding why the Intent Activated Lead is being returned; and (b) the Customer’s name, phone number, mailing address, and email address. For CPL Campaigns, Customer must provide the Intentsify Lead Identification, along with Customer’s phone number or email address, and associated disposition of the Intent Activated Lead. Thereafter, Intentsify will review Customer’s submission to determine whether Customer received an Invalid Lead. If Intentsify determines Customer received an Invalid Lead, it will promptly issue Customer a credit as set forth in this Section 3.2. 
3.4	Taxes.  Customer is responsible for paying all applicable fees and taxes it may incur in connection with access to the Hosted Services.  Customer agrees to pay amounts equal to any Federal, state or local sales, use, excise, privilege or other taxes or assessments, however designated or levied, relating to any amounts payable by Customer to Intentsify hereunder, this Agreement or any Hosted Services provided by Intentsify to Customer pursuant hereto and any taxes or amounts in lieu thereof paid or payable by Intentsify, excluding taxes based on Intentsify’s net income.

4.	OWNERSHIP
4.1	Hosted Services.  Customer acknowledges that the structure, organization, and code used in conjunction with the Hosted Services are proprietary to Intentsify and/or Intentsify’s supplier(s), and that Intentsify and/or its suppliers retain exclusive ownership of the Hosted Services, including all Intellectual Property Rights therein.  Customer shall not remove any proprietary, copyright, trademark, or service mark legend from any Hosted Services including any modifications, enhancements, derivatives, and other materials provided by Intentsify. Intentsify reserves all rights not expressly granted to Customer in this Agreement. Except for the limited rights and licenses expressly granted under this Agreement, nothing in this Agreement grants, by implication, waiver, estoppel, or otherwise, to Customer or any third party any Intellectual Property Rights or other right, title, or interest in or to the Hosted Services.
4.2	Customer Data.  Intentsify acknowledges that, as between Intentsify and Customer, Customer owns all right, title, and interest, including all Intellectual Property Rights, in and to the Customer Data. Customer hereby grants to Intentsify a non-exclusive, royalty-free, irrevocable, perpetual, worldwide license to reproduce, distribute, modify, and otherwise use and display the Customer Data for all lawful purposes, at Intentsify’s sole discretion, including to perform all acts with respect to the Customer Data as may be necessary for Intentsify to provide the Hosted Services to Customer. Intentsify agrees to aggregate and de-identify any personally identifiable information included in the Customer Data and to use such aggregated, de-identified personally identifiable information only in conjunction with Intentsify’s internal business purposes. Further, Intentsify agrees to comply with any applicable laws and regulations when collecting and maintaining Customer Data.
4.3	Intent Data. Intentsify grants to Customer a non-exclusive, non-sublicensable, and non-transferable license during the Term to access and use the Intent Data solely for Customer’s internal business purposes and solely in conjunction with the Hosted Services.
4.4	Restrictions on Use of Intent Data. Customer shall only use the Intent Data as set forth in Section 4.3 and shall not disclose, resell, release, distribute, or deliver the Intent Data, or any portion thereof, to any third party. Any purpose or use not specifically authorized herein is prohibited unless otherwise agreed to in writing by Intentsify. Without limiting the foregoing and except as otherwise expressly set forth in this Agreement, Customer shall not at any time, directly or indirectly: (i) copy, modify, or create derivative works of the Intent Data, in whole or in part; (ii) rent, lease, lend, sell, sublicense, assign, distribute, publish, transfer, or otherwise make available the Intent Data; (iii) reverse engineer, disassemble, decompile, decode, adapt, or otherwise attempt to derive or gain access to the source of the Intent Data or methods used to compile the Intent Data, in whole or in part; (iv) remove any proprietary notices included within the Intent Data; (v) publish, enhance, or display any compilation or directory based upon information derived from the Intent Data; or (vi) use the Intent Data in any manner or for any purpose that infringes, misappropriates, or otherwise violates any intellectual property right or other right of any person, or that violates any applicable law.

5.	CONFIDENTIALITY
5.1	Limitations on Disclosure and Use.  Both parties acknowledge that either may receive Confidential Information (as defined below) from the other during the Term of this Agreement.  The receiving party shall disclose the other party’s Confidential Information only to persons within the receiving party having the need to know the information for the purpose of this Agreement.  The receiving party (as defined below) shall treat Confidential Information as it does its own valuable and sensitive information of a similar nature, and, in any event, with not less than reasonable care.  Upon the disclosing party’s written request, the receiving party shall return or certify the destruction of all Confidential Information.
5.2	Exceptions.  The obligations of either party under Section 5.1 will not apply to information that (i) was already in the possession of the receiving party at the time of disclosure and without restriction as to confidentiality, (ii) at the time of disclosure was in the public domain or after disclosure becomes part of the public domain through no breach of this Agreement or other wrongful act by the receiving party, (iii) rightfully obtained by the receiving party on a non-confidential basis from a third party, or (iv) was independently developed by the receiving party without regard to the Confidential Information of the disclosing party, as demonstrated by documentary evidence.  Further, the receiving party may disclose Confidential Information to the extent such disclosure is required by law or order of a court of competent jurisdiction or regulatory authority, provided that the receiving party shall furnish prompt written notice of such required disclosure and reasonably cooperate with the disclosing party, at the disclosing party’s cost and expense, in any effort made by the disclosing party to seek a protective order or other appropriate protection of its Confidential Information.  
5.3	Specific Performance.  The parties agree that failure on the part of the receiving party to abide by this Section 5 will cause the disclosing party irreparable harm for which damages, although available, may not be an adequate remedy at law.  Accordingly, the disclosing party has the right to seek an injunction to prevent any violations or attempted violations of this Section 5 and seek to recover court costs and reasonable attorney fees incurred by the disclosing party in the enforcement of this Section 5.

6.	INDEMNIFICATION
6.1	Intentsify Indemnification.  Intentsify shall indemnify, defend and hold harmless Customer, its directors, officers, employees, suppliers, agents, successors, and assigns from and against all liabilities, losses, costs, expenses, (including reasonable attorney’s fees), and damages relating to or arising from or in connection with any third-party claim alleging that the Hosted Services, when used in accordance with this Agreement, infringe a third party’s U.S. patent or U.S. copyright. If such a claim is made or appears possible, Customer agrees to permit Intentsify, at Intentsify’s sole discretion, to (i) modify or replace the Hosted Services, or component or part thereof, to make it non-infringing, or (ii) obtain the right for Customer to continue use of the Hosted Services with materially equivalent functionality. If Intentsify determines that neither alternative is reasonably available, Intentsify may terminate this Agreement, in its entirety or with respect to the affected component or part, effective immediately on written notice to Customer.
6.2	Procedure.  Intentsify’s obligations under Section 6.1 are contingent upon: (i) providing Intentsify with prompt written notice of such claim, action or proceeding, (ii) permitting Intentsify to assume and solely control the defense of such claim, action or proceeding and all related settlement negotiations, with counsel chosen by the Intentsify, and (iii) cooperating at Intentsify’s request and expense with the defense or settlement of such claim, action or proceeding which cooperation shall include providing reasonable assistance and information.  Nothing herein shall restrict Customer’s right to participate in a claim, action or proceeding through its own counsel and at its own expense.
6.3	Exclusions. Section 6.1 will not apply to the extent that the alleged infringement arises from: (i) use of the Hosted Services in combination with data, software, hardware, equipment, or technology not provided by Intentsify or authorized by Intentsify in writing; (ii) modifications to the Services not made by Intentsify; or (iii) Customer Data.
6.4	Sole Remedy. SECTION 6.1 AND 6.2 SET FORTH CUSTOMER’S SOLE REMEDIES AND INTENTSIFY’S SOLE LIABILITY AND OBLIGATION FOR ANY ACTUAL, THREATENED, OR ALLEGED CLAIMS THAT THE HOSTED SERVICES INFRINGE, MISAPPROPRIATE, OR OTHERWISE VIOLATE ANY INTELLECTUAL PROPERTY RIGHTS OF ANY THIRD PARTY. 
6.5	Customer Indemnification. Customer shall indemnify, hold harmless, and, at Intentsify’s option, defend Intentsify from and against all liabilities, losses, costs, expenses, (including reasonable attorney’s fees), and damages resulting from or relating to (i) any third-party claim that the Customer Data, or any use of the Customer Data in accordance with this Agreement, infringes or misappropriates a third party’s Intellectual Property Rights and (ii) Customer’s or any User’s (A) gross negligence or willful misconduct; (B) use of the Hosted Services in a manner not authorized by this Agreement; (C) use of the Hosted Services in combination with data, software, hardware, equipment, or technology not provided by Intentsify or authorized by Intentsify in writing; or (D) modifications to the Hosted Services not made by Intentsify, provided that Customer may not settle any such claim against Intentsify unless Intentsify consents to such settlement, and further provided that Intentsify will have the right, at its option, to defend itself against any claim or to participate in the defense thereof by counsel of its own choice.

7.	WARRANTY AND SERVICE LEVEL AGREEMENT
7.1	Warranty.  Intentsify warrants that the Hosted Services will conform in all material respects to the service levels set forth in Section 7.2 when accessed and used in accordance with this Agreement. Intentsify does not make any representations or guarantees regarding uptime or availability of the Hosted Services unless specifically identified in Section 7.2. The remedies set forth in this Section 7 are Customer’s sole remedies and Intentsify’s sole liability under the limited warranty set forth in this Section 7.1.
7.2	Service Level Guarantee. Intentsify will use reasonable efforts consistent with prevailing industry standards to ensure that the Hosted Services are available for access and use over the internet by Customer and its Users at least ninety-nine percent (99%) of the time (the “Availability Requirement”), as measured over the course of each calendar month during the Term (each such calendar month, a “Service  Period”). A “Service Level Failure” means a failure by Intentsify to meet the Availability Requirement. For the purposes of determining compliance with the Availability Requirement, the Hosted Services will not be considered unavailable nor will any Service Level Failure deemed to occur, in whole or in part, as a result of: (i) any act or omission by Customer or any User that does not comply with this Agreement; (ii) Customer’s or its Users’ internet connectivity; (iii) a Force Majeure event; (iv) any failure, interruption, outage, or other problem with any of Customer’s or its Users’ software, hardware, system, network, facility, or other matter not supplied by Intentsify pursuant to this Agreement; (v) any scheduled downtime; or (vi) any disabling, suspension, or termination of the Hosted Services pursuant to Section 3.1.
7.3	Service Level Credits. In the event of a Service Level Failure, Intentsify shall issue a “Service Credit” to Customer equal to one-half of one percent (0.5%) of the total fees attributable to the Service Period during which the Service Level Failure occurred for each 1% by which the actual availability of the Hosted Services for use over the internet by Customer and its Users is less than the Availability Requirement; provided, however, that the Service Credit for any Service Period shall not exceed four percent (4%) of the total fees attributable to that Service Period. Any undisputed Service Credit payable to Customer pursuant to the terms of this Section 7.3 shall be issued to Customer in the calendar month following Customer’s written request. This Section 7.3 sets forth Intentsify’s sole obligation and liability for any Service Level Failure.
7.4	Limitation.  EXCEPT AS PROVIDED IN THIS SECTION 7, INTENTSIFY AND ITS SUPPLIERS MAKE AND GIVE NO REPRESENTATIONS OR WARRANTIES WHATSOEVER, AND EXPRESSLY DISCLAIM ALL WARRANTIES, REPRESENTATIONS, CONDITIONS AND GUARANTIES, WHETHER ORAL OR WRITTEN, IMPLIED OR STATUTORY, WITH REGARD TO THE HOSTED SERVICES AND ANY COMPONENTS THEREOF. INTENTSIFY MAKES NO WARRANTY OF ANY KIND THAT THE HOSTED SERVICES, OR ANY PRODUCTS OR RESULTS OF THE USE THEREOF, WILL MEET CUSTOMER’S OR ANY OTHER PERSON’S REQUIREMENTS, OPERATE WITHOUT INTERRUPTION, ACHIEVE ANY INTENDED RESULT, BE COMPATIBLE OR WORK WITH ANY SOFTWARE, SYSTEM, OR OTHER SERVICES, OR BE SECURE, ACCURATE, COMPLETE, FREE OF HARMFUL CODE, OR ERROR FREE.

8.	LIMITATIONS OF LIABILITY
8.1	WAIVER OF CERTAIN DAMAGES.  NEITHER PARTY OR ITS SUPPLIERS SHALL BE LIABLE TO THE OTHER PARTY OR ANY THIRD PARTY FOR ANY INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE, OR CONSEQUENTIAL DAMAGES IN CONNECTION WITH, OR RELATING TO, THIS AGREEMENT, HOWEVER CAUSED, WHETHER BASED IN CONTRACT, TORT, WARRANTY, OR OTHER LEGAL THEORY, AND EVEN IF SUCH PARTY HAS BEEN INFORMED IN ADVANCE OF THE POSSIBILITY OF SUCH DAMAGES OR SUCH DAMAGES COULD HAVE BEEN REASONABLY FORESEEN BY SUCH PARTY.  
8.2	AGGREGATE LIABILITY.  IN NO EVENT WILL INTENTSIFY’S AGGREGATE LIABILITY ARISING OUT OF OR RELATED TO THIS AGREEMENT UNDER ANY LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, AND OTHERWISE, EXCEED THE TOTAL AMOUNTS PAID TO INTENTSIFY UNDER THIS AGREEMENT IN THE TWELVE (12) MONTH PERIOD PRECEDING THE EVENT GIVING RISE TO THE CLAIM.
8.3	ESSENTIAL PURPOSE. The limitations specified in this Section 8 will survive and apply even if any limited remedies specified in this Agreement are found to have failed of their essential purpose. 

9.	TERM AND TERMINATION
9.1	Term.  This Agreement will have an initial term of one (1) year that commences on the Effective Date (“Initial Term”). This Agreement will automatically renew for successive one (1) year periods upon the expiration of the then-current term (each, a “Renewal Term”) unless either party provides written notice of termination of this Agreement at least thirty (30) days prior to the expiration of the then-current term, subject to earlier termination pursuant to Section 9.2 (the Initial Term, together with each Renewal Term, are collectively referred to herein as the “Term”).
9.2	Termination.  Either party may terminate this Agreement upon thirty (30) days prior written notice to the other party if the other party materially breaches this Agreement and fails to correct the breach within such thirty (30) day period.  Furthermore, each party may terminate this Agreement upon written notice if the other party ceases to conduct business (except for Force Majeure), becomes or is declared insolvent or bankrupt, is the subject of any proceeding relating to its liquidation or insolvency which is not dismissed within sixty (60) days, or makes an assignment for the benefit or its creditors.   Notwithstanding anything herein to the contrary, in the event of termination under this Section 8.2 all amounts due under this Agreement shall be paid within ten (10) days of such termination.
9.3	Force Majeure.  Neither party shall be liable to the other for failure or delay in the performance of a required obligation (except for any obligations to make payments) if such failure or delay is caused by riot, fire, natural disaster, utilities and communications failures, governmental acts or orders or restrictions, or any other reason where failure to perform is beyond the reasonable control of and is not caused by the negligence of the non performing party (“Force Majeure”), provided that such party gives prompt written notice of such condition and resumes its performance as soon as possible.  Notwithstanding the foregoing, Customer may terminate this Agreement if Intentsify is unable to provide the Hosted Services due to events of Force Majeure lasting longer than ninety (90) consecutive days.

10.	 GENERAL
10.1	Governing Law.  This Agreement will be governed by the laws of the Commonwealth of Massachusetts, without regard to principles of conflicts of law.  Disputes will be heard in the appropriate federal or state courts located in Massachusetts and each party irrevocably submits to the exclusive jurisdiction of such courts in any such suit, action, or proceeding. The parties acknowledge and agree that this Agreement relates solely to the performance of services (not the sale of goods) and, accordingly, will not be governed by the Uniform Commercial Code of any state having jurisdiction and shall not be governed by the United Nations Convention on the International Sale of Goods. 
10.2	Assignment.  Customer may not assign any of its rights or delegate any of its obligations hereunder, in each case whether voluntarily, involuntarily, by operation of law or otherwise, without the prior written consent of Intentsify. Any purported assignment or delegation in violation of this section will be null and void. This Agreement is binding upon and inures to the benefit of the parties and their respective permitted successors and assigns.
10.3	Severability.  If any provision of this Agreement is held by a court of competent jurisdiction to be contrary to law, the remaining provisions of the Agreement shall remain in full force and effect.  
10.4	Independent Contractors.  Nothing contained in this Agreement will be deemed to place the parties in the relationship of employer / employee, partners, or joint venturers.  Neither party will have any right to obligate or bind the other in any manner except as specifically provided for in this Agreement.  Each party agrees and acknowledges that it will not hold itself out as an authorized agent with the power to bind the other party in any manner.  Each party will be responsible for any withholding taxes, payroll taxes, disability insurance payments, unemployment taxes, and other similar taxes or charges with respect to its activities in relation to performance of its obligations under this Agreement.  
10.5	Notices.  All notices, demands or consents required or permitted in this agreement will be in writing and will be delivered, sent by facsimile or mailed certified return receipt requested to the respective parties at the addresses stated in the introductory clause above or at any other address the party will specify to the other party in writing.  Any notice required or permitted to be given by the provisions of this Agreement will be conclusively deemed to have been received on the day it is delivered to that party by U.S. Mail with acknowledgment of receipt or by any commercial courier providing equivalent acknowledgment of receipt.
10.6	Entire Agreement.  Each party acknowledges and agrees that this Agreement is the complete statement of the agreement between the parties, and that this Agreement supersedes all prior proposals, understandings and arrangements, oral or written, between the parties relating to this Agreement.
10.7	Headings.  The headings of the sections and paragraphs of this Agreement shall be for convenience only.
10.8	Counterparts.  This Agreement may be executed in multiple counterparts, any of which will be deemed an original, but all of which will constitute one and the same instrument.
`;

export const agreementTextBlocks = agreementText.trim().split("\n");
