import { SignalSelectionSource } from "@intentsify/types";
import orderBy from "lodash/orderBy";
import { useMemo } from "react";
import { useKeywordsAndTopicsState } from "../../KeywordsAndTopics.hooks";
import { SummaryTableEntry } from "./SummaryTableEntry";
import { useFilters } from "./useFilters";

export const useData = (category: "keywords" | "topics") => {
  const { state } = useKeywordsAndTopicsState();
  const filters = useFilters();

  const data = useMemo<SummaryTableEntry[]>(() => {
    if (category === "keywords") {
      return state.selectedKeywords.map((k) => ({
        id: k.value,
        name: k.label,
        category: "Keyword" as SummaryTableEntry["category"],
        selectionMethod: k.metaData?.source as SignalSelectionSource,
      }));
    }

    if (category === "topics") {
      return state.selectedTopics.map((k) => ({
        id: k.topicId,
        name: k.topic,
        category: "Topic" as SummaryTableEntry["category"],
        selectionMethod: k.source,
      }));
    }

    throw new Error(`Unknown category "${category as string}".`);
  }, [category, state.selectedKeywords, state.selectedTopics]);

  const filteredData = useMemo(() => {
    return data.filter((item) =>
      item.name.toLowerCase().includes(filters.search.toLowerCase())
    );
  }, [data, filters.search]);

  const orderedData = useMemo(() => {
    const [sort] = filters.sorting;

    if (sort) {
      return orderBy(filteredData, [sort.id], sort.desc ? "desc" : "asc");
    }

    return filteredData;
  }, [filteredData, filters.sorting]);

  return {
    data: orderedData,
    isDataAvailable:
      state.selectedKeywords.length > 0 || state.selectedTopics.length > 0,
  };
};
