import type { AccountOpportunity, CampaignDetails } from "@intentsify/types";
import {
  Box,
  Divider,
  Flex,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import React from "react";
import { toNumberDisplayValue } from "@intentsify/utils";
import { SpotlightBlockSectionComponent } from "./SpotlightBlockSection.component";
import { TopLocations } from "./TopLocations.component";
import { AccountOpportunityLeadEngagement } from "./AccountOpportunityLeadEngagement.component";
import { AccountOpportunityDisplay } from "./AccountOpportunityDisplay.component";
import { Card } from "components";

interface AccountOpportunitySpotlightComponentProps {
  campaignDetails: CampaignDetails;
  accountOpportunity: AccountOpportunity | null;
  isLoading: boolean;
}

export const AccountOpportunitySpotlightComponent = ({
  accountOpportunity,
  isLoading,
}: AccountOpportunitySpotlightComponentProps) => {
  const isLeadTriggered = Boolean(accountOpportunity?.leadEngagements?.length);
  const renderLocationsTooltip = (locations: Array<{ location: string }>) =>
    locations.length > 1 ? (
      <>
        Locations:{" "}
        <UnorderedList>
          {locations.map(({ location }) => (
            <ListItem key={location}>{location}</ListItem>
          ))}
        </UnorderedList>
      </>
    ) : (
      <>Location: {locations[0].location}</>
    );

  const triggerLocationLabel = isLeadTriggered
    ? "lead locations"
    : "click locations";

  return (
    <>
      <Card
        isLoading={isLoading}
        title={"Intent Signal Summary"}
        hasData={Boolean(accountOpportunity)}
      >
        {accountOpportunity && (
          <>
            <Flex gap={6} flexDirection="row">
              <SpotlightBlockSectionComponent
                header="Topics"
                borderColor="teal.200"
              >
                {Boolean(
                  accountOpportunity.topics.matchingLocations?.length
                ) && (
                  <>
                    <Text>
                      Top intent-identified content topics from{" "}
                      {triggerLocationLabel}:
                    </Text>
                    <UnorderedList>
                      {accountOpportunity.topics.matchingLocations?.map(
                        ({ topic, stage }) => (
                          <ListItem key={topic} mb={1}>
                            {topic} {stage ? `(${stage})` : ""}
                          </ListItem>
                        )
                      )}
                    </UnorderedList>
                  </>
                )}

                {Boolean(accountOpportunity.topics.topLocations.length) &&
                  Boolean(
                    accountOpportunity.topics.matchingLocations?.length
                  ) && <Divider borderColor="teal.200" my={2} />}

                {Boolean(accountOpportunity.topics.topLocations.length) && (
                  <>
                    <Text>Topics from trending locations:</Text>
                    <TopLocations
                      locations={accountOpportunity.topics.topLocations}
                      groupBy="topic"
                      renderItem={({ topic, stage }) =>
                        `${topic} ${stage ? ` (${stage as string})` : ""}`
                      }
                      renderTooltip={renderLocationsTooltip}
                    />
                  </>
                )}

                {!accountOpportunity.topics?.matchingLocations?.length &&
                  !accountOpportunity?.topics.topLocations.length && (
                    <Flex
                      height="100%"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Text align="center">
                        Topics not available for this domain
                      </Text>
                    </Flex>
                  )}
              </SpotlightBlockSectionComponent>

              <SpotlightBlockSectionComponent
                header="Keywords"
                borderColor="green.200"
              >
                {Boolean(
                  accountOpportunity?.keywords?.matchingLocations?.length
                ) && (
                  <>
                    <Text>
                      Top intent-identified content keywords from{" "}
                      {triggerLocationLabel}:
                    </Text>
                    <UnorderedList>
                      {accountOpportunity.keywords.matchingLocations?.map(
                        ({ keyword }) => (
                          <ListItem key={keyword} mb={1}>
                            {keyword}
                          </ListItem>
                        )
                      )}
                    </UnorderedList>
                  </>
                )}

                {Boolean(
                  accountOpportunity.keywords?.matchingLocations?.length
                ) &&
                  Boolean(accountOpportunity?.keywords.topLocations.length) && (
                    <Divider borderColor="green.200" my={2} />
                  )}

                {Boolean(accountOpportunity?.keywords?.topLocations.length) && (
                  <>
                    <Text>Keywords from trending locations:</Text>
                    <TopLocations
                      locations={accountOpportunity.keywords.topLocations}
                      groupBy="keyword"
                      renderTooltip={renderLocationsTooltip}
                    />
                  </>
                )}

                {!accountOpportunity.keywords?.matchingLocations?.length &&
                  !accountOpportunity?.keywords?.topLocations.length && (
                    <Flex
                      height="100%"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Text align="center">
                        Keywords not available for this domain
                      </Text>
                    </Flex>
                  )}
              </SpotlightBlockSectionComponent>

              {accountOpportunity?.socials &&
                Boolean(
                  accountOpportunity.socials.matchingLocations?.length ||
                    accountOpportunity.socials.topLocations?.length
                ) && (
                  <SpotlightBlockSectionComponent
                    header="Social"
                    borderColor="teal.200"
                  >
                    {Boolean(
                      accountOpportunity.socials.matchingLocations?.length
                    ) && (
                      <>
                        <Text>Social media activity:</Text>
                        <UnorderedList>
                          {accountOpportunity.socials.matchingLocations.map(
                            (activity) => (
                              <ListItem key={activity} mb={1}>
                                {activity}
                              </ListItem>
                            )
                          )}
                        </UnorderedList>
                      </>
                    )}

                    {Boolean(
                      accountOpportunity.socials.matchingLocations?.length &&
                        accountOpportunity.socials.topLocations?.length
                    ) && <Divider color="teal.200" />}

                    {accountOpportunity.socials.topLocations &&
                      Boolean(
                        accountOpportunity.socials.topLocations.length
                      ) && (
                        <>
                          <Text>Social media activity:</Text>
                          <TopLocations
                            locations={accountOpportunity.socials.topLocations}
                            groupBy="social"
                            renderTooltip={renderLocationsTooltip}
                          />
                        </>
                      )}
                  </SpotlightBlockSectionComponent>
                )}

              <Flex flexDirection="column" gap={2} flex={1}>
                <SpotlightBlockSectionComponent
                  header="Display Engagement"
                  borderColor="green.200"
                >
                  {accountOpportunity.displays ? (
                    <AccountOpportunityDisplay
                      displays={accountOpportunity.displays}
                    />
                  ) : (
                    <Flex
                      height="100%"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Text align="center">Not available for this domain</Text>
                    </Flex>
                  )}
                </SpotlightBlockSectionComponent>

                <SpotlightBlockSectionComponent
                  header="Site Visitors"
                  borderColor="teal.200"
                >
                  {accountOpportunity?.siteVisitors ? (
                    <>
                      {typeof accountOpportunity.siteVisitors.pageVisits ===
                        "number" && (
                        <Box>
                          <Text>
                            Site Visits:{" "}
                            {toNumberDisplayValue(
                              accountOpportunity.siteVisitors.pageVisits
                            )}
                          </Text>
                        </Box>
                      )}
                      {typeof accountOpportunity.siteVisitors.unique ===
                        "number" && (
                        <Box>
                          <Text>
                            Unique:{" "}
                            {toNumberDisplayValue(
                              accountOpportunity.siteVisitors.unique
                            )}
                          </Text>
                        </Box>
                      )}
                      {typeof accountOpportunity.siteVisitors.sessions ===
                        "number" && (
                        <Box>
                          <Text>
                            Sessions:{" "}
                            {toNumberDisplayValue(
                              accountOpportunity.siteVisitors.sessions
                            )}
                          </Text>
                        </Box>
                      )}
                    </>
                  ) : (
                    <Flex
                      height="100%"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Text align="center">
                        Stats not available for this domain
                      </Text>
                    </Flex>
                  )}
                </SpotlightBlockSectionComponent>
              </Flex>

              <SpotlightBlockSectionComponent
                header="Lead Engagement"
                borderColor="green.200"
              >
                {accountOpportunity.leadEngagements?.length ? (
                  <AccountOpportunityLeadEngagement
                    dividerColor="green.200"
                    leadEngagements={accountOpportunity.leadEngagements}
                  />
                ) : (
                  <Flex
                    height="100%"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Text align="center">
                      Leads not available for this domain
                    </Text>
                  </Flex>
                )}
              </SpotlightBlockSectionComponent>
            </Flex>
          </>
        )}
      </Card>
    </>
  );
};
