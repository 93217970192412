import { Option } from "@intentsify/types";
import { useQuery } from "@tanstack/react-query";
import {
  defaultListPaginatedCampaignsParams,
  listPaginatedCampaigns,
} from "api";
import { Dispatch, SetStateAction, useState } from "react";

type SelectedCampaign = Option<number> & {
  createdAt?: string;
};

/**
 * Used to manage state of the CampaignSelector. If there is no label (no campaign name, only campaign ID),
 * it will fetch it. It is possible to not have the campaign name, because the campaign list
 * is paginated. Sometimes, on initial render, we only know the campaign ID.
 */
export const useCampaignSelectorSelectedCampaign = (
  defaultCampaignId?: number
): {
  selected: SelectedCampaign | undefined;
  setSelected: Dispatch<SetStateAction<SelectedCampaign | undefined>>;
} => {
  const [selected, setSelected] = useState<SelectedCampaign | undefined>(
    defaultCampaignId
      ? {
          value: Number(defaultCampaignId),
          label: "",
        }
      : undefined
  );

  const needsToFetchCampaignName = Boolean(
    selected?.value && selected.label === ""
  );
  const campaignId = Number(selected?.value);

  useQuery(
    ["campaignForSelector", campaignId],
    () => {
      if (needsToFetchCampaignName && selected?.value) {
        return listPaginatedCampaigns({
          ...defaultListPaginatedCampaignsParams,
          search: String(selected.value),
        });
      }
    },
    {
      enabled: needsToFetchCampaignName,
      onSuccess: (data) => {
        const campaign = data?.campaigns.find(
          (i) => i.campaignId === selected?.value
        );

        if (campaign) {
          setSelected({
            value: campaignId,
            label: campaign.displayName,
            createdAt: campaign.createdAt,
          });
        }
      },
    }
  );

  if (!needsToFetchCampaignName) {
    return { selected, setSelected };
  }

  return {
    selected,
    setSelected,
  };
};
