import {
  Tabs as ChakraTabs,
  TabsProps as ChakraTabsProps,
  Flex,
  FlexboxProps,
  LayoutProps,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
} from "@chakra-ui/react";
import { ReactNode } from "react";

export type TabItem = {
  title: string;
  body: ReactNode;
};

type TabsProps = {
  items: TabItem[];
  variant?: ChakraTabsProps["variant"];
  colorScheme?: ChakraTabsProps["colorScheme"];
  defaultIndex?: ChakraTabsProps["defaultIndex"];
  onChange?: ChakraTabsProps["onChange"];
  isLazy?: ChakraTabsProps["isLazy"];
  isFitted?: ChakraTabsProps["isFitted"];
  strech?: boolean;
};

const Tabs = ({
  items,
  variant = "enclosed",
  colorScheme = "gray",
  defaultIndex = 0,
  onChange = undefined,
  isLazy = false,
  strech = false,
  isFitted = false,
}: TabsProps) => {
  const stretchTabsProps: LayoutProps & FlexboxProps = strech
    ? {
        display: "flex",
        flexDir: "column",
        flexGrow: 1,
        height: "100%",
      }
    : {};

  return (
    <>
      <ChakraTabs
        isLazy={isLazy}
        variant={variant}
        colorScheme={colorScheme}
        defaultIndex={defaultIndex}
        onChange={onChange}
        isFitted={isFitted}
        {...stretchTabsProps}
      >
        <Flex overflowX="auto" w="100%">
          <TabList w={strech ? "100%" : undefined} marginBottom={0}>
            {items.map(({ title }, i) => (
              <Tab key={i}>{title}</Tab>
            ))}
          </TabList>
        </Flex>
        <TabPanels {...stretchTabsProps}>
          {items.map(({ body }, i) => (
            <TabPanel {...stretchTabsProps} key={i}>
              {body}
            </TabPanel>
          ))}
        </TabPanels>
      </ChakraTabs>
    </>
  );
};

export { Tabs };
