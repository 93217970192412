import { CreateUserScreen } from "types";
import { createScreen } from "utils/createScreen";

const CreateUserScreenDefinition = createScreen({
  name: CreateUserScreen,
  path: "/admin/users/create",
  routePath: ["/create"],
  accessPath: "user.create",
});

export { CreateUserScreenDefinition };
