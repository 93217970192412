import { Firmographic, PaginatedResponse } from "@intentsify/types";
import { Endpoints, apiService } from "api";
import { PaginatedFirmographicsParams } from "./Firmographics.types";

export const listPaginatedFirmographics =
  () =>
  async (
    params: PaginatedFirmographicsParams
  ): Promise<PaginatedResponse<Firmographic>> => {
    const { filters, page, per_page, search, order, order_by } = params;

    const { data } = await apiService.post(
      Endpoints.Campaigns.Post.Firmographics.Paginated,
      {
        filters,
        page,
        per_page,
        search,
        order,
        order_by,
      }
    );
    return data;
  };
