import {
  Box,
  CircularProgress,
  Divider,
  Flex,
  SpaceProps,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { isDefined, toNumberDisplayValue } from "@intentsify/utils";
import { useQuery } from "@tanstack/react-query";
import { Card, MIN_MODAL_CONTENT_HEIGHT } from "components";
import { MdOutlineTvOff } from "react-icons/md";
import { DisplayPerformanceFilters } from "screens/CampaignPacing/screens/DisplayPerformance/DisplayPerformance";
import { colors } from "theme";
import { getOverallVideoViews } from "./OverallVideoViews.requests";
import { toDurationString } from "./OverallVideoViews.utils";

type OverallVideoViewsProps = {
  campaignId: string;
  filters: DisplayPerformanceFilters;
};

const NotAvailable = (spaceProps: SpaceProps) => <Box {...spaceProps}>n/a</Box>;

const OverallVideoViews = ({ campaignId, filters }: OverallVideoViewsProps) => {
  const { data, isFetching } = useQuery(
    ["overallVideoViews", campaignId, filters],
    () => getOverallVideoViews(campaignId, filters)
  );

  const hasData = data && Object.values(data).some((i) => isDefined(i));

  const textColor = useColorModeValue("gray", colors.gray[300]);

  return (
    <Card
      hasData={hasData}
      noDataIcon={MdOutlineTvOff}
      noDataMessage="This visualization is designated for campaigns with allocated video formats and creatives."
      isLoading={isFetching}
      title="Overall Video Views"
      isExpandable
    >
      {(isExpanded) => (
        <Flex
          h="100%"
          w="100%"
          minH={isExpanded ? MIN_MODAL_CONTENT_HEIGHT : undefined}
          flexDir="column"
        >
          <Flex
            grow={1}
            flexDir="row"
            justifyContent="space-evenly"
            alignItems="center"
          >
            <Flex flexDir="column" alignItems="center" grow={1}>
              <Text color={textColor}>Views</Text>
              <Flex minH="55px" alignItems="center" justifyContent="center">
                {data?.videoPlays ? (
                  <Text fontSize="3xl" fontWeight="bold">
                    {toNumberDisplayValue(data.videoPlays)}
                  </Text>
                ) : (
                  <NotAvailable />
                )}
              </Flex>
            </Flex>

            <Flex flexDir="column" alignItems="center" grow={1}>
              <Text color={textColor}>Avg % Watched</Text>
              <Flex minH="55px" alignItems="center" justifyContent="center">
                {(data?.averagePercentageWatched && (
                  <>
                    <CircularProgress
                      color="#6EB21F"
                      value={data.averagePercentageWatched * 100}
                      size={10}
                      thickness={16}
                      mr={2}
                    />
                    <Text fontSize="3xl" fontWeight="bold">
                      {toNumberDisplayValue(
                        data.averagePercentageWatched * 100
                      )}
                      %
                    </Text>
                  </>
                )) ?? <NotAvailable />}
              </Flex>
            </Flex>

            <Flex flexDir="column" alignItems="center" grow={1}>
              <Text color={textColor}>Overall Video Completion Rate</Text>
              <Flex minH="55px" alignItems="center" justifyContent="center">
                {(data?.averageVideoCompletionRatePercentage && (
                  <>
                    <CircularProgress
                      color="#6EB21F"
                      value={data.averageVideoCompletionRatePercentage * 100}
                      size={10}
                      thickness={16}
                      mr={2}
                    />
                    <Text fontSize="3xl" fontWeight="bold">
                      {toNumberDisplayValue(
                        data.averageVideoCompletionRatePercentage * 100
                      )}
                      %
                    </Text>
                  </>
                )) ?? <NotAvailable />}
              </Flex>
            </Flex>
          </Flex>

          <Divider orientation="horizontal" my={2} />

          <Flex grow={1} justifyContent="space-evenly" alignItems="center">
            <Flex alignItems="center" justifyContent="center">
              <Text color={textColor}>Avg Time Watched</Text>

              {data?.averageTimeWatchedSeconds ? (
                <Text mb={"1px"} fontWeight="bold" ml={2} fontSize="xl">
                  {toDurationString({
                    seconds: data.averageTimeWatchedSeconds,
                  })}
                </Text>
              ) : (
                <NotAvailable ml={2} />
              )}
            </Flex>

            <Divider orientation="vertical" mx={2} />

            <Flex alignItems="center" justifyContent="center">
              <Text color={textColor}>Total Time Watched</Text>

              {data?.totalTimeWatchedHours ? (
                <Text mb={"1px"} fontWeight="bold" ml={2} fontSize="xl">
                  {toDurationString({
                    seconds: data.totalTimeWatchedHours * 3600,
                  })}
                </Text>
              ) : (
                <NotAvailable ml={2} />
              )}
            </Flex>
          </Flex>
        </Flex>
      )}
    </Card>
  );
};

export { OverallVideoViews };
