import { ColumnDef } from "@tanstack/react-table";
import { ResultsTableEntry } from "./ResultsTable.data";

const columns: ColumnDef<ResultsTableEntry>[] = [
  {
    header: "Name",
    accessorKey: "name",
  },
  {
    header: "score",
    accessorKey: "score",
    cell: ({ row }) => {
      return Number(row.original?.score).toFixed(2);
    },
  },
];

export const useColumns = () => {
  return columns;
};
