import { Icon } from "@chakra-ui/react";
import {
  GrDocument,
  GrDocumentCsv,
  GrDocumentImage,
  GrDocumentPdf,
  GrDocumentTxt,
  GrDocumentWord,
} from "react-icons/gr";

const ExtensionIcon = ({
  extension: ex,
}: {
  extension: string | undefined;
}) => {
  switch (true) {
    case ex === "pdf":
      return <Icon as={GrDocumentPdf} color="red.400" />;
    case ex === "docx":
      return <Icon as={GrDocumentWord} color="blue.400" />;
    case ex === "csv":
      return <Icon as={GrDocumentCsv} color="blue.400" />;
    case ex === "txt":
    case ex === "xlsx":
    case ex === "xlxs":
      return <Icon as={GrDocumentTxt} color="gray.400" />;
    case ex === "png":
    case ex === "jpg":
    case ex === "jpeg":
    case ex === "gif":
      return <Icon as={GrDocumentImage} color="yellow.400" />;
    default:
      return <Icon as={GrDocument} color="gray.400" />;
  }
};

export { ExtensionIcon };
