import { WeeklySnapshotRangeDTO } from "@intentsify/dto";
import { useQuery } from "@tanstack/react-query";
import { getTopCreativePerformance } from "./TopCreativePerformance.requests";

export const useTopCreativePerformance = (
  rawCampaignId: number | string | undefined,
  { weekTo, yearTo, weekFrom, yearFrom }: WeeklySnapshotRangeDTO
) => {
  const campaignId = Number(rawCampaignId);
  const { data, isFetching } = useQuery(
    [
      "getTopCreativePerformance",
      campaignId,
      weekFrom,
      yearFrom,
      weekTo,
      yearTo,
    ],
    () =>
      getTopCreativePerformance(campaignId, {
        weekFrom,
        yearFrom,
        weekTo,
        yearTo,
      }),
    {
      enabled: !Number.isNaN(campaignId),
    }
  );
  return {
    topCreatives: data ?? [],
    isTopCreativesFetching: isFetching,
  };
};
