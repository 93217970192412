import { ProgrammaticDomainsRoot, ScreenRoot } from "types";

export const ProgrammaticDomainsRootDefinition: ScreenRoot = {
  name: ProgrammaticDomainsRoot,
  routePath: [
    "/programmatic-segments/*",
    "/programmatic-segments",
    "/programmatic-segments/:view",
  ],
  accessPath: "programmaticCampaign.programmaticDomain",
};
