import { LoginFormInputs } from "./LoginForm.types";
import { apiService, Endpoints } from "api";

export const signInRequest = async ({ email, password }: LoginFormInputs) => {
  const { data } = await apiService.post(Endpoints.Auth.Post.LogIn, {
    email: email.toLowerCase(),
    password,
  });

  return data;
};
