import { DateRangeFormat } from "@intentsify/types";
import { ReactNode } from "react";
import { ErrorBoundary } from "../../ErrorBoundary";
import { Bars } from "./Bars";
import { TimeseriesTooltipProps } from "./components/TimeseriesTooltip/TimeseriesTooltip.types";
import { Regular } from "./Regular";
import { TimeseriesItem, TimeseriesItemDataRecord } from "types";
import { Colour } from "theme";

export type TimeseriesProps<Keys extends string, DataKeys extends string> = {
  isLoading?: boolean;
  data: TimeseriesItem<Keys, TimeseriesItemDataRecord<DataKeys>>[];
  dataKeyLabels?: Record<DataKeys, string>;
  id?: string;
  exportMode?: true;
  title?: string;
  dataMainKey: Keys;
  timeseries: Array<{
    start: string;
    end: string;
  }>;
  disableLegend?: boolean;
  disableLegendTooltip?: boolean;
  disableDataSources?: boolean;
  legendItemActions?: (
    item: TimeseriesItem<Keys, TimeseriesItemDataRecord<DataKeys>>
  ) => ReactNode;
  legendPosition?: "bottom" | "right";
  dateFormat?: DateRangeFormat;
  margins?: { t: number; b: number; r: number; l: number };
  stretch?: boolean;
  noDataMessage?: string;
  pallete?: Colour[];
  shufflePallete?: boolean;
  includePalleteShades?: boolean;
  snapToDateTime?: boolean;
  fixedTooltip?: boolean;
  tooltipRenderer?: (
    props: TimeseriesTooltipProps<Keys, DataKeys>
  ) => ReactNode;
  /**
   * Whether to display two Y axis, for each
   * individual entry in `data`.
   *
   * It will fail silently if `data.length !== 2` and
   * revert to using just one axis.
   */
  useTwoYAxes?: boolean;
  secondYAxisDataKeyLabel?: string;
  scale?: "linear" | "log";
  variant?: "lines" | "bars";
  minHeight?: string;
  startDate?: Date;
  defaultTimeGap?: number; // this is required if startDate is passed
};

const Timeseries = <K extends string, T extends string>(
  props: TimeseriesProps<K, T>
) => {
  const variant = props.variant ?? "lines";
  const Component = variant === "lines" ? Regular : Bars;

  return (
    <ErrorBoundary>
      <Component {...props} />
    </ErrorBoundary>
  );
};

export { Timeseries };
