import { Box, Text } from "@chakra-ui/react";
import { Button, Modal } from "components";

type Dynamic1ModalProps = {
  isOpen: boolean;
  onOk: () => void;
};

export const Dynamic1Modal = ({ isOpen, onOk }: Dynamic1ModalProps) => {
  return (
    <Modal
      isOpen={isOpen}
      size="md"
      canClose={false}
      body={
        <Box p={2} mt={4} overflowY="auto">
          <Text>
            By selecting <Text as="b">Reprocess</Text> within AutoDiscovery or
            <Text as="b"> updating your TAL</Text>, your campaign will be added
            to the Dynamic1 queue to restart the D1 process. Current D1 results
            will be removed from the campaign details, and within 48 hours your
            new results will be calculated and available to view.
          </Text>
        </Box>
      }
      primaryButton={
        <Button variant="secondary" onClick={onOk}>
          OK
        </Button>
      }
    />
  );
};
