import { InfoIcon } from "@chakra-ui/icons";
import { Box, Center, Flex, Text } from "@chakra-ui/react";
import { ProgrammaticCampaignCreativeEngagementTesting } from "@intentsify/types";
import { decimalToPercentage } from "@intentsify/utils";
import { Creative, Tooltip } from "components";

const CreativesComparison = ({
  creatives,
}: {
  creatives: ProgrammaticCampaignCreativeEngagementTesting[];
}) => {
  return (
    <Flex>
      {creatives.slice(0, 2).map((creative, index) => (
        <Box flex={1} key={index} paddingX={4}>
          <Box marginY={4}>
            <Text align="center" fontSize="xs">
              Sample {index === 0 ? "A" : "B"}
            </Text>
          </Box>
          <Center marginY={4}>
            <Creative
              creativeUrl={creative.creativeUrl}
              showIframe={creative.showIframe}
            />
          </Center>
          <Box marginY={4} marginX="auto">
            {creative.creativeId !== "All" ? (
              <Text align="center" fontSize="xs">
                {creative.creativeName}
              </Text>
            ) : (
              <Tooltip
                aria-label={"Sample creative testing results"}
                label="This is sample creative from the set"
              >
                <Text align="center" fontSize="xs">
                  {creative.creativeName}
                  <InfoIcon w={3} h={3} ml={2} />
                </Text>
              </Tooltip>
            )}
          </Box>
          <Box marginY={4}>
            <Text align="center" fontSize="2xl" fontWeight="bold">
              {decimalToPercentage(creative.ctr)}%
            </Text>
            <Text align="center" fontSize="xs">
              CTR
            </Text>
          </Box>
        </Box>
      ))}
    </Flex>
  );
};

export { CreativesComparison };
