import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { Nullable } from "@intentsify/types";
import { ReactNode } from "react";
import { colors } from "theme";

type ListProps = {
  title: string;
  items: {
    label: Nullable<string>;
    value: Nullable<number | string>;
    valueFormatter?: (value: number) => string | ReactNode;
    valueTextColor?: string;
  }[];
};

const List = ({ title, items }: ListProps) => {
  const textColor = useColorModeValue("gray", colors.gray[300]);
  const firstDividerColor = useColorModeValue(
    colors.brand[100],
    "blackAlpha.500"
  );
  const dividerColor = useColorModeValue(colors.gray[100], "blackAlpha.300");

  return (
    <Flex flexDir="column" grow={1} my={2}>
      <Text mb={2} fontSize="larger" textAlign="center" fontWeight="semibold">
        {title}
      </Text>

      <Flex flexDir="column" grow={1}>
        {items.map((i, index) => {
          return (
            <Flex
              key={index}
              flexDir="column"
              justifyContent="space-around"
              grow={1}
            >
              <Box
                height="1px"
                mt={1}
                backgroundColor={index === 0 ? firstDividerColor : dividerColor}
              />
              <Flex px={2} minH="50px" alignItems="center">
                <Text
                  color={textColor}
                  w="50%"
                  textAlign="end"
                  pr={2}
                  lineHeight="1.2"
                >
                  {i.label}
                </Text>
                <Text
                  color={i.valueTextColor ? i.valueTextColor : undefined}
                  fontSize="2xl"
                  fontWeight="bold"
                  w="50%"
                  pl={2}
                  lineHeight="1"
                >
                  {i.valueFormatter && typeof i.value === "number"
                    ? i.valueFormatter(i.value)
                    : i.value}
                </Text>
              </Flex>
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
};

export { List };
