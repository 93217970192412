import { AxiosInstance } from "axios";

const IS_AUTH_REFRESHING = Symbol("IS_AUTH_REFRESHING");

export const isAuthRefreshing = (
  apiService: AxiosInstance | WindowOrWorkerGlobalScope["fetch"]
) => {
  return Reflect.get(apiService, IS_AUTH_REFRESHING) ?? false;
};

export const setIsAuthRefreshing = (
  apiService: AxiosInstance | WindowOrWorkerGlobalScope["fetch"],
  isAuthRefreshing: boolean
) => {
  return Reflect.set(apiService, IS_AUTH_REFRESHING, isAuthRefreshing);
};
