import { Box, Divider, Flex } from "@chakra-ui/layout";
import { TempAccountFileDTO } from "@intentsify/dto";
import { Button } from "components";
import { useCompanyNameToDomain } from "../hooks/useCompanyNameToDomain";

export const CompanyNameToDomainForm = ({
  selectedFile,
}: {
  selectedFile: TempAccountFileDTO | undefined;
}) => {
  const { mutateAsync: requestCompanyNameToDomain, isLoading: isSubmitting } =
    useCompanyNameToDomain();

  const onSubmit = () => {
    if (!selectedFile) {
      return;
    }

    requestCompanyNameToDomain({
      file: selectedFile,
    });
  };

  return (
    <Box as="form">
      <Divider mt={4} />

      <Flex mt={4} justifyContent="flex-end">
        <Button
          size="md"
          variant="primary-teal"
          isLoading={isSubmitting}
          isDisabled={!selectedFile}
          onClick={onSubmit}
        >
          Process Company Names
        </Button>
      </Flex>
    </Box>
  );
};
