const getTopTopics = (topics: string | undefined, numberOfTopics: number) => {
  const topicsStrings = typeof topics === "string" ? topics.split(",") : [];

  const topicsWithSignals = topicsStrings.reduce((acc, prev) => {
    const [topicName, signalsCount] = prev.split("_");
    if (topicName) {
      if (!acc[topicName]) {
        acc[topicName] = Number(signalsCount);
      } else {
        acc[topicName] = acc[topicName] + Number(signalsCount);
      }
    }
    return acc;
  }, {} as Record<string, number>);

  return Object.entries(topicsWithSignals)
    .sort(([, a], [, b]) => b - a)
    .slice(0, numberOfTopics)
    .map(([topicName]) => topicName);
};

export { getTopTopics };
