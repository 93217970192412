import { IconButton, useColorModeValue } from "@chakra-ui/react";
import { FiMinus, FiPlus } from "react-icons/fi";

type CollapseButtonProps = {
  isLeaf: boolean;
  expanded: boolean;
  onExpand: () => void;
};
const CollapseButton = ({
  isLeaf,
  expanded,
  onExpand,
}: CollapseButtonProps) => {
  const hoverBg = useColorModeValue("gray.100", "whiteAlpha.300");

  if (isLeaf) {
    return <span />;
  }

  return (
    <IconButton
      outline="false"
      variant="ghost"
      colorScheme="gray"
      size="sm"
      aria-label="toggle checkbox"
      title="Toggle"
      onClick={onExpand}
      _hover={{ bg: hoverBg }}
      icon={expanded ? <FiMinus /> : <FiPlus />}
    />
  );
};

export { CollapseButton };
