import {
  Flex,
  StylesProvider,
  useColorModeValue,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import { ControlProps, GroupBase } from "react-select";
import { Option as IntentsifyOption } from "@intentsify/types";

export const SimpleControl = <
  Option extends IntentsifyOption,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  innerProps,
  isFocused,
  isDisabled,
  innerRef,
  children,
  selectProps,
}: ControlProps<Option, IsMulti, Group>) => {
  const inputStyles = useMultiStyleConfig("Input", {});

  return (
    <StylesProvider value={inputStyles}>
      <Flex
        ref={innerRef}
        sx={{
          p: 0,
          overflow: "hidden",
          h: "auto",
          border: "1px solid",
          w: "200px",
          borderRadius: "md",
          borderColor: useColorModeValue("whiteAlpha.400", "whiteAlpha.300"),
          minH: 4,
          color: "white",
          fontWeight: "medium",
          _focus: {
            borderColor: "whiteAlpha.600",
            color: "white",
          },
          _active: {
            borderColor: "whiteAlpha.600",
            color: "white",
          },
          _hover: {
            borderColor: "whiteAlpha.600",
          },
        }}
        {...innerProps}
        {...(isFocused && { "data-focus": true })}
        {...(isDisabled && { disabled: true })}
        aria-invalid={selectProps["aria-invalid"]}
      >
        {children}
      </Flex>
    </StylesProvider>
  );
};
