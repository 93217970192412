import { SortingState } from "@tanstack/react-table";
import { atom, useRecoilValue, useSetRecoilState } from "recoil";

const searchState = atom({
  key: "summaryTableSearch",
  default: "",
});

const sortingState = atom<SortingState>({
  key: "summaryTableSorting",
  default: [],
});

export const useSetFilters = () => {
  const setSearch = useSetRecoilState(searchState);
  const setSorting = useSetRecoilState(sortingState);

  return { setSearch, setSorting };
};

export const useFilters = () => {
  const search = useRecoilValue(searchState);
  const sorting = useRecoilValue(sortingState);

  return { search, sorting };
};
