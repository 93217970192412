import { ArrowDownIcon, ArrowUpIcon, MinusIcon } from "@chakra-ui/icons";
import { Box } from "@chakra-ui/react";
import { WeeklySnapshotAccountBySpend } from "@intentsify/types";
import {
  decimalToPercentage,
  toCurrency,
  toNumberDisplayValue,
} from "@intentsify/utils";
import { ColumnDef } from "@tanstack/react-table";

export const getColumns = (): ColumnDef<WeeklySnapshotAccountBySpend>[] => {
  return [
    {
      header: "Account",
      accessorKey: "domain",
    },
    {
      header: "Spend",
      accessorKey: "spend",
      accessorFn: (row) => {
        return toCurrency(Number(row.spend));
      },
    },
    {
      header: "Impressions",
      accessorKey: "count_impressions",
      accessorFn: (row) => {
        return toNumberDisplayValue(Number(row.count_impressions));
      },
    },
    {
      header: "Clicks",
      accessorKey: "count_clicks",
      accessorFn: (row) => {
        return toNumberDisplayValue(Number(row.count_clicks));
      },
    },
    {
      header: "CTR",
      accessorKey: "ctr",
      cell: (info) => {
        const trend = Number(info.row.original?.ctr_vs_previous);
        return (
          <Box>
            {toNumberDisplayValue(
              decimalToPercentage(Number(info.row.original?.ctr) ?? 0),
              { digits: 2 }
            )}
            %
            {!isNaN(trend) && (
              <span style={{ marginLeft: "5px" }}>
                {trend > 0 && <ArrowUpIcon color="green.400" />}
                {trend === 0 && <MinusIcon color="blackAlpha.600" />}
                {trend < 0 && <ArrowDownIcon color="red.400" />}
              </span>
            )}
          </Box>
        );
      },
    },
    {
      header: "leads",
      accessorKey: "count_leads",
    },
  ];
};
