import { CampaignForList } from "@intentsify/types";
import { isDefined } from "@intentsify/utils";
import { useQuery } from "@tanstack/react-query";
import { defaultListPaginatedCampaignsParams } from "api";
import { Actions, Search, Table } from "components";
import { PaginationType } from "components/Table/Table/Table.types";
import { useCallback, useState } from "react";
import { FaUnlink } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { useCampaignsTableColumns } from "screens/Campaigns/screens/YourCampaigns/CampaignsTable/campaignsTable.columns";
import { useTrackSearched } from "tracking/useTrackSearched";
import { ActionItems, FetchDataParamsWithToken } from "types";
import { listPublisherCampaigns } from "../../../Publishers/Publishers.requests";
import { useUnassignCampaignMutation } from "../../SinglePublisher.hook";

const RowActions = ({ campaignId }: { campaignId: number }) => {
  const { id: publisherId } = useParams<"id">();

  if (!publisherId) {
    throw Error("404");
  }

  const { unassignCampaignMutation } = useUnassignCampaignMutation(
    publisherId,
    campaignId
  );

  const items: ActionItems[] = [
    {
      label: "Unassign Campaign",
      accessPath: "publisher",
      icon: <FaUnlink />,
      onClick: () => unassignCampaignMutation.mutate(campaignId),
      isLoading: unassignCampaignMutation.isLoading,
    },
  ];

  return <Actions items={items} />;
};

const PublisherCampaignsTable = () => {
  const { id: publisherId } = useParams<"id">();
  const campaignsTableColumns = useCampaignsTableColumns(RowActions);
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);

  if (!publisherId) {
    throw new Error("404");
  }

  const [fetchDataParams, setFetchDataParams] = useState<
    FetchDataParamsWithToken<keyof CampaignForList>
  >(defaultListPaginatedCampaignsParams);

  const { data, isFetching } = useQuery(
    ["publisherCampaignsList", fetchDataParams],
    () => listPublisherCampaigns(publisherId, fetchDataParams)
  );

  const handleOnFetchDataChange = useCallback(
    (params: FetchDataParamsWithToken<keyof CampaignForList>) => {
      setFetchDataParams(params);
    },
    []
  );

  const trackSearched = useTrackSearched();

  return (
    <>
      <Search
        currentValue={searchValue}
        onSearch={(v) => {
          setSearchValue(v);
          trackSearched({ term: v, collocation: "Publisher campaigns table" });
        }}
      />
      <Table
        variant="intentsifyNew"
        searchValue={searchValue}
        defaultSort={[{ id: "campaignId", desc: true }]}
        columns={campaignsTableColumns.filter(isDefined)}
        isFetching={isFetching}
        data={data?.campaigns || []}
        onFetchDataChange={handleOnFetchDataChange}
        resetPaginationDependencies={[searchValue]}
        paginationType={PaginationType.TOKEN}
        nextPageToken={data?.nextPageToken}
      />
    </>
  );
};

export { PublisherCampaignsTable };
