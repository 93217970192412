import { Stack, Text } from "@chakra-ui/react";
import { Option } from "@intentsify/types";
import escapeRegExp from "lodash/escapeRegExp";
import { useEffect, useMemo, useState } from "react";
import { useComponentColors } from "theme";
import { useDeepEffect } from "utils";
import { Select } from "../Select";

type FilterProps<V extends string | number, M> = {
  options: Option<V, M>[];
  isLoading: boolean;
  isDisabled?: boolean;
  label?: string;
  placeholder?: string;
  onFilterValuesChange: (values: Option<V, M>[]) => void;
  selected?: Option<V, M>[];
  minW?: string;
  maxW?: string;
  isClearable?: boolean;
  isMulti?: boolean;
};

const Filter = <V extends string | number, M>({
  options,
  isLoading,
  label,
  placeholder = "Select",
  onFilterValuesChange,
  selected,
  minW,
  maxW,
  isDisabled = false,
  isClearable = false,
}: FilterProps<V, M>) => {
  const componentColors = useComponentColors();
  const [inputValue, setInputValue] = useState("");
  const [selectedValues, setSelectedValues] = useState<Option<V, M>[]>(
    selected ?? []
  );

  useEffect(() => {
    if (selected) {
      setSelectedValues(selected);
    }
  }, [selected]);

  const filteredOptions = useMemo(() => {
    if (!inputValue) {
      return options;
    }

    const matchByStart = [];
    const matchByInclusion = [];

    const regByInclusion = new RegExp(escapeRegExp(inputValue), "i");
    const regByStart = new RegExp(`^${escapeRegExp(inputValue)}`, "i");

    for (const option of options) {
      if (regByInclusion.test(option.label)) {
        if (regByStart.test(option.label)) {
          matchByStart.push(option);
        } else {
          matchByInclusion.push(option);
        }
      }
    }

    return [...matchByStart, ...matchByInclusion];
  }, [inputValue, options]);

  const handleChange = (option: Option<V, M>[]) => {
    setSelectedValues(option);
    onFilterValuesChange(option);
  };

  useDeepEffect(() => {
    onFilterValuesChange(selectedValues);
  }, [selectedValues]);

  return (
    <Stack minW={minW} maxW={maxW}>
      {label && (
        <Text
          p="0"
          m="0"
          fontSize="sm"
          fontWeight="md"
          color={componentColors.form.formLabelColor}
        >
          {label}:
        </Text>
      )}
      <Select
        isClearable={isClearable}
        isDisabled={isDisabled}
        options={filteredOptions}
        onInputChange={(value: string) => setInputValue(value)}
        filterOption={() => true} // disable native filter
        isLoading={isLoading}
        isMulti={true}
        placeholder={placeholder}
        value={selectedValues}
        onChange={(option) => handleChange(option)}
      />
    </Stack>
  );
};

export { Filter };
