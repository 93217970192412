import { BusinessEvent, SignalType } from "@intentsify/types";
import { SignalsTable } from "../SignalsTable";
import { useColumns } from "./BusinessEvents.columns";

const BusinessEvents = ({
  campaignId,
  weekNumber,
  yearNumber,
  domainId,
}: {
  campaignId: number;
  domainId: number;
  weekNumber: number;
  yearNumber: number;
}) => {
  const columns = useColumns();

  return (
    <>
      <SignalsTable<BusinessEvent>
        columns={columns}
        defaultSort={[
          {
            id: "datePublished",
            desc: true,
          },
        ]}
        campaignId={campaignId}
        domainId={domainId}
        signalId={SignalType.BusinessEvent}
        weekNumber={weekNumber}
        yearNumber={yearNumber}
      />
    </>
  );
};

export { BusinessEvents };
