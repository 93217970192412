import { ProgrammaticCampaignCreativeEngagementTesting } from "@intentsify/types";
import { useQuery } from "@tanstack/react-query";
import { Card, Tabs } from "components";
import sortBy from "lodash/sortBy";
import { useMemo } from "react";
import { FaBalanceScaleLeft } from "react-icons/fa";
import { DisplayPerformanceFilters } from "screens";
import { getProgrammaticCampaignEngagementTestingResults } from "./EngagementTestingResults.requests";
import { CreativesComparison } from "./components";

type Props = {
  campaignId: string;
  filters: DisplayPerformanceFilters;
};

const EngagementTestingResults = ({ campaignId, filters }: Props) => {
  const { data, isFetching } = useQuery(
    ["EngagementTestingResults", campaignId, filters],
    () => getProgrammaticCampaignEngagementTestingResults(campaignId, filters)
  );

  const resultsGroupedBySize = useMemo(() => {
    return (
      sortBy(data?.creativeEngagementTesting || [], [
        (creative) => creative.creativeSize,
        (creative) => creative.testGroup,
      ]).reduce((acc, prev) => {
        const size = prev.creativeSize;
        if (!acc[size]) {
          acc[size] = [prev];
        } else {
          acc[size].push(prev);
        }
        return acc;
      }, {} as Record<string, ProgrammaticCampaignCreativeEngagementTesting[]>) ||
      {}
    );
  }, [data?.creativeEngagementTesting]);

  const firstCreativeSet = Object.values(resultsGroupedBySize)[0];

  return (
    <Card
      noDataIcon={FaBalanceScaleLeft}
      noDataMessage="This visualization is designated for campaigns that have creative a/b or messaging experiments."
      hasData={Object.keys(resultsGroupedBySize).length > 0}
      title={"Testing Results"}
      isLoading={isFetching}
      isExpandable
    >
      <Tabs
        variant="enclosed-colored"
        isFitted={true}
        items={Object.keys(resultsGroupedBySize).map((key) => {
          /* for aggregated result (for both creatives sets) we want to display one of the creatives instead of placeholder */
          const creatives =
            key !== "All"
              ? resultsGroupedBySize[key]
              : resultsGroupedBySize["All"].map((el, index) => ({
                  ...el,
                  creativeName: firstCreativeSet[index].creativeName,
                  creativeUrl: firstCreativeSet[index].creativeUrl,
                  showIframe: firstCreativeSet[index].showIframe,
                }));

          return {
            title: key,
            body: <CreativesComparison creatives={creatives} />,
          };
        })}
      />
    </Card>
  );
};

export { EngagementTestingResults };
