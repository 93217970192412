import { Box, Text } from "@chakra-ui/react";
import { AuthenticatedUser } from "@intentsify/types";
import { useMutation } from "@tanstack/react-query";
import { Button } from "components";
import { Navigate } from "react-router-dom";
import { YourCampaignsScreenDefinition } from "screens";
import { useUser } from "store/store.hooks";
import { useAppStore } from "store/useAppStore";
import { LoginScreenDefinition } from "../Login/Login.definition";
import { updateAgreement } from "./Agreement.requests";
import { agreementTextBlocks } from "./Agreement.text";
import { AgreementContainer } from "./AgreementContainer";

const Agreement = () => {
  const user = useUser();
  const setUser = useAppStore.use.setUser();

  const { mutate, isLoading } = useMutation(updateAgreement, {
    onSuccess: () => {
      setUser({
        ...user,
        agreement: true,
        isActive: true,
      } as AuthenticatedUser);
    },
  });

  const onSubmit = () => {
    mutate({
      userId: user?.userId as number,
      agreement: true,
    });
  };

  if (!user) {
    return <Navigate to={LoginScreenDefinition.navigate()} />;
  }

  if (user.isActive) {
    return <Navigate to={YourCampaignsScreenDefinition.navigate()} />;
  }

  return (
    <AgreementContainer>
      <Text fontSize="2xl" marginY="8" textAlign="center" color="white">
        Before you continue to application
      </Text>

      <Box
        maxHeight="container.sm"
        paddingX="8"
        color="gray.100"
        overflowY="auto"
      >
        <Text fontSize="md" marginBottom="2">
          In consideration of the mutual promises and covenants contained in
          this Agreement, Intentsify, LLC (“Intentsify”) and Customer each agree
          as follows:
        </Text>

        {agreementTextBlocks.map((textBlock, index) => (
          <Text
            key={index}
            fontSize="small"
            marginTop="0.5"
            lineHeight="tall"
            whiteSpace="pre-wrap"
          >
            {textBlock}
          </Text>
        ))}
      </Box>

      <Box display="flex" justifyContent="flex-end" padding="8">
        <Button
          size="md"
          variant="primary"
          isLoading={isLoading}
          isDisabled={isLoading}
          onClick={onSubmit}
        >
          I Agree
        </Button>
      </Box>
    </AgreementContainer>
  );
};

export { Agreement };
