import { useToken } from "@chakra-ui/system";
import { LayerProps } from "react-map-gl";
import { LayerVisibility } from "../ImpressionsAndTopicsMap.types";

export const useTopicsClusterLayerProps = (
  visibility: LayerVisibility
): LayerProps => {
  return {
    layout: {
      visibility: visibility,
    },
    type: "circle",
    filter: ["has", "point_count"],
    paint: {
      "circle-color": useToken("colors", "orange.300"),
      "circle-opacity": 0.7,
      "circle-radius": [
        "step",
        ["get", "point_count"],
        10,
        25,
        8,
        100,
        12,
        500,
        16,
        1000,
        20,
      ],
    },
  };
};
