import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  HStack,
  Heading,
  Image,
  Link,
  List,
  ListItem,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Nullable, SignalType, TopUrl } from "@intentsify/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useComponentColors } from "theme";
import { TopUrlPopover } from "./TopUrlPopover";
import { excludeDynamic1Article } from "./TopUrls.requests";

type TopUrlsProps = {
  domainId: number;
  campaignId: number;
  topUrls: TopUrl[];
  hasAdminAccess: boolean;
};

const TopUrls = ({
  topUrls,
  hasAdminAccess,
  campaignId,
  domainId,
}: TopUrlsProps) => {
  const componentColors = useComponentColors();
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(excludeDynamic1Article, {
    onSuccess: (data, variables) => {
      return queryClient.invalidateQueries([
        `campaignDetailsAggregatedSignals`,
        campaignId,
        domainId,
        SignalType.Dynamic1,
        variables.weekNumber,
        variables.yearNumber,
      ]);
    },
  });

  if (topUrls.length === 0) {
    return null;
  }

  const handleExclude = (
    campaignId: number,
    domainId: number,
    url: string,
    logoUrl: string,
    score: number,
    exclude: boolean,
    weekNumber: number,
    yearNumber: number,
    runningWeek: number,
    exclusionReason: Nullable<string> = null
  ) => {
    mutate({
      campaignId,
      domainId,
      url,
      logoUrl,
      score,
      exclude,
      weekNumber,
      yearNumber,
      runningWeek,
      exclusionReason: exclude === true ? exclusionReason : null,
    });
  };

  return topUrls.length > 0 ? (
    <Stack
      borderTopRadius={4}
      shadow="base"
      borderRadius={4}
      p={4}
      bg={componentColors.form.stackBg}
    >
      <Heading as="h4" size="md" pb="4">
        Top Urls
      </Heading>
      <List spacing={3}>
        {topUrls.map((topUrl, index) => (
          <ListItem key={index} p="2">
            <HStack>
              <Link href={topUrl.url} isExternal>
                <HStack>
                  <Image
                    maxHeight={"32px"}
                    maxWidth={"32px"}
                    src={topUrl.logourl}
                    mr={2}
                  />
                  <Text>{topUrl.title}</Text>
                  <ExternalLinkIcon />
                </HStack>
              </Link>
              {hasAdminAccess && (
                <TopUrlPopover
                  disabled={isLoading}
                  topUrl={topUrl}
                  campaignId={campaignId}
                  domainId={domainId}
                  handleExclude={handleExclude}
                />
              )}
            </HStack>
          </ListItem>
        ))}
      </List>
    </Stack>
  ) : null;
};

export { TopUrls };
