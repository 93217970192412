import {
  MutationOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { useToast } from "@chakra-ui/react";
import { CreateCompanyFormSchema } from "./CreateCompanyFormSchema";
import { Endpoints, apiService } from "api";
import { valueToQueryURL } from "utils";

const createCompany = async ({
  businessUnits,
  ...restData
}: CreateCompanyFormSchema) => {
  await apiService.post(Endpoints.Companies.Post.CreateCompany, {
    ...restData,
    businessUnits: businessUnits
      ? valueToQueryURL(businessUnits.split("\n"))
      : undefined,
  });
};

type UseCreateCompnayMutationOptions = MutationOptions<
  void,
  unknown,
  CreateCompanyFormSchema,
  unknown
>;

export const useCreateCompany = (
  mutationOptions?: UseCreateCompnayMutationOptions
) => {
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation({
    ...mutationOptions,
    mutationFn: createCompany,
    onSuccess: (...args) => {
      queryClient.invalidateQueries({
        queryKey: ["companiesList"],
        exact: false,
      });
      toast({
        title: "Company created successfully.",
        status: "success",
      });
      mutationOptions?.onSuccess?.(...args);
    },
  });
};
