import { Flex } from "@chakra-ui/layout";
import { CampaignTopic, SignalSelectionSource } from "@intentsify/types";
import { isDefined } from "@intentsify/utils";
import { TableVirtualised } from "components";
import difference from "lodash/difference";
import differenceWith from "lodash/differenceWith";
import uniqBy from "lodash/uniqBy";
import { useMemo } from "react";
import { useKeywordsAndTopicsState } from "screens/Campaigns/screens/CampaignsWizard/components/KeywordsAndTopicsStep/KeywordsAndTopics.hooks";
import { KeywordItem, SelectionMode } from "types/Keyword";
import { useColumns } from "./ResultsTable.columns";
import { ResultsTableEntry, useData } from "./ResultsTable.data";
import { ResultsTableTab, useTabs } from "./ResultsTable.tabs";
import { useRowSelection } from "./useRowSelection/useRowSelection";
import { useSyncSelectionFromRecoil } from "./useRowSelection/useSyncSelectionFromRecoil";

interface ResultsTableProps {
  search?: string;
}

export const ResultsTable = (props: ResultsTableProps) => {
  const { state, actions } = useKeywordsAndTopicsState();
  const [Tabs, selectedTab] = useTabs();
  const data = useData({ tab: selectedTab, search: props.search });
  const columns = useColumns();
  const [keywordRowSelection, setKeywordRowSelection] = useRowSelection();
  const [topicRowSelection, setTopicRowSelection] = useRowSelection();
  const [productRowSelection, setProductRowSelection] = useRowSelection();

  const rowSelection = useMemo(() => {
    if (selectedTab === ResultsTableTab.Keywords) {
      return keywordRowSelection;
    }

    if (selectedTab === ResultsTableTab.Topics) {
      return topicRowSelection;
    }

    if (selectedTab === ResultsTableTab.Products) {
      return productRowSelection;
    }

    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    throw new Error(`Unknown tab: ${selectedTab}`);
  }, [
    keywordRowSelection,
    productRowSelection,
    selectedTab,
    topicRowSelection,
  ]);

  const setRowSelection = useMemo(() => {
    if (selectedTab === ResultsTableTab.Keywords) {
      return setKeywordRowSelection;
    }

    if (selectedTab === ResultsTableTab.Topics) {
      return setTopicRowSelection;
    }

    if (selectedTab === ResultsTableTab.Products) {
      return setProductRowSelection;
    }

    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    throw new Error(`Unknown tab: ${selectedTab}`);
  }, [
    selectedTab,
    setKeywordRowSelection,
    setProductRowSelection,
    setTopicRowSelection,
  ]);

  useSyncSelectionFromRecoil({
    tab: selectedTab,
    onSync: setRowSelection,
  });

  return (
    <Flex width="100%">
      <Tabs w="200px" variant="corporate" orientation="vertical" />

      <TableVirtualised<ResultsTableEntry>
        selectable="checkbox-multi-select"
        clearSelectionOnSort={false}
        data={data}
        columns={columns}
        rowSelection={rowSelection}
        setRowSelection={(rowSelectionStateUpdater) => {
          const nextRowSelectionState =
            typeof rowSelectionStateUpdater === "function"
              ? rowSelectionStateUpdater(rowSelection)
              : rowSelectionStateUpdater;

          if (selectedTab === ResultsTableTab.Keywords) {
            const selectedState = Object.keys(nextRowSelectionState).map(
              (rowIndex) => {
                const entry =
                  state.autodiscoverySettings.autodiscoveryKeywords[
                    rowIndex as unknown as number
                  ];

                return entry;
              }
            );

            const alreadySelectedKeywords = state.selectedKeywords.filter(
              (k) => k.metaData?.source === SignalSelectionSource.Autodiscovery
            );

            const nextKeywords = selectedState.map((s) => ({
              value: s.keywordId,
              label: s.keyword,
              metaData: { source: SignalSelectionSource.Autodiscovery },
            })) as KeywordItem[];

            if (alreadySelectedKeywords.length > nextKeywords.length) {
              actions.setSelectedKeywords(
                differenceWith(
                  alreadySelectedKeywords,
                  nextKeywords,
                  (a, b) => a.label === b.label
                ),
                SelectionMode.UNSELECT
              );
            }

            if (alreadySelectedKeywords.length < nextKeywords.length) {
              actions.setSelectedKeywords(
                uniqBy(nextKeywords, (k) => k.label),
                SelectionMode.SELECT
              );
            }
          }

          if (selectedTab === ResultsTableTab.Topics) {
            const shouldDeselect =
              Object.keys(rowSelection).length >
              Object.keys(nextRowSelectionState).length;

            const indicesToDeselect = difference(
              Object.keys(rowSelection),
              Object.keys(nextRowSelectionState)
            ).map((i) => Number(i));

            const selectedState = Object.keys(nextRowSelectionState)
              .map((rowIndex) => {
                const entry =
                  state.autodiscoverySettings.autodiscoveryTopics[
                    Number(rowIndex)
                  ];

                if (
                  indicesToDeselect.includes(Number(rowIndex)) &&
                  shouldDeselect
                ) {
                  return;
                }

                return entry;
              })
              .filter(isDefined);

            const alreadySelectedTopics = state.selectedTopics.filter(
              (t) => t.source === SignalSelectionSource.Autodiscovery
            );

            const nextTopics = selectedState.map((s) => ({
              source: SignalSelectionSource.Autodiscovery,
              topic: s.topic,
              topicId: s.topicId,
            })) as CampaignTopic[];

            if (shouldDeselect) {
              if (nextTopics.length === 0) {
                //If nextTopics is an empty array it means that we are unselecting
                // either all selected items, or there was one selected, and we are unselecting it.
                actions.setSelectedTopics(
                  state.autodiscoverySettings.autodiscoveryTopics.map((t) => ({
                    topicId: t.topicId,
                    topic: t.topic,
                    source: SignalSelectionSource.Autodiscovery,
                  })),
                  SelectionMode.UNSELECT
                );
              } else {
                actions.setSelectedTopics(
                  differenceWith(
                    alreadySelectedTopics,
                    nextTopics,
                    (a, b) => a.topicId === b.topicId
                  ),
                  SelectionMode.UNSELECT
                );
              }
            } else {
              actions.setSelectedTopics(
                uniqBy(
                  [...nextTopics, ...alreadySelectedTopics],
                  (k) => k.topic
                ),
                SelectionMode.SELECT
              );
            }
          }

          if (selectedTab === ResultsTableTab.Products) {
            const shouldDeselect =
              Object.keys(rowSelection).length >
              Object.keys(nextRowSelectionState).length;

            const indicesToDeselect = difference(
              Object.keys(rowSelection),
              Object.keys(nextRowSelectionState)
            ).map((i) => Number(i));

            const selectedState = Object.keys(nextRowSelectionState)
              .map((rowIndex) => {
                const entry =
                  state.autodiscoverySettings.autodiscoveryProducts[
                    Number(rowIndex)
                  ];

                if (
                  indicesToDeselect.includes(Number(rowIndex)) &&
                  shouldDeselect
                ) {
                  return;
                }

                return entry;
              })
              .filter(isDefined);

            const alreadySelectedTopics = state.selectedTopics.filter(
              (k) => k.source === SignalSelectionSource.Autodiscovery
            );

            const nextTopics = selectedState.map((s) => ({
              source: SignalSelectionSource.Autodiscovery,
              topic: s.topic,
              topicId: s.topicId,
            })) as CampaignTopic[];

            if (shouldDeselect) {
              if (nextTopics.length === 0) {
                //If nextTopics is an empty array it means that we are unselecting
                // either all selected items, or there was one selected, and we are unselecting it.
                actions.setSelectedTopics(
                  state.autodiscoverySettings.autodiscoveryProducts.map(
                    (t) => ({
                      topicId: t.topicId,
                      topic: t.topic,
                      source: SignalSelectionSource.Autodiscovery,
                    })
                  ),
                  SelectionMode.UNSELECT
                );
              } else {
                actions.setSelectedTopics(
                  differenceWith(
                    alreadySelectedTopics,
                    nextTopics,
                    (a, b) => a.topicId === b.topicId
                  ),
                  SelectionMode.UNSELECT
                );
              }
            } else {
              actions.setSelectedTopics(
                uniqBy(
                  [...nextTopics, ...alreadySelectedTopics],
                  (k) => k.topic
                ),
                SelectionMode.SELECT
              );
            }
          }

          return setRowSelection(nextRowSelectionState);
        }}
        containerProps={{ width: "100%", height: "500px" }}
      />
    </Flex>
  );
};
