import { ListCampaignsRO } from "@intentsify/dto";
import { AxiosResponse } from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  apiService,
  defaultListPaginatedCampaignsParams,
  Endpoints,
} from "api";

export const useUnassignCampaignMutation = (
  publisherId: string,
  campaignId: number
) => {
  const queryClient = useQueryClient();

  const fetchDataParams = {
    ...defaultListPaginatedCampaignsParams,
    search: campaignId,
  };

  const unassignCampaignMutation = useMutation<
    AxiosResponse<ListCampaignsRO[]>,
    unknown,
    number
  >(
    (campaignId) =>
      apiService.delete(
        Endpoints.Publishers.Delete.UnassignCampaign(publisherId, campaignId)
      ),
    {
      onSuccess: () => {
        queryClient.setQueryData(["campaignAssignmentState", fetchDataParams], {
          data: { results: [] },
        });
        queryClient.invalidateQueries({ queryKey: ["publisherCampaignsList"] });
      },
    }
  );

  return { unassignCampaignMutation };
};
