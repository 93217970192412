import { Flex, Text as ChakraText, useColorMode } from "@chakra-ui/react";
import {
  isDefined,
  isPopulatedArray,
  toNumberDisplayValue,
} from "@intentsify/utils";
import { ErrorBoundary } from "@sentry/react";
import { localPoint } from "@visx/event";
import { Group } from "@visx/group";
import { ParentSize } from "@visx/responsive";
import Pie, { PieArcDatum, ProvidedProps } from "@visx/shape/lib/shapes/Pie";
import { Text } from "@visx/text";
import { useTooltip, useTooltipInPortal } from "@visx/tooltip";
import { useMemo, useState } from "react";
import { animated, to, useTransition } from "react-spring";
import { useMeasure } from "react-use";
import { useRecoilValue } from "recoil";
import { BasicTooltip } from "../BasicTooltip";
import {
  EXPORT_LEGEND_WIDTH,
  EXPORT_TITLE_HEIGHT,
  MIN_HEIGHT,
} from "../Charts.const";
import { getWrapperDirection } from "../Charts.utils";
import { Legend } from "./Legend";
import { disabledLegendItemsFamily } from "./PieChart.state";
import { PieChartItem } from "./PieChart.types";
import { getPieColors, PieColor } from "./PieChart.utils";
import { useGetPieChartData } from "./PieChart.hooks";
import { useChartColors } from "utils";
import { NoData } from "components/NoData";
import { Loader } from "components/Loader";

export type PieChartProps = {
  isLoading?: boolean;
  margins?: { t: number; b: number; r: number; l: number };
  stretch?: boolean;
  fixedRatio?: [width: number, height: number];
  noDataMessage?: string;
  data: PieChartItem[];
  legendPosition?: "bottom" | "right";
  id?: string;
  exportMode?: true;
  title?: string;
  minHeight?: string;
  shouldSort?: boolean;
  displayAsPercent?: boolean;
};

type AnimatedStyles = { startAngle: number; endAngle: number; opacity: number };

const fromLeaveTransition = ({ endAngle }: PieArcDatum<any>) => ({
  // enter from 360° if end angle is > 180°
  startAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  endAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
  opacity: 0,
});
const enterUpdateTransition = ({ startAngle, endAngle }: PieArcDatum<any>) => ({
  startAngle,
  endAngle,
  opacity: 1,
});

const calculateHeightBasedOnRatio = (
  width: number,
  height: number,
  ratio?: [widthRatio: number, heightRatio: number]
): number => {
  if (!ratio) {
    return height;
  }
  const [widthRatio, heightRatio] = ratio;
  return Math.round((width / widthRatio) * heightRatio);
};

const PieChart = ({
  isLoading,
  stretch,
  fixedRatio,
  noDataMessage,
  data,
  id,
  title,
  margins = {
    t: 30,
    b: 30,
    r: 50,
    l: 50,
  },
  legendPosition = undefined,
  exportMode = undefined,
  minHeight = MIN_HEIGHT,
  shouldSort = true,
  displayAsPercent = false,
}: PieChartProps) => {
  const [uniqueKey] = useState(Math.random().toString());

  const [ref, { width: containerWidth }] = useMeasure<HTMLDivElement>();
  const { colorMode } = useColorMode();
  const { labelColor } = useChartColors();

  const colors = useMemo(() => {
    return getPieColors(colorMode);
  }, [colorMode]);

  const disabledLegendItems = useRecoilValue(
    disabledLegendItemsFamily(uniqueKey)
  );

  const pieData = useGetPieChartData(
    data,
    disabledLegendItems,
    displayAsPercent
  );

  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip<PieChartItem>();

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    detectBounds: true,
    scroll: true,
  });

  if (isLoading) {
    return <Loader minHeight={minHeight} stretch={stretch} />;
  }

  if (!isPopulatedArray(data)) {
    return (
      <NoData message={noDataMessage} height={minHeight} stretch={stretch} />
    );
  }

  const legendPositionComputed = legendPosition
    ? legendPosition
    : containerWidth > 1200
    ? "right"
    : "bottom";

  return (
    <ErrorBoundary>
      <Flex ref={ref} width="100%" height="100%">
        <Flex
          flexDirection={getWrapperDirection(legendPositionComputed)}
          width="100%"
        >
          <Flex
            width="100%"
            flexGrow={stretch ? 1 : 0}
            minHeight={minHeight}
            overflow="hidden"
          >
            <ParentSize>
              {(parent) => {
                const { width, height } = parent;

                if (!width || !height) {
                  return null;
                }

                const ratioBasedHeight = calculateHeightBasedOnRatio(
                  width,
                  height,
                  fixedRatio
                );
                const baseInnerWidth = width - margins.l - margins.r;
                const baseInnerHeight =
                  ratioBasedHeight - margins.t - margins.b;

                const innerWidth = !exportMode
                  ? baseInnerWidth
                  : baseInnerWidth - EXPORT_LEGEND_WIDTH - 50;

                const innerHeight = !exportMode
                  ? baseInnerHeight
                  : baseInnerHeight - EXPORT_TITLE_HEIGHT;

                const radius = Math.min(innerWidth, innerHeight) / 2;
                const centerX = innerWidth / 2;
                const top = margins.t + radius;
                const left = centerX + margins.l;

                return (
                  <svg
                    width="100%"
                    height={ratioBasedHeight}
                    ref={containerRef}
                    id={id}
                    style={{
                      fontFamily: "var(--chakra-fonts-body)",
                    }}
                  >
                    <rect
                      x={0}
                      y={0}
                      width={width}
                      height={ratioBasedHeight}
                      fill={"transparent"}
                    />

                    {exportMode && title && (
                      <Group top={30} left={22}>
                        <Text
                          verticalAnchor="middle"
                          fontSize={22}
                          fontWeight="semibold"
                          fill={labelColor}
                        >
                          {title}
                        </Text>
                      </Group>
                    )}

                    <Group
                      top={!exportMode ? top : top + EXPORT_TITLE_HEIGHT}
                      left={exportMode ? radius + margins.l : left}
                    >
                      <Pie
                        data={pieData}
                        pieValue={(i) => i.value}
                        pieSortValues={
                          shouldSort ? (a: number, b: number) => b - a : () => 1
                        }
                        outerRadius={radius}
                      >
                        {(pie) => {
                          return (
                            <AnimatedPie<PieChartItem>
                              {...pie}
                              animate={true}
                              getKey={(arc) => arc.data.label}
                              data={pieData}
                              showTooltip={showTooltip}
                              hideTooltip={hideTooltip}
                              colorsFiltered={colors}
                              displayAsPercent={displayAsPercent}
                              radius={radius}
                            />
                          );
                        }}
                      </Pie>
                    </Group>

                    {exportMode && (
                      <Group
                        width={`${EXPORT_LEGEND_WIDTH}px`}
                        top={margins.t + EXPORT_TITLE_HEIGHT}
                        left={width - margins.r / 2 - EXPORT_LEGEND_WIDTH}
                      >
                        {data.map((item, index) => {
                          const color = colors[index];

                          return (
                            <>
                              <rect
                                x={0}
                                y={index * 32 - 8}
                                width={16}
                                height={16}
                                fill={color.value}
                              />

                              <Text
                                verticalAnchor="middle"
                                fontSize={14}
                                key={index}
                                width={EXPORT_LEGEND_WIDTH}
                                x={22}
                                y={index * 32}
                                fill={labelColor}
                              >
                                {item.label}
                              </Text>
                            </>
                          );
                        })}
                      </Group>
                    )}

                    {tooltipOpen &&
                      tooltipData &&
                      isDefined(tooltipLeft) &&
                      isDefined(tooltipTop) && (
                        <BasicTooltip
                          tooltipRenderer={({ textColor, tooltipData }) => {
                            return (
                              <ChakraText fontSize="medium" color={textColor}>
                                <b>{tooltipData.label}:</b>{" "}
                                {`${toNumberDisplayValue(tooltipData.value)}${
                                  displayAsPercent ? "%" : ""
                                }`}
                              </ChakraText>
                            );
                          }}
                          TooltipInPortal={TooltipInPortal}
                          tooltipLeft={tooltipLeft}
                          tooltipTop={tooltipTop}
                          tooltipData={tooltipData}
                        />
                      )}
                  </svg>
                );
              }}
            </ParentSize>
          </Flex>

          {!exportMode && (
            <Flex
              flexDirection="column"
              width={legendPositionComputed === "bottom" ? "100%" : undefined}
              mt={legendPositionComputed === "bottom" ? 5 : undefined}
              ml={legendPositionComputed === "right" ? 5 : undefined}
            >
              <Legend
                uniqueKey={uniqueKey}
                legendPosition={legendPositionComputed}
                data={data}
                colors={colors}
              />
            </Flex>
          )}
        </Flex>
      </Flex>
    </ErrorBoundary>
  );
};

export { PieChart };

type AnimatedPieProps<PieChartItem> = ProvidedProps<PieChartItem> & {
  animate?: boolean;
  getKey: (d: PieArcDatum<PieChartItem>) => string;
  delay?: number;
  showTooltip: (args: any) => void;
  hideTooltip: () => void;
  data: PieChartItem[];
  colorsFiltered: PieColor[];
  displayAsPercent: boolean;
  radius: number;
};

function AnimatedPie<PieChartItem>({
  animate,
  arcs,
  path,
  getKey,
  colorsFiltered,
  showTooltip,
  hideTooltip,
  data,
  displayAsPercent,
  radius,
}: AnimatedPieProps<PieChartItem>) {
  const transitions = useTransition<PieArcDatum<PieChartItem>, AnimatedStyles>(
    arcs,
    {
      from: animate ? fromLeaveTransition : enterUpdateTransition,
      enter: enterUpdateTransition,
      update: enterUpdateTransition,
      leave: animate ? fromLeaveTransition : enterUpdateTransition,
      keys: getKey,
    }
  );

  const { pieValueColor } = useChartColors();

  return transitions((props, arc, { key }, index) => {
    const [centroidX, centroidY] = path.centroid(arc);
    const hasSpaceForLabel = arc.endAngle - arc.startAngle >= 0.4;

    const color = colorsFiltered[index];

    const handleMouseOver = (event: any, item: PieChartItem) => {
      if (event.target) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        const coords = localPoint(event.target.ownerSVGElement, event);
        if (coords) {
          showTooltip({
            tooltipLeft: coords.x,
            tooltipTop: coords.y,
            tooltipData: item,
          });
        }
      }
    };

    if (!color) {
      return null;
    }

    const distanceFromCentroid = radius / 6; // we don't want to put label on centroid, but a little bit closer to the edge
    const dx = centroidX - 0; // difference in x coordinates between centroid and pie center
    const dy = centroidY - 0; // difference in y coordinates between centroid and pie center
    const length = Math.sqrt(dx * dx + dy * dy); // length of the line segment
    const textX = centroidX + (dx / length) * distanceFromCentroid;
    const textY = centroidY + (dy / length) * distanceFromCentroid;

    return (
      <g key={key}>
        <animated.path
          // compute interpolated path d attribute from intermediate angle values
          d={to([props.startAngle, props.endAngle], (startAngle, endAngle) =>
            path({
              ...arc,
              startAngle,
              endAngle,
            })
          )}
          fill={color.value}
          onMouseMove={(e) => {
            handleMouseOver(e, data[index]);
          }}
          onMouseLeave={() => {
            hideTooltip();
          }}
        />

        {hasSpaceForLabel && (
          <animated.g style={{ opacity: props.opacity }}>
            <text
              fill={pieValueColor}
              x={textX}
              y={textY}
              dy=".33em"
              fontWeight="bold"
              fontSize={16}
              textAnchor="middle"
              pointerEvents="none"
            >
              {`${toNumberDisplayValue(arc.value, { digits: 0 })}${
                displayAsPercent ? "%" : ""
              }`}
            </text>
          </animated.g>
        )}
      </g>
    );
  });
}
