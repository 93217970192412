export type MarkerLineType = "dashed" | "dotted" | "solid";

export type MarkerNamePlacement = "bottom" | "top" | null;

export const lineTypeToDashArray = (lineType: MarkerLineType) => {
  switch (lineType) {
    case "dashed":
      return "4,2";
    case "dotted":
      return "2,2";
    case "solid":
      return undefined;
  }
};
