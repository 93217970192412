import { TIMESERIES_X_AXIS_TICK_REQUIRED_SPACE } from "../Charts.const";
import { muteColor } from "utils";

export const findXAxisLabelFrequency = (
  {
    width,
    itemsCount,
  }: {
    width: number;
    itemsCount: number;
  },
  every = 1
): number => {
  if (TIMESERIES_X_AXIS_TICK_REQUIRED_SPACE > width) {
    return 0;
  }

  const space = width / (itemsCount / every);

  if (space >= TIMESERIES_X_AXIS_TICK_REQUIRED_SPACE) {
    return every;
  }

  return findXAxisLabelFrequency({ width, itemsCount }, every + 1);
};

export const muteTimeseriesColor = (color: string) => muteColor(color, 0.3);

export const generateTimeValuesSeq = (
  gap: number,
  isoDates: string[]
): string[] => {
  const sortedIsoDates = Array.from(new Set([...isoDates])).sort();
  const sortedUnixTimeDates = sortedIsoDates.map((isoDate) =>
    new Date(isoDate).getTime()
  );

  const firstKnownDate = Math.min(...sortedUnixTimeDates);
  const lastKnownDate = Math.max(...sortedUnixTimeDates);

  for (let offset = firstKnownDate; offset < lastKnownDate; offset += gap) {
    const hasDataPointForOffset = sortedUnixTimeDates.some(
      (unixTime) => unixTime === offset
    );
    if (hasDataPointForOffset) {
      continue;
    }

    sortedUnixTimeDates.push(new Date(offset).getTime());
  }

  return sortedUnixTimeDates
    .sort()
    .map((unixTime) => new Date(unixTime).toISOString());
};
