import { DownloadItemStatus } from "utils";
import { Loader } from "components";
import { Flex, Text } from "@chakra-ui/react";

type DownloadStatusProps = {
  status: DownloadItemStatus | undefined;
};

export const DownloadStatus = ({ status }: DownloadStatusProps) => {
  return (
    <Flex w="100%" h="50vh" alignItems="center" justifyContent="center">
      {status === DownloadItemStatus.IN_PROGRESS && (
        <Loader label="Downloading file..." />
      )}

      {status === DownloadItemStatus.COMPLETED && (
        <Text
          display="block"
          textAlign="center"
          fontWeight="bold"
          fontSize="2xl"
        >
          Downloading completed.
        </Text>
      )}

      {status === DownloadItemStatus.ERROR && (
        <Text
          display="block"
          textAlign="center"
          fontWeight="bold"
          fontSize="2xl"
        >
          Downloading failed.
        </Text>
      )}
    </Flex>
  );
};
