import { modalAnatomy as parts } from "@chakra-ui/anatomy";
import { extendTheme, ThemeConfig, useColorModeValue } from "@chakra-ui/react";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";
import { isDefined, shuffle } from "@intentsify/utils";

// https://chakra-ui.com/docs/components/modal/theming#adding-a-custom-variant
// eslint-disable-next-line @typescript-eslint/unbound-method
const { definePartsStyle } = createMultiStyleConfigHelpers(parts.keys);

export const colors = {
  gray: {
    25: "#f5f5f5",
    50: "#F0F2F4",
    100: "#D5DAE1",
    200: "#BBC2CE",
    300: "#A0AABB",
    400: "#8593A8",
    500: "#6B7B94",
    600: "#556277",
    700: "#404A59",
    750: "#323d4d",
    800: "#2B313B",
    900: "#15191E",
  },
  brand: {
    50: "#818993",
    100: "#68717d",
    200: "#4f5a67",
    300: "#354251",
    375: "#1d2e40",
    400: "#1c2b3c",
    425: "#1a2838",
    450: "#13202f",
    500: "#031326",
    600: "#031122",
    700: "#020f1e",
    800: "#020d1b",
    900: "#020b17",
  },
  green: {
    50: "#b7d98f",
    100: "#a8d179",
    200: "#9ac962",
    300: "#8bc14c",
    400: "#7dba35",
    500: "#6eb21f",
    600: "#63a01c",
    700: "#588e19",
    800: "#4d7d16",
    900: "#426b13",
  },
  red: {
    50: "#f49ca4",
    100: "#f18892",
    200: "#ef7480",
    300: "#ed606d",
    400: "#ea4c5b",
    500: "#e83849",
    600: "#d13242",
    700: "#ba2d3a",
    800: "#a22733",
    900: "#8b222c",
  },
  orange: {
    50: "#f2bf91",
    100: "#efb27b",
    200: "#eda565",
    300: "#ea994f",
    400: "#e88c39",
    500: "#e57f23",
    600: "#ce7220",
    700: "#b7661c",
    800: "#a05919",
    900: "#894c15",
  },
  yellow: {
    50: "#ecda81",
    100: "#e8d268",
    200: "#e4cb4f",
    300: "#e0c335",
    400: "#dcbc1c",
    500: "#d8b403",
    600: "#c2a203",
    700: "#ad9002",
    800: "#977e02",
    900: "#826c02",
  },
  teal: {
    50: "#afeede",
    100: "#9febd7",
    200: "#8fe7d0",
    300: "#7fe4c9",
    400: "#6fe0c3",
    500: "#5fddbc",
    600: "#56c7a9",
    700: "#4cb196",
    800: "#439b84",
    900: "#398571",
  },
  blue: {
    50: "#EBF2FA",
    100: "#C6DCF1",
    200: "#A1C5E7",
    300: "#7DAEDE",
    400: "#5897D5",
    500: "#3381CC",
    600: "#2967A3",
    700: "#1F4D7A",
    800: "#153351",
    900: "#0A1A29",
  },
  pink: {
    50: "#e699b8",
    100: "#e085aa",
    200: "#db709c",
    300: "#d65c8d",
    400: "#d1477f",
    500: "#cc3371",
    600: "#b82e66",
    700: "#a3295a",
    800: "#8f244f",
    900: "#7a1f44",
  },
};

export type Colour =
  | "blue"
  | "teal"
  | "green"
  | "yellow"
  | "orange"
  | "pink"
  | "red";

type ModifiableColour = Colour & { __type: "ModifiedColour" };
export type AnyColour = ModifiableColour | Colour;

export const colours: Colour[] = [
  "blue",
  "teal",
  "green",
  "yellow",
  "orange",
  "pink",
  "red",
];

export const isModifiableColour = (
  colour: AnyColour
): colour is ModifiableColour => {
  const modifiableRe = new RegExp(`^(${colours.join("|")})\\.\\d00$`);
  return modifiableRe.test(colour);
};
export const parseModifiableColour = (
  anyColour: AnyColour
): [Colour, number] => {
  if (!isModifiableColour(anyColour)) {
    return [anyColour, 0];
  }
  const [colour, modifier] = anyColour.split(".");
  return [colour as Colour, Number(modifier)];
};

export const getChartColours = (
  theme: "light" | "dark",
  pallete: Colour[] = colours,
  shuffleColors = true,
  includeShades = true
) => {
  const pool = pallete
    .map((p) => {
      return Object.entries(colors).find((c) => c[0] === p);
    })
    .filter(isDefined);

  const onlyColors = pool
    .map((c) => c[1])
    .map((c) => Object.entries(c))
    .flat()
    .filter((c) => {
      if (includeShades) {
        if (theme === "dark") {
          return Number(c[0]) <= 300;
        }

        if (theme === "light") {
          return Number(c[0]) >= 500;
        }
      }

      if (theme === "dark") {
        return Number(c[0]) === 300;
      }

      if (theme === "light") {
        return Number(c[0]) === 500;
      }
    })
    .map((c) => c[1]);

  if (shuffleColors) {
    return shuffle([
      ...onlyColors,
      ...onlyColors,
      ...onlyColors,
      ...onlyColors,
      ...onlyColors,
    ]);
  }

  return [
    ...onlyColors,
    ...onlyColors,
    ...onlyColors,
    ...onlyColors,
    ...onlyColors,
  ];
};

const styles = {
  global: {
    ".body": {
      height: "100%",
    },
    "*::-webkit-scrollbar": {
      width: "16px",
      backgroundColor: `rgba(0, 0, 0, 0.05)`,
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: `rgba(0, 0, 0, 0.15)`,
      borderRadius: "8px",
    },
    "*::-webkit-scrollbar-corner": {
      backgroundColor: `rgba(0, 0, 0, 0.05)`,
    },
    "body::-webkit-scrollbar": {
      borderRadius: "0",
    },
    "body::-webkit-scrollbar-thumb": {
      borderRadius: "0",
    },

    "#sidebar::-webkit-scrollbar": {
      width: "8px",
      backgroundColor: `rgba(255, 255, 255, 0.2)`,
      borderRadius: "3px",
    },
    "#sidebar::-webkit-scrollbar-thumb": {
      backgroundColor: `rgba(255, 255, 255, 0.3)`,
      borderRadius: "3px",
    },
    "3sidebar::-webkit-scrollbar-corner": {
      backgroundColor: `rgba(255, 255, 255, 0.2)`,
    },
    ".chakra-ui-dark .notification-content a": {
      color: "teal.300",
      fontWeight: "500",
    },
    ".chakra-ui-light .notification-content a": {
      color: "teal.900",
      fontWeight: "500",
    },
  },
};

const config: ThemeConfig = {
  initialColorMode: "system",
  useSystemColorMode: true,
};

export const intentsifyTheme = extendTheme({
  colors,
  styles,
  config,
  components: {
    Button: {
      variants: {
        pagination: {
          border: `1px solid ${colors.gray[400]}`,
          borderRadius: 0,
          backgroundColor: "transparent",
          _hover: {
            backgroundColor: "gray.400",
          },
        },
        "outline-teal": {
          color: "brand.900",
          borderColor: "teal.500",
          borderStyle: "solid",
          borderWidth: "1px",
          backgroundColor: "white",
          _hover: {
            backgroundColor: "teal.50",
          },
          _dark: {
            color: "white",
            backgroundColor: "transparent",
            _hover: {
              backgroundColor: "#8fe7d03d",
            },
          },
        },
      },
    },
    Modal: {
      sizes: {
        expandedCard: definePartsStyle({
          dialog: {
            maxWidth: "1024px",
            maxHeight: "768px",
            boxShadow: "none",
          },
        }),
        viewContainer: definePartsStyle({
          dialog: {
            maxWidth: "1800px",
            maxHeight: "1200px",
            boxShadow: "none",
          },
        }),
      },
    },
    Tabs: {
      variants: {
        corporate: ({ colorMode }: { colorMode: "light" | "dark" }) => ({
          tablist: {
            mt: "4",
            mr: "-4",
            height: "max-content",
            background: colorMode === "light" ? "gray.50" : "brand.400",
          },
          tab: {
            position: "relative",
            justifyContent: "flex-start",
            whiteSpace: "nowrap",
            fontSize: "sm",
            border: "1px solid",
            borderColor: colorMode === "light" ? "gray.200" : "gray.700",
            borderBottom: "unset",
            height: "40px",
            _hover: {
              borderRightColor: "transparent",
              background: colorMode === "light" ? "white" : "brand.375",
            },
            _last: {
              borderBottom: "1px solid",
              borderBottomColor:
                colorMode === "light" ? "gray.200" : "gray.700",
            },
            _before: {
              content: '""',
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              width: "5px",
              background: "transparent",
            },
            _selected: {
              borderRightColor: "transparent",
              background: colorMode === "light" ? "white" : "brand.375",
              _before: {
                background: "teal.500",
              },
            },
          },
        }),
      },
    },
    Table: {
      variants: {
        corporate: (props: { colorMode: string }) => ({
          thead: {
            tr: {
              th: {
                textTransform: "capitalize",
                py: "12px",
                px: "4",
                color: props.colorMode === "light" ? "#000" : "#fff",
                background:
                  props.colorMode === "light" ? "gray.50" : "brand.400",
                borderBottom: `1px solid ${
                  props.colorMode === "light"
                    ? colors.gray[100]
                    : colors.gray[700]
                }`,
              },
            },
          },
          tr: {
            td: {
              color: props.colorMode === "light" ? "#14202F" : colors.gray[100],
              py: "2",
              px: "4",
            },
            background: props.colorMode === "light" ? "white" : "brand.375",
          },
        }),
        corporateCompact: (props: { colorMode: string }) => ({
          thead: {
            tr: {
              th: {
                textTransform: "capitalize",
                py: "4px",
                px: "2",
                color: props.colorMode === "light" ? "#000" : "#fff",
                background:
                  props.colorMode === "light" ? "gray.50" : "brand.400",
                borderBottom: `1px solid ${
                  props.colorMode === "light"
                    ? colors.gray[100]
                    : colors.gray[700]
                }`,
              },
            },
          },
          tr: {
            td: {
              py: "4px",
              px: "2",
            },
            _even: {
              background: "inherit",
            },
            _odd: {
              background: "inherit",
            },
            borderBottom: "1px solid",
            borderColor: props.colorMode === "light" ? "gray.100" : "brand.400",
          },
        }),
        intentsifyPlain: (props: { colorMode: string }) => ({
          thead: {
            tr: {
              th: {
                backgroundColor:
                  props.colorMode === "light" ? "gray.50" : "brand.500",
                color: props.colorMode === "light" ? "black" : "white",
              },
            },
          },
          tr: {
            _even: {
              background: "inherit",
            },
            _odd: {
              background: "inherit",
            },
            _last: {
              borderBottom: "none",
            },
            borderBottom: "1px solid",
            borderColor: props.colorMode === "light" ? "gray.100" : "brand.300",
          },
        }),
        intentsifyStriped: () => ({
          thead: {
            tr: {
              th: {
                backgroundColor: "brand.450",
                color: "white",
              },
              _last: {
                th: {
                  borderBottom: "none",
                },
              },
            },
          },
          tr: {
            _odd: {
              backgroundColor: "white",
              _dark: {
                backgroundColor: "transparent",
              },
            },
            _even: {
              backgroundColor: "gray.25",
              _dark: {
                backgroundColor: "brand.400",
              },
            },
          },
        }),
        intentsifyExpanded: () => ({
          thead: {
            tr: {
              th: {
                backgroundColor: "brand.450",
                color: "white",
                _dark: {
                  backgroundColor: "brand.450",
                },
              },
            },
          },
          tr: {
            fontWeight: 500,
            td: {
              color: "brand.450",
              _dark: {
                color: "white",
              },
            },
            _odd: {
              backgroundColor: "white",
              _dark: {
                backgroundColor: "transparent",
              },
            },
            _even: {
              backgroundColor: "gray.25",
              _dark: {
                backgroundColor: "brand.400",
              },
            },
          },
        }),
        intentsifyClean: (props: { colorMode: string }) => ({
          thead: {
            tr: {
              th: {
                textTransform: "capitalize",
                padding: "12px 0",
                color: props.colorMode === "light" ? "#000" : "#fff",
                background: "transparent",
                borderBottom: `1px solid ${
                  props.colorMode === "light"
                    ? colors.gray[500]
                    : colors.gray[500]
                }`,
              },
            },
          },
          tr: {
            td: {
              color: props.colorMode === "light" ? "#14202F" : colors.gray[100],
              padding: "12px 0",
              borderBottom: `1px solid ${
                props.colorMode === "light"
                  ? colors.gray[100]
                  : colors.gray[700]
              }`,
            },
            background: props.colorMode === "light" ? "gray.25" : "brand.400",
          },
        }),
        intentsifyNew: (props: { colorMode: string }) => ({
          thead: {
            tr: {
              borderTop: `1px solid ${colors.gray[500]}`,
              borderBottom: `1px solid ${colors.gray[500]}`,
              th: {
                color: props.colorMode === "light" ? "brand.500" : "gray.25",
                backgroundColor:
                  props.colorMode === "light" ? "gray.25" : "brand.500",
                fontSize: "12px",
                fontWeight: "700",
                padding: "6px",
                textTransform: "uppercase",
              },
            },
          },
          tr: {
            borderBottom: `1px solid ${
              props.colorMode === "light" ? colors.gray[100] : colors.gray[700]
            }`,
            fontWeight: "500",
            td: {
              padding: "6px",
            },
          },
        }),
      },
    },
    Popover: {
      baseStyle: {
        popper: {
          width: "fit-content",
          maxWidth: "fit-content",
        },
      },
    },
  },
});

export const useComponentColors = () => ({
  form: {
    content: useColorModeValue("white", "brand.375"),
    stackBg: useColorModeValue("white", "brand.375"),
    boxBg: useColorModeValue("brand.300", "brand.500"),
    formLabelColor: useColorModeValue("gray.500", "gray.200"),
    formSubLabelColor: useColorModeValue("gray.500", "gray.200"),
  },
  spinner: {
    emptyColor: useColorModeValue("gray.100", "gray.500"),
    color: useColorModeValue("gray.500", "gray.100"),
  },
  popoverGray: useColorModeValue("gray.750", "gray.700"),
});
