import { useQuery } from "@tanstack/react-query";
import { DateWeekAndYear } from "types";
import { getCampaignSummary } from "./CampaignDetails.requests";

type UseCampaignSummary = {
  campaignId: number;
  date: DateWeekAndYear;
};

export const useCampaignSummary = ({
  campaignId,
  date,
}: UseCampaignSummary) => {
  const { data, isFetching } = useQuery(
    ["getCampaignSummary", campaignId, date.yearNumber, date.weekNumber],
    () =>
      getCampaignSummary(Number(campaignId), {
        yearNumber: date.yearNumber,
        weekNumber: date.weekNumber,
      })
  );

  return {
    data,
    isFetching,
  };
};
