import { DateRange, Option, SplitBy } from "@intentsify/types";
import { atom, selector, useResetRecoilState } from "recoil";
import { ViewEnum } from "screens/IntentAnalysis/screens/CampaignsTrendingDomains/CampaignsTrendingDomains.types";
import { TrendingKeywordsOptions } from "screens/IntentAnalysis/screens/CampaignsTrendingSignals/CampaignsTrendingSignals.types";

export const trendingKeywordsDateRangeAtom = atom<DateRange>({
  key: `campaignsTrendingKeywordsDateRange`,
  default: DateRange.LastFourWeeks,
});

export const keywordsSplitByAtom = atom<SplitBy>({
  key: `keywordsSplitByAtom`,
  default: SplitBy.OneWeek,
});

export const keywordsViewAtom = atom<ViewEnum>({
  key: `keywordsViewAtom`,
  default: ViewEnum.TimeSeries,
});

export const selectedTrendingKeywordsAtom = atom<{
  campaignId: number | undefined;
  data: Array<Option<string>>;
}>({
  key: `selectedTrendingKeywordsAtom`,
  default: {
    campaignId: undefined,
    data: [],
  },
});

export const trendingKeywordsOptionsStateAtom = atom<TrendingKeywordsOptions>({
  key: `trendingKeywordsOptionsStateAtom`,
  default: {
    isLoading: true,
    keywords: [],
  },
});

export const trendingKeywordsStateSelector = selector({
  key: "trendingKeywordsStateSelector",
  get: ({ get }) => {
    const { isLoading, keywords } = get(trendingKeywordsOptionsStateAtom);

    const selectedDateRange = get(trendingKeywordsDateRangeAtom);
    const selectedSplitBy = get(keywordsSplitByAtom);
    const selectedView = get(keywordsViewAtom);
    const selectedKeywords = get(selectedTrendingKeywordsAtom);

    return {
      isLoading,
      keywordsOptions: keywords,
      selectedDateRange,
      selectedSplitBy,
      selectedView,
      selectedKeywords,
    };
  },
});

export const useResetTrendingKeywordsState = () => {
  const list = [
    useResetRecoilState(trendingKeywordsOptionsStateAtom),
    useResetRecoilState(trendingKeywordsDateRangeAtom),
    useResetRecoilState(keywordsSplitByAtom),
    useResetRecoilState(keywordsViewAtom),
    useResetRecoilState(selectedTrendingKeywordsAtom),
  ];

  return () => {
    list.forEach((f) => f());
  };
};
