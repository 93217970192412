import { Route, Routes } from "react-router";
import { Navigate } from "react-router-dom";
import {
  BusinessEntities,
  CreateBusinessEntity,
  EditBusinessEntity,
} from "screens";
import { BusinessEntitiesScreenDefinition } from "screens/Admin/screens/BusinessEntities/screens/BusinessEntities/BusinessEntities.definition";
import { CreateBusinessEntityScreenDefinition } from "screens/Admin/screens/BusinessEntities/screens/CreateBusinessEntity/CreateBusinessEntity.definition";
import { EditBusinessEntityScreenDefinition } from "screens/Admin/screens/BusinessEntities/screens/EditBusinessEntity/EditBusinessEntity.definition";

const BusinessEntitiesRouter = () => {
  return (
    <Routes>
      {BusinessEntitiesScreenDefinition.routePath.map((path) => (
        <Route key={path} path={path} element={<BusinessEntities />} />
      ))}

      {CreateBusinessEntityScreenDefinition.routePath.map((path) => (
        <Route key={path} path={path} element={<CreateBusinessEntity />} />
      ))}

      {EditBusinessEntityScreenDefinition.routePath.map((path) => (
        <Route key={path} path={path} element={<EditBusinessEntity />} />
      ))}

      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>
  );
};

export { BusinessEntitiesRouter };
