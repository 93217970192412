import { useColorModeValue } from "@chakra-ui/color-mode";
import { Icon as ChakraIcon, IconProps } from "@chakra-ui/icon";
import { Icon, InfoIcon } from "@chakra-ui/icons";
import { Box, HStack, Text } from "@chakra-ui/layout";
import { As } from "@chakra-ui/system";
import { IconButton, Tooltip } from "components";
import { FiDownload } from "react-icons/fi";

interface DomainValidationEntryProps {
  icon: As;
  iconColor: IconProps["color"];
  typeContent: string;
  count: number;
  hideDivider?: boolean;
  tooltipContent?: string;
  onDownload?: (type: "passed" | "failed" | "unknown") => void;
}

export const DomainValidationEntry = ({
  icon,
  iconColor,
  typeContent,
  count,
  tooltipContent,
  hideDivider = false,
  onDownload,
}: DomainValidationEntryProps) => {
  const dividerBgColor = useColorModeValue("gray.100", "gray.700");

  return (
    <>
      <Box
        position="relative"
        height="36px"
        _before={
          hideDivider
            ? {}
            : {
                content: "''",
                position: "absolute",
                bottom: "0",
                left: 0,
                right: 0,
                w: "95%",
                backgroundColor: dividerBgColor,
                height: "1px",
              }
        }
      >
        <HStack alignItems="center" spacing={1} fontWeight="semibold" h="100%">
          <ChakraIcon as={icon} w={6} h={6} color={iconColor} />
          <Text>{typeContent}</Text>
          <Text>({count})</Text>

          {count > 0 && tooltipContent && (
            <Tooltip
              shouldWrapChildren
              aria-label={`${typeContent} domains`}
              label={tooltipContent}
            >
              <InfoIcon w={3} h={3} />
            </Tooltip>
          )}

          {onDownload && (
            <IconButton
              colorScheme="blue"
              as="a"
              icon={<Icon cursor="pointer" as={FiDownload} fontSize="md" />}
              onClick={() =>
                onDownload(
                  typeContent.toLowerCase() as "passed" | "failed" | "unknown"
                )
              }
              tooltip="Download file"
            />
          )}
        </HStack>
      </Box>
    </>
  );
};
