import { Flex } from "@chakra-ui/layout";
import { FormControl, FormLabel, Skeleton } from "@chakra-ui/react";

export function OptOutDisplayLoading() {
  return (
    <Flex display="flex" flexDir="column" gap="4">
      <FormControl isRequired>
        <FormLabel>Email</FormLabel>
        <Skeleton h="40px" borderRadius="md" />
      </FormControl>

      <FormControl isRequired>
        <FormLabel>First name</FormLabel>
        <Skeleton h="40px" borderRadius="md" />
      </FormControl>

      <FormControl isRequired>
        <FormLabel>Last name</FormLabel>
        <Skeleton h="40px" borderRadius="md" />
      </FormControl>

      <FormControl>
        <FormLabel>
          <Skeleton h="18px" w="20%" borderRadius="md" />
        </FormLabel>
        <Skeleton h="40px" borderRadius="md" />
      </FormControl>

      <FormControl>
        <FormLabel>
          <Skeleton h="18px" w="20%" borderRadius="md" />
        </FormLabel>
        <Skeleton h="40px" borderRadius="md" />
      </FormControl>

      <FormControl>
        <FormLabel>
          <Skeleton h="18px" w="20%" borderRadius="md" />
        </FormLabel>
        <Skeleton h="40px" borderRadius="md" />
      </FormControl>

      <FormControl>
        <FormLabel>
          <Skeleton h="18px" w="20%" borderRadius="md" />
        </FormLabel>
        <Skeleton h="40px" borderRadius="md" />
      </FormControl>
    </Flex>
  );
}
