import { Center, Stack } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { SetPasswordScreenDefinition } from "./SetPassword.definition";
import { SetPasswordForm } from "./SetPasswordForm";
import { LogoBoxed } from "components";
import { AuthFormContainer, AuthFormHeader } from "auth/components";

const SetPassword = () => {
  const { search } = useLocation();
  const values = new URLSearchParams(search);
  const id = values.get("id");
  const token = values.get("token");

  if (!id || !token) {
    throw new Error("404");
  }

  return (
    <AuthFormContainer>
      <Center>
        <Stack>
          <LogoBoxed w={"200px"} />
          <AuthFormHeader text={SetPasswordScreenDefinition.name} />
        </Stack>
      </Center>
      <SetPasswordForm token={token} userId={id} />
    </AuthFormContainer>
  );
};

export { SetPassword };
