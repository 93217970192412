import { Box, Center, Text } from "@chakra-ui/react";
import { AutodiscoveryProcessingProgress } from "@intentsify/types";
import { Progress } from "components";

type AutodiscoveryProgressProps = {
  autodiscoveryProgress: AutodiscoveryProcessingProgress;
};

const AutodiscoveryProgress = ({
  autodiscoveryProgress,
}: AutodiscoveryProgressProps) => {
  return (
    <Box>
      <Center>
        <Text size="lg" fontWeight="semibold">
          Processing...
        </Text>
      </Center>
      <Progress
        hasStripe
        colorScheme={"blue"}
        value={autodiscoveryProgress.progress}
      />
    </Box>
  );
};

export { AutodiscoveryProgress };
