import { CreateBusinessEntityDTO } from "@intentsify/dto";
import { AgencyFormData } from "../../components";
import { apiService, Endpoints } from "api";

const createBusinessEntity = async (data: Required<AgencyFormData>) => {
  await apiService.post<any, any, CreateBusinessEntityDTO>(
    Endpoints.BusinessEntities.Post.Create,
    data
  );
};

export { createBusinessEntity };
