import { BaseFilterRecordValue } from "types";

const normalizeFilters = (filters: { [k: string]: BaseFilterRecordValue }) => {
  Object.entries(filters).forEach(([key]) => {
    if (!Array.isArray(filters[key]) && typeof filters[key] === "object") {
      Object.entries(filters[key]).forEach(([subKey, value]) => {
        filters[`${key}${subKey.charAt(0).toUpperCase() + subKey.slice(1)}`] =
          value;
      });

      delete filters[key];
    }
  });

  return { ...filters };
};

export { normalizeFilters };
