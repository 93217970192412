import { Company } from "@intentsify/types";
import { useMutation } from "@tanstack/react-query";
import { Duration } from "luxon";
import { apiService, Endpoints } from "api";

const establishWorkatoMapping = async (companyId: Company["companyId"]) =>
  await apiService.post(
    Endpoints.Companies.Post.EstablishWorkatoMapping(Number(companyId))
  );

export const useEstablishWorkatoMapping = () => {
  return useMutation(establishWorkatoMapping, {
    cacheTime: Duration.fromObject({ minutes: 2 }).as("millisecond"),
  });
};
