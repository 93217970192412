import { Tag, TagLabel } from "@chakra-ui/react";
import { memo } from "react";
import { Node } from "@intentsify/types";

const InputTags = memo(({ items }: { items: Node[] }) => {
  return (
    <>
      {items.map((item) => {
        return (
          <Tag size="sm" key={item?.label} h="22px" m="2px">
            <TagLabel>{item?.label}</TagLabel>
          </Tag>
        );
      })}
    </>
  );
});

export { InputTags };
