import { useCallback } from "react";
import { useLocation } from "react-router";
import { CampaignFileDownloadedProperties, ampli } from "./amplitude";

type TrackCampaignFileDownloadedOptions = {
  type: CampaignFileDownloadedProperties["type"];
  campaignIds: CampaignFileDownloadedProperties["campaignIds"];
};

export const useTrackCampaignFileDownloaded = () => {
  const location = useLocation();

  return useCallback(
    ({ type, campaignIds }: TrackCampaignFileDownloadedOptions) => {
      ampli.campaignFileDownloaded({
        type,
        campaignIds,
        pagePath: location.pathname,
      });
    },
    [location.pathname]
  );
};
