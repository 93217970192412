import { SimpleGrid } from "@chakra-ui/react";
import { useQueries } from "@tanstack/react-query";
import { Button, Loader, ViewContainer } from "components";
import { useCompanyDataPackageQueryOptions } from "queries/companies/useCompanyDataPackageQueryOptions";
import { useCompanyQueryOptions } from "queries/companies/useCompanyQueryOptions";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useScreen } from "utils";
import { BusinessUnits } from "./BusinessUnits";
import { NewBusinessUnit } from "./BusinessUnits/components";
import { EditCompanyScreenDefinition } from "./EditCompany.definition";
import { EditCompanyForm } from "./EditCompanyForm";

const EditCompany = () => {
  useScreen(EditCompanyScreenDefinition);
  const { id } = useParams<"id">();
  const [isNewBusinessUnitOpen, setIsNewBusinessUnitOpen] = useState(false);

  if (!id) {
    throw new Error("404");
  }

  const [company, companyDataPackage] = useQueries({
    queries: [
      useCompanyQueryOptions(id),
      useCompanyDataPackageQueryOptions(id),
    ],
  });

  return (
    <SimpleGrid
      mt={"2px"}
      columns={2}
      spacing="2px"
      minChildWidth={"800px"}
      gridAutoRows="min-content"
    >
      <ViewContainer withBackground title="Company details">
        {company.isLoading ? (
          <Loader />
        ) : (
          <EditCompanyForm
            id={id}
            company={company.data}
            dataPackageId={companyDataPackage.data?.id}
          />
        )}
      </ViewContainer>

      <ViewContainer
        withBackground
        title="Subsidiaries"
        actionItems={
          <Button
            variant="primary-teal"
            onClick={() => setIsNewBusinessUnitOpen(true)}
          >
            New Subsidiary
          </Button>
        }
      >
        {company.isLoading ? (
          <Loader />
        ) : (
          <>
            <BusinessUnits companyId={Number(company.data?.companyId)} />
            <NewBusinessUnit
              isOpen={isNewBusinessUnitOpen}
              onClose={() => setIsNewBusinessUnitOpen(false)}
              companyId={Number(company.data?.companyId)}
            />
          </>
        )}
      </ViewContainer>
    </SimpleGrid>
  );
};

export { EditCompany };
