import { CampaignAdvancedSettings } from "@intentsify/types";
import { useMemo } from "react";
import { haveAdvancedSettingsChanged } from "screens/Campaigns/screens/YourCampaigns/YourCampaigns.utils";

export const useWatchActivationSettings = (
  campaignAdvancedSettings: Omit<CampaignAdvancedSettings, "convertrCampaigns">,
  newCampaignAdvancedSettings: Omit<
    CampaignAdvancedSettings,
    "convertrCampaigns"
  >
) => {
  const haveChanged = useMemo(() => {
    if (
      haveAdvancedSettingsChanged(
        campaignAdvancedSettings,
        newCampaignAdvancedSettings
      )
    ) {
      return true;
    }

    return false;
  }, [newCampaignAdvancedSettings, campaignAdvancedSettings]);

  return haveChanged;
};
