import { AxiosResponse } from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CampaignForList } from "@intentsify/types";
import { ListCampaignsRO } from "@intentsify/dto";
import {
  apiService,
  defaultListPaginatedCampaignsParams,
  Endpoints,
} from "api";
import { FetchDataParamsWithToken, PaginatedCampaign } from "types";

export const listAllCampaigns = async (
  params: FetchDataParamsWithToken<
    keyof CampaignForList
  > = defaultListPaginatedCampaignsParams
): Promise<ListCampaignsRO> => {
  const { data } = await apiService.get<ListCampaignsRO>(
    Endpoints.Publishers.Get.ListAllCampaigns,
    { params }
  );

  return data;
};

export const useAssignCampaignMutation = (
  publisherId: string,
  campaignId: number
) => {
  const queryClient = useQueryClient();

  const fetchDataParams = {
    ...defaultListPaginatedCampaignsParams,
    search: campaignId,
  };

  const assignCampaignMutation = useMutation<
    AxiosResponse<PaginatedCampaign>,
    unknown,
    number
  >(
    (campaignId) =>
      apiService.post(Endpoints.Publishers.Post.AssignCampaign(publisherId), {
        campaignId,
      }),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(["campaignAssignmentState", fetchDataParams], {
          data: { results: [data.data] },
        });
        queryClient.invalidateQueries({ queryKey: ["publisherCampaignsList"] });
      },
    }
  );

  return { assignCampaignMutation };
};
