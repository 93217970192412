import { SortingState } from "@tanstack/react-table";

export type OnFetchDataChangeParams = {
  pageIndex: number;
  pageSize: number;
  sortBy: SortingState;
  globalFilter?: string;
};

export type OnTokenFetchDataChangeParams = {
  pageToken: string;
  sortBy: SortingState;
  globalFilter?: string;
};

export enum PaginationType {
  TOKEN = "TOKEN",
  LIMIT_OFFSET = "LIMIT_OFFSET",
}

export type UseTokenPagination = {
  currentToken: string;
  useUpdateToken: (nextToken: string) => void;
  updateToken: (nextToken: string) => void;
  hasToken: (pageNumber: number) => boolean;
};
