import { listFormat, listFormatDisjunction } from "shared/listFormat";

const allowedFileExtensions = [
  {
    mimes: [
      "applcation/pdf",
      "application/x-pdf",
      "application/acrobat",
      "applications/vnd.pdf",
      "text/pdf",
      "text/x-pdf",
      "image/jpeg",
      "image/png",
      "image/gif",
      "text/csv",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
    ],
    extensions: [".pdf"],
  },
  {
    mimes: ["text/plain", "text/richtext", "text/rtf", "application/rtf"],
    extensions: [".txt"],
  },
];

export const contentLibraryAllowedFileExtensions = Object.fromEntries(
  allowedFileExtensions.flatMap((e) =>
    e.mimes.map((mime) => [mime, e.extensions])
  )
);
export const contentLibraryAllowedFileExtensionsAsAcceptString = Object.entries(
  contentLibraryAllowedFileExtensions
)
  .map(([mime, extensions]) => `${mime}, ${extensions.join(", ")}`)
  .join(", ");

export const prettyContentLibraryAllowedFileExtensions = listFormat.format(
  allowedFileExtensions.flatMap((e) => e.extensions)
);

export const prettyContentLibraryAllowedFileExtensionsDisjunction =
  listFormatDisjunction.format(
    allowedFileExtensions.flatMap((e) => e.extensions)
  );
