import { Flex, LayoutProps, Text } from "@chakra-ui/react";
import { PropsWithChildren, ReactNode } from "react";

const NoData = ({
  message = "No data found",
  height = "50vh",
  stretch = false,
  children,
}: PropsWithChildren<{
  message?: ReactNode;
  height?: LayoutProps["height"];
  stretch?: boolean;
}>) => {
  return (
    <Flex
      height={stretch ? "100%" : height}
      minH={height}
      width="full"
      align="center"
      justifyContent="center"
      textAlign="center"
      flexGrow={stretch ? 1 : 0}
    >
      {message && (
        <Text
          display="block"
          textAlign="center"
          fontWeight="bold"
          fontSize="2xl"
        >
          {message}
        </Text>
      )}
      {children && <>{children}</>}
    </Flex>
  );
};

export { NoData };
