import { useEffect } from "react";
import { useLocation } from "react-router";

export const useScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const scrollPos =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop;
    if (scrollPos < 1) {
      window.scrollTo(0, 1);
    }
  }, [pathname]);
};
