import { Center, Text } from "@chakra-ui/layout";
import { TargetPersonaTopic } from "@intentsify/types";
import { Table } from "components";
import { useTopics } from "../BuyingGroup.requests";
import { columns } from "./TopicsTable.utils";

type TopicsTableProps = {
  campaignId: number;
  accountId: number;
  selectedPersonas: string[];
};

const TopicsTable = ({
  campaignId,
  accountId,
  selectedPersonas,
}: TopicsTableProps) => {
  const topics = useTopics({
    accountId,
    campaignId,
  });

  let filteredTopics = [...(topics.data || [])];

  if (selectedPersonas.length > 0) {
    filteredTopics =
      topics.data?.filter((topic) =>
        selectedPersonas.includes(`${topic.jobLevel}, ${topic.jobFunction}`)
      ) || [];
  }

  if (filteredTopics?.length === 0) {
    return (
      <Center p={8}>
        <Text fontWeight={"semibold"}>No topics found</Text>
      </Center>
    );
  }

  return (
    <Table<TargetPersonaTopic>
      stretch
      maxWidth="100%"
      columns={columns}
      data={filteredTopics || []}
      defaultSort={[{ id: "name", desc: true }]}
      isScrollable={false}
      isControlledByAPI={false}
    />
  );
};
export { TopicsTable };
