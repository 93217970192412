import { Box, Stack, Text } from "@chakra-ui/react";
import { Select } from "../Select";
import { Option } from "./SplitByFilter.types";

type SplitByProps<K> = {
  options: Array<Option<K>>;
  value: string;
  onChange: (value: K) => void;
  width?: string | number;
  showLabel?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
};

const SplitByFilter = <T extends string>({
  onChange,
  options,
  value,
  isDisabled,
  isLoading,
  showLabel = true,
  width = "100%",
}: SplitByProps<T>) => {
  const handleSplitByChange = (option: Option<T>) => {
    onChange(option.value);
  };

  return (
    <Stack minW={width}>
      {showLabel && (
        <Text fontSize="xs" fontWeight="semibold">
          Split by:
        </Text>
      )}
      <Box minWidth={250}>
        <Select
          isMulti={false}
          isLoading={isLoading}
          isDisabled={isDisabled}
          options={options}
          value={options.find((dr: Option<T>) => dr.value === value)}
          placeholder="Select a split by"
          onChange={(option) => {
            if (option) {
              handleSplitByChange(option);
            }
          }}
        />
      </Box>
    </Stack>
  );
};

export { SplitByFilter };
