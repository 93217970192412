import { keyframes } from "@emotion/react";

export const defaultTransition = `
  opacity 0.34s cubic-bezier(0.33, 1, 0.68, 1),
  max-height 2.6s cubic-bezier(0.5, 1, 0.89, 1),
  color 0.34s cubic-bezier(0.33, 1, 0.68, 1),
  text-decoration-color 0.34s cubic-bezier(0.33, 1, 0.68, 1)
`;

export const viewKeyframesWithScale = keyframes`  
  from {
    opacity: 0;
    transform: scale(0.96) translateY(-18%);
  }

  to {
    opacity: 1;
    transform: scale(1) translateY(0%);
  } 
`;
