import enGb from "date-fns/locale/en-GB";
import { DateTime } from "luxon";
import { useState } from "react";
import { DayPicker, useInput, UseInputOptions } from "react-day-picker";
import { useColorMode } from "@chakra-ui/react";
import { getWeekRange } from "components/DatePicker/DatePicker.utils";
import { colors } from "theme";

const options: UseInputOptions = {
  defaultSelected: new Date(),
  fromYear: 2021,
  toYear: new Date().getFullYear(),
  format: "PP",
  required: true,
};

type PickerProps = {
  mode: "week" | "day";
  selected: string;
  selectedRange: Date[];
  month: Date;
  onMonthChange: (month: Date) => void;
  onChange: (isoDate: string) => void;
  disabled: (date: Date) => boolean;
};

const Picker = ({
  mode,
  selected,
  selectedRange,
  onChange,
  month,
  onMonthChange,
  disabled,
}: PickerProps) => {
  const picker = useInput(options);

  const { colorMode } = useColorMode();

  const [hoveredRange, setHoveredRange] = useState<{ from: Date; to: Date }>({
    from: new Date(),
    to: new Date(),
  });

  const handleDayEnter = (date: Date) => {
    setHoveredRange(getWeekRange(date));
  };

  return (
    <DayPicker
      {...picker.dayPickerProps}
      month={month}
      onMonthChange={onMonthChange}
      showOutsideDays
      mode="range"
      showWeekNumber
      selected={
        mode === "week"
          ? getWeekRange(
              DateTime.fromObject({
                weekYear: DateTime.fromISO(selected, { zone: "utc" }).year,
                weekNumber: DateTime.fromISO(selected, { zone: "utc" })
                  .weekNumber,
              }).toJSDate()
            )
          : {
              from: DateTime.fromISO(selected, { zone: "utc" }).toJSDate(),
            }
      }
      onDayClick={(date) => {
        /*
          1. Picker always gives 00:00:00 local time
          2. 00:00:00 local time in New York is converted to 2024-09-20 04:00:00 UTC
          3. 00:00:00 local time in Warsaw is converted to 2024-09-19 22:00:00 UTC
          4. Fix is about to set the date but with the REAL local time (not 00:00:00)
        */
        const selectedDate = DateTime.fromJSDate(date);
        const now = DateTime.now();
        const dateTime = DateTime.fromObject({
          day: selectedDate.day,
          month: selectedDate.month,
          year: selectedDate.year,
          hour: now.hour,
          minute: now.minute,
          second: now.second,
        });
        onChange(dateTime.toUTC().toISO());
      }}
      locale={enGb}
      disabled={disabled}
      modifiers={{
        ...(mode === "week" ? hoveredRange : {}),
        selectedRange,
      }}
      modifiersStyles={{
        hoveredRange: {},
        selectedRange: {
          fontWeight: 500,
          color:
            colorMode === "light" ? colors.teal["700"] : colors.teal["500"],
        },
      }}
      onDayMouseEnter={handleDayEnter}
    />
  );
};

export { Picker };
