import { Option } from "@intentsify/types";
import uniq from "lodash/uniq";
import {
  SingleAccountDetailsFilters,
  SingleAccountDetailsItem,
  SingleAccountDetailsTimeseriesResponse,
} from "../../SingleAccountDetails.types";

export const filterAccountsDetailItem = (
  item: SingleAccountDetailsItem,
  filters: SingleAccountDetailsFilters
) => {
  const names = filters.names.map((i) => i.label);

  if (names.includes(String(item.label))) {
    return true;
  }

  const locations = filters.locations.map((i) => i.label);

  const locationsRaw = item.timeseries
    .flatMap((i) => i.data.location)
    .filter((i) => i.label.length > 0)
    .map((i) => i.label.trim());

  if (locations.some((i) => locationsRaw.includes(i))) {
    return true;
  }

  return false;
};

export const extractNamesAndLocations = (
  data: SingleAccountDetailsTimeseriesResponse
) => {
  const names: Option[] = data.data
    .map((i) => ({
      label: String(i.label),
      value: String(i.label),
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const locationsRaw = data.data
    .flatMap((i) => {
      return i.timeseries.map((i) => i.data.location);
    })
    .filter((i) => i.length > 0)
    .flat()
    .map((i) => i.label.trim())
    .sort((a, b) => a.localeCompare(b));

  const locations: Option[] = uniq(locationsRaw).map((i) => ({
    label: i,
    value: i,
  }));

  return { names, locations };
};
