import { useLocation, useMatch, useNavigate } from "react-router-dom";
import { EditCampaignScreen } from "types";
import { createScreen } from "utils/createScreen";

const screen = createScreen({
  name: EditCampaignScreen,
  path: `/intent-models/:campaignId/edit/:step`,
  routePath: ["/:campaignId/edit/:step"],
  accessPath: "campaign.edit",
});

type EditCampaignArgs = Parameters<(typeof screen)["navigate"]>[0];

const EditCampaignScreenDefinition = {
  ...screen,
  navigate: (args: EditCampaignArgs, { from }: { from: string }) => {
    return (
      screen.navigate(args) +
      "?" +
      new URLSearchParams([["from", from]]).toString()
    );
  },
};

export { EditCampaignScreenDefinition };

export const useEditCampaignNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const match = useMatch({ path: location.pathname, end: true });

  return (args: EditCampaignArgs) => {
    navigate(
      EditCampaignScreenDefinition.navigate(args, {
        from: match
          ? new URLSearchParams(location.search).get("from") ??
            location.pathname
          : location.pathname,
      })
    );
  };
};
