import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { WeeklySnapshotRangeDTO } from "@intentsify/dto";
import { getLeadByAssetPerformance } from "./LeadByAssetPerformance.requests";
import { ChartExport, useExportableChart } from "shared/components";
import { BarChart, Card, MIN_MODAL_CONTENT_HEIGHT } from "components";

type LeadByAssetPerformanceProps = {
  campaignId: number;
  filters: WeeklySnapshotRangeDTO;
};

const LeadByAssetPerformance = ({
  campaignId,
  filters,
}: LeadByAssetPerformanceProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const { data, isFetching } = useQuery(
    ["getLeadByAssetPerformance", campaignId, filters],
    () => getLeadByAssetPerformance(campaignId, filters)
  );

  const chart = useExportableChart({
    title: "Lead By Asset Performance",
    campaignId: campaignId,
    chart: (
      <BarChart
        minHeight={isExpanded ? MIN_MODAL_CONTENT_HEIGHT : undefined}
        stretch
        orientation="vertical"
        data={data ?? []}
        valueAxisLabel="Sum of Leads Delivered"
        maxXAxisHeight={70}
      />
    ),
  });

  return (
    <Card
      isLoading={isFetching}
      onExpandedChange={setIsExpanded}
      title={chart.title}
      hasData={!isFetching && Boolean(data?.length)}
      noDataMessage="This visualization is allocated for content syndication programs associated with this campaign. If the program began recently, data may still be configuring."
      isExpandable
      actions={
        <ChartExport
          size="small"
          campaignId={1}
          title={chart.title}
          onExportPNG={chart.downloadAsPng}
          data={data ?? []}
        />
      }
    >
      {chart.component}
      {chart.exportComponent}
    </Card>
  );
};

export { LeadByAssetPerformance };
