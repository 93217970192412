import { Box } from "@chakra-ui/react";
import { ActiveCampaignForList } from "@intentsify/types";
import { ColumnDef } from "@tanstack/react-table";
import { useNavigate } from "react-router";
import { CampaignProgress, EmptyCell } from "components";
import { CampaignLeadPerformanceScreenDefinition } from "screens";

export const activeCampaignsTableColumns = (): Array<
  ColumnDef<ActiveCampaignForList>
> => {
  return [
    {
      header: "component",
      cell: (props) => {
        // This is fine, linter is confused because of the `cell` function name.
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const navigate = useNavigate();

        if (!props.row.original) {
          return <EmptyCell />;
        }

        const {
          displayName,
          campaignId,
          startDate,
          leadsNumber,
          bookedLeadsNumber,
          endDate,
          leadsProgressScore,
        } = props.row.original;

        return (
          <Box mb={2} mt={1}>
            <CampaignProgress
              startDate={startDate}
              endDate={endDate}
              campaignId={campaignId}
              title={displayName}
              currentValue={leadsNumber}
              goalValue={bookedLeadsNumber}
              progressScore={leadsProgressScore}
              onPermormanceClick={() => {
                navigate(
                  CampaignLeadPerformanceScreenDefinition.navigate({
                    campaignId,
                  })
                );
              }}
            />
          </Box>
        );
      },
    },
  ];
};
