import { Icon, Text, useColorModeValue } from "@chakra-ui/react";
import { ProgrammaticDomainFileForList, SegmentState } from "@intentsify/types";
import { getExportFileName, toNumberDisplayValue } from "@intentsify/utils";
import { ColumnDef } from "@tanstack/react-table";
import { Endpoints } from "api";
import { Actions, EmptyCell } from "components";
import { useMemo } from "react";
import { AiOutlineDownload } from "react-icons/ai";
import { FiFileText } from "react-icons/fi";
import { RiCheckboxCircleFill } from "react-icons/ri";
import { useNavigate } from "react-router";
import { ActionItems } from "types";
import { useDownloadFile } from "utils";
import { ProgrammaticDomainsFileDetailsScreenDefinition } from "../../ProgrammaticDomainsDetails/ProgrammaticDomainsFileDetails.definition";
import { statusColor } from "../ProgrammaticDomains.utils";

const ActionItemsComponent = ({
  fileName: originalFileName,
  id,
}: {
  fileName: string;
  id: number;
}) => {
  const navigate = useNavigate();
  const { download, getDownloadingStatus } = useDownloadFile();

  const fileName =
    originalFileName.split(".").shift() ??
    getExportFileName({
      data: {
        id,
      },
      extension: false,
      prefix: "programmatic_domains",
    });

  const handleRedirect = () => {
    navigate(
      ProgrammaticDomainsFileDetailsScreenDefinition.navigate({
        programmaticDomainsFileId: id,
      })
    );
  };

  const handleDownloadClick = (fileId: number) => {
    if (fileId) {
      download({
        url: Endpoints.ProgrammaticDomains.Get.Download(Number(fileId)),
        fileName: fileName,
        fileExtension: "csv",
      });
    }
  };

  const items: ActionItems[] = [
    {
      label: "View Details",
      accessPath: "programmaticCampaign.programmaticDomain",
      icon: <FiFileText />,
      onClick: handleRedirect,
    },
    {
      label: "Download CSV",
      accessPath: "programmaticCampaign.programmaticDomain",
      icon: <AiOutlineDownload />,
      isDisabled: getDownloadingStatus(fileName) === "inProgress",
      onClick: () => handleDownloadClick(id),
      tootlipPlacement: "bottom-end",
    },
  ];

  return <Actions items={items} />;
};

const useProgrammaticDomainsTableColumns = () => {
  const iconColor = useColorModeValue("brand.500", "gray.25");

  return useMemo<ColumnDef<ProgrammaticDomainFileForList>[]>(
    () => [
      {
        header: "ID",
        accessorKey: "programmaticDomainsFileId",
      },
      {
        header: "Company",
        accessorKey: "companyName",
      },
      {
        header: "Tag",
        accessorKey: "tag",
      },
      {
        header: "Region",
        accessorKey: "region",
      },
      {
        header: "IP Addresses Count",
        accessorKey: "ipAddressesCount",
        cell: ({ row }) => {
          return row.original.ipAddressesCount !== 0 ? (
            toNumberDisplayValue(row.original.ipAddressesCount)
          ) : (
            <EmptyCell />
          );
        },
      },
      {
        header: "User IDs Count",
        accessorKey: "userIdsCount",
        cell: ({ row }) => {
          return row.original.userIdsCount !== 0 ? (
            toNumberDisplayValue(row.original.userIdsCount)
          ) : (
            <EmptyCell />
          );
        },
      },
      {
        header: "MAIDs Count",
        accessorKey: "maidsCount",
        cell: ({ row }) => {
          return row.original.maidsCount !== 0 ? (
            toNumberDisplayValue(row.original.maidsCount)
          ) : (
            <EmptyCell />
          );
        },
      },
      {
        header: "IP Address Segment Status",
        accessorKey: "ipAddressSegmentState",
        cell: ({ row }) => {
          return row.original?.ipAddressSegmentState !==
            SegmentState.STATE_UNSPECIFIED ? (
            <Text
              fontWeight="bold"
              textTransform={"uppercase"}
              color={statusColor(row.original.ipAddressSegmentState)}
            >
              {row.original.ipAddressSegmentState.replaceAll("_", " ")}
            </Text>
          ) : (
            <EmptyCell />
          );
        },
      },
      {
        header: "User ID Segment Status",
        accessorKey: "ipAddressSegmentState",
        cell: ({ row }) => {
          return row.original?.userIdSegmentState !==
            SegmentState.STATE_UNSPECIFIED ? (
            <Text
              fontWeight="bold"
              textTransform={"uppercase"}
              color={statusColor(row.original.userIdSegmentState)}
            >
              {row.original.userIdSegmentState.replaceAll("_", " ")}
            </Text>
          ) : (
            <EmptyCell />
          );
        },
      },
      {
        header: "MAID Segment Status",
        accessorKey: "maidSegmentState",
        cell: ({ row }) => {
          return row.original?.maidSegmentState !==
            SegmentState.STATE_UNSPECIFIED ? (
            <Text
              fontWeight="bold"
              textTransform={"uppercase"}
              color={statusColor(row.original.maidSegmentState)}
            >
              {row.original.maidSegmentState.replaceAll("_", " ")}
            </Text>
          ) : (
            <EmptyCell />
          );
        },
      },
      {
        header: "Beeswax Count",
        accessorKey: "beeswaxUserCount",
        enableSorting: false,
        cell: ({ row }) => {
          return row.original.beeswaxUserCount !== 0 ? (
            toNumberDisplayValue(row.original.beeswaxUserCount)
          ) : (
            <EmptyCell />
          );
        },
      },
      {
        header: "ISP Data",
        accessorKey: "hasIspData",
        cell: ({ row }) => {
          return (
            <>
              {row.original?.hasIspData && (
                <Icon as={RiCheckboxCircleFill} w={6} h={6} color={iconColor} />
              )}
            </>
          );
        },
      },
      {
        header: "Created At",
        accessorKey: "createdAt",
      },
      {
        header: "Actions",
        enableSorting: false,
        cell: ({ row }) => {
          return row.original?.programmaticDomainsFileId ? (
            <ActionItemsComponent
              id={row.original?.programmaticDomainsFileId}
              fileName={row.original?.fileName}
            />
          ) : (
            <EmptyCell />
          );
        },
      },
    ],
    [iconColor]
  );
};

export { useProgrammaticDomainsTableColumns };
