import { Navigate, Route, Routes } from "react-router-dom";
import { Agreement, Login, ResetPassword, SetPassword } from "auth";
import { AgreementScreenDefinition } from "auth/screens/Agreement/Agreement.definition";
import { LoginScreenDefinition } from "auth/screens/Login/Login.definition";
import { ResetPasswordScreenDefinition } from "auth/screens/ResetPassword/ResetPassword.definition";
import { SetPasswordScreenDefinition } from "auth/screens/SetPassword/SetPassword.definition";
import { RequireAuth } from "components";

const AuthRouter = () => {
  return (
    <Routes>
      {LoginScreenDefinition.routePath.map((path) => (
        <Route key={path} path={path} element={<Login />} />
      ))}

      {ResetPasswordScreenDefinition.routePath.map((path) => (
        <Route key={path} path={path} element={<ResetPassword />} />
      ))}

      {SetPasswordScreenDefinition.routePath.map((path) => (
        <Route key={path} path={path} element={<SetPassword />} />
      ))}

      {AgreementScreenDefinition.routePath.map((path) => (
        <Route
          key={path}
          path={path}
          element={
            <RequireAuth>
              <Agreement />
            </RequireAuth>
          }
        />
      ))}

      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>
  );
};

export { AuthRouter };
