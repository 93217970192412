import { ListCompaniesRO } from "@intentsify/dto";
import { CompanyForList, SortDirection } from "@intentsify/types";
import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { Endpoints, apiService } from "api";
import { Duration } from "luxon";
import { AsyncReturnType } from "type-fest";
import { FetchDataParamsWithToken } from "types";

export const defaultListPaginatedCompaniesParams: FetchDataParamsWithToken<
  keyof CompanyForList
> = {
  pageToken: "",
  order_by: "companyId",
  order: SortDirection.DESC,
};

const listPaginatedCompanies = async (
  params: FetchDataParamsWithToken<
    keyof CompanyForList
  > = defaultListPaginatedCompaniesParams
): Promise<ListCompaniesRO> => {
  const { data } = await apiService.get(Endpoints.Companies.Get.Paginated, {
    params,
  });

  return data;
};

const queryKey = (params: FetchDataParamsWithToken<keyof CompanyForList>) =>
  ["companiesList", params] as const;

type UsePaginatedCompaniesQueryOptions = UseQueryOptions<
  AsyncReturnType<typeof listPaginatedCompanies>
>;

export const usePaginatedCompanies = (
  params: FetchDataParamsWithToken<keyof CompanyForList>,
  queryOptions?: UsePaginatedCompaniesQueryOptions
) => {
  return useQuery({
    queryKey: queryKey(params),
    queryFn: () => listPaginatedCompanies(params),
    staleTime: Duration.fromObject({ minutes: 5 }).as("millisecond"),
    ...queryOptions,
  });
};
