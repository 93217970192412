import {
  AccountsInBuyerResearchStageStatisticsRO,
  WeeklySnapshotDTO,
} from "@intentsify/dto";
import { useQuery } from "@tanstack/react-query";
import { getAccountAnalysisStats } from "./AccountAnalysis.requests";

const nonInitializedStats: AccountsInBuyerResearchStageStatisticsRO[] = [
  "Total Active",
  "Decision",
  "Consideration",
  "Interest",
  "Awareness",
].map((buyerResearchStage) => ({
  buyerResearchStage,
  countAccounts: Number.NaN,
  countAccountsChange: null,
}));

export const useAccountAnalysisStats = (
  rawCampaignId: number | string | undefined,
  { weekTo, yearTo }: WeeklySnapshotDTO
) => {
  const campaignId = Number(rawCampaignId);
  const { data, isFetching } = useQuery(
    ["getAccountAnalysisStats", campaignId, weekTo, yearTo],
    () => getAccountAnalysisStats(campaignId, { weekTo, yearTo }),
    {
      enabled: !Number.isNaN(campaignId),
    }
  );

  let stableData = nonInitializedStats;
  if (data) {
    stableData = nonInitializedStats.map(
      (brsDetails) =>
        data.find(
          ({ buyerResearchStage }) =>
            brsDetails.buyerResearchStage === buyerResearchStage
        ) ?? brsDetails
    );
  }

  return {
    data: stableData,
    isFetching,
  };
};
