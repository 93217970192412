import { BusinessUnit } from "@intentsify/types";
import { ColumnDef } from "@tanstack/react-table";
import { useCallback, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getColumns } from "./BusinessUnits.columns";
import {
  defaultListPaginatedCompanyBusinessUnitsParams,
  listCompanyBusinessUnits,
} from "api";
import { Table } from "components";
import { PaginationType } from "components/Table/Table/Table.types";
import { FetchDataParamsWithToken } from "types";

type BusinessUnitsProps = {
  companyId: number;
};

const BusinessUnits = ({ companyId }: BusinessUnitsProps) => {
  const [fetchDataParams, setFetchDataParams] = useState<
    FetchDataParamsWithToken<keyof BusinessUnit>
  >(defaultListPaginatedCompanyBusinessUnitsParams);

  const columns = useMemo<ColumnDef<BusinessUnit>[]>(() => getColumns(), []);

  const { data, isFetching } = useQuery(
    ["businessUnits", companyId, fetchDataParams],
    () => listCompanyBusinessUnits(companyId, fetchDataParams)
  );

  const handleOnFetchDataChange = useCallback(
    (params: FetchDataParamsWithToken<keyof BusinessUnit>) => {
      setFetchDataParams(params);
    },
    []
  );

  return (
    <Table
      columns={columns}
      isFetching={isFetching}
      data={data?.businessUnits || []}
      defaultSort={[
        {
          id: defaultListPaginatedCompanyBusinessUnitsParams.order_by,
          desc: true,
        },
      ]}
      onFetchDataChange={handleOnFetchDataChange}
      paginationType={PaginationType.TOKEN}
      nextPageToken={data?.nextPageToken}
    />
  );
};

export { BusinessUnits };
