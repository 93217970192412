import {
  GetAccountsReachedOverTimeRO,
  GetTopAccountsBySpendRO,
  WeeklySnapshotDTO,
  WeeklySnapshotRangeDTO,
} from "@intentsify/dto";
import { ImpressionsAndClicksOverTime } from "@intentsify/types";
import { Endpoints, apiService } from "api";

export const requestWeeklySnapshotReport = async (
  campaignId: number,
  filters: WeeklySnapshotRangeDTO
) => {
  const { data } = await apiService.post(
    Endpoints.Reports.WeeklySnapshot.Post.RequestReport(campaignId),
    {
      ...filters,
    }
  );

  return data;
};

export const getTopAccountsBySpend = async (
  campaignId: string | number,
  filters: WeeklySnapshotDTO
) => {
  const { data } = await apiService.get<GetTopAccountsBySpendRO>(
    Endpoints.Campaigns.Get.WeeklySnapshot.TopAccountsBySpend(campaignId),
    { params: filters }
  );

  return data;
};

export const getAccountsReachedOverTime = async (
  campaignId: string | number,
  filters: WeeklySnapshotDTO
) => {
  const { data } = await apiService.get<GetAccountsReachedOverTimeRO>(
    Endpoints.Campaigns.Get.WeeklySnapshot.AccountsReachedOverTime(campaignId),
    { params: filters }
  );

  return data;
};

export const getImpressionsAndClicksOverTime = async (
  campaignId: string | number,
  filters: WeeklySnapshotDTO
) => {
  const { data } = await apiService.get<ImpressionsAndClicksOverTime[]>(
    Endpoints.Campaigns.Get.WeeklySnapshot.ImpressionsAndClicksOverTime(
      campaignId
    ),
    { params: filters }
  );

  return data;
};
