import {
  AuthenticatedUser,
  NotificationSetting,
  UserImpersonation,
} from "@intentsify/types";
import { StateCreator } from "zustand";

type UserStoreState = {
  user: AuthenticatedUser | null;
  impersonation: UserImpersonation | null;
  isProcessingImpersonation: boolean;
  isImpersonationModalOpen: boolean;
};

type UserStoreActions = {
  setUser: (user: UserStoreState["user"]) => void;
  setUserNotificationSettings: (settings: NotificationSetting[]) => void;
  reset: () => void;
  setIsImpersonationProcessing: (value: boolean) => void;
  setImpersonation: (impersonation: UserStoreState["impersonation"]) => void;
  setIsImpersonationModalOpen: (value: boolean) => void;
  setUserCompanyTrialEndDate: (date: string | undefined) => void;
};

export type UserStore = UserStoreState & UserStoreActions;

export const defaultUserState: UserStoreState = {
  user: null,
  impersonation: null,
  isProcessingImpersonation: false,
  isImpersonationModalOpen: false,
};

export const createUserSlice: StateCreator<
  UserStore,
  [["zustand/immer", never]]
> = (set) => ({
  ...defaultUserState,
  reset: () => set(defaultUserState),
  setUser: (user: UserStore["user"]) =>
    set((state: UserStore) => {
      state.user = user;
    }),
  setUserNotificationSettings: (settings: NotificationSetting[]) =>
    set((state: UserStore) => {
      if (!state.user) {
        return;
      }
      state.user.settings.notifications = settings;
    }),
  setUserCompanyTrialEndDate: (date: string | undefined) =>
    set((state: UserStore) => {
      if (state.impersonation?.impersonates) {
        state.impersonation.impersonates.companyTrialEndDate = date;
      } else if (state.user) {
        state.user.companyTrialEndDate = date;
      }
    }),
  setIsImpersonationModalOpen: (value: boolean) =>
    set((state: UserStore) => {
      state.isImpersonationModalOpen = value;
    }),
  setIsImpersonationProcessing: (value: boolean) =>
    set((state: UserStore) => {
      state.isProcessingImpersonation = value;
    }),
  setImpersonation: (impersonation: UserStore["impersonation"]) =>
    set((state: UserStore) => {
      state.impersonation = impersonation;
    }),
});
