import { signOutRequest } from "api";
import { useCallback } from "react";

interface UseSignOutOptions {
  onSuccess?: () => void;
}

export const useSignOut = (options?: UseSignOutOptions) => {
  return useCallback(async () => {
    await signOutRequest({ showSessionExpiredToast: false });
    options?.onSuccess?.();
  }, [options]);
};
