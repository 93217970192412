import { ProgrammaticDomainsUploadScreen } from "types";
import { createScreen } from "utils/createScreen";

const ProgrammaticDomainsUploadScreenDefinition = createScreen({
  name: ProgrammaticDomainsUploadScreen,
  path: "/media-activation/programmatic-segments/manual/upload",
  routePath: ["/manual/upload"],
  accessPath: "programmaticCampaign.programmaticDomain",
});

export { ProgrammaticDomainsUploadScreenDefinition };
