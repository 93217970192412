import { SortDirection, PaginatedResponse } from "@intentsify/types";
import { CampaignsLeadsFilters } from "../../CampaignsLeads";
import { apiService, Endpoints } from "api";
import { Item } from "types";
import { getPaginatedQuery } from "utils";

type PaginatedAccountLeadsParams = {
  accounts: number[];
  page: number;
  per_page: number;
};

export const listPaginatedAccountsLeads =
  (campaignId: number) =>
  async (
    params: PaginatedAccountLeadsParams
  ): Promise<PaginatedResponse<Item>> => {
    const query = getPaginatedQuery<Item, CampaignsLeadsFilters>(
      Endpoints.Campaigns.Get.Leads.PaginatedAccounts(campaignId),
      { ...params, order: SortDirection.ASC, order_by: "label" }
    );

    const { data } = await apiService.get(query);
    return data;
  };
