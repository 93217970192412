import { Box, Button, Flex, Spinner, Text } from "@chakra-ui/react";
import { Virtuoso } from "react-virtuoso";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  useMarkAsRead,
  useOnLoadMore,
  useUnreadCount,
} from "../../Notifications.hooks";
import {
  isFetchingSelector,
  showReadState,
  userNotificationsSelector,
} from "../../Notifications.state";
import { NotificationItem } from "../NotificationItem/NotificationItem";

const MAX_HEIGHT = "90vh";

const List = () => {
  const onLoadMore = useOnLoadMore();
  const isFetching = useRecoilValue(isFetchingSelector);
  const markAsRead = useMarkAsRead();
  const [showRead, setShowRead] = useRecoilState(showReadState);
  const data = useRecoilValue(userNotificationsSelector);
  const hasUnread = useUnreadCount() > 0;

  const showReadButton = !showRead ? (
    <Flex mt={4} justifyContent="center">
      <Button size="xs" variant="secondary" onClick={() => setShowRead(true)}>
        Show Read
      </Button>
    </Flex>
  ) : null;

  return (
    <Flex
      grow={1}
      maxH={MAX_HEIGHT}
      flexDir="column"
      alignItems="stretch"
      overflowY="auto"
    >
      {!hasUnread && !showRead && (
        <Flex flexGrow={1} mb={4} alignItems="center" justifyContent="center">
          <Text fontSize="small">You have no unread notifications.</Text>
        </Flex>
      )}

      {!showRead ? (
        <Box>
          {data.map((n, i) => (
            <Box key={n.id} mt={i > 0 ? 1 : 0}>
              <NotificationItem
                markAsRead={() => markAsRead([n.id])}
                data={n}
              />
            </Box>
          ))}

          {showReadButton}
        </Box>
      ) : (
        <>
          <Box height={MAX_HEIGHT}>
            <Virtuoso
              endReached={onLoadMore}
              components={{
                Footer: () => {
                  return !isFetching ? (
                    showReadButton
                  ) : (
                    <Flex
                      flexGrow={1}
                      height={50}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Spinner />
                    </Flex>
                  );
                },
              }}
              data={data}
              itemContent={(index, data) => {
                return (
                  <Box mt={index > 0 ? 1 : 0}>
                    <NotificationItem
                      markAsRead={() => markAsRead([data.id])}
                      data={data}
                    />
                  </Box>
                );
              }}
            />
          </Box>
        </>
      )}
    </Flex>
  );
};

export { List };
