import { Route, Routes } from "react-router";
import { Navigate } from "react-router-dom";
import { Publishers, SinglePublisher } from "screens";
import { PublishersScreenDefinition } from "screens/Admin/screens/Publishers/screens/Publishers/Publishers.definition";
import { SinglePublisherScreenDefinition } from "screens/Admin/screens/Publishers/screens/SinglePublisher/SinglePublisher.definition";

const PublishersRouter = () => {
  return (
    <Routes>
      {PublishersScreenDefinition.routePath.map((path) => (
        <Route key={path} path={path} element={<Publishers />} />
      ))}

      {SinglePublisherScreenDefinition.routePath.map((path) => (
        <Route key={path} path={path} element={<SinglePublisher />} />
      ))}

      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>
  );
};

export { PublishersRouter };
