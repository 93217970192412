import { Box, Divider, Flex, Heading, useToast } from "@chakra-ui/react";
import { TempAccountFileDTO } from "@intentsify/dto";
import { GenericUserJobType } from "@intentsify/types";
import { isPopulatedArray } from "@intentsify/utils";
import { useState } from "react";
import { isTempAccountFileDTO } from "screens/Campaigns/screens/CampaignsWizard/components/DomainsStep/DomainsForm";
import { DomainsUploadTus } from "shared/components/DomainsUploadTus/DomainsUploadTus";
import { UploadedFileEntry } from "shared/components/UploadedFiles/UploadedFileEntry";
import { UploadedFiles } from "shared/components/UploadedFiles/UploadedFiles";
import { UploadedFilesWrapper } from "shared/components/UploadedFiles/UploadedFilesWrapper";
import { talToolsUpload } from "upload/talToolsUpload";
import { useUploadedFiles } from "../../hooks/useUploadedFiles";
import { ResultsTable } from "../ResultsTable/ResultsTable";
import { SmarTALForm } from "./SmarTALForm";

const MAX_DOMAINS_COUNT = 200;
export const DEFAULT_LOOKALIKE_DOMAINS = 500;
export const MAX_LOOKALIKE_DOMAINS = 1000;

const SmarTAL = () => {
  const [selectedFile, setSelectedFile] = useState<
    TempAccountFileDTO | undefined
  >();

  const { files, filesForList, setFiles } = useUploadedFiles();
  const toast = useToast();

  return (
    <Flex direction="column" gap="4">
      <Box>
        <Heading as="h2" size="sm" my="4">
          Instructions
        </Heading>
        <Box as="ol" fontSize="xs" ml="8">
          <li>
            Upload a CSV file with one column containing domains and a header
            row with single value “Domain”.{" "}
            <b>You can upload a maximum of {MAX_DOMAINS_COUNT} domains.</b>
          </li>
          <li>
            Adjust the maximum #of lookalike domains to be returned. The default
            is {DEFAULT_LOOKALIKE_DOMAINS}, and maximum is{" "}
            {MAX_LOOKALIKE_DOMAINS}.
          </li>
          <li>Click "Process SmartTAL"</li>
          <li>
            You'll receive an email when the list has been processed containing
            the link to download.
          </li>
        </Box>

        <Heading as="h2" size="sm" my="4">
          Best Practices
        </Heading>
        <Box as="ul" fontSize="xs" ml="8">
          <li>
            Seed domains should be similar to each other. We recommend keeping
            seed domains within the same or similar industries. If they span
            different industries, best results are achieved by splitting them
            into 2 requests.
          </li>
          <li>
            For optimal results, upload seed domains that have previously
            demonstrated high success for the customer.
          </li>
        </Box>

        <Box mt={4}>
          <DomainsUploadTus
            dropzoneSubHeader={`
                    Please upload a CSV file containing a "Domain" 
                    column. Up to ${MAX_DOMAINS_COUNT} domains is allowed.
                  `}
            tusUpload={talToolsUpload}
            uploadedFileNames={files.map((i) => i.fileName)}
            onFileUpload={(tempFileName, fileName, validationResults) => {
              const newFile = {
                tempFileName,
                fileName,
                accountsCount: validationResults.validRowCount,
              };

              if (validationResults.validRowCount > MAX_DOMAINS_COUNT) {
                toast({
                  status: "warning",
                  description: `Sorry, you can upload at most ${MAX_DOMAINS_COUNT} domains and your file has ${validationResults.validRowCount} domains. Please edit your file or try a different one.`,
                  duration: 1000 * 10,
                  isClosable: true,
                });

                return;
              }

              setFiles((prev) => {
                return [
                  ...prev.filter((file) => file.fileName !== fileName),
                  newFile,
                ];
              });

              setSelectedFile(newFile);
            }}
          />

          {isPopulatedArray(filesForList) && (
            <UploadedFilesWrapper variant="plain">
              <UploadedFiles hideUploadedAt selectable>
                {filesForList.map((uploadedFileEntryProps) => (
                  <UploadedFileEntry
                    isSelected={
                      uploadedFileEntryProps.file.fileName ===
                      selectedFile?.fileName
                    }
                    isSelectable
                    onSelect={(file) => {
                      if (file && isTempAccountFileDTO(file)) {
                        setSelectedFile(file);
                      } else {
                        setSelectedFile(undefined);
                      }
                    }}
                    hideDownload
                    hideUploadedAt
                    key={uploadedFileEntryProps.file.fileName}
                    file={uploadedFileEntryProps.file}
                  />
                ))}
              </UploadedFiles>
            </UploadedFilesWrapper>
          )}
        </Box>
      </Box>

      <SmarTALForm selectedFile={selectedFile} />

      <Divider />

      <ResultsTable toolType={GenericUserJobType.SMARTAL} />
    </Flex>
  );
};

export { SmarTAL };
