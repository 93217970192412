import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Center,
  Flex,
} from "@chakra-ui/react";
import { TusUpload } from "@intentsify/tus/dist/react";
import { TusUploadProgress } from "components/Upload/TusUploadProgress";
import { useState } from "react";
import { FiX } from "react-icons/fi";
import { ContentLibraryFile } from "./ContentLibraryFile";

const transition = `
  all 0.24s cubic-bezier(0.25, 1, 0.5, 1)
`;

export const ContentLibraryUploadProgress = (props: {
  tusUpload: TusUpload;
}) => {
  const [index, setIndex] = useState<number | number[]>([0]);

  const files = props.tusUpload.useFiles();
  const isUploading = props.tusUpload.useIsUploading();
  const removeAllFiles = props.tusUpload.useRemoveAllFiles();
  const uploadFilesStatus = useUploadFilesStatus(props.tusUpload);

  return (
    <Box
      position="fixed"
      right={0}
      bottom={0}
      zIndex="sticky"
      opacity={uploadFilesStatus ? 1 : 0}
      pointerEvents={uploadFilesStatus ? "all" : "none"}
      transform={`translateY(${uploadFilesStatus ? 0 : 4})`}
      transition={transition}
    >
      <Accordion
        index={index}
        onChange={setIndex}
        w="400px"
        m="6"
        rounded="md"
        shadow={index === 0 || (index as number[])[0] ? "xl" : "sm"}
        overflow="hidden"
        borderWidth="1px"
        borderStyle="solid"
        bg="white"
        borderColor="blackAlpha.300"
        _dark={{ bg: "brand.450", borderColor: "whiteAlpha.200" }}
      >
        <AccordionItem border="none">
          <AccordionButton
            w="full"
            justifyContent="space-between"
            py="4"
            px="4"
            h="auto"
            bg="transparent"
            rounded="none"
            fontWeight="semibold"
            _dark={{ _hover: { bg: "brand.400" } }}
            onClick={() => {
              setIndex((index) =>
                index === 0 || (index as number[])[0] === 0 ? [] : [0]
              );
            }}
          >
            <Flex alignItems="center" gap="2">
              {uploadFilesStatus}

              <TusUploadProgress tusUpload={props.tusUpload} />
            </Flex>

            <Center position="relative">
              <AccordionIcon
                mr={isUploading ? 0 : 12}
                transition={transition}
              />

              <Box
                role="button"
                position="absolute"
                right={-2.5}
                px="3"
                py="2.5"
                rounded="md"
                aria-label="Close upload progress"
                visibility={isUploading ? "hidden" : "visible"}
                _hover={{
                  bg: "blackAlpha.300",
                  _dark: { bg: "whiteAlpha.300" },
                }}
                transition={transition}
                onClick={(e) => {
                  e.stopPropagation();
                  removeAllFiles();
                }}
              >
                <FiX />
              </Box>
            </Center>
          </AccordionButton>

          <AccordionPanel p={0} h="340px" overflowY="auto">
            {files.map((file) => (
              <ContentLibraryFile
                key={file.name}
                file={file}
                tusUpload={props.tusUpload}
              />
            ))}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};

const useUploadFilesStatus = (tusUpload: TusUpload) => {
  const contentLibraryFiles = tusUpload.useFiles();

  if (contentLibraryFiles.length === 0) {
    return null;
  }

  const uploadingContentLibraryFilesLength = contentLibraryFiles.filter(
    (file) => file.status === "uploading"
  ).length;

  if (uploadingContentLibraryFilesLength === 0) {
    return `Uploaded ${contentLibraryFiles.length} ${
      contentLibraryFiles.length === 1 ? "file" : "files"
    }`;
  }

  return `Uploading ${uploadingContentLibraryFilesLength} ${
    uploadingContentLibraryFilesLength === 1 ? "file" : "files"
  }`;
};
