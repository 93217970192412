import { Notification, NotificationTypes } from "@intentsify/types";
import { Endpoints, apiService } from "api";
import { valueToQueryURL } from "utils";

export type ListNotificationsParams = {
  hasBeenRead: boolean;
  pageToken: string | undefined;
  notificationTypes: NotificationTypes[];
};

export const listPaginatedNotifications = async (
  params: ListNotificationsParams
) => {
  const { data } = await apiService.get<{
    notifications: Notification[];
    nextPageToken: string;
  }>(Endpoints.Notifications.Get.Paginated, {
    params: {
      ...params,
      notificationTypes: valueToQueryURL(params.notificationTypes),
    },
  });
  return data;
};

export const markAsReadRequest = async (ids: number[]) => {
  const { data } = await apiService.post(
    Endpoints.Notifications.Post.MarkAsRead,
    {
      ids,
    }
  );
  return data;
};

export const markAllAsReadRequest = async () => {
  const { data } = await apiService.post(
    Endpoints.Notifications.Post.MarkAllAsRead
  );
  return data;
};
