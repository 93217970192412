import { SimpleTable, Tooltip, Upload } from "components";
import { InfoIcon } from "@chakra-ui/icons";
import { Box } from "@chakra-ui/layout";
import { Section } from "../../../components/Section";
import { useComponentColors } from "theme";
import { ErrorCode, useDropzone } from "react-dropzone";
import { Spinner, useToast } from "@chakra-ui/react";
import { targetPersonaUpload } from "../../../upload/targetPersonaUpload";
import { z } from "zod";
import {
  DEFAULT_BLOCKLIST_FILE_NAME,
  useTargetPersonaBlocklistMetadata,
} from "./useTargetPersonaBlocklistMetadata";
import { useTargetPersonaBlocklistColumns } from "./TargetPersonaBlocklist.hooks";
import { useDeleteTargetPersonaBlocklist } from "./useDeleteTargetPersonaBlocklist";
import { useConfirm } from "../../../components/ConfirmDialog/useConfirm";
import {
  JUST_NOW_DATE,
  RemoteFileUploaded,
  RemoteFileUploading,
  TargetPersonaBlocklistFile,
} from "./TargetPersonaBlocklist.types";
import { useEffect, useState } from "react";
import {
  buildZodCsvValidator,
  ProgressEventDetails,
  validateCsvStream,
} from "@intentsify/utils/csvStream/browser";

const blocklistFileReference = {
  fileName: "Blocklist Template.csv",
  data: [
    { email: "blocked-test@intentsify.io" },
    { email: "blocked-used-2@intentsify.io" },
  ],
};

interface TargetPersonaBlocklistProps {
  companyId: number;
}

const resumeUploadConfirm = {
  title: "Blocklist uploading",
  description: `We detected that uploading blocklist was interrupted. Would you like to resume uploading?`,
  confirmText: "Continue",
};

const replaceBlocklistConfig = ({
  formattedDate,
}: {
  formattedDate: string;
}) => ({
  title: "Replace blocklist with a new one?",
  description: `Are you sure that you want to replace blocklist from ${formattedDate} with a new one?`,
  confirmText: "Replace blocklist",
});

const csvValidator = () =>
  buildZodCsvValidator(
    z.object({
      Email: z.string().email(),
    }),
    {
      quoteMode: "none",
    }
  );

export const TargetPersonaBlocklist = ({
  companyId,
}: TargetPersonaBlocklistProps) => {
  const componentColors = useComponentColors();
  const confirm = useConfirm();

  const uploadFile = targetPersonaUpload.useUploadFile();
  const tusFiles = targetPersonaUpload.useFiles();
  const resumeUploads = targetPersonaUpload.useResumeUploads();
  const removeAllFiles = targetPersonaUpload.useRemoveAllFiles();
  const hasResumableFiles = targetPersonaUpload.useHasResumableFiles();

  const uploadingFiles = tusFiles.filter(
    ({ status }) => status === "uploading"
  );
  const {
    update: updateBlocklistMetadata,
    data: uploadedBlocklistMetadata,
    isInitialLoading,
    isLoading,
  } = useTargetPersonaBlocklistMetadata();
  const { mutateAsync: deleteUploadedBlocklist, isLoading: isDeleting } =
    useDeleteTargetPersonaBlocklist();

  useEffect(() => {
    const resume = async () => {
      if (await confirm(resumeUploadConfirm)) {
        const [file] = resumeUploads();
        updateBlocklistMetadata({
          fileName: DEFAULT_BLOCKLIST_FILE_NAME,
          size: file.size,
          lastModified: JUST_NOW_DATE,
          downloadLink: `/target-persona/blocklist/download`,
        });
      } else {
        removeAllFiles();
      }
    };

    if (hasResumableFiles) {
      void resume();
    }
  }, [
    hasResumableFiles,
    resumeUploads,
    removeAllFiles,
    confirm,
    companyId,
    updateBlocklistMetadata,
  ]);

  const toast = useToast();
  const [validationProgress, setValidationProgress] = useState<
    number | undefined
  >(undefined);
  const columns = useTargetPersonaBlocklistColumns(() => {
    void deleteUploadedBlocklist();
  });
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: { "text/csv": [".csv"] },
    maxFiles: 1,
    minSize: 0,
    disabled: isDeleting || isLoading || validationProgress !== undefined,
    maxSize: 500 * 1024 * 1024,
    onDropRejected: (issues) => {
      const isFileTooLarge = issues.find((rejection) =>
        rejection.errors.find((error) => error.code === ErrorCode.FileTooLarge)
      );

      if (isFileTooLarge) {
        toast({
          status: "error",
          description: "File too large. Maximum size allowed: 50MB.",
          duration: null,
          isClosable: true,
        });

        return;
      }

      toast({
        status: "error",
        description: issues[0].errors[0].message,
        duration: null,
        isClosable: true,
      });
    },
    onDrop: (files) => {
      const [file] = files;

      if (!file) {
        return;
      }

      const upload = () =>
        uploadFile(file, {
          onSuccess: () => {
            updateBlocklistMetadata({
              fileName: DEFAULT_BLOCKLIST_FILE_NAME,
              size: file.size,
              lastModified: JUST_NOW_DATE,
              downloadLink: `/target-persona/blocklist/download`,
            });
          },
          metadata: {
            filename: file.name,
            filetype: file.type,
            companyId: String(companyId),
          },
        });
      const safeUpload = async () => {
        if (uploadedBlocklistMetadata) {
          const formattedDate =
            uploadedBlocklistMetadata.lastModified instanceof Date
              ? uploadedBlocklistMetadata.lastModified.toISOString()
              : uploadedBlocklistMetadata.lastModified;
          if (await confirm(replaceBlocklistConfig({ formattedDate }))) {
            await deleteUploadedBlocklist();
            upload();
          }
        } else {
          upload();
        }
      };
      const fileSize = file.size;
      const { events, whenComplete } = validateCsvStream(
        file.stream().pipeThrough(new TextDecoderStream()),
        csvValidator()
      );
      events.addEventListener("progress", ((
        e: CustomEvent<ProgressEventDetails>
      ) => {
        setValidationProgress(e.detail.proceed / fileSize);
      }) as EventListener);
      whenComplete
        .finally(() => {
          setValidationProgress(undefined);
        })
        .then(() => safeUpload())
        .catch((err: Error) => {
          toast({
            status: "error",
            description: "CSV is invalid: " + err.message,
            duration: null,
            isClosable: true,
          });
        });
    },
  });

  const uploadingFile = uploadingFiles.at(-1);
  const remoteUploadingFile = uploadingFile
    ? ({
        type: "remote",
        fileName: DEFAULT_BLOCKLIST_FILE_NAME,
        size: uploadingFile.size,
        isDeleting,
        isUploading: true,
      } as RemoteFileUploading)
    : null;
  const remoteUploadedFile = uploadedBlocklistMetadata
    ? ({
        type: "remote",
        isUploading: false,
        isDeleting,
        ...uploadedBlocklistMetadata,
      } as RemoteFileUploaded)
    : null;

  const remoteFile = remoteUploadingFile ?? remoteUploadedFile;

  return (
    <Section
      name="Upload a blocklist"
      defaultIndex={[0]}
      tooltip={
        <Tooltip
          aria-label="Upload emails"
          label={`Upload emails as a CSV file. A "email" column is required. Refer to the template below for additional mapping columns you can upload.`}
        >
          <InfoIcon
            color={componentColors.form.formLabelColor}
            w={4}
            h={4}
            ml={2}
            mt={-1}
          />
        </Tooltip>
      }
    >
      <Upload
        testId="blocklist-upload-zone"
        getInputProps={getInputProps}
        getRootProps={getRootProps}
        subHeader={
          "CSV with emails. If one uploaded then dropping new one, will override previous one."
        }
        validationProgress={validationProgress}
      />
      <Box mt={4}>
        <SimpleTable<TargetPersonaBlocklistFile>
          columns={columns}
          nonIdealState={{
            variant: "noUploadedFiles",
            onCtaClick: () => void 0,
          }}
          data={[
            {
              type: "local",
              ...blocklistFileReference,
            },
            ...(remoteFile ? [remoteFile] : []),
          ]}
        />
        {isInitialLoading && (
          <Box display="flex" justifyContent="center">
            <Spinner
              thickness="4px"
              speed="0.75s"
              size="md"
              {...componentColors.spinner}
            />
          </Box>
        )}
      </Box>
    </Section>
  );
};
