import { Box, Flex } from "@chakra-ui/react";
import { ProgrammaticCampaignCreativesEngagements } from "@intentsify/types";
import { toNumberDisplayValue } from "@intentsify/utils";
import { ColumnDef } from "@tanstack/react-table";
import { BasedOnValue } from "./CreativeWithHighestEngagement";

export const getColumns = (
  basedOn: BasedOnValue
): ColumnDef<ProgrammaticCampaignCreativesEngagements>[] => {
  const nameColumn: ColumnDef<ProgrammaticCampaignCreativesEngagements> = {
    accessorKey: "creativeName",
    header: "Name",
    cell: (info) => {
      return (
        <Flex
          minH={"24px"} // this is important, TableVirtualised gets buggy if rows are not tall enough
          fontSize="xs"
          alignItems="center"
          overflowWrap="anywhere"
        >
          {String(info.getValue())}
        </Flex>
      );
    },
  };

  switch (basedOn) {
    case "conversions": {
      return [
        nameColumn,
        {
          accessorKey: "conversions",
          header: "Conversions",
          cell: (info) => {
            return <Box fontSize="xs">{String(info.getValue())}</Box>;
          },
        },
        {
          accessorKey: "conversionRate",
          header: "Conversion Rate",
          cell: (info) => {
            return (
              <Box fontSize="xs">
                {toNumberDisplayValue(Number(info.getValue() ?? 0) * 100)}%
              </Box>
            );
          },
        },
      ];
    }

    case "ctr": {
      return [
        nameColumn,
        {
          accessorKey: "clicks",
          header: "Clicks",
          cell: (info) => {
            return <Box fontSize="xs">{String(info.getValue())}</Box>;
          },
        },
        {
          accessorKey: "ctr",
          header: "CTR",
          cell: (info) => {
            const val = Number(info.getValue() ?? 0) * 100;
            return <Box fontSize="xs">{toNumberDisplayValue(val)}%</Box>;
          },
        },
      ];
    }
  }
};
