import { DownloadScreen } from "../../types";
import { createScreen } from "utils/createScreen";

const DownloadScreenDefinition = createScreen({
  name: DownloadScreen,
  path: "/download/:bucket/:s3File/:filename",
  routePath: ["/download/:bucket/:s3File/:filename"],
  accessPath: "campaign.download",
});

export { DownloadScreenDefinition };
