import { Flex } from "@chakra-ui/layout";
import { SignalType } from "@intentsify/types";
import { isPopulatedArray } from "@intentsify/utils";
import { useQuery } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import { Table } from "components";
import { useMemo } from "react";
import { useMeasure } from "react-use";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { accountDetailsAtom } from "screens/IntentAnalysis/screens/CampaignsTrendingDomains/CampaignsTrendingDomains.state";
import { useComponentColors } from "theme";
import {
  singleAccountDetailsFiltersAtom,
  singleAccountDetailsLocationOptionsAtom,
  singleAccountDetailsNamesOptionsAtom,
} from "../../SingleAccountDetails.state";
import { getSingleAccountDetails } from "../../SingleAcountDetails.requests";
import { Filters } from "../Filters";
import { getSingleAccountDetailsColumns } from "./DetailsTable.columns";
import {
  extractNamesAndLocations,
  filterAccountsDetailItem,
} from "./DetailsTable.utils";

type DetailsTableProps = {
  signalType: SignalType;
};

const DetailsTable = ({ signalType }: DetailsTableProps) => {
  const accountDetails = useRecoilValue(accountDetailsAtom);
  const setNameOptions = useSetRecoilState(
    singleAccountDetailsNamesOptionsAtom
  );
  const setLocationsOptions = useSetRecoilState(
    singleAccountDetailsLocationOptionsAtom
  );

  const filters = useRecoilValue(singleAccountDetailsFiltersAtom);

  const enableFilters = ![SignalType.SiteVisitor, SignalType.Display].includes(
    signalType
  );

  const [ref, { height, width }] = useMeasure<HTMLDivElement>();

  const {
    form: { stackBg },
  } = useComponentColors();

  const { data } = useQuery(
    [
      "singleAccountDetails",
      accountDetails?.campaignId,
      accountDetails?.account,
      accountDetails?.dateRange,
      signalType,
    ],
    () => {
      if (accountDetails) {
        return getSingleAccountDetails({
          campaignId: accountDetails.campaignId,
          account: accountDetails.account,
          dateRange: accountDetails.dateRange,
          signalType: [signalType],
        });
      }
    },
    {
      enabled: Boolean(accountDetails),
      onSuccess: (data) => {
        if (data && enableFilters) {
          const { names, locations } = extractNamesAndLocations(data);

          setNameOptions(names);
          setLocationsOptions(locations);
        }
      },
    }
  );

  const columns = useMemo<ColumnDef<any>[]>(
    () =>
      data ? getSingleAccountDetailsColumns(data?.timeseries, signalType) : [],
    [data, signalType]
  );

  const tableData = useMemo(() => {
    return data?.data ?? [];
  }, [data]);

  const isLoading = !accountDetails || !data;

  const filteredData = useMemo(() => {
    if (
      (!isPopulatedArray(filters.names) &&
        !isPopulatedArray(filters.locations)) ||
      !enableFilters
    ) {
      return tableData.sort((a, b) =>
        b.label.toString().localeCompare(a.label.toString())
      );
    }

    return tableData
      .filter((i) => filterAccountsDetailItem(i, filters))
      .sort((a, b) => b.label.toString().localeCompare(a.label.toString()));
  }, [tableData, enableFilters, filters]);

  return (
    <Flex ref={ref} flexGrow={1}>
      <Flex height={height} width={width} flexDir="column">
        {!isLoading && isPopulatedArray(tableData) && enableFilters && (
          <Filters />
        )}
        <Table
          stretch={true}
          isControlledByAPI={false}
          hidePageSize={true}
          bg={stackBg}
          columns={columns}
          isFetching={isLoading}
          data={filteredData}
          onFetchDataChange={() => undefined}
          resetPaginationDependencies={[filters.names]}
        />
      </Flex>
    </Flex>
  );
};

export { DetailsTable };
