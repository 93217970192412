import { Company } from "@intentsify/types";
import {
  Heading,
  Text,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import { IntegrationsModalBody } from "./IntegrationsModalBody";
import { ErrorBoundary } from "components";

const ModalHeading = (props: { company: Company }) => {
  return (
    <VStack spacing="1" alignItems="flex-start">
      <Heading fontSize="xl" color="whiteAlpha.800">
        {props.company.displayName}'s integrations
      </Heading>

      <Text fontSize="sm" fontWeight="normal" color="whiteAlpha.700">
        Connect Intentsify data to external providers
      </Text>
    </VStack>
  );
};

export const IntegrationsModal = ({
  company,
  isOpen,
  onClose,
}: {
  company: Company;
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <Modal isCentered size="2xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />

      <ModalContent
        px="6"
        py="5"
        bg="brand.450"
        borderRadius="xl"
        border="2px solid"
        borderColor="whiteAlpha.100"
      >
        <VStack spacing="12" alignItems="flex-start">
          <ModalHeader p="0" w="100%">
            <ModalHeading company={company} />

            <ModalCloseButton
              color="whiteAlpha.600"
              _hover={{ color: "white", bg: "whiteAlpha.100" }}
              _focus={{ color: "white", bg: "whiteAlpha.100" }}
            />
          </ModalHeader>

          <ModalBody p="0" w="100%">
            <ErrorBoundary>
              <IntegrationsModalBody company={company} />
            </ErrorBoundary>
          </ModalBody>
        </VStack>
      </ModalContent>
    </Modal>
  );
};
