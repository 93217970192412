import { Route, Routes } from "react-router";
import { Navigate } from "react-router-dom";
import { UserSettings, UserSettingsScreenDefinition } from "screens";

const SettingsRouter = () => {
  return (
    <Routes>
      {UserSettingsScreenDefinition.routePath.map((path) => (
        <Route key={path} path={path} element={<UserSettings />} />
      ))}
      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>
  );
};

export { SettingsRouter };
