import { Text, useToast } from "@chakra-ui/react";

export const useErrorToast = () => {
  const toast = useToast();

  return () => {
    toast({
      position: "top",
      variant: "subtle",
      colorScheme: "red",
      isClosable: true,
      status: "error",
      title: <Text fontSize="md">Something went wrong</Text>,
      description: (
        <Text fontSize="xs">Please try again later or contact support.</Text>
      ),
    });
  };
};
