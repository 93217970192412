import {
  Box,
  Progress as ChakraProgress,
  ProgressProps as ChakraProgressProps,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

type ProgressProps = {
  value?: number;
  transitionDuration?: number;
  colorScheme?: ChakraProgressProps["colorScheme"];
  isIndeterminate?: boolean;
  hasStripe?: boolean;
  showPercentageValue?: boolean;
  size?: ChakraProgressProps["size"];
  height?: ChakraProgressProps["height"];
  isEligible?: boolean;
  backgroundColor?: string;
  customProgressBarColor?: string;
};

const Progress = ({
  value,
  transitionDuration = 1000,
  colorScheme = "red",
  isIndeterminate = false,
  hasStripe = false,
  showPercentageValue = false,
  size = "sm",
  height,
  isEligible = true,
  backgroundColor,
  customProgressBarColor,
}: ProgressProps) => {
  const defaultBackgroundColor = useColorModeValue("gray.200", "gray.800");
  const bgColor = backgroundColor || defaultBackgroundColor;

  return (
    <Box position="relative">
      <ChakraProgress
        background={bgColor}
        borderRadius={1}
        isAnimated
        my={2}
        size={size}
        height={height}
        colorScheme={colorScheme}
        isIndeterminate={isIndeterminate}
        value={value}
        hasStripe={hasStripe}
        sx={{
          "& > div:first-child": {
            transitionProperty: "width",
            transitionDuration: `${transitionDuration}ms`,
            transitionTimingFunction: "linear",
            backgroundColor: customProgressBarColor,
          },
        }}
      />
      {showPercentageValue && (
        <Text
          as="span"
          position="absolute"
          left="50%"
          top="50%"
          transform="translateX(-50%) translateY(-50%)"
          fontSize={size}
        >
          {isEligible ? `${Number(value)}%` : "-"}
        </Text>
      )}
    </Box>
  );
};

export { Progress };
