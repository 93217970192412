import {
  GetCampaignSummaryRO,
  GetFiltersDTO,
  GetFiltersRO,
  ListAvailableSignalsDTO,
  ListBySignalDTO,
  ListSignalsRO,
  ListToLocationsDTO,
} from "@intentsify/dto";
import {
  AggregatedTopLocations,
  AudienceSegmentDefinition,
  CampaignDetails,
  SignalType,
} from "@intentsify/types";
import { Endpoints, apiService } from "api";
import { getPaginatedQuery, paramsToQueryUrl } from "utils";
import { Signal } from "./CampaignDetails.types";
import { PaginatedCampaignDetailsParams } from "./CampaignDetailsTable/CampaignDetailsTable.types";

export const listPaginatedCampaignDetails =
  (campaignId: string | number) =>
  async (params: PaginatedCampaignDetailsParams) => {
    const query = getPaginatedQuery<CampaignDetails, AudienceSegmentDefinition>(
      Endpoints.CampaignDetails.Get.List(campaignId),
      params
    );

    const { data } = await apiService.get(query);
    return data;
  };

export const getCampaignDetailsFilters = async (
  dto: GetFiltersDTO & { campaignId: number }
): Promise<GetFiltersRO> => {
  const { weekNumber, yearNumber, campaignId } = dto;

  const { data } = await apiService.get(
    `${Endpoints.CampaignDetails.Get.Filters(campaignId)}?${paramsToQueryUrl({
      params: { filters: { weekNumber, yearNumber } },
      includeFirstAmpersand: false,
    })}`
  );
  return data;
};

export const getCampaignDetailsAvailableSignals = async ({
  campaignId,
  domainId,
  weekNumber,
  yearNumber,
}: ListAvailableSignalsDTO & { campaignId: number; domainId: number }) => {
  const { data } = await apiService.get(
    `${Endpoints.CampaignDetails.Get.AvailableSignals(
      campaignId,
      domainId
    )}?${paramsToQueryUrl({
      params: { filters: { weekNumber, yearNumber } },
      includeFirstAmpersand: false,
    })}`
  );
  return data;
};

export const getCampaignDetailsAggregatedSignals = async ({
  campaignId,
  domainId,
  signalId,
  weekNumber,
  yearNumber,
}: ListBySignalDTO & {
  campaignId: number;
  domainId: number;
  signalId: SignalType;
}) => {
  const { data } = await apiService.get(
    `${Endpoints.CampaignDetails.Get.AggregatedSignal(
      campaignId,
      domainId,
      signalId
    )}?${paramsToQueryUrl({
      params: { filters: { weekNumber, yearNumber } },
      includeFirstAmpersand: false,
    })}`
  );
  return data;
};

export const getCampaignSignals = async (): Promise<Signal[]> => {
  const { data } = await apiService.get<ListSignalsRO>(
    Endpoints.Signals.Get.All
  );
  return data.signals;
};

export const getCampaignDetailsTopLocations = async ({
  campaignId,
  domainId,
  weekNumber,
  yearNumber,
}: ListToLocationsDTO & { campaignId: number; domainId: number }) => {
  const { data } = await apiService.get<AggregatedTopLocations>(
    `${Endpoints.CampaignDetails.Get.TopLocations(
      campaignId,
      domainId
    )}?${paramsToQueryUrl({
      params: { filters: { weekNumber, yearNumber } },
      includeFirstAmpersand: false,
    })}`
  );
  return data;
};

export const getCampaignSummary = async (
  campaignId: number,
  params: { yearNumber: number; weekNumber: number }
): Promise<GetCampaignSummaryRO> => {
  const { data } = await apiService.get<GetCampaignSummaryRO>(
    `${Endpoints.CampaignDetails.Get.Summary(campaignId)}`,
    {
      params,
    }
  );

  return data;
};
