import { GetProgrammaticOverallViewabilityRO } from "@intentsify/dto";
import { apiService, Endpoints } from "api";
import { DisplayPerformanceFilters } from "screens/CampaignPacing/screens/DisplayPerformance";

export const getOverallViewability = async (
  id: string,
  filters: DisplayPerformanceFilters
): Promise<GetProgrammaticOverallViewabilityRO> => {
  const { data } = await apiService.get(
    Endpoints.ProgrammaticCampaigns.Accounts.Get.OverallViewability(id),
    {
      params: {
        yearFrom: filters.yearFrom,
        yearTo: filters.yearTo,
        weekFrom: filters.weekFrom,
        weekTo: filters.weekTo,
      },
    }
  );

  return data;
};
