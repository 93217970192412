import { CreateBusinessEntityScreen } from "types";
import { createScreen } from "utils/createScreen";

const CreateBusinessEntityScreenDefinition = createScreen({
  name: CreateBusinessEntityScreen,
  path: "/admin/business-entities/create",
  routePath: ["create"],
  accessPath: "businessEntities",
});

export { CreateBusinessEntityScreenDefinition };
