import { Box, BoxProps, Checkbox, Flex, Spinner } from "@chakra-ui/react";
import { BaseMetaData, Item } from "types";

const Cell =
  <T extends Item<M>, M extends BaseMetaData = BaseMetaData>(
    onCheck: (item: T) => void,
    padding?: BoxProps["padding"]
  ) =>
  ({
    columnIndex,
    rowIndex,
    style,
    data,
  }: {
    columnIndex: number;
    rowIndex: number;
    style: React.CSSProperties;
    data: {
      isLoading: boolean;
      columnCount: number;
      list: T[];
      checkedItems: T[];
      disabledItems: T[];
      maxWidth: number;
      disableAll: boolean;
      metaNodeProperty?: keyof T["metaData"];
    };
  }) => {
    const {
      columnCount,
      list,
      checkedItems,
      disabledItems,
      maxWidth,
      disableAll,
      metaNodeProperty,
    } = data;
    const itemIndex = rowIndex * columnCount + columnIndex;

    const item = list[itemIndex];

    if (!item) {
      return (
        <Box className="ListItem" style={style}>
          {data.isLoading && <Spinner size="sm" opacity="0.5" />}
        </Box>
      );
    }

    const checkedValues = checkedItems.map((i) => i.value);
    const disabledValues = disabledItems.map((i) => i.value);
    const isChecked = checkedValues.includes(item.value);
    const isDisabled = disableAll || disabledValues.includes(item.value);

    const metaData = item.metaData;

    const onChange = () => {
      onCheck(item);
    };

    return (
      <Box className="ListItem" style={style} p={padding}>
        <Checkbox
          isDisabled={isDisabled}
          isChecked={isChecked}
          onChange={onChange}
        >
          <Flex pr={5} alignItems="center" lineHeight={1} width={maxWidth - 25}>
            <Box flexShrink={0}>{item.label}</Box>
            {metaData && metaNodeProperty && metaData[metaNodeProperty] && (
              <Box
                flexGrow={1}
                overflow="hidden"
                fontSize="small"
                opacity="0.5"
              >
                <Box
                  ml={1}
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                  overflow="hidden"
                >
                  <>{metaData[metaNodeProperty]}</>
                </Box>
              </Box>
            )}
          </Flex>
        </Checkbox>
      </Box>
    );
  };

export { Cell };
