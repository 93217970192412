import { ContactSearchFilters } from "@intentsify/types";
import { useIsFetching, useQueryClient } from "@tanstack/react-query";
import { REQUEST_SEARCH_RESULTS_QUERY_KEY } from "./components/ContactSearchTable/ContactSearchTable";

/**
 * Determine "Process Contact Search" button loading state.
 * If pressed the button it should be spinning but when just using pagination,
 * it shouldn't.
 */
export const useIsLoadingContactSearch = (
  submittedFilters: ContactSearchFilters | undefined
) => {
  const isFetching = useIsFetching({
    queryKey: [REQUEST_SEARCH_RESULTS_QUERY_KEY],
    exact: false,
  });

  const queryClient = useQueryClient();
  const hasResults = queryClient.getQueryData(
    [REQUEST_SEARCH_RESULTS_QUERY_KEY, submittedFilters],
    { exact: false }
  );

  return !!isFetching && !hasResults;
};
