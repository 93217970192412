import { Meta, SortDirection } from "@intentsify/types";
import { RowSelectionState } from "@tanstack/react-table";
import omit from "lodash/omit";
import uniqBy from "lodash/uniqBy";
import { useMemo, useState } from "react";
import { FetchDataParams, FetchDataParamsWithToken } from "types";
import { useDeepEffect } from "utils";
import {
  OnFetchDataChangeParams,
  OnTokenFetchDataChangeParams,
} from "./Table.types";

export const getFetchDataParams = <T,>(
  params: OnFetchDataChangeParams
): FetchDataParams<keyof T> => {
  let fetchDataParams = {
    page: Number(params.pageIndex) + 1,
    per_page: params.pageSize,
  } as FetchDataParams<keyof T>;

  if (params.sortBy[0]) {
    fetchDataParams = {
      ...fetchDataParams,
      order: params.sortBy[0].desc ? SortDirection.DESC : SortDirection.ASC,
      order_by: params.sortBy[0].id as keyof T,
    };
  }

  return { ...fetchDataParams, search: params.globalFilter };
};

export const getTokenFetchDataParams = <T,>(
  params: OnTokenFetchDataChangeParams
): FetchDataParamsWithToken<keyof T> => {
  let fetchDataParams = {
    pageToken: params.pageToken || undefined,
  } as FetchDataParamsWithToken<keyof T>;

  if (params.sortBy[0]) {
    fetchDataParams = {
      ...fetchDataParams,
      order: params.sortBy[0].desc ? SortDirection.DESC : SortDirection.ASC,
      order_by: params.sortBy[0].id as keyof T,
    };
  }

  return { ...fetchDataParams, search: params.globalFilter };
};

/**
 * https://github.com/tannerlinsley/react-table/issues/2321
 * Pagination breaks if manualPagination is set to false and pageCount is passed to ussTable (even if undefined)
 *
 * @param isControlledByApi - boolean
 * @param meta - Meta
 * @returns
 */

export const getTablePaginationParams = (
  isControlledByApi: boolean,
  meta?: Meta
) => {
  return isControlledByApi
    ? {
        manualPagination: true,
        pageCount: meta?.total_pages,
        autoResetPage: false,
      }
    : {
        manualPagination: false,
        autoResetPage: false,
      };
};

export const useRowSelection = <
  T extends Record<string, unknown>,
  K extends T[keyof T] = T[keyof T]
>(
  state: RowSelectionState,
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>,
  data: T[] | undefined,
  uniqueKeyAccessor: (item: T) => T[keyof T]
) => {
  const [dataCombined, setDataCombined] = useState<T[]>([]);

  const selected = Object.keys(state);

  useDeepEffect(() => {
    setDataCombined((p) => {
      return uniqBy([...p, ...(data ?? [])], (i) => uniqueKeyAccessor(i));
    });
  }, [data, uniqueKeyAccessor]);

  const selectedRows = useMemo(() => {
    return dataCombined.filter((i) =>
      selected.includes(String(uniqueKeyAccessor(i)))
    );
  }, [selected, dataCombined, uniqueKeyAccessor]);

  const removeSelection = (uniqueKey: K) => {
    setRowSelection((p) => {
      return omit(p, [String(uniqueKey)]);
    });
  };

  return { selectedRows, removeSelection };
};
