import { CampaignKeyword } from "@intentsify/types";
import { Endpoints, apiService } from "api";
import { KeywordItem, VerifiedKeywords } from "types/Keyword";

export const verifyCampaignKeywords = async ({
  file,
}: {
  file: File;
  toMergeWith?: KeywordItem[];
}): Promise<VerifiedKeywords[]> => {
  const formData = new FormData();
  formData.append("file", file);

  const result = await apiService.post<VerifiedKeywords[]>(
    Endpoints.Signals.Post.Keywords.Verify,
    formData,
    {
      headers: { "content-type": "multipart/form-data" },
    }
  );

  return result.data;
};

export const saveCampaignKeywords =
  (campaignId: number) =>
  async ({
    fileName,
    keywords,
  }: {
    fileName: string;
    keywords: string[];
    toMergeWith?: KeywordItem[];
  }): Promise<CampaignKeyword[]> => {
    const result = await apiService.post<CampaignKeyword[]>(
      Endpoints.Campaigns.Post.Signals.KeywordsSave(campaignId),
      {
        fileName,
        keywords,
      }
    );

    return result.data;
  };
