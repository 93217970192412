import { Flex } from "@chakra-ui/react";
import { defaultStringTransition } from "components/FramerMotionChakra/transitions";
import { memo } from "react";
import { Link } from "react-router-dom";
import { YourCampaignsScreenDefinition } from "screens/Campaigns/screens/CampaignsWizard/YourCampaigns.definition";
import { useAppStore } from "store/useAppStore";
import { MotionBox, MotionGrid } from "../../../FramerMotionChakra";
import { Logo } from "../../../Logo";
import { Tooltip } from "../../../Tooltip";
import { LinkGroup } from "./LinkGroup";
import { LinkGroupCollapsed } from "./LinkGroupCollapsed";
import { sharedLinkProps } from "./sharedLinkProps";
import { useLinkGroups } from "./useLinkGroups";

const Sidebar = memo(() => {
  const sideBarCollapsed = useAppStore.use.sideBarCollapsed();
  const linkGroups = useLinkGroups();

  return (
    <MotionBox
      layout
      position="relative"
      width="100%"
      height="100%"
      transition={defaultStringTransition}
      borderRightWidth="2px"
      borderRightStyle="solid"
      borderRightColor="brand.400"
      background="brand.450"
      display="flex"
      flexDirection="column"
    >
      <Tooltip
        aria-label="Home"
        isDisabled={!sideBarCollapsed}
        label="Home"
        placement="right"
      >
        <Link to={YourCampaignsScreenDefinition.navigate()}>
          <Logo
            logoFullSizeProps={{
              ...sharedLinkProps,
              w: "210px",
              p: "4",
            }}
            logoCollapsedProps={{
              ...sharedLinkProps,
              py: "1",
              cursor: "pointer",
            }}
            logoSmallSizeProps={{
              ...sharedLinkProps,
              py: "1",
              cursor: "pointer",
            }}
          />
        </Link>
      </Tooltip>

      <Flex id="sidebar" overflow="auto">
        <MotionGrid
          layout
          transition={defaultStringTransition}
          mt={sideBarCollapsed ? "0" : "2"}
          gap={sideBarCollapsed ? "0" : "4"}
        >
          {linkGroups.map((linkGroup) => {
            const LinkGroupComponent = sideBarCollapsed
              ? LinkGroupCollapsed
              : LinkGroup;

            return (
              <LinkGroupComponent key={linkGroup.name} linkGroup={linkGroup} />
            );
          })}
        </MotionGrid>
      </Flex>
    </MotionBox>
  );
});

export { Sidebar };
