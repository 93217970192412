import { baseTargetPersonaColumns } from "../../../../../../../../TargetPersonas/useTargetPersonaColumns";
import { Button, Table as ComponentsTable } from "components";
import { BaseTargetPersona } from "@intentsify/types";
import { Center, Text } from "@chakra-ui/react";
import { ColumnDef } from "@tanstack/react-table";
import { FaTrash } from "react-icons/fa";

const uniqueKeyAccessor = (i: BaseTargetPersona) => i.id;

type SelectedTargetPersonasTableProps = {
  data: BaseTargetPersona[];
  onRemove: (index: number) => void;
};

export const TargetPersonasPreview = ({
  data,
  onRemove,
}: SelectedTargetPersonasTableProps) => {
  const columns = [
    ...baseTargetPersonaColumns,
    {
      id: "actions",
      cell: ({ row }) => {
        return (
          <Button
            leftIcon={<FaTrash />}
            onClick={() => onRemove(data.indexOf(row.original))}
          >
            Remove
          </Button>
        );
      },
    } satisfies ColumnDef<BaseTargetPersona>,
  ];
  return (
    <ComponentsTable<BaseTargetPersona>
      customNoData={
        <Center flexDir="column" py="12">
          <Text fontWeight={500} fontSize="md" textAlign="center">
            No personas selected.
          </Text>

          <Text
            fontWeight={500}
            fontSize="xs"
            textAlign="center"
            color="blackAlpha.600"
            _dark={{ color: "whiteAlpha.700" }}
          >
            Add personas to selection using selector above
          </Text>
        </Center>
      }
      hidePagination={true}
      variant="intentsifyPlain"
      columns={columns}
      data={data}
      uniqueKeyAccessor={uniqueKeyAccessor}
    />
  );
};
