import {
  chakra,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  ListItem,
  SimpleGrid,
  UnorderedList,
  useToast,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { passwordValidator } from "@intentsify/utils";
import { useMutation } from "@tanstack/react-query";
import { Button } from "components";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { isCustomApiError } from "types";
import { handleApiError } from "utils";
import { z } from "zod";
import { LoginScreenDefinition } from "../Login/Login.definition";
import { ResetPasswordScreenDefinition } from "../ResetPassword/ResetPassword.definition";
import { setPasswordRequest } from "./SetPasswordForm.requests";

const validationSchema = z
  .object({
    password: passwordValidator,
    passwordConfirmation: passwordValidator,
  })
  .refine((data) => data.password === data.passwordConfirmation, {
    message: "passwords must match",
    path: ["passwordConfirmation"],
  });

type FieldValues = z.infer<typeof validationSchema>;

type SetPasswordFormProps = {
  token: string;
  userId: string;
};

const SetPasswordForm = ({ token, userId }: SetPasswordFormProps) => {
  const toast = useToast();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FieldValues>({
    mode: "onChange",
    resolver: zodResolver(validationSchema),
    criteriaMode: "all",
  });

  const { mutate, isLoading } = useMutation(setPasswordRequest, {
    onSuccess: () => {
      toast({
        title: "The new password was successfully set.",
        status: "success",
      });
      navigate(LoginScreenDefinition.navigate());
    },
    onError: (err) => {
      if (isCustomApiError(err)) {
        if (err.response.internalCode === "400ResetPasswordTokenExpired") {
          toast({
            title: (
              <>
                Your password reset link has expired. Please request another
                link by clicking{" "}
                <Button
                  variant="link"
                  size="md"
                  onClick={() => {
                    toast.closeAll();
                    navigate(ResetPasswordScreenDefinition.navigate());
                  }}
                >
                  {" "}
                  here{" "}
                </Button>
                to get the valid password reset link.
              </>
            ),
            status: "error",
            isClosable: true,
            duration: 25 * 1000,
          });

          return;
        }
      }
      handleApiError(err, navigate, toast);
    },
  });

  const onSubmit = (values: {
    passwordConfirmation: string;
    password: string;
  }) => {
    mutate({ password: values.password, token, userId });
  };

  return (
    <Flex justifyContent="center">
      <chakra.form
        onSubmit={(data) => {
          handleSubmit(onSubmit)(data);
        }}
        w="100%"
      >
        <SimpleGrid columns={1} spacing={4}>
          <FormControl isInvalid={!!errors?.password?.message} isRequired>
            <FormLabel color="white">Password</FormLabel>
            <Input
              {...register("password")}
              bg="gray.100"
              color="gray.600"
              _placeholder={{ color: "gray.400" }}
              type="password"
              name="password"
              placeholder="Password"
            />
            <FormErrorMessage>
              {errors?.password?.types ? (
                <UnorderedList textAlign="left">
                  {Object.values(errors.password.types).map((t, index) => (
                    <ListItem key={index}>{t}</ListItem>
                  ))}
                </UnorderedList>
              ) : null}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            isInvalid={!!errors?.passwordConfirmation?.message}
            isRequired
          >
            <FormLabel color="white">Confirm password</FormLabel>
            <Input
              {...register("passwordConfirmation")}
              bg="gray.100"
              color="gray.600"
              _placeholder={{ color: "gray.400" }}
              type="password"
              name="passwordConfirmation"
              placeholder="Confirm password"
            />
            <FormErrorMessage>
              {errors?.passwordConfirmation?.types ? (
                <UnorderedList textAlign="left">
                  {Object.values(errors.passwordConfirmation.types).map(
                    (t, index) => (
                      <ListItem key={index}>{t}</ListItem>
                    )
                  )}
                </UnorderedList>
              ) : null}
            </FormErrorMessage>
          </FormControl>

          <Button
            size="md"
            type="submit"
            fullWidth
            isLoading={isLoading}
            isDisabled={!!errors.password || isLoading}
          >
            Set Password
          </Button>
        </SimpleGrid>
      </chakra.form>
    </Flex>
  );
};

export { SetPasswordForm };
