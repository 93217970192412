import { Flex } from "@chakra-ui/react";
import { BusinessEntity } from "@intentsify/types";
import { useQuery } from "@tanstack/react-query";
import { Button, Search, Table, ViewContainer } from "components";
import { PaginationType } from "components/Table/Table/Table.types";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router";
import { useTrackSearched } from "tracking/useTrackSearched";
import { FetchDataParamsWithToken } from "types";
import { useScreen } from "utils";
import { CreateBusinessEntityScreenDefinition } from "../CreateBusinessEntity/CreateBusinessEntity.definition";
import { BusinessEntitiesScreenDefinition } from "./BusinessEntities.definition";
import {
  defaultPaginatedBusinessEntitiesParams,
  listPaginatedBusinessEntities,
} from "./BusinessEntities.requests";
import { TableActions } from "./TableActions";

const BusinessEntities = () => {
  useScreen(BusinessEntitiesScreenDefinition);
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);

  const [fetchDataParams, setFetchDataParams] = useState<
    FetchDataParamsWithToken<keyof BusinessEntity>
  >(defaultPaginatedBusinessEntitiesParams);

  const { data, isFetching } = useQuery(
    ["listPaginatedBusinessEntities", fetchDataParams],
    () => listPaginatedBusinessEntities(fetchDataParams)
  );

  const handleOnFetchDataChange = useCallback(
    (params: FetchDataParamsWithToken<keyof BusinessEntity>) => {
      setFetchDataParams(params);
    },
    []
  );

  const trackSearched = useTrackSearched();

  return (
    <ViewContainer>
      <Flex alignItems="center">
        <Search
          currentValue={searchValue}
          onSearch={(v) => {
            setSearchValue(v);
            trackSearched({ term: v, collocation: "Business entities table" });
          }}
        />
        <Flex grow={1} />

        <Button
          variant="primary-teal"
          onClick={() =>
            navigate(CreateBusinessEntityScreenDefinition.navigate())
          }
        >
          {CreateBusinessEntityScreenDefinition.name}
        </Button>
      </Flex>

      <Table
        searchValue={searchValue}
        variant="intentsifyNew"
        defaultSort={[
          {
            id: "displayName",
            desc: true,
          },
        ]}
        columns={[
          {
            header: "ID",
            accessorKey: "id",
          },
          {
            header: "Name",
            accessorKey: "displayName",
          },
          {
            header: "Companies",
            accessorKey: "companies",
            enableSorting: false,
            cell: ({ row }) => {
              return row.original.companies
                .map((i) => i.displayName)
                .join(", ");
            },
          },
          {
            header: "Actions",
            enableSorting: false,
            cell: ({ row }) => {
              return row.original?.id && <TableActions id={row.original?.id} />;
            },
          },
        ]}
        isFetching={isFetching}
        data={data?.businessEntities ?? []}
        onFetchDataChange={handleOnFetchDataChange}
        paginationType={PaginationType.TOKEN}
        nextPageToken={data?.nextPageToken}
        resetPaginationDependencies={[searchValue]}
      />
    </ViewContainer>
  );
};

export { BusinessEntities };
