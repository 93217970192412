import { TrendingTopicsRO, WeeklySnapshotDTO } from "@intentsify/dto";
import { apiService, Endpoints } from "api";

export const getTrendingTopics = async (
  campaignId: number,
  filters: WeeklySnapshotDTO
) => {
  const { data } = await apiService.get<TrendingTopicsRO[]>(
    Endpoints.Campaigns.Get.WeeklySnapshot.TrendingTopics(campaignId),
    {
      params: filters,
    }
  );

  return data;
};
