import { WeeklySnapshotDTO } from "@intentsify/dto";
import { useQuery } from "@tanstack/react-query";
import { getPingContent } from "./PingContent.requests";

export const usePingContent = (
  rawCampaignId: number | string | undefined,
  { weekTo, yearTo }: WeeklySnapshotDTO
) => {
  const campaignId = Number(rawCampaignId);
  const hasValidCampaignId = !Number.isNaN(campaignId);

  const { data, isFetching } = useQuery(
    ["getPingContent", campaignId, weekTo, yearTo],
    () => getPingContent(campaignId, { weekTo, yearTo }),
    {
      enabled: !Number.isNaN(campaignId),
    }
  );

  return {
    data: (hasValidCampaignId ? data : null) ?? [],
    isFetching,
  };
};
