import { PermissionModule } from "@intentsify/authorization";
import { IntentsifyAuthorizationContext } from "@intentsify/authorization/dist/react";
import { useUserPermissionModules } from "queries/permissions/useUserPermissionModules";
import { PropsWithChildren } from "react";
import { useUser } from "store/store.hooks";

export const AuthorizationProvider = (props: PropsWithChildren) => {
  const user = useUser();

  const { data, isLoading } = useUserPermissionModules();

  // If user is using old version of permissions, we need to reload the permission modules
  const shouldReloadPermissionModules = "permissions" in (user || {});
  const permissionModules = (
    shouldReloadPermissionModules ? data : user?.permissionModules || []
  ) as PermissionModule[];

  if (isLoading && shouldReloadPermissionModules) {
    return null;
  }

  return (
    <IntentsifyAuthorizationContext.Provider value={{ permissionModules }}>
      {props.children}
    </IntentsifyAuthorizationContext.Provider>
  );
};
