import { AccountFileDTO, TempAccountFileDTO } from "@intentsify/dto";
import { Campaign, FirmographicsFilters } from "@intentsify/types";
import { useEffect, useMemo, useState } from "react";
import { haveDomainsSettingsChanged } from "screens/Campaigns/screens/YourCampaigns/YourCampaigns.utils";
import { isAccountFileDTO, isTempAccountFileDTO } from "./DomainsForm";

export const useUploadedFiles = ({
  campaignId,
  files,
  onDeleteIconClick,
  onDownloadClick,
}: {
  files: (AccountFileDTO | TempAccountFileDTO)[];
  campaignId: number;
  onDeleteIconClick: () => void;
  onDownloadClick: (fileName: string) => void;
}) => {
  const [currentFiles, setCurrentFiles] =
    useState<Array<AccountFileDTO | TempAccountFileDTO>>(files);

  useEffect(() => {
    setCurrentFiles(files);
  }, [files]);

  const filesForList = currentFiles
    .filter((i) => {
      if (isAccountFileDTO(i) && i.toBeDeleted) {
        return false;
      }

      return true;
    })
    .map((i) => ({
      file: i,
      campaignId,
      onDownloadClick: (file: AccountFileDTO | TempAccountFileDTO) =>
        onDownloadClick(file.fileName),
      onDeleteIconClick: () => {
        if (isTempAccountFileDTO(i)) {
          setCurrentFiles((prev) => {
            return prev.filter((f) => {
              if (isAccountFileDTO(f)) {
                return true;
              }

              return (
                isTempAccountFileDTO(f) && f.tempFileName !== i.tempFileName
              );
            });
          });
        }

        if (isAccountFileDTO(i)) {
          setCurrentFiles((prev) => {
            return prev.map((f) => {
              if (f.fileName === i.fileName) {
                return {
                  ...f,
                  toBeDeleted: true,
                };
              }

              return f;
            });
          });

          onDeleteIconClick();
        }
      },
    }));

  return { filesForList, files: currentFiles, setFiles: setCurrentFiles };
};

export const useWatchDomains = (
  haveFilesChanged: boolean,
  oldFirmoFilters?: FirmographicsFilters,
  newFirmoFilters?: FirmographicsFilters,
  oldDomainEnhancementStrategy?: Campaign["accounts"]["domainEnhancementStrategy"],
  newDomainEnhancementStrategy?: Campaign["accounts"]["domainEnhancementStrategy"]
) => {
  const haveChanged = useMemo(() => {
    if (
      haveDomainsSettingsChanged(
        oldFirmoFilters,
        newFirmoFilters,
        haveFilesChanged,
        oldDomainEnhancementStrategy,
        newDomainEnhancementStrategy
      )
    ) {
      return true;
    }

    return false;
  }, [
    oldFirmoFilters,
    newFirmoFilters,
    haveFilesChanged,
    oldDomainEnhancementStrategy,
    newDomainEnhancementStrategy,
  ]);

  return haveChanged;
};
