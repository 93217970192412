import { Flex, HStack, Text, useColorMode } from "@chakra-ui/react";
import { Notification, NotificationTypes, Nullable } from "@intentsify/types";
import { DateFormat, formatDate } from "@intentsify/utils";
import { DateTime } from "luxon";
import { FiEdit, FiFileText } from "react-icons/fi";
import { useNavigate } from "react-router";
import { CampaignDetailsScreenDefinition } from "screens/Campaigns/screens/CampaignDetails/CampaignDetails.definition";
import {
  EditCampaignScreenDefinition,
  useEditCampaignNavigation,
} from "screens/Campaigns/screens/CampaignsWizard/EditCampaign.definition";
import { ActionItems } from "types";
import { Actions } from "../../../../../../../Actions";
import { Button } from "../../../../../../../Button";

type NotificationItemProps = {
  data: Notification;
  markAsRead?: (id: number) => void;
};

const useBackgroundColor = (readAt: Nullable<string>) => {
  const { colorMode } = useColorMode();

  if (colorMode === "dark") {
    return readAt ? "rgba(255,255,255,0.05)" : "rgba(255,255,255,0.1)";
  }

  return readAt ? "gray.50" : "gray.100";
};

const NotificationItem = ({ data, markAsRead }: NotificationItemProps) => {
  const navigate = useNavigate();
  const editCampaignNavigate = useEditCampaignNavigation();
  const { readAt, createdAt, id, type, metadata } = data;

  const relativeDate = DateTime.fromISO(createdAt).toRelative();

  const background = useBackgroundColor(readAt);

  const campaignActionItems: ActionItems[] = [
    {
      label: CampaignDetailsScreenDefinition.name,
      icon: <FiFileText />,
      accessPath: "campaign.details",
      size: "xs",
      onClick: () => {
        if (metadata?.campaignId) {
          navigate(
            CampaignDetailsScreenDefinition.navigate({
              campaignId: Number(metadata.campaignId),
              view: "account-details",
            })
          );
        }
      },
    },
    {
      label: EditCampaignScreenDefinition.name,
      accessPath: "campaign.edit",
      size: "xs",
      icon: <FiEdit />,
      onClick: () => {
        if (metadata?.campaignId) {
          editCampaignNavigate({
            campaignId: Number(metadata.campaignId),
            step: 1,
          });
        }
      },
    },
  ];

  return (
    <Flex
      mr={1}
      fontSize="sm"
      background={background}
      p={4}
      borderRadius={4}
      flexDir="column"
      onClick={() => {
        // If user interacts in any way, mark as read
        if (markAsRead) {
          markAsRead(id);
        }
      }}
    >
      <div
        className="notification-content"
        dangerouslySetInnerHTML={{ __html: data.content }}
      />

      <Flex
        fontSize="11px"
        mt={3}
        opacity={0.75}
        alignItems="center"
        justifyContent="space-between"
      >
        {relativeDate}
        <HStack>
          {type === NotificationTypes.HIDE_CAMPAIGN && (
            <Actions items={campaignActionItems} />
          )}
          {!readAt && markAsRead && (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                markAsRead(id);
              }}
              variant="ghost"
              size="xs"
            >
              Mark As Read
            </Button>
          )}
          {readAt && (
            <Text>
              Read at{" "}
              {formatDate({
                date: new Date(readAt),
                format: DateFormat.DateTime,
              })}
            </Text>
          )}
        </HStack>
      </Flex>
    </Flex>
  );
};

export { NotificationItem };
