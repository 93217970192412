import { useQuery } from "@tanstack/react-query";
import { Loader } from "components";
import { useParams } from "react-router";
import { useScreen } from "utils";
import { AutomatedProgrammaticSegmentForm } from "../AutomatedProgrammaticSegmentForm/AutomatedProgrammaticSegmentForm";
import { EditAutomatedProgrammaticSegmentDefinition } from "./EditAutomatedProgrammaticSegment.definition";
import { getAutomatedProgrammaticSegment } from "./EditAutomatedProgrammaticSegment.requests";

const EditAutomatedProgrammaticSegment = () => {
  useScreen(EditAutomatedProgrammaticSegmentDefinition);

  const { id: segmentId } = useParams<"id">();

  if (!segmentId) {
    throw new Error("404");
  }

  const { data } = useQuery(
    ["getAutomatedProgrammaticSegment", Number(segmentId)],
    () => getAutomatedProgrammaticSegment(Number(segmentId))
  );

  if (!data?.automatedProgrammaticSegment) {
    return <Loader />;
  }

  const {
    hasIspData,
    tag,
    region,
    campaignId,
    excludedAccounts,
    leadsStrategy,
    buyerResearchStages,
    segmentUploads,
    segmentType,
    filtersRelationship,
  } = data.automatedProgrammaticSegment;

  return (
    <AutomatedProgrammaticSegmentForm
      automatedProgrammaticSegmentId={Number(segmentId)}
      initialState={{
        hasIspData: hasIspData,
        excludedAccounts,
        segmentType,
        tag: tag,
        region,
        buyerResearchStages,
        leadsStrategy,
        campaignId: campaignId,
        filtersRelationship,
      }}
      mode="edit"
      segmentUploads={segmentUploads}
    />
  );
};

export { EditAutomatedProgrammaticSegment };
