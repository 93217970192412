import {
  Badge as ChakraBadge,
  BadgeProps,
  useColorModeValue,
} from "@chakra-ui/react";
import { forwardRef } from "react";

const Badge = forwardRef((props: BadgeProps, ref) => {
  const color = useColorModeValue("rgba(0,0,0,0.9)", "rgba(255,255,255,0.9)");

  return <ChakraBadge {...props} ref={ref} style={{ color }} />;
});

export { Badge };
