import { localPoint } from "@visx/event";
import { LinePath } from "@visx/shape";
import { ScaleLinear, ScaleTime } from "d3-scale";
import { DateTime } from "luxon";
import { CSSProperties } from "react";
import { animated, useSpring } from "react-spring";
import { useRecoilState, useSetRecoilState } from "recoil";
import { TimeseriesProps } from "../Timeseries";
import { useTimeseriesData } from "../Timeseries.hooks";
import {
  hoveredLineAtomFamily,
  hoveredNearestPointAtomFamily,
  hoveredXAxisLabelFamily,
} from "../Timeseries.state";
import { TimeseriesItem, TimeseriesItemDataRecord } from "types";

// TODO: add unit tests
const getNearestPoint = <K extends string, T extends string>(
  x: number,
  timeScale: ScaleTime<number, number, never>,
  valuesScale: ScaleLinear<number, number, never>,
  datum: TimeseriesItem<K, TimeseriesItemDataRecord<T>>,
  timeValues: number[],
  dataKey: T,
  offset: {
    x: number;
    y: number;
  }
) => {
  const nearestX = timeScale?.invert(x - offset.x);
  const closest = timeValues.reduce((prev, curr) =>
    Math.abs(curr - nearestX.valueOf()) < Math.abs(prev - nearestX.valueOf())
      ? curr
      : prev
  );

  const indexX = timeValues.indexOf(closest);
  const snappedToDate = datum.timeseries[indexX].isoDate;

  const snappedToY =
    (datum.timeseries.find((s) => s.isoDate === snappedToDate)?.data[
      dataKey
    ] as number) ?? 0;

  const nearestPoint = {
    x: Math.round(timeScale(DateTime.fromISO(snappedToDate).toUTC().valueOf())),
    y: Math.round(valuesScale(snappedToY)),
  };

  return { nearestPoint, snappedToDate };
};

type LineProps<K extends string, T extends string> = {
  data: TimeseriesItem<K, TimeseriesItemDataRecord<T>>[];
  style?: CSSProperties;
  item: TimeseriesItem<K, TimeseriesItemDataRecord<T>>;
  uniqueKey: string;
  strokeColor: string;
  timeScale: ScaleTime<number, number, never> | undefined;
  valuesScale: ScaleLinear<number, number, never> | undefined;
  dataKey: T;
  showTooltip: (args: {
    tooltipLeft: number;
    tooltipTop: number;
    tooltipData: TimeseriesItem<string, TimeseriesItemDataRecord<string>>;
  }) => void;
  hideTooltip: () => void;
  timeseries: TimeseriesProps<K, T>["timeseries"];
  snapToDateTime: boolean;
  fixedTooltip: boolean;
  margins: TimeseriesProps<K, T>["margins"];
};

const Line = <K extends string, T extends string>({
  data,
  style,
  item,
  uniqueKey,
  strokeColor,
  timeScale,
  valuesScale,
  dataKey,
  showTooltip,
  hideTooltip,
  timeseries,
  snapToDateTime,
  fixedTooltip,
  margins,
}: LineProps<K, T>) => {
  const setHoveredLine = useSetRecoilState(hoveredLineAtomFamily(uniqueKey));
  const [hoveredNearestPoint, setHoveredNearestPoint] = useRecoilState(
    hoveredNearestPointAtomFamily(uniqueKey)
  );

  const setHoveredXAxisLabel = useSetRecoilState(
    hoveredXAxisLabelFamily(uniqueKey)
  );

  const { timeValues } = useTimeseriesData(data, timeseries, uniqueKey);

  const handleMouseOver = (
    event: any,
    datum: TimeseriesItem<K, TimeseriesItemDataRecord<T>>
  ) => {
    if (event.target) {
      if (fixedTooltip) {
        showTooltip({
          tooltipLeft: 0,
          tooltipTop: 0,
          tooltipData: datum,
        });

        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const coords = localPoint(event.target.ownerSVGElement, event);

      if (snapToDateTime && coords && timeScale && valuesScale) {
        const { nearestPoint } = getNearestPoint(
          coords?.x,
          timeScale,
          valuesScale,
          datum,
          timeValues,
          dataKey,
          {
            x: margins?.l ?? 0,
            y: margins?.t ?? 0,
          }
        );

        if (nearestPoint) {
          showTooltip({
            tooltipLeft: nearestPoint.x + (margins?.l ?? 0),
            tooltipTop: nearestPoint.y + (margins?.t ?? 0),
            tooltipData: datum,
          });
        }

        return;
      }

      if (coords) {
        showTooltip({
          tooltipLeft: coords.x,
          tooltipTop: coords.y,
          tooltipData: datum,
        });
      }
    }
  };

  const handleNearestPoint = (
    event: any,
    datum: TimeseriesItem<K, TimeseriesItemDataRecord<T>>
  ) => {
    if (event.target) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const coords = localPoint(event.target.ownerSVGElement, event);

      if (coords && timeScale && valuesScale) {
        const { nearestPoint, snappedToDate } = getNearestPoint(
          coords?.x,
          timeScale,
          valuesScale,
          datum,
          timeValues,
          dataKey,
          {
            x: margins?.l ?? 0,
            y: margins?.t ?? 0,
          }
        );

        if (nearestPoint) {
          setHoveredNearestPoint({
            datum: datum,
            coords: nearestPoint,
            isoDate: snappedToDate,
          });

          setHoveredXAxisLabel(snappedToDate);
        }
      }
    }
  };

  const [{ opacity, transform }] = useSpring(
    {
      to: { opacity: 1, transform: "translateY(0px)" },
      from: { opacity: 0, transform: "translateY(-10px)" },
      reset: true,
      delay: 200,
    },
    []
  );

  if (!timeScale || !valuesScale) {
    return null;
  }

  const AnimatedLinePath = animated(LinePath);

  return (
    <>
      {hoveredNearestPoint && (
        <circle
          cx={hoveredNearestPoint.coords.x}
          cy={hoveredNearestPoint.coords.y}
          r={8}
          fill={strokeColor}
          pointerEvents="none"
        />
      )}

      <AnimatedLinePath
        style={{
          fill: "none",
          ...style,
          opacity,
          transform,
        }}
        key={Math.random()}
        strokeWidth={3}
        data={item.timeseries}
        // ts and ts-lint gets confused when wrapped in react-spring animated
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        x={(d) => timeScale(DateTime.fromISO(d.isoDate).toUTC().valueOf())}
        y={(d) => {
          // ts and ts-lint gets confused when wrapped in react-spring animated
          // @ts-ignore
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          return valuesScale(d.data[dataKey] ? Number(d.data[dataKey]) : 0);
        }}
        stroke={strokeColor}
      />

      <LinePath
        style={{
          fill: "none",
        }}
        onMouseMove={(e) => {
          handleMouseOver(e, item);
          setHoveredLine(item);
          if (snapToDateTime) {
            handleNearestPoint(e, item);
          }
        }}
        onMouseLeave={() => {
          hideTooltip();
          setHoveredLine(undefined);
          setHoveredXAxisLabel(undefined);
        }}
        key={Math.random()}
        strokeWidth={9}
        data={item.timeseries}
        x={(d) => timeScale(DateTime.fromISO(d.isoDate).toUTC().valueOf())}
        y={(d) => {
          return valuesScale(d.data[dataKey] ? Number(d.data[dataKey]) : 0);
        }}
        stroke={"transparent"}
      />
    </>
  );
};

export { Line };
