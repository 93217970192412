import { Icon, useDisclosure } from "@chakra-ui/react";
import { Button } from "components";
import { BsPlus } from "react-icons/bs";
import { TargetPersonaCreate, TargetPersonasForm } from "./Form";
import {
  TargetPersonasModal,
  TargetPersonasModalBody,
  TargetPersonasModalHeader,
} from "./Modal";
import { useCreateTargetPersona } from "./useTargetPersonas";

type CreateTargetPersonaButtonProps = {
  companyId?: number;
  onCreated?: (persona: TargetPersonaCreate) => void;
};

export function CreateTargetPersonaButton({
  companyId,
  onCreated,
}: CreateTargetPersonaButtonProps) {
  const disclosure = useDisclosure();
  const createTargetPersona = useCreateTargetPersona({
    onSuccess: (data) => {
      disclosure.onClose();
      onCreated?.(data);
    },
  });

  return (
    <>
      <TargetPersonasModal {...disclosure}>
        <TargetPersonasModalHeader>Create Persona</TargetPersonasModalHeader>

        <TargetPersonasModalBody>
          <TargetPersonasForm
            isLoading={createTargetPersona.isLoading}
            defaultValues={{ companyId }}
            onSubmit={createTargetPersona.mutate}
          />
        </TargetPersonasModalBody>
      </TargetPersonasModal>

      <Button variant="primary-teal" onClick={disclosure.onOpen}>
        <Icon as={BsPlus} h="6" w="6" />
        Create Persona
      </Button>
    </>
  );
}
