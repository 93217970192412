import { AutomatedProgrammaticSegment } from "@intentsify/types";
import { useQuery } from "@tanstack/react-query";
import { Table } from "components";
import { PaginationType } from "components/Table/Table/Table.types";
import { useCallback, useState } from "react";
import { FetchDataParamsWithToken } from "types";
import { useAutomatedSegmentsTableColumns } from "./AutomatedProgrammaticSegmentsTable.columns";
import {
  defaultListAutomatedProgrammaticSegmentsParams,
  listAutomatedProgrammaticSegments,
} from "./AutomatedProgrammaticSegmentsTable.requests";

type AutomatedProgrammaticSegmentsTableProps = {
  searchValue?: string;
};

const AutomatedProgrammaticSegmentsTable = ({
  searchValue,
}: AutomatedProgrammaticSegmentsTableProps) => {
  const [fetchDataParams, setFetchDataParams] = useState<
    FetchDataParamsWithToken<keyof AutomatedProgrammaticSegment>
  >(defaultListAutomatedProgrammaticSegmentsParams);

  const { data, isFetching } = useQuery(
    ["listAutomatedProgrammaticSegments", fetchDataParams],
    () => listAutomatedProgrammaticSegments(fetchDataParams)
  );

  const handleOnFetchDataChange = useCallback(
    (params: FetchDataParamsWithToken<keyof AutomatedProgrammaticSegment>) => {
      setFetchDataParams(params);
    },
    []
  );

  return (
    <Table
      searchValue={searchValue}
      defaultSort={[
        {
          id: "id",
          desc: true,
        },
      ]}
      columns={useAutomatedSegmentsTableColumns()}
      variant="intentsifyNew"
      isFetching={isFetching}
      data={data?.automatedProgrammaticSegments || []}
      onFetchDataChange={handleOnFetchDataChange}
      paginationType={PaginationType.TOKEN}
      nextPageToken={data?.nextPageToken}
    />
  );
};

export { AutomatedProgrammaticSegmentsTable };
