/* eslint-disable react-hooks/rules-of-hooks */
import { Box, Flex } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/react";
import { formatDateRange } from "@intentsify/utils";
import { ColumnDef } from "@tanstack/react-table";
import { CellWithChange, IconButton } from "components";
import { accountLogoFallbackImage } from "const";
import { FiFileText } from "react-icons/fi";
import { useParams } from "react-router";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { accountDetailsAtom } from "screens/IntentAnalysis/screens/CampaignsTrendingDomains/CampaignsTrendingDomains.state";
import { TimeseriesRange } from "types";
import { topPerformingDomainsDateRangeAtom } from "../../TopPerformingDomains.state";
import {
  TopPerformingDomainsItem,
  TopPerformingDomainsWithTimeseriesResponse,
} from "../../TopPerformingDomains.types";

const getSeriesColumns = (
  seriesKey: string,
  previousKey: string | undefined,
  timeseriesRange: TimeseriesRange
): ColumnDef<TopPerformingDomainsItem> => {
  const formattedSeriesHeader = formatDateRange(timeseriesRange);

  return {
    header: formattedSeriesHeader,
    columns: [
      {
        header: "Signals",
        enableSorting: false,
        cell: ({ row }) => {
          const data = row.original?.timeseries.find(
            (s) => s.isoDate === seriesKey
          );

          const previousSeries = previousKey
            ? row.original?.timeseries.find((s) => s.isoDate === previousKey)
            : undefined;

          return (
            <CellWithChange
              value={data?.data.value}
              previousValue={previousSeries?.data.value}
            />
          );
        },
      },
    ],
  };
};

export const getCampaignsTopChangesColumns = (
  timeseries: TopPerformingDomainsWithTimeseriesResponse["timeseries"]
) => {
  const seriesColumns = timeseries.map((series, i) =>
    getSeriesColumns(series.start, timeseries[i - 1]?.start, series)
  );

  const columns: Array<ColumnDef<TopPerformingDomainsItem>> = [
    {
      header: "Account",
      enableSorting: false,
      cell: ({ row }) => {
        const selectedDateRange = useRecoilValue(
          topPerformingDomainsDateRangeAtom
        );
        const setAccountDetails = useSetRecoilState(accountDetailsAtom);
        const campaignId = Number(useParams<"campaignId">().campaignId);

        return (
          <Flex alignItems="center">
            <Image
              minW="35px"
              boxSize="35px"
              objectFit="contain"
              src={String(row.original?.logoUrl)}
              mr={4}
              fallbackSrc={accountLogoFallbackImage}
            />
            {row.original?.label}

            <Box ml={2}>
              <IconButton
                size="xs"
                tooltip="View account details"
                variant="outline"
                icon={<FiFileText />}
                onClick={(e) => {
                  e.stopPropagation();

                  if (campaignId) {
                    setAccountDetails({
                      campaignId: Number(campaignId),
                      account: String(row.original?.label),
                      dateRange: selectedDateRange,
                    });
                  }
                }}
              />
            </Box>
          </Flex>
        );
      },
    },
    ...seriesColumns,
  ];

  return columns;
};
