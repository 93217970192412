import { Flex } from "@chakra-ui/layout";
import { useColorModeValue } from "@chakra-ui/react";
import { isPopulatedArray, toNumberDisplayValue } from "@intentsify/utils";
import { AxisLeft, AxisTop } from "@visx/axis";
import { Group } from "@visx/group";
import { ParentSize } from "@visx/responsive";
import { scaleBand, scaleLinear } from "@visx/scale";
import { Bar } from "@visx/shape";
import { Text } from "@visx/text";
import { ScaleBand, ScaleLinear } from "d3-scale";
import omit from "lodash/omit";
import { useMemo, useRef } from "react";
import { animated, useSpring } from "react-spring";
import { colors } from "theme";
import { useChartColors } from "utils";
import { Loader } from "../../Loader";
import { NoData } from "../../NoData";
import { BarChartItem } from "../BarChart";
import { estimateSpaceForLabelHorizontal } from "../BarChart/estimateSpaceForLabel";
import { EXPORT_TITLE_HEIGHT } from "../Charts.const";

const estimatedBarHeight = 60;
const spaceForAxisTop = 15;
const spaceForLebelPercantage = 70;

type BarChartProps = {
  isLoading?: boolean;
  data: BarChartItem[];
  margins?: { t: number; b: number; r: number; l: number };
  stretch?: boolean;
  noDataMessage?: string;
  id?: string;
  title?: string;
  exportMode?: true;
  minHeight?: string;
};

const VideoCompletionRateChart = ({
  isLoading,
  data,
  margins = {
    t: 20,
    b: 30,
    r: 30,
    l: 10,
  },
  stretch = false,
  noDataMessage,
  id,
  exportMode,
  title,
  minHeight,
}: BarChartProps) => {
  const itemsScale = useRef<ScaleBand<string> | undefined>();
  const valueScale = useRef<ScaleLinear<number, number, never> | undefined>();
  const { labelColor, axisColors } = useChartColors();

  const yAxisWidth = useMemo(() => {
    return (
      estimateSpaceForLabelHorizontal(data, { fontSize: 12 }) +
      spaceForLebelPercantage
    );
  }, [data]);

  const barFill = "#6EB21F";
  const dividerColor = useColorModeValue(colors.gray["100"], colors.gray[700]);

  const [{ scale }] = useSpring(
    {
      from: { scale: 0 },
      to: { scale: 1 },
      reset: true,
    },
    [isLoading]
  );

  if (isLoading) {
    return <Loader minHeight={minHeight} stretch={stretch} />;
  }

  if (!isPopulatedArray(data)) {
    return (
      <NoData message={noDataMessage} height={minHeight} stretch={stretch} />
    );
  }

  const maxHeight = !stretch
    ? estimatedBarHeight * data.length + margins.t + margins.b
    : undefined;

  const AnimatedBar = animated(Bar);

  return (
    <Flex
      overflowY="auto"
      width="100%"
      height="100%"
      borderRadius="10px"
      minHeight={minHeight}
      maxH="300px"
    >
      <Flex width="100%" height={maxHeight}>
        <ParentSize>
          {(parent) => {
            const { width, height } = parent;
            const baseYMax = height - margins.t - margins.b - spaceForAxisTop;

            const yMax = !exportMode
              ? baseYMax
              : baseYMax - EXPORT_TITLE_HEIGHT;
            const xMax = width - margins.l - margins.r - yAxisWidth;

            itemsScale.current = scaleBand<string>({
              range: [0, yMax],
              round: true,
              domain: data.map((i) => i.label),
              paddingInner: 0.2,
              paddingOuter: 0,
            });

            valueScale.current = scaleLinear<number>({
              range: [0, xMax],
              round: true,
              domain: [0, 100],
            });

            return (
              <svg
                width={width}
                height={height}
                id={id}
                style={{
                  fontFamily: "var(--chakra-fonts-body)",
                }}
              >
                <rect
                  x={0}
                  y={0}
                  width={width}
                  height={height}
                  fill={"transparent"}
                />

                {exportMode && title && (
                  <Group top={30} left={22}>
                    <Text
                      verticalAnchor="middle"
                      fontSize={22}
                      fontWeight="semibold"
                      fill={labelColor}
                    >
                      {title}
                    </Text>
                  </Group>
                )}

                <g
                  transform={`translate(${margins.l},${
                    !exportMode
                      ? margins.t + spaceForAxisTop
                      : margins.t + EXPORT_TITLE_HEIGHT + spaceForAxisTop
                  })`}
                >
                  <Group>
                    {data.map((d, index) => {
                      if (!valueScale.current || !itemsScale.current) {
                        return null;
                      }

                      const barHeight = itemsScale.current.bandwidth();
                      const barY = itemsScale.current(d.label);

                      return (
                        <line
                          key={index}
                          x1={0}
                          y1={(barY ?? 0) + barHeight + 6}
                          x2={width - margins.r - margins.l}
                          y2={(barY ?? 0) + barHeight + 6}
                          stroke={dividerColor}
                        />
                      );
                    })}
                  </Group>
                </g>

                <g
                  transform={`translate(${margins.l + yAxisWidth},${
                    !exportMode
                      ? margins.t + spaceForAxisTop
                      : margins.t + EXPORT_TITLE_HEIGHT + spaceForAxisTop
                  })`}
                >
                  <Group>
                    {data.map((d, index) => {
                      if (!valueScale.current || !itemsScale.current) {
                        return null;
                      }

                      const barHeight = itemsScale.current.bandwidth();
                      const barWidth = valueScale.current(d.value ?? 0);
                      const barY = itemsScale.current(d.label);

                      return (
                        <AnimatedBar
                          key={`bar-${d.value}-${index}`}
                          x={0}
                          y={barY}
                          width={scale.to((s) => s * barWidth)}
                          height={barHeight}
                          fill={barFill}
                        />
                      );
                    })}
                  </Group>

                  <AxisTop
                    numTicks={3}
                    stroke={axisColors}
                    tickStroke={axisColors}
                    top={-5}
                    tickFormat={(v) => `${String(v)}%`}
                    scale={valueScale.current}
                    tickLabelProps={() => ({
                      fill: labelColor,
                      fontSize: 11,
                      textAnchor: "middle",
                    })}
                    tickComponent={(props) => {
                      return (
                        <Text
                          {...omit(props, ["formattedValue"])}
                          y={props.y - 5}
                        >
                          {props.formattedValue}
                        </Text>
                      );
                    }}
                  />

                  <AxisLeft
                    hideAxisLine
                    hideTicks
                    numTicks={data.length}
                    tickComponent={(props) => {
                      const item = data.find(
                        (i) => i.label === props.formattedValue
                      );

                      return (
                        <g
                          transform={`translate(${
                            props.x - yAxisWidth - props.x
                          },${props.y})`}
                        >
                          <Text
                            {...omit(props, ["formattedValue", "x", "y"])}
                            width={spaceForLebelPercantage}
                            x={0}
                            y={0}
                            fontSize="14"
                            fontWeight="bold"
                          >
                            {`${toNumberDisplayValue(item?.value ?? 0)}%`}
                          </Text>

                          <Text
                            {...omit(props, ["formattedValue", "x", "y"])}
                            width={yAxisWidth - 20}
                            x={spaceForLebelPercantage}
                            y={0}
                          >
                            {props.formattedValue}
                          </Text>
                        </g>
                      );
                    }}
                    scale={itemsScale.current}
                    tickFormat={(v) => String(v)}
                    tickLabelProps={() => ({
                      fill: labelColor,
                      fontSize: 12,
                      textAnchor: "start",
                      verticalAnchor: "middle",
                    })}
                  />
                </g>
              </svg>
            );
          }}
        </ParentSize>
      </Flex>
    </Flex>
  );
};

export { VideoCompletionRateChart };
