import { OptOutReason } from "@intentsify/types";
import { getZodDefaults } from "utils/getZodDefaults";
import { z } from "zod";

export const OptOutFormValues = z.object({
  email: z.string().email("Email must be a valid email").default(""),
  firstName: z
    .string()
    .nonempty("First name must contain at least 1 character")
    .default(""),
  lastName: z
    .string()
    .nonempty("Last name must contain at least 1 character")
    .default(""),
  reason: z
    .nativeEnum(OptOutReason, {
      invalid_type_error: "Reason must be a valid reason",
      required_error: "Reason is required",
    })
    .transform((val) => (!val || val === "unspecified" ? "unspecified" : val))
    .default("unspecified"),
});

export type OptOutFormValues = z.infer<typeof OptOutFormValues>;
export const defaultOptOutFormValues = getZodDefaults(OptOutFormValues);

const OptOutReasonDescription = {
  unspecified: "Unspecified",
  requestToAccess: "Request to know/access my personal information",
  requestToCorrect: "Request to correct my personal information",
  requestToDelete: "Request to delete my personal information",
  optOutOfSale: `Opt out of the “sale” or “sharing” of my personal information`,
  optOutOfProcessing:
    "Opt out of the processing of my information for purposes of targeted advertising",
} as const satisfies Record<keyof OptOutReason, string>;

export type OptOutReasonOption = {
  value: keyof typeof OptOutReason;
  label: string;
};

export const optOutReasonOptions: OptOutReasonOption[] = Object.values(
  OptOutReason
)
  .filter((v) => v !== "unspecified")
  .map((value) => ({
    value,
    label: OptOutReasonDescription[value],
  }));
