import { Flex } from "@chakra-ui/layout";
import { DraftAsset, SortDirection } from "@intentsify/types";
import { RowSelectionState } from "@tanstack/react-table";
import { Table as ComponentsTable, useRowSelection } from "components";
import { PaginationType } from "components/Table/Table/Table.types";
import { useState } from "react";
import { FetchDataParamsWithToken } from "types";
import { BatchDeleteButton } from "../BatchDeleteButton";
import { NoData } from "./NoData";
import { useColumns } from "./useColumns";
import { useDeleteDraftAssets } from "./useDeleteDraftAssets";
import { useDraftAssets } from "./useDraftAssets";

type TableProps = {
  searchValue: string;
  showCurrentUserDrafts: boolean;
};

export const Table = (props: TableProps) => {
  const [params, setParams] = useState<
    FetchDataParamsWithToken<keyof DraftAsset> & {
      showOnlyUploadedByUser?: boolean;
    }
  >({ pageToken: "", order: SortDirection.DESC, order_by: "uploadedAt" });

  const draftAssets = useDraftAssets({
    ...params,
    search: props.searchValue,
    showOnlyUploadedByUser: props.showCurrentUserDrafts,
  });

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const uniqueKeyAccessor = (i: DraftAsset) => i.id;
  const { selectedRows, removeSelection } = useRowSelection<DraftAsset>(
    rowSelection,
    setRowSelection,
    draftAssets.data?.draftAssets,
    uniqueKeyAccessor
  );

  const columns = useColumns(removeSelection);
  const deleteDraftAssets = useDeleteDraftAssets(removeSelection);
  const selectedAssetIds = selectedRows.map((row) => row.id);

  return (
    <>
      <ComponentsTable
        selectable
        customNoData={<NoData />}
        variant="intentsifyPlain"
        searchValue={props.searchValue}
        defaultSort={[{ id: "uploadedAt", desc: true }]}
        columns={columns}
        data={draftAssets.data?.draftAssets ?? []}
        paginationType={PaginationType.TOKEN}
        nextPageToken={draftAssets.data?.nextPageToken}
        isFetching={draftAssets.isFetching}
        onFetchDataChange={setParams}
        uniqueKeyAccessor={uniqueKeyAccessor}
        resetPaginationDependencies={[props.searchValue]}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
      />

      <Flex>
        {selectedRows.length > 0 && (
          <BatchDeleteButton
            assetIds={selectedAssetIds}
            isLoading={deleteDraftAssets.isLoading}
            onDelete={() => deleteDraftAssets.mutate(selectedAssetIds)}
          />
        )}
      </Flex>
    </>
  );
};
