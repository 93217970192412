import { LeadPacingScreen } from "types";
import { createScreen } from "utils/createScreen";

const LeadPacingScreenDefinition = createScreen({
  name: LeadPacingScreen,
  path: "/dashboard/campaigns-overview/:companyId/:campaignId",
  routePath: ["/campaigns-overview/:companyId/:campaignId"],
  accessPath: "campaign.intentAnalysisWithSpotlight",
});

export { LeadPacingScreenDefinition };
