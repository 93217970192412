import { LabeledValueRO, WeeklySnapshotRangeDTO } from "@intentsify/dto";
import { apiService, Endpoints } from "api";

export const getLeadByAssetPerformance = async (
  id: number,
  filters: WeeklySnapshotRangeDTO
): Promise<any[]> => {
  const { data } = await apiService.get<LabeledValueRO[]>(
    Endpoints.Campaigns.Get.WeeklySnapshot.LeadByAssetPerformance(id),
    {
      params: filters,
    }
  );

  return data;
};
