import { useDisclosure, useToast } from "@chakra-ui/react";
import { AccessPath } from "@intentsify/authorization";
import { Company, CompanyForList } from "@intentsify/types";
import { ColumnDef } from "@tanstack/react-table";
import { Actions, Button, EmptyCell, Modal } from "components";
import { useRemoveCompany } from "mutations/companies/useRemoveCompany";
import { useMemo } from "react";
import { FiEdit3, FiTrash2 } from "react-icons/fi";
import { MdCable } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { ActionItems } from "types";
import { getTrialEndDays } from "utils/getTrialEndDays";
import { EditCompanyScreenDefinition } from "../EditCompany/EditCompany.definition";
import { IntegrationsModal } from "./IntegrationsModal/IntegrationsModal";

interface ActionsCellProps {
  company: Company;
}

const ActionsCell = ({ company }: ActionsCellProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isIntegrationsOpen,
    onOpen: onIntegrationsOpen,
    onClose: onIntegrationsClose,
  } = useDisclosure();
  const navigate = useNavigate();
  const toast = useToast();

  const { mutate, isLoading } = useRemoveCompany({
    onSuccess: () => {
      toast({
        title: "Company removed successfully",
        status: "success",
      });
      onClose();
    },
  });

  const items: ActionItems[] = [
    {
      label: EditCompanyScreenDefinition.name,
      accessPath: "company.edit",
      icon: <FiEdit3 />,
      onClick: () =>
        navigate(
          EditCompanyScreenDefinition.navigate({ id: company.companyId })
        ),
    },
    {
      label: "Delete Company",
      accessPath: "company.delete",
      icon: <FiTrash2 />,
      onClick: onOpen,
    },
    // due to workato integration complexities
    // we are only allowing this in production env
    ...(process.env.VITE_NODE_ENV === "production"
      ? [
          {
            label: "Establish Company Integrations",
            accessPath: "company.establishCompanyIntegration" as AccessPath,
            icon: <MdCable />,
            onClick: onIntegrationsOpen,
          },
        ]
      : []),
  ];

  return (
    <>
      <Actions items={items} />
      <Modal
        title={"Delete company"}
        body={"Delete this company?"}
        secondaryButton={
          <Button aria-label="Cancel" onClick={onClose}>
            Cancel
          </Button>
        }
        primaryButton={
          <Button
            aria-label="Delete company"
            variant="warning"
            leftIcon={<FiTrash2 />}
            onClick={() => {
              mutate(company.companyId);
            }}
            isLoading={isLoading}
          >
            Remove
          </Button>
        }
        isOpen={isOpen}
        onClose={onClose}
      />

      <IntegrationsModal
        company={company}
        isOpen={isIntegrationsOpen}
        onClose={onIntegrationsClose}
      />
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const TrialEndDateCell = ({
  row,
}: {
  row: { original: { trialEndDate?: string | null } };
}) => {
  const trialEndDays = getTrialEndDays(row.original?.trialEndDate);

  if (!trialEndDays && trialEndDays !== 0) {
    return <>N/A</>;
  }

  if (trialEndDays < 0) {
    return <>Expired on {row.original?.trialEndDate}</>;
  }

  if (trialEndDays === 0) {
    return <>Expires today</>;
  }

  return (
    <>
      {trialEndDays} {trialEndDays === 1 ? "day" : "days"} left
    </>
  );
};

export const useCompaniesTableColumns = () => {
  return useMemo(
    () =>
      [
        {
          header: "ID",
          accessorKey: "companyId",
        },
        {
          header: "Name",
          accessorKey: "displayName",
        },
        {
          header: "Created Date",
          accessorKey: "createdAt",
        },
        // {
        //   header: "Trial",
        //   accessorKey: "trialEndDate",
        //   cell: TrialEndDateCell,
        // },
        {
          header: "Actions",
          enableSorting: false,
          cell: ({ row }) => {
            return row.original?.companyId ? (
              <ActionsCell company={row.original} />
            ) : (
              <EmptyCell />
            );
          },
        },
      ] satisfies ColumnDef<CompanyForList>[],
    []
  );
};
