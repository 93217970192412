/**
 * When to remind the user about the update if the toast was dismissed.
 */
export const UPDATE_RECOMMENDED_TOAST_REMINDER_DURATION = 1000 * 60 * 60; // 60 min
export const UPDATE_REQUIRED_TOAST_REMINDER_DURATION = 1000 * 60 * 2; // 2 min

/**
 * How often to check for new versions.
 */
export const CHECK_FOR_NEW_VERSION_INTERVAL_DURATION = 1000 * 60; // 1 min

/**
 * When update is required. How long we wait before reloading the tab programtically.
 */
export const AUTOMATIC_RELOAD_TIMEOUT = 1000 * 30; // 30 sec
