import { FileForm } from "./FileForm";
import { ProgrammaticDomainsUploadScreenDefinition } from "./ProgrammaticDomainsUpload.definition";
import { ViewContainer } from "components";
import { useScreen } from "utils";

const ProgrammaticDomainsUpload = () => {
  useScreen(ProgrammaticDomainsUploadScreenDefinition);

  return (
    <ViewContainer compact>
      <FileForm />
    </ViewContainer>
  );
};

export { ProgrammaticDomainsUpload };
