import { Notification } from "@intentsify/types";
import { useQueryClient } from "@tanstack/react-query";
import uniqBy from "lodash/uniqBy";
import { atom, selector, useResetRecoilState } from "recoil";

export const unreadNotificationsState = atom<Notification[]>({
  key: "unreadNotificationsState",
  default: [],
});

export const readNotificationsState = atom<Notification[] | undefined>({
  key: "readNotificationsState",
  default: undefined,
});

export const showReadState = atom<boolean>({
  key: "showReadState",
  default: false,
});

export const nextPageTokenState = atom<string | undefined>({
  key: "nextPageToken",
  default: undefined,
});

export const isFetchingUnreadState = atom<boolean>({
  key: "isFetchingUnreadState",
  default: false,
});

export const isFetchingUnreadNextPageState = atom<boolean>({
  key: "isFetchingUnreadNextPageState",
  default: false,
});

export const isFetchingSelector = selector({
  key: "isFetchingSelector",
  get: ({ get }) => {
    const isFetchingUnread = get(isFetchingUnreadState);
    const isFetchingUnreadNextPage = get(isFetchingUnreadNextPageState);

    return isFetchingUnread || isFetchingUnreadNextPage;
  },
});

export const userNotificationsSelector = selector({
  key: "userNotificationsSelector",
  get: ({ get }) => {
    const unread = get(unreadNotificationsState);
    const read = get(readNotificationsState);
    const showRead = get(showReadState);

    if (!showRead) {
      return unread;
    }

    const sorted = [...(unread ?? []), ...(read ?? [])].sort(
      (a, b) =>
        new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
    );

    return uniqBy(sorted, (item) => item.id);
  },
});

export const useResetOnClose = () => {
  const queryClient = useQueryClient();

  const f1 = useResetRecoilState(showReadState);
  const f2 = useResetRecoilState(nextPageTokenState);
  const f3 = useResetRecoilState(readNotificationsState);

  return () => {
    [f1, f2, f3].forEach((i) => i());

    queryClient.resetQueries({
      queryKey: ["readNotifications"],
      exact: false,
    });
    queryClient.resetQueries({
      queryKey: ["unreadNotifications"],
      exact: false,
    });
  };
};

export const useResetOnClearAll = () => {
  const reset = useResetOnClose();
  const resetUnread = useResetRecoilState(unreadNotificationsState);

  return () => {
    reset();
    resetUnread();
  };
};
