import { Text } from "@chakra-ui/layout";
import { CampaignTopic } from "@intentsify/types";
import { useQueryClient } from "@tanstack/react-query";
import { PropsWithChildren, useCallback } from "react";
import { useRecoilValue } from "recoil";
import { useDebounceValue } from "shared/hooks/useDebounceValue";
import { ampli } from "tracking/amplitude";
import { SelectionMode } from "types/Keyword";
import { useKeywordsAndTopicsState } from "../../KeywordsAndTopics.hooks";
import { topicIdsSelector } from "../../KeywordsAndTopicsStep.state";
import { defaultTransition } from "./animations";

export const Suggestion = (
  props: PropsWithChildren<{
    topic: CampaignTopic;
    campaignId: number;
  }>
) => {
  const selectTopic = useSelectTopic(props.campaignId);

  return (
    <Text
      display="inline-block"
      ml="1"
      cursor="pointer"
      fontSize="sm"
      lineHeight="1.67"
      color="blue.700"
      textDecoration="underline"
      textDecorationColor="blackAlpha.500"
      textUnderlineOffset="0.1rem"
      _hover={{ color: "black", textDecorationColor: "black" }}
      transition={defaultTransition}
      _dark={{
        color: "blue.200",
        textDecorationColor: "whiteAlpha.500",
        _hover: {
          color: "white",
          textDecorationColor: "white",
        },
      }}
      onClick={() => selectTopic(props.topic)}
    >
      {props.children}
    </Text>
  );
};

const useSelectTopic = (campaignId: number) => {
  const queryClient = useQueryClient();
  const { actions } = useKeywordsAndTopicsState();
  const topicIds = useRecoilValue(topicIdsSelector);
  const debouncedTopicIds = useDebounceValue(topicIds, 1000);

  return useCallback(
    (topic: CampaignTopic) => {
      ampli.topicSuggestionSelected({
        campaignId,
        topicId: `${topic.topicId}`,
      });

      queryClient.setQueryData<{ id: string; topic: string }[]>(
        ["topicSuggestions", campaignId, debouncedTopicIds],
        (prev) => {
          if (!prev) {
            return [];
          }

          return prev.filter((item) => item.topic !== topic.topic);
        }
      );

      actions.setSelectedTopics(
        [{ ...topic, topicId: Number(topic.topicId) }],
        SelectionMode.SELECT
      );
    },
    [actions, campaignId, queryClient, debouncedTopicIds]
  );
};
