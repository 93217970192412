import { Center, Flex, Icon, Text, useDisclosure } from "@chakra-ui/react";
import { TusFile, TusUpload } from "@intentsify/tus/dist/react";
import { DraftAsset } from "@intentsify/types";
import { IconButton } from "components";
import { FaEdit } from "react-icons/fa";
import { FiX } from "react-icons/fi";
import { MdCheckCircleOutline, MdCircle, MdOutlineError } from "react-icons/md";
import { CompletedAssetForm } from "screens/ContentLibrary/Completed/CompletedAssetForm";
import { useReviewDraftAsset } from "screens/ContentLibrary/Drafts/useReviewDraftAsset";
import {
  ContentLibraryModal,
  ContentLibraryModalBody,
  ContentLibraryModalHeader,
} from "screens/ContentLibrary/Modal";
import { contentLibraryUpload } from "upload/contentLibraryUpload";

const transition = `
  all 0.24s cubic-bezier(0.25, 1, 0.5, 1)
`;

export const getContentLibraryTusUploadResponseBody = (file: TusFile) => {
  if ("body" in file) {
    return file.body as DraftAsset;
  }

  return undefined;
};

export const ContentLibraryFile = (props: {
  file: TusFile;
  tusUpload: TusUpload;
}) => {
  const removeFile = contentLibraryUpload.useRemoveFile();
  const draftAsset = getContentLibraryTusUploadResponseBody(props.file);
  const draftDisclosure = useDisclosure();

  const reviewDraftAsset = useReviewDraftAsset(draftAsset?.id ?? 0, () => {
    draftDisclosure.onClose();
  });

  return (
    <>
      <ContentLibraryModal {...draftDisclosure}>
        <ContentLibraryModalHeader>
          Review {draftAsset?.name}
        </ContentLibraryModalHeader>

        <ContentLibraryModalBody>
          <CompletedAssetForm
            isLoading={reviewDraftAsset.isLoading}
            defaultValues={{ name: draftAsset?.name.split(".")[0] }}
            onSubmit={(data) => {
              reviewDraftAsset.mutate(data);
            }}
          />
        </ContentLibraryModalBody>
      </ContentLibraryModal>

      <Flex
        alignItems="center"
        justifyContent="space-between"
        borderTop="1px solid"
        borderTopColor="blackAlpha.300"
        _dark={{ borderTopColor: "whiteAlpha.200" }}
        py={3}
        px={4}
        role="group"
        _hover={{
          bg: "blackAlpha.50",
          _dark: { bg: "whiteAlpha.100" },
        }}
        transition={transition}
      >
        <Text
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          pr="6"
          color="blackAlpha.700"
          _dark={{ color: "whiteAlpha.700" }}
          _groupHover={{ color: "inherit" }}
        >
          {props.file.name}
        </Text>

        <Center>
          {props.file.status === "uploaded" && (
            <IconButton
              tooltip={"Review draft asset"}
              size="sm"
              icon={<FaEdit opacity={1} />}
              variant="ghost"
              onClick={draftDisclosure.onOpen}
            />
          )}

          <Icon
            _groupHover={{ display: "none" }}
            h="32px"
            w="32px"
            p={1}
            color={
              props.file.status === "uploading"
                ? "inherit"
                : props.file.status === "error"
                ? "red.400"
                : props.file.status === "uploaded"
                ? "green.400"
                : "inherit"
            }
            _light={{
              color:
                props.file.status !== "error" &&
                props.file.status !== "uploaded"
                  ? "blackAlpha.500"
                  : "inherit",
            }}
            as={
              props.file.status === "uploading"
                ? MdCircle
                : props.file.status === "error"
                ? MdOutlineError
                : props.file.status === "uploaded"
                ? MdCheckCircleOutline
                : MdOutlineError
            }
            transition={transition}
          />

          <IconButton
            size="sm"
            icon={<FiX />}
            display="none"
            variant="ghost"
            colorScheme="red"
            _groupHover={{ display: "inline-flex" }}
            aria-label="Discard from list"
            tooltip="Discard from list"
            transition={transition}
            onClick={() => {
              removeFile(props.file.id);
            }}
          />
        </Center>
      </Flex>
    </>
  );
};
