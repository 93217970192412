import { useGetHasAccessTo } from "@intentsify/authorization/dist/react";
import { isDefined } from "@intentsify/utils";
import { useCallback, useMemo } from "react";
import { ActionItem, ActionItems } from "types";
import { isActionItem, isActionItemGroup } from "./Actions.utils";

export const useSafeActionItems = (items: ActionItems[]) => {
  const hasAccessTo = useGetHasAccessTo();

  const getIsActionItemSafe = useCallback(
    (item: ActionItem) => {
      if (!item.accessPath) {
        return true;
      }

      if (hasAccessTo(item.accessPath)) {
        return true;
      }

      return false;
    },
    [hasAccessTo]
  );

  const filterUnsafeActionItems = useCallback(
    (item: ActionItems) => {
      if (isActionItem(item)) {
        return getIsActionItemSafe(item);
      }

      if (isActionItemGroup(item)) {
        const hasAccessToEveryItem = item.items.every(getIsActionItemSafe);
        const hasAccessToSomeItems = item.items.some(getIsActionItemSafe);

        if (hasAccessToEveryItem || hasAccessToSomeItems) {
          return true;
        }

        return false;
      }
    },
    [getIsActionItemSafe]
  );

  const filterUnsafeActionItemGroupItems = useCallback(
    (item: ActionItems) => {
      if (!isActionItemGroup(item)) {
        return item;
      }

      return {
        ...item,
        items: item.items.filter(getIsActionItemSafe),
      };
    },
    [getIsActionItemSafe]
  );

  return useMemo(
    () =>
      items
        .filter(isDefined)
        .filter(filterUnsafeActionItems)
        .map(filterUnsafeActionItemGroupItems),
    [items, filterUnsafeActionItems, filterUnsafeActionItemGroupItems]
  );
};
