import { Box, Flex } from "@chakra-ui/layout";
import {
  Button,
  Heading,
  Progress,
  Stat,
  StatLabel,
  StatNumber,
  chakra,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { useHasAccessTo } from "@intentsify/authorization/dist/react";
import { formatDate } from "@intentsify/utils";
import { DateTime } from "luxon";
import { Tooltip } from "../Tooltip";
import {
  getNumberOfLeadsExpectedForToday,
  getProgressColor,
  getProgressValue,
} from "./CampaignProgress.utils";

type CampaignProgressProps = {
  campaignId: number;
  title: string;
  currentValue: number;
  goalValue: number;
  progressScore: number | null;
  startDate: string | null;
  endDate: string | null;
  onPermormanceClick: () => void;
};

const getPaceTooltipContent = (
  progressScore: number,
  numberOfLeadsExpectedToday: number
) => {
  if (progressScore >= 100) {
    return `Campaign is on track to fulfil the target.`;
  }

  return `Campaign is ${
    100 - progressScore
  }% behind goal. Expected today is: ${numberOfLeadsExpectedToday}.`;
};

const CampaignProgress = ({
  campaignId,
  title,
  currentValue,
  goalValue,
  progressScore,
  startDate,
  endDate,
  onPermormanceClick,
}: CampaignProgressProps) => {
  const { colorMode } = useColorMode();
  const progressValue = getProgressValue(currentValue, goalValue);
  const progressColor = getProgressColor(progressScore, colorMode);

  const statLabelColor = useColorModeValue("gray.400", "gray.200");
  const goalNumberColor = useColorModeValue("gray.50", "whiteAlpha.100");

  const numberOfLeadsExpectedToday = getNumberOfLeadsExpectedForToday(
    startDate,
    endDate,
    goalValue
  );
  const hasAccessToDetailedPerformance = useHasAccessTo(
    "campaign.detailedPerformance"
  );

  return (
    <Flex flexDir={"column"} py={2}>
      <Flex mb={2}>
        <Heading as="h3" size="md">
          {title}
        </Heading>

        <Flex flexGrow={1} />

        <Flex alignItems="center">
          <chakra.span
            mr={4}
            opacity={0.6}
            fontSize="small"
            fontWeight="medium"
            whiteSpace="nowrap"
          >
            ID: {campaignId}
          </chakra.span>
          {hasAccessToDetailedPerformance && (
            /** limit access to admins for now */
            <Button
              onClick={() => onPermormanceClick()}
              size="xs"
              variant="outline"
              isDisabled={currentValue <= 1}
            >
              View Detailed Performance
            </Button>
          )}
        </Flex>
      </Flex>
      <Flex flexDir="row">
        <Box flexGrow={1}>
          <Stat mb={2}>
            <StatLabel color={statLabelColor} mb={2} fontSize={"xs"}>
              <Flex mt={2} mb={2}>
                <Box mr={8}>
                  Start date:{" "}
                  <b>
                    {startDate
                      ? formatDate({
                          date: DateTime.fromISO(startDate).toJSDate(),
                        })
                      : "n/a"}
                  </b>
                </Box>
                <Box>
                  End date:{" "}
                  <b>
                    {endDate
                      ? formatDate({
                          date: DateTime.fromISO(endDate).toJSDate(),
                        })
                      : "n/a"}
                  </b>
                </Box>
              </Flex>
            </StatLabel>
          </Stat>

          <Box position="relative">
            <Progress
              className="progress-bar"
              borderRadius={4}
              sx={
                progressColor
                  ? {
                      div: {
                        background: progressColor,
                      },
                    }
                  : undefined
              }
              colorScheme={!progressColor ? "gray" : undefined}
              height="32px"
              value={progressValue}
            />
          </Box>
        </Box>
        <Flex ml={2} alignItems="flex-end" minWidth="192px">
          <Tooltip
            aria-label="Campaign progress"
            label={
              numberOfLeadsExpectedToday && progressScore
                ? getPaceTooltipContent(
                    progressScore,
                    numberOfLeadsExpectedToday
                  )
                : undefined
            }
            shouldWrapChildren={true}
          >
            <Stat mr={2}>
              <StatLabel color={statLabelColor} pl={2} mb={2} fontSize={"sm"}>
                Pace
              </StatLabel>
              <StatNumber
                borderRadius={4}
                pl={2}
                pr={2}
                display="flex"
                alignItems="center"
                textAlign="center"
                bg={goalNumberColor}
                height="32px"
                fontSize="xl"
              >
                <Box pl={0.5} whiteSpace="nowrap">
                  {progressScore}%
                </Box>
              </StatNumber>
            </Stat>
          </Tooltip>

          <Stat mr={2}>
            <StatLabel color={statLabelColor} pl={2} mb={2} fontSize={"sm"}>
              Goal
            </StatLabel>
            <StatNumber
              borderRadius={4}
              pl={2}
              pr={2}
              display="flex"
              alignItems="center"
              textAlign="center"
              bg={goalNumberColor}
              height="32px"
              fontSize="xl"
            >
              <Box pl={0.5} whiteSpace="nowrap">
                {currentValue} / {goalValue || "N/A"}
              </Box>
            </StatNumber>
          </Stat>
        </Flex>
      </Flex>
    </Flex>
  );
};

export { CampaignProgress };
