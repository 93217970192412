import { DigitalForecastingScreen } from "types";
import { createScreen } from "utils/createScreen";

const DigitalForecastingScreenDefinition = createScreen({
  name: DigitalForecastingScreen,
  path: "/media-activation/impression-forecasting",
  routePath: ["/impression-forecasting"],
  accessPath: "digitalForecasting",
});

export { DigitalForecastingScreenDefinition };
