import { Box, Text } from "@chakra-ui/layout";
import { QueryClient, useQueryClient } from "@tanstack/react-query";
import { Button } from "components";
import { Component } from "react";
import { useAppStore } from "store/useAppStore";
import { Version } from "types";
import { CustomApiError } from "utils";

type ErrorBoundaryProps = {
  children: React.ReactNode;
  sx?: React.CSSProperties;
  queryClient?: QueryClient;
  version?: Version;
};

const withQueryClientAndBuild =
  (Component: React.ComponentType<ErrorBoundaryProps>) => (props: any) => {
    const queryClient = useQueryClient();
    const build = useAppStore.use.version();

    return <Component queryClient={queryClient} build={build} {...props} />;
  };

class StaticErrorBoundary extends Component<
  ErrorBoundaryProps,
  { error: Error | CustomApiError | undefined }
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: undefined };
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(_error: Error | CustomApiError, _errorInfo: unknown) {
    // do logging here if necessary
  }

  public render() {
    if (this.state.error) {
      return (
        <Box py="4">
          <Text fontSize="md" fontWeight="medium" lineHeight={1.65}>
            Couldn't load topic suggestions
          </Text>

          <Text fontSize="xs" lineHeight={1.65} color="whiteAlpha.700">
            Our team has been notified and will look into it. Please try again
            later.
          </Text>

          <Button
            variant="outline"
            size="sm"
            mt="6"
            onClick={() => {
              this.props.queryClient?.refetchQueries({
                queryKey: ["topicSuggestions"],
                exact: false,
              });
              this.setState({ error: undefined });
            }}
          >
            Retry
          </Button>
        </Box>
      );
    }

    return this.props.children;
  }
}

export const ErrorBoundary = withQueryClientAndBuild(StaticErrorBoundary);
