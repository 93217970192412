import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { MiscStore, createMiscSlice } from "./misc.slice";
import { createSelectors } from "./store.utils";
import { UserStore, createUserSlice } from "./user.slice";

export type AppStore = UserStore & MiscStore;

export const useAppBaseStore = create<AppStore>()(
  devtools(
    persist(
      immer((...api) => ({
        ...createUserSlice(...api),
        ...createMiscSlice(...api),
      })),
      {
        name: "root-app-local-storage",
        // Select which properties to store in local storage
        partialize: (state: AppStore) => ({
          user: state.user,
          impersonation: state.impersonation,
          showUploadContentTip: state.showUploadContentTip,
          uploadContentTipCount: state.uploadContentTipCount,
        }),
        version: 1,
      }
    )
  )
);

export const useAppStore = createSelectors(useAppBaseStore);
