import { ProgrammaticDomainFileWithSegments } from "@intentsify/types";
import { apiService, Endpoints } from "api";

export const getProgrammaticDomainFileWithSegmentsById = async (
  programmaticDomainsFile: number
) => {
  const { data } = await apiService.get<ProgrammaticDomainFileWithSegments>(
    Endpoints.ProgrammaticDomains.Get.FileById(programmaticDomainsFile)
  );

  return data;
};
