import { Box, Center, Text } from "@chakra-ui/layout";
import { Button } from "components";
import { Link } from "react-router-dom";
import { UploadContent } from "../UploadContent";

export const NoData = () => (
  <Center
    flexDir="column"
    gap="6"
    mt="24"
    textAlign="center"
    maxW="sm"
    mx="auto"
  >
    <Center flexDir="column" gap="1.5">
      <Text fontSize="2xl">No completed assets found.</Text>

      <Text
        color="blackAlpha.700"
        _dark={{
          color: "whiteAlpha.700",
        }}
      >
        Drag and drop files or click the button below to upload them to the
        content library.
      </Text>
    </Center>

    <Center>
      <Link to="/content-library/drafts">
        <Button variant="secondary">Review draft assets</Button>
      </Link>

      <Center position="relative" flex="1" w="24" mx="4">
        <Text
          position="relative"
          zIndex="1"
          px="2"
          color="blackAlpha.700"
          _dark={{ color: "whiteAlpha.700" }}
        >
          <Box
            content=""
            position="absolute"
            top="50%"
            left="-100%"
            right="100%"
            height="2px"
            bg="blackAlpha.300"
            _dark={{ bg: "whiteAlpha.300" }}
          />
          Or
          <Box
            content=""
            position="absolute"
            top="50%"
            left="100%"
            right="-100%"
            height="2px"
            bg="blackAlpha.300"
            _dark={{ bg: "whiteAlpha.300" }}
          />
        </Text>
      </Center>

      <UploadContent />
    </Center>
  </Center>
);
