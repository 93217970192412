import { useQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { WeeklySnapshotRangeDTO } from "@intentsify/dto";
import { getMostResearchedTopics } from "./MostResearchedTopics.requests";
import { BarChart, Card, MIN_MODAL_CONTENT_HEIGHT } from "components";
import { ChartExport, useExportableChart } from "shared/components";

type MostResearchedTopicsProps = {
  campaignId: number;
  filters: WeeklySnapshotRangeDTO;
};

const MostResearchedTopics = ({
  campaignId,
  filters,
}: MostResearchedTopicsProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const { data, isFetching } = useQuery(
    ["getMostResearchedTopics", campaignId, filters],
    () => getMostResearchedTopics(campaignId, filters)
  );

  const top10Topics = useMemo(() => data?.slice(0, 10) ?? [], [data]);

  const chart = useExportableChart({
    title: "Most Researched Topics For Selected Week",
    campaignId,
    chart: (
      <BarChart
        minHeight={isExpanded ? MIN_MODAL_CONTENT_HEIGHT : undefined}
        stretch
        valueAxisLabel="Total Signal Count"
        orientation="horizontal"
        data={top10Topics}
      />
    ),
  });

  return (
    <Card
      onExpandedChange={setIsExpanded}
      isLoading={isFetching}
      title={chart.title}
      isExpandable
      hasData={!isFetching && Boolean(top10Topics.length)}
      actions={
        <ChartExport
          size="small"
          campaignId={campaignId}
          title={chart.title}
          onExportPNG={chart.downloadAsPng}
          data={top10Topics}
        />
      }
    >
      {chart.component}
      {chart.exportComponent}
    </Card>
  );
};

export { MostResearchedTopics };
