import { BusinessDataAppendDTO, GenericUserJobRecordRO } from "@intentsify/dto";
import { GenericUserJobType } from "@intentsify/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Endpoints, apiService } from "api";

export const useBusinessDataAppend = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (BusinessDataAppendDTO: BusinessDataAppendDTO) =>
      apiService.post<GenericUserJobRecordRO>(
        Endpoints.TalTools.BusinessDataAppend.Post,
        BusinessDataAppendDTO
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["talTools", GenericUserJobType.BUSINESS_DATA_APPEND],
        exact: false,
      });
    },
  });
};
