import { useMemo } from "react";
import { decimalToPercentage } from "@intentsify/utils";
import { PieChartItem } from "./PieChart.types";

const useGetPieChartData = (
  data: PieChartItem[],
  disabledLegendItems: PieChartItem[],
  asPercent: boolean
) => {
  return useMemo(() => {
    const disabled = disabledLegendItems.map((i) => i.label);

    const filteredData = data.map((i) => {
      const keep = !disabled.includes(i.label);

      if (keep) {
        return i;
      }

      return {
        ...i,
        value: 0,
      };
    });

    if (!asPercent) {
      return filteredData;
    }

    const sum = filteredData.reduce((acc, prev) => acc + prev.value, 0);

    return filteredData.map((i) => ({
      value: decimalToPercentage(i.value / sum),
      label: i.label,
    }));
  }, [asPercent, data, disabledLegendItems]);
};

export { useGetPieChartData };
