import { UserImpersonation } from "@intentsify/types";
import { apiService, Endpoints } from "api";

export const enableImpersonationMode = async (userId: number) => {
  const { data } = await apiService.post<UserImpersonation["impersonates"]>(
    Endpoints.Impersonation.Post.Enable,
    {
      userId,
    }
  );

  return data;
};

export const disableImpersonationMode = async () => {
  const { data } = await apiService.post<string>(
    Endpoints.Impersonation.Post.Disable
  );

  return data;
};
