import {
  FileUploadMetadata,
  TusStorage,
  createTusUpload,
} from "@intentsify/tus/dist/react";
import { Endpoints } from "api";

export const targetPersonaUpload = createTusUpload({
  uploadUrl: new URL(
    `${process.env.VITE_REACT_APP_API_URL ?? `http://localhost:3001`}${
      Endpoints.TargetPersonas.Upload
    }`
  ),
  autoResumeUploads: false,
  storage: new TusStorage<
    "targetPersona",
    FileUploadMetadata & {
      companyId: string;
    }
  >("targetPersona"),
});
