import { Button, useDisclosure } from "@chakra-ui/react";
import { useHasAccessTo } from "@intentsify/authorization/dist/react";
import { LeadSignalRow } from "@intentsify/types";
import { isDefined, isPopulatedArray } from "@intentsify/utils";
import { ColumnDef, Row, Table } from "@tanstack/react-table";
import { EMPTY_CELL, IndeterminateCheckbox } from "components";
import { LeadActivationModal } from "./LeadActivationSummaryModal";

export const useLeadsColumns = (): Array<ColumnDef<LeadSignalRow>> => {
  const hasAccessToLeadActivationSummaries = useHasAccessTo(
    "campaign.leadActivationSummaries"
  );

  const columns: Array<ColumnDef<LeadSignalRow>> = [
    hasAccessToLeadActivationSummaries
      ? {
          id: "select",
          header: ({ table }: { table: Table<LeadSignalRow> }) => {
            const rows = table.getRowModel().rows;

            const onlySelectableRows = rows.filter(
              (row) => row.original.leadNote
            );

            const onChange = () => {
              onlySelectableRows.forEach((row) => row.toggleSelected());
            };

            const allChecked = onlySelectableRows.every((row) =>
              row.getIsSelected()
            );

            const someChecked = onlySelectableRows.some((r) =>
              r.getIsSelected()
            );
            const indeterminate = someChecked && !allChecked;

            return (
              <IndeterminateCheckbox
                {...{
                  checked: allChecked && isPopulatedArray(onlySelectableRows),
                  indeterminate,
                  onChange,
                }}
              />
            );
          },

          cell: ({ row }: { row: Row<LeadSignalRow> }) => {
            return (
              <IndeterminateCheckbox
                {...{
                  isDisabled: !row.original.leadNote,
                  checked: row.getIsSelected(),
                  indeterminate: row.getIsSomeSelected(),
                  onChange: row.getToggleSelectedHandler(),
                }}
              />
            );
          },
        }
      : undefined,
    {
      header: "Name",
      accessorKey: "name",
    },
    {
      header: "Locations",
      accessorKey: "location",
      cell: ({ row }: { row: Row<LeadSignalRow> }) => {
        return row.original?.location.filter((l) => l).join(" | ");
      },
    },
    hasAccessToLeadActivationSummaries
      ? {
          header: "Lead Activation Summary",
          enableSorting: false,
          cell: ({ row }: { row: Row<LeadSignalRow> }) => {
            if (!row.original.leadId || !row.original.leadNote) {
              return EMPTY_CELL;
            }

            // eslint-disable-next-line react-hooks/rules-of-hooks
            const { isOpen, onOpen, onClose } = useDisclosure();

            return (
              <>
                <Button size="sm" onClick={onOpen}>
                  View Summary
                </Button>

                <LeadActivationModal
                  isOpen={isOpen}
                  onClose={onClose}
                  location={row.original.location}
                  jobTitle={row.original.name}
                  leadActivationSummary={row.original.leadNote}
                />
              </>
            );
          },
        }
      : undefined,
  ].filter(isDefined);

  return columns;
};
