import { WeeklySnapshotRangeDTO } from "@intentsify/dto";
import { useQuery } from "@tanstack/react-query";
import { getLeadsPerformance } from "./Performance.requests";

export const useLeadsPerformance = (
  campaignId: string | number,
  { weekTo, yearTo, weekFrom, yearFrom }: WeeklySnapshotRangeDTO
) => {
  return useQuery(
    ["getLeadsPerformance", campaignId, weekFrom, yearFrom, weekTo, yearTo],
    () =>
      getLeadsPerformance(campaignId, {
        weekFrom,
        yearFrom,
        weekTo,
        yearTo,
      }),
    {
      enabled: !Number.isNaN(campaignId),
    }
  );
};
