import { Option } from "@intentsify/types";
import { atom } from "recoil";
import { SingleAccountDetailsFilters } from "./SingleAccountDetails.types";

export const singleAccountDetailsFiltersAtom =
  atom<SingleAccountDetailsFilters>({
    key: `singleAccountDetailsFiltersAtom`,
    default: {
      names: [],
      locations: [],
    },
  });

export const singleAccountDetailsNamesOptionsAtom = atom<Option[]>({
  key: `singleAccountDetailsNamesOptionsAtom`,
  default: [],
});

export const singleAccountDetailsLocationOptionsAtom = atom<Option[]>({
  key: `singleAccountDetailsLocationOptionsAtom`,
  default: [],
});
