import {
  chakra,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  Input,
  SimpleGrid,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "components";
import { useForm } from "react-hook-form";
import { useComponentColors } from "theme";
import { z, ZodError } from "zod";
import { CompaniesSelector } from "./CompaniesSelector";

const validationSchema = z.object({
  displayName: z.string().min(3).optional(),
  companyIds: z.array(z.number()).min(1).max(32),
});

export type AgencyFormData = z.infer<typeof validationSchema>;

type BusinessEntityFormProps = {
  isSubmitting: boolean;
  data: AgencyFormData;
  onChange: (data: AgencyFormData) => void;
  onSubmit: (data: AgencyFormData) => void;
  submitButtonLabel: "Create Business Entity" | "Save Changes";
};

const BusinessEntityForm = ({
  isSubmitting,
  data,
  onChange,
  onSubmit,
  submitButtonLabel,
}: BusinessEntityFormProps) => {
  const componentColors = useComponentColors();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
    clearErrors,
  } = useForm<AgencyFormData>({
    mode: "onBlur",
    resolver: zodResolver(validationSchema),
    defaultValues: data,
  });

  const onSubmitForm = (data: AgencyFormData) => onSubmit(data);

  return (
    <SimpleGrid>
      <chakra.form
        onSubmit={(data) => {
          handleSubmit(onSubmitForm)(data);
        }}
      >
        <SimpleGrid>
          <FormControl
            isInvalid={!!errors?.displayName?.message}
            as={GridItem}
            isRequired
          >
            <FormLabel
              color={componentColors.form.formLabelColor}
              fontSize="sm"
              fontWeight="md"
            >
              Business Entity Name
            </FormLabel>
            <Input
              {...register("displayName")}
              type="displayName"
              name="displayName"
              placeholder="Name"
              shadow="sm"
              size="sm"
              w="300px"
              rounded="md"
            />
            <FormErrorMessage>{errors?.displayName?.message}</FormErrorMessage>
          </FormControl>

          <FormControl
            isInvalid={!!errors?.companyIds?.message}
            as={GridItem}
            isRequired
            mt={4}
          >
            <FormLabel
              color={componentColors.form.formLabelColor}
              fontSize="sm"
              fontWeight="md"
            >
              Companies
            </FormLabel>

            <CompaniesSelector
              selected={data.companyIds}
              onChange={(companyIds) => {
                const newData = {
                  displayName: "validation placeholder",
                  companyIds,
                };

                try {
                  onChange(newData);
                  const ids = newData.companyIds;

                  setValue("companyIds", ids);

                  // TODO: `CompaniesSelector` should be connected to `react-hook-form`
                  // so we don't have to manually validate the field
                  // https://react-hook-form.com/docs/usecontroller/controller
                  validationSchema.parseAsync({
                    ...newData,
                    companies: ids,
                  });
                  clearErrors("companyIds");
                } catch (error) {
                  if (error instanceof ZodError) {
                    setError("companyIds", {
                      type: "custom",
                      message: error
                        .flatten()
                        .fieldErrors["companyIds"]?.join(", "),
                    });
                  }
                }
              }}
            />

            <FormErrorMessage>{errors?.companyIds?.message}</FormErrorMessage>
          </FormControl>
        </SimpleGrid>

        <Divider mt={4} />

        <Flex mt={4} justifyContent="flex-end">
          <Button
            size="md"
            isLoading={isSubmitting}
            type="submit"
            variant="primary-teal"
            isDisabled={!!errors.displayName || !!errors.companyIds}
          >
            {submitButtonLabel}
          </Button>
        </Flex>
      </chakra.form>
    </SimpleGrid>
  );
};

export { BusinessEntityForm };
