import { Center, Stack } from "@chakra-ui/react";
import { ResetPasswordForm } from "./components";
import { ResetPasswordScreenDefinition } from "./ResetPassword.definition";
import { AuthFormContainer, AuthFormHeader } from "auth/components";
import { LogoBoxed } from "components";

const ResetPassword = () => {
  return (
    <AuthFormContainer>
      <Center>
        <Stack>
          <LogoBoxed w={"200px"} />
          <AuthFormHeader text={ResetPasswordScreenDefinition.name} />
        </Stack>
      </Center>
      <ResetPasswordForm />
    </AuthFormContainer>
  );
};

export { ResetPassword };
