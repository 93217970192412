import { pluralize } from "@intentsify/utils";
import { Button } from "components";
import { useConfirm } from "components/ConfirmDialog/useConfirm";

export const BatchDeleteButton = (props: {
  isLoading: boolean;
  idsToDelete: number[];
  onDelete: () => void;
}) => {
  const confirm = useConfirm();

  return (
    <Button
      isLoading={props.isLoading}
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onClick={async () => {
        if (
          await confirm({
            title: "Delete target persona",
            description: `Are you sure you want to delete selected ${pluralize(
              props.idsToDelete.length,
              "target persona",
              "target personas"
            )}?`,
            confirmText: "Delete",
          })
        ) {
          props.onDelete();
        }
      }}
    >
      Delete {props.idsToDelete.length} target{" "}
      {pluralize(props.idsToDelete.length, "persona", "personas")}
    </Button>
  );
};
