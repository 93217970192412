import { Td, Tr } from "@chakra-ui/react";
import {
  flexRender,
  RowData,
  Row as ReactTableRow,
} from "@tanstack/react-table";

interface SimpleRowProps<Data extends RowData> {
  row: ReactTableRow<Data>;
}

export const SimpleRow = <Data extends RowData>({
  row,
}: SimpleRowProps<Data>) => (
  <Tr>
    {row.getVisibleCells().map((cell) => (
      <Td key={cell.id} pl="0" pr="0" py="1" borderColor="transparent">
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </Td>
    ))}
  </Tr>
);
