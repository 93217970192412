import { GetProgrammaticCampaignMobileDeliveryBreakdownRO } from "@intentsify/dto";
import { apiService, Endpoints } from "api";
import { DisplayPerformanceFilters } from "screens/CampaignPacing/screens/DisplayPerformance";

export const getMobileDeliveryBreakdown = async (
  id: string,
  filters: DisplayPerformanceFilters
): Promise<GetProgrammaticCampaignMobileDeliveryBreakdownRO> => {
  const { data } = await apiService.get(
    Endpoints.ProgrammaticCampaigns.ImpressionsAndClicks.Get.MobileDeliveryBreakdown(
      id
    ),
    {
      params: {
        yearFrom: filters.yearFrom,
        yearTo: filters.yearTo,
        weekFrom: filters.weekFrom,
        weekTo: filters.weekTo,
      },
    }
  );

  return data;
};
