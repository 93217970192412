import { Stack, Text } from "@chakra-ui/react";
import { Option, PaginatedResponse } from "@intentsify/types";
import { isDefined, isPopulatedArray } from "@intentsify/utils";
import { useQuery } from "@tanstack/react-query";
import isEqual from "lodash/isEqual";
import { useEffect, useState } from "react";
import { useDebounce } from "react-use";
import { FetchDataParams } from "types";
import { useDeepEffect } from "utils";
import { Select } from "../Select";

const LIST_ELEMENTS_PER_PAGE = 500;

type FiltersAsyncProps = {
  isDisabled?: boolean;
  isLoading?: boolean;
  label: string;
  placeholder?: string;
  defaultOptions: Option[];
  onFilterValuesChange: (values: Option[]) => void;
  dataRequest: (...args: any[]) => Promise<PaginatedResponse<Option>>;
  // List of additional dependencies that will trigger data re-fetch
  resetFilterRequestDependencies?: any[];
  // Use to make the component controlled
  currentValue?: Option[];
  showLabel?: boolean;
};

type FilterAsyncFetchDataParams = Omit<
  FetchDataParams<keyof Option>,
  "order" | "order_by"
>;

const defaultListPaginatedParams: FilterAsyncFetchDataParams = {
  page: 1,
  per_page: LIST_ELEMENTS_PER_PAGE,
  search: "",
};

const FilterAsync = ({
  isDisabled,
  label,
  isLoading,
  placeholder,
  dataRequest,
  defaultOptions,
  onFilterValuesChange,
  resetFilterRequestDependencies,
  currentValue,
  showLabel = true,
}: FiltersAsyncProps) => {
  const [inputValue, setInputValue] = useState("");
  const [hasData, setHasData] = useState<boolean | undefined>(undefined);
  const [debouncedInputValue, setDebouncedInputValue] = useState("");
  const [selectOptions, setSelectOptions] = useState<Option[] | undefined>();

  const [selectedValues, setSelectedValues] = useState<Option[]>(
    currentValue ?? []
  );

  useEffect(() => {
    if (isDefined(currentValue)) {
      setSelectedValues(currentValue);
    }
  }, [currentValue, setSelectedValues]);

  const [fetchDataParams, setFetchDataParams] =
    useState<FilterAsyncFetchDataParams>(defaultListPaginatedParams);

  const { isFetching, data } = useQuery(
    [
      `filterAsyncList-${label}`,
      debouncedInputValue,
      fetchDataParams,
      ...(resetFilterRequestDependencies || []),
    ],
    () => {
      return dataRequest({
        ...fetchDataParams,
        search: debouncedInputValue,
      });
    },
    {
      enabled: !isDisabled,
      cacheTime: 0,
      staleTime: 0,
      onSuccess: (data) => {
        if (!data) {
          return;
        }

        const { results } = data;

        /**
         * We want to determine if there are any results available. If default params are used and there is no results,
         * input can be disabled. Typing into search input will not yield and results anyway.
         */
        if (isEqual(fetchDataParams, defaultListPaginatedParams)) {
          if (!isPopulatedArray(results)) {
            setHasData(false);
          } else {
            setHasData(true);
          }
        }

        if (fetchDataParams.page === 1) {
          setSelectOptions(results);
          return;
        }

        const currentValues = [...(selectOptions ?? [])];
        setSelectOptions([...currentValues, ...results]);
      },
    }
  );

  useDebounce(
    () => {
      if (selectOptions?.length !== 0 && inputValue.length !== 0) {
        setSelectOptions([]);
      }

      setFetchDataParams(defaultListPaginatedParams);
      setDebouncedInputValue(inputValue);
    },
    200,
    [inputValue]
  );

  useEffect(() => {
    setSelectOptions(undefined);
    setFetchDataParams({
      ...defaultListPaginatedParams,
      search: debouncedInputValue,
    });
  }, [debouncedInputValue]);

  useDeepEffect(() => {
    onFilterValuesChange(selectedValues);
  }, [selectedValues]);

  const handleChange = (option: Option[]) => {
    setSelectedValues(option);
  };

  const handleMenuClose = () => {
    if (
      fetchDataParams.page === data?.meta?.total_pages &&
      fetchDataParams.page !== 1
    ) {
      setSelectOptions([]);
      setFetchDataParams(defaultListPaginatedParams);
    }
  };

  const handleOnFetchDataChange = () => {
    if (data?.meta && fetchDataParams.page < data.meta?.total_pages) {
      setFetchDataParams((prevState: FilterAsyncFetchDataParams) => ({
        ...prevState,
        page: prevState.page + 1,
      }));
    }
  };

  return (
    <Stack>
      {showLabel && (
        <Text fontSize="xs" fontWeight="semibold">
          {label}:
        </Text>
      )}
      <Select
        isDisabled={(isFetching && !hasData) || isDisabled}
        options={selectOptions ? selectOptions : defaultOptions}
        onInputChange={(value: string) => setInputValue(value)}
        filterOption={() => true} // disable native filter
        isLoading={isLoading || isFetching}
        isMulti={true}
        placeholder={placeholder}
        value={selectedValues}
        onFetchDataChange={handleOnFetchDataChange}
        totalPages={data?.meta?.total_pages}
        currentPage={data?.meta?.current_page}
        onMenuClose={handleMenuClose}
        onChange={(option) => handleChange(option)}
      />
    </Stack>
  );
};

export { FilterAsync };
