import { useToast } from "@chakra-ui/react";
import { ListAutomatedProgrammaticSegmentsRO } from "@intentsify/dto";
import { AutomatedProgrammaticSegment, SortDirection } from "@intentsify/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiService, Endpoints } from "api";
import { FetchDataParamsWithToken } from "types";

export const defaultListAutomatedProgrammaticSegmentsParams: FetchDataParamsWithToken<
  keyof AutomatedProgrammaticSegment
> = {
  pageToken: "",
  order_by: "id",
  order: SortDirection.DESC,
};

export const listAutomatedProgrammaticSegments = async (
  params: FetchDataParamsWithToken<
    keyof AutomatedProgrammaticSegment
  > = defaultListAutomatedProgrammaticSegmentsParams
): Promise<ListAutomatedProgrammaticSegmentsRO> => {
  const { data } = await apiService.get(
    Endpoints.AutomatedProgrammaticSegment.Get.AutomatedProgrammaticSegments,
    {
      params,
    }
  );
  return data;
};

export const stopOrStartAutomatedProgrammaticSegment = async ({
  id,
  isStopped,
}: {
  id: number;
  isStopped: boolean;
}) => {
  await apiService.put(
    Endpoints.AutomatedProgrammaticSegment.Put.StopOrStartAutomatedProgrammaticSegment(
      id,
      isStopped ? "start" : "stop"
    )
  );
};

export const useStopOrStartAutomatedProgrammaticSegment = () => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const { mutate, isLoading } = useMutation(
    stopOrStartAutomatedProgrammaticSegment,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["listAutomatedProgrammaticSegments"]);
        toast({
          title: "Automated segment was updated successfully.",
          status: "success",
          duration: 9000,
        });
      },
    }
  );

  return { stopOrStartAutomatedProgrammaticSegment: mutate, isLoading };
};
