import { Flex, Text, useToast } from "@chakra-ui/react";
import { RequestSpotlightDeckDTO } from "@intentsify/dto";
import type {
  BuyerResearchStage,
  CampaignDetails,
  CampaignMeta,
} from "@intentsify/types";
import { useMutation } from "@tanstack/react-query";
import { requestSpotlightDeck } from "api";
import { Badge, Button, Modal } from "components";
import { DateTime } from "luxon";
import { useState } from "react";
import { useUser } from "store/store.hooks";
import { SpotlightComponent } from "./components/Spotlight";

interface SpotlightButtonComponentProps {
  campaignId: number;
  campaignMeta: CampaignMeta | undefined;
  campaignDetails: CampaignDetails;
  domainId: number;
  buyerResearchStage?: BuyerResearchStage;
}

export const SpotlightButtonComponent = ({
  campaignId,
  campaignDetails,
  campaignMeta,
  domainId,
  buyerResearchStage,
}: SpotlightButtonComponentProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const toast = useToast();
  const user = useUser();

  const {
    mutate: requestSpotlightDeckMutation,
    isLoading: isLoadingSpotlightDeckMutation,
  } = useMutation<unknown, unknown, RequestSpotlightDeckDTO>(
    requestSpotlightDeck,
    {
      onSuccess: () => {
        toast({
          title: `Your request for the spotlight report has been received. You will be notified once it's ready.`,
          status: "success",
        });
      },
    }
  );

  return (
    <>
      <Button
        onClick={() => {
          setIsOpen((isCurrentlyOpen) => !isCurrentlyOpen);
        }}
      >
        Show Spotlight
      </Button>
      <Modal
        size="viewContainer"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title={
          <Flex alignItems="flex-start">
            <Flex flexDir="column">
              {`${campaignDetails.domainName} spotlight${
                campaignMeta ? ` for ${campaignMeta.campaignName}` : ""
              }`}
              <Flex mt={2} alignItems="center">
                <Text fontSize="sm" fontWeight="normal">
                  CURRENT BUYER RESEARCH STAGE:
                </Text>
                <Badge ml={2} fontWeight="bold">
                  {buyerResearchStage}
                </Badge>
              </Flex>
            </Flex>
            <Button
              mt={"3px"}
              isLoading={isLoadingSpotlightDeckMutation}
              onClick={() => {
                if (!user) {
                  return;
                }

                requestSpotlightDeckMutation({
                  campaignId,
                  domainIds: [domainId],
                  weekNumber: DateTime.now().minus({ weeks: 1 }).weekNumber,
                  yearNumber: DateTime.now().minus({ weeks: 1 }).weekYear,
                  userId: user.userId,
                });
              }}
              variant="primary"
              size="xs"
              ml={4}
            >
              Export PDF
            </Button>{" "}
          </Flex>
        }
        body={
          <SpotlightComponent
            campaignId={campaignId}
            campaignDetails={campaignDetails}
          />
        }
      />
    </>
  );
};
