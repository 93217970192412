import { InfoIcon } from "@chakra-ui/icons";
import { Center, Flex, useColorMode } from "@chakra-ui/react";
import {
  Campaign,
  CampaignDomainEnhancementStatus,
  CampaignState,
} from "@intentsify/types";
import { isTalProcessing } from "@intentsify/utils";
import { Tooltip } from "components";

const CampaignLabel = ({
  state,
  domainEnhancementStatus,
}: {
  state: CampaignState;
  domainEnhancementStatus: Campaign["domainEnhancementStatus"];
}) => {
  const { colorMode } = useColorMode();
  const isDraft = state === CampaignState.Creating;

  return (
    <Flex alignItems="center">
      {isDraft && (
        <Tooltip
          aria-label="The campaign will remain in draft state until all steps of the campaign build are complete."
          label="The campaign will remain in draft state until all steps of the campaign build are complete."
        >
          <Center
            as="p"
            border="1px solid"
            borderColor={
              colorMode === "light" ? "blackAlpha.500" : "whiteAlpha.500"
            }
            borderRadius="md"
            color={colorMode === "light" ? "blackAlpha.700" : "whiteAlpha.600"}
            mr="2"
            py="0.5"
            px="1.5"
            fontSize="x-small"
            textTransform="uppercase"
            fontWeight="semibold"
            whiteSpace="nowrap"
          >
            Draft
          </Center>
        </Tooltip>
      )}

      {isTalProcessing(state) && (
        <Center
          as="p"
          border="1px solid"
          backgroundColor="yellow.100"
          borderColor="yellow.100"
          borderRadius="md"
          color="blackAlpha.800"
          mr="2"
          py="0.5"
          px="1.5"
          fontSize="x-small"
          textTransform="uppercase"
          fontWeight="semibold"
          whiteSpace="nowrap"
        >
          TAL Processing
        </Center>
      )}

      {domainEnhancementStatus ===
        CampaignDomainEnhancementStatus.PROCESSING && (
        <Center
          as="p"
          border="1px solid"
          backgroundColor="blue.100"
          borderColor="blue.100"
          borderRadius="md"
          color="blackAlpha.800"
          mr="2"
          py="0.5"
          px="1.5"
          fontSize="x-small"
          textTransform="uppercase"
          fontWeight="semibold"
          whiteSpace="nowrap"
        >
          DE processing{" "}
          <Tooltip
            aria-label={"Domain enhancement processing"}
            label={"Domain enhancement processing"}
          >
            <InfoIcon w={3} h={3} ml={2} />
          </Tooltip>
        </Center>
      )}

      {state.includes("error") && (
        <Center
          as="p"
          border="1px solid"
          backgroundColor="red.100"
          borderColor="red.100"
          borderRadius="md"
          color="blackAlpha.800"
          mr="2"
          py="0.5"
          px="1.5"
          fontSize="x-small"
          textTransform="uppercase"
          fontWeight="semibold"
          whiteSpace="nowrap"
        >
          TAL Processing Error
        </Center>
      )}

      {domainEnhancementStatus === CampaignDomainEnhancementStatus.ERROR && (
        <Center
          as="p"
          border="1px solid"
          backgroundColor="red.100"
          borderColor="red.100"
          borderRadius="md"
          color="blackAlpha.800"
          mr="2"
          py="0.5"
          px="1.5"
          fontSize="x-small"
          textTransform="uppercase"
          fontWeight="semibold"
          whiteSpace="nowrap"
        >
          DE error{" "}
          <Tooltip
            aria-label={"Domain enhancement error"}
            label={"Domain enhancement error"}
          >
            <InfoIcon w={3} h={3} ml={2} />
          </Tooltip>
        </Center>
      )}
    </Flex>
  );
};

export { CampaignLabel };
