import { Campaign, Company } from "@intentsify/types";
import { useMutation } from "@tanstack/react-query";
import { Duration } from "luxon";
import { apiService, Endpoints } from "api";

interface Data {
  campaignId: Campaign["campaignId"];
}

const assignCampaigns = async (
  companyId: Company["companyId"],
  integrationId: number,
  data: Data[]
) => {
  const results = await apiService.post(
    Endpoints.Companies.Post.Integrations.AssignCampaigns(),
    data.map((item) => ({
      campaignId: item.campaignId,
      companyId,
      integrationId,
    }))
  );

  return results;
};

export const useAssignCampaigns = (
  companyId: Company["companyId"],
  integrationId: number
) => {
  return useMutation<any, unknown, Data[]>(
    (data) => assignCampaigns(companyId, integrationId, data),
    {
      cacheTime: Duration.fromDurationLike({ minutes: 5 }).as("millisecond"),
    }
  );
};
