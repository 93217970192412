import { Layer, Source } from "react-map-gl";
import {
  impressionsClusterLayerId,
  topicsClusterLayerId,
  topicsPointsLayerId,
  topicsSourceId,
} from "../config";
import { LayerVisibility } from "../ImpressionsAndTopicsMap.types";
import { UseCampaignTopicsByLocationReturnType } from "../hooks";
import { useTopicsClusterLayerProps } from "./useTopicsClusterLayerProps";
import { useTopicsPointsLayerProps } from "./useTopicsPointsLayerProps";
import { WithChildren } from "types";

const TopicsLayers = ({
  topicsByLocation,
  visibility,
  children,
}: WithChildren<{
  topicsByLocation: UseCampaignTopicsByLocationReturnType;
  visibility: LayerVisibility;
}>) => {
  const topicsClusterLayerProps = useTopicsClusterLayerProps(visibility);
  const topicsPointsLayerProps = useTopicsPointsLayerProps(
    topicsByLocation.maxSignalsCount,
    visibility
  );

  return (
    <>
      {children}
      <Source
        cluster
        clusterRadius={30}
        clusterMaxZoom={14}
        id={topicsSourceId}
        type="geojson"
        data={topicsByLocation.geoJson}
        clusterProperties={{
          topic: [
            "concat",
            ["concat", ["get", "topic"], "_", ["get", "count"], ","],
          ],
        }}
      >
        <Layer
          id={topicsClusterLayerId}
          beforeId={impressionsClusterLayerId}
          {...topicsClusterLayerProps}
        />
        <Layer
          id={topicsPointsLayerId}
          beforeId={topicsClusterLayerId}
          {...topicsPointsLayerProps}
        />
      </Source>
    </>
  );
};

export { TopicsLayers };
