import { PastCampaignForList, SortDirection } from "@intentsify/types";
import { useQuery } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import { listPastCampaigns } from "api";
import { Search, Table } from "components";
import { PaginationType } from "components/Table/Table/Table.types";
import { useCallback, useMemo, useState } from "react";
import { useTrackSearched } from "tracking/useTrackSearched";
import { FetchDataParamsWithToken } from "types";
import { getPastCampaignsTableColumns } from "./PastCampaigns.columns";

const defaultListPaginatedCampaignsParams: FetchDataParamsWithToken<
  keyof PastCampaignForList
> = {
  pageToken: "",
  order_by: "campaignId",
  order: SortDirection.DESC,
};

const PastCampaigns = ({
  companyId,
}: {
  companyId: string | number | undefined;
}) => {
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);

  const columns = useMemo<ColumnDef<PastCampaignForList>[]>(
    () => getPastCampaignsTableColumns(),
    []
  );

  const [fetchDataParams, setFetchDataParams] = useState<
    FetchDataParamsWithToken<keyof PastCampaignForList>
  >(defaultListPaginatedCampaignsParams);

  const { data, isFetching } = useQuery(
    ["pastCampaignsList", fetchDataParams, Number(companyId)],
    () => {
      return listPastCampaigns(Number(companyId), fetchDataParams);
    }
  );

  const handleOnFetchDataChange = useCallback(
    (params: FetchDataParamsWithToken<keyof PastCampaignForList>) => {
      setFetchDataParams(params);
    },
    []
  );

  const trackSearched = useTrackSearched();

  return (
    <>
      <Search
        currentValue={searchValue}
        onSearch={(v) => {
          setSearchValue(v);
          trackSearched({ term: v, collocation: "Active campaigns table" });
        }}
      />
      <Table
        noDataMessage="No past campaigns."
        variant="intentsifyNew"
        searchValue={searchValue}
        defaultSort={[
          {
            id: defaultListPaginatedCampaignsParams.order_by,
            desc: true,
          },
        ]}
        columns={columns}
        isFetching={isFetching}
        data={data?.pastCampaigns || []}
        onFetchDataChange={handleOnFetchDataChange}
        resetPaginationDependencies={[companyId, searchValue]}
        paginationType={PaginationType.TOKEN}
        nextPageToken={data?.nextPageToken}
      />
    </>
  );
};

export { PastCampaigns };
