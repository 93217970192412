import {
  Box,
  Menu as ChakraMenu,
  MenuButton as ChakraMenuButton,
  MenuItem as ChakraMenuItem,
  MenuProps as ChakraMenuProps,
  MenuList,
} from "@chakra-ui/react";
import { Tooltip } from "../Tooltip";
import { MenuItem } from "./Menu.types";

type MenuVariant = "primary" | "corporate";

type MenuProps = {
  tooltip?: string;
  /**
   * Has to be chakras MenuButton
   */
  menuButton: React.ReactNode;
  menuItems: MenuItem[];
  placement?: ChakraMenuProps["placement"];
  variant?: MenuVariant;
};

const getMenuStyles = (variant: MenuVariant) => {
  switch (variant) {
    case "primary":
      return {
        menuStyles: {},
        menuListStyles: {},
        menuItemStyles: {},
      };
    case "corporate": {
      return {
        menuStyles: {},
        menuListStyles: {
          paddingY: 0,
          rounded: "md",
        },
        menuItemStyles: {
          rounded: "md",
          fontWeight: "sm",
          fontSize: "sm",
          _hover: {
            backgroundColor: "teal.100",
          },
          _active: {
            backgroundColor: "teal.100",
          },
          _focus: {
            backgroundColor: "teal.100",
          },
          _dark: {
            _hover: {
              backgroundColor: "teal.500",
            },
            _active: {
              backgroundColor: "teal.500",
            },
            _focus: {
              backgroundColor: "teal.500",
            },
          },
        },
      };
    }
  }
};

const Menu = ({
  tooltip,
  menuItems,
  menuButton,
  placement,
  variant = "primary",
}: MenuProps) => {
  const ButtonComponent = menuButton || ChakraMenuButton;

  const button = tooltip ? (
    <Tooltip
      placement="bottom-start"
      aria-label={tooltip}
      label={tooltip}
      shouldWrapChildren
    >
      <>{ButtonComponent}</>
    </Tooltip>
  ) : (
    ButtonComponent
  );

  const { menuStyles, menuListStyles, menuItemStyles } = getMenuStyles(variant);

  return (
    <Box>
      <ChakraMenu placement={placement} strategy="fixed" {...menuStyles}>
        <>
          {button}

          <MenuList {...menuListStyles}>
            {menuItems.map((item) => {
              const { icon: Icon, onClick, label, isDisabled = false } = item;

              return (
                <ChakraMenuItem
                  icon={Icon ? <Icon /> : undefined}
                  onClick={onClick}
                  key={label}
                  isDisabled={isDisabled}
                  {...menuItemStyles}
                >
                  {label}
                </ChakraMenuItem>
              );
            })}
          </MenuList>
        </>
      </ChakraMenu>
    </Box>
  );
};

export { Menu };
