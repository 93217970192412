import { Box, Flex, Heading, Text, useToast } from "@chakra-ui/react";
import { TempAccountFileDTO } from "@intentsify/dto";
import {
  BulkEditCampaignOperation,
  BulkEditCampaignsSubject,
  CampaignForList,
  Option,
} from "@intentsify/types";
import { isDefined, isPopulatedArray } from "@intentsify/utils";
import { useMutation } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import {
  Button,
  ButtonGroup,
  ButtonGroupItem,
  Modal,
  Select,
  TableVirtualised,
  Tabs,
} from "components";
import { useCallback, useEffect, useMemo, useState } from "react";
import { KeywordItem } from "types/Keyword";
import { useDeepEffect } from "utils";
import { useTabItems } from "./BulkEdit.hooks";
import { updateCampaignsInBulk } from "./BulkEdit.requests";
import { getContentsFromTab } from "./BulkEdit.utils";

type BulkEditProps<T extends BulkEditCampaign> = {
  isOpen: boolean;
  onClose: () => void;
  selectedCampaigns: T[];
};

type BulkEditCampaign = Pick<CampaignForList, "campaignId" | "displayName"> & {
  operation?: BulkEditCampaignOperation;
};

const operationItems:
  | ButtonGroupItem<BulkEditCampaignOperation>[]
  | Option<BulkEditCampaignOperation>[] = [
  { label: "Merge", value: BulkEditCampaignOperation.Merge },
  { label: "Replace", value: BulkEditCampaignOperation.Replace },
];

const getTabFromIndex = (index: number): BulkEditCampaignsSubject => {
  switch (index) {
    case 1:
      return BulkEditCampaignsSubject.Keywords;
    case 2:
      return BulkEditCampaignsSubject.Topics;
    case 0:
    default:
      return BulkEditCampaignsSubject.TAL;
  }
};

const BulkEdit = <T extends BulkEditCampaign>({
  isOpen,
  onClose,
  selectedCampaigns,
}: BulkEditProps<T>) => {
  const [tab, setTab] = useState(0);
  const currentTab = getTabFromIndex(tab);
  const [selectedOperation, setSelectedOperation] = useState<
    BulkEditCampaignOperation | undefined
  >();
  const [campaigns, setCampaigns] = useState<BulkEditCampaign[]>([]);
  const [areOperationsSelected, setAreOperationsSelected] = useState(false);
  const [accountsFile, setAccountsFile] = useState<
    TempAccountFileDTO | undefined
  >(undefined);
  const [uploadedKeywords, setUploadedKeywords] = useState<KeywordItem[]>([]);
  const [uploadedTopics, setUploadedTopics] = useState<string[]>([]);
  const toast = useToast({ duration: 4000 });

  const tabItems = useTabItems({
    accountsFile,
    uploadedKeywords,
    uploadedTopics,
    setUploadedKeywords,
    setUploadedTopics,
    setAccountsFile,
  });

  const resetState = useCallback(() => {
    setTab(0);
    setCampaigns(campaigns.map((i) => ({ ...i, operation: undefined })));
    setAccountsFile(undefined);
    setUploadedKeywords([]);
    setUploadedTopics([]);
  }, [campaigns]);

  const refreshAreOperationsSelected = useCallback(() => {
    setAreOperationsSelected(campaigns.every((i) => isDefined(i.operation)));
  }, [setAreOperationsSelected, campaigns]);

  useDeepEffect(() => {
    refreshAreOperationsSelected();
  }, [campaigns]);

  const updateOperation = useCallback(
    (campaignId: number, operation: BulkEditCampaignOperation) => {
      const campaign = campaigns.find((i) => i.campaignId === campaignId);

      if (campaign) {
        campaign.operation = operation;
        refreshAreOperationsSelected();
      }
    },
    [campaigns, refreshAreOperationsSelected]
  );

  useEffect(() => {
    setCampaigns(
      selectedCampaigns.map((i) => ({
        campaignId: i.campaignId,
        displayName: i.displayName,
      }))
    );
  }, [selectedCampaigns]);

  const { mutate, isLoading } = useMutation(updateCampaignsInBulk, {
    onSuccess: () => {
      resetState();
      onClose();
      toast({
        title: `The request to update your ${getContentsFromTab(
          currentTab
        ).label.toLocaleLowerCase()} has been received. You will be notified once complete.`,
        status: "success",
      });
    },
  });

  const columns = useMemo<ColumnDef<BulkEditCampaign>[]>(
    () => [
      {
        header: "ID",
        accessorKey: "campaignId",
      },
      {
        header: "Name",
        accessorKey: "displayName",
      },
      {
        header: "Operation",
        accessorKey: "operation",
        enableSorting: false,
        cell: ({ getValue, row }) => {
          const initialValue = getValue<BulkEditCampaignOperation>();
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const [value, setValue] = useState(initialValue);

          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            setValue(initialValue);
          }, [initialValue]);

          return (
            <Flex>
              <ButtonGroup
                selectedValue={value}
                setSelectedValue={(value: BulkEditCampaignOperation) => {
                  updateOperation(row.original.campaignId, value);
                  setValue(value);
                }}
                items={operationItems}
                size="xs"
              />
            </Flex>
          );
        },
      },
    ],
    [updateOperation]
  );

  const isApplyButtonDisabled = useMemo(() => {
    if (!areOperationsSelected) {
      return true;
    }

    if (
      currentTab === BulkEditCampaignsSubject.Keywords &&
      !isPopulatedArray(uploadedKeywords)
    ) {
      return true;
    }

    if (
      currentTab === BulkEditCampaignsSubject.Topics &&
      !isPopulatedArray(uploadedTopics)
    ) {
      return true;
    }

    if (currentTab === BulkEditCampaignsSubject.TAL && !accountsFile) {
      return true;
    }

    return false;
  }, [
    currentTab,
    areOperationsSelected,
    accountsFile,
    uploadedKeywords,
    uploadedTopics,
  ]);

  return (
    <Modal
      size="4xl"
      title="Bulk Edit Intent Models"
      subtitle={`Quickly update multiple intent models at once. Choose from Target Account List, keywords or topics. After you upload the file and set the correct operation, click the "Update" button. You will receive a notification after the process has been completed.`}
      body={
        <Box>
          <Tabs
            strech={true}
            items={tabItems}
            colorScheme="cyan"
            onChange={(tab) => {
              resetState();
              setTab(tab);
            }}
          />

          <Heading as="h3" fontSize="1xl" mb={2}>
            Updating {getContentsFromTab(currentTab).label}
          </Heading>

          <Text mb={4} fontSize="sm">
            {getContentsFromTab(currentTab).desc}
          </Text>

          <Flex mb={4} justifyContent="flex-end">
            <Select
              isClearable={false}
              isMulti={false}
              options={operationItems}
              value={(operationItems as any).find(
                (dr: Option<BulkEditCampaignOperation>) =>
                  dr.value === selectedOperation
              )}
              placeholder="Operation"
              onChange={(option) => {
                if (option) {
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                  setSelectedOperation(option.value);
                }
              }}
            />

            <Button
              onClick={() => {
                const updated = campaigns.map((i) => ({
                  ...i,
                  operation: selectedOperation,
                }));

                setCampaigns(updated);
              }}
              ml={2}
              variant="secondary"
            >
              Apply To All Campaigns
            </Button>
          </Flex>

          <TableVirtualised<BulkEditCampaign>
            data={campaigns}
            columns={columns}
            containerProps={{ width: "100%", height: "300px" }}
          />
        </Box>
      }
      isOpen={isOpen}
      onClose={() => {
        resetState();
        onClose();
      }}
      primaryButton={
        <Button
          isLoading={isLoading}
          onClick={() => {
            mutate({
              dto: {
                subject: currentTab,
                campaigns: campaigns.map((i) => {
                  if (!i.operation) {
                    throw Error(
                      "Operation must be defined, should never happen"
                    );
                  }

                  return {
                    campaignId: i.campaignId,
                    operation: i.operation,
                  };
                }),
                talFile: accountsFile?.tempFileName,
                keywords: uploadedKeywords.map((i) => i.label) ?? [],
                topics: uploadedTopics,
              },
            });
          }}
          isDisabled={isApplyButtonDisabled}
          variant="primary"
        >
          Update {getContentsFromTab(currentTab).label} for {campaigns.length}{" "}
          campaigns
        </Button>
      }
      secondaryButton={
        <Button
          onClick={() => {
            resetState();
            onClose();
          }}
          variant="secondary"
        >
          Cancel
        </Button>
      }
    />
  );
};

export { BulkEdit };
