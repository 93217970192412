import { Box } from "@chakra-ui/react";
import { ColumnDef } from "@tanstack/react-table";
import { Modal, Search, Table } from "components";
import startCase from "lodash/startCase";
import { useMemo, useState } from "react";
import { useTrackSearched } from "tracking/useTrackSearched";

const DomainsDuplicates = ({
  duplicates,
  columns,
  isOpen,
  onClose,
}: {
  duplicates: string[][];
  columns: string[];
  isOpen: boolean;
  onClose: () => void;
}) => {
  const serializedDuplicates: Record<string, unknown>[] = [];
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);

  const tableColumns = useMemo<ColumnDef<any>[]>(
    () =>
      columns.map((column) => ({
        header: startCase(column),
        accessorKey: column,
      })),
    [columns]
  );

  const trackSearched = useTrackSearched();

  if (duplicates?.length === 0) {
    return null;
  }

  duplicates.map((duplicatedElement) => {
    const preparedDuplicate: Record<string, unknown> = {};

    duplicatedElement.forEach((item, index) => {
      const columnName = columns[index];
      preparedDuplicate[columnName] = item;
    });

    serializedDuplicates.push(preparedDuplicate);
  });

  return (
    <Modal
      size="4xl"
      title={"Duplicate domains"}
      body={
        <Box p={0.1}>
          <Search
            currentValue={searchValue}
            onSearch={(v) => {
              setSearchValue(v);
              trackSearched({
                term: v,
                collocation: "Domains upload duplicate domains table",
              });
            }}
          />
          <Table
            variant="intentsifyNew"
            columns={tableColumns}
            data={serializedDuplicates}
            isScrollable={false}
            isControlledByAPI={false}
            stretch
            overflow={"hidden"}
            searchValue={searchValue}
            resetPaginationDependencies={[searchValue]}
          />
        </Box>
      }
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};

export { DomainsDuplicates };
