import { useMemo } from "react";
import { ResultsTableTab } from "./ResultsTable.tabs";
import { useKeywordsAndTopicsState } from "screens/Campaigns/screens/CampaignsWizard/components/KeywordsAndTopicsStep/KeywordsAndTopics.hooks";

export interface ResultsTableEntry {
  name: string;
  score: number;
}

interface UseDataOptions {
  tab: ResultsTableTab;
  search?: string;
}

export const useData = (options: UseDataOptions) => {
  const { state } = useKeywordsAndTopicsState();
  const data = useMemo(() => {
    if (options.tab === ResultsTableTab.Keywords) {
      return state.autodiscoverySettings.autodiscoveryKeywords.map(
        (keyword) => ({
          name: keyword.keyword,
          score: keyword.score,
        })
      );
    }

    if (options.tab === ResultsTableTab.Topics) {
      return state.autodiscoverySettings.autodiscoveryTopics.map((topic) => ({
        name: topic.topic,
        score: topic.score,
      }));
    }

    if (options.tab === ResultsTableTab.Products) {
      return state.autodiscoverySettings.autodiscoveryProducts.map(
        (product) => ({
          name: product.topic,
          score: product.score,
        })
      );
    }

    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    throw new Error(`Unknown tab: ${options.tab}`);
  }, [
    options.tab,
    state.autodiscoverySettings.autodiscoveryKeywords,
    state.autodiscoverySettings.autodiscoveryProducts,
    state.autodiscoverySettings.autodiscoveryTopics,
  ]);

  const filteredData = useMemo(() => {
    if (!options.search) {
      return data;
    }

    return data.filter((entry) =>
      entry.name
        .toLocaleLowerCase()
        .includes(options.search?.toLocaleLowerCase() as string)
    );
  }, [data, options.search]);

  return filteredData;
};
