import { DisplayPerformanceScreen } from "types";
import { createScreen } from "utils/createScreen";

const DisplayPerformanceScreenDefinition = createScreen({
  name: DisplayPerformanceScreen,
  path: "/dashboard/programmatic-performance/:programmaticCampaignId/:view",
  routePath: [
    "/programmatic-performance",
    "/programmatic-performance/:programmaticCampaignId",
    "/programmatic-performance/:programmaticCampaignId/:view",
  ],
  accessPath: "campaign.intentAnalysisWithSpotlight",
});

export { DisplayPerformanceScreenDefinition };
