import { Divider } from "@chakra-ui/react";
import { signalLabels } from "@intentsify/types";
import { isPopulatedArray } from "@intentsify/utils";
import { useQuery } from "@tanstack/react-query";
import { Loader, NoData, TabItem, Tabs } from "components";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { accountDetailsAtom } from "screens/IntentAnalysis/screens/CampaignsTrendingDomains/CampaignsTrendingDomains.state";
import { singleAccountDetailsFiltersAtom } from "./SingleAccountDetails.state";
import { getAvailableSignalTypes } from "./SingleAcountDetails.requests";
import { DetailsTable } from "./components";

const SingleAccountDetails = () => {
  const accountDetails = useRecoilValue(accountDetailsAtom);
  const resetFilters = useResetRecoilState(singleAccountDetailsFiltersAtom);

  const { data: availableSignalTypes, isFetching } = useQuery(
    ["availableSignalTypes", accountDetails?.campaignId],
    () => {
      if (accountDetails) {
        return getAvailableSignalTypes({
          campaignId: accountDetails.campaignId,
          account: accountDetails.account,
          dateRange: accountDetails.dateRange,
        });
      }
    },
    { enabled: Boolean(accountDetails) }
  );

  if (isFetching) {
    return <Loader stretch />;
  }

  if (!isPopulatedArray(availableSignalTypes?.data)) {
    return <NoData stretch />;
  }

  const signalTabItems: TabItem[] | undefined = availableSignalTypes?.data.map(
    (s) => {
      return {
        title: signalLabels[s],
        body: (
          <>
            <Divider mb={4} />
            <DetailsTable signalType={s} />
          </>
        ),
      };
    }
  );

  return (
    <Tabs
      strech
      isLazy
      defaultIndex={0}
      items={signalTabItems ?? []}
      colorScheme="blue"
      variant="soft-rounded"
      onChange={() => {
        resetFilters();
      }}
    />
  );
};

export { SingleAccountDetails };
