import { coerceNumber } from "@visx/scale";
import { TIMESERIES_X_AXIS_TICK_REQUIRED_SPACE } from "../Charts.const";
import { TimeseriesItem, TimeseriesItemDataRecord } from "types";

export const getMinMax = (vals: (number | { valueOf(): number })[]) => {
  const numericVals = vals.map(coerceNumber);
  return [Math.min(...numericVals), Math.max(...numericVals)];
};

export const renderLegendTooltip = (
  legendHoverItem:
    | TimeseriesItem<string, TimeseriesItemDataRecord<string>>
    | undefined,
  disabledLegendItems: TimeseriesItem<
    string,
    TimeseriesItemDataRecord<string>
  >[],

  showTooltip: (args: {
    tooltipLeft: number;
    tooltipTop: number;
    tooltipData: TimeseriesItem<string, TimeseriesItemDataRecord<string>>;
  }) => void,
  hideTooltip: () => void
) => {
  const isDisabled = legendHoverItem
    ? disabledLegendItems.find(
        (i) => i.timeseriesItemId === legendHoverItem.timeseriesItemId
      )
    : false;

  if (isDisabled || !legendHoverItem) {
    hideTooltip();
    return;
  }

  const tooltipTop = 0;
  const tooltipLeft = 0;

  showTooltip({ tooltipData: legendHoverItem, tooltipLeft, tooltipTop });
};

export const findXAxisLabelFrequency = (
  {
    width,
    itemsCount,
  }: {
    width: number;
    itemsCount: number;
  },
  every = 1
): number => {
  if (TIMESERIES_X_AXIS_TICK_REQUIRED_SPACE > width) {
    return 0;
  }

  const space = width / (itemsCount / every);

  if (space >= TIMESERIES_X_AXIS_TICK_REQUIRED_SPACE) {
    return every;
  }

  return findXAxisLabelFrequency({ width, itemsCount }, every + 1);
};
