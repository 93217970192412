import { Button, Center, Icon, Text } from "@chakra-ui/react";
import { pluralize } from "@intentsify/utils";
import { FiCheck, FiEdit } from "react-icons/fi";
import { IntegrationWithLogo } from "../../IntegrationsModal.utils";
import { ProviderCard, TagCampaign } from "./ProviderCard";

interface EnabledProviderProps {
  integration: IntegrationWithLogo;
  campaignsAssigned: TagCampaign[];
  setIsEditing: (isEditing: boolean) => void;
}

export const EnabledProvider = (props: EnabledProviderProps) => {
  return (
    <ProviderCard.Root>
      <ProviderCard.Header {...props} color="white">
        <Center justifyContent="flex-start" ml="-3" mt="-1">
          <Icon as={FiCheck} color="green.200" fontSize="lg" />
        </Center>

        <Center flex="1" justifyContent="flex-end">
          <Text color="whiteAlpha.600" fontSize="sm">
            {props.campaignsAssigned.length}{" "}
            {pluralize(props.campaignsAssigned.length, "campaign")} assigned
          </Text>

          <Button
            ml="4"
            py="2"
            h="100%"
            variant="ghost"
            onClick={() => props.setIsEditing(true)}
          >
            <Icon as={FiEdit} color="whiteAlpha.600" />
          </Button>
        </Center>
      </ProviderCard.Header>
    </ProviderCard.Root>
  );
};
