import { atomFamily } from "recoil";
import { HoveredNearestPoint } from "./Timeseries.types";
import { TimeseriesItem, TimeseriesItemDataRecord } from "types";

export const legendHoverAtomFamily = atomFamily<
  TimeseriesItem<string, TimeseriesItemDataRecord<string>> | undefined,
  string
>({
  key: "timeseriesLegendHoverAtom",
  default: undefined,
});

export const legendDataSourceHoverFamily = atomFamily<
  string | undefined,
  string
>({
  key: "legendDataSourceHover",
  default: undefined,
});

export const hoveredLineAtomFamily = atomFamily<
  TimeseriesItem<string, TimeseriesItemDataRecord<string>> | undefined,
  string
>({
  key: "hoveredLineAtom",
  default: undefined,
});

export const hoveredNearestPointAtomFamily = atomFamily<
  HoveredNearestPoint | undefined,
  string
>({
  key: "hoveredNearestPointAtom",
  default: undefined,
});

export const disabledLegendItemsFamily = atomFamily<
  Array<TimeseriesItem<string, TimeseriesItemDataRecord<string>>>,
  string
>({
  key: "disabledLegendItems",
  default: [],
});

export const disabledDataKeysFamily = atomFamily<string[], string>({
  key: "disabledDataKeysFamily",
  default: [],
});

export const hoveredXAxisLabelFamily = atomFamily<string | undefined, string>({
  key: "hoveredXAxisLabelFamily",
  default: undefined,
});
