import {
  Flex,
  StylesProvider,
  useColorModeValue,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import { ControlProps, GroupBase } from "react-select";
import { Option as IntentsifyOption } from "@intentsify/types";

const Control = <
  Option extends IntentsifyOption,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: ControlProps<Option, IsMulti, Group>
) => {
  const { innerProps, isFocused, isDisabled, innerRef, children, selectProps } =
    props;
  const inputStyles = useMultiStyleConfig("Input", {});

  return (
    <StylesProvider value={inputStyles}>
      <Flex
        ref={innerRef}
        sx={{
          ...inputStyles.field,
          p: 0,
          overflow: "hidden",
          h: "auto",
          color: useColorModeValue("gray.700", "gray.200"),
          minH: 4,
        }}
        {...innerProps}
        {...(isFocused && { "data-focus": true })}
        {...(isDisabled && { disabled: true })}
        aria-invalid={selectProps["aria-invalid"]}
      >
        {children}
      </Flex>
    </StylesProvider>
  );
};

export { Control };
