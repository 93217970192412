import { Box, Flex } from "@chakra-ui/react";
import { AccountFileDTO } from "@intentsify/dto";
import { FirmographicsFilters } from "@intentsify/types";
import { Endpoints } from "api";
import isEqual from "lodash/isEqual";
import { useMemo, useState } from "react";
import { UploadedFileEntry } from "shared/components/UploadedFiles/UploadedFileEntry";
import { UploadedFiles } from "shared/components/UploadedFiles/UploadedFiles";
import { useDownloadFile } from "utils";

import { filtersOrNull } from "components/Firmographics/Firmographics.utils";
import { Firmographics, FirmographicsProps } from "components/FirmographicsNew";
import { defaultFirmoFilters } from "components/FirmographicsNew/Firmographics.const";

type WizardFirmographicsProps = FirmographicsProps & {
  campaignId: number;
  files: AccountFileDTO[];
  currentFirmographicsFilters: FirmographicsFilters | undefined;
};

const WizardFirmographics = ({
  campaignId,
  currentFirmographicsFilters,
  firmographicsFilters,
  onFirmographicsFiltersChange,
  onCountChange,
  files,
}: WizardFirmographicsProps) => {
  const didEditFilters = useMemo(() => {
    return !isEqual(currentFirmographicsFilters, firmographicsFilters);
  }, [currentFirmographicsFilters, firmographicsFilters]);
  const hasAnyFilters = Boolean(filtersOrNull(firmographicsFilters));
  const { download } = useDownloadFile();
  const fileName = `firmographics_domains_${Number(campaignId)}.csv`;

  const file = didEditFilters && hasAnyFilters ? undefined : files[0];

  /**
   * Some old campaigns have firmographic domains but no filters stored in the DB
   */
  const [shouldRenderFileAnyway, setShouldRenderFileAnyway] = useState(
    Boolean(!filtersOrNull(firmographicsFilters) && file)
  );

  const onDownloadClick =
    file && !didEditFilters && hasAnyFilters
      ? () => {
          download({
            url: Endpoints.Campaigns.Get.Domains.DownloadFirmographicsFile(
              Number(campaignId)
            ),
            fileName,
          });
        }
      : undefined;

  return (
    <>
      <Firmographics
        fileName={`firmographics_domains_${Number(campaignId)}.csv`}
        onCountChange={onCountChange}
        firmographicsFilters={firmographicsFilters}
        onFirmographicsFiltersChange={onFirmographicsFiltersChange}
        file={
          file && onDownloadClick
            ? {
                dto: file,
                onDownloadClick,
              }
            : undefined
        }
      />

      {shouldRenderFileAnyway && file && (
        <Flex h="100%" flex="1" flexDir="column">
          <Box
            w="100%"
            borderBottomLeftRadius={5}
            borderBottomRightRadius={5}
            p={4}
            border="1px solid #a1aabb33"
          >
            <UploadedFiles>
              <UploadedFileEntry
                file={file}
                onDeleteIconClick={() => {
                  setShouldRenderFileAnyway(false);
                  onFirmographicsFiltersChange(defaultFirmoFilters);
                }}
                onDownloadClick={() => {
                  download({
                    url: Endpoints.Campaigns.Get.Domains.DownloadFirmographicsFile(
                      Number(campaignId)
                    ),
                    fileName,
                  });
                }}
              />
            </UploadedFiles>
          </Box>
        </Flex>
      )}
    </>
  );
};

export { WizardFirmographics };
