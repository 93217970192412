import { LayerProps } from "react-map-gl";
import { LayerVisibility } from "../ImpressionsAndTopicsMap.types";

const useImpressionsClusterCountLayerProps = (
  visibility: LayerVisibility
): LayerProps => {
  return {
    type: "symbol",
    filter: ["has", "point_count"],
    layout: {
      visibility: visibility,
      "text-field": [
        "number-format",
        ["round", ["get", "sum"]],
        { "min-fraction-digits": 0, "max-fraction-digits": 0 },
      ],
      "text-size": ["step", ["get", "point_count"], 8, 25, 12, 750, 12],
    },
    paint: {
      "text-color": "#000",
    },
  };
};

export { useImpressionsClusterCountLayerProps };
