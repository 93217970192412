import { Heading, useColorModeValue } from "@chakra-ui/react";

const AuthFormHeader = ({ text }: { text: string }) => {
  return (
    <Heading
      size="lg"
      pt={4}
      pb={8}
      color={useColorModeValue("white", "white")}
    >
      {text}
    </Heading>
  );
};

export { AuthFormHeader };
