import { isPopulatedArray } from "@intentsify/utils";
import { queryUrlSeparator } from "const";
import omit from "lodash/omit";
import {
  BaseFilter,
  BaseFilterRecordValue,
  FetchDataParams,
  isOption,
} from "types";

/**
 * Takes a value of BaseFilterRecordValue type and transforms it into a valid query URL string
 */
export const valueToQueryURL = (value: BaseFilterRecordValue) => {
  if (Array.isArray(value)) {
    const onlyValues = value
      .map((f) => {
        if (isOption(f)) {
          return encodeURIComponent(f.value);
        }

        return encodeURIComponent(f);
      })
      .join(queryUrlSeparator);

    return onlyValues;
  }

  return encodeURIComponent(String(value));
};

/**
 * Converts BaseFilter into query URL
 */
const baseFilterToQueryUrl = <T>(filters: BaseFilter<T>) => {
  if (!filters) {
    return "";
  }

  const entries = Object.entries(filters) as unknown as [
    string,
    BaseFilterRecordValue
  ][];

  if (!isPopulatedArray(entries)) {
    return "";
  }

  let query = ``;

  entries.forEach((filter) => {
    const [filterKey, filterValue] = filter;

    if (filterKey && filterValue) {
      const val = valueToQueryURL(filterValue);

      if (val.length === 0) {
        return query;
      }

      query += `&${filterKey}=${val}`;
    }
  });

  return query;
};

/**
 * Converts FetchDataParams into query URL.
 */
export const paramsToQueryUrl = <T>({
  params,
  includeFirstAmpersand = true,
}: {
  params: Partial<FetchDataParams<unknown, BaseFilter<T>>> | undefined;
  includeFirstAmpersand: boolean;
}) => {
  if (!params) {
    return "";
  }

  const { filters } = params;

  const withoutFilters = omit(params, ["filters"]);

  let query = "";

  Object.entries(withoutFilters).map((filter) => {
    const [filterKey, filterValue] = filter;

    if (filterKey && filterValue) {
      query += `&${filterKey}=${encodeURIComponent(String(filterValue))}`;
    }
  });

  if (typeof filters === "object") {
    query += baseFilterToQueryUrl(filters);
  }

  if (!includeFirstAmpersand) {
    query = query.slice(1);
  }

  return query;
};
