import { Heading, SimpleGrid, Stack, Text } from "@chakra-ui/react";
import { ProgrammaticDomainSegment } from "@intentsify/types";
import startCase from "lodash/startCase";

type SegmentDetailsProps = {
  programmaticDomainSegment: ProgrammaticDomainSegment;
};

const SegmentDetails = ({ programmaticDomainSegment }: SegmentDetailsProps) => {
  return (
    <SimpleGrid columns={1}>
      <Heading pb={4} as="h2" size="md">
        {startCase(programmaticDomainSegment.segmentType.replace("_", " "))}{" "}
        Segment Details
      </Heading>
      <Stack direction="row">
        <Text fontWeight="semibold">Pollinator version:</Text>
        <Text fontWeight="light">
          {programmaticDomainSegment.pollinatorVersion || " "}
        </Text>
      </Stack>
      <Stack direction="row">
        <Text fontWeight="semibold">Beeswax segment ID:</Text>
        <Text fontWeight="light">
          {programmaticDomainSegment.beeswaxSegmentId || " "}
        </Text>
      </Stack>
      <Stack direction="row">
        <Text fontWeight="semibold">Unique segments count:</Text>
        <Text fontWeight="light">
          {programmaticDomainSegment.uniqueSegmentsCount || " "}
        </Text>
      </Stack>
      <Stack direction="row">
        <Text fontWeight="semibold">Lines processed count:</Text>
        <Text fontWeight="light">
          {programmaticDomainSegment.linesProcessedCount || " "}
        </Text>
      </Stack>
      <Stack direction="row">
        <Text fontWeight="semibold">Unique invalid segments count:</Text>
        <Text fontWeight="light">
          {programmaticDomainSegment.uniqueInvalidSegmentsCount || " "}
        </Text>
      </Stack>
      <Stack direction="row">
        <Text fontWeight="semibold">Lines succeeded count:</Text>
        <Text fontWeight="light">
          {programmaticDomainSegment.linesSucceedCount || " "}
        </Text>
      </Stack>
      <Stack direction="row">
        <Text fontWeight="semibold">Invalid segments count:</Text>
        <Text fontWeight="light">
          {programmaticDomainSegment.invalidSegmentsCount || " "}
        </Text>
      </Stack>
      <Stack direction="row">
        <Text fontWeight="semibold">Lines failed count:</Text>
        <Text fontWeight="light">
          {programmaticDomainSegment.linesFailedCount || " "}
        </Text>
      </Stack>
      <Stack direction="row">
        <Text fontWeight="semibold">Invalid user IDs count:</Text>
        <Text fontWeight="light">
          {programmaticDomainSegment.invalidUserIdsCount || " "}
        </Text>
      </Stack>
      <Stack direction="row">
        <Text fontWeight="semibold">Line parser error:</Text>
        <Text fontWeight="light">
          {programmaticDomainSegment.lineParseError || " "}
        </Text>
      </Stack>
      <Stack direction="row">
        <Text fontWeight="semibold">Is liveramp derived idl?</Text>
        <Text fontWeight="light">
          {programmaticDomainSegment.isLiverampDerivedIdl || " "}
        </Text>
      </Stack>
      <Stack direction="row">
        <Text fontWeight="semibold">Skipped segment count:</Text>
        <Text fontWeight="light">
          {programmaticDomainSegment.skippedSegmentCount || " "}
        </Text>
      </Stack>
      <Stack direction="row">
        <Text fontWeight="semibold">Not found all IDs for segment count:</Text>
        <Text fontWeight="light">
          {programmaticDomainSegment.notFoundAltIdsForSegmentCount || " "}
        </Text>
      </Stack>
      <Stack direction="row">
        <Text fontWeight="semibold">Translated customer ID count:</Text>
        <Text fontWeight="light">
          {programmaticDomainSegment.translatedCustomerIdCount || " "}
        </Text>
      </Stack>
      <Stack direction="row">
        <Text fontWeight="semibold">Unmappable customer ID count:</Text>
        <Text fontWeight="light">
          {programmaticDomainSegment.unmappableCustomerIdCount || " "}
        </Text>
      </Stack>
    </SimpleGrid>
  );
};

export { SegmentDetails };
