import { GenericUserJobType } from "@intentsify/types";
import { Endpoints } from "api";
import { useEffect } from "react";
import { useParams } from "react-router";
import { useMostRecentDownloadStatus } from "screens/Download/Download";
import { DownloadItemStatus, useDownloadFile, useScreen } from "utils";
import { ampli } from "../../tracking/amplitude";
import { DownloadStatus } from "../Download/DownloadStatus";
import { DownloadTalToolsDefinition } from "./DownloadTalTools.definition";

const DownloadTalTools = () => {
  useScreen(DownloadTalToolsDefinition);

  const { type } = useParams<"type">();
  const { fileName } = useParams<"fileName">();
  const { jobId } = useParams<"jobId">();

  if (
    !type ||
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    !Object.values(GenericUserJobType).includes(type as GenericUserJobType) ||
    !fileName ||
    !jobId
  ) {
    throw new Error("404");
  }

  const talToolType = type as GenericUserJobType;

  const { download } = useDownloadFile();
  const downloadingStatus = useMostRecentDownloadStatus(fileName);

  useEffect(() => {
    if (downloadingStatus !== DownloadItemStatus.IN_PROGRESS) {
      download({
        url: Endpoints.TalTools.Download(Number(jobId)),
        fileName,
      });
      ampli.fileDownloaded({
        fileName,
        label: talToolType,
        pagePath: window.location.pathname,
      });
    }

    // We want it to trigger once only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <DownloadStatus status={downloadingStatus} />;
};

export { DownloadTalTools };
