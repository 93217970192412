import {
  DateRange,
  defaultDateRangeFilters,
  SignalType,
} from "@intentsify/types";
import { getDatesFromDateRange, isPopulatedArray } from "@intentsify/utils";
import { useQuery } from "@tanstack/react-query";
import {
  BarChart,
  DateRangeFilter,
  FiltersContainer,
  FiltersContainerItem,
  ViewContainer,
} from "components";
import { useMemo } from "react";
import { useParams } from "react-router";
import { useRecoilState } from "recoil";
import { getTopSignals } from "screens/IntentAnalysis/screens/CampaignsTrendingSignals/CampaignsTrendingSignals.requests";
import {
  mostResearchedKeywordsDateRangeAtom,
  mostResearchedTopicsDateRangeAtom,
} from "screens/IntentAnalysis/screens/CampaignsTrendingSignals/CampaignsTrendingSignals.state";
import {
  ChartExport,
  useCampaignMeta,
  useExportableChart,
} from "shared/components";

type TopSignalsProps = {
  signalType: SignalType;
  headerText: string;
};

const TopSignals = ({ signalType, headerText }: TopSignalsProps) => {
  const campaignId = Number(useParams<"campaignId">().campaignId);

  const { campaignMeta: meta } = useCampaignMeta({ campaignId });

  const recoilDateRange =
    signalType === SignalType.Topics
      ? mostResearchedTopicsDateRangeAtom
      : mostResearchedKeywordsDateRangeAtom;

  const [dateRange, setDateRange] = useRecoilState(recoilDateRange);

  const { data, isFetching } = useQuery(
    [`topSignals-${signalType}`, campaignId, dateRange],
    () => {
      if (campaignId) {
        return getTopSignals({
          signalType,
          campaignId,
          dateRange,
          size: 10,
        });
      }
    }
  );

  const { start, end } = getDatesFromDateRange(
    dateRange,
    new Date(meta?.campaignStartDate ?? ""),
    new Date(meta?.campaignEndDate ?? "")
  );

  const hasNoOptions = !isPopulatedArray(data);

  const csvData = !hasNoOptions
    ? data.map((i) => ({
        term: i.label,
        count: i.value,
      }))
    : [];

  const dates = useMemo(() => {
    return {
      start: start.toISO(),
      end: end.toISO(),
    };
  }, [start, end]);

  const exportableChart = useExportableChart({
    title: headerText,
    campaignId: Number(campaignId),
    dates,
    chart: (
      <BarChart
        stretch
        orientation="horizontal"
        isLoading={isFetching}
        data={isPopulatedArray(data) ? data : []}
        valueAxisLabel="Sum - Count of Engagement"
      />
    ),
  });

  const items: FiltersContainerItem[] = [
    {
      component: (
        <DateRangeFilter<DateRange>
          isLoading={isFetching}
          isDisabled={isFetching}
          dateRange={dateRange}
          options={defaultDateRangeFilters.dateRangeOptions}
          onChange={(value) => setDateRange(value)}
        />
      ),
    },
  ];

  return (
    <ViewContainer
      withBackground
      expandable={true}
      title={headerText}
      actionItems={
        <ChartExport
          dates={dates}
          campaignId={Number(campaignId)}
          title={exportableChart.title}
          onExportPNG={exportableChart.downloadAsPng}
          data={csvData ?? []}
        />
      }
    >
      <FiltersContainer items={items} />
      {exportableChart.component}
      {exportableChart.exportComponent}
    </ViewContainer>
  );
};

export { TopSignals };
