import { useState } from "react";
import { useDeepEffect } from "utils";

const useDynamicInput = <T extends number | string>(initialItems: T[]) => {
  const [items, setItems] = useState<T[]>([]);

  useDeepEffect(() => {
    setItems(initialItems);
  }, [initialItems]);

  const addItem = (item: T) => {
    setItems((oldItems) => [...oldItems, item]);
  };

  const removeItem = (item: T) => {
    const newItems = items.filter((i) => i !== item);
    setItems(newItems);
  };

  return {
    items,
    setItems,
    addItem,
    removeItem,
  };
};

export { useDynamicInput };
