import { Flex } from "@chakra-ui/layout";
import { ReviewedAsset, SortDirection } from "@intentsify/types";
import { RowSelectionState } from "@tanstack/react-table";
import { Table as ComponentsTable, useRowSelection } from "components";
import { PaginationType } from "components/Table/Table/Table.types";
import { useState } from "react";
import { FetchDataParamsWithToken } from "types";
import { BatchDeleteButton } from "../BatchDeleteButton";
import { BatchUpdateModal } from "./BatchUpdateModal";
import { NoData } from "./NoData";
import { useColumns } from "./useColumns";
import { useDeleteReviewedAssets } from "./useDeleteReviewedAssets";
import { useReviewedAssets } from "./useReviewedAssets";

type TableProps = {
  searchValue: string;
  campaignIds?: number[];
};

export const Table = (props: TableProps) => {
  const [params, setParams] = useState<
    FetchDataParamsWithToken<keyof ReviewedAsset>
  >({ pageToken: "", order: SortDirection.DESC, order_by: "reviewedAt" });
  const draftAssets = useReviewedAssets({
    ...params,
    search: props.searchValue,
    campaignIds: props.campaignIds,
  });

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const uniqueKeyAccessor = (i: ReviewedAsset) => i.id;
  const { selectedRows, removeSelection } = useRowSelection<ReviewedAsset>(
    rowSelection,
    setRowSelection,
    draftAssets.data?.reviewedAssets,
    uniqueKeyAccessor
  );

  const columns = useColumns(removeSelection);
  const deleteReviewedAssets = useDeleteReviewedAssets(removeSelection);
  const selectedAssetIds = selectedRows.map((row) => row.id);

  return (
    <>
      <ComponentsTable
        selectable
        customNoData={<NoData />}
        variant="intentsifyPlain"
        searchValue={props.searchValue}
        defaultSort={[{ id: "reviewedAt", desc: true }]}
        columns={columns}
        data={draftAssets.data?.reviewedAssets ?? []}
        paginationType={PaginationType.TOKEN}
        nextPageToken={draftAssets.data?.nextPageToken}
        isFetching={draftAssets.isFetching}
        onFetchDataChange={setParams}
        uniqueKeyAccessor={(item) => item?.id}
        resetPaginationDependencies={[props.searchValue]}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
      />

      <Flex>
        {selectedRows.length > 0 && (
          <Flex gap="4">
            <BatchUpdateModal reviewedAssets={selectedRows} />
            <BatchDeleteButton
              assetIds={selectedAssetIds}
              isLoading={deleteReviewedAssets.isLoading}
              onDelete={() => deleteReviewedAssets.mutate(selectedAssetIds)}
            />
          </Flex>
        )}
      </Flex>
    </>
  );
};
