import { localPoint } from "@visx/event";
import { ScaleBand, ScaleLinear } from "d3-scale";
import { MouseEvent } from "react";
import {
  TimeseriesDataItem,
  TimeseriesLegendItemFormatted,
  TimeseriesTooltipItem,
} from "../../Charts.types";

const getNearestPoint = (
  x: number,
  timeScale: ScaleBand<string>,
  timeValues: string[],
  valuesScale: ScaleLinear<number, number, never>,
  items: TimeseriesDataItem[],
  offsetLeft: number
) => {
  const cursorX = x - offsetLeft;
  const steps = timeValues.map(
    (i) => (timeScale(i) as number) + timeScale.bandwidth() / 2
  );

  const closest = steps.reduce((prev, curr) => {
    return Math.abs(curr - cursorX) < Math.abs(prev - cursorX) ? curr : prev;
  });

  const indexX = steps.indexOf(closest);

  if (!items[indexX]) {
    return null;
  }

  const snappedToDate = items[indexX].isoDate;
  const snappedToY = items.find((s) => s.isoDate === snappedToDate)?.value ?? 0;

  const nearestPoint = {
    x: (timeScale(snappedToDate) as number) + timeScale.bandwidth() / 2,
    y: Math.round(valuesScale(snappedToY)),
  };

  return { nearestPoint, item: items[indexX] };
};

export const handleMouseOver = ({
  event,
  timeScale,
  timeValues,
  valuesScale,
  offsetLeft,
  offsetTop,
  data,
  showTooltip,
}: {
  event: MouseEvent<SVGPathElement>;
  timeScale: ScaleBand<string> | undefined;
  valuesScale: ScaleLinear<number, number, never> | undefined;
  timeValues: string[];
  offsetLeft: number;
  offsetTop: number;
  data: {
    data: TimeseriesDataItem[];
    legend: TimeseriesLegendItemFormatted;
  };
  showTooltip: (args: {
    tooltipLeft: number;
    tooltipTop: number;
    tooltipData: {
      isoDate: string;
      data: TimeseriesTooltipItem[];
    };
  }) => void;
}) => {
  if (event.target) {
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const coords = localPoint(event.target.ownerSVGElement, event) as {
      x: number;
    };

    if (coords && timeScale && valuesScale) {
      const result = getNearestPoint(
        coords?.x,
        timeScale,
        timeValues,
        valuesScale,
        data.data,
        offsetLeft
      );

      if (!result) {
        return;
      }

      const { nearestPoint, item } = result;

      if (nearestPoint) {
        showTooltip({
          tooltipLeft: nearestPoint.x + offsetLeft,
          tooltipTop: nearestPoint.y + offsetTop,
          tooltipData: {
            isoDate: item.isoDate,
            data: [
              {
                label: data.legend.label,
                value: item.value,
                color: data.legend.color,
              },
            ],
          },
        });
      }
    }
  }
};

export const handleNearestPoint = ({
  event,
  timeScale,
  timeValues,
  valuesScale,
  offsetLeft,
  items,
  setHoveredNearestPoint,
}: {
  event: MouseEvent<SVGPathElement>;
  timeScale: ScaleBand<string> | undefined;
  valuesScale: ScaleLinear<number, number, never> | undefined;
  timeValues: string[];
  offsetLeft: number;
  items: TimeseriesDataItem[];
  setHoveredNearestPoint: (coords: { x: number; y: number }) => void;
}) => {
  if (event.target) {
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const coords = localPoint(event.target.ownerSVGElement, event) as {
      x: number;
      y: number;
    };

    if (coords && timeScale && valuesScale) {
      const result = getNearestPoint(
        coords?.x,
        timeScale,
        timeValues,
        valuesScale,
        items,
        offsetLeft
      );

      if (result) {
        setHoveredNearestPoint(result.nearestPoint);
      }
    }
  }
};
