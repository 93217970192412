import { useToast } from "@chakra-ui/react";
import {
  ListDraftAssetsRO,
  ListReviewedAssetsRO,
  ReviewDraftAssetClientDTO,
} from "@intentsify/dto";
import { ReviewedAsset } from "@intentsify/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Endpoints, apiService } from "api";
import { getContentLibraryTusUploadResponseBody } from "components/ContentLibrary/ContentLibraryFile";
import { contentLibraryUpload } from "upload/contentLibraryUpload";

export const useReviewDraftAsset = (
  draftAssetId: number,
  onSuccess?: (data: ReviewedAsset) => void
) => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const files = contentLibraryUpload.useFiles();
  const removeTusFile = contentLibraryUpload.useRemoveFile();

  return useMutation({
    retry: false,
    mutationKey: ["contentLibrary", "draftAssets", "review"],
    mutationFn: async (data: ReviewDraftAssetClientDTO) => {
      const res = await apiService.post<ReviewedAsset>(
        Endpoints.ContentLibrary.DraftAssets.Post.Review(draftAssetId),
        data
      );

      return res.data;
    },
    onSuccess: (data) => {
      queryClient.setQueriesData<ListDraftAssetsRO>(
        { queryKey: ["contentLibrary", "draftAssets"], exact: false },
        (oldData) => ({
          ...oldData,
          draftAssets:
            oldData?.draftAssets?.filter(
              (asset) => asset.id !== draftAssetId
            ) || [],
        })
      );
      queryClient.setQueriesData<ListReviewedAssetsRO>(
        { queryKey: ["contentLibrary", "reviewedAssets"], exact: false },
        (oldData) => ({
          ...oldData,
          reviewedAssets: [data, ...(oldData?.reviewedAssets || [])],
        })
      );

      /**
       * Remove draft asset entry from TUS upload component.
       */
      files.forEach((file) => {
        const draftAsset = getContentLibraryTusUploadResponseBody(file);

        if (draftAsset?.id === draftAssetId) {
          removeTusFile(file.id);
        }
      });

      toast({
        title: `Asset reviewed`,
        status: "success",
      });

      onSuccess?.(data);
    },
  });
};
