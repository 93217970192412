import { chakra } from "@chakra-ui/system";
import { Box, Text, useColorModeValue, useToken } from "@chakra-ui/react";
import { MAP_LEGEND_HEIGHT } from "components";

const MapLegendLayerToggle = ({
  layerName,
  isEnabled,
  toggleIsEnabled,
  color,
}: {
  layerName: string;
  isEnabled: boolean;
  toggleIsEnabled: () => void;
  color: string;
}) => {
  return (
    <Box
      width="120px"
      height={`${MAP_LEGEND_HEIGHT}px`}
      onClick={toggleIsEnabled}
      cursor="pointer"
      padding="5px"
      opacity={isEnabled ? undefined : 0.2}
      _hover={{
        background: useToken(
          "colors",
          useColorModeValue("gray.25", "brand.100")
        ),
      }}
    >
      <Text position="relative" fontSize="xs">
        {layerName}
        <chakra.span
          position="absolute"
          top="calc(50% - 2px)"
          display="inline-block"
          width="30px"
          marginLeft="10px"
          height="4px"
          backgroundColor={color}
        />
      </Text>
    </Box>
  );
};

export { MapLegendLayerToggle };
