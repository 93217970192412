import { Checkbox, HStack, Text } from "@chakra-ui/react";
import { Item } from "types";

type TreeItemProps = {
  checked: number;
  disabled: boolean;
  value: Item["value"];
  isParent: boolean;
  label: string;
  onCheck: () => void;
  onExpand: () => void;
};

const TreeItem = ({
  checked,
  disabled,
  value,
  isParent,
  label,
  onCheck,
}: TreeItemProps) => {
  const inputId = `${String(value).split(" ").join("_")}`;

  return (
    <HStack w="100%">
      <Checkbox
        id={inputId}
        disabled={disabled}
        isChecked={checked === 1}
        isIndeterminate={checked === 2}
        onChange={onCheck}
        pl={0}
      >
        {!isParent ? (
          <Text fontSize={"sm"} ml={1} cursor="pointer">
            {label}
          </Text>
        ) : (
          <span key={value} role="link" tabIndex={0}>
            <Text fontSize="sm" cursor="pointer">
              {label}
            </Text>
          </span>
        )}
      </Checkbox>
    </HStack>
  );
};

export { TreeItem };
