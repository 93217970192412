import { Box, Divider, Flex } from "@chakra-ui/layout";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { TempAccountFileDTO } from "@intentsify/dto";
import { isPopulatedArray } from "@intentsify/utils";
import { Button, Select } from "components";
import { Controller, useForm } from "react-hook-form";
import { useComponentColors } from "theme";
import { z } from "zod";
import { useBusinessDataAppend } from "../hooks/useBusinessDataAppend";
import { firmoOptions } from "./BusinessDataAppend.options";
import { BusinessEventsFilter } from "./BusinessEventsFilter";
import { TechnographicProducts } from "./TechnographicProducts";

const businessDataAppendSchema = z
  .object({
    firmo: z.array(z.string()),
    techno: z
      .array(
        z.object({
          id: z.number(),
          name: z.string(),
          value: z.string(),
        })
      )
      .max(10),
    businessEvents: z.array(
      z.object({
        label: z.string(),
        value: z.string(),
      })
    ),
    businessEventsPerCategory: z
      .number({
        invalid_type_error: "Must be a number between 1 and 15.",
      })
      .min(1)
      .max(15),
  })
  .refine(
    (value) =>
      value.firmo.length > 0 ||
      value.techno.length > 0 ||
      value.businessEvents.length > 0,
    {
      message:
        "At least one firmographic, technographic or business events filter must be selected.",
    }
  );

const BusinessDataAppendForm = ({
  selectedFile,
}: {
  selectedFile: TempAccountFileDTO | undefined;
}) => {
  const { mutateAsync: appendBusinessData, isLoading: isSubmitting } =
    useBusinessDataAppend();

  const componentColors = useComponentColors();

  const { control, handleSubmit, reset, setValue, formState, watch } = useForm({
    mode: "all",
    defaultValues: {
      firmo: [],
      techno: [],
      businessEvents: [],
      businessEventsPerCategory: 5,
    },
    resolver: zodResolver(businessDataAppendSchema),
  });

  const businessEventsValue = watch("businessEvents");

  const onSubmitHandler = handleSubmit(async (data) => {
    if (!selectedFile) {
      return;
    }

    await appendBusinessData({
      file: selectedFile,
      firmos: data.firmo,
      technos: data.techno,
      businessEvents: data.businessEvents,
      businessEventsPerCategory: data.businessEventsPerCategory,
    });
    reset();
  });

  return (
    <Box as="form" onSubmit={(data) => void onSubmitHandler(data)}>
      <Heading as="h3" size="sm" my="4">
        Firmographic Append
      </Heading>
      <Box w={320} mb={2}>
        <Controller
          name="firmo"
          control={control}
          render={({ field }) => (
            <Select
              isDisabled={!selectedFile}
              options={firmoOptions}
              isMulti
              value={firmoOptions.filter((option) =>
                field.value.some((value) => option.value.includes(value))
              )}
              onChange={(options) =>
                field.onChange(options.map((option) => option.value))
              }
            />
          )}
        />
      </Box>

      <Heading as="h3" size="sm" my="4">
        Technographic Append
      </Heading>
      <Flex>
        <Box w={320}>
          <Controller
            name="techno"
            control={control}
            render={({ field, fieldState: { error, invalid } }) => (
              <FormControl isInvalid={invalid}>
                <TechnographicProducts
                  value={field.value}
                  onChange={(value) => {
                    field.onChange(value);
                  }}
                  isDisabled={!selectedFile}
                />
                <FormErrorMessage>{error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </Box>
      </Flex>

      <Heading as="h3" size="sm" my="4">
        Business Events Append
      </Heading>
      <Flex>
        <Box w={320}>
          <Controller
            name="businessEvents"
            control={control}
            render={({ field, fieldState: { error, invalid } }) => (
              <FormControl isInvalid={invalid}>
                <BusinessEventsFilter
                  value={field.value}
                  onChange={(value) => {
                    field.onBlur();
                    field.onChange(value);
                  }}
                  isDisabled={!selectedFile}
                />
                <FormErrorMessage>{error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </Box>
      </Flex>

      <FormLabel
        mt={4}
        fontSize="sm"
        fontWeight="md"
        color={componentColors.form.formLabelColor}
      >
        Number of business events per category
      </FormLabel>

      <Flex>
        <Box w={320}>
          <Controller
            name="businessEventsPerCategory"
            control={control}
            render={({ field, fieldState: { error, invalid } }) => (
              <FormControl isInvalid={invalid}>
                <NumberInput
                  size="sm"
                  rounded="md"
                  max={15}
                  min={1}
                  step={1}
                  value={field.value}
                  onChange={(value) =>
                    setValue("businessEventsPerCategory", Number(value))
                  }
                  isDisabled={!isPopulatedArray(businessEventsValue)}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>

                <FormErrorMessage>{error?.message}</FormErrorMessage>
              </FormControl>
            )}
          />
        </Box>
      </Flex>

      <Divider mt={4} />

      <Flex mt={4} justifyContent="flex-end">
        <Button
          size="md"
          variant="primary-teal"
          isLoading={isSubmitting}
          isDisabled={!formState.isValid || !selectedFile}
          type="submit"
        >
          Process Domains
        </Button>
      </Flex>
    </Box>
  );
};

export { BusinessDataAppendForm };
