import { Flex, Heading, Spinner } from "@chakra-ui/react";
import { Button } from "components";
import {
  useIsLoading,
  useMarkAllAsRead,
  useUnreadCount,
} from "../../Notifications.hooks";
import { List } from "../List/List";

const NotificationsOverlay = () => {
  const unreadCount = useUnreadCount();
  const isLoading = useIsLoading();
  const { mutate: markAllAsRead, isLoading: isLoadingMutation } =
    useMarkAllAsRead();

  return (
    <Flex p={4} pr={3} minH="150px" flexDir="column">
      {isLoading ? (
        <Flex flexGrow={1} alignItems="center" justifyContent="center">
          <Spinner />
        </Flex>
      ) : (
        <>
          <Flex mb={4} mt={1} justifyContent="space-between">
            <Heading
              display="flex"
              fontSize="large"
              fontWeight="semibold"
              alignItems="center"
            >
              Notifications
            </Heading>

            {unreadCount > 0 && (
              <Button
                size="xs"
                isLoading={isLoadingMutation}
                onClick={() => {
                  markAllAsRead();
                }}
                variant="secondary"
              >
                Clear All
              </Button>
            )}
          </Flex>

          <List />
        </>
      )}
    </Flex>
  );
};

export { NotificationsOverlay };
