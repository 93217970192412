import { ListKeywordsRO } from "@intentsify/dto";
import {
  CampaignKeyword,
  CampaignKeywordsAndTopics,
  CampaignTopic,
  SignalSelectionSource,
  SortDirection,
} from "@intentsify/types";
import { useQuery } from "@tanstack/react-query";
import { listKeywords } from "api";
import { useCallback, useMemo, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { haveKeywordsAndTopicsChanged } from "screens/Campaigns/screens/YourCampaigns/YourCampaigns.utils";
import { Item } from "types";
import {
  KeywordItem,
  PaginatedKeywordsParams,
  SelectionMode,
} from "types/Keyword";
import {
  getAllTopics,
  getTopicsAggregated,
} from "./KeywordsAndTopicsStep.requests";
import {
  autodiscoverySettingsAtom,
  keywordsAndTopicsStateSelector,
  keywordsSelector,
  topicsSelector,
} from "./KeywordsAndTopicsStep.state";

export const useKeywordsAndTopicsState = () => {
  const keywordsAndTopicsState = useRecoilValue(keywordsAndTopicsStateSelector);
  const [selectedKeywords, setSelectedKeywords] =
    useRecoilState<KeywordItem[]>(keywordsSelector);
  const [selectedTopics, setSelectedTopics] =
    useRecoilState<CampaignTopic[]>(topicsSelector);

  const [autodiscoverySettings, setAutodiscoverySettings] = useRecoilState(
    autodiscoverySettingsAtom
  );

  const prepareKeywordsForSelection = useCallback(
    (keywords: KeywordItem[], selectionMode: SelectionMode) => {
      const newValuesLabels = keywords.map((n) => n.label.toLowerCase());
      const keywordsToSelect = [];

      if (selectionMode === SelectionMode.UNSELECT) {
        keywordsToSelect.push(
          ...selectedKeywords.filter((k) => !newValuesLabels.includes(k.label))
        );
      }

      if (selectionMode === SelectionMode.SELECT) {
        keywordsToSelect.push(
          ...[
            ...selectedKeywords.filter(
              (k) => !newValuesLabels.includes(k.label)
            ),
            ...keywords,
          ]
        );
      }

      return keywordsToSelect;
    },
    [selectedKeywords]
  );

  const prepareTopicsForSelection = useCallback(
    (topics: CampaignTopic[], selectionMode: SelectionMode) => {
      if (selectionMode === SelectionMode.SET) {
        return topics;
      }

      const newValuesIds = topics.map((n) => n.topicId);

      const topicsToSelect = [];

      if (selectionMode === SelectionMode.UNSELECT) {
        topicsToSelect.push(
          ...selectedTopics.filter((t) => !newValuesIds.includes(t.topicId))
        );
      }

      if (selectionMode === SelectionMode.SELECT) {
        topicsToSelect.push(
          ...[
            ...selectedTopics.filter((t) => !newValuesIds.includes(t.topicId)),
            ...topics,
          ]
        );
      }

      return topicsToSelect;
    },
    [selectedTopics]
  );

  const actions = useMemo(
    () => ({
      setSelectedKeywords: (
        keywords: KeywordItem[],
        selectionMode: SelectionMode
      ) =>
        setSelectedKeywords(
          prepareKeywordsForSelection(keywords, selectionMode)
        ),
      setSelectedTopics: (
        topics: CampaignTopic[],
        selectionMode: SelectionMode
      ) => setSelectedTopics(prepareTopicsForSelection(topics, selectionMode)),
      setAutodiscoverySettings,
    }),
    [
      prepareKeywordsForSelection,
      prepareTopicsForSelection,
      setAutodiscoverySettings,
      setSelectedKeywords,
      setSelectedTopics,
    ]
  );

  return {
    actions,
    state: {
      campaignKeywordsAndTopics: keywordsAndTopicsState,
      selectedKeywords,
      selectedTopics,
      autodiscoverySettings,
    },
  };
};

export const useKeywordsAndTopicsQueries = () => {
  const [keywordsData, setKeywordsData] = useState<Item[]>([]);
  const [nextPageToken, setNextPageToken] = useState<string | undefined>(
    undefined
  );

  const [keywordsParams, setKeywordsParams] = useState<PaginatedKeywordsParams>(
    {
      search: undefined,
      pageToken: "",
      order_by: "keywordId",
      order: SortDirection.DESC,
    }
  );

  const loadMoreKeywords = useCallback(() => {
    if (nextPageToken) {
      setKeywordsParams((params) => ({ ...params, pageToken: nextPageToken }));
    }
  }, [nextPageToken]);

  const onSearch = useCallback((value: string) => {
    setKeywordsData([]);

    setKeywordsParams((params) => ({
      ...params,
      pageToken: "",
      search: value === "" ? undefined : value,
    }));
  }, []);

  const keywords = useQuery<ListKeywordsRO>(
    ["getCampaignKeywords", keywordsParams],
    () => listKeywords(keywordsParams),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      staleTime: 0,
      onSuccess: (data: ListKeywordsRO) => {
        const keywordsItems = data.keywords.map((r) => ({
          label: r.displayName,
          value: r.keywordId,
        }));

        setKeywordsData([...keywordsData, ...keywordsItems]);
        setNextPageToken(data.nextPageToken);
      },
      initialData: {
        keywords: [],
        nextPageToken: undefined,
      },
    }
  );

  const topics = useQuery(["getAllTopics"], getAllTopics, {
    initialData: [],
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    staleTime: 0,
  });

  const topicsAggregated = useQuery(
    ["getCampaignTopicsAggregated"],
    getTopicsAggregated,
    {
      initialData: [],
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      staleTime: 0,
    }
  );

  return {
    keywords: {
      isLoading: keywords.isLoading || keywords.isFetching,
      data: keywordsData,
      onLoadMore: loadMoreKeywords,
      onSearch: onSearch,
    },
    topics: {
      data: topics.data ?? [],
      aggregated: topicsAggregated.data ?? [],
      isLoading:
        topics.isFetching ||
        topics.isFetching ||
        topicsAggregated.data?.length === 0 ||
        topics.data?.length === 0,
    },
  };
};

export const useWatchKeywordsAndTopics = (
  campaignKeywordsAndTopics: CampaignKeywordsAndTopics,
  checkedKeywordItems: KeywordItem[],
  topics: CampaignTopic[]
) => {
  const haveChanged = useMemo(() => {
    const keywords: CampaignKeyword[] = checkedKeywordItems.map((k) => ({
      keyword: k.label,
      keywordId: k.value as number,
      source: k.metaData?.source as SignalSelectionSource,
    }));

    return haveKeywordsAndTopicsChanged(campaignKeywordsAndTopics, {
      keywords,
      topics,
    });
  }, [campaignKeywordsAndTopics, checkedKeywordItems, topics]);

  return haveChanged;
};
