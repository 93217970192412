import { S3FileMetadataDTO } from "@intentsify/dto";
import {
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "@tanstack/react-query";
import { apiService, Endpoints } from "api";
import { HttpStatus } from "types";
import { CustomApiError } from "utils";
import { RemoteFile } from "./TargetPersonaBlocklist.types";

export const buildTargetPersonaBlocklistMetadataKey = () => [
  "Endpoints.TargetPersonas.BlocklistMetadata.Get",
];

export const DEFAULT_BLOCKLIST_FILE_NAME = "buying_group_persona_blocklist.csv";

export const useTargetPersonaBlocklistMetadata =
  (): UseQueryResult<RemoteFile | null> & {
    update: (file: RemoteFile) => void;
  } => {
    const queryClient = useQueryClient();
    const queryProps = useQuery<RemoteFile | null>(
      buildTargetPersonaBlocklistMetadataKey(),
      {
        queryFn: async (): Promise<RemoteFile | null> => {
          try {
            const {
              data: { downloadLink, lastModified, ...data },
            } = await apiService.get<S3FileMetadataDTO>(
              Endpoints.TargetPersonas.BlocklistMetadata.Get
            );

            return {
              ...data,
              lastModified: new Date(lastModified),
              downloadLink:
                String(process.env.VITE_REACT_APP_API_URL) + downloadLink,
            };
          } catch (err) {
            if (
              err instanceof CustomApiError &&
              err.response.statusCode === HttpStatus.NOT_FOUND
            ) {
              return null;
            }
            throw err;
          }
        },
      }
    );

    return {
      ...queryProps,
      update({ downloadLink, ...file }: RemoteFile) {
        queryClient.setQueryData(buildTargetPersonaBlocklistMetadataKey(), {
          ...file,
          downloadLink:
            String(process.env.VITE_REACT_APP_API_URL) + downloadLink,
        });
      },
    };
  };
