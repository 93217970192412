import { IntegrationWithLogo } from "../../IntegrationsModal.utils";
import { ProviderCard } from "./ProviderCard";

interface LoadingProviderProps {
  integration: IntegrationWithLogo;
}

export const LoadingProvider = (props: LoadingProviderProps) => {
  return (
    <ProviderCard.Root flexDirection="column">
      <ProviderCard.Header isLoading {...props} />
    </ProviderCard.Root>
  );
};
