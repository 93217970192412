import { Unsafe } from "@intentsify/types";
import { TooltipProps } from "components";
import { useCallback } from "react";
import { useLocation } from "react-router";
import { useRecoilValue } from "recoil";
import { screenAtom } from "utils";
import { ampli } from "./amplitude";

type TrackTooltipOptions = {
  ariaLabel?: Unsafe<TooltipProps["aria-label"]>;
};

export const useTrackTooltipHovered = () => {
  const screen = useRecoilValue(screenAtom);
  const location = useLocation();

  return useCallback(
    ({ ariaLabel }: TrackTooltipOptions) => {
      if (!ariaLabel || !screen.name) {
        return;
      }

      ampli.tooltipHovered({
        label: ariaLabel,
        screenName: screen.name,
        pagePath: location.pathname,
      });
    },
    [location.pathname, screen.name]
  );
};
