import { PublisherForList } from "@intentsify/types";
import { useQuery } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import { Search, Table, ViewContainer } from "components";
import { PaginationType } from "components/Table/Table/Table.types";
import { useCallback, useMemo, useState } from "react";
import { useTrackSearched } from "tracking/useTrackSearched";
import { FetchDataParamsWithToken } from "types";
import { useScreen } from "utils";
import { publishersTableColumns } from "./Publishers.columns";
import { PublishersScreenDefinition } from "./Publishers.definition";
import {
  defaultListPaginatedPublishersParams,
  listPublishers,
} from "./Publishers.requests";

const Publishers = () => {
  useScreen(PublishersScreenDefinition);

  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);

  const columns = useMemo<ColumnDef<PublisherForList>[]>(
    () => publishersTableColumns,
    []
  );

  const [fetchDataParams, setFetchDataParams] = useState<
    FetchDataParamsWithToken<keyof PublisherForList>
  >(defaultListPaginatedPublishersParams);

  const { data, isFetching } = useQuery(
    ["publishersList", fetchDataParams],
    () => listPublishers(fetchDataParams)
  );

  const handleOnFetchDataChange = useCallback(
    (params: FetchDataParamsWithToken<keyof PublisherForList>) => {
      setFetchDataParams((prevState) => ({
        ...prevState,
        ...params,
      }));
    },
    []
  );

  const trackSearched = useTrackSearched();

  return (
    <>
      <ViewContainer>
        <Search
          currentValue={searchValue}
          onSearch={(v) => {
            setSearchValue(v);
            trackSearched({ term: v, collocation: "Publishers table" });
          }}
        />
        <Table
          variant="intentsifyNew"
          searchValue={searchValue}
          defaultSort={[{ id: "userId", desc: true }]}
          columns={columns}
          isFetching={isFetching}
          data={data?.publishers || []}
          onFetchDataChange={handleOnFetchDataChange}
          resetPaginationDependencies={[searchValue]}
          paginationType={PaginationType.TOKEN}
          nextPageToken={data?.nextPageToken}
        />
      </ViewContainer>
    </>
  );
};

export { Publishers };
