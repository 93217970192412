import { TargetPersonasScreen } from "types";
import { createScreen } from "utils/createScreen";

export const TargetPersonasDefinition = createScreen({
  name: TargetPersonasScreen,
  info: `A target persona represents the characteristics of individuals within your Ideal Customer Profile (ICP).
  Target personas can be created using criteria such as Job Level, Job Function and Job Title. Target personas are 
  saved at the company level.`,
  path: "/target-personas",
  routePath: ["/target-personas"],
  accessPath: "targetPersona",
});
