import { AuthRootDefinition, LoginScreenDefinition } from "auth";
import { Navigate, Route, Routes } from "react-router-dom";
import { DownloadToastManager } from "utils";
import { AuthRouter } from "./AuthRouter";
import { RootRouter } from "./RootRouter";

export const AllRoutes = () => {
  return (
    <Routes>
      <Route path={AuthRootDefinition.routePath[0]} element={<AuthRouter />} />

      <Route
        path="/*"
        element={
          <>
            <DownloadToastManager />
            <RootRouter />
          </>
        }
      />

      <Route
        path="*"
        element={<Navigate to={LoginScreenDefinition.navigate()} />}
      />
    </Routes>
  );
};
