import { CampaignSettings } from "@intentsify/types";
import { useRef } from "react";
import { useParams } from "react-router";
import { useRecoilValue } from "recoil";
import { WithCampaignId } from "types";
import { useCampaign } from "../../CampaignsWizard.hooks";
import {
  CreateCampaignParams,
  MutateCampaignSettingsParams,
} from "../../CampaignsWizard.requests";
import { Controls } from "../Controls";
import { SettingsForm, SubmitTrigger } from "./SettingsForm";
import { SettingsStepState } from "./SettingsStep.state";

type SettingsStepProps = {
  editing: boolean;
  onSubmit: (
    params: CreateCampaignParams | MutateCampaignSettingsParams
  ) => void;
  nextItemLabel?: string;
};

const SettingsStep = ({
  onSubmit,
  editing,
  nextItemLabel,
}: SettingsStepProps) => {
  const formRef = useRef<SubmitTrigger | undefined>();
  const isLoading = useRecoilValue(SettingsStepState.isLoadingAtom);
  const { step } = useParams<"step">();
  const campaignId = Number(useParams<"campaignId">().campaignId);

  const { campaignSettings } = useCampaign({
    campaignId: campaignId ? Number(campaignId) : undefined,
  });

  if (!step) {
    throw Error("404");
  }

  return (
    <>
      <SettingsForm
        state={campaignSettings}
        ref={formRef}
        onSubmit={(params) => {
          if (!campaignId) {
            onSubmit(params);
            return;
          }

          const settings: WithCampaignId<CampaignSettings> = {
            ...params.settings,
            id: Number(campaignId),
          };

          onSubmit({
            ...params,
            settings,
          });
        }}
      />

      <Controls
        onNextLabel={`Next${nextItemLabel ? `: ${nextItemLabel}` : ""}`}
        editing={editing}
        isDisabled={isLoading}
        formRef={formRef}
        step={Number(step)}
        campaignId={campaignId ? Number(campaignId) : undefined}
      />
    </>
  );
};
export { SettingsStep };
