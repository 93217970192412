import { isDefined } from "@intentsify/utils";
import camelCase from "lodash/camelCase";
import * as Papa from "papaparse";

const CELL_VALUES_SEPARATOR = "_|";

const columnsToString = (columns: string[]) =>
  columns
    .map((s) => `"${s}"`)
    .join(", ")
    .toLowerCase();

const validateCSVColumns = (
  columns: string[],
  expectedColumns: string[]
): { isValid: boolean; errorObject?: Record<string, string> } => {
  const columnsMessage = {
    expectedColumns: columnsToString(expectedColumns),
    receivedColumns: columnsToString(columns),
  };

  switch (true) {
    case columns.length !== expectedColumns.length:
      return {
        isValid: false,
        errorObject: {
          error: "Invalid number of columns",
          ...columnsMessage,
        },
      };
    case columns.sort().join().trim().toLowerCase() !==
      expectedColumns.sort().join().trim().toLowerCase():
      return {
        isValid: false,
        errorObject: {
          error: "Invalid column name",
          ...columnsMessage,
        },
      };
    default:
      return { isValid: true };
  }
};

type ParseCSVDefinitionItem = {
  validator: undefined | ((cell: string) => undefined | string);
  isOptional: boolean;
};

export type ParseCSVDefinition<K extends string> = {
  [key in K]: ParseCSVDefinitionItem;
};

type ParseCSVResults<T> = {
  data: T[];
  errors: ParseRowError[];
  duplicates: string[][];
};

type ParsedRow = Record<string, unknown>;

export type ParseRowError = {
  row: number;
  column: string;
  value: string;
  error: string;
  [key: string]: unknown;
};

export const parseCSV = async <K extends string, T = Record<K, string>>(
  file: string,
  definition: ParseCSVDefinition<K>
): Promise<ParseCSVResults<T>> => {
  return new Promise((resolve) => {
    let currentRow = 0;
    let fileColumns: undefined | string[];

    const expectedColumns = Object.keys(definition);

    const requiredColumns = Object.keys(definition)
      .filter((column) =>
        // @ts-ignore
        !definition[column].isOptional ? camelCase(column) : undefined
      )
      .filter(isDefined);

    const validators = Object.values<ParseCSVDefinitionItem>(definition).map(
      (d: ParseCSVDefinitionItem) => d.validator
    );
    const errors: ParseCSVResults<T>["errors"] = [];
    const deduplicatedData = new Set<string>();
    const duplicatedData = new Set<string>();
    const data: Array<T> = [];

    Papa.parse<string>(file, {
      skipEmptyLines: "greedy",
      header: true,
      transformHeader: (header) => camelCase(header),
      step: (row) => {
        currentRow += 1;
        // Validate the column names
        if (!fileColumns) {
          //Check only required columns
          fileColumns = Object.keys(row.data).filter((column) =>
            requiredColumns.includes(camelCase(column))
          );

          const { isValid, errorObject } = validateCSVColumns(
            fileColumns,
            requiredColumns
          );

          if (!isValid) {
            resolve({
              data: [],
              errors: (errorObject ? [errorObject] : []) as ParseRowError[],
              duplicates: [],
            });
          }
        }

        const parsedRow = {} as ParsedRow;

        expectedColumns.forEach(
          (key: string) =>
            //@ts-ignore
            (parsedRow[key] = row.data[key] ?? "")
        );

        const cellUniqueValuesCombination = Object.values(parsedRow)
          .join(CELL_VALUES_SEPARATOR)
          .toLowerCase();

        if (!deduplicatedData.has(cellUniqueValuesCombination)) {
          deduplicatedData.add(cellUniqueValuesCombination);
        } else {
          duplicatedData.add(cellUniqueValuesCombination);
        }
        data.push(parsedRow as T);

        Object.values(row.data).forEach((cell, index) => {
          const cellValidator = validators[index];

          if (!cellValidator) {
            return;
          }

          const column = expectedColumns[index];
          const error = cellValidator(cell);

          if (!error) {
            return;
          }

          return errors.push({
            row: currentRow,
            column,
            value: cell,
            error,
          });
        });
      },

      complete: () => {
        const serializedDuplicates = Array.from(duplicatedData).map((item) =>
          item.split(CELL_VALUES_SEPARATOR)
        );

        resolve({
          data,
          errors,
          duplicates: serializedDuplicates,
        });
      },
    });
  });
};
