import { Option } from "@intentsify/types";

export enum ViewEnum {
  Table = "table",
  TimeSeries = "timeseries",
}

export type TrendingKeywordsOptions = {
  isLoading: boolean;
  keywords: Array<Option<string>>;
};

export type TrendingTopicsOptions = {
  isLoading: boolean;
  topics: Array<Option<string>>;
};
