import { useNavigate } from "react-router";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useDisclosure, Text } from "@chakra-ui/react";
import { UserForm } from "../../components";
import { UsersScreenDefinition } from "../Users";
import { createUser } from "../Users.requets";
import { CreateUserScreenDefinition } from "./CreateUser.definition";
import { Button, Modal, ViewContainer } from "components";
import { useScreen } from "utils";

const CreateUser = () => {
  useScreen(CreateUserScreenDefinition);
  const queryClient = useQueryClient();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation(createUser, {
    onSuccess: () => {
      queryClient.invalidateQueries(["listUsers"]);
      onOpen();
    },
  });

  return (
    <>
      <ViewContainer>
        <UserForm
          isLoading={isLoading}
          onSubmit={mutate}
          submitLabel="Create"
        />
      </ViewContainer>

      <Modal
        title="User has been created"
        body={
          <Text>
            We will send a confirmation email with a link for creating password
            on the address provided in the form.
          </Text>
        }
        primaryButton={
          <Button
            aria-label="Close"
            onClick={() => {
              onClose();
              navigate(UsersScreenDefinition.navigate());
            }}
          >
            Close
          </Button>
        }
        isOpen={isOpen}
        onClose={() => {
          onClose();
          navigate(UsersScreenDefinition.navigate());
        }}
      />
    </>
  );
};

export { CreateUser };
