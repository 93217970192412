import {
  chakra,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  Input,
  SimpleGrid,
  // Switch,
  Textarea,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { DataPackageId } from "@intentsify/types";
import { Button } from "components";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useComponentColors } from "theme";
import { DataPackageSelector } from "../../components/DataPackageSelector";
import { CompaniesScreenDefinition } from "../Companies/Companies.definition";
import {
  CreateCompanyFormSchema,
  createCompanyFormSchema,
} from "./CreateCompanyFormSchema";
import { useCreateCompany } from "./useCreateCompany";

export const CreateCompanyForm = () => {
  const {
    register,
    handleSubmit,
    control,
    // setValue,
    formState: { errors },
  } = useForm<CreateCompanyFormSchema>({
    mode: "onBlur",
    resolver: zodResolver(createCompanyFormSchema),
    defaultValues: {
      dataPackageId: DataPackageId.DataSubscription,
    },
  });

  const navigate = useNavigate();
  const { mutate, isLoading } = useCreateCompany({
    onSuccess: () => {
      navigate(CompaniesScreenDefinition.navigate());
    },
  });

  const componentColors = useComponentColors();

  return (
    <SimpleGrid>
      <chakra.form
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit((data) => mutate(data))}
      >
        <SimpleGrid>
          <FormControl
            isInvalid={!!errors?.name?.message}
            as={GridItem}
            isRequired
          >
            <FormLabel
              color={componentColors.form.formLabelColor}
              fontSize="sm"
              fontWeight="md"
            >
              Company Name
            </FormLabel>

            <Input
              {...register("name")}
              type="name"
              name="name"
              placeholder="Name"
              shadow="sm"
              size="sm"
              w="300px"
              rounded="md"
              autoComplete="off"
            />
            <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
          </FormControl>

          <FormControl
            mt={4}
            isInvalid={!!errors?.businessUnits?.message}
            as={GridItem}
            w={"300px"}
          >
            <FormLabel
              color={componentColors.form.formLabelColor}
              fontSize="sm"
              fontWeight="md"
              mb={0}
            >
              Subsidiaries
            </FormLabel>

            <FormLabel
              color={componentColors.form.formSubLabelColor}
              fontSize="xs"
            >
              Type in freeform space to create new subsidiaries that fall under
              this company. Each line will be an individual subsidiary entry.
            </FormLabel>

            <Textarea
              {...register("businessUnits")}
              placeholder={`Name Of Subsidiary 1\nName Of Subsidiary 2`}
              name="businessUnits"
              shadow="sm"
              size="sm"
              w="full"
              rounded="md"
            />

            <FormErrorMessage>
              {errors?.businessUnits?.message}
            </FormErrorMessage>
          </FormControl>

          {/* <FormControl
            mt={4}
            as={GridItem}
            isInvalid={!!errors?.isOnTrial?.message}
          >
            <FormLabel
              htmlFor="isOnTrial"
              fontSize="sm"
              fontWeight="md"
              color={componentColors.form.formLabelColor}
            >
              Company is on 90-day trial period
            </FormLabel>

            <Switch
              {...register("isOnTrial", {
                onChange: (e) => {
                  if (e.currentTarget.checked) {
                    setValue("dataPackageId", DataPackageId.DataSubscription);
                  }
                },
              })}
              id="isOnTrial"
              name="isOnTrial"
              w="300px"
              size="md"
              rounded="md"
              shadow="sm"
              colorScheme="teal"
            />

            <FormErrorMessage>{errors?.isOnTrial?.message}</FormErrorMessage>
          </FormControl> */}

          <FormControl
            isRequired
            isInvalid={!!errors?.dataPackageId?.message}
            as={GridItem}
            w={"300px"}
            mt={4}
          >
            <FormLabel
              color={componentColors.form.formLabelColor}
              fontSize="sm"
              fontWeight="md"
            >
              Company Package
            </FormLabel>

            <Controller
              name="dataPackageId"
              control={control}
              render={({ field: { onChange, value } }) => (
                <DataPackageSelector
                  value={value}
                  onChange={(option) => onChange(Number(option?.value))}
                />
              )}
            />

            <FormErrorMessage>
              {errors?.dataPackageId?.message}
            </FormErrorMessage>
          </FormControl>
        </SimpleGrid>

        <Divider mt={4} />

        <Flex mt={4} justifyContent="flex-end">
          <Button
            type="submit"
            variant="primary-teal"
            size="md"
            isLoading={isLoading}
            isDisabled={!!errors.name}
          >
            Create
          </Button>
        </Flex>
      </chakra.form>
    </SimpleGrid>
  );
};
