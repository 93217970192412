import { useDisclosure, useToast } from "@chakra-ui/react";
import { CampaignTopic, SignalSelectionSource } from "@intentsify/types";
import { useMutation } from "@tanstack/react-query";
import { ShouldOverwritePrompt } from "components";
import uniqBy from "lodash/uniqBy";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { HttpStatus, isCustomApiError } from "types";
import { SelectionMode } from "types/Keyword";
import {
  CustomApiError,
  formatApiError,
  getToastSettingsFromHttpStatusCode,
  handleApiError,
} from "utils";
import { uploadCampaignTopics, verifyTopics } from "./UploadTopics.requests";
import { UploadTopicsModal } from "./UploadTopicsModal";

type WithCampaignId = {
  topicsFile: File;
  clearTopicsFile: () => void;
  selectedTopics: CampaignTopic[];
  campaignId: number;
  setSelectedTopics: (
    topics: CampaignTopic[],
    selectionMode: SelectionMode
  ) => void;
};

type WithoutCampaignId = {
  topicsFile: File;
  clearTopicsFile: () => void;
  selectedTopics: CampaignTopic[];
  campaignId?: undefined;
  setSelectedTopics: (topics: string[]) => void;
};

/**
 * If campaignId is provided, topics will be verified, saved and `setSelectedTopics` will be called,
 * if campaignId is not provided topics will be verified and `setSelectedTopics` will be called
 */
type UploadTopicsProps = WithCampaignId | WithoutCampaignId;

export const UploadTopics = ({
  campaignId,
  topicsFile,
  clearTopicsFile,
  selectedTopics,
  setSelectedTopics,
}: UploadTopicsProps) => {
  const toast = useToast();
  const navigate = useNavigate();
  const topicsFileNameRef = useRef<string>("");

  const [uploadedTopics, setUploadedTopics] = useState<CampaignTopic[]>([]);

  const {
    isOpen: isTopicModalOpen,
    onOpen: onTopicsModalOpen,
    onClose: onTopicsModalClose,
  } = useDisclosure();

  const {
    isOpen: isShouldOverwriteTopicsModalOpen,
    onOpen: onShouldOverwriteTopicsModalOpen,
    onClose: onShouldOverwriteTopicsModalClose,
  } = useDisclosure();

  const { mutate: uploadTopics, isLoading: isLoadingTopicsUpload } =
    useMutation(uploadCampaignTopics, {
      onMutate: () => {
        setUploadedTopics([]);
        clearTopicsFile();
      },
      onSuccess: (uploaded, { toMergeWith }) => {
        const uploadedTopics = uploaded.map((t) => {
          return {
            ...t,
            source: SignalSelectionSource.Csv,
          };
        });

        setUploadedTopics(uploadedTopics);

        const unique = uniqBy(
          [...uploadedTopics, ...(toMergeWith ?? [])],
          (t) => t.topicId
        );

        if (campaignId) {
          setSelectedTopics(unique, SelectionMode.SELECT);
        }
      },
      onError: (error) => {
        const shouldDisplayToast =
          isCustomApiError(error) &&
          error.response.internalCode === "400InvalidTopics";

        if (shouldDisplayToast) {
          toast({
            title: formatApiError(
              // If we pass the original error it will be interpreted using internalCode
              // and the error messages won't be shown
              new CustomApiError("Topics validation error", {
                statusCode: HttpStatus.BAD_REQUEST,
                message: error.response.message,
              }),
              `There was an error while uploading the file. Please verify its correctness.`
            ),
            ...getToastSettingsFromHttpStatusCode(error.response.statusCode),
            duration: null,
          });
        }

        handleApiError(error, navigate, toast);
        onTopicsModalClose();
      },
    });

  const { mutate: verifyTopicsMutation, isLoading: isLoadingVerifyTopics } =
    useMutation(verifyTopics, {
      onSuccess: (uploadedTopics, { toMergeWith, file }) => {
        if (campaignId) {
          uploadTopics({ id: campaignId, file, toMergeWith });
        }

        if (typeof campaignId === "undefined") {
          setSelectedTopics(uploadedTopics);
          onTopicsModalClose();
        }
      },
      onError: (error) => {
        const shouldDisplayToast =
          isCustomApiError(error) &&
          error.response.internalCode === "400InvalidTopics";

        if (shouldDisplayToast) {
          toast({
            title: formatApiError(
              // If we pass the original error it will be interpreted using internalCode
              // and the error messages won't be shown
              new CustomApiError("Topics validation error", {
                statusCode: HttpStatus.BAD_REQUEST,
                message: error.response.message,
              }),
              `There was an error while uploading the file. Please verify its correctness.`
            ),
            ...getToastSettingsFromHttpStatusCode(error.response.statusCode),
            duration: null,
          });
        }

        handleApiError(error, navigate, toast);
        onTopicsModalClose();
      },
    });

  const processTopicsFile = useCallback(
    (file: File, toMergeWith?: CampaignTopic[]) => {
      onTopicsModalOpen();
      topicsFileNameRef.current = file.name;
      verifyTopicsMutation({ file, toMergeWith });
    },
    [onTopicsModalOpen, verifyTopicsMutation]
  );

  useEffect(() => {
    if (topicsFile) {
      if (selectedTopics.length > 0) {
        onShouldOverwriteTopicsModalOpen();
        return;
      }

      processTopicsFile(topicsFile);
    }
  }, [
    topicsFile,
    onShouldOverwriteTopicsModalOpen,
    processTopicsFile,
    selectedTopics.length,
    campaignId,
  ]);

  return (
    <>
      <ShouldOverwritePrompt
        subjectPlural="topics"
        isOpen={isShouldOverwriteTopicsModalOpen}
        onReplace={() => {
          if (topicsFile) {
            if (campaignId) {
              setSelectedTopics(
                selectedTopics.filter(
                  (t) => t.source === SignalSelectionSource.Csv
                ),
                SelectionMode.UNSELECT
              );
            }

            onShouldOverwriteTopicsModalClose();
            processTopicsFile(topicsFile);
          }
        }}
        onMerge={() => {
          if (topicsFile) {
            onShouldOverwriteTopicsModalClose();
            processTopicsFile(topicsFile, selectedTopics);
          }
        }}
        onClose={() => {
          onShouldOverwriteTopicsModalClose();
          clearTopicsFile();
        }}
      />

      <UploadTopicsModal
        isOpen={isTopicModalOpen}
        onClose={() => {
          onTopicsModalClose();
          clearTopicsFile();
        }}
        topics={uploadedTopics}
        isLoading={isLoadingTopicsUpload || isLoadingVerifyTopics}
      />
    </>
  );
};
