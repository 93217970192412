import { TusStorage, createTusUpload } from "@intentsify/tus/dist/react";
import { Endpoints } from "api";

export const contentLibraryUpload = createTusUpload({
  uploadUrl: new URL(
    `${process.env.VITE_REACT_APP_API_URL ?? `http://localhost:3001`}${
      Endpoints.ContentLibrary.All.Upload
    }`
  ),
  storage: new TusStorage("contentLibrary"),
});
