import { ButtonGroup } from "@chakra-ui/react";
import { Button } from "components";

type EditControlsProps = {
  onSave: () => void;
  onSaveAndExit: () => void;
  isDisabled: boolean;
};

const EditControls = ({
  onSave,
  onSaveAndExit,
  isDisabled = false,
}: EditControlsProps) => {
  return (
    <ButtonGroup>
      <Button size="md" isDisabled={isDisabled} onClick={onSaveAndExit}>
        Save and Exit
      </Button>

      <Button
        size="md"
        isDisabled={isDisabled}
        variant="primary-teal"
        onClick={onSave}
      >
        Save Changes
      </Button>
    </ButtonGroup>
  );
};

export { EditControls };
