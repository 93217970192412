import {
  Button,
  Center,
  Flex,
  Icon,
  MenuItem,
  Switch,
  Text,
} from "@chakra-ui/react";
import { useColorMode } from "@chakra-ui/system";
import { HasAccess } from "@intentsify/authorization/dist/react";
import { useDisableImpersonationModeMutation } from "components/Impersonation/mutations/useDisableImpersonationModeMutation";
import { DelayedTooltip } from "components/Tooltip/DelayedTooltip";
import { MdOutlineDarkMode, MdOutlineLightMode } from "react-icons/md";
import { RiSpyLine } from "react-icons/ri";
import { useAppStore } from "store/useAppStore";

export const Actions = () => {
  return (
    <Flex gap="2">
      <ToggleColorMode />
      <Impersonation />
    </Flex>
  );
};

const ToggleColorMode = () => {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <DelayedTooltip
      aria-label="Toggle color mode"
      label="Adjust the appearance of Intentsify to reduce glare and give your eyes a break."
    >
      <MenuItem
        as={Button}
        flex="1"
        size="sm"
        color="whiteAlpha.900"
        onClick={toggleColorMode}
        bg="whiteAlpha.200"
        _light={{
          bg: "blackAlpha.100",
          color: "blackAlpha.900",
          _hover: { bg: "blackAlpha.300" },
        }}
        leftIcon={
          <Icon
            as={colorMode === "light" ? MdOutlineLightMode : MdOutlineDarkMode}
            fontSize="sm"
            mr="-1"
          />
        }
      >
        <Center w="full" justifyContent="space-between" gap="2">
          <Text fontSize="sm">
            {colorMode === "light" ? "Light" : "Dark"} mode
          </Text>

          <Switch
            size="sm"
            pointerEvents="none"
            colorScheme="blackAlpha"
            sx={{
              "span.chakra-switch__track": {
                backgroundColor: "whiteAlpha.300",
              },
              "span.chakra-switch__track:not([data-checked])": {
                backgroundColor: "blackAlpha.300",
              },
              ".chakra-switch__thumb": {
                backgroundColor: "whiteAlpha.900",
                _light: { backgroundColor: "whiteAlpha.800" },
              },
            }}
            isChecked={colorMode === "dark"}
          />
        </Center>
      </MenuItem>
    </DelayedTooltip>
  );
};

const Impersonation = () => {
  const impersonation = useAppStore.use.impersonation();

  if (impersonation) {
    return <DisableImpersonation />;
  }

  return (
    <HasAccess to="miscellaneous.impersonation">
      <EnableImpersonation />
    </HasAccess>
  );
};

const EnableImpersonation = () => {
  const setIsImpersonationModalOpen =
    useAppStore.use.setIsImpersonationModalOpen();

  return (
    <DelayedTooltip
      aria-label="Enable impersonation"
      label="Perform tasks and access resources on behalf of an user"
    >
      <MenuItem
        as={Button}
        flex="1"
        size="sm"
        color="whiteAlpha.900"
        bg="whiteAlpha.200"
        _light={{
          bg: "blackAlpha.100",
          color: "blackAlpha.900",
          _hover: { bg: "blackAlpha.300" },
        }}
        leftIcon={<Icon as={RiSpyLine} fontSize="sm" mr="-1" />}
        onClick={() => setIsImpersonationModalOpen(true)}
      >
        <Text fontSize="sm">Impersonate</Text>
      </MenuItem>
    </DelayedTooltip>
  );
};

const DisableImpersonation = () => {
  const disableImpersonation = useDisableImpersonationModeMutation();

  return (
    <DelayedTooltip
      aria-label="Disable impersonation"
      label="Exit impersonation and go back to your account"
    >
      <MenuItem
        as={Button}
        flex="1"
        size="sm"
        colorScheme="yellow"
        color="blackAlpha.900"
        bg="yellow.300"
        _light={{
          bg: "yellow.300",
          color: "blackAlpha.900",
          _hover: { bg: "yaaaellow.100" },
        }}
        leftIcon={<Icon as={RiSpyLine} fontSize="sm" mr="-1" />}
        onClick={() => disableImpersonation.mutate()}
      >
        <Text fontSize="sm">Exit impersonation</Text>
      </MenuItem>
    </DelayedTooltip>
  );
};
