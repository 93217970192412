import { GetCampaignRO } from "@intentsify/dto";
import { CampaignDefinition } from "@intentsify/types";
import { Endpoints, apiService } from "api";

export const getCampaignById = async (
  id: number
): Promise<CampaignDefinition> => {
  const { data } = await apiService.get<GetCampaignRO>(
    Endpoints.Campaigns.Get.ById(id)
  );
  return data.campaign;
};

export interface FavoriteStateChange {
  campaignId: number;
  isFavorite: boolean;
}

export const updateCampaignFavoriteStatus = async (
  changeDetails: FavoriteStateChange
): Promise<FavoriteStateChange> => {
  const { campaignId, isFavorite } = changeDetails;

  await apiService.put<boolean>(
    Endpoints.Campaigns.Put.UpdateCampaignFavoriteStatus(campaignId),
    {
      isFavorite,
    }
  );
  return changeDetails;
};
