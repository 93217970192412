import { CoverageByRevenueSizeRO, WeeklySnapshotDTO } from "@intentsify/dto";
import { apiService, Endpoints } from "api";

export const getCoverageByRevenueSize = async (
  id: number,
  filters: WeeklySnapshotDTO
): Promise<any[]> => {
  const { data } = await apiService.get<CoverageByRevenueSizeRO[]>(
    Endpoints.Campaigns.Get.WeeklySnapshot.CoverageByRevenueSize(id),
    {
      params: {
        yearTo: filters.yearTo,
        weekTo: filters.weekTo,
      },
    }
  );

  return data;
};
