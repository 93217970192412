import {
  Box,
  chakra,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tr,
} from "@chakra-ui/react";
import { formatDate, toNumberDisplayValue } from "@intentsify/utils";
import { DateTime } from "luxon";
import { useGetColour } from "../Timeseries.hooks";
import { Colour } from "theme";

export type TimeseriesTooltipProps = {
  isoDate: string;
  data: { label: string; value: number; color: Colour }[];
  textColor: string;
};

const TimeseriesTooltip = ({
  isoDate,
  data,
  textColor,
}: TimeseriesTooltipProps) => {
  const getColour = useGetColour();

  return (
    <Flex color={textColor} flexDir="column">
      <Text size="sm">
        <b>
          {formatDate({
            date: DateTime.fromISO(isoDate).toJSDate(),
          })}
        </b>
      </Text>

      <TableContainer mt={2}>
        <Table size="xs">
          <Tbody>
            {data.map((i, index) => {
              return (
                <Tr key={`${i.label}-${index}`}>
                  <Td>
                    <Box w="10px" h="10px" mr={1} bg={getColour(i.color)[0]} />
                  </Td>
                  <Td>
                    <chakra.span pr={4}>{i.label}</chakra.span>
                  </Td>
                  <Td>{toNumberDisplayValue(i.value)}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Flex>
  );
};

export { TimeseriesTooltip };
