import { Link, Text } from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import { ResetPasswordScreenDefinition } from "auth/screens/ResetPassword/ResetPassword.definition";

const ResetPasswordLink = () => {
  return (
    <Text display="block" color={"gray.100"} _hover={{ color: "green.200" }}>
      <Link
        as={ReactRouterLink}
        style={{ textDecoration: "none" }}
        to={ResetPasswordScreenDefinition.navigate()}
      >
        Reset Password
      </Link>
    </Text>
  );
};

export { ResetPasswordLink };
