import { VStack } from "@chakra-ui/layout";
import { Dynamic1Row, SaasRoleName } from "@intentsify/types";
import { ColumnDef } from "@tanstack/react-table";
import { Loader, NoData, Table } from "components";
import { useMemo } from "react";
import { useSignal } from "screens/Campaigns/screens/CampaignDetails/CampaignDetailsTable/CampaignDetailsTable.hooks";
import { useUser } from "store/store.hooks";
import { TopUrls } from "./TopUrls";

type Dynamic1Props = {
  columns: Array<ColumnDef<Dynamic1Row>>;
  defaultSortByColumn: keyof Dynamic1Row;
  campaignId: number;
  signalId: number;
  domainId: number;
  weekNumber: number;
  yearNumber: number;
};

const Dynamic1 = ({
  defaultSortByColumn,
  columns,
  campaignId,
  domainId,
  signalId,
  weekNumber,
  yearNumber,
}: Dynamic1Props) => {
  const user = useUser();
  const isAdmin = user?.saasRole === SaasRoleName.Admin;

  const tableColumns = useMemo<ColumnDef<Dynamic1Row>[]>(
    () => columns,
    [columns]
  );

  const signalData = useSignal<Dynamic1Row[]>({
    campaignId,
    domainId,
    signalId,
    weekNumber,
    yearNumber,
  });

  if (signalData.isLoading || signalData.isRefetching) {
    return <Loader minHeight={1} height="200" />;
  }

  if (!signalData.data?.length) {
    return <NoData />;
  }

  return (
    signalData.data && (
      <VStack align={"stretch"}>
        <Table
          columns={tableColumns}
          data={signalData.data}
          isScrollable={false}
          isControlledByAPI={false}
          stretch
          overflow={"hidden"}
          defaultSort={[{ id: defaultSortByColumn, desc: true }]}
        />
        <TopUrls
          campaignId={campaignId}
          domainId={domainId}
          topUrls={signalData?.data[0].topUrls}
          hasAdminAccess={isAdmin}
        />
      </VStack>
    )
  );
};

export { Dynamic1 };
