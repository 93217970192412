import { InfoIcon } from "@chakra-ui/icons";
import { Flex, Text, useToast } from "@chakra-ui/react";
import { Tooltip, Upload } from "components";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { UploadKeywords } from "shared/components";
import { useComponentColors } from "theme";
import { KeywordItem } from "types/Keyword";

type KeywordsUploadProps = {
  onChange: (data: KeywordItem[]) => void;
};

const KeywordsUpload = ({ onChange }: KeywordsUploadProps) => {
  const componentColors = useComponentColors();
  const [keywordsFile, setKeywordsFile] = useState<File | undefined>();
  const toast = useToast();

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    onDropAccepted: (files: File[]) => {
      const [firstFile] = files;

      const reader = new FileReader();

      reader.onload = (event) => {
        if (event?.target?.result) {
          setKeywordsFile(firstFile);
        }
      };

      reader.readAsText(firstFile);
    },
    onDropRejected: () => {
      toast({
        title: "Invalid file",
        description: "Please upload a valid CSV file.",
        status: "error",
      });
    },
  });

  return (
    <Flex flexDir="column">
      <Flex alignItems="center" mb={4}>
        <Text color={componentColors.form.formLabelColor} fontWeight="semibold">
          Upload keywords
        </Text>

        <Tooltip
          aria-label={"Upload keywords"}
          label={`Upload keywords as a CSV file with a single column: "Keyword". Each row should contain a single keyword (e.g., "big data").`}
        >
          <InfoIcon
            color={componentColors.form.formLabelColor}
            w={4}
            h={4}
            ml={2}
          />
        </Tooltip>
      </Flex>

      <Upload
        getInputProps={getInputProps}
        getRootProps={getRootProps}
        subHeader={"CSV up to 100MB"}
      />

      {keywordsFile && (
        <UploadKeywords
          keywordsFile={keywordsFile}
          clearKeywordsFile={() => setKeywordsFile(undefined)}
          selectedKeywords={[]}
          setSelectedKeywords={onChange}
        />
      )}
    </Flex>
  );
};

export { KeywordsUpload };
