import { Text, Button, VStack, useToast } from "@chakra-ui/react";
import { useUserPermissionModules } from "queries/permissions/useUserPermissionModules";

const Hero = () => {
  return (
    <VStack alignItems="flex-start" spacing={1.5}>
      <Text fontWeight="medium" fontSize="xl">
        Features
      </Text>

      <Text color="gray.500" fontSize="sm">
        Based on new features or fixes to the Intentsify platform, you may click
        on this button to see these features instantly, with no need to log out
        of the platform for a seamless experience.
      </Text>
    </VStack>
  );
};

const SyncFeatures = () => {
  const toast = useToast();
  const { refetch: refetchPermissionModules, isRefetching } =
    useUserPermissionModules();

  return (
    <Button
      size="sm"
      isLoading={isRefetching}
      loadingText="Syncing features"
      onClick={() => {
        refetchPermissionModules({ type: "all" });

        toast.closeAll();

        toast({
          position: "top",
          status: "loading",
          title: "Syncing features...",
        });
      }}
    >
      Sync features
    </Button>
  );
};

export const Features = () => {
  return (
    <VStack spacing={4} alignItems="flex-start">
      <Hero />
      <SyncFeatures />
    </VStack>
  );
};
