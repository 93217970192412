import { Box, Flex } from "@chakra-ui/react";
import { AccountFileDTO } from "@intentsify/dto";
import { FirmographicsFilters } from "@intentsify/types";
import { useQuery } from "@tanstack/react-query";
import { Endpoints } from "api";
import isEqual from "lodash/isEqual";
import { useEffect, useMemo } from "react";
import { UploadedFileEntry } from "shared/components/UploadedFiles/UploadedFileEntry";
import { UploadedFiles } from "shared/components/UploadedFiles/UploadedFiles";
import { useDownloadFile } from "utils";
import { defaultFirmoFilters } from "./Firmographics.const";
import { getFirmographicsCount } from "./Firmographics.requests";
import { filtersOrNull } from "./Firmographics.utils";
import { Filters } from "./components";

export type FirmographicsProps = {
  firmographicsFilters: FirmographicsFilters;
  onFirmographicsFiltersChange: (filters: FirmographicsFilters) => void;
  onCountChange?: (count: number) => void;
  fileName?: string;
  file?: {
    dto: AccountFileDTO;
    onDownloadClick: () => void;
  };
};

const Firmographics = ({
  firmographicsFilters,
  onFirmographicsFiltersChange,
  onCountChange,
  file,
  fileName = `firmographics_domains.csv`,
}: FirmographicsProps) => {
  const hasAnyFilters = Boolean(filtersOrNull(firmographicsFilters));
  const { download } = useDownloadFile();

  const properFilters: FirmographicsFilters | undefined = useMemo(() => {
    const filtersToUse = filtersOrNull(firmographicsFilters);

    if (!filtersToUse) {
      return undefined;
    }

    const { installBaseProducts, ...remainingFirmographicsFilters } =
      filtersToUse;

    return {
      ...defaultFirmoFilters,
      ...remainingFirmographicsFilters,
      installBaseProducts: installBaseProducts.map(({ value, label }) => {
        const productId = String(value).split("_,_").pop() || "";
        return {
          value: productId,
          label: label,
        };
      }),
    };
  }, [firmographicsFilters]);

  const { isFetching, data: count } = useQuery(
    ["firmographicsList", properFilters],
    () => getFirmographicsCount(properFilters),
    {
      enabled: Boolean(properFilters),
    }
  );

  useEffect(() => {
    if (!onCountChange) {
      return;
    }

    if (!hasAnyFilters) {
      onCountChange(0);
      return;
    }

    onCountChange(count ?? 0);
  }, [count, onCountChange, hasAnyFilters]);

  return (
    <>
      <Box w="100%">
        <Filters
          setFirmographicsFilters={(val) => {
            const newFilters = { ...firmographicsFilters, ...val };

            if (isEqual(newFilters, firmographicsFilters)) {
              return;
            }

            onFirmographicsFiltersChange(newFilters || defaultFirmoFilters);
          }}
          filters={firmographicsFilters}
        />

        {hasAnyFilters && (
          <Flex h="100%" flex="1" flexDir="column">
            <Box
              w="100%"
              borderBottomLeftRadius={5}
              borderBottomRightRadius={5}
              p={4}
              border="1px solid #a1aabb33"
            >
              <UploadedFiles>
                <UploadedFileEntry
                  isLoadingCount={isFetching}
                  file={
                    file
                      ? file.dto
                      : {
                          fileName,
                          accountsCount: count ?? 0,
                        }
                  }
                  onDeleteIconClick={() => {
                    onFirmographicsFiltersChange(defaultFirmoFilters);
                  }}
                  onDownloadClick={() => {
                    if (file?.onDownloadClick) {
                      file.onDownloadClick();
                      return;
                    }

                    download({
                      url: Endpoints.Campaigns.Get.Firmographics.Download,
                      fileName,
                      data: firmographicsFilters,
                      method: "POST",
                    });
                  }}
                />
              </UploadedFiles>
            </Box>
          </Flex>
        )}
      </Box>
    </>
  );
};

export { Firmographics };
