import { PastCampaignForList } from "@intentsify/types";
import { ColumnDef } from "@tanstack/react-table";
import { EmptyCell } from "components";

const getPastCampaignsTableColumns = () => {
  const columns: Array<ColumnDef<PastCampaignForList>> = [
    {
      header: "ID",
      accessorKey: "campaignId",
    },
    {
      header: "Name",
      accessorKey: "displayName",
    },
    {
      header: "Billable",
      accessorKey: "leadsNumber",
      cell: (props) => {
        return props.row.original?.leadsNumber ? (
          props.row.original?.leadsNumber
        ) : (
          <EmptyCell />
        );
      },
    },
    {
      header: "Goal",
      accessorKey: "bookedLeadsNumber",
      cell: (props) => {
        return props.row.original?.bookedLeadsNumber ? (
          props.row.original?.bookedLeadsNumber
        ) : (
          <EmptyCell />
        );
      },
    },
    {
      header: "End date",
      accessorKey: "endDate",
      cell: ({ row }) => {
        return row.original?.endDate || <EmptyCell />;
      },
    },
  ];

  return columns;
};

export { getPastCampaignsTableColumns };
