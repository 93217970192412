import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  HStack,
  Text,
  VStack,
  Icon,
} from "@chakra-ui/react";
import { MdOutlineSettingsEthernet } from "react-icons/md";
import { Features } from "./sections/Features";

const Hero = () => {
  return (
    <VStack alignItems="flex-start" spacing={2}>
      <HStack spacing={2}>
        <Icon
          as={MdOutlineSettingsEthernet}
          width="1.6rem"
          height="1.6rem"
          color="gray.400"
        />

        <Text fontWeight="bold" fontSize="2xl">
          Advanced settings
        </Text>
      </HStack>

      <Text color="gray.500" fontSize="sm" textAlign="left">
        Adjust your settings for a smoother platform experience. <br />
        <Text as="span" fontStyle="italic">
          Tweak only if you're confident in your understanding of the features
          and its settings.
        </Text>
      </Text>
    </VStack>
  );
};

export const AdvancedSettings = () => {
  return (
    <Accordion allowToggle allowMultiple>
      <VStack mt={10} spacing={10} alignItems="flex-start">
        <AccordionItem
          w="100%"
          bg="blackAlpha.100"
          border="1px solid"
          borderColor="whiteAlpha.100"
          borderRadius="md"
        >
          <AccordionButton px={6} py={6} justifyContent="space-between">
            <Hero />
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel borderTop="1px solid" borderColor="whiteAlpha.100">
            <VStack spacing={10} pl={10} py={4} alignItems="flex-start">
              <Features />
            </VStack>
          </AccordionPanel>
        </AccordionItem>
      </VStack>
    </Accordion>
  );
};
