import { Box, DarkMode, Grid, Icon, useColorMode } from "@chakra-ui/react";
import { matchPath, resolvePath, useNavigate } from "react-router";
import { MotionBox, MotionText } from "../../../FramerMotionChakra";
import { IconButton } from "../../../IconButton";
import { LinkGroup } from "./SideBar.linkGroups";
import { defaultStringTransition } from "components/FramerMotionChakra/transitions";

const getIsRouteActive = (path: string) => {
  const { pathname } = resolvePath(path);

  const isActive = matchPath(
    { path: location.pathname, end: true, caseSensitive: false },
    pathname
  );

  return Boolean(isActive);
};

interface LinkGroupCollapsedProps {
  linkGroup: LinkGroup;
}

const LinkGroupCollapsed = ({ linkGroup }: LinkGroupCollapsedProps) => {
  const navigate = useNavigate();
  const { colorMode } = useColorMode();
  const isAnyLinkActive = linkGroup.items.some((linkItem) =>
    getIsRouteActive(linkItem.path)
  );

  return (
    <MotionBox
      position="relative"
      transition={defaultStringTransition}
      layoutId={`${linkGroup.name}container`}
      display="flex"
      justifyContent="center"
      alignItems="center"
      py="5"
      px="2"
      borderTopWidth="2px"
      borderTopStyle="solid"
      borderTopColor="brand.400"
    >
      <DarkMode>
        {isAnyLinkActive && (
          <MotionBox
            layoutId="underline"
            position="absolute"
            top="0"
            bottom="0"
            left="0"
            right="0"
            p="1"
            w="100%"
            h="100%"
            sx={{
              "::before": {
                content: '""',
                position: "absolute",
                top: 0,
                left: 0,
                height: "100%",
                width: "2%",
                background: "teal.500",
              },
            }}
          />
        )}

        <MotionText
          layout="position"
          layoutId={`${linkGroup.name}name`}
          transition={defaultStringTransition}
          transformTemplate={({
            x,
            y,
            rotate,
            scale,
          }: Record<string, number>) =>
            `rotate(${rotate}deg) translate(${x}px,${y}px) scale(${scale})`
          }
          fontSize="0.8rem"
          fontWeight="bold"
          textTransform="uppercase"
          transform="rotate(-180deg)"
          color="white"
          mt="1"
          mr="3"
          sx={{
            writingMode: "vertical-lr",
          }}
        >
          {linkGroup.name.toLocaleUpperCase()}
        </MotionText>
      </DarkMode>

      <Grid rowGap="1">
        {linkGroup.items.map((linkItem) => {
          const isActive = getIsRouteActive(linkItem.path);

          return (
            <Box key={`${linkGroup.name}${linkItem.name}${linkItem.path}`}>
              <DarkMode>
                <IconButton
                  isActive={isActive}
                  colorScheme={isActive ? "teal" : undefined}
                  tooltip={linkItem.name}
                  tooltipPlacement="right"
                  tooltipBackground={
                    colorMode === "light" ? "gray.600" : undefined
                  }
                  tooltipTextColor={colorMode === "light" ? "white" : undefined}
                  onClick={() => navigate(linkItem.path)}
                  icon={
                    <MotionBox
                      layout
                      layoutId={`${linkGroup.name}${linkItem.name}${linkItem.path}`}
                      transition={defaultStringTransition}
                      mt="1"
                    >
                      <Icon
                        as={linkItem.icon}
                        w={5}
                        h={5}
                        color={isActive ? "white" : "gray.500"}
                      />
                    </MotionBox>
                  }
                />
              </DarkMode>
            </Box>
          );
        })}
      </Grid>
    </MotionBox>
  );
};

export { LinkGroupCollapsed };
