import {
  Flex,
  HStack,
  Icon,
  Radio,
  Spinner,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { AccountFileDTO, TempAccountFileDTO } from "@intentsify/dto";
import { DateFormat, formatDate } from "@intentsify/utils";
import { EMPTY_CELL } from "components";
import { IconButton } from "components/IconButton";
import { DateTime } from "luxon";
import { IconType } from "react-icons";
import { FaFileCsv } from "react-icons/fa";
import { FiDownload, FiTrash } from "react-icons/fi";

type UploadedFileEntryProps = {
  file: AccountFileDTO | TempAccountFileDTO;
  icon?: IconType;
  iconColor?: string | undefined;
  onDeleteIconClick?: () => void;
  onDownloadClick?: (file: AccountFileDTO | TempAccountFileDTO) => void;
  isLoadingCount?: boolean;
  hideUploadedAt?: boolean;
  hideDownload?: boolean;
  isSelectable?: boolean;
  isSelected?: boolean;
  onSelect?: (file: AccountFileDTO | TempAccountFileDTO | undefined) => void;
};

const UploadedFileEntry = ({
  file,
  icon = FaFileCsv,
  iconColor = undefined,
  onDeleteIconClick,
  onDownloadClick,
  isLoadingCount = false,
  hideUploadedAt = true,
  hideDownload = false,
  isSelectable = false,
  isSelected = false,
  onSelect,
}: UploadedFileEntryProps) => {
  const { fileName, accountsCount, uploadedAt } = file;
  const hoverBg = useColorModeValue("blackAlpha.100", "whiteAlpha.200");
  const selectedBorderColor = useColorModeValue("blue.200", "blue.200");

  return (
    <HStack
      _hover={isSelectable ? { background: hoverBg } : undefined}
      onClick={
        isSelectable
          ? () => onSelect?.(isSelected ? undefined : file)
          : undefined
      }
      cursor={isSelectable ? "pointer" : undefined}
      spacing="1"
      alignItems="center"
      w="100%"
      borderRadius={4}
      py={1}
      border={"2px solid"}
      borderColor={isSelected ? selectedBorderColor : "transparent"}
      background={isSelected ? hoverBg : "transparent"}
    >
      {isSelectable && (
        <Flex w="40px">
          <Radio
            isChecked={isSelected}
            onClick={(e) => {
              e.preventDefault();
              onSelect?.(isSelected ? undefined : file);
            }}
            ml={2}
          />
        </Flex>
      )}

      <Icon as={icon} w={4} h={4} color={iconColor} />

      <Text
        fontWeight="semibold"
        fontSize="sm"
        w="384px"
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        title={fileName}
      >
        {fileName}
      </Text>

      <Text
        fontSize="sm"
        w="125px"
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
      >
        {!isLoadingCount ? (
          <>{accountsCount || EMPTY_CELL} </>
        ) : (
          <Spinner as="span" size="xs" />
        )}
      </Text>

      {!hideUploadedAt && (
        <Text
          fontSize="sm"
          w="175px"
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
        >
          {uploadedAt
            ? formatDate({
                date: DateTime.fromISO(uploadedAt, { zone: "utc" }).toJSDate(),
                format: DateFormat.DateTime,
              })
            : EMPTY_CELL}
        </Text>
      )}

      {!hideDownload && (
        <IconButton
          as="a"
          size="xs"
          icon={
            <Icon
              cursor="pointer"
              as={FiDownload}
              fontSize="md"
              color={iconColor}
            />
          }
          onClick={() => onDownloadClick?.(file)}
          tooltip="Download file"
        />
      )}

      {onDeleteIconClick && (
        <IconButton
          size="xs"
          tooltip="Delete file"
          icon={<Icon as={FiTrash} fontSize="md" />}
          onClick={onDeleteIconClick}
        />
      )}
    </HStack>
  );
};

export { UploadedFileEntry };
