import { getChartColorsByPalette } from "../Charts.utils";

export const getChartColors = (colorMode: "light" | "dark", index: number) => {
  const blues = getChartColorsByPalette(colorMode, "blue");

  const greens = getChartColorsByPalette(colorMode, "green");

  const reds = getChartColorsByPalette(colorMode, "red");

  const yellows = getChartColorsByPalette(colorMode, "yellow");

  const colors = [blues, greens, yellows, reds];

  return colors[index] || greens;
};
