import { useQuery } from "@tanstack/react-query";
import {
  getCampaignDetailsAggregatedSignals,
  getCampaignDetailsAvailableSignals,
  getCampaignDetailsFilters,
  getCampaignDetailsTopLocations,
  getCampaignSignals,
} from "../CampaignDetails.requests";

const useCampaignSignalsAndFilters = (
  campaignId: number,
  weekNumber: number,
  yearNumber: number
) => {
  const signals = useQuery(["signals"], getCampaignSignals);

  const filters = useQuery(
    ["campaignDetailsFilters", campaignId, weekNumber, yearNumber],
    () => {
      return getCampaignDetailsFilters({
        weekNumber: weekNumber,
        yearNumber: yearNumber,
        campaignId: Number(campaignId),
      });
    }
  );

  return {
    signals,
    filters,
    error: signals.isError || filters.isError,
  };
};

const useAvailableSignals = ({
  campaignId,
  domainId,
  weekNumber,
  yearNumber,
}: Parameters<typeof getCampaignDetailsAvailableSignals>[0]) => {
  return useQuery(
    [
      "campaignDetailsAvailableSignals",
      campaignId,
      domainId,
      weekNumber,
      yearNumber,
    ],
    () =>
      getCampaignDetailsAvailableSignals({
        campaignId,
        domainId,
        weekNumber,
        yearNumber,
      })
  );
};

const useSignal = <T>({
  campaignId,
  domainId,
  signalId,
  weekNumber,
  yearNumber,
}: Parameters<typeof getCampaignDetailsAggregatedSignals>[0]) => {
  return useQuery<T>(
    [
      `campaignDetailsAggregatedSignals`,
      campaignId,
      domainId,
      signalId,
      weekNumber,
      yearNumber,
    ],
    () =>
      getCampaignDetailsAggregatedSignals({
        campaignId,
        domainId,
        signalId,
        weekNumber,
        yearNumber,
      })
  );
};

const useTopLocations = ({
  campaignId,
  domainId,
  weekNumber,
  yearNumber,
}: Parameters<typeof getCampaignDetailsTopLocations>[0]) => {
  return useQuery(
    [
      `campaignDetailsTopLocations`,
      campaignId,
      domainId,
      weekNumber,
      yearNumber,
    ],
    () =>
      getCampaignDetailsTopLocations({
        campaignId,
        domainId,
        weekNumber,
        yearNumber,
      })
  );
};

export {
  useAvailableSignals,
  useCampaignSignalsAndFilters,
  useSignal,
  useTopLocations,
};
