import { Alert, AlertIcon, Box, Text, useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { enableImpersonationMode } from "api";
import { ImpersonationUsersTable } from "components/Impersonation/ImpersonationUsersTable";
import { useInvalidateUserAgencyCompany } from "queries/companies/useUserAgencyCompany";
import { useNavigate } from "react-router";
import { YourCampaignsScreenDefinition } from "screens";
import { ImpersonationTransitionDefinition } from "screens/ImpersonationTransition/ImpersonationTransition.definition";

import { useUser } from "store/store.hooks";
import { useAppStore } from "store/useAppStore";
import { handleApiError } from "utils";
import { Modal, StatusModal } from "../Modal";

interface ImpersonationSelectUserModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const useEnableImpersonation = () => {
  const isProcessingImpersonation = useAppStore.use.isProcessingImpersonation();
  const setIsImpersonationModalOpen =
    useAppStore.use.setIsImpersonationModalOpen();
  const setIsProcessingImpersonation =
    useAppStore.use.setIsImpersonationProcessing();
  const setImpersonation = useAppStore.use.setImpersonation();

  const navigate = useNavigate();

  const toast = useToast();
  const client = useQueryClient();
  const invalidateUserAgencyCompany = useInvalidateUserAgencyCompany();

  const enableImpersonationModeMutation = useMutation(enableImpersonationMode, {
    onMutate: () => {
      navigate(ImpersonationTransitionDefinition.navigate());
      setIsImpersonationModalOpen(false);
      setIsProcessingImpersonation(true);
    },
    onSuccess: (user) => {
      const data = { impersonates: user };
      setImpersonation(data);
      client.resetQueries();
      invalidateUserAgencyCompany();
      navigate(YourCampaignsScreenDefinition.navigate());
      setIsProcessingImpersonation(false);
    },
    onError: (err) => {
      setImpersonation(null);
      handleApiError(err, navigate, toast);
      setIsProcessingImpersonation(false);
      invalidateUserAgencyCompany();
      navigate(YourCampaignsScreenDefinition.navigate());
    },
  });

  return {
    enableImpersonationModeMutation,
    isProcessingImpersonation,
  };
};

const ImpersonationSelectUserModal = ({
  isOpen,
  onClose,
}: ImpersonationSelectUserModalProps) => {
  const { enableImpersonationModeMutation, isProcessingImpersonation } =
    useEnableImpersonation();

  const user = useUser();
  if (!user) {
    return;
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        size="6xl"
        scrollBehavior="inside"
        title="Select user to impersonate"
        body={
          <Box>
            <Alert mb={4} status="info" fontSize="sm">
              <AlertIcon />
              <Text fontWeight={"semibold"}>
                Every action in impersonation mode is tracked.
              </Text>
            </Alert>

            <Box mb={4}>
              <ImpersonationUsersTable
                onEnableImpersonation={(userId: number) => {
                  enableImpersonationModeMutation.mutate(userId);
                }}
              />
            </Box>
          </Box>
        }
        onClose={onClose}
      />

      <StatusModal isOpen={isProcessingImpersonation} />
    </>
  );
};

export { ImpersonationSelectUserModal };
