import {
  Popover as ChakraPopover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverContentProps as ChakraPopoverContentProps,
  PopoverFooter,
  PopoverHeader,
  PopoverProps as ChakraPopoverProps,
  PopoverTrigger,
  useColorMode,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { useComponentColors } from "theme";

type PopoverProps = {
  width: ChakraPopoverContentProps["width"];
  height?: ChakraPopoverContentProps["width"];
  popoverTrigger: ReactNode;
  popoverBody: ReactNode;
  popoverFooter?: ReactNode;
  popoverHeader?: ReactNode;
  closeOnBlur?: boolean;
  isLazy?: boolean;
  isOpen?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  showPopoverCloseButton?: boolean;
  placement?: ChakraPopoverProps["placement"];
  isDisabled?: boolean;
};

const Popover = ({
  width,
  height,
  popoverTrigger,
  popoverBody,
  popoverHeader = null,
  showPopoverCloseButton = false,
  popoverFooter = null,
  closeOnBlur = undefined,
  onClose = undefined,
  onOpen = undefined,
  isLazy = true,
  isOpen = undefined,
  placement = "bottom",
  isDisabled = false,
}: PopoverProps) => {
  const { popoverGray } = useComponentColors();

  const { colorMode } = useColorMode();

  const bg = colorMode === "dark" ? popoverGray : "white";

  return (
    <ChakraPopover
      isOpen={isDisabled ? false : isOpen}
      isLazy={isLazy}
      onClose={onClose}
      onOpen={onOpen}
      closeOnBlur={closeOnBlur}
      placement={placement}
    >
      <PopoverTrigger>{popoverTrigger}</PopoverTrigger>
      <PopoverContent bg="none" w={width} h={height} border="0" rounded={"md"}>
        <PopoverBody
          p={0}
          w={width}
          h={height}
          bg={bg}
          rounded={"md"}
          border="1px solid var(--chakra-colors-chakra-border-color)"
        >
          {popoverHeader && (
            <PopoverHeader pt={4} fontWeight="bold" border="0">
              {popoverHeader}
            </PopoverHeader>
          )}
          {showPopoverCloseButton && <PopoverCloseButton onClick={onClose} />}
          {popoverBody}
          {popoverFooter && (
            <PopoverFooter border="0" pb={4}>
              {popoverFooter}
            </PopoverFooter>
          )}
        </PopoverBody>
      </PopoverContent>
    </ChakraPopover>
  );
};

export { Popover };
