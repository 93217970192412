import { apiService, Endpoints } from "api";

export const editBusinessUnit = async ({
  companyId,
  unitId,
  displayName,
}: {
  companyId: number;
  unitId: number;
  displayName: string;
}) =>
  await apiService.put(
    Endpoints.Companies.Put.EditBusinessUnit(companyId, unitId),
    {
      displayName,
    }
  );
