import { FirmographicsFilters, NaicsCodeLevel, Node } from "@intentsify/types";
import {
  flattenNodes,
  getLeafNodes,
  isDefined,
  isNumeric,
  isPopulatedArray,
} from "@intentsify/utils";
import { useQuery } from "@tanstack/react-query";
import { listPaginatedKeywords } from "api";
import {
  Filter,
  FilterAsyncTokenBased,
  FiltersContainer,
  FiltersContainerItem,
  LabeledSwitch,
  TreeFilter,
} from "components";
import { useMemo } from "react";
import { useBusinessEventsTree } from "utils/useBusinessEventsTree";
import { getFirmographicsFilters } from "../Firmographics.requests";

type FiltersProps = {
  setFirmographicsFilters: (val: Partial<FirmographicsFilters>) => void;
  filters: FirmographicsFilters;
};

const Filters = ({ setFirmographicsFilters, filters }: FiltersProps) => {
  const { data, isLoading: isLoadingFilters } = useQuery(
    ["firmographicsFilters"],
    getFirmographicsFilters({
      shouldGroupNaics: !isPopulatedArray(filters.naics2Codes),
    })
  );

  const businessEventsTree = useBusinessEventsTree(
    data?.filters.businessEvents
  );

  const selectedCountries = useMemo(() => {
    if (!data) {
      return [];
    }
    const values = filters.countries.map((i) => i.value);

    return flattenNodes(data.regionsTree).filter(
      (r) => r && values.includes(r.value)
    );
  }, [filters.countries, data]);

  const selectedNaicsCodes = useMemo(() => {
    if (!data) {
      return [];
    }

    const values = [
      ...filters.naics2Codes,
      ...filters.naics3Codes,
      ...filters.naics4Codes,
      ...filters.naics5Codes,
      ...filters.naics6Codes,
    ].map((i) => i.value);

    return flattenNodes(data.naicsCodesTree)
      .filter((r) =>
        r && isNumeric(r.value)
          ? values.includes(r.value)
          : String(r.value)
              .split("_")
              .some((v) => values.includes(Number(v)))
      )
      .flatMap((n) => {
        if (isPopulatedArray(n.children)) {
          return getLeafNodes([n]);
        }
        return n;
      });
  }, [
    filters.naics2Codes,
    filters.naics3Codes,
    filters.naics4Codes,
    filters.naics5Codes,
    filters.naics6Codes,
    data,
  ]);

  const selectedInstallBaseProducts = useMemo(() => {
    if (!data) {
      return [];
    }

    const values = filters.installBaseProducts.map((i) => i.value);

    return flattenNodes(data.installBaseProductsTree)
      .filter((r) => {
        const onlyId = String(r.value).split("_,_").pop();
        return onlyId ? values.includes(onlyId) : false;
      })
      .flatMap((n) => {
        if (isPopulatedArray(n.children)) {
          return getLeafNodes([n]);
        }
        return n;
      });
  }, [data, filters.installBaseProducts]);

  const statesNodes: Node[] = useMemo(
    () =>
      data
        ? [
            {
              label: "United States",
              value: "us",
              children: data.filters.usStates,
            },
          ]
        : [],
    [data]
  );

  const revenueNodes: Node[] = useMemo(
    () =>
      data
        ? [
            {
              label: "Revenue ranges",
              value: "ranges",
              children: data.filters.revenueRanges ?? [],
            },
          ]
        : [],
    [data]
  );

  const employeeNodes: Node[] = useMemo(
    () =>
      data
        ? [
            {
              label: "Employee ranges",
              value: "ranges",
              children: data.filters.employeeRanges ?? [],
            },
          ]
        : [],
    [data]
  );

  const items: FiltersContainerItem[] = [
    {
      component: (
        <TreeFilter
          label="Countries"
          subject="countries"
          nodes={data?.regionsTree ?? []}
          included={selectedCountries}
          onIncludedChange={(selected) => {
            setFirmographicsFilters({
              countries: selected.map((i) => ({
                label: i.label,
                value: i.value,
              })),
            });
          }}
          isLoading={isLoadingFilters}
          isDisabled={isLoadingFilters}
        />
      ),
    },

    {
      component: (
        <TreeFilter
          label="NAICS codes"
          subject="NAICS codes"
          searchPlaceholder="Search by code or name"
          nodes={data?.naicsCodesTree ?? []}
          included={selectedNaicsCodes}
          onIncludedChange={(selected) => {
            setFirmographicsFilters({
              naics2Codes: selected.filter(
                (i) => i.meta?.level === NaicsCodeLevel.NAICS2
              ),
              naics3Codes: selected.filter(
                (i) => i.meta?.level === NaicsCodeLevel.NAICS3
              ),
              naics4Codes: selected.filter(
                (i) => i.meta?.level === NaicsCodeLevel.NAICS4
              ),
              naics5Codes: selected.filter(
                (i) => i.meta?.level === NaicsCodeLevel.NAICS5
              ),
              naics6Codes: selected.filter(
                (i) => i.meta?.level === NaicsCodeLevel.NAICS6
              ),
            });
          }}
          isLoading={isLoadingFilters}
          isDisabled={isLoadingFilters}
        />
      ),
    },
    {
      component: (
        <TreeFilter
          label="US States"
          subject="US States"
          nodes={statesNodes}
          included={filters.usStates}
          onIncludedChange={(selected) => {
            setFirmographicsFilters({
              usStates: selected.map((i) => ({
                label: i.label,
                value: i.value,
              })),
            });
          }}
          isLoading={isLoadingFilters}
          isDisabled={isLoadingFilters}
          expandOnInitialRender
        />
      ),
    },
    {
      component: (
        <TreeFilter
          label="Employee range"
          subject="Employee range"
          nodes={employeeNodes}
          included={filters.employeeRanges}
          onIncludedChange={(selected) => {
            setFirmographicsFilters({
              employeeRanges: selected.map((i) => ({
                label: i.label,
                value: i.value,
              })),
            });
          }}
          isLoading={isLoadingFilters}
          isDisabled={isLoadingFilters}
          expandOnInitialRender
        />
      ),
    },
    {
      component: (
        <TreeFilter
          label="Revenue range"
          subject="Revenue range"
          nodes={revenueNodes}
          included={filters.revenueRanges}
          onIncludedChange={(selected) => {
            setFirmographicsFilters({
              revenueRanges: selected.map((i) => ({
                label: i.label,
                value: i.value,
              })),
            });
          }}
          isLoading={isLoadingFilters}
          isDisabled={isLoadingFilters}
          expandOnInitialRender
        />
      ),
    },
    {
      component: (
        <Filter
          selected={filters.topics}
          options={data?.filters.topics ?? []}
          isLoading={isLoadingFilters}
          label="Trending Topics in the Last 30 Days"
          placeholder="Select topics"
          onFilterValuesChange={(option) => {
            setFirmographicsFilters({ topics: option });
          }}
          isDisabled={isLoadingFilters}
        />
      ),
    },
    {
      component: (
        <FilterAsyncTokenBased
          isMulti
          currentValue={filters.keywords}
          defaultOptions={data?.filters.keywords ?? []}
          dataRequest={listPaginatedKeywords()}
          label="Trending Keywords in the Last 30 Days"
          placeholder="Select keywords"
          onFilterValuesChange={(option) => {
            setFirmographicsFilters({ keywords: option });
          }}
          isLoading={isLoadingFilters}
          isDisabled={isLoadingFilters}
        />
      ),
    },
    {
      component: (
        <TreeFilter
          label="Install Base Product"
          subject="Install Base Product"
          searchPlaceholder="Search by code or name"
          nodes={data?.installBaseProductsTree ?? []}
          included={selectedInstallBaseProducts}
          onIncludedChange={(selected) => {
            setFirmographicsFilters({
              installBaseProducts: selected.map(({ value, label }) => {
                const productId = String(value).split("_,_").pop() || "";
                return {
                  value: productId,
                  label: label,
                };
              }),
            });
          }}
          isLoading={isLoadingFilters}
          isDisabled={isLoadingFilters}
        />
      ),
    },
    {
      component: (
        <TreeFilter
          expandOnInitialRender
          label="Business Events"
          subject="Business Events"
          isDisabled={isLoadingFilters}
          isLoading={isLoadingFilters}
          nodes={businessEventsTree}
          included={filters.businessEvents}
          onIncludedChange={(selected) => {
            setFirmographicsFilters({
              businessEvents: selected.map((i) => ({
                label: i.label,
                value: i.value,
              })),
            });
          }}
        />
      ),
    },
    {
      component: (
        <LabeledSwitch
          label="Expand account reach to all office locations"
          isChecked={filters.includeOffice}
          leftLabel="No"
          rightLabel="Yes"
          onChange={(isChecked) => {
            setFirmographicsFilters({ includeOffice: isChecked });
          }}
        />
      ),
    },
  ].filter(isDefined);

  return <FiltersContainer items={items} />;
};

export { Filters };
