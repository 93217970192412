import { ListBusinessEntitiesRO } from "@intentsify/dto";
import { BusinessEntity, SortDirection } from "@intentsify/types";
import { apiService, Endpoints } from "api";
import { FetchDataParamsWithToken } from "types";

export const defaultPaginatedBusinessEntitiesParams: FetchDataParamsWithToken<
  keyof BusinessEntity
> = {
  pageToken: "",
  order_by: "displayName",
  order: SortDirection.DESC,
};

export const listPaginatedBusinessEntities = async (
  params: FetchDataParamsWithToken<
    keyof BusinessEntity
  > = defaultPaginatedBusinessEntitiesParams
): Promise<ListBusinessEntitiesRO> => {
  const { data } = await apiService.get(
    Endpoints.BusinessEntities.Get.Paginated,
    {
      params,
    }
  );
  return data;
};
