import { isDefined } from "@intentsify/utils";
import { useTooltip } from "@visx/tooltip";
import { TooltipInPortalProps } from "@visx/tooltip/lib/hooks/useTooltipInPortal";
import { ScaleLinear, ScaleBand } from "d3-scale";
import { FC, ReactNode, useState } from "react";
import {
  TimeseriesDataItem,
  TimeseriesLegendItemFormatted,
  TimeseriesTooltipItem,
} from "../../Charts.types";
import { useGetColour } from "../Timeseries.hooks";
import { muteTimeseriesColor } from "../Timeseries.utils";
import { Line } from "./Line";
import { TimeseriesTooltipProps } from "./TimeseriesTooltip";
import { BasicTooltip } from "components/Charts/BasicTooltip";

type LinesProps = {
  data: Array<{
    data: TimeseriesDataItem[];
    legend: TimeseriesLegendItemFormatted;
  }>;
  timeScale: ScaleBand<string> | undefined;
  timeValues: string[];
  valuesScale: ScaleLinear<number, number, never> | undefined;
  fixedTooltip?: boolean;
  offsetLeft: number;
  offsetTop: number;
  tooltipInPortal: FC<TooltipInPortalProps>;
  tooltipRenderer: (props: TimeseriesTooltipProps) => ReactNode;
};

const Lines = ({
  data,
  timeScale,
  timeValues,
  valuesScale,
  offsetLeft,
  offsetTop,
  tooltipInPortal,
  tooltipRenderer,
}: LinesProps) => {
  const getColour = useGetColour();
  const [focusedLine, setFocusedLine] = useState(-1);

  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip<{
    isoDate: string;
    data: TimeseriesTooltipItem[];
  }>();

  return (
    <>
      {data.map((item, i) => {
        const isColorMuted = item.legend.muteColor;

        return (
          <Line
            data={item}
            style={{
              strokeDasharray: 0,
              strokeLinecap: "butt",
              strokeLinejoin: "bevel",
            }}
            key={i}
            strokeColor={
              isColorMuted && focusedLine !== i
                ? muteTimeseriesColor(getColour(item.legend.color)[0])
                : getColour(item.legend.color)[0]
            }
            timeScale={timeScale}
            timeValues={timeValues}
            valuesScale={valuesScale}
            showTooltip={(...params: Parameters<typeof showTooltip>) => {
              setFocusedLine(i);
              showTooltip(...params);
            }}
            hideTooltip={(...params: Parameters<typeof hideTooltip>) => {
              setFocusedLine(-1);
              hideTooltip(...params);
            }}
            offsetLeft={offsetLeft}
            offsetTop={offsetTop}
          />
        );
      })}

      {tooltipOpen &&
        tooltipData &&
        isDefined(tooltipLeft) &&
        isDefined(tooltipTop) && (
          <BasicTooltip
            tooltipRenderer={({ textColor, tooltipData }) => {
              return (
                <>
                  {tooltipRenderer({
                    textColor,
                    isoDate: tooltipData.isoDate,
                    data: tooltipData.data,
                  })}
                </>
              );
            }}
            TooltipInPortal={tooltipInPortal}
            tooltipLeft={tooltipLeft}
            tooltipTop={tooltipTop}
            tooltipData={tooltipData}
          />
        )}
    </>
  );
};

export { Lines };
