import { useToast } from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useParams } from "react-router";
import { BusinessEntityForm, AgencyFormData } from "../../components";
import { EditBusinessEntityScreenDefinition } from "./EditBusinessEntity.definition";
import { editBusinessEntity } from "./EditBusinessEntity.requests";
import { useScreen } from "utils";
import { Loader, ViewContainer } from "components";
import { getBusinessEntityById } from "api";

const EditBusinessEntity = () => {
  useScreen(EditBusinessEntityScreenDefinition);
  const { id } = useParams<"id">();

  if (!id) {
    throw new Error("404");
  }

  const { data: businessEntity } = useQuery(
    [id],
    () => getBusinessEntityById(Number(id)),
    {
      onSuccess: (data) =>
        setFormData({
          displayName: data.displayName,
          companyIds: data.companies.map((i) => i.id),
        }),
    }
  );

  const [formData, setFormData] = useState<AgencyFormData | undefined>();

  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(editBusinessEntity, {
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["listPaginatedBusinessEntities"],
      });
      toast({
        title: "Business Entity changes saved.",
        status: "success",
      });
    },
  });

  const onSubmit = (data: AgencyFormData) =>
    data.displayName &&
    mutate({ ...(data as Required<AgencyFormData>), id: Number(id) });

  return (
    <ViewContainer>
      {businessEntity && formData ? (
        <BusinessEntityForm
          isSubmitting={isLoading}
          data={formData}
          onChange={setFormData}
          onSubmit={onSubmit}
          submitButtonLabel="Save Changes"
        />
      ) : (
        <Loader />
      )}
    </ViewContainer>
  );
};

export { EditBusinessEntity };
