import { ListCampaignsRO, ListPublishersRO } from "@intentsify/dto";
import {
  CampaignForList,
  PublisherForList,
  SortDirection,
} from "@intentsify/types";
import {
  apiService,
  defaultListPaginatedCampaignsParams,
  Endpoints,
} from "api";
import { FetchDataParamsWithToken } from "types";

const defaultListPaginatedPublishersParams: FetchDataParamsWithToken<
  keyof PublisherForList
> = {
  pageToken: "",
  order_by: "userId",
  order: SortDirection.DESC,
};

const listPublishers = async (
  params: FetchDataParamsWithToken<
    keyof PublisherForList
  > = defaultListPaginatedPublishersParams
): Promise<ListPublishersRO> => {
  const { data } = await apiService.get(Endpoints.Publishers.Get.List, {
    params,
  });
  return data;
};

const listPublisherCampaigns = async (
  publisherId: string,
  params: FetchDataParamsWithToken<
    keyof CampaignForList
  > = defaultListPaginatedCampaignsParams
): Promise<ListCampaignsRO> => {
  const { data } = await apiService.get(
    Endpoints.Publishers.Get.ListCampaigns(publisherId),
    {
      params,
    }
  );
  return data;
};

export {
  defaultListPaginatedPublishersParams,
  listPublisherCampaigns,
  listPublishers,
};
