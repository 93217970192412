import { z } from "zod";

export const createCompanyFormSchema = z.object({
  name: z.string().min(3).max(255),
  businessUnits: z
    .string()
    .optional()
    .refine(
      (v) => {
        if (v && (v.length < 3 || v.length > 1024)) {
          return false;
        }

        return true;
      },
      {
        message: "must be between 3 and 1024 characters.",
      }
    ),
  dataPackageId: z.number(),
  isOnTrial: z.boolean().optional(),
});

export type CreateCompanyFormSchema = z.infer<typeof createCompanyFormSchema>;
