import { DateRange } from "@intentsify/types";

export enum ViewEnum {
  Table = "table",
  TimeSeries = "timeseries",
}

export type AccountDetailsState = {
  campaignId: number;
  account: string;
  dateRange: DateRange;
};
