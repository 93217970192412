import { SortDirection } from "@intentsify/types";
import { isPopulatedArray } from "@intentsify/utils";
import { useQuery } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import { Table } from "components";
import omit from "lodash/omit";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSetRecoilState } from "recoil";
import { useComponentColors } from "theme";
import { combineParamsAndFilters } from "utils";
import { CampaignsLeadsFilters } from "../../CampaignsLeads";
import {
  downloadFetchDataParamsAtom,
  leadsHasResultsAtom,
} from "../../CampaignsLeads.state";
import { campaignLeadsTableColumns } from "./CampaignsLeadsTable.columns";
import { listPaginatedLeads } from "./CampaignsLeadsTable.requests";
import { Lead, LeadsFetchDataParams } from "./CampaignsLeadsTable.types";

const defaultListPaginatedCampaignsParams: LeadsFetchDataParams = {
  page: 1,
  per_page: 10,
  order_by: "date",
  order: SortDirection.DESC,
};

const CampaignsLeadsTable = ({
  campaignId,
  filters,
}: {
  campaignId: string | number | undefined;
  filters: CampaignsLeadsFilters;
}) => {
  const {
    form: { stackBg },
  } = useComponentColors();
  const setHasResults = useSetRecoilState(leadsHasResultsAtom);
  const setDownloadFetchDataParams = useSetRecoilState(
    downloadFetchDataParamsAtom
  );
  const [fetchDataParams, setFetchDataParams] = useState<LeadsFetchDataParams>(
    defaultListPaginatedCampaignsParams
  );

  const columns = useMemo<ColumnDef<Lead>[]>(
    () => campaignLeadsTableColumns,
    []
  );

  const { data, isFetching } = useQuery(
    ["activeCampaignsList", fetchDataParams, campaignId, filters],
    () => {
      return listPaginatedLeads(
        Number(campaignId),
        combineParamsAndFilters(fetchDataParams, filters)
      );
    }
  );

  useEffect(() => {
    setDownloadFetchDataParams(
      omit(combineParamsAndFilters(fetchDataParams, filters), [
        "page",
        "per_page",
      ])
    );
  }, [fetchDataParams, filters, setDownloadFetchDataParams]);

  useEffect(() => {
    setHasResults(isPopulatedArray(data?.results));
  }, [data, setHasResults]);

  const handleOnFetchDataChange = useCallback(
    (params: LeadsFetchDataParams) => {
      setFetchDataParams({
        ...params,
      });
    },
    [setFetchDataParams]
  );

  return (
    <Table
      bg={stackBg}
      defaultSort={[
        {
          id: defaultListPaginatedCampaignsParams.order_by,
          desc: true,
        },
      ]}
      columns={columns}
      isFetching={isFetching}
      data={data?.results || []}
      meta={data?.meta}
      onFetchDataChange={handleOnFetchDataChange}
      resetPaginationDependencies={[campaignId, filters]}
    />
  );
};

export { CampaignsLeadsTable };
