import { CampaignForList } from "@intentsify/types";
import { ColumnDef } from "@tanstack/react-table";
import { createElement } from "react";
import { EmptyCell } from "components";

const getAssignCampaignsTableColumns = (
  actionItems: ({ id }: { id: number }) => JSX.Element | null
) => {
  const columns: ColumnDef<CampaignForList>[] = [
    {
      header: "ID",
      accessorKey: "campaignId",
    },
    {
      header: "Name",
      accessorKey: "displayName",
    },
    {
      header: "Actions",
      enableSorting: false,
      cell: ({ row }) => {
        return row.original?.campaignId ? (
          createElement(actionItems, {
            id: row.original.campaignId,
          })
        ) : (
          <EmptyCell />
        );
      },
    },
  ];

  return columns;
};

export { getAssignCampaignsTableColumns };
