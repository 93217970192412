import { Flex, Icon, Text } from "@chakra-ui/react";
import { FaRegCheckCircle } from "react-icons/fa";

export const CampaignEnded = () => (
  <Flex
    alignItems="center"
    width="70px"
    justifyContent="space-between"
    marginLeft={3}
  >
    <Icon as={FaRegCheckCircle} color="green.300" />
    <Text color="green.300" fontWeight="bold">
      Ended
    </Text>
  </Flex>
);
