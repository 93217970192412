import React from "react";
import {
  Box,
  OrderedList,
  ListItem,
  useColorModeValue,
} from "@chakra-ui/react";
import { FiDownload } from "react-icons/fi";
import { PingContentRO, WeeklySnapshotRangeDTO } from "@intentsify/dto";
import { toNumberDisplayValue, decimalToPercentage } from "@intentsify/utils";
import { usePingContent } from "./PingContent.state";
import { Card } from "components/Card";

type PingContentProps = {
  campaignId: number;
  filters: WeeklySnapshotRangeDTO;
};

const decorateContent = (
  contentParts: (string | React.ReactNode)[],
  token: RegExp,
  decorator: (index: number, matchedToken: string) => React.ReactNode
) => {
  const compiledParts = [];
  let index = 0;

  for (const part of contentParts) {
    if (typeof part !== "string") {
      compiledParts.push(part);
      continue;
    }

    let notParsedPart = part;
    let result = token.exec(notParsedPart);

    while (result) {
      compiledParts.push(part.substring(0, result.index));
      compiledParts.push(decorator(index++, result[0]));
      notParsedPart = part.substring(result.index + result[0].length);

      result = token.exec(notParsedPart);
    }

    compiledParts.push(notParsedPart);
  }

  return compiledParts;
};

const compileContent = ({ template, lead, topic, value }: PingContentRO) => {
  let compiledDescription: (string | React.ReactNode)[] = [template];

  if (typeof lead === "string") {
    compiledDescription = [
      ...decorateContent(compiledDescription, /{lead}/, (index) => (
        <strong key={`lead-${index}`}>{lead}</strong>
      )),
    ];
  }
  if (typeof topic === "string") {
    compiledDescription = [
      ...decorateContent(compiledDescription, /{topic}/, (index) => (
        <strong key={`topic-${index}`}>{topic}</strong>
      )),
    ];
  }
  if (typeof value === "number") {
    compiledDescription = [
      ...decorateContent(
        compiledDescription,
        /{value}(\s*%)?/,
        (index, matchedToken) => (
          <strong key={`value-${index}`}>
            {matchedToken.includes("%")
              ? toNumberDisplayValue(decimalToPercentage(value)) + "%"
              : toNumberDisplayValue(value)}
          </strong>
        )
      ),
    ];
  }
  return compiledDescription.map((part, index) =>
    typeof part === "string" ? <span key={`str-${index}`}>{part}</span> : part
  );
};

export const PingContent = ({ campaignId, filters }: PingContentProps) => {
  const { data, isFetching } = usePingContent(campaignId, filters);
  const pingContentColor = useColorModeValue(
    "var(--chakra-colors-gray-500)",
    "var(--chakra-colors-gray-100)"
  );

  return (
    <Card
      title="Correlation Between Topic Research And Content Assets"
      isLoading={isFetching}
      hasData={!isFetching && Boolean(data?.length)}
      noDataMessage="This messaging analysis is for campaigns with allocated content syndication program. If campaign is live, insights may still be processing."
      noDataIcon={FiDownload}
    >
      <Box mx={1}>
        {data?.length ? (
          <OrderedList fontSize="11px" color={pingContentColor}>
            {data.map((item, index) => (
              <ListItem key={index} mt={index > 0 ? 2 : undefined}>
                {compileContent(item)}
              </ListItem>
            ))}
          </OrderedList>
        ) : null}
      </Box>
    </Card>
  );
};
