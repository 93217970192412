import { Box, Flex } from "@chakra-ui/layout";
import { useToast } from "@chakra-ui/react";
import { SimpleCsvFilesTable, Upload } from "components";
import identity from "lodash/identity";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { UploadKeywords, UploadTopics } from "shared/components";
import { parseCSV } from "utils";
import { useKeywordsAndTopicsState } from "../../../KeywordsAndTopics.hooks";

interface UploadListProps {
  id: number;
}

const keywordsTemplateData = [
  Object.fromEntries(["Keyword"].map((header) => [header, undefined])),
];

const topicsTemplateData = [
  Object.fromEntries(["Topic"].map((header) => [header, undefined])),
];

interface FileWithContext {
  file: File;
  context: "keywords" | "topics";
}

export const UploadList = ({ id }: UploadListProps) => {
  const [fileToProcess, setFileToProcess] = useState<
    FileWithContext | undefined
  >();

  const clearFileToProcess = useCallback(() => setFileToProcess(undefined), []);
  const { state, actions } = useKeywordsAndTopicsState();
  const toast = useToast();

  const { getRootProps, getInputProps, inputRef } = useDropzone({
    maxFiles: 1,
    onDropAccepted: (files: File[]) => {
      const [firstFile] = files;

      const reader = new FileReader();

      reader.onload = async (event) => {
        if (event?.target?.result) {
          const { data: keywords } = await parseCSV<"keyword">(
            event.target.result.toString(),
            { keyword: { validator: identity, isOptional: false } }
          );

          if (keywords.length > 0) {
            setFileToProcess({ file: firstFile, context: "keywords" });
            return;
          }

          const { data: topics } = await parseCSV<"topic">(
            event.target.result.toString(),
            { topic: { validator: identity, isOptional: false } }
          );

          if (topics.length > 0) {
            setFileToProcess({ file: firstFile, context: "topics" });
            return;
          } else {
            toast({
              title:
                "Please ensure your CSV file meets the required format. The file must have a single column named 'topic' or 'keyword'.",
              status: "warning",
            });
          }
        }
      };

      reader.readAsText(firstFile);
    },
  });

  return (
    <>
      <Flex gap="10">
        <Box flex="1">
          <Upload
            testId="topics-or-keywords-upload"
            getInputProps={getInputProps}
            getRootProps={getRootProps}
            subHeader={"CSV up to 100MB"}
          />
        </Box>

        <Box flex="1">
          <SimpleCsvFilesTable
            includeSize
            nonIdealState={{
              variant: "noUploadedFiles",
              onCtaClick: () => inputRef.current?.click(),
            }}
            files={[
              {
                fileName: "Keywords Template.csv",
                data: keywordsTemplateData,
                normaliseFileName: false,
              },
              {
                fileName: "Topics Template.csv",
                data: topicsTemplateData,
                normaliseFileName: false,
              },
            ]}
          />
        </Box>
      </Flex>

      {fileToProcess?.context === "keywords" && (
        <UploadKeywords
          campaignId={id}
          keywordsFile={fileToProcess.file}
          clearKeywordsFile={clearFileToProcess}
          selectedKeywords={state.selectedKeywords}
          setSelectedKeywords={actions.setSelectedKeywords}
        />
      )}

      {fileToProcess?.context === "topics" && (
        <UploadTopics
          campaignId={id}
          topicsFile={fileToProcess.file}
          clearTopicsFile={clearFileToProcess}
          selectedTopics={state.selectedTopics}
          setSelectedTopics={actions.setSelectedTopics}
        />
      )}
    </>
  );
};
