import { Box, Stack, StackProps, Tag, TagCloseButton } from "@chakra-ui/react";
import { PropsWithChildren, ReactNode } from "react";

const DynamicInputRoot = ({
  children,
  ...props
}: PropsWithChildren<StackProps>) => {
  return (
    <Stack
      w="100%"
      spacing="2"
      borderColor="whiteAlpha.100"
      borderRadius="lg"
      {...props}
    >
      {children}
    </Stack>
  );
};

const DynamicInputBody = ({ children }: { children: ReactNode }) => {
  return <>{children}</>;
};

const DynamicInputTag = ({
  tags,
  onRemoveClick,
}: {
  tags: string[];
  onRemoveClick?: (tag: string) => void;
}) => {
  return (
    <Box>
      {tags.map((tag) => (
        <Tag key={tag} mr={1} mt={1}>
          {tag}
          {onRemoveClick && (
            <TagCloseButton onClick={() => onRemoveClick?.(tag)} />
          )}
        </Tag>
      ))}
    </Box>
  );
};

export const DynamicInputNext = {
  Root: DynamicInputRoot,
  Body: DynamicInputBody,
  Tag: DynamicInputTag,
};
