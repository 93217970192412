import { PaginatedResponse } from "@intentsify/types";
import { Lead, LeadsFetchDataParams } from "./CampaignsLeadsTable.types";
import { apiService, Endpoints } from "api";
import { BaseFilter } from "types";
import { getPaginatedQuery } from "utils";

export const listPaginatedLeads = async <T,>(
  campaignId: number,
  params: LeadsFetchDataParams
): Promise<PaginatedResponse<Lead>> => {
  const query = getPaginatedQuery<Lead, BaseFilter<T>>(
    Endpoints.Campaigns.Get.Leads.Paginated(campaignId),
    params
  );
  const { data } = await apiService.get(query);
  return data;
};
