import React, { useMemo } from "react";
import groupBy from "lodash/groupBy";
import {
  Divider,
  ListItem,
  OrderedList,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { AccountOpportunityLeadEngagement as AccountOpportunityLeadEngagementType } from "@intentsify/types";
import uniqBy from "lodash/uniqBy";
import { pluralize } from "@intentsify/utils";
import { TextWithTooltip } from "components";

type AccountOpportunityLeadEngagementComponent = {
  leadEngagements: AccountOpportunityLeadEngagementType[];
  dividerColor: React.ComponentProps<typeof Divider>["borderColor"];
};

export const AccountOpportunityLeadEngagement = ({
  leadEngagements,
  dividerColor,
}: AccountOpportunityLeadEngagementComponent) => {
  const {
    mainLeadsCount,
    mainLeadLocation: mainReadLoc,
    restLeadLocations: restLeadLocs,
  } = useMemo(() => {
    const [mainLeadLocation, ...restLeadLocations] = Object.entries(
      groupBy(leadEngagements, "location")
    )
      .map(([leadLocation, leadRecords]) => ({
        leadLocation,
        leadRecords: Object.entries(groupBy(leadRecords, "leadAsset")).map(
          ([leadAsset, leadAssetDetails]) => ({
            leadAsset,
            leadAssetJobTitles: Object.entries(
              groupBy(leadAssetDetails, ({ jobTitle }) => `${jobTitle}`)
            ).map(([_, details]) => ({
              jobTitle: details[0].jobTitle,
            })),
          })
        ),
      }))
      .sort(
        (a, b) =>
          Object.keys(b.leadRecords).length - Object.keys(a.leadRecords).length
      );

    return {
      mainLeadLocation,
      restLeadLocations,
      mainLeadsCount: uniqBy(
        leadEngagements.filter(
          ({ location }) => mainLeadLocation.leadLocation === location
        ),
        ({ jobTitle, date, leadAsset }) => `${jobTitle}:${date}:${leadAsset}`
      ).length,
    };
  }, [leadEngagements]);

  const renderLeadRecords = (
    leadRecords: (typeof mainReadLoc)["leadRecords"]
  ) => (
    <OrderedList>
      {leadRecords.map(({ leadAsset, leadAssetJobTitles }) => (
        <ListItem key={leadAsset}>
          {leadAsset}:
          <UnorderedList>
            {leadAssetJobTitles.map(({ jobTitle }, index) => (
              <ListItem key={index}>{jobTitle}</ListItem>
            ))}
          </UnorderedList>
        </ListItem>
      ))}
    </OrderedList>
  );

  return (
    <>
      {mainReadLoc ? (
        <>
          <Text>
            We have generated {String(mainLeadsCount)}{" "}
            {pluralize(mainLeadsCount, "lead")} from{" "}
            <Text fontWeight="bold">{mainReadLoc.leadLocation}</Text>
          </Text>
          {renderLeadRecords(mainReadLoc.leadRecords)}
        </>
      ) : null}
      {restLeadLocs.length > 0 && (
        <>
          <Divider borderColor={dividerColor} my={2} />
          <Text>
            More leads generated from locations:
            <UnorderedList>
              {restLeadLocs.map((lead) => (
                <ListItem key={lead.leadLocation}>
                  <TextWithTooltip
                    renderTooltip={() => renderLeadRecords(lead.leadRecords)}
                  >
                    {lead.leadLocation}
                  </TextWithTooltip>
                </ListItem>
              ))}
            </UnorderedList>
          </Text>
        </>
      )}
    </>
  );
};
