import { Box } from "@chakra-ui/react";
import { useHasAccessTo } from "@intentsify/authorization/dist/react";
import { ButtonGroup, ViewContainer } from "components";
import { useNavigate, useParams } from "react-router";
import { useScreen } from "utils";
import { ProgrammaticDomainsScreenDefinition } from "./ProgrammaticDomains.definition";
import { ProgrammaticDomainsTable } from "./ProgrammaticDomainsTable";
import { AutomatedProgrammaticSegments } from "./components/AutomatedProgrammaticSegments";

export enum ProgrammaticSegmentsView {
  Automated = "automated",
  Manual = "manual",
}

const ProgrammaticDomains = () => {
  useScreen(ProgrammaticDomainsScreenDefinition);
  const navigate = useNavigate();
  const { view } = useParams<"view">();
  const hasAccessToAutomations = useHasAccessTo(
    "programmaticCampaign.automatedProgrammaticSegments"
  );

  const selectedView =
    (view as ProgrammaticSegmentsView | undefined) ||
    ProgrammaticSegmentsView.Manual;

  return (
    <ViewContainer>
      {hasAccessToAutomations && (
        <ButtonGroup
          selectedValue={selectedView}
          setSelectedValue={(view) => {
            navigate(
              ProgrammaticDomainsScreenDefinition.navigate({
                view,
              })
            );
          }}
          items={[
            {
              label: "Automatically Updated Segments",
              value: "automated",
            },
            {
              label: "Manually Uploaded Segments",
              value: "manual",
            },
          ]}
        />
      )}

      <Box mt={4}>
        <Box>
          {hasAccessToAutomations &&
            selectedView === ProgrammaticSegmentsView.Automated && (
              <AutomatedProgrammaticSegments />
            )}

          {selectedView === ProgrammaticSegmentsView.Manual && (
            <ProgrammaticDomainsTable />
          )}
        </Box>
      </Box>
    </ViewContainer>
  );
};

export { ProgrammaticDomains };
