import { SortDirection } from "@intentsify/types";
import { RowData, SortingState } from "@tanstack/react-table";
import { useState } from "react";

export const useSorting = <Data extends RowData>(
  defaultSortByColumn?: keyof Data,
  defaultSortDirection?: SortDirection
) => {
  const defaultColumn = {
    id: defaultSortByColumn as string,
    desc: defaultSortDirection === SortDirection.DESC,
  };

  return useState<SortingState>(defaultSortByColumn ? [defaultColumn] : []);
};
