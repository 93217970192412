import { Box, Flex, Image } from "@chakra-ui/react";
import {
  BuyerResearchStageMovementRO,
  WeeklySnapshotDTO,
} from "@intentsify/dto";
import { useQuery } from "@tanstack/react-query";
import { ColumnDef, SortingState } from "@tanstack/react-table";
import { ParentSize } from "@visx/responsive";
import { useMemo, useState } from "react";
import orderBy from "lodash/orderBy";
import { SortDirection, BuyerResearchStageMap } from "@intentsify/types";
import { getBuyerResearchStageMovement } from "./BuyerResearchStageMovement.requests";
import { Card, EmptyCell, TableVirtualised } from "components";
import { accountLogoFallbackImage } from "const";

type BuyerResearchStageMovementProps = {
  campaignId: number;
  filters: WeeklySnapshotDTO;
};

const columns: ColumnDef<BuyerResearchStageMovementRO>[] = [
  {
    header: "Account",
    accessorKey: "account",
    cell: ({ row }) => {
      return (
        <Flex alignItems="center" fontSize="xs">
          <Image
            minW="24px"
            boxSize="24px"
            objectFit="contain"
            src={String(row.original?.logoUrl)}
            mr={4}
            fallbackSrc={accountLogoFallbackImage}
          />
          {row.original.account}
        </Flex>
      );
    },
  },
  {
    header: "Buyer Research Stage This Week",
    accessorKey: "currentWeekBuyerResearchStage",
    cell: (info) =>
      info.getValue() ? (
        <Box fontSize="xs">{String(info.getValue())}</Box>
      ) : (
        <EmptyCell />
      ),
    sortingFn: (rowA, rowB, columnId) => {
      const stageA = rowA.getValue<
        "Decision" | "Consideration" | "Interest" | "Awareness"
      >(columnId);
      const stageB = rowB.getValue<
        "Decision" | "Consideration" | "Interest" | "Awareness"
      >(columnId);

      return BuyerResearchStageMap[stageA] < BuyerResearchStageMap[stageB]
        ? 1
        : BuyerResearchStageMap[stageA] > BuyerResearchStageMap[stageB]
        ? -1
        : 0;
    },
  },
  {
    header: "Buyer Research Stage Last Week",
    accessorKey: "previousWeekBuyerResearchStage",
    cell: (info) =>
      info.getValue() ? (
        <Box fontSize="xs">{String(info.getValue())}</Box>
      ) : (
        <EmptyCell />
      ),
    sortingFn: (rowA, rowB, columnId) => {
      const stageA = rowA.getValue<
        "Decision" | "Consideration" | "Interest" | "Awareness"
      >(columnId);
      const stageB = rowB.getValue<
        "Decision" | "Consideration" | "Interest" | "Awareness"
      >(columnId);

      return BuyerResearchStageMap[stageA] < BuyerResearchStageMap[stageB]
        ? 1
        : BuyerResearchStageMap[stageA] > BuyerResearchStageMap[stageB]
        ? -1
        : 0;
    },
  },
];

const BuyerResearchStageMovement = ({
  campaignId,
  filters,
}: BuyerResearchStageMovementProps) => {
  const { data: rawData, isFetching } = useQuery(
    ["getStageMgetBuyerResearchStageMovement", campaignId, filters],
    () => getBuyerResearchStageMovement(campaignId, filters)
  );

  const [sortBy, setSortBy] = useState<SortingState>([
    { id: "currentWeekBuyerResearchStage", desc: false },
  ]);

  const data = useMemo(() => {
    if (!rawData) {
      return [];
    }

    const [sort] = sortBy;

    if (sort) {
      return orderBy(rawData, [sort.id], sort.desc ? "desc" : "asc");
    }

    return rawData;
  }, [rawData, sortBy]);

  return (
    <Card
      isLoading={isFetching}
      title="Buyer Research Stage Movement"
      hasData={!isFetching && Boolean(data?.length)}
      noDataMessage="The data in this visualization is currently being configured and will be available after trend results are calculated."
      titleTooltip="Accounts showing greater intent signals to move up within a given BuyerResearchStage, which illustrates later-stage research movement."
    >
      <ParentSize>
        {(parent) => {
          const { width, height } = parent;

          return (
            <Flex w={width} h={height} fontSize="small">
              <TableVirtualised<BuyerResearchStageMovementRO>
                variant="corporateCompact"
                data={data ?? []}
                columns={columns}
                containerProps={{
                  width,
                  maxHeight: height,
                }}
                defaultSortByColumn={"currentWeekBuyerResearchStage"}
                defaultSortDirection={SortDirection.ASC}
                onSortingChange={setSortBy}
                enableSortingRemoval={false}
              />
            </Flex>
          );
        }}
      </ParentSize>
    </Card>
  );
};

export { BuyerResearchStageMovement };
