import { ListDraftAssetsRO } from "@intentsify/dto";
import { DraftAsset } from "@intentsify/types";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Endpoints, apiService } from "api";
import { useCallback } from "react";
import { FetchDataParamsWithToken } from "types";

export const useDraftAssets = (
  fetchDataParams: FetchDataParamsWithToken<keyof DraftAsset> & {
    showOnlyUploadedByUser?: boolean;
  }
) => {
  return useQuery({
    queryKey: ["contentLibrary", "draftAssets", fetchDataParams],
    queryFn: async () => {
      const params = { ...fetchDataParams };

      if ("search" in params && !params.search) {
        delete params.search;
      }

      const { data } = await apiService.get<ListDraftAssetsRO>(
        Endpoints.ContentLibrary.DraftAssets.Get.ListPaginated,
        { params }
      );

      return data;
    },
  });
};

export const useInvalidateDraftAssets = () => {
  const queryClient = useQueryClient();

  return useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: ["contentLibrary", "draftAssets"],
      exact: false,
    });
  }, [queryClient]);
};
