import { useCallback } from "react";
import { useLocation } from "react-router";
import { SearchedProperties, ampli } from "./amplitude";

export const useTrackSearched = () => {
  const location = useLocation();

  return useCallback(
    ({ collocation, term }: Omit<SearchedProperties, "pagePath">) => {
      // we shouldn't track empty searches
      // since they are the same as initial page load
      if (!term) {
        return;
      }

      ampli.searched({
        term,
        collocation,
        pagePath: location.pathname,
      });
    },
    [location.pathname]
  );
};
