import {
  Flex,
  SimpleGrid,
  Skeleton,
  SkeletonProps,
  useColorModeValue,
} from "@chakra-ui/react";
import { ViewContainer } from "components";

const CardSkeleton = (props: SkeletonProps) => (
  <Skeleton
    width="100%"
    height="143.6px"
    startColor={useColorModeValue("blackAlpha.100", "blackAlpha.500")}
    endColor={useColorModeValue("blackAlpha.300", "blackAlpha.200")}
    {...props}
  />
);

export const FallbackSkeleton = () => (
  <SimpleGrid
    columns={2}
    spacing="2px"
    minChildWidth={"800px"}
    gridAutoRows="min-content"
  >
    <ViewContainer stretch withBackground title="Active Campaigns">
      <Flex mb={4} w="100%" gridGap="4" flexDirection="column">
        <Flex justifyContent="space-between" alignItems="flex-end">
          <CardSkeleton width="400px" height="40px" />
          <CardSkeleton width="262px" height="32px" />
        </Flex>

        <CardSkeleton />
        <CardSkeleton />
        <CardSkeleton />
        <CardSkeleton />
      </Flex>
    </ViewContainer>

    <ViewContainer stretch withBackground title="Past Campaigns">
      <Flex mb={4} w="100%" gridGap="4" flexDirection="column">
        <CardSkeleton width="400px" height="40px" />
        <CardSkeleton height="400px" />
      </Flex>
    </ViewContainer>
  </SimpleGrid>
);
