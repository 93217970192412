import { useState } from "react";
import { Item } from "types";
import { useDeepEffect } from "utils";

const useTree = ({
  checked,
  expanded,
}: {
  checked: Item["value"][];
  expanded: Item["value"][];
}) => {
  const [checkedValues, setCheckedValues] = useState<Item["value"][]>([]);
  const [expandedValues, setExpandedValues] = useState<Item["value"][]>([]);

  useDeepEffect(() => {
    setCheckedValues(checked);
  }, [checked]);

  useDeepEffect(() => {
    setExpandedValues(expanded);
  }, [expanded]);

  const onReset = () => {
    setCheckedValues([]);
  };

  return {
    checkedValues,
    expandedValues,
    setCheckedValues,
    setExpandedValues,
    onReset,
  };
};

export { useTree };
