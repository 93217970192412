import { Box, Flex } from "@chakra-ui/layout";
import { LegendItem, LegendLabel } from "@visx/legend";
import { getLegendDirection } from "../../Charts.utils";
import { TimeseriesProps } from "../Timeseries";
import { useGetColour } from "../Timeseries.hooks";
import {
  TimeseriesDataItem,
  TimeseriesLegendItemFormatted,
} from "../../Charts.types";
import { muteTimeseriesColor } from "../Timeseries.utils";

type LegendProps = {
  items: Array<{
    data: TimeseriesDataItem[];
    legend: TimeseriesLegendItemFormatted;
  }>;
} & Pick<TimeseriesProps, "legendPosition">;

const Legend = ({ items, legendPosition }: LegendProps) => {
  const legendGlyphSize = 14;

  const getColour = useGetColour();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: getLegendDirection(legendPosition),
        width: "100%",
        flexWrap: "wrap",
      }}
    >
      {items.map((item, i) => {
        return (
          <Flex alignItems="center" key={`legend-quantile-${i}`}>
            <LegendItem>
              <Flex width="100%" alignItems="center" borderRadius={4} px={3}>
                <Box mr={2}>
                  <svg width={legendGlyphSize} height={legendGlyphSize}>
                    <rect
                      fill={
                        item.legend.muteColor
                          ? muteTimeseriesColor(getColour(item.legend.color)[0])
                          : getColour(item.legend.color)[0]
                      }
                      width={legendGlyphSize}
                      height={legendGlyphSize}
                    />
                  </svg>
                </Box>
                <LegendLabel align="left">
                  <Box whiteSpace="nowrap" fontSize="small">
                    {item.legend.label}
                  </Box>
                </LegendLabel>
              </Flex>
            </LegendItem>

            <Flex grow={1}></Flex>
          </Flex>
        );
      })}
    </div>
  );
};

export { Legend };
