import { SinglePublisherScreen } from "types";
import { createScreen } from "utils/createScreen";

const SinglePublisherScreenDefinition = createScreen({
  name: SinglePublisherScreen,
  path: "/admin/publishers/:id/details",
  routePath: ["/:id/details"],
  accessPath: "publisher",
});

export { SinglePublisherScreenDefinition };
