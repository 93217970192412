import { Flex } from "@chakra-ui/layout";
import { Text as ChakraText, useColorMode } from "@chakra-ui/react";
import {
  isDefined,
  isPopulatedArray,
  toNumberDisplayValue,
} from "@intentsify/utils";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { localPoint } from "@visx/event";
import { Group } from "@visx/group";
import { ParentSize } from "@visx/responsive";
import { scaleBand, scaleLinear, scaleOrdinal } from "@visx/scale";
import { Bar } from "@visx/shape";
import { Text } from "@visx/text";
import { useTooltip, useTooltipInPortal } from "@visx/tooltip";
import { ScaleBand, ScaleLinear } from "d3-scale";
import omit from "lodash/omit";
import { useMemo, useRef } from "react";
import { animated, useSpring } from "react-spring";
import { useChartColors } from "utils";
import { Loader } from "../../Loader";
import { NoData } from "../../NoData";
import { BasicTooltip } from "../BasicTooltip";
import { EXPORT_TITLE_HEIGHT } from "../Charts.const";
import { BarChartProps, defaultKeys } from "./BarChart";
import { BarChartItem } from "./BarChart.types";
import { getBarChartColor } from "./BarChart.utils";
import { estimateSpaceForLabelHorizontal } from "./estimateSpaceForLabel";

const estimatedBarHeight = 40;
const spaceForAxisBottom = 15;
const spaceForAxisBottomLabel = 30;
const yAxisMarginRight = 8;

const Horizontal = ({
  isLoading,
  data,
  margins = {
    t: 20,
    b: 30,
    r: 20,
    l: 0,
  },
  stretch = false,
  showXAxis = true,
  noDataMessage,
  valueAxisLabel,
  labelAxisLabel,
  id,
  exportMode,
  title,
  colorRange,
  minHeight,
  valueNumTicks,
  keys = defaultKeys,
  tooltipRenderer,
}: Omit<BarChartProps, "orientation">) => {
  const itemsScale = useRef<ScaleBand<string> | undefined>();
  const valueScale = useRef<ScaleLinear<number, number, never> | undefined>();

  const { labelColor, axisColors } = useChartColors();

  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip<BarChartItem>();

  const estimatedYAxisWidth = useMemo(() => {
    return estimateSpaceForLabelHorizontal(data, {
      fontSize: 12,
    });
  }, [data]);

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    detectBounds: true,
    scroll: true,
  });

  const colorScale = scaleOrdinal<string, string>({
    domain: keys,
    range: colorRange,
  });

  const { colorMode } = useColorMode();
  const barFill = getBarChartColor(colorMode);

  const [{ scale }] = useSpring(
    {
      from: { scale: 0 },
      to: { scale: 1 },
      reset: true,
    },
    [isLoading]
  );

  if (isLoading) {
    return <Loader minHeight={minHeight} stretch={stretch} />;
  }

  if (!isPopulatedArray(data)) {
    return (
      <NoData message={noDataMessage} height={minHeight} stretch={stretch} />
    );
  }

  const maxHeight = !stretch
    ? estimatedBarHeight * data.length + margins.t + margins.b
    : undefined;

  const handleMouseOver = (event: any, item: BarChartItem) => {
    if (event.target) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const coords = localPoint(event.target.ownerSVGElement, event);
      if (coords) {
        showTooltip({
          tooltipLeft: coords.x,
          tooltipTop: coords.y,
          tooltipData: item,
        });
      }
    }
  };

  const AnimatedBar = animated(Bar);
  if (labelAxisLabel) {
    margins.l += 40;
  }

  return (
    <Flex
      overflowY="auto"
      width="100%"
      height="100%"
      borderRadius="10px"
      minHeight={minHeight}
    >
      <Flex width="100%" height={maxHeight}>
        <ParentSize>
          {(parent) => {
            const { width, height } = parent;

            const horizontalMargin = margins.l + margins.r;

            const yAxisWidth = Math.min(
              estimatedYAxisWidth,
              (width - horizontalMargin) * (3 / 4)
            );

            const baseYMax =
              height -
              margins.t -
              margins.b -
              spaceForAxisBottom -
              (valueAxisLabel ? spaceForAxisBottomLabel : 0);

            const yMax = !exportMode
              ? baseYMax
              : baseYMax - EXPORT_TITLE_HEIGHT;

            const xMax = width - horizontalMargin - yAxisWidth;

            itemsScale.current = scaleBand<string>({
              range: [0, yMax],
              round: true,
              domain: data.map((i) => i.label),
              paddingInner: 0.2,
              paddingOuter: 0,
            });

            valueScale.current = scaleLinear<number>({
              range: [0, xMax],
              round: true,
              domain: [0, Math.max(...data.map((i) => i.value))],
            });

            return (
              <svg
                width={width}
                height={height}
                ref={containerRef}
                id={id}
                style={{
                  fontFamily: "var(--chakra-fonts-body)",
                }}
              >
                <rect
                  x={0}
                  y={0}
                  width={width}
                  height={height}
                  fill={"transparent"}
                />

                {exportMode && title && (
                  <Group top={30} left={22}>
                    <Text
                      verticalAnchor="middle"
                      fontSize={22}
                      fontWeight="semibold"
                      fill={labelColor}
                    >
                      {title}
                    </Text>
                  </Group>
                )}

                <g
                  transform={`translate(${margins.l + yAxisWidth},${
                    !exportMode ? margins.t : margins.t + EXPORT_TITLE_HEIGHT
                  })`}
                >
                  <Group transform={`translate(${yAxisMarginRight}, 0)`}>
                    {data.map((d, index) => {
                      if (!valueScale.current || !itemsScale.current) {
                        return null;
                      }

                      const barHeight = itemsScale.current.bandwidth();
                      const barWidth = valueScale.current(d.value ?? 0);
                      const barY = itemsScale.current(d.label);

                      return (
                        <AnimatedBar
                          key={`bar-${d.value}-${index}`}
                          x={0}
                          y={barY}
                          width={scale.to((s) => s * barWidth)}
                          height={barHeight}
                          fill={colorRange ? colorScale(d.label) : barFill}
                          onMouseMove={(e) => {
                            handleMouseOver(e, data[index]);
                          }}
                          onMouseLeave={() => {
                            hideTooltip();
                          }}
                        />
                      );
                    })}
                  </Group>

                  <AxisLeft
                    numTicks={data.length}
                    tickComponent={(props) => {
                      return (
                        <Text
                          {...omit(props, "formattedValue")}
                          width={yAxisWidth}
                          x={0} // AxisLeft set this to 8px which result in cut of first character
                          style={{
                            // visx internally calculate text size based on style object,
                            // and ignoring passed fontSize, here the fix for this
                            fontSize: props.fontSize
                              ? `${props.fontSize}px`
                              : undefined,
                          }}
                        >
                          {props.formattedValue}
                        </Text>
                      );
                    }}
                    scale={itemsScale.current}
                    hideTicks
                    tickFormat={(v) => String(v)}
                    hideAxisLine
                    tickLabelProps={() => ({
                      fill: labelColor,
                      fontSize: 12,
                      textAnchor: "end",
                      verticalAnchor: "middle",
                    })}
                  />

                  {labelAxisLabel && (
                    <Text
                      x={-yMax / 2}
                      y={-margins.l + 12}
                      textAnchor="middle"
                      verticalAnchor="end"
                      transform="rotate(-90)"
                      fontSize={14}
                      fill={labelColor}
                    >
                      {labelAxisLabel}
                    </Text>
                  )}

                  {showXAxis && (
                    <>
                      <AxisBottom
                        stroke={axisColors}
                        tickStroke={axisColors}
                        left={yAxisMarginRight}
                        top={yMax + spaceForAxisBottom}
                        scale={valueScale.current}
                        numTicks={valueNumTicks}
                        tickLabelProps={() => ({
                          fill: labelColor,
                          fontSize: 11,
                          textAnchor: "middle",
                        })}
                      />

                      {valueAxisLabel && (
                        <Text
                          x={xMax / 2}
                          y={
                            yMax + spaceForAxisBottom + spaceForAxisBottomLabel
                          }
                          textAnchor="middle"
                          verticalAnchor="start"
                          fontSize={14}
                          fill={labelColor}
                        >
                          {valueAxisLabel}
                        </Text>
                      )}
                    </>
                  )}
                </g>

                {tooltipOpen &&
                  tooltipData &&
                  isDefined(tooltipLeft) &&
                  isDefined(tooltipTop) && (
                    <BasicTooltip
                      tooltipRenderer={({ textColor, tooltipData }) => {
                        if (tooltipRenderer) {
                          return <>{tooltipRenderer(textColor, tooltipData)}</>;
                        }
                        return (
                          <ChakraText fontSize="medium" color={textColor}>
                            <strong>{tooltipData.label}:</strong>{" "}
                            {toNumberDisplayValue(tooltipData.value)}
                          </ChakraText>
                        );
                      }}
                      TooltipInPortal={TooltipInPortal}
                      tooltipLeft={tooltipLeft}
                      tooltipTop={tooltipTop}
                      tooltipData={tooltipData}
                    />
                  )}
              </svg>
            );
          }}
        </ParentSize>
      </Flex>
    </Flex>
  );
};

export { Horizontal };
