import { HStack, Icon, Text } from "@chakra-ui/react";
import { getExportFileName, noop } from "@intentsify/utils";
import { IconButton } from "components/IconButton";
import { IconType } from "react-icons";
import { FaFileCsv } from "react-icons/fa";
import { FiDownload, FiTrash } from "react-icons/fi";
import { useDownloadCsvLink } from "../../DomainsUpload.hooks";

interface UploadedFileEntryProps {
  data: Record<string, string | number | undefined | null>[];
  fileName: string;
  campaignId?: number;
  domainsCount?: number;
  icon?: IconType;
  iconColor?: string | undefined;
  onDeleteIconClick?: () => void;
  displayExpliciteFileName?: boolean;
}

const UploadedFileEntry = ({
  data,
  fileName,
  campaignId,
  domainsCount,
  icon = FaFileCsv,
  iconColor = undefined,
  onDeleteIconClick,
  displayExpliciteFileName = false,
}: UploadedFileEntryProps) => {
  const [downloadCsvLink, blobSize] = useDownloadCsvLink(data);
  const isFileNonEmpty = blobSize > 0;
  const fileNameWithoutSpaces = fileName.split(" ").join("_");
  const exportFileName = getExportFileName({
    prefix: fileNameWithoutSpaces,
    data: { id: campaignId || 0 },
    extension: ".csv",
  });

  if (!isFileNonEmpty) {
    return null;
  }

  return (
    <HStack spacing="1" alignItems="center">
      <Icon as={icon} w={4} h={4} color={iconColor} />
      <Text
        fontWeight="semibold"
        fontSize="sm"
        w="240px"
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        title={exportFileName}
      >
        {displayExpliciteFileName ? fileName : exportFileName}
      </Text>

      <Text
        fontSize="sm"
        pl="24px"
        w="200px"
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
      >
        {domainsCount || ""}
      </Text>

      {downloadCsvLink && (
        <IconButton
          as="a"
          icon={<Icon as={FiDownload} fontSize="md" color={iconColor} />}
          onClick={noop}
          tooltip="Download file"
          download={exportFileName}
          href={downloadCsvLink}
        />
      )}

      {onDeleteIconClick && (
        <IconButton
          tooltip="Delete file"
          icon={<Icon as={FiTrash} fontSize="md" />}
          onClick={onDeleteIconClick}
        />
      )}
    </HStack>
  );
};

export { UploadedFileEntry };
