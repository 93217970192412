import { Box, Flex } from "@chakra-ui/layout";
import { BaseTargetPersona, Option, TargetPersona } from "@intentsify/types";
import { Button, FilterAsyncTokenBased } from "components";
import { useState } from "react";
import { FetchDataParamsWithToken } from "types";
import { getTargetPersonas } from "../../../../../../../../TargetPersonas/useTargetPersonas";

type PersonaOption = Option<string, BaseTargetPersona>;

const personaDataRequest = async (
  params: FetchDataParamsWithToken<keyof TargetPersona> & { companyId?: number }
) => {
  const { targetPersonas } = await getTargetPersonas(params);
  return {
    ...targetPersonas,
    results: targetPersonas.map((persona) => ({
      value: persona.id,
      label: persona.name,
      meta: persona,
    })),
  };
};

type PersonaSelectorProps = {
  companyId: number;
  onAdd: (persona: BaseTargetPersona[]) => void;
};

export const PersonaSelector = ({ companyId, onAdd }: PersonaSelectorProps) => {
  const [activePersona, setActivePersona] = useState<PersonaOption[]>([]);

  const addPersona = () => {
    if (activePersona.length) {
      onAdd(activePersona.flatMap(({ meta }) => (meta ? [meta] : [])));
      setActivePersona([]);
    }
  };

  return (
    <Flex gap="2" alignItems="flex-end">
      <Box width="sm">
        <FilterAsyncTokenBased
          isMulti={true}
          defaultOptions={[]}
          currentValue={activePersona}
          label="Existing Personas"
          dataRequest={(arg: FetchDataParamsWithToken<keyof TargetPersona>) =>
            personaDataRequest({ ...arg, companyId })
          }
          onFilterValuesChange={(options) => setActivePersona(options ?? [])}
        />
      </Box>
      <Button isDisabled={!activePersona.length} onClick={addPersona}>
        Add to Buying Group
      </Button>
    </Flex>
  );
};
