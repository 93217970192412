import { BusinessEvent } from "@intentsify/types";
import { formatDate } from "@intentsify/utils";
import { ColumnDef } from "@tanstack/react-table";
import startCase from "lodash/startCase";
import toLower from "lodash/toLower";

export const useColumns = (): Array<ColumnDef<BusinessEvent>> => {
  const columns: Array<ColumnDef<BusinessEvent>> = [
    {
      header: "Category",
      accessorKey: "category",
      accessorFn: (row) => startCase(toLower(row.category)),
    },
    {
      header: "Summary",
      accessorKey: "summary",
    },
    {
      header: "Date Published",
      accessorKey: "datePublished",
      cell: ({ row }) => {
        return formatDate({
          date: new Date(row.original.datePublished),
        });
      },
    },
  ];

  return columns;
};
