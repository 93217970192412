import { Box, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import { CampaignTopic } from "@intentsify/types";
import { Modal, StatusModal } from "components";

const TopicsList = ({ topics }: { topics: CampaignTopic[] }) => {
  if (topics.length === 0) {
    return (
      <Text size="xl" fontWeight="bold" pb={4}>
        -
      </Text>
    );
  }

  return (
    <UnorderedList>
      {topics.map((t) => (
        <ListItem key={t.topicId}>{t.topic}</ListItem>
      ))}
    </UnorderedList>
  );
};

type UploadTopicsModalProps = {
  isOpen: boolean;
  topics: CampaignTopic[];
  isLoading: boolean;
  onClose: () => void;
};

export const UploadTopicsModal = ({
  topics,
  isOpen,
  onClose,
  isLoading,
}: UploadTopicsModalProps) => {
  if (isLoading) {
    return <StatusModal isOpen />;
  }

  return (
    <Modal
      title="Uploaded topics:"
      onClose={onClose}
      isOpen={isOpen}
      body={
        <Box ml={0} maxH={"500px"} overflowY="auto">
          <TopicsList topics={topics} />
        </Box>
      }
    />
  );
};
