import { AxiosError } from "axios";
import { CustomApiError } from "utils";
import { HttpStatus } from "./HttpStatus";

export type ApiError = {
  statusCode: HttpStatus;
  message?: string | string[];
  internalCode?: string;
  metadata?: Record<string, unknown>;
};

export const isCustomApiError = (
  error: CustomApiError | Error | AxiosError | unknown
): error is CustomApiError => {
  const possibleApiError = error as CustomApiError;

  if (possibleApiError instanceof CustomApiError) {
    return true;
  }

  return false;
};

export const isError = (error: any): error is Error => error instanceof Error;
