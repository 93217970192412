import {
  AvailableSignalTypeResponse,
  AvailableSingalTypesParams,
  SingleAccountDetailsParams,
  SingleAccountDetailsTimeseriesResponse,
} from "./SingleAccountDetails.types";
import { apiService, Endpoints } from "api";
import { valueToQueryURL } from "utils";

export const getSingleAccountDetails = async ({
  campaignId,
  account,
  dateRange,
  signalType,
}: SingleAccountDetailsParams): Promise<SingleAccountDetailsTimeseriesResponse> => {
  const { data } = await apiService.get<SingleAccountDetailsTimeseriesResponse>(
    Endpoints.Campaigns.Get.Domains.Details(Number(campaignId)),
    {
      params: {
        signalType: signalType.join(","),
        dateRange,
        account: valueToQueryURL(account),
      },
    }
  );
  return data;
};

export const getAvailableSignalTypes = async ({
  campaignId,
  account,
  dateRange,
}: AvailableSingalTypesParams): Promise<AvailableSignalTypeResponse> => {
  const { data } = await apiService.get<AvailableSignalTypeResponse>(
    Endpoints.Campaigns.Get.Signals.AvailableSignalTypes(Number(campaignId)),
    {
      params: {
        dateRange,
        account: valueToQueryURL(account),
      },
    }
  );

  return data;
};
