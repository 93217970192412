import { Flex } from "@chakra-ui/react";
import { LogoBoxed } from "components/Logo";

const LayoutSkeleton = () => {
  return (
    <Flex h="100vh" w="100vw" flexDir={"row"}>
      <Flex
        borderRightWidth="2px"
        borderRightStyle="solid"
        borderRightColor="brand.400"
        background="brand.450"
        display="flex"
        h="100%"
        w="240px"
      >
        <Flex
          p={4}
          alignItems="center"
          justifyContent="center"
          h="64px"
          w="210px"
          position="absolute"
        >
          <LogoBoxed w="100%" />
        </Flex>
      </Flex>
      <Flex bg="brand.400" h="64px" w="100%" />
    </Flex>
  );
};

export { LayoutSkeleton };
