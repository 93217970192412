import { createScreen } from "utils/createScreen";
import { DownloadTalToolsScreen } from "../../types";

const DownloadTalToolsDefinition = createScreen({
  name: DownloadTalToolsScreen,
  path: "/download/tal-tools/:type/:fileName/:jobId",
  routePath: ["/download/tal-tools/:type/:fileName/:jobId"],
});

export { DownloadTalToolsDefinition };
