import {
  Box,
  BoxProps,
  Grid,
  GridProps,
  Text,
  TextProps,
} from "@chakra-ui/react";
import { motion } from "framer-motion";

export const MotionBox = motion<BoxProps>(Box);
export const MotionText = motion<TextProps>(Text);
export const MotionGrid = motion<GridProps>(Grid);
