import { ListBusinessEntitiesRO } from "@intentsify/dto";
import { apiService, Endpoints } from "api";

export const listBusinessEntities = async (): Promise<
  ListBusinessEntitiesRO["businessEntities"]
> => {
  const { data } = await apiService.get<
    ListBusinessEntitiesRO["businessEntities"]
  >(Endpoints.BusinessEntities.Get.All);
  return data;
};
