import { Box, Flex } from "@chakra-ui/react";
import { useHasAccessTo } from "@intentsify/authorization/dist/react";
import { Option } from "@intentsify/types";
import { Endpoints } from "api";
import { Button, ErrorBoundary, ViewContainer } from "components";
import { useEffect, useState } from "react";
import { FiDownload } from "react-icons/fi";
import { Navigate, useParams } from "react-router";
import { useRecoilValue } from "recoil";
import { CampaignMetaHeader } from "shared/components";
import { useTrackCampaignFileDownloaded } from "tracking/useTrackCampaignFileDownloaded";
import { useDownloadFile, useScreen } from "utils";
import { YourCampaignsScreenDefinition } from "../CampaignsWizard/YourCampaigns.definition";
import { CampaignsLeadsScreenDefinition } from "./CampaignsLeads.definition";
import {
  downloadFetchDataParamsAtom,
  leadsHasResultsAtom,
} from "./CampaignsLeads.state";
import { CampaignsLeadsTable, Filters } from "./components";

export type CampaignsLeadsFilters = {
  accounts: Option[];
  search: string;
};

const CampaignsLeads = () => {
  useScreen(CampaignsLeadsScreenDefinition);

  const campaignId = Number(useParams<"campaignId">().campaignId);
  const { download } = useDownloadFile();
  const [filters, setFilters] = useState<CampaignsLeadsFilters>({
    accounts: [],
    search: "",
  });
  const hasResults = useRecoilValue(leadsHasResultsAtom);
  const downloadFetchDataParams = useRecoilValue(downloadFetchDataParamsAtom);

  useEffect(() => {
    setFilters({ accounts: [], search: "" });
  }, [campaignId, setFilters]);

  const hasAccessToLeads = useHasAccessTo("campaign.leadGeneration");
  const trackCampaignFileDownloaded = useTrackCampaignFileDownloaded();

  if (!hasAccessToLeads) {
    return <Navigate to={YourCampaignsScreenDefinition.navigate()} />;
  }

  const onFiltersChange = (val: Partial<CampaignsLeadsFilters>) => {
    setFilters((prev) => ({ ...prev, ...val }));
  };

  const handleDownload = async () => {
    if (campaignId) {
      await download({
        url: Endpoints.Campaigns.Get.Leads.DownloadSelection(
          Number(campaignId)
        ),
        fileName: `intent_model_leads_${String(campaignId)}_${new Date()
          .toISOString()
          .slice(0, 10)}`,
        fileExtension: "zip",
        params: downloadFetchDataParams,
      });

      trackCampaignFileDownloaded({
        type: "campaign leads",
        campaignIds: [Number(campaignId)],
      });
    }
  };

  return (
    <Flex p={4} flexWrap="wrap">
      {campaignId && (
        <>
          <Box flex="1 0 100%">
            <ErrorBoundary>
              <CampaignMetaHeader />
            </ErrorBoundary>
          </Box>
          <Box flex="1 0 100%">
            <ViewContainer
              actionItems={
                <Button
                  isDisabled={!hasResults}
                  size="sm"
                  variant="primary"
                  leftIcon={<FiDownload />}
                  onClick={() => {
                    handleDownload();
                  }}
                >
                  Download
                </Button>
              }
              noPadding
            >
              <Filters setFilters={onFiltersChange} filters={filters} />
              <CampaignsLeadsTable campaignId={campaignId} filters={filters} />
            </ViewContainer>
          </Box>
        </>
      )}
    </Flex>
  );
};

export { CampaignsLeads };
