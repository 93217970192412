import { Flex } from "@chakra-ui/react";
import { Nullable } from "@intentsify/types";
import { isDefined } from "@intentsify/utils";
import { Group } from "@visx/group";
import { ParentSize } from "@visx/responsive";
import { scaleLinear, scaleTime } from "@visx/scale";
import { LinePath } from "@visx/shape";
import { ErrorBoundary } from "components";
import { ScaleLinear, ScaleTime } from "d3-scale";
import { DateTime } from "luxon";
import { useRef } from "react";
import { useMeasure } from "react-use";
import { getMinMax } from "../Timeseries/Timeseries.utils";

type SparklineProps = {
  data: {
    isoDate: string;
    value: Nullable<number>;
  }[];
  margins: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
  lineWidth: number;
  color?: string;
  beginAtZero?: boolean;
};

const Sparkline = ({
  data,
  margins,
  lineWidth,
  color,
  beginAtZero = true,
}: SparklineProps) => {
  const [ref] = useMeasure<HTMLDivElement>();
  const xScale = useRef<ScaleTime<number, number, never> | undefined>();
  const yScale = useRef<ScaleLinear<number, number, never> | undefined>();
  const xValues = data.map((i) =>
    DateTime.fromISO(i.isoDate).toUTC().valueOf()
  );
  const yValues = data.map((i) => i.value).filter(isDefined);

  const sortedData = data.sort(
    (a, b) =>
      DateTime.fromISO(a.isoDate, { zone: "utc" }).valueOf() -
      DateTime.fromISO(b.isoDate, { zone: "utc" }).valueOf()
  );

  return (
    <ErrorBoundary>
      <Flex ref={ref} width="100%" height="100%">
        <Flex width="100%">
          <ParentSize>
            {(parent) => {
              const { width, height } = parent;
              const baseYMax = height - margins.top - margins.bottom;
              const baseXMax = width - margins.left - margins.right;

              const yMax = baseYMax;
              const xMax = baseXMax;

              xScale.current = scaleTime<number>({
                domain: getMinMax(xValues),
                range: [0, xMax],
                nice: false,
              });

              yScale.current = scaleLinear<number>({
                domain: beginAtZero
                  ? [0, Math.max(...yValues)]
                  : getMinMax(yValues),
                range: [yMax, 0],
                nice: true,
              });

              return (
                <Flex
                  width={width}
                  height={height}
                  justifyContent="center"
                  alignItems="center"
                >
                  <svg
                    width={width}
                    height={height}
                    style={{
                      fontFamily: "var(--chakra-fonts-body)",
                    }}
                  >
                    <rect width={width} height={height} fill={"transparent"} />

                    <Group left={margins.left} top={margins.top}>
                      <g>
                        <LinePath
                          defined={(d) => d.value !== null}
                          strokeWidth={lineWidth}
                          data={sortedData}
                          x={(d) => {
                            // @ts-ignore
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                            return xScale.current(
                              DateTime.fromISO(d.isoDate).toUTC().valueOf()
                            );
                          }}
                          y={(d) => {
                            // @ts-ignore
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                            return yScale.current(d.value);
                          }}
                          stroke={color}
                        />
                      </g>
                    </Group>
                  </svg>
                </Flex>
              );
            }}
          </ParentSize>
        </Flex>
      </Flex>
    </ErrorBoundary>
  );
};

export { Sparkline };
