import { toNumberDisplayValue } from "@intentsify/utils";
import { useQuery } from "@tanstack/react-query";
import { getUserCampaignsSummary } from "api";
import { StatCardsContainer } from "components";

type StatsProps = {
  searchValue: string | undefined;
  isFavoritesFilterEnabled: boolean;
};

const Stats = ({ searchValue, isFavoritesFilterEnabled }: StatsProps) => {
  const { data: summary, isFetching: isFetchingSummary } = useQuery({
    queryKey: ["userCampaignsSummary", searchValue, isFavoritesFilterEnabled],
    queryFn: () =>
      getUserCampaignsSummary({
        search: searchValue || undefined,
        isFavorite: isFavoritesFilterEnabled,
      }),
  });

  return (
    <StatCardsContainer
      items={[
        {
          isLoading: isFetchingSummary,
          title: "Total Live Intent Models",
          value: summary?.totalLiveCampaigns,
          previousPeriodChange: summary?.totalLiveCampaignsVsPrevious,
          previousPeriodLabel: "vs Previous Week:",
          valueFormatter: (value) => `${toNumberDisplayValue(value)}`,
          titleTooltip:
            "Campaigns associated with your assigned company(s) that are currently in market.",
          noValueMessage: "No historical data available.",
        },
        {
          isLoading: isFetchingSummary,
          title: "Total Unique Accounts",
          value: summary?.totalUniqueAccounts,
          previousPeriodChange: summary?.totalUniqueAccountsVsPrevious,
          previousPeriodLabel: "vs Previous Week:",
          valueFormatter: (value) => `${toNumberDisplayValue(value)}`,
          titleTooltip: "Count of unique accounts within your live campaigns.",
          noValueMessage: "No historical data available.",
        },
        {
          isLoading: isFetchingSummary,
          title: "Total Unique Intent Signals",
          value: summary?.totalUniqueIntentSignals,
          previousPeriodChange: summary?.totalUniqueIntentSignalsVsPrevious,
          previousPeriodLabel: "vs Previous Week:",
          valueFormatter: (value) => `${toNumberDisplayValue(value)}`,
          titleTooltip:
            "Count of unique intent activity signals across your target accounts and associated campaigns in market, ie. researched relevant topics, engaged with a public post on LinkedIn, etc.",
          noValueMessage: "No historical data available.",
        },
        {
          isLoading: isFetchingSummary,
          title: "Total Unique Locations of Intent",
          value: summary?.topLocationsIntent,
          previousPeriodLabel: "vs Previous Week:",
          previousPeriodChange: summary?.topLocationsIntentVsPrevious,
          valueFormatter: (value) => `${toNumberDisplayValue(value)}`,
          titleTooltip:
            "In the previous week, sum of unique locations monitoring intent signals and activity.",
          noValueMessage: "No historical data available.",
        },
        {
          isLoading: isFetchingSummary,
          title: "Total Website Visits in Past 30 Days",
          value: summary?.totalWebsiteVisits,
          previousPeriodChange: summary?.totalWebsiteVisitsVsPrevious,
          previousPeriodLabel: "vs Previous Week:",
          valueFormatter: (value) => `${toNumberDisplayValue(value)}`,
          titleTooltip:
            "Utilizing Intentsify’s universal pixel, this is the sum of website visits to your company’s website over the past 30 days.",
          titleTooltipPlacement: "bottom-start",
          noValueMessage: "No historical data available.",
        },
      ]}
    />
  );
};

export { Stats };
