import { AccessPath } from "@intentsify/authorization";
import { IconType } from "react-icons";
import { FaTools, FaUsers } from "react-icons/fa";
import { FiHardDrive } from "react-icons/fi";
import {
  MdAnalytics,
  MdApartment,
  MdAutoGraph,
  MdBatchPrediction,
  MdDomain,
  MdEngineering,
  MdMultilineChart,
  MdOutlineCorporateFare,
  MdPeople,
  MdPersonSearch,
  MdShowChart,
  MdStackedLineChart,
} from "react-icons/md";
import { RiMapPinFill } from "react-icons/ri";
import { AdminRootDefinition } from "screens/Admin/Admin.definition";
import { BusinessEntitiesScreenDefinition } from "screens/Admin/screens/BusinessEntities/screens/BusinessEntities/BusinessEntities.definition";
import { CompaniesScreenDefinition } from "screens/Admin/screens/Companies/screens/Companies/Companies.definition";
import { PublishersScreenDefinition } from "screens/Admin/screens/Publishers/screens/Publishers/Publishers.definition";
import { UsersScreenDefinition } from "screens/Admin/screens/Users/screens/Users/Users.definition";
import { CampaignPerformanceRootDefinition } from "screens/CampaignPacing/CampaignPacing.definition";
import { CompanyLeadPacingScreenDefinition } from "screens/CampaignPacing/screens/CompanyLeadPacing/CompanyLeadPacing.definition";
import { DisplayPerformanceScreenDefinition } from "screens/CampaignPacing/screens/DisplayPerformance/DisplayPerformance.definition";
import { CampaignsRootDefinition } from "screens/Campaigns/Campaigns.definition";
import { YourCampaignsScreenDefinition } from "screens/Campaigns/screens/CampaignsWizard/YourCampaigns.definition";
import { ContentLibraryDefinition } from "screens/ContentLibrary/ContentLibrary.definition";
import { IntentAnalysisRootDefinition } from "screens/IntentAnalysis/IntentAnalysis.definition";
import { TrendingAccountsScreenDefinition } from "screens/IntentAnalysis/screens/CampaignsTrendingDomains/CampaignsTrendingDomains.definition";
import { TrendingTopicsAndKeywordsScreenDefinition } from "screens/IntentAnalysis/screens/CampaignsTrendingSignals/CampaignsTrendingSignals.definition";
import { WeeklySnapshotScreenDefinition } from "screens/IntentAnalysis/screens/WeeklySnapshot";
import { MediaActivationRootDefinition } from "screens/MediaActivation/MediaActivation.definition";
import { DigitalForecastingScreenDefinition } from "screens/MediaActivation/screens/DigitalForecasting/DigitalForecasting.definition";
import { PageTrackingScreenDefinition } from "screens/MediaActivation/screens/PageTracking/PageTracking.definition";
import { ProgrammaticDomainsScreenDefinition } from "screens/MediaActivation/screens/ProgrammaticDomains/screens/ProgrammaticDomains/ProgrammaticDomains.definition";
import { TargetPersonasDefinition } from "screens/TargetPersonas/TargetPersonas.definition";
import { ContactSearchDefinition } from "screens/Tools/screens/ContactSearch";
import {
  TalPreparationToolsDefinition,
  ToolsRootDefinition,
} from "../../../../screens/Tools";

interface LinkItem {
  name: string;
  path: string;
  accessPath: AccessPath;
  icon: IconType;
}

interface LinkGroup {
  name: string;
  items: LinkItem[];
}

const linkGroups: LinkGroup[] = [
  {
    name: CampaignsRootDefinition.name,
    items: [
      {
        name: YourCampaignsScreenDefinition.name,
        path: YourCampaignsScreenDefinition.path,
        accessPath: "campaign",
        icon: MdAnalytics,
      },
      {
        name: ContentLibraryDefinition.name,
        path: "/content-library",
        accessPath: "contentLibrary",
        icon: FiHardDrive,
      },
      {
        name: TargetPersonasDefinition.name,
        path: "/target-personas",
        accessPath: "targetPersona",
        icon: FaUsers,
      },
    ],
  },

  {
    name: CampaignPerformanceRootDefinition.name,
    items: [
      {
        name: CompanyLeadPacingScreenDefinition.name,
        path: CompanyLeadPacingScreenDefinition.navigate({
          companyId: undefined,
        }),
        accessPath: "campaign.digitalPerformance",
        icon: MdShowChart,
      },
      {
        name: DisplayPerformanceScreenDefinition.name,
        path: DisplayPerformanceScreenDefinition.navigate({
          programmaticCampaignId: undefined,
          view: undefined,
        }),
        accessPath: "campaign.displayPerformance",
        icon: MdShowChart,
      },
    ],
  },

  {
    name: IntentAnalysisRootDefinition.name,
    items: [
      {
        name: WeeklySnapshotScreenDefinition.name,
        path: WeeklySnapshotScreenDefinition.navigate({
          campaignId: undefined,
          view: undefined,
        }),
        accessPath: "campaign.weeklySnapshot",
        icon: MdMultilineChart,
      },
      {
        name: TrendingAccountsScreenDefinition.name,
        path: TrendingAccountsScreenDefinition.navigate({
          campaignId: undefined,
        }),
        accessPath: "campaign.trendingAccounts",
        icon: MdAutoGraph,
      },
      {
        name: TrendingTopicsAndKeywordsScreenDefinition.name,
        path: TrendingTopicsAndKeywordsScreenDefinition.navigate({
          campaignId: undefined,
        }),
        accessPath: "campaign.accountsTopicsAndKeywords",
        icon: MdStackedLineChart,
      },
    ],
  },

  {
    name: MediaActivationRootDefinition.name,
    items: [
      {
        name: ProgrammaticDomainsScreenDefinition.name,
        path: ProgrammaticDomainsScreenDefinition.navigate({
          view: undefined,
        }),
        accessPath: "programmaticCampaign.programmaticDomain",
        icon: MdDomain,
      },
      {
        name: PageTrackingScreenDefinition.name,
        path: PageTrackingScreenDefinition.navigate(),
        accessPath: "pageTracking",
        icon: RiMapPinFill,
      },
      {
        name: DigitalForecastingScreenDefinition.name,
        path: DigitalForecastingScreenDefinition.navigate(),
        accessPath: "digitalForecasting",
        icon: MdBatchPrediction,
      },
    ],
  },

  {
    name: ToolsRootDefinition.name,
    items: [
      {
        name: TalPreparationToolsDefinition.name,
        path: TalPreparationToolsDefinition.navigate(),
        accessPath: "talPreparationTools",
        icon: FaTools,
      },
      {
        name: ContactSearchDefinition.name,
        path: ContactSearchDefinition.navigate(),
        accessPath: "contactSearch",
        icon: MdPersonSearch,
      },
    ],
  },

  {
    name: AdminRootDefinition.name,
    items: [
      {
        name: UsersScreenDefinition.name,
        path: UsersScreenDefinition.navigate(),
        accessPath: "user",
        icon: MdPeople,
      },
      {
        name: PublishersScreenDefinition.name,
        path: PublishersScreenDefinition.navigate(),
        accessPath: "publisher",
        icon: MdEngineering,
      },
      {
        name: CompaniesScreenDefinition.name,
        path: CompaniesScreenDefinition.navigate(),
        accessPath: "company.edit",
        icon: MdApartment,
      },
      {
        name: BusinessEntitiesScreenDefinition.name,
        path: BusinessEntitiesScreenDefinition.navigate(),
        accessPath: "businessEntities",
        icon: MdOutlineCorporateFare,
      },
    ],
  },
];

export { linkGroups };
export type { LinkGroup };
