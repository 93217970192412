import { SortDirection } from "@intentsify/types";
import { FetchDataParams, Nullable } from "types";

export type PageTracking = {
  pageIdentifier: string;
  firstTimeSeen: Nullable<string>;
  lastTimeSeen: Nullable<string>;
};

type PageTrackingFilters = Record<never, never>;

export type PageTrackingFetchDataParams = FetchDataParams<
  keyof PageTracking,
  PageTrackingFilters
>;

export const defaultListPaginatedPageTrackingParams: PageTrackingFetchDataParams =
  {
    page: 1,
    per_page: 10,
    order_by: "pageIdentifier",
    order: SortDirection.DESC,
  };
