import { Route, Routes } from "react-router";
import { Navigate } from "react-router-dom";
import { CloneUser, CreateUser, EditUser, Users } from "screens";
import { CloneUserScreenDefinition } from "screens/Admin/screens/Users/screens/CloneUser/CloneUser.definition";
import { CreateUserScreenDefinition } from "screens/Admin/screens/Users/screens/CreateUser/CreateUser.definition";
import { EditUserScreenDefinition } from "screens/Admin/screens/Users/screens/EditUser/EditUser.definition";
import { UsersScreenDefinition } from "screens/Admin/screens/Users/screens/Users/Users.definition";

const UsersRouter = () => {
  return (
    <Routes>
      {UsersScreenDefinition.routePath.map((path) => (
        <Route key={path} path={path} element={<Users />} />
      ))}

      {CreateUserScreenDefinition.routePath.map((path) => (
        <Route key={path} path={path} element={<CreateUser />} />
      ))}

      {CloneUserScreenDefinition.routePath.map((path) => (
        <Route key={path} path={path} element={<CloneUser />} />
      ))}

      {EditUserScreenDefinition.routePath.map((path) => (
        <Route key={path} path={path} element={<EditUser />} />
      ))}

      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>
  );
};

export { UsersRouter };
