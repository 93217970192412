import {
  Center,
  Icon,
  Spinner,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { UserForList } from "@intentsify/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import { resetPasswordRequest } from "auth/screens/ResetPassword/components/ResetPasswordForm.requests";
import { Actions, Button, EmptyCell, Modal } from "components";
import { useConfirm } from "components/ConfirmDialog/useConfirm";
import { FiCopy, FiEdit3, FiLock, FiSend, FiTrash2 } from "react-icons/fi";
import { RiCheckboxCircleFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { ActionItems } from "types";
import { handleApiError } from "utils";
import { CloneUserScreenDefinition } from "../CloneUser/CloneUser.definition";
import { EditUserScreenDefinition } from "../EditUser/EditUser.definition";
import { removeUser as removeUserMutation } from "./Users.requests";

const ActionsCell = ({ id, email }: { id: number | string; email: string }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const queryClient = useQueryClient();
  const confirm = useConfirm();

  const toast = useToast();
  const navigate = useNavigate();
  const { mutate: removeUser, isLoading } = useMutation(removeUserMutation, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["listUsers"] });
      toast({
        title: "User removed successfully",
        status: "success",
      });
      onClose();
    },
    onError: (err) => {
      handleApiError(err, navigate, toast);
      onClose();
    },
  });
  const resetPassword = useMutation({
    mutationFn: async (data: { email: string }) => {
      if (
        !(await confirm({
          title: `Reset password`,
          description: `Are you sure you want to reset the password for ${data.email}? This action will send a reset link to the specified email.`,
          confirmText: "Yes, reset password",
        }))
      ) {
        return;
      }

      return await resetPasswordRequest({ email: data.email });
    },
    onSuccess: (data, variables) => {
      if (!data) {
        return;
      }

      toast({
        title: `Email with reset password link was sent to ${variables.email}.`,
        status: "success",
      });
    },
    onError: (err) => {
      handleApiError(err, navigate, toast);
    },
  });

  const items: ActionItems[] = [
    {
      label: CloneUserScreenDefinition.name,
      accessPath: "user.clone",
      icon: <FiCopy />,
      onClick: () =>
        navigate(CloneUserScreenDefinition.navigate({ id: Number(id) })),
    },
    {
      label: EditUserScreenDefinition.name,
      accessPath: "user.edit",
      icon: <FiEdit3 />,
      onClick: () =>
        navigate(EditUserScreenDefinition.navigate({ id: Number(id) })),
    },
    {
      label: resetPassword.isLoading ? "Sending email..." : "Reset Password",
      accessPath: "user.resetPassword",
      icon: (
        <Center position="relative">
          {resetPassword.isLoading && <Spinner w="3" h="3" />}
          {!resetPassword.isLoading && (
            <>
              <Icon as={FiLock} w="3" h="3" />
              <Icon
                as={FiSend}
                position="absolute"
                top="10px"
                left="1"
                w="3"
                h="3"
              />
            </>
          )}
        </Center>
      ),
      onClick: () => resetPassword.mutate({ email }),
    },
    {
      label: "Delete User",
      accessPath: "user.delete",
      can: "canDelete",
      icon: <FiTrash2 />,
      onClick: onOpen,
    },
  ];

  return (
    <>
      <Actions items={items} />
      <Modal
        title={"Delete user"}
        body={"Delete this user?"}
        secondaryButton={
          <Button aria-label="Cancel" onClick={onClose}>
            Cancel
          </Button>
        }
        primaryButton={
          <Button
            variant="warning"
            aria-label="Delete user"
            leftIcon={<FiTrash2 />}
            onClick={() => removeUser(id)}
            isLoading={isLoading}
          >
            Delete
          </Button>
        }
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  );
};

const userTableColumns: ColumnDef<UserForList>[] = [
  {
    header: "ID",
    accessorKey: "userId",
  },
  {
    header: "Name",
    accessorKey: "displayName",
  },
  {
    header: "Email",
    accessorKey: "email",
  },
  {
    header: "Company",
    accessorKey: "company",
    cell: ({ row }) => {
      return row.original?.company ? (
        <>{row.original.company}</>
      ) : (
        <EmptyCell />
      );
    },
  },
  {
    header: "Business Entity",
    accessorKey: "businessEntity",
    cell: ({ row }) => {
      return row.original?.businessEntity ? (
        <>{row.original.businessEntity}</>
      ) : (
        <EmptyCell />
      );
    },
  },
  {
    header: "Role",
    accessorKey: "saas_role",
    cell: ({ row }) => {
      return (
        <Text fontWeight={"bold"} textTransform="uppercase">
          {row.original?.saasRole}
        </Text>
      );
    },
  },
  {
    header: "Created Date",
    accessorKey: "createdAt",
  },
  {
    header: "Active",
    accessorKey: "isActive",
    cell: ({ row }) => {
      return row.original?.isActive ? (
        <Icon as={RiCheckboxCircleFill} w={6} h={6} color="green.500"></Icon>
      ) : (
        <EmptyCell />
      );
    },
  },
  {
    header: "Actions",
    cell: ({ row }) => {
      return row.original?.userId ? (
        <ActionsCell id={row.original?.userId} email={row.original.email} />
      ) : (
        <EmptyCell />
      );
    },
  },
];

export { userTableColumns };
