import { Divider, Flex, Heading, Stack } from "@chakra-ui/react";
import { ContactSearchFilters } from "@intentsify/types";
import { isPopulatedArray } from "@intentsify/utils";
import { Button, ViewContainer } from "components";
import { useState } from "react";
import { useScreen } from "utils";
import { ContactSearchDefinition } from "./ContactSearch.definition";
import { useIsLoadingContactSearch } from "./ContactSearch.hooks";
import { ContactSearchFilters as ContactSearchFiltersComponent } from "./components/ContactSearchFilters/ContactSearchFilters";
import { ContactSearchTable } from "./components/ContactSearchTable/ContactSearchTable";

const defaultContactSearchFilters: ContactSearchFilters = {
  domain: [],
  jobLevel: [],
  jobFunction: [],
  jobTitle: [],
  jobTitleKeyword: [],
  businessEmail: [],
  firstName: [],
  lastName: [],
};

export const ContactSearch = () => {
  useScreen(ContactSearchDefinition);

  const [filters, setFilters] = useState<ContactSearchFilters>(
    defaultContactSearchFilters
  );

  const [submittedFilters, setSubmittedFilters] = useState<
    ContactSearchFilters | undefined
  >();

  const isValid = isPopulatedArray(filters.domain);
  const isLoading = useIsLoadingContactSearch(submittedFilters);

  return (
    <ViewContainer>
      <Stack gap={2}>
        <Heading as="h2" size="sm" noOfLines={1}>
          Select search criteria
        </Heading>

        <ContactSearchFiltersComponent
          filters={filters}
          onChange={setFilters}
        />

        <Divider />

        <Flex mt={4} justifyContent="flex-end">
          <Button
            size="md"
            isDisabled={!isValid}
            isLoading={isLoading}
            onClick={() => setSubmittedFilters(filters)}
            variant="primary-teal"
          >
            Process Contact Search
          </Button>
        </Flex>

        <ContactSearchTable filters={submittedFilters} />
      </Stack>
    </ViewContainer>
  );
};
