import { Box, ListItem, OrderedList, Text } from "@chakra-ui/react";
import { AutodiscoveryFileUpload } from "@intentsify/types";

export const IncompleteUploadWarning = ({
  successfullyUploadedFiles,
  failedToUploadFiles,
}: {
  successfullyUploadedFiles: AutodiscoveryFileUpload[];
  failedToUploadFiles: AutodiscoveryFileUpload[];
}) => {
  return (
    <Box p={4}>
      <Text>There was an issue uploading file(s):</Text>
      <OrderedList marginY={4}>
        {failedToUploadFiles.map((file, index) => (
          <ListItem key={index}>{file.filename}</ListItem>
        ))}
      </OrderedList>

      {successfullyUploadedFiles.length > 0 && (
        <>
          <Text>Files uploaded successfully:</Text>
          <OrderedList marginY={4}>
            {successfullyUploadedFiles.map((file) => (
              <ListItem key={file.id}>{file.filename}</ListItem>
            ))}
          </OrderedList>
        </>
      )}
    </Box>
  );
};
