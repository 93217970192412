import { Flex } from "@chakra-ui/layout";
import React, { useCallback, useState } from "react";
import { MapLayerMouseEvent, Popup, PopupProps } from "react-map-gl";
import { Text, ListItem, UnorderedList } from "@chakra-ui/react";
import { getTopTopics } from "./utils";

export const useTopicsPointsPopup = () => {
  const [hoverInfo, setHoverInfo] = useState<
    { topics: string[]; longitude: number; latitude: number } | undefined
  >();
  const TopicsPopup = ({ offset }: Pick<PopupProps, "offset">) => {
    if (!hoverInfo || hoverInfo?.topics.length === 0) {
      return null;
    }

    return (
      <Popup
        offset={offset}
        longitude={hoverInfo.longitude}
        latitude={hoverInfo.latitude}
        closeButton={false}
        style={{
          width: "max-content",
          userSelect: "none",
          pointerEvents: "none",
        }}
      >
        <Flex py="1" px="2" fontSize="sm" color="gray.800" flexWrap="wrap">
          <Text width="100%">Topics: </Text>
          <UnorderedList>
            {hoverInfo.topics.map((topic, index) => (
              <ListItem key={index}>{topic}</ListItem>
            ))}
          </UnorderedList>
        </Flex>
      </Popup>
    );
  };

  const handleMouseMove = useCallback((event: MapLayerMouseEvent) => {
    const feature = event.features && event.features[0];

    const topics = feature?.properties?.topic as string | undefined;
    const topTopics = getTopTopics(topics, 3);

    setHoverInfo({
      longitude: event.lngLat.lng,
      latitude: event.lngLat.lat,
      topics: topTopics,
    });
  }, []);

  return { handleMouseMove, TopicsPopup };
};
