import { Td } from "@chakra-ui/react";
import { Cell as CellType, flexRender, RowData } from "@tanstack/react-table";

const Cell = <T extends RowData, V>({
  cell,
  isCentered = false,
}: {
  cell: CellType<T, V>;
  isCentered?: boolean;
}) => {
  const columnWidth = cell.column.getSize();
  return (
    <Td
      key={cell.id}
      style={{ minWidth: columnWidth !== 0 ? columnWidth : undefined }}
      textAlign={isCentered ? "center" : undefined}
    >
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </Td>
  );
};

export { Cell };
