import { Box, Flex, Text } from "@chakra-ui/layout";
import { CampaignImpressionsByLocation } from "@intentsify/types";
import { useCallback, useState } from "react";
import { MapLayerMouseEvent, Popup, PopupProps } from "react-map-gl";

export const useImpressionsPointsPopup = () => {
  const [hoverInfo, setHoverInfo] = useState<
    CampaignImpressionsByLocation | undefined
  >();
  const ImpressionsPopup = ({ offset }: Pick<PopupProps, "offset">) => {
    if (!hoverInfo?.count) {
      return null;
    }

    return (
      <Popup
        offset={offset}
        longitude={hoverInfo.longitude}
        latitude={hoverInfo.latitude}
        closeButton={false}
        style={{
          width: "max-content",
          userSelect: "none",
          pointerEvents: "none",
        }}
      >
        <Flex gap="1" py="1" px="2" fontSize="sm" color="gray.800">
          <Box fontWeight="semibold">{hoverInfo.count}</Box>
          <Text>{hoverInfo.count === 1 ? "impression" : "impressions"}</Text>
        </Flex>
      </Popup>
    );
  };

  const handleMouseMove = useCallback((event: MapLayerMouseEvent) => {
    const feature = event.features && event.features[0];

    setHoverInfo({
      longitude: event.lngLat.lng,
      latitude: event.lngLat.lat,
      count: feature?.properties?.sum || feature?.properties?.count,
    });
  }, []);

  return { handleMouseMove, ImpressionsPopup };
};
