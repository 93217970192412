import { SimpleGrid, SimpleGridProps } from "@chakra-ui/react";
import { WithChildren } from "types";

type GridProps = WithChildren & {
  minChildWidth?: SimpleGridProps["minChildWidth"];
};

const Grid = ({ children, minChildWidth = "550px" }: GridProps) => {
  return (
    <SimpleGrid
      spacing="2px"
      minChildWidth={minChildWidth}
      gridAutoRows="min-content"
      gridAutoFlow={"dense"}
      width="100%"
    >
      {children}
    </SimpleGrid>
  );
};

export { Grid };
