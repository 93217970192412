import { CreateAutomatedSegmentUploadScreen } from "types";
import { createScreen } from "utils/createScreen";

const AutomatedProgrammaticSegmentDefinition = createScreen({
  name: CreateAutomatedSegmentUploadScreen,
  path: "/media-activation/programmatic-segments/automated/create",
  routePath: ["/automated/create"],
  accessPath: "programmaticCampaign.programmaticDomain",
});

export { AutomatedProgrammaticSegmentDefinition };
