import { Item, Keyword, SignalSelectionSource } from "@intentsify/types";
import { FetchDataParamsWithToken } from "./FetchDataParams";

export type KeywordItem = Item<{ source: SignalSelectionSource }>;

export type PaginatedKeywordsParams = FetchDataParamsWithToken<keyof Keyword>;

export type VerifiedKeywords = { keyword: string; exists: boolean };

export enum SelectionMode {
  SET = "SET",
  SELECT = "SELECT",
  UNSELECT = "UNSELECT",
}
