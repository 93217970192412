import { Icon as CharkaIcon } from "@chakra-ui/icon";
import type { TusUpload } from "@intentsify/tus/dist/react";
import { formatBytes } from "@intentsify/utils";
import { ColumnDef } from "@tanstack/react-table";
import { IconButton } from "components";
import { useMemo } from "react";
import { FiDownload, FiTrash } from "react-icons/fi";
import { useTrackFileDownloaded } from "tracking/useTrackFileDownloaded";
import { TusUploadProgress } from "../../../components/Upload/TusUploadProgress";
import { makeDownloadCsvLink } from "../../../shared/hooks/useDownloadCsvLink";
import { targetPersonaUpload } from "../../../upload/targetPersonaUpload";
import {
  JUST_NOW_DATE,
  TargetPersonaBlocklistFile,
} from "./TargetPersonaBlocklist.types";

export const useTargetPersonaBlocklistColumns = (
  handleRowDelete: () => void
) => {
  const trackFileDownloaded = useTrackFileDownloaded();
  return useMemo(() => {
    const columns = [
      {
        header: "File name",
        accessorKey: "fileName",
      },
      {
        header: "Size",
        accessorKey: "size",
        cell: ({ row }) => {
          if (row.original.type === "local") {
            const [_downloadLink, blob] = makeDownloadCsvLink(
              row.original.data
            );
            return formatBytes(blob.size);
          }
          if (row.original.size !== null) {
            return formatBytes(row.original.size);
          }
          return null;
        },
      },
      {
        header: "Last Modified",
        accessorKey: "lastModified",
        cell: ({ row }) => {
          if (row.original.type === "local" || row.original.isUploading) {
            return null;
          }
          return row.original.lastModified === JUST_NOW_DATE
            ? row.original.lastModified
            : new Intl.DateTimeFormat("en-US", {
                dateStyle: "short",
                timeStyle: "short",
              }).format(row.original.lastModified);
        },
      },
      {
        id: "action-delete",
        size: 40,
        header: () => null,
        cell: ({ row }) => {
          if (
            row.original.type === "local" ||
            (row.original.type === "remote" &&
              (row.original.isUploading || row.original.isDeleting))
          ) {
            return null;
          }

          return (
            <IconButton
              tooltip="Delete file"
              icon={<CharkaIcon as={FiTrash} fontSize="md" />}
              onClick={() => handleRowDelete()}
            />
          );
        },
      },
      {
        id: "action-download",
        size: 40,
        header: () => null,
        cell: ({ row }) => {
          if (
            row.original.type === "remote" &&
            (row.original.isUploading || row.original.isDeleting)
          ) {
            return (
              <TusUploadProgress
                tusUpload={targetPersonaUpload as unknown as TusUpload}
              />
            );
          }
          const [downloadLink] =
            row.original.type === "local"
              ? makeDownloadCsvLink(row.original.data)
              : [row.original.downloadLink];

          return (
            <IconButton
              as="a"
              icon={<CharkaIcon as={FiDownload} fontSize="md" />}
              href={downloadLink}
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              onClick={() =>
                trackFileDownloaded({
                  label: "Target Persona Blocklist",
                  fileName: row.original.fileName,
                })
              }
              tooltip="Download file"
              download={row.original.fileName}
            />
          );
        },
      },
    ] satisfies ColumnDef<TargetPersonaBlocklistFile>[];

    return columns;
  }, [handleRowDelete, trackFileDownloaded]);
};
