import { Icon, Text } from "@chakra-ui/react";
import { AutomatedProgrammaticSegment, SegmentState } from "@intentsify/types";
import { getExportFileName, toNumberDisplayValue } from "@intentsify/utils";
import { ColumnDef } from "@tanstack/react-table";
import { Endpoints } from "api";
import { Actions, EmptyCell } from "components";
import { useMemo } from "react";
import { AiOutlineDownload } from "react-icons/ai";
import { FiEye } from "react-icons/fi";
import {
  RiCheckboxBlankCircleFill,
  RiCheckboxCircleFill,
} from "react-icons/ri";
import { VscDebugStart, VscDebugStop } from "react-icons/vsc";
import { Link, useNavigate } from "react-router-dom";
import { CampaignDetailsScreenDefinition } from "screens/Campaigns";
import { ActionItems } from "types";
import { useDownloadFile } from "utils";
import { EditAutomatedProgrammaticSegmentDefinition } from "../../../../EditAutomatedProgrammaticSegment/EditAutomatedProgrammaticSegment.definition";
import { statusColor } from "../../../ProgrammaticDomains.utils";
import { useStopOrStartAutomatedProgrammaticSegment } from "./AutomatedProgrammaticSegmentsTable.requests";

const ActionItemsComponent = ({
  fileName: originalFileName,
  id,
  isStopped,
}: {
  fileName: string;
  id: number;
  isStopped: boolean;
}) => {
  const navigate = useNavigate();
  const { download, getDownloadingStatus } = useDownloadFile();
  const { stopOrStartAutomatedProgrammaticSegment } =
    useStopOrStartAutomatedProgrammaticSegment();

  const fileName =
    originalFileName.split(".").shift() ??
    getExportFileName({
      data: {
        id,
      },
      extension: false,
      prefix: "automated_programmatic_segment",
    });

  const handleRedirect = () => {
    navigate(
      EditAutomatedProgrammaticSegmentDefinition.navigate({
        id,
      })
    );
  };

  const handleDownloadClick = (fileId: number) => {
    if (fileId) {
      download({
        url: Endpoints.AutomatedProgrammaticSegment.Get.DownloadAutomatedProgrammaticSegment(
          Number(fileId)
        ),
        fileName: fileName,
        fileExtension: "zip",
      });
    }
  };

  const items: ActionItems[] = [
    isStopped
      ? {
          label: "Start Automation",
          accessPath: "programmaticCampaign.programmaticDomain",
          icon: <VscDebugStart />,
          isDisabled: getDownloadingStatus(fileName) === "inProgress",
          onClick: () =>
            stopOrStartAutomatedProgrammaticSegment({ id, isStopped }),
          tootlipPlacement: "bottom-end",
        }
      : {
          label: "Stop Automation",
          accessPath: "programmaticCampaign.programmaticDomain",
          icon: <VscDebugStop />,
          isDisabled: getDownloadingStatus(fileName) === "inProgress",
          onClick: () =>
            stopOrStartAutomatedProgrammaticSegment({ id, isStopped }),
          tootlipPlacement: "bottom-end",
        },
    {
      label: "View Details",
      accessPath: "programmaticCampaign.programmaticDomain",
      icon: <FiEye />,
      onClick: handleRedirect,
    },
    {
      label: "Download CSV",
      accessPath: "programmaticCampaign.programmaticDomain",
      icon: <AiOutlineDownload />,
      isDisabled: getDownloadingStatus(fileName) === "inProgress",
      onClick: () => handleDownloadClick(id),
      tootlipPlacement: "bottom-end",
    },
  ];

  return <Actions items={items} />;
};

const useAutomatedSegmentsTableColumns = () => {
  return useMemo<ColumnDef<AutomatedProgrammaticSegment>[]>(
    () => [
      {
        header: "ID",
        accessorKey: "id",
      },
      {
        header: "Intent Model",
        accessorKey: "campaignId",
        cell: ({ row }) => {
          return (
            <Text
              as={Link}
              textDecoration="underline"
              cursor="pointer"
              textAlign="left"
              to={CampaignDetailsScreenDefinition.navigate({
                campaignId: row.original.campaignId,
                view: "weekly-snapshot",
              })}
            >
              {row.original.campaign}
            </Text>
          );
        },
      },
      {
        header: "Company",
        accessorKey: "company",
      },
      {
        header: "Tag",
        accessorKey: "tag",
        enableSorting: false,
      },
      {
        header: "Region",
        accessorKey: "region",
        enableSorting: false,
      },
      {
        header: "State",
        accessorKey: "state",
        enableSorting: false,
        cell: ({ row }) => {
          return row.original?.state !== SegmentState.STATE_UNSPECIFIED ? (
            <Text
              fontWeight="bold"
              textTransform={"uppercase"}
              color={statusColor(row.original.state)}
            >
              {row.original.state.replaceAll("_", " ")}
            </Text>
          ) : (
            <EmptyCell />
          );
        },
      },
      {
        header: "Segment Type",
        accessorKey: "segmentType",
        enableSorting: false,
        cell: ({ row }) => {
          return (
            <Text
              textTransform="uppercase"
              cursor="pointer"
              fontWeight={"bold"}
            >
              {row.original.segmentType}
            </Text>
          );
        },
      },
      {
        header: "Intentsify Count",
        accessorKey: "intentsifyCount",
        enableSorting: false,
        cell: ({ row }) => {
          return row.original?.intentsifyCount ? (
            toNumberDisplayValue(row.original?.intentsifyCount)
          ) : (
            <EmptyCell />
          );
        },
      },
      {
        header: "Beeswax Count",
        accessorKey: "beeswaxCount",
        enableSorting: false,
        cell: ({ row }) => {
          return row.original?.beeswaxCount ? (
            toNumberDisplayValue(row.original?.beeswaxCount)
          ) : (
            <EmptyCell />
          );
        },
      },
      {
        header: "Segment Uploads #",
        accessorKey: "segmentUploadsNumber",
        enableSorting: false,
      },
      {
        header: "Active",
        accessorKey: "isActive",
        enableSorting: false,
        cell: ({ row }) => {
          return row.original?.isActive ? (
            <Icon as={RiCheckboxCircleFill} w={6} h={6} color="green.500" />
          ) : (
            <Icon as={RiCheckboxBlankCircleFill} w={6} h={6} color="red.500" />
          );
        },
      },
      {
        header: "Created At",
        accessorKey: "createdDate",
      },
      {
        header: "Created By",
        accessorKey: "creatorDisplay",
      },
      {
        header: "End Date",
        accessorKey: "endDateIso",
      },
      {
        header: "Actions",
        enableSorting: false,
        cell: ({ row }) => {
          return row.original?.id ? (
            <ActionItemsComponent
              id={row.original?.id}
              fileName={row.original?.tag}
              isStopped={row.original?.isStopped}
            />
          ) : (
            <EmptyCell />
          );
        },
      },
    ],
    []
  );
};

export { useAutomatedSegmentsTableColumns };
