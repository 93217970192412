import { formatDateRange } from "@intentsify/utils";
import {
  TimeseriesItem,
  TimeseriesItemDataRecord,
  TimeseriesResponse,
} from "types";

export const timeseriesToCsvData = <
  K extends string,
  DK extends string,
  T extends TimeseriesResponse<TimeseriesItem<K, TimeseriesItemDataRecord<DK>>>
>(
  data: T | undefined,
  mainKey: K,
  dataKeyLabels: Record<DK, string>,
  dataFormatters?: Partial<Record<DK, (data: any) => string>>
) => {
  if (!data) {
    return [];
  }

  const dataKeys = data.data
    .map((i) => i.timeseries.map((s) => Object.keys(s.data)))
    .flat(2) as DK[];

  const formattedData: Record<string, string | number>[] = [];

  data.data.forEach((dataItem) => {
    const formatted: Record<string, any> = {};
    formatted[mainKey] = dataItem[mainKey];

    data.timeseries.forEach((seriesItem) => {
      const range = formatDateRange(seriesItem);

      dataKeys.forEach((dataKey) => {
        const dataKeyData = dataItem.timeseries.find(
          (d) => d.isoDate === seriesItem.start
        );

        const formatter = dataFormatters && dataFormatters[dataKey];

        if (formatter) {
          formatted[`${String(dataKeyLabels[dataKey])}: ${String(range)}`] =
            formatter(dataKeyData?.data[dataKey]);
        } else {
          formatted[`${String(dataKeyLabels[dataKey])}: ${String(range)}`] =
            dataKeyData?.data[dataKey];
        }
      });
    });

    formattedData.push(formatted);
  });

  return formattedData;
};
