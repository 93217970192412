import {
  Box,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  forwardRef,
  IconButton,
  Text,
  Textarea,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { TopUrl } from "@intentsify/types";
import { formatDate } from "@intentsify/utils";
import { Button, Popover } from "components";
import { useCallback, useEffect, useState } from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";

type TopUrlPopoverProps = {
  topUrl: TopUrl;
  disabled: boolean;
  campaignId: number;
  domainId: number;
  handleExclude: (
    campaignId: number,
    domainId: number,
    url: string,
    logoUrl: string,
    score: number,
    exclude: boolean,
    weekNumber: number,
    yearNumber: number,
    runningWeek: number,
    exclusionReason: string
  ) => void;
};

export const TopUrlPopover = ({
  topUrl,
  domainId,
  campaignId,
  handleExclude,
  disabled,
}: TopUrlPopoverProps) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [inputValue, setInputValue] = useState<string | undefined>();

  const isError = inputValue === "";

  const handleClose = useCallback(() => {
    setInputValue(undefined);
    onClose();
  }, [onClose]);

  useEffect(() => {
    setInputValue(topUrl.flagReason ?? undefined);
  }, [isOpen, topUrl.flagReason]);

  const handleSubmit = () => {
    if (
      !isError &&
      inputValue &&
      topUrl.weekNumber &&
      topUrl.yearNumber &&
      topUrl.runningWeek
    ) {
      handleExclude(
        campaignId,
        domainId,
        topUrl.url,
        topUrl.logourl,
        topUrl.score,
        !topUrl.isFlagged,
        topUrl.weekNumber,
        topUrl.yearNumber,
        topUrl.runningWeek,
        inputValue
      );
    }
    handleClose();
  };

  useEffect(() => {
    if (topUrl.isFlagged && topUrl.flagReason) {
      setInputValue(topUrl.flagReason);
    }

    return () => handleClose();
  }, [topUrl.isFlagged, topUrl.flagReason, handleClose]);

  const TopURLPopoverTrigger = forwardRef(
    ({ hasArrow, label, placement, ...other }, ref) => (
      <Tooltip hasArrow={hasArrow} label={label} placement={placement}>
        <IconButton
          disabled={disabled}
          ml={2}
          size="xs"
          aria-label={topUrl.isFlagged ? "Include URL" : "Exclude URL"}
          icon={topUrl.isFlagged ? <FiEyeOff /> : <FiEye />}
          ref={ref}
          {...other}
        />
      </Tooltip>
    )
  );

  return (
    <Popover
      width={400}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={handleClose}
      popoverTrigger={
        <TopURLPopoverTrigger
          hasArrow
          label={
            topUrl.isFlagged ? "Undo the URL exclusion" : "Exclude the URL"
          }
          placement="top"
        />
      }
      popoverHeader={<Text>Exclude or include an article from Top URLs.</Text>}
      popoverBody={
        <FormControl isInvalid={isError} p={3}>
          <FormLabel htmlFor="exclusionReason" size="xs" fontSize="sm">
            Reason for exclusion:
          </FormLabel>
          <Text color="gray.500" fontSize="sm" mt={3}></Text>
          <Textarea
            isRequired
            size="sm"
            disabled={topUrl.isFlagged}
            id="exclusionReason"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
          {isError && <FormErrorMessage>Reason is required.</FormErrorMessage>}
        </FormControl>
      }
      popoverFooter={
        <Flex flexDirection="row">
          <Box display={"flex"} justifyContent={"flex-start"}>
            <Text color="gray.500" fontSize="xs">
              Modified at:{" "}
              {topUrl.lastModificationDate
                ? formatDate({ date: new Date(topUrl.lastModificationDate) })
                : "-"}
            </Text>
          </Box>
          <Box flexGrow={1}></Box>
          <Box pl={2} pr={2} borderBottomRadius={4}>
            <ButtonGroup display="flex" justifyContent="flex-end">
              <Button size="xs" onClick={() => handleClose()}>
                Cancel
              </Button>
              <Button
                size="xs"
                type="submit"
                variant="primary"
                isDisabled={isError || inputValue === undefined}
                onClick={() => handleSubmit()}
              >
                {topUrl.isFlagged ? "Include URL" : "Exclude URL"}
              </Button>
            </ButtonGroup>
          </Box>
        </Flex>
      }
      showPopoverCloseButton={true}
    />
  );
};
