import { LoginScreenDefinition } from "auth";
import { PropsWithChildren, useEffect } from "react";
import { Outlet } from "react-router";
import { useNavigate } from "react-router-dom";
import { useUser } from "store/store.hooks";

const RequireAuth = ({ children }: PropsWithChildren) => {
  const user = useUser();

  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate(LoginScreenDefinition.navigate());
    }
  }, [user, navigate]);

  return children ? <>{children}</> : <Outlet />;
};

export { RequireAuth };
