import { useResetTrendingKeywordsState } from "./components/TrendingKeywords/TrendingKeywords.state";
import { useResetTrendingTopicsState } from "./components/TrendingTopics/TrendingTopics.state";

export const useResetState = () => {
  const resetTopics = useResetTrendingTopicsState();
  const resetKeywords = useResetTrendingKeywordsState();

  return () => {
    resetTopics();
    resetKeywords();
  };
};
