import { Flex } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import { Search, Table } from "components";
import { useCallback, useMemo, useState } from "react";
import { useTrackSearched } from "tracking/useTrackSearched";
import { listPaginatedPageTracking } from "../PageTracking.requests";
import {
  PageTracking,
  PageTrackingFetchDataParams,
  defaultListPaginatedPageTrackingParams,
} from "../PageTracking.types";
import { pageTrackingTableColumns } from "./PageTracking.columns";

const PageTrackingTable = () => {
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);

  const columns = useMemo<ColumnDef<PageTracking>[]>(
    () => pageTrackingTableColumns,
    []
  );

  const [fetchDataParams, setFetchDataParams] =
    useState<PageTrackingFetchDataParams>(
      defaultListPaginatedPageTrackingParams
    );

  const { data, isFetching } = useQuery(
    ["pageTrackingList", fetchDataParams],
    () => listPaginatedPageTracking(fetchDataParams)
  );

  const handleOnFetchDataChange = useCallback(
    (params: PageTrackingFetchDataParams) => {
      setFetchDataParams(params);
    },
    []
  );

  const trackSearched = useTrackSearched();

  return (
    <>
      <Flex>
        <Search
          currentValue={searchValue}
          onSearch={(v) => {
            setSearchValue(v);
            trackSearched({ term: v, collocation: "Page tracking table" });
          }}
        />
      </Flex>
      <Table
        variant="intentsifyNew"
        searchValue={searchValue}
        defaultSort={[{ id: "pageIdentifier", desc: true }]}
        columns={columns}
        isFetching={isFetching}
        data={data?.results || []}
        meta={data?.meta}
        onFetchDataChange={handleOnFetchDataChange}
        resetPaginationDependencies={[searchValue]}
      />
    </>
  );
};

export { PageTrackingTable };
