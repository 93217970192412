import { ColorModeScript } from "@chakra-ui/color-mode";
import {
  CaptureConsole as CaptureConsoleIntegration,
  ExtraErrorData as ExtraErrorDataIntegration,
} from "@sentry/integrations";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { router } from "App.router";
import { DateTime, Settings } from "luxon";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { intentsifyTheme } from "theme";
import { ampli } from "tracking/amplitude";
import { HttpStatus, isCustomApiError } from "types";

Settings.throwOnInvalid = false;

const ignoredStatusCodes = [HttpStatus.UNAUTHORIZED, HttpStatus.CONFLICT];

Sentry.init({
  environment: "production",
  release: DateTime.now().toUTC().toISO(),
  dsn: process.env.VITE_REACT_APP_SENTRY_DSN,
  integrations: [
    new Integrations.BrowserTracing(),
    new CaptureConsoleIntegration({
      levels: ["error", "warn"],
    }),
    new ExtraErrorDataIntegration({ depth: 6 }),
    new Sentry.Replay({
      networkDetailAllowUrls: [
        window.location.origin,
        `https://api-production.app.intentsify.io/api`,
      ],
    }),
  ],
  tracesSampleRate: 0.5,
  sampleRate: 0.75,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  autoSessionTracking: true,
  debug: false,
  attachStacktrace: true,
  normalizeDepth: 5,
  ignoreErrors: [
    // Random plugins/extensions
    "top.GLOBALS",
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    "originalCreateNotification",
    "canvas.contentDocument",
    "MyApp_RemoveAllHighlights",
    "http://tt.epicplay.com",
    "Can't find variable: ZiteReader",
    "jigsaw is not defined",
    "ComboSearch is not defined",
    "http://loading.retry.widdit.com/",
    "atomicFindClose",
    // Facebook borked
    "fb_xd_fragment",
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    "bmi_SafeAddOnload",
    "EBCallBackMessageReceived",
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    "conduitPage",
    "Stylesheet could not be loaded:",
    "Amplitude Logger [Error]: Failed to fetch",
    "Amplitude Logger [Error]: Converting circular structure to JSON",
    "Amplitude Logger [Error]: NetworkError when attempting to fetch resource.",
  ],
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
  /**
   * We want to filter out some of the events before sending to Sentry.
   */
  beforeSend: (event, hint) => {
    if (
      hint &&
      isCustomApiError(hint.originalException) &&
      ignoredStatusCodes.includes(hint.originalException.response.statusCode)
    ) {
      return null;
    }

    return event;
  },
});

if (process.env.NODE_ENV === "production") {
  ampli.load({
    environment: "default",
    client: { configuration: { minIdLength: 1 } },
  });
}

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);
root.render(
  <StrictMode>
    <ColorModeScript
      initialColorMode={intentsifyTheme.config.initialColorMode}
    />
    <RouterProvider router={router} />
  </StrictMode>
);
