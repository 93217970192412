import { Heading, Stack } from "@chakra-ui/react";
import { GenericUserJobRecord, GenericUserJobType } from "@intentsify/types";
import { useQuery } from "@tanstack/react-query";
import { Table } from "components";
import { PaginationType } from "components/Table/Table/Table.types";
import { useCallback, useState } from "react";
import { useColumns } from "./ResultsTable.columns";
import {
  defaultParams,
  listPaginatedTalToolsRecords,
  ListPaginatedTalToolsRecordsParams,
} from "./ResultsTable.requests";

const ResultsTable = ({ toolType }: { toolType: GenericUserJobType }) => {
  const tableColumns = useColumns();

  const [fetchDataParams, setFetchDataParams] =
    useState<ListPaginatedTalToolsRecordsParams>(defaultParams);

  const handleOnFetchDataChange = useCallback(
    (params: ListPaginatedTalToolsRecordsParams) => {
      setFetchDataParams(params);
    },
    []
  );

  const { data, isFetching } = useQuery(
    ["talTools", toolType, fetchDataParams],
    () => listPaginatedTalToolsRecords({ ...fetchDataParams, type: toolType })
  );

  return (
    <Stack gap={4}>
      <Heading as="h2" size="sm" noOfLines={1}>
        Processing History
      </Heading>

      <Table<GenericUserJobRecord>
        paginationType={PaginationType.TOKEN}
        nextPageToken={data?.nextPageToken}
        isFetching={isFetching}
        onFetchDataChange={handleOnFetchDataChange}
        columns={tableColumns}
        data={data?.files ?? []}
        stretch
        overflow={"hidden"}
        defaultSort={[
          {
            id: "createdAt",
            desc: false,
          },
        ]}
      />
    </Stack>
  );
};

export { ResultsTable };
