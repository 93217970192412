import { Box, Flex, Icon, Text, useColorModeValue } from "@chakra-ui/react";
import { isPopulatedArray } from "@intentsify/utils";
import mapKeys from "lodash/mapKeys";
import upperFirst from "lodash/upperFirst";
import * as Papa from "papaparse";
import { useEffect, useState } from "react";
import { AiOutlineDownload } from "react-icons/ai";
import { FaFileCsv } from "react-icons/fa";
import { useTrackFileDownloaded } from "tracking/useTrackFileDownloaded";
import { Badge } from "../Badge";
import { IconButton, IconButtonProps } from "../IconButton";
import { TooltipProps } from "../Tooltip";

type CommonProps = {
  fileName: string;
  data: Array<Record<string, string | number | undefined | null>>;
  downloadLabel: string;
  iconSize?: IconButtonProps["size"];
  tooltipPlacement?: TooltipProps["placement"];
};

type WithLabelProps =
  | {
      withLabel?: false;
      subject?: never;
      subjectPlural?: never;
    }
  | {
      withLabel: true;
      subject: string;
      subjectPlural: string;
    };

type DataToCsvDownloadProps = CommonProps & WithLabelProps;

const DataToCsvDownload = ({
  fileName,
  data,
  downloadLabel,
  iconSize = "sm",
  withLabel,
  subject,
  subjectPlural,
  tooltipPlacement = "bottom-end",
}: DataToCsvDownloadProps) => {
  const [downloadLink, setDownloadLink] = useState("");
  const csvIconColor = useColorModeValue("gray.600", "gray.200");
  const trackFileDownloaded = useTrackFileDownloaded();

  useEffect(() => {
    const dataWithUppercaseKeys = data.map((item) =>
      mapKeys(item, (_, key) => upperFirst(key))
    );

    const csv = Papa.unparse(dataWithUppercaseKeys);
    const blob = new Blob([csv], { type: "text/plain" });
    setDownloadLink(window.URL.createObjectURL(blob));
  }, [data]);

  const isPopulated = isPopulatedArray(data);

  const icon = (
    <IconButton
      variant="solid"
      isDisabled={!isPopulated}
      as="a"
      download={fileName}
      tooltip={`Download: "${fileName}"`}
      icon={<AiOutlineDownload />}
      size={iconSize}
      onClick={() => {
        trackFileDownloaded({ label: downloadLabel, fileName });
      }}
      tooltipPlacement={tooltipPlacement}
      {...(isPopulated && {
        href: downloadLink,
      })}
    />
  );

  if (!withLabel) {
    return icon;
  }

  return (
    <Flex alignItems="center">
      <Icon color={csvIconColor} w={6} h={6} mr={2} as={FaFileCsv} />
      <Badge>
        <Box
          maxW={{ base: "300px", xl: "100%" }}
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
        >
          {fileName}
        </Box>
      </Badge>
      <Text
        whiteSpace="nowrap"
        fontWeight="normal"
        ml={2}
        colorScheme="gray"
        fontSize="xs"
      >
        {`${data.length} ${data.length === 1 ? subject : subjectPlural}`}
      </Text>
      <Box ml={2}>{icon}</Box>
    </Flex>
  );
};

export { DataToCsvDownload };
