import { Box, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import { isDefined } from "@intentsify/utils";
import { ReactNode } from "react";
import { CustomApiError } from "./CustomApiError";
import { appErrors, getAppError } from "./errors";

export const formatApiError = (
  error: CustomApiError,
  title = `Something went wrong. Our engineers have been notified about the
  problem.`
): ReactNode => {
  if (error.response.internalCode) {
    return getAppError(error.response.internalCode);
  }

  const messages = [error.response.message].flat().filter(isDefined);

  if (messages.length === 0) {
    messages.push("Unknown error");
  }

  const mapped = messages.map((m) => {
    if (appErrors[m]) {
      return appErrors[m];
    }

    return m;
  });

  return (
    <>
      <Text fontWeight="bold" fontSize="md" mb={3}>
        {title}
      </Text>

      <Text as="div" fontWeight="600">
        {mapped.length === 1 && <>Error:</>}
        {mapped.length > 1 && <>Errors ({mapped.length}):</>}
        <Box maxHeight={300} overflowY="auto">
          <UnorderedList fontSize="sm">
            {mapped.map((e) => (
              <ListItem lineHeight="1.3" mb={1} key={e}>
                {e}
              </ListItem>
            ))}
          </UnorderedList>
        </Box>
      </Text>
    </>
  );
};
