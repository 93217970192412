import {
  Box,
  Center,
  Divider,
  Flex,
  HStack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useHasAccessTo } from "@intentsify/authorization/dist/react";
import { WeeklySnapshotDTO, WeeklySnapshotRangeDTO } from "@intentsify/dto";
import { CampaignStatus } from "@intentsify/types";
import { ButtonGroup, ErrorBoundary, Loader, ViewContainer } from "components";
import { memoize } from "lodash";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import {
  CampaignEnded,
  CampaignSelector,
  NoEntitySelected,
  SelectedCampaign,
} from "shared/components";
import { useCampaignBrief } from "shared/components/campaign/Campaign.state";
import { useScreen } from "utils";
import { WeeklySnapshotScreenDefinition } from "./WeeklySnapshot.definition";
import {
  AccountAnalysis,
  CreativeAndImpressions,
  Filters,
  SectionHeader,
  TopicsAndContentAnalysis,
} from "./components";
import { RequestReportButton } from "./components/RequestReportButton/RequestReportButton";

export enum WeeklySnapshotView {
  OverallPerformance = "overall-performance",
  StrategicWest = "strategic-west",
  StrategicEast = "strategic-east",
  GrowthAccounts = "growth-accounts",
}

const getMaxEndDate = (campaignEndDate: string | undefined) => {
  const now = DateTime.now();
  const maxEndDate = campaignEndDate ? DateTime.fromISO(campaignEndDate) : now;
  return maxEndDate.valueOf() < now.valueOf()
    ? maxEndDate
    : now.minus({ weeks: 1 });
};

const buildCampaignFilters = memoize(
  (
    campaignCreateDate: string,
    campaignEndDate: string | undefined
  ): WeeklySnapshotRangeDTO => {
    const defaultStartWeek = {
      start: DateTime.fromISO(campaignCreateDate).startOf("week").toJSDate(),
      end: DateTime.fromISO(campaignCreateDate).endOf("week").toJSDate(),
    };
    const endWeekDate = getMaxEndDate(campaignEndDate);
    const defaultEndWeek = {
      start: endWeekDate.startOf("week").toJSDate(),
      end: endWeekDate.endOf("week").toJSDate(),
    };

    return {
      weekFrom: DateTime.fromJSDate(defaultStartWeek.start).weekNumber,
      yearFrom: DateTime.fromJSDate(defaultStartWeek.start).year,
      weekTo: DateTime.fromJSDate(defaultEndWeek.start).weekNumber,
      yearTo: DateTime.fromJSDate(defaultEndWeek.start).year,
    };
  }
);

const WeeklySnapshot = () => {
  const navigate = useNavigate();
  const { campaignId: rawCampaignId, view } = useParams<
    "campaignId" | "view"
  >();
  const campaignId = Number(rawCampaignId);
  const [selected, setSelected] = useState<SelectedCampaign | undefined>(
    undefined
  );
  const selectedView =
    (view as WeeklySnapshotView | undefined) ||
    WeeklySnapshotView.OverallPerformance;

  const { campaignBrief } = useCampaignBrief(campaignId);
  const hasAccessToDownload = useHasAccessTo("campaign.download");

  const [filters, setFilters] = useState<WeeklySnapshotRangeDTO | undefined>(
    undefined
  );

  const campaignCreateDate =
    selected?.createdAt ?? campaignBrief?.campaignCreateDate;
  const campaignEndDate =
    selected?.campaignEndDate ?? campaignBrief?.campaignEndDate;

  useEffect(() => {
    if (!campaignId) {
      setSelected(undefined);
    } else if (campaignBrief) {
      setSelected((currentlySelected) => {
        if (
          currentlySelected?.value !== campaignId ||
          !currentlySelected?.label
        ) {
          return {
            value: campaignBrief.campaignId,
            label: campaignBrief.campaignName,
            campaignStatusId: campaignBrief.campaignStatusId,
            campaignEndDate: campaignBrief.campaignEndDate,
            createdAt: campaignBrief.campaignCreateDate,
          };
        }

        return currentlySelected;
      });
    }
  }, [campaignBrief, campaignId]);

  useEffect(() => {
    if (!campaignCreateDate) {
      return;
    }

    setFilters(buildCampaignFilters(campaignCreateDate, campaignEndDate));
  }, [campaignCreateDate, campaignEndDate, campaignId]);

  const onFiltersChange = (changedFilters: WeeklySnapshotDTO) => {
    if (!campaignCreateDate) {
      return;
    }

    setFilters({
      ...buildCampaignFilters(campaignCreateDate, campaignEndDate),
      ...changedFilters,
    });
  };

  const topbarColor = useColorModeValue("white", "brand.375");
  const onCampaignChange = (c: SelectedCampaign | undefined) => {
    setSelected(c);

    if (!c || !c.createdAt) {
      navigate(
        WeeklySnapshotScreenDefinition.navigate({
          campaignId: undefined,
          view: undefined,
        })
      );
    } else {
      setFilters(buildCampaignFilters(c.createdAt, c.campaignEndDate));
      navigate(
        WeeklySnapshotScreenDefinition.navigate({
          campaignId: Number(c.value),
          view: selectedView,
        })
      );
    }
  };

  useScreen(WeeklySnapshotScreenDefinition);

  const noCampaignSelected = Number.isNaN(campaignId);
  const campaignName = selected?.label || campaignBrief?.campaignName;
  const campaignStatusId =
    selected?.campaignStatusId || campaignBrief?.campaignStatusId;

  return (
    <ViewContainer noPadding withBackground stretch>
      <Flex p={4} flexDir="column" bg={topbarColor}>
        <Box maxWidth="1610px" width="full">
          <Flex justifyContent="space-between" alignItems="center">
            <Flex>
              <Text fontWeight={"bold"}>
                {campaignName || "Overall Weekly Snapshot"}
              </Text>
              {campaignStatusId === CampaignStatus.Ended && <CampaignEnded />}
            </Flex>
            <Flex alignItems="center">
              <Text fontSize="sm" mr={2} opacity="0.8" ml={1} fontWeight="bold">
                Intent Models
              </Text>
              <HStack>
                <CampaignSelector
                  selected={selected}
                  onChange={onCampaignChange}
                />
                <Center height="32px">
                  <Divider orientation="vertical" />
                </Center>
                {hasAccessToDownload && typeof filters !== "undefined" && (
                  <RequestReportButton
                    campaignId={campaignId}
                    isCampaignSelected={!noCampaignSelected}
                    filters={filters}
                  />
                )}
              </HStack>
            </Flex>
          </Flex>
        </Box>
      </Flex>
      <Box mt={0.5} px={2} bg={topbarColor}>
        {!noCampaignSelected && filters && (
          <Filters
            selectedFilters={filters}
            onChange={onFiltersChange}
            minDate={campaignBrief?.campaignCreateDate}
            maxDate={campaignEndDate}
          />
        )}

        {!noCampaignSelected && filters && (
          <ButtonGroup
            selectedValue={selectedView}
            setSelectedValue={(view) => {
              navigate(
                WeeklySnapshotScreenDefinition.navigate({
                  campaignId: campaignId,
                  view,
                })
              );
            }}
            items={[
              {
                label: "Overall Performance",
                value: WeeklySnapshotView.OverallPerformance,
              },
            ]}
          />
        )}
      </Box>

      <Box px={4} maxWidth="1640px">
        {!noCampaignSelected && filters && campaignBrief ? (
          <>
            <SectionHeader>Account Analysis</SectionHeader>
            <ErrorBoundary>
              <AccountAnalysis campaignId={campaignId} filters={filters} />
            </ErrorBoundary>

            <SectionHeader>Content and Topic Analysis</SectionHeader>
            <ErrorBoundary>
              <TopicsAndContentAnalysis
                campaignId={campaignId}
                filters={filters}
              />
            </ErrorBoundary>

            <SectionHeader>
              Display and Lead Generation Overview
              <Text fontStyle="italic" fontSize="sm" fontWeight="normal" as="p">
                All visual components below represent data campaign to date
              </Text>
            </SectionHeader>

            <ErrorBoundary>
              <CreativeAndImpressions
                campaignId={campaignId}
                filters={filters}
              />
            </ErrorBoundary>
          </>
        ) : noCampaignSelected ? (
          <NoEntitySelected entityName="campaign" />
        ) : (
          <Loader />
        )}
      </Box>
    </ViewContainer>
  );
};

export { WeeklySnapshot };
