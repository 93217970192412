import { Box, Flex, HStack, Text, useColorModeValue } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { ampli } from "tracking/amplitude";
import { Slide } from "./Carousel.types";

type Props = {
  slides: Slide[];
  /**
   * Used for tracking purposes.
   *
   * @example
   * Top Creative Performance
   */
  collocation: string;
  height?: string;
  maxWidth?: string;
  isSlidesCounterVisible?: boolean;
};

const Carousel = ({
  slides,
  collocation,
  height = "200px",
  maxWidth = "full",
  isSlidesCounterVisible = false,
}: Props) => {
  const textColor = useColorModeValue("gray.600", "gray.200");
  const arrowsBackgroundColor = useColorModeValue("gray.100", "brand.200");
  const arrowsBackgroundColorHover = useColorModeValue("gray.50", "brand.100");
  const currentSlideBackgroundColor = useColorModeValue(
    "brand.100",
    "brand.200"
  );

  const arrowStyles = {
    cursor: "pointer",
    position: "absolute",
    top: "50%",
    width: "35px",
    height: "35px",
    marginTop: "-22px",
    padding: "16px",
    color: textColor,
    fontWeight: "bold",
    fontSize: "18px",
    transition: "0.6s ease",
    borderRadius: "50%",
    userSelect: "none",
    backgroundColor: arrowsBackgroundColor,
    _hover: {
      backgroundColor: arrowsBackgroundColorHover,
    },
    display: "flex",
    alignItems: "center",
  } as const;

  const [currentSlide, setCurrentSlide] = useState(0);

  const slidesCount = slides.length;

  const prevSlide = () => {
    setCurrentSlide((s) => (s === 0 ? slidesCount - 1 : s - 1));
  };

  const nextSlide = () => {
    setCurrentSlide((s) => (s === slidesCount - 1 ? 0 : s + 1));
  };

  const setSlide = (slide: number) => {
    setCurrentSlide(slide);
  };

  useEffect(() => {
    ampli.carouselSlideChanged({ collocation, slideIndex: currentSlide });
  }, [collocation, currentSlide]);

  const carouselStyle = {
    transition: "all .5s",
    ml: `-${currentSlide * 100}%`,
  };

  return (
    <Flex
      maxW={maxWidth}
      padding={0}
      alignItems="center"
      justifyContent="center"
      flexWrap="wrap"
    >
      <Flex width="full" position="relative" overflow="hidden">
        {isSlidesCounterVisible && slidesCount > 1 && (
          <Text
            color={textColor}
            fontSize="xs"
            padding="8px 12px"
            position="absolute"
            top="0"
          >
            {currentSlide + 1} / {slidesCount}
          </Text>
        )}
        <Flex height={height} width="full" {...carouselStyle}>
          {slides.map((slide, sid) => (
            <Box key={`slide-${sid}`} boxSize="full" flex="none">
              <Box
                height="80%"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                {slide.content}
              </Box>
              <Flex
                padding="8px 12px"
                textAlign="center"
                width="full"
                marginBottom="8"
                color={textColor}
                height="20%"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
              >
                {slide.label && (
                  <Text
                    fontSize="2xl"
                    dangerouslySetInnerHTML={{ __html: slide.label }}
                  />
                )}
                {slide.description}
              </Flex>
            </Box>
          ))}
        </Flex>
        {slidesCount > 1 && (
          <>
            <Text
              {...arrowStyles}
              justifyContent="center"
              left="0"
              onClick={prevSlide}
            >
              &#10094;
            </Text>
            <Text
              {...arrowStyles}
              right="0"
              onClick={nextSlide}
              justifyContent="center"
            >
              &#10095;
            </Text>
          </>
        )}
      </Flex>
      {slidesCount > 1 && (
        <HStack justify="center" height={50}>
          {Array.from({ length: slidesCount }).map((_, slide) => (
            <Box
              key={`dots-${slide}`}
              cursor="pointer"
              boxSize={["7px", null, "15px"]}
              margin="0 2px"
              backgroundColor={
                currentSlide === slide
                  ? currentSlideBackgroundColor
                  : "gray.200"
              }
              rounded="50%"
              display="inline-block"
              transition="background-color 0.6s ease"
              _hover={{ bg: currentSlideBackgroundColor }}
              onClick={() => setSlide(slide)}
            ></Box>
          ))}
        </HStack>
      )}
    </Flex>
  );
};

export { Carousel };
