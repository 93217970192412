import { useQuery } from "@tanstack/react-query";
import { Card, MIN_MODAL_CONTENT_HEIGHT, PieChart } from "components";
import { useMemo, useState } from "react";
import { DisplayPerformanceFilters } from "screens";
import {
  ChartExport,
  useExportableChart,
} from "../../../../../../../../shared/components";
import { getMobileDeliveryBreakdown } from "./MobileDeliveryBreakdown.requests";

type MobileDeliveryBreakdownProps = {
  campaignId: string;
  filters: DisplayPerformanceFilters;
};

export const MobileDeliveryBreakdown = ({
  campaignId,
  filters,
}: MobileDeliveryBreakdownProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { data: rawData, isFetching } = useQuery({
    queryKey: ["getMobileDeliveryBreakdown", campaignId, filters],
    queryFn: async () => await getMobileDeliveryBreakdown(campaignId, filters),
  });

  const data = useMemo(() => {
    if (isFetching || !rawData?.mobileDeliveryBreakdown) {
      return [];
    }

    return rawData.mobileDeliveryBreakdown.map((platform) => ({
      label: platform.platformName,
      value: platform.impressions,
    }));
  }, [rawData, isFetching]);

  const chart = useExportableChart({
    title: "Mobile Delivery Breakdown",
    campaignId,
    chart: (
      <PieChart
        minHeight={isExpanded ? MIN_MODAL_CONTENT_HEIGHT : undefined}
        stretch
        legendPosition="bottom"
        data={data ?? []}
        displayAsPercent={true}
      />
    ),
  });

  return (
    <Card
      onExpandedChange={setIsExpanded}
      isLoading={isFetching}
      title={chart.title}
      hasData={!isFetching && Boolean(data?.length)}
      isExpandable
      actions={
        <ChartExport
          size="small"
          campaignId={campaignId}
          title={chart.title}
          onExportPNG={chart.downloadAsPng}
          data={data}
        />
      }
    >
      {chart.component}
      {chart.exportComponent}
    </Card>
  );
};
