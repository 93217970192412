import { Flex, useToast } from "@chakra-ui/react";
import { useHasAccessTo } from "@intentsify/authorization/dist/react";
import { ListCampaignsRO } from "@intentsify/dto";
import { CampaignForList, RoomEventType, SocketRoom } from "@intentsify/types";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { RowSelectionState } from "@tanstack/react-table";
import {
  ListPaginatedCampaignsParams,
  defaultListPaginatedCampaignsParams,
  listPaginatedCampaigns,
} from "api";
import {
  Button,
  LabeledSwitch,
  Search,
  Table as TableComponent,
  useRowSelection,
} from "components";
import { PaginationType } from "components/Table/Table/Table.types";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router";
import { CampaignActionsItems } from "shared/components";
import { useUser } from "store/store.hooks";
import { useTrackSearched } from "tracking/useTrackSearched";
import { HttpStatus } from "types";
import { handleApiError, shouldRetryUnlessStatus } from "utils";
import { useRoomEvent } from "webSocket";
import { CreateCampaignScreenDefinition } from "../../CampaignsWizard";
import {
  FavoriteStateChange,
  updateCampaignFavoriteStatus,
} from "../YourCampaigns.requests";
import { useCampaignsTableColumns } from "./campaignsTable.columns";
import { BulkActions, Stats } from "./components";

const CampaignsTable = () => {
  const queryClient = useQueryClient();
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const hasAccessToDraftCampaigns = useHasAccessTo("campaign.edit");

  const [isFavoritesFilterEnabled, setIsFavoritesFilterEnabled] =
    useState<boolean>(false);
  const [fetchDataParams, setFetchDataParams] =
    useState<ListPaginatedCampaignsParams>(defaultListPaginatedCampaignsParams);

  const user = useUser();

  const navigate = useNavigate();
  const toast = useToast();

  const campaignsQueryKey = [
    "campaignsList",
    fetchDataParams,
    user,
    hasAccessToDraftCampaigns,
  ];

  const { data, isFetching } = useQuery({
    queryKey: campaignsQueryKey,
    queryFn: () => {
      const params: ListPaginatedCampaignsParams = {
        ...fetchDataParams,
        includeCampaignsInDraft: hasAccessToDraftCampaigns,
      };

      return listPaginatedCampaigns(params);
    },
    staleTime: 0,
    refetchOnWindowFocus: false,
  });

  useRoomEvent(
    SocketRoom.MY_INTENT_MODELS,
    RoomEventType.LIST_CAMPAIGNS_STATE_UPDATE,
    (event) => {
      if (
        data?.campaigns?.find((i) => i.campaignId === event.data.campaignId)
      ) {
        const previousCampaignState =
          queryClient.getQueryData<ListCampaignsRO>(campaignsQueryKey);

        if (previousCampaignState) {
          queryClient.setQueryData(campaignsQueryKey, {
            ...previousCampaignState,
            campaigns: previousCampaignState.campaigns.map((c) => {
              if (c.campaignId === event.data.campaignId) {
                return {
                  ...c,
                  state: event.data.campaignState,
                };
              }
              return c;
            }),
          });
        }
      }
    }
  );

  const handleOnFetchDataChange = useCallback(
    (params: ListPaginatedCampaignsParams) => {
      setFetchDataParams({
        ...params,
        isFavorite: isFavoritesFilterEnabled,
      });
    },
    [isFavoritesFilterEnabled]
  );

  const handleRedirectToCreateCampaign = () => {
    navigate(
      CreateCampaignScreenDefinition.navigate({
        campaignId: undefined,
        step: 1,
      })
    );
  };

  const updateCampaignsAfterCampaignFavoriteStatusUpdate = (
    campaigns: CampaignForList[],
    campaignId: number,
    isFavorite: boolean
  ) => {
    const updatedCampaigns = [...campaigns];
    const recordIndex = updatedCampaigns.findIndex(
      (campaign) => campaign.campaignId === campaignId
    );
    if (recordIndex > -1) {
      updatedCampaigns.splice(recordIndex, 1, {
        ...updatedCampaigns[recordIndex],
        isFavorite: isFavorite,
      });
    }

    return updatedCampaigns;
  };

  const { mutate: updateCampaignFavoriteStatusMutation } = useMutation(
    updateCampaignFavoriteStatus,
    {
      onMutate: (data: FavoriteStateChange) => {
        const campaigns = queryClient.getQueryData(
          campaignsQueryKey
        ) as ListCampaignsRO;

        queryClient.setQueryData(
          campaignsQueryKey,
          (oldData?: ListCampaignsRO) => {
            return {
              ...oldData,
              campaigns: updateCampaignsAfterCampaignFavoriteStatusUpdate(
                oldData?.campaigns || [],
                data.campaignId,
                data.isFavorite
              ),
            };
          }
        );

        return {
          previousCampaigns: campaigns,
        };
      },
      onError: (err, data, context) => {
        handleApiError(err, navigate, toast);
        if (context) {
          queryClient.setQueryData(
            campaignsQueryKey,
            context.previousCampaigns
          );
        }
      },
      onSettled: () => {
        /*
         * we have to invalidate queries by "main" prefixes, since changing status on "show favorites" enabled should also cause invalidation
         * of queries for "show favorites" disabled scenario (to ensure that campaigns favorite statuses are up-to-date)
         * */
        void queryClient.invalidateQueries({ queryKey: ["campaignsList"] });
        void queryClient.invalidateQueries({
          queryKey: ["userCampaignsSummary"],
        });
      },
      retry: shouldRetryUnlessStatus(HttpStatus.UNPROCESSABLE_ENTITY),
    }
  );

  const trackSearched = useTrackSearched();
  const campaignsTableColumns = useCampaignsTableColumns(
    CampaignActionsItems,
    updateCampaignFavoriteStatusMutation
  );

  const hasAccessToCreateCampaign = useHasAccessTo("campaign.create");

  const uniqueKeyAccessor = (i: CampaignForList) => i.campaignId;
  const { selectedRows } = useRowSelection<CampaignForList>(
    rowSelection,
    setRowSelection,
    data?.campaigns,
    uniqueKeyAccessor
  );

  return (
    // FIXME: remove this `pb` hack, I suppose it was added to the container so we can fit the
    // campaign context menu. Instead we need to create a menu that fits.
    // In certain situations it makes the scroll bar go crazy.
    <Flex width="100%" flexDirection="column" pb={500}>
      <Flex
        mb={2}
        width="100%"
        justifyContent="space-between"
        alignItems="center"
      >
        <LabeledSwitch
          rightLabel="Show Favorites"
          isChecked={isFavoritesFilterEnabled}
          onChange={() =>
            setIsFavoritesFilterEnabled(!isFavoritesFilterEnabled)
          }
        />
        {hasAccessToCreateCampaign && (
          <Button
            variant="primary-teal"
            size="sm"
            onClick={handleRedirectToCreateCampaign}
          >
            Create Intent Model
          </Button>
        )}
      </Flex>
      <Flex
        my={2}
        width="100%"
        alignItems="center"
        justifyContent="space-between"
      >
        <BulkActions selectedRows={selectedRows} />
        <Search
          mb={0}
          currentValue={searchValue}
          onSearch={(value: string) => {
            setIsFavoritesFilterEnabled(false);
            setSearchValue(value);
            trackSearched({ term: value, collocation: "Campaigns table" });
          }}
        />
      </Flex>
      <Flex my={2}>
        <Stats
          searchValue={searchValue}
          isFavoritesFilterEnabled={isFavoritesFilterEnabled}
        />
      </Flex>
      <Flex my={2}>
        <TableComponent
          variant="intentsifyNew"
          searchValue={searchValue}
          defaultSort={[
            {
              id: "campaignId",
              desc: true,
            },
          ]}
          columns={campaignsTableColumns}
          isFetching={isFetching}
          data={data?.campaigns || []}
          onFetchDataChange={handleOnFetchDataChange}
          resetPaginationDependencies={[searchValue, isFavoritesFilterEnabled]}
          paginationType={PaginationType.TOKEN}
          nextPageToken={data?.nextPageToken}
          borderRadius={0}
          maxWidth="100%"
          headerCellVerticalAlign="top"
          isCellContentCentered
          stretch
          selectable
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          uniqueKeyAccessor={uniqueKeyAccessor}
        />
      </Flex>
    </Flex>
  );
};

export { CampaignsTable };
