import { TempAccountFileDTO } from "@intentsify/dto";
import { useState } from "react";

export const useUploadedFiles = () => {
  const [currentFiles, setCurrentFiles] = useState<Array<TempAccountFileDTO>>(
    []
  );

  const filesForList = currentFiles.map((i) => ({
    file: i,
    onDeleteIconClick: () => {
      setCurrentFiles((prev) => {
        return prev.filter((f) => {
          return f.tempFileName !== i.tempFileName;
        });
      });
    },
  }));

  return { filesForList, files: currentFiles, setFiles: setCurrentFiles };
};
