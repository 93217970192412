import { ProgrammaticDomainsFileDetailsScreen } from "types";
import { createScreen } from "utils/createScreen";

const ProgrammaticDomainsFileDetailsScreenDefinition = createScreen({
  name: ProgrammaticDomainsFileDetailsScreen,
  path: "/media-activation/programmatic-segments/:programmaticDomainsFileId/details",
  routePath: ["/:programmaticDomainsFileId/details"],
  accessPath: "programmaticCampaign.programmaticDomain",
});

export { ProgrammaticDomainsFileDetailsScreenDefinition };
