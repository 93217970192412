import { Navigate, Route, Routes } from "react-router";
import {
  CampaignDetails,
  CampaignDetailsScreenDefinition,
  CampaignsLeads,
  CampaignsLeadsScreenDefinition,
  CreateCampaign,
  CreateCampaignScreenDefinition,
  EditCampaign,
  EditCampaignScreenDefinition,
  YourCampaigns,
  YourCampaignsScreenDefinition,
} from "screens";

const CampaignsRouter = () => {
  return (
    <Routes>
      {YourCampaignsScreenDefinition.routePath.map((path) => (
        <Route key={path} path={path} element={<YourCampaigns />} />
      ))}

      {CreateCampaignScreenDefinition.routePath.map((path) => (
        <Route key={path} path={path} element={<CreateCampaign />} />
      ))}

      {EditCampaignScreenDefinition.routePath.map((path) => (
        <Route key={path} path={path} element={<EditCampaign />} />
      ))}

      {CampaignDetailsScreenDefinition.routePath.map((path) => (
        <Route key={path} path={path} element={<CampaignDetails />} />
      ))}

      {CampaignsLeadsScreenDefinition.routePath.map((path) => (
        <Route key={path} path={path} element={<CampaignsLeads />} />
      ))}

      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>
  );
};

export { CampaignsRouter };
