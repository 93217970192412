import { InfoIcon } from "@chakra-ui/icons";
import { Flex, HStack } from "@chakra-ui/layout";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
  Tooltip,
  chakra,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  BaseTargetPersona,
  BuyingGroupContactsAccounts,
} from "@intentsify/types";
import { Select } from "components";
import { forwardRef, useImperativeHandle } from "react";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { MutateCampaignBuyingGroupParams } from "screens/Campaigns/screens/CampaignsWizard/CampaignsWizard.requests";
import { useComponentColors } from "theme";
import { z } from "zod";
import { CreateTargetPersonaButton } from "../../../../../../TargetPersonas/CreateTargetPersonaButton";
import { NavigationBlockers } from "../../NavigationBlockers/NavigationBlockers";
import { SubmitTrigger } from "../../SettingsStep/SettingsForm";
import { DEFAULT_MAXIMUM_BUYER_GROUP_PER_ACCOUNT_SIZE } from "./BuyingGroupForm.const";
import { useWatchCampaignPersonas } from "./BuyingGroupForm.hooks";
import { PersonaSelector } from "./components/PersonaSelector/PersonaSelector";
import { TargetPersonasPreview } from "./components/TargetPersonasPreview";

const CampaignBuyingGroup = z.object({
  selectedPersonas: z.array(BaseTargetPersona),
  buyingGroupContactsAccounts: z.nativeEnum(BuyingGroupContactsAccounts),
  maxBuyerGroupSizePerAccount: z.coerce
    .number({
      required_error: "Max buyer group size per account must be specified.",
    })
    .int()
    .min(1, "Max buyer group size should be above 1")
    .max(100, "Max buyer group size should be below 100"),
});

type CampaignBuyingGroup = z.infer<typeof CampaignBuyingGroup>;

const addTargetPersonaTooltip = `
A buying group comprises of multiple target personas who are involved in the
decision-making and/or buying process for a specific product or product line. Select from the
dropdown of existing target personas to create the buying group for this intent model.
After selecting necessary target personas, select "Add to Buying Group". If
there are missing target personas, use the "Create Persona" button to add more.`;

const selectedTargetPersonaTooltip = `
The selected target personas make up the buying group for the Intent Model. You can
add to this list using the "Add Target Personas" option above or "Remove" target 
personas at any point throughout the campaign.
`;

const contactTypeOptions = [
  {
    value: BuyingGroupContactsAccounts.NONE,
    label: "None",
  },
  {
    value: BuyingGroupContactsAccounts.WITH_LEADS,
    label: "Buying Group Contacts for Leads",
  },
  {
    value: BuyingGroupContactsAccounts.ACTIVE,
    label: "TAL Enrichment",
  },
];

type BuyingGroupFormProps = {
  id: number;
  companyId: number;
  campaignBuyingGroup: CampaignBuyingGroup;
  onSubmit: (params: MutateCampaignBuyingGroupParams) => void;
};

const BuyingGroupForm = forwardRef<
  SubmitTrigger | undefined,
  BuyingGroupFormProps
>(({ id, companyId, campaignBuyingGroup, onSubmit }, ref) => {
  const componentColors = useComponentColors();
  const formProps = useForm<CampaignBuyingGroup>({
    mode: "onChange",
    resolver: zodResolver(CampaignBuyingGroup),
    defaultValues: campaignBuyingGroup ?? {
      selectedPersonas: [],
      buyingGroupContactsAccounts: BuyingGroupContactsAccounts.NONE,
      maxBuyerGroupSizePerAccount: DEFAULT_MAXIMUM_BUYER_GROUP_PER_ACCOUNT_SIZE,
    },
  });
  const {
    formState: { errors },
    getValues,
    register,
    control,
    handleSubmit,
  } = formProps;

  const { append: appendPersona, remove: removePersona } = useFieldArray({
    control,
    name: "selectedPersonas",
  });

  useImperativeHandle(ref, () => ({
    submit({ shouldExitOnSuccess }) {
      handleSubmit(
        ({
          maxBuyerGroupSizePerAccount,
          buyingGroupContactsAccounts,
          selectedPersonas,
        }) => {
          onSubmit({
            shouldExitOnSuccess,
            newSettings: {
              maxBuyerGroupSizePerAccount,
              buyingGroupContactsAccounts,
              selectedPersonas,
              id,
            },
          });
        }
      )();
    },
  }));

  const addPersona = (persona: BaseTargetPersona | BaseTargetPersona[]) => {
    const newPersonas = Array.isArray(persona) ? persona : [persona];
    const selectedPersonaIds = getValues("selectedPersonas").map(
      (selectedPersona) => selectedPersona.id
    );
    appendPersona(
      newPersonas.filter(
        (newPersona) => !selectedPersonaIds.includes(newPersona.id)
      )
    );
  };

  const haveChanged = useWatchCampaignPersonas(
    campaignBuyingGroup,
    getValues("selectedPersonas"),
    getValues("maxBuyerGroupSizePerAccount"),
    getValues("buyingGroupContactsAccounts")
  );

  return (
    <>
      <NavigationBlockers isUnsavedChangesBlockerEnabled={haveChanged} />

      <FormProvider {...formProps}>
        <chakra.form gap="6" display="flex" flexDirection="column">
          <Flex justifyContent="space-between" alignItems="flex-end">
            <Flex columnGap="4" alignItems="center">
              <Text size="small">Add Target Personas</Text>
              <Tooltip label={addTargetPersonaTooltip}>
                <InfoIcon w={4} h={4} ml={2} />
              </Tooltip>
            </Flex>

            <CreateTargetPersonaButton
              companyId={companyId}
              onCreated={(persona) => addPersona(persona as BaseTargetPersona)}
            />
          </Flex>
          <Flex>
            <PersonaSelector
              companyId={companyId}
              onAdd={(persona) => addPersona(persona)}
            />
          </Flex>
          <Flex>
            <Flex columnGap="4" alignItems="center">
              <Text size="small">Selected Target Personas</Text>
              <Tooltip label={selectedTargetPersonaTooltip}>
                <InfoIcon w={4} h={4} ml={2} />
              </Tooltip>
            </Flex>
          </Flex>

          <TargetPersonasPreview
            data={getValues("selectedPersonas")}
            onRemove={removePersona}
          />

          <HStack spacing="8">
            <FormControl
              isInvalid={!!errors?.buyingGroupContactsAccounts?.message}
              w="400px"
            >
              <FormLabel
                fontSize="sm"
                fontWeight="md"
                color={componentColors.form.formLabelColor}
              >
                Intentsify Orbit Data Package
              </FormLabel>
              <Controller
                name="buyingGroupContactsAccounts"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Select
                    options={contactTypeOptions}
                    value={
                      contactTypeOptions.find(
                        (option) => option.value === value
                      ) ?? contactTypeOptions[0]
                    }
                    onChange={(newOption) => {
                      onChange(newOption?.value ?? contactTypeOptions[0]);
                    }}
                    isMulti={false}
                  />
                )}
              />
            </FormControl>

            <FormControl
              isInvalid={!!errors?.maxBuyerGroupSizePerAccount?.message}
              isRequired
            >
              <FormLabel
                fontSize="sm"
                fontWeight="md"
                color={componentColors.form.formLabelColor}
              >
                Maximum Buyer Group Size per Account
              </FormLabel>
              <NumberInput
                shadow="sm"
                size="sm"
                rounded="md"
                width="2xs"
                defaultValue={10}
                max={100}
                min={1}
                step={1}
              >
                <NumberInputField
                  {...register("maxBuyerGroupSizePerAccount")}
                  type="number"
                />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>

              <FormErrorMessage>
                {errors?.maxBuyerGroupSizePerAccount?.message}
              </FormErrorMessage>
            </FormControl>
          </HStack>
        </chakra.form>
      </FormProvider>
    </>
  );
});

export { BuyingGroupForm };
