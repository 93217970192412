import { Box, Flex, useColorMode } from "@chakra-ui/react";
import { Option } from "@intentsify/types";
import { decimalToPercentage } from "@intentsify/utils";
import { useQuery } from "@tanstack/react-query";
import {
  Card,
  getChartColors,
  MIN_MODAL_CONTENT_HEIGHT,
  Select,
  StackedGroupedBarChart,
} from "components";
import { useMemo, useState } from "react";
import { MdAnalytics } from "react-icons/md";
import { DisplayPerformanceFilters } from "screens";
import { getEngagementAcrossPersonas } from "../../AccountsPerformance.requests";

type EngagementAcrossPersonasProps = {
  campaignId: string;
  filters: DisplayPerformanceFilters;
};

type Mode = "impressions" | "clicks";

const modeOptions: Option<Mode>[] = [
  {
    value: "impressions",
    label: "Impressions",
  },
  {
    value: "clicks",
    label: "Clicks",
  },
];

enum PersonaBarType {
  IdentifiedPersonas = "Identified Personas",
  AdSolutionsSegmentPersonas = "Ad Solutions Segment Personas",
}

export const EngagementAcrossPersonas = ({
  campaignId,
  filters,
}: EngagementAcrossPersonasProps) => {
  const { colorMode } = useColorMode();

  const [modeValue, setModeValue] = useState<Option<Mode>>(modeOptions[0]);

  const { data: rawData, isFetching: isFetching } = useQuery({
    queryKey: ["engagementAcrossPersonas", campaignId, filters],
    queryFn: async () => await getEngagementAcrossPersonas(campaignId, filters),
  });

  // check if there is at least one persona with impressions that comes from uploaded segments
  const isImpressionsSegmentPresent =
    rawData?.engagementAcrossPersonas.some(
      (item) => item.impressionsSegment !== null && item.impressionsSegment > 0
    ) || false;
  // check if there is at least one persona with clicks that comes from uploaded segments
  const isClickSegmentPresent =
    rawData?.engagementAcrossPersonas.some(
      (item) => item.clicksSegment !== null && item.clicksSegment > 0
    ) || false;

  const formattedData = useMemo(() => {
    if (isFetching || !rawData) {
      return [];
    }

    return rawData.engagementAcrossPersonas.map((element) => {
      return {
        groupLabel: element.personaName,
        groupItems: [
          {
            label: PersonaBarType.IdentifiedPersonas,
            data: [
              {
                label: modeValue.label,
                value: element[`${modeValue.value}General`] ?? 0,
                metaData: {
                  additionalTooltipContent:
                    modeValue.value === "clicks"
                      ? `CTR: ${decimalToPercentage(
                          element.clicksGeneral / element.impressionsGeneral
                        )}%`
                      : undefined,
                },
              },
            ],
          },
          // display Ad Solution Segment Personas when there is at least one persona that has impressions and clicks
          ...(isImpressionsSegmentPresent && isClickSegmentPresent
            ? [
                {
                  label: PersonaBarType.AdSolutionsSegmentPersonas,
                  data: [
                    {
                      label: modeValue.label,
                      value: element[`${modeValue.value}Segment`] ?? 0,
                      metaData: {
                        additionalTooltipContent:
                          modeValue.value === "clicks"
                            ? `CTR: ${decimalToPercentage(
                                element.clicksSegment /
                                  element.impressionsSegment
                              )}%`
                            : undefined,
                      },
                    },
                  ],
                },
              ]
            : []),
        ],
      };
    });
  }, [
    isFetching,
    modeValue,
    rawData,
    isImpressionsSegmentPresent,
    isClickSegmentPresent,
  ]);

  const hasSelectedTargetPersonas = formattedData.length > 0;

  const getChartDomain = () => {
    const fullDomain = [
      PersonaBarType.IdentifiedPersonas,
      PersonaBarType.AdSolutionsSegmentPersonas,
    ];
    const partialDomain = [PersonaBarType.IdentifiedPersonas];

    if (modeValue.value === "impressions") {
      return isImpressionsSegmentPresent ? fullDomain : partialDomain;
    }

    // clicks mode
    return isClickSegmentPresent ? fullDomain : partialDomain;
  };

  return (
    <Card
      isLoading={isFetching}
      title={"Engagement Across Personas"}
      isExpandable={hasSelectedTargetPersonas}
      hasData={hasSelectedTargetPersonas}
      noDataMessage='This visualization is designated for programs that have selected target personas. To configure your target personas, click on the "Target Personas" tab in the navigation bar.'
      noDataIcon={MdAnalytics}
      minHeight="250px"
    >
      {(isExpanded) => (
        <Flex
          flexDir="column"
          h={isExpanded ? MIN_MODAL_CONTENT_HEIGHT : "100%"}
        >
          <Flex mb={2}>
            <Flex alignItems="center">
              <Box minWidth={300} padding={2}>
                <Select
                  isClearable={false}
                  isMulti={false}
                  value={modeValue}
                  options={modeOptions}
                  onChange={(val) => {
                    if (val) {
                      setModeValue(val);
                    }
                  }}
                />
              </Box>
            </Flex>
          </Flex>
          <Flex>
            <StackedGroupedBarChart
              minHeight={MIN_MODAL_CONTENT_HEIGHT}
              isLoading={isFetching}
              stretch
              data={formattedData}
              legendConfig={{
                domain: getChartDomain(),
                range: [
                  getChartColors(colorMode, 0)[0],
                  getChartColors(colorMode, 1)[1],
                ],
              }}
              hideBarsLabels={true}
            />
          </Flex>
        </Flex>
      )}
    </Card>
  );
};
