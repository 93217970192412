import { Center, Spinner, Text } from "@chakra-ui/react";
import { Option } from "@intentsify/types";
import { useQueries } from "@tanstack/react-query";
import { SimpleSelect } from "components/Select/SimpleSelect";
import { useSelectUserAgencyCompany } from "mutations/companies/useSelectUserAgencyCompany";
import { useCompaniesConfig } from "queries/companies/useCompaniesConfig";
import { useUserAgencyCompanyConfig } from "queries/companies/useUserAgencyCompany";
import { useUserPermissionModulesConfig } from "queries/permissions/useUserPermissionModules";
import { useInvalidatePortalQueries } from "queries/useInvalidatePortalQueries";
import { memo, useEffect, useState } from "react";
import { useErrorToast } from "./useErrorToast";

type CompanyOption = Option<string>;

const isMulti = false;

export const UserAgencyCompanySelect = memo(
  (props: { isDisabled?: boolean }) => {
    /**
     * react-select is such a scam, defaultValue prop doesn't work
     * for non static values, so we have to control the select
     *
     * it should really just match the behaviour of react hook form
     *
     * debugging it was a nightmare
     */
    const [options, setOptions] = useState<CompanyOption[]>([]);
    const [value, setValue] = useState<CompanyOption | null>();
    const errorToast = useErrorToast();
    const invalidatePortalQueries = useInvalidatePortalQueries();

    const [companies, userAgencyCompany, userPermissionModules] = useQueries({
      queries: [
        useCompaniesConfig(),
        useUserAgencyCompanyConfig({
          suspense: true,
          onSuccess: () => {
            invalidatePortalQueries();
          },
        }),
        useUserPermissionModulesConfig(),
      ],
    });

    const selectUserAgencyCompany = useSelectUserAgencyCompany({
      retry: 0,
      onSuccess: () => {
        userAgencyCompany.refetch({ exact: false });
        userPermissionModules.refetch({ exact: false });
      },
      onError: () => {
        errorToast();
        // select previous value on error
        setValue(
          options.find(
            (company) =>
              company.value === String(userAgencyCompany.data?.companyId)
          )
        );
      },
    });

    useEffect(() => {
      // when we wait for the companies query to resolve
      // we want to show the user agency company as the only option
      // for better ux
      if (!companies.data?.companies && userAgencyCompany.data) {
        setOptions([
          {
            label: userAgencyCompany.data?.companyDisplayName || "",
            value: String(userAgencyCompany.data?.companyId),
          },
        ]);

        return;
      }

      setOptions(
        companies.data?.companies.map((company) => ({
          value: String(company.companyId),
          label: company.displayName,
        })) || []
      );
    }, [companies.data?.companies, userAgencyCompany.data]);

    useEffect(() => {
      if (!userAgencyCompany.data?.companyId) {
        // select first option if no company is selected
        // it matches the default behaviour of user agency company module on portal-api
        setValue(options[0]);
      }

      setValue(
        options.find(
          (company) =>
            company.value === String(userAgencyCompany.data?.companyId)
        )
      );
    }, [options, userAgencyCompany.data?.companyId]);

    const isLoading =
      selectUserAgencyCompany.isLoading ||
      companies.isFetching ||
      userAgencyCompany.isRefetching;

    return (
      <Center data-testId="user-agency-company-select">
        <Center
          as="label"
          htmlFor="user-agency-company-select"
          color="gray.400"
          _hover={{ color: "white" }}
          _focus={{ color: "white" }}
          _active={{ color: "white" }}
        >
          {isLoading && <Spinner size="xs" color="whiteAlpha.500" />}
          <Text fontSize="xs" mx="2" color="currentcolor" fontWeight="medium">
            Company
          </Text>
        </Center>

        <SimpleSelect<CompanyOption, typeof isMulti>
          inputId="user-agency-company-select"
          isClearable={false}
          isDisabled={props.isDisabled || isLoading}
          placeholder={
            userAgencyCompany.data?.companyDisplayName || "Select company"
          }
          isMulti={isMulti}
          options={options}
          value={value}
          onChange={(option) => {
            if (option?.value === value?.value) {
              return;
            }

            setValue(option);
            selectUserAgencyCompany.mutate({
              companyId: Number(option?.value),
            });
          }}
        />
      </Center>
    );
  }
);

UserAgencyCompanySelect.displayName = "UserAgencyCompanySelect";
