import { InfoIcon } from "@chakra-ui/icons";
import { Box } from "@chakra-ui/layout";
import {
  ButtonGroup,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  SimpleGrid,
  Stack,
  chakra,
  useToast,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  BlacklistedFirmographic,
  DomainsData,
  Firmographic,
  FirmographicsFilters,
  SegmentType,
  TargetPersona,
  regionOptions,
} from "@intentsify/types";
import { isPopulatedArray } from "@intentsify/utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  DataToCsvDownload,
  FilterAsyncTokenBased,
  Firmographics,
  Select,
  Tooltip,
} from "components";
import { defaultFirmoFilters } from "components/Firmographics/Firmographics.const";
import { useUserAgencyCompany } from "queries/companies/useUserAgencyCompany";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { DomainsUploadLegacy } from "shared/components";
import { DomainsUploadScreenType } from "shared/components/DomainsUploadLegacy/DomainsUpload.types";
import { useComponentColors } from "theme";
import { FetchDataParamsWithToken } from "types";
import { getTargetPersonas } from "../../../../../../TargetPersonas/useTargetPersonas";
import { useCombineDomains } from "../../../../../MediaActivation.hooks";
import { ProgrammaticDomainsScreenDefinition } from "../../ProgrammaticDomains";
import {
  UploadProgrammaticDomains,
  uploadProgrammaticDomains,
  uploadProgrammaticDomainsSchema,
} from "./FileForm.requests";

const DEFAULT_FILENAME = "domains.csv";

const formDefaultValues = {
  hasIspData: false,
  domains: [],
  segmentType: SegmentType.IP,
  fileName: DEFAULT_FILENAME,
  targetPersonas: [],
};

const personaDataRequest = async (
  params: FetchDataParamsWithToken<keyof TargetPersona> & { companyId?: number }
) => {
  const { targetPersonas } = await getTargetPersonas(params);
  return {
    ...targetPersonas,
    results: targetPersonas.map((persona) => ({
      value: persona.id,
      label: persona.name,
      meta: persona,
    })),
  };
};

const FileForm = () => {
  const componentColors = useComponentColors();
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    watch,
  } = useForm<UploadProgrammaticDomains>({
    mode: "onChange",
    resolver: zodResolver(uploadProgrammaticDomainsSchema),
    defaultValues: formDefaultValues,
  });

  const { mutate, isLoading } = useMutation(uploadProgrammaticDomains, {
    onSuccess: () => {
      toast({
        title: "Domains uploaded successfully.",
        status: "success",
        duration: 9000,
      });
      queryClient.invalidateQueries(["listProgrammaticDomainFiles"]);

      navigate(
        ProgrammaticDomainsScreenDefinition.navigate({ view: "manual" })
      );
    },
  });

  const toast = useToast();
  const navigate = useNavigate();
  const [firmographicsFilters, setFirmographicsFilters] =
    useState<FirmographicsFilters>(defaultFirmoFilters);
  const [selectedFirmographics, setSelectedFirmographics] = useState<
    Firmographic[]
  >([]);
  const [blacklistedFirmographics, setBlacklistedFirmographics] = useState<
    BlacklistedFirmographic[]
  >([]);
  const [isLoadingFirmographics, setIsLoadingFirmographics] = useState(false);

  const [uploadedDomains, setUploadedDomains] =
    useState<DomainsData>(undefined);

  const userAgencyCompany = useUserAgencyCompany();
  const selectedCompanyId = userAgencyCompany.data?.companyId;

  const selectedSegmentType = watch("segmentType");
  const targetPersonas = watch("targetPersonas");

  const availableRegionsOptions = [SegmentType.MAID].includes(
    selectedSegmentType
  )
    ? regionOptions.filter((option) => option.value === "AMER")
    : regionOptions;

  const { combinedSelectedDomains, combinedCsvDownloadDomains } =
    useCombineDomains(
      selectedFirmographics,
      blacklistedFirmographics,
      uploadedDomains
    );

  useEffect(() => {
    setValue("domains", combinedSelectedDomains);
  }, [setValue, combinedSelectedDomains]);

  useEffect(() => {
    setValue("targetPersonas", []);
  }, [selectedCompanyId, selectedSegmentType, setValue]);

  return (
    <SimpleGrid>
      <chakra.form onSubmit={(e) => e.preventDefault()} borderRadius={4}>
        <FormControl
          w="400px"
          mb={3}
          isInvalid={!!errors?.tag?.message}
          isRequired
        >
          <FormLabel
            color={componentColors.form.formLabelColor}
            fontSize="sm"
            fontWeight="bold"
          >
            Tag
          </FormLabel>
          <Input
            {...register("tag")}
            type="name"
            name="tag"
            placeholder="Tag"
            shadow="sm"
            size="sm"
            rounded="md"
          />
          <FormErrorMessage>{errors?.tag?.message}</FormErrorMessage>
        </FormControl>

        <FormControl
          w="400px"
          mb={3}
          isInvalid={!!errors?.segmentType?.message}
          isRequired
        >
          <FormLabel
            color={componentColors.form.formLabelColor}
            fontSize="sm"
            fontWeight="bold"
          >
            Segments to Upload
          </FormLabel>

          <RadioGroup
            colorScheme="teal"
            defaultValue={formDefaultValues.segmentType}
          >
            <Stack spacing={[1, 5]} direction={["column", "row"]}>
              <Radio value={SegmentType.IP} {...register("segmentType")}>
                IP Addresses
              </Radio>
              <Radio value={SegmentType.USER_ID} {...register("segmentType")}>
                User IDs
              </Radio>
              <Radio value={SegmentType.MAID} {...register("segmentType")}>
                MAIDs
              </Radio>
            </Stack>
          </RadioGroup>
          <FormErrorMessage>{errors?.segmentType?.message}</FormErrorMessage>
        </FormControl>

        <FormControl
          w="400px"
          mb={3}
          isInvalid={isPopulatedArray(errors?.region)}
          isRequired
        >
          <FormLabel
            color={componentColors.form.formLabelColor}
            fontSize="sm"
            fontWeight="bold"
          >
            Region
          </FormLabel>
          <Controller
            name="region"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Select
                  isMulti={false}
                  value={regionOptions.find((i) => i.value === value)}
                  onChange={(selection) => {
                    onChange(selection?.value);
                  }}
                  options={availableRegionsOptions}
                  placeholder="Select region"
                />
              );
            }}
          />
          <FormErrorMessage>{errors?.region?.message}</FormErrorMessage>
        </FormControl>

        <FormControl
          w="400px"
          mb={3}
          isInvalid={!!errors?.targetPersonas?.message}
        >
          <FormLabel
            color={componentColors.form.formLabelColor}
            fontSize="sm"
            fontWeight="bold"
          >
            Target Personas
          </FormLabel>
          <Flex gap="2" alignItems="flex-end">
            <Box width="sm">
              <FilterAsyncTokenBased
                isDisabled={
                  !selectedCompanyId || selectedSegmentType === SegmentType.IP
                }
                isMulti={true}
                defaultOptions={[]}
                currentValue={targetPersonas.map((targetPersona) => ({
                  value: targetPersona.id,
                  label: targetPersona.name,
                }))}
                dataRequest={(
                  arg: FetchDataParamsWithToken<keyof TargetPersona>
                ) =>
                  personaDataRequest({ ...arg, companyId: selectedCompanyId })
                }
                resetFilterRequestDependencies={[selectedCompanyId]}
                onFilterValuesChange={(options) => {
                  setValue(
                    "targetPersonas",
                    (options || []).map((item) => ({
                      id: item.value,
                      name: item.label,
                    }))
                  );
                }}
              />
            </Box>
          </Flex>
        </FormControl>

        <FormControl w="400px" mb={3} isInvalid={!!errors?.hasIspData?.message}>
          <FormLabel
            color={componentColors.form.formLabelColor}
            fontSize="sm"
            fontWeight="bold"
          >
            ISP settings
          </FormLabel>

          <Controller
            name="hasIspData"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Checkbox
                  colorScheme="teal"
                  isChecked={Boolean(value)}
                  onChange={(v) => onChange(v)}
                >
                  ISP Data Included
                </Checkbox>
              );
            }}
          />

          <FormErrorMessage>{errors?.hasIspData?.message}</FormErrorMessage>
        </FormControl>

        <Divider my={8} />

        <FormControl>
          <FormLabel
            color={componentColors.form.formLabelColor}
            fontSize="sm"
            fontWeight="bold"
          />
          <DomainsUploadLegacy
            domainsData={uploadedDomains}
            domainsUploadScreenType={
              DomainsUploadScreenType.PROGRAMMATIC_DOMAINS_VIEW
            }
            onUploadedDomainsChange={(data) => {
              setUploadedDomains(data);
              setValue("fileName", data?.fileName || DEFAULT_FILENAME);
            }}
          />
        </FormControl>

        <Divider my={8} />

        <FormControl>
          <FormLabel
            color={componentColors.form.formLabelColor}
            fontSize="sm"
            fontWeight="bold"
          />
          <Firmographics
            subject={`Programmatic Segments`}
            firmographicsFilters={firmographicsFilters ?? defaultFirmoFilters}
            setFirmographicsFilters={setFirmographicsFilters}
            setSelectedFirmographics={(data) => {
              setSelectedFirmographics(data);
            }}
            selectedFirmographics={selectedFirmographics}
            blacklisted={blacklistedFirmographics}
            onChangeBlacklisted={setBlacklistedFirmographics}
            setIsLoadingFirmographics={setIsLoadingFirmographics}
          />
        </FormControl>

        <Divider my={8} />

        <Flex mb={2} justifyContent="space-between">
          <div>
            <chakra.span>Summary</chakra.span>
            <Tooltip
              aria-label="Programmatic segments file upload"
              label={`Uploaded and Firmographics domains combined`}
            >
              <InfoIcon w={4} h={4} ml={2} />
            </Tooltip>
          </div>
          <DataToCsvDownload
            fileName={"Programmatic segments - combined.csv"}
            data={combinedCsvDownloadDomains}
            downloadLabel="Uploaded and Firmographics domains combined"
            iconSize={"xs"}
            withLabel
            subject="domain"
            subjectPlural="domains"
            tooltipPlacement="bottom-end"
          />
        </Flex>

        <Divider mt={8} />

        <Flex mt={4} justifyContent="flex-end">
          <ButtonGroup>
            <Button
              size="md"
              onClick={() =>
                navigate(
                  ProgrammaticDomainsScreenDefinition.navigate({
                    view: undefined,
                  })
                )
              }
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleSubmit((data) => mutate(data))();
              }}
              size="md"
              type="submit"
              variant="primary-teal"
              isLoading={isLoading}
              isDisabled={isLoadingFirmographics}
            >
              Upload Segments
            </Button>
          </ButtonGroup>
        </Flex>
      </chakra.form>
    </SimpleGrid>
  );
};

export { FileForm };
