import { Flex, Image } from "@chakra-ui/react";
import { isDefined } from "@intentsify/utils";
import { ColumnDef } from "@tanstack/react-table";
import { EmptyCell } from "components";
import { accountLogoFallbackImage } from "const";
import { DateTime } from "luxon";
import { Lead } from "./CampaignsLeadsTable.types";

export const campaignLeadsTableColumns: Array<ColumnDef<Lead>> = [
  { header: "Id", accessorKey: "leadId" },
  {
    header: "Account",
    accessorKey: "domain",
    enableSorting: true,
    cell: (props) => {
      const { row } = props;

      return (
        <Flex alignItems="center">
          <Image
            minW="35px"
            boxSize="35px"
            objectFit="contain"
            src={String(row.original?.logoUrl)}
            mr={4}
            fallbackSrc={accountLogoFallbackImage}
          />
          {row.original?.domain}
        </Flex>
      );
    },
  },
  {
    header: "Name",
    accessorKey: "title",
    enableSorting: true,
    cell: (props) => {
      if (!props.row.original) {
        return <EmptyCell />;
      }

      const { title, firstName, lastName } = props.row.original;
      const fullName = firstName && lastName && `${firstName} ${lastName}`;
      return title ?? fullName ?? <EmptyCell />;
    },
  },
  {
    header: "Location",
    accessorKey: "city",
    enableSorting: true,
    cell: (props) => {
      if (!props.row.original) {
        return <EmptyCell />;
      }

      const { city, state, country } = props.row.original;
      const location = [city, state, country].filter(isDefined).join(", ");
      return location.length > 0 ? location : <EmptyCell />;
    },
  },
  {
    header: "Date",
    accessorKey: "date",
    enableSorting: true,
    cell: (props) => {
      if (!props.row.original) {
        return <EmptyCell />;
      }

      return DateTime.fromISO(props.row.original?.date).toUTC().toISODate();
    },
  },
];
