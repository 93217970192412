import { Box, Flex, UseDisclosureReturn, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";

import { RequestProgramReviewDTO } from "@intentsify/dto";
import { Endpoints, apiService } from "api";
import { Modal } from "components/Modal";
import { DateTime } from "luxon";
import { PropsWithChildren } from "react";
import { RequestProgramReviewForm } from "./RequestProgramReviewForm";

export function RequestProgramReviewModal(
  props: PropsWithChildren<
    { campaignId: number } & Pick<UseDisclosureReturn, "isOpen" | "onClose">
  >
) {
  const requestProgramReviewDeck = useRequestProgramReviewDeck({
    onSuccess: props.onClose,
  });

  return (
    <Modal
      overflow="visible"
      size="2xl"
      title={`${props.campaignId} Campaign Program Review`}
      isOpen={props.isOpen}
      onClose={props.onClose}
      subtitle={
        <Box
          color="blackAlpha.700"
          fontSize="sm"
          _dark={{ color: "whiteAlpha.700" }}
        >
          The report will show up to 8 weeks of data, leading up to the selected
          date.
        </Box>
      }
      body={
        <Flex pb="4">
          <RequestProgramReviewForm
            campaignId={props.campaignId}
            isLoading={requestProgramReviewDeck.isLoading}
            onSubmit={(data) => {
              const selectedDateIso = DateTime.fromISO(data.selectedDate);
              requestProgramReviewDeck.mutate({
                campaignId: props.campaignId,
                weekNumber: selectedDateIso.weekNumber,
                yearNumber: selectedDateIso.weekYear,
              });
            }}
          />
        </Flex>
      }
    />
  );
}

function useRequestProgramReviewDeck(options?: { onSuccess?: () => void }) {
  const toast = useToast();

  return useMutation({
    mutationFn: async (params: RequestProgramReviewDTO) => {
      return await apiService.post(
        Endpoints.Reports.ProgramReview.Post.RequestProgramReview,
        params
      );
    },
    onSuccess: () => {
      options?.onSuccess?.();
      toast({
        title: `Your request for the Program Review report has been received. You will be notified once it's ready.`,
        status: "success",
      });
    },
  });
}
