import { CampaignAssignedToCompanyWorkatoIntegrationListRO } from "@intentsify/dto";
import { Company } from "@intentsify/types";
import { useQuery } from "@tanstack/react-query";
import { Duration } from "luxon";
import { apiService, Endpoints } from "api";

const getCampaignsAssignedToIntegrations = async (
  companyId: Company["companyId"]
) => {
  const { data } = await apiService.get<
    CampaignAssignedToCompanyWorkatoIntegrationListRO["campaigns"]
  >(Endpoints.Companies.Get.Integrations.Paginated(companyId));

  return data;
};

export const useCampaignsAssignedToIntegration = (
  companyId: Company["companyId"]
) => {
  const results = useQuery(
    ["campaignsAssignedToIntegrations", companyId],
    () => getCampaignsAssignedToIntegrations(companyId),
    {
      staleTime: Duration.fromObject({ minutes: 5 }).as("millisecond"),
    }
  );

  return results;
};
