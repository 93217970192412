import { useQuery } from "@tanstack/react-query";
import { WeeklySnapshotDTO } from "@intentsify/dto";
import { useState } from "react";
import { getCoverageByEmployeeSize } from "./CoverageByEmployeeSize.requests";
import { Card, MIN_MODAL_CONTENT_HEIGHT, PieChart } from "components";
import { ChartExport, useExportableChart } from "shared/components";

type CoverageByEmployeeSizeProps = {
  campaignId: number;
  filters: WeeklySnapshotDTO;
};

const CoverageByEmployeeSize = ({
  campaignId,
  filters,
}: CoverageByEmployeeSizeProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const { data, isFetching } = useQuery(
    ["getCoverageByEmployeeSize", campaignId, filters],
    () => getCoverageByEmployeeSize(campaignId, filters)
  );

  const chart = useExportableChart({
    title: "Active Account Coverage By Employee Size",
    campaignId: 1,
    chart: (
      <PieChart
        minHeight={isExpanded ? MIN_MODAL_CONTENT_HEIGHT : undefined}
        stretch
        fixedRatio={[1, 1]}
        legendPosition="bottom"
        data={data ?? []}
        shouldSort={false}
        displayAsPercent={true}
      />
    ),
  });

  return (
    <Card
      onExpandedChange={setIsExpanded}
      isLoading={isFetching}
      title={chart.title}
      hasData={!isFetching && Boolean(data?.length)}
      isExpandable
      actions={
        <ChartExport
          size="small"
          campaignId={campaignId}
          title={chart.title}
          onExportPNG={chart.downloadAsPng}
          data={data}
        />
      }
    >
      {chart.component}
      {chart.exportComponent}
    </Card>
  );
};

export { CoverageByEmployeeSize };
