import { Flex, useToast } from "@chakra-ui/react";
import { Button } from "components";
import { FileUploadButton } from "components/Upload/FileUploadButton";
import { useAppStore } from "store/useAppStore";
import { contentLibraryUpload } from "upload/contentLibraryUpload";
import { useInvalidateDraftAssets } from "./Drafts/useDraftAssets";

export const UploadContent = () => {
  const showUploadContentTip = useAppStore.use.showUploadContentTip();
  const setShowUploadContentTip = useAppStore.use.setShowUploadContentTip();
  const incrementUploadContentTipCount =
    useAppStore.use.incrementUploadContentTipCount();
  const uploadContentTipCount = useAppStore.use.uploadContentTipCount();

  const invalidateDraftAssets = useInvalidateDraftAssets();
  const uploadFile = contentLibraryUpload.useUploadFile();
  const toast = useToast();

  return (
    <FileUploadButton
      onChange={(e) => {
        const files = e.target.files;
        if (!files) {
          return;
        }

        Array.from(files).forEach((file) =>
          uploadFile(file, { onSuccess: invalidateDraftAssets })
        );

        if (!showUploadContentTip) {
          return;
        }

        const toastId = toast({
          title: (
            <>
              Tip: You can drag and drop files on any page to upload them to the
              content library.
            </>
          ),
          status: "info",
          isClosable: true,
          duration: 9000,
          description: (
            <Flex w="full" justifyContent="flex-end">
              {uploadContentTipCount > 0 && (
                <Button
                  variant="secondary"
                  onClick={() => {
                    setShowUploadContentTip(false);
                    toast.close(toastId);
                  }}
                >
                  Don't show again
                </Button>
              )}
            </Flex>
          ),
        });

        incrementUploadContentTipCount();
      }}
    />
  );
};
