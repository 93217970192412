import { Routes } from "react-router";
import { Navigate, Route } from "react-router-dom";
import {
  BusinessEntitiesRouter,
  CompaniesRouter,
  PublishersRouter,
  UsersRouter,
} from "./routes";
import {
  BusinessEntitiesRootDefinition,
  CompaniesRootDefinition,
  PublishersRootDefinition,
  UsersRootDefinition,
} from "screens";

const AdminRouter = () => {
  return (
    <Routes>
      {UsersRootDefinition.routePath.map((path) => (
        <Route key={path} path={path} element={<UsersRouter />} />
      ))}

      {CompaniesRootDefinition.routePath.map((path) => (
        <Route key={path} path={path} element={<CompaniesRouter />} />
      ))}

      {BusinessEntitiesRootDefinition.routePath.map((path) => (
        <Route key={path} path={path} element={<BusinessEntitiesRouter />} />
      ))}

      {PublishersRootDefinition.routePath.map((path) => (
        <Route key={path} path={path} element={<PublishersRouter />} />
      ))}

      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>
  );
};

export { AdminRouter };
