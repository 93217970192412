import { Box, Flex } from "@chakra-ui/layout";
import { useColorModeValue } from "@chakra-ui/react";
import { LegendItem, LegendLabel, LegendOrdinal } from "@visx/legend";
import { scaleOrdinal } from "@visx/scale";
import { useRecoilState, useSetRecoilState } from "recoil";
import { getLegendDirection } from "../../Charts.utils";
import { TimeseriesProps } from "../Timeseries";
import {
  disabledLegendItemsFamily,
  hoveredLineAtomFamily,
  legendHoverAtomFamily,
} from "../Timeseries.state";

type LegendProps = { chartColors: string[]; uniqueKey: string } & Pick<
  TimeseriesProps<string, string>,
  | "data"
  | "legendPosition"
  | "dataMainKey"
  | "legendItemActions"
  | "disableLegendTooltip"
>;

const Legend = ({
  dataMainKey,
  uniqueKey,
  data,
  legendPosition,
  chartColors,
  legendItemActions,
  disableLegendTooltip,
}: LegendProps) => {
  const setHoverItem = useSetRecoilState(legendHoverAtomFamily(uniqueKey));
  const setHoveredLine = useSetRecoilState(hoveredLineAtomFamily(uniqueKey));

  const [disabledLegendItems, setDisabledLegendItems] = useRecoilState(
    disabledLegendItemsFamily(uniqueKey)
  );

  const ordinalColorScale = scaleOrdinal({
    domain: data.map((item) => item[dataMainKey]),
    range: data.map((_, i) => chartColors[i]),
  });

  const legendGlyphSize = 14;

  const legendItemHoverBg = useColorModeValue(
    "blackAlpha.100",
    "whiteAlpha.100"
  );

  return (
    <LegendOrdinal
      scale={ordinalColorScale}
      labelFormat={(label) => `${String(label)}`}
    >
      {(labels) => (
        <div
          style={{
            display: "flex",
            flexDirection: getLegendDirection(legendPosition),
            width: "100%",
            flexWrap: "wrap",
          }}
        >
          {labels.map((label, i) => {
            const isDisabled = disabledLegendItems.find(
              (i) => i.timeseriesItemId === data[label.index].timeseriesItemId
            );

            return (
              <Flex alignItems="center" key={`legend-quantile-${i}`}>
                <LegendItem
                  onMouseOver={() => {
                    if (!disableLegendTooltip) {
                      setHoverItem(data[label.index]);
                    }

                    setHoveredLine(data[label.index]);
                  }}
                  onMouseLeave={() => {
                    setHoverItem(undefined);
                    setHoveredLine(undefined);
                  }}
                >
                  <Flex
                    width="100%"
                    alignItems="center"
                    borderRadius={4}
                    _hover={{ background: legendItemHoverBg }}
                    px={3}
                    cursor="pointer"
                    opacity={isDisabled ? 0.2 : 1}
                    onClick={() => {
                      if (!isDisabled) {
                        setDisabledLegendItems([
                          ...disabledLegendItems,
                          data[label.index],
                        ]);
                      } else {
                        setDisabledLegendItems(
                          disabledLegendItems.filter(
                            (i) =>
                              i.timeseriesItemId !==
                              data[label.index].timeseriesItemId
                          )
                        );
                      }
                    }}
                  >
                    <Box mr={2}>
                      <svg width={legendGlyphSize} height={legendGlyphSize}>
                        <rect
                          fill={label.value}
                          width={legendGlyphSize}
                          height={legendGlyphSize}
                        />
                      </svg>
                    </Box>
                    <LegendLabel align="left">
                      <Box whiteSpace="nowrap" fontSize="small">
                        {label.datum}
                      </Box>
                    </LegendLabel>
                  </Flex>
                </LegendItem>

                <Flex grow={1}></Flex>
                {legendItemActions && (
                  <Box mr={legendPosition === "bottom" ? 4 : undefined}>
                    {legendItemActions(data[label.index])}
                  </Box>
                )}
              </Flex>
            );
          })}
        </div>
      )}
    </LegendOrdinal>
  );
};

export { Legend };
