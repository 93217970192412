import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Endpoints, apiService } from "api";
import { buildTargetPersonaBlocklistMetadataKey } from "./useTargetPersonaBlocklistMetadata";

export const useDeleteTargetPersonaBlocklist = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      await apiService.delete(
        Endpoints.TargetPersonas.BlocklistMetadata.Delete
      );
    },
    onSuccess: () => {
      queryClient.setQueriesData(
        buildTargetPersonaBlocklistMetadataKey(),
        null
      );
    },
  });
};
