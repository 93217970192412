import { Flex, Grid, useColorModeValue } from "@chakra-ui/react";
import { isPopulatedArray } from "@intentsify/utils";
import { NoData } from "components";
import { useMemo, useState } from "react";
import { colors } from "theme";
import { Cell } from "./Cell";
import { Row } from "./Row";

export type BuyerResearchStageEngagementItem = {
  name: string;
  decision: number;
  consideration: number;
  interest: number;
  awareness: number;
};

type BuyerResearchStageEngagementTableProps = {
  data: BuyerResearchStageEngagementItem[];
  accountsInBuyerResearchStage: {
    accountsInDecision: number;
    accountsInConsideration: number;
    accountsInInterest: number;
    accountsInAwareness: number;
  };
};

const BuyerResearchStageEngagementTable = ({
  data,
  accountsInBuyerResearchStage,
}: BuyerResearchStageEngagementTableProps) => {
  const [nameColumnWidth, setNameColumnWidth] = useState<number | undefined>();

  const maxValue = useMemo(() => {
    return Math.max(
      ...data.flatMap((i) => [
        i.decision,
        i.consideration,
        i.interest,
        i.awareness,
      ])
    );
  }, [data]);

  const totalAccounts = useMemo(() => {
    return Object.values(accountsInBuyerResearchStage).reduce(
      (prev, curr) => prev + curr,
      0
    );
  }, [accountsInBuyerResearchStage]);

  const {
    accountsInDecision,
    accountsInConsideration,
    accountsInInterest,
    accountsInAwareness,
  } = accountsInBuyerResearchStage;

  const accountsCellBg = useColorModeValue(
    colors.gray["50"],
    colors.gray["700"]
  );

  const templateColumns = "minmax(100px, auto) repeat(5, minmax(50px, 1fr))";

  if (!isPopulatedArray(data)) {
    return <NoData height="100%" stretch />;
  }

  return (
    <Flex flexDir="column" w="100%">
      <Grid w="100%" templateColumns={templateColumns} gap={0} pr="16px">
        <Cell width={nameColumnWidth} noBorder />
        <Cell>Decision</Cell>
        <Cell>Consideration</Cell>
        <Cell>Interest</Cell>
        <Cell>Awareness</Cell>
        <Cell>Total</Cell>

        <Cell
          width={nameColumnWidth}
          noBorder
          bg={accountsCellBg}
          contentAlignment="right"
        >
          Accounts in Buyer Research Stage
        </Cell>
        <Cell bg={accountsCellBg}>{accountsInDecision}</Cell>
        <Cell bg={accountsCellBg}>{accountsInConsideration}</Cell>
        <Cell bg={accountsCellBg}>{accountsInInterest}</Cell>
        <Cell bg={accountsCellBg}>{accountsInAwareness}</Cell>
        <Cell bg={accountsCellBg}>{totalAccounts}</Cell>
      </Grid>

      <Grid
        w="100%"
        templateColumns={templateColumns}
        gap={0}
        overflowY="scroll"
      >
        {data.map((i, index) => {
          return (
            <Row
              onNameWidthChange={
                index === 0 ? (width) => setNameColumnWidth(width) : undefined
              }
              key={i.name}
              item={i}
              maxValue={maxValue}
            />
          );
        })}
      </Grid>
    </Flex>
  );
};

export { BuyerResearchStageEngagementTable };
