import { Flex } from "@chakra-ui/react";
import { Option } from "@intentsify/types";
import { useMemo } from "react";
import { Select } from "../Select";
import { useCompanies } from "./CompanySelector.state";

type CompanySelectorProps = {
  selected?: string | number;
  onChange: (selection: Option | null) => void;
  hasCampaigns?: boolean;
  isDisabled?: boolean;
  isClearable?: boolean;
  width?: string;
};

const CompanySelector = ({
  selected,
  onChange,
  hasCampaigns,
  isDisabled,
  isClearable = true,
  width = "400px",
}: CompanySelectorProps) => {
  const params = { hasCampaigns };

  const { companies, isLoading } = useCompanies(params);
  const companiesList = useMemo(
    () =>
      companies?.map((company) => ({
        value: company.companyId,
        label: company.displayName,
      })) ?? [],
    [companies]
  );

  const value = selected
    ? companiesList.find((c) => Number(c.value) === Number(selected))
    : undefined;

  return (
    <Flex
      data-testId="company-selector"
      w={width}
      flexDir={"column"}
      justify="space-between"
    >
      <Select
        isMulti={false}
        isDisabled={isLoading || isDisabled}
        isLoading={isLoading}
        isClearable={isClearable}
        placeholder="Select a company"
        options={companiesList}
        value={value}
        onChange={onChange}
      />
    </Flex>
  );
};

export { CompanySelector };
