import {
  Box,
  Center,
  Flex,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  useColorModeValue,
  useTheme,
} from "@chakra-ui/react";
import { isPopulatedArray } from "@intentsify/utils";
import { useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import { Item } from "types";
import { Button } from "../Button";

type SelectedTagsProps<T extends Item> = {
  items: T[];
  subject?: string;
  customTitle?: string;
  onRemoveByItem?: (item: T) => void;
  onReset?: () => void;
};

const DEFAULT_RENDERED_SIZE = 80;

const SelectedTags = <T extends Item>({
  items,
  subject,
  customTitle,
  onRemoveByItem,
  onReset,
}: SelectedTagsProps<T>) => {
  const chakraTheme = useTheme();
  const shadowColor = useColorModeValue(
    "white",
    chakraTheme.colors.brand[375]
  ) as string;

  const [showMoreIndex, setShowMoreIndex] = useState(1);
  const itemsList = items.slice(0, DEFAULT_RENDERED_SIZE * showMoreIndex);

  if (!isPopulatedArray(items)) {
    if (customTitle) {
      return (
        <Box mb={4}>
          <Text as="em">{customTitle}</Text>
        </Box>
      );
    }

    if (subject) {
      return (
        <Box mb={4}>
          <Text as="em">{`Please select ${subject} from list below.`}</Text>
        </Box>
      );
    }
  }

  const allVisible = items.length <= DEFAULT_RENDERED_SIZE * showMoreIndex;

  if (!isPopulatedArray(itemsList)) {
    return null;
  }

  return (
    <Box overflow="hidden">
      <Flex flexFlow="row wrap">
        {itemsList.map((item, index) => {
          return (
            <Tag
              as="div"
              display="flex"
              mb={1.5}
              mr={1.5}
              borderRadius="full"
              key={`${item.label}-${index}`}
            >
              <TagLabel title={item.label}>{item.label}</TagLabel>

              {onRemoveByItem && (
                <TagCloseButton
                  onClick={() => {
                    onRemoveByItem(item);
                  }}
                />
              )}
            </Tag>
          );
        })}
      </Flex>

      {allVisible && onReset && (
        <Button variant="outline" size="xs" mb={1} mr={1} onClick={onReset}>
          Remove all
        </Button>
      )}

      {!allVisible && (
        <Center
          position="relative"
          w="100%"
          boxShadow={`0px -20px 40px 20px ${shadowColor}`}
          zIndex={1}
        >
          <Button
            fullWidth
            variant="ghost"
            leftIcon={<FiChevronDown />}
            size="sm"
            onClick={() => setShowMoreIndex((prev) => prev + 1)}
          >
            See {DEFAULT_RENDERED_SIZE} more
          </Button>
        </Center>
      )}
    </Box>
  );
};

export { SelectedTags };
