import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import { ReactNode } from "react";
import { IconType } from "react-icons";

export const Body = ({
  isExpanded,
  hasData,
  noDataIcon,
  palette,
  noDataMessage,
  children,
}: {
  isExpanded: boolean;
  hasData?: boolean;
  noDataIcon?: IconType;
  palette: Record<string, any>;
  noDataMessage?: string;
  children?: ReactNode | ((expanded: boolean) => ReactNode);
}) => (
  <>
    {!hasData ? (
      <Flex
        alignItems="center"
        justifyContent="center"
        height="calc(100% - 40px)"
      >
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          maxW="250px"
        >
          {noDataIcon && (
            <Box mt={1}>
              <Icon
                as={noDataIcon}
                height="24px"
                width="24px"
                fill={palette?.title}
              />
            </Box>
          )}
          {noDataMessage && (
            <Box mt={1}>
              <Text fontWeight={500} fontSize="xs" textAlign="center">
                {noDataMessage}
              </Text>
            </Box>
          )}
        </Flex>
      </Flex>
    ) : (
      <Flex flexDir="column" px={2} pb={2} h="100%">
        {typeof children === "function" ? children(isExpanded) : children}
      </Flex>
    )}
  </>
);
