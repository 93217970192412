import React, { useRef, useEffect } from "react";
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from "video.js";
import "videojs-contrib-ads";
import "videojs-ima";
import "video.js/dist/video-js.css";

type ImaConfig = {
  adTagUrl?: string;
  adsResponse?: string;
};

type Props = {
  options: VideoJsPlayerOptions;
  ima?: ImaConfig;
};

type VideoPlayer = VideoJsPlayer & {
  ima: (options: ImaConfig) => void;
};

const Video = (props: Props) => {
  const videoRef = useRef<HTMLDivElement | null>(null);
  const playerRef = useRef<VideoPlayer | null>(null);
  const { options, ima } = props;

  useEffect(() => {
    const videoElement = document.createElement("video-js");
    videoElement.classList.add("vjs-big-play-centered");

    if (videoRef.current !== null) {
      videoRef.current.appendChild(videoElement);
    }

    const player = videojs(videoElement, options) as VideoPlayer;
    playerRef.current = player;

    if (ima) {
      player.ima(ima);
    }

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [options, ima]);

  return (
    <div data-vjs-player>
      <div ref={videoRef} />
    </div>
  );
};

export { Video };
