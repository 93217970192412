import { WeeklySnapshotScreen } from "../../../../types";
import { createScreen } from "utils/createScreen";

const WeeklySnapshotScreenDefinition = createScreen({
  name: WeeklySnapshotScreen,
  path: "/dashboard/weekly-snapshot/:campaignId/:view",
  routePath: ["/weekly-snapshot/", "/weekly-snapshot/:campaignId/:view"],
  accessPath: "campaign.weeklySnapshot",
});

export { WeeklySnapshotScreenDefinition };
