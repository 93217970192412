import { Box, Flex, useColorModeValue } from "@chakra-ui/react";
import { WithChildren } from "types";

const AgreementContainer = ({ children }: WithChildren) => {
  return (
    <Flex
      minHeight="100vh"
      width="full"
      align="center"
      justifyContent="center"
      bg={useColorModeValue("gray.800", "gray.800")}
    >
      <Box
        borderWidth={0}
        width="full"
        maxWidth="640px"
        borderRadius={4}
        boxShadow="2xl"
        marginTop="32"
        bg={useColorModeValue("gray.700", "gray.700")}
        overflowY="auto"
      >
        <Box>{children}</Box>
      </Box>
    </Flex>
  );
};

export { AgreementContainer };
