import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  SimpleGrid,
  useDisclosure,
} from "@chakra-ui/react";
import { User } from "@intentsify/types";
import { FaLink } from "react-icons/fa";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { useHasAccessTo } from "@intentsify/authorization/dist/react";
import {
  PublisherAssignCampaignsTable,
  PublisherCampaignsTable,
} from "./components";
import { SinglePublisherScreenDefinition } from "./SinglePublisher.definition";
import { apiService } from "api";
import { Button, ViewContainer } from "components";
import { useComponentColors } from "theme";
import { useScreen } from "utils";

const SinglePublisher = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { id } = useParams<"id">();

  if (!id) {
    throw Error("404");
  }

  const { data: userDetails } = useQuery(["singlePublisher", id], () =>
    apiService.get<User>(`users/${id}`)
  );

  const {
    form: { content, stackBg },
  } = useComponentColors();

  const hasAccessToAssignCampaign = useHasAccessTo("publisher");

  const publisherName = userDetails ? userDetails.data.displayName : undefined;

  useScreen(SinglePublisherScreenDefinition, { subtitle: publisherName });

  return (
    <>
      <ViewContainer>
        <SimpleGrid columns={1}>
          <Heading pb={4} as="h2" size="md" display="flex">
            Assigned campaigns
            <Box flexGrow={1}></Box>
            {hasAccessToAssignCampaign && (
              <Button leftIcon={<FaLink />} variant="primary" onClick={onOpen}>
                Assign Campaigns
              </Button>
            )}
          </Heading>
          <Box>
            <PublisherCampaignsTable />
          </Box>
        </SimpleGrid>

        <Drawer size="lg" isOpen={isOpen} onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent bg={content}>
            <DrawerCloseButton />
            <DrawerHeader mb="2" bg={stackBg}>
              Assign campaigns
            </DrawerHeader>
            <DrawerBody>
              <PublisherAssignCampaignsTable />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </ViewContainer>
    </>
  );
};

export { SinglePublisher };
