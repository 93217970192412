import { Divider, SimpleGrid } from "@chakra-ui/react";
import { ViewContainer } from "components";
import { useUser } from "store/store.hooks";
import { useScreen } from "utils";
import { UserSettingsScreenDefinition } from "./UserSettings.definition";
import { AdvancedSettings } from "./components/AdvancedSettings/AdvancedSettings";
import { IntegrationSettings } from "./components/IntegrationSettings/IntegrationSettings";
import { NotificationSettings } from "./components/NotificationSettings";

const IntegrationSettings_ONLY_ENABLED_ON_PRODUCTION = () => {
  const user = useUser();
  const companyId = Number(user?.companyId);

  if (process.env.VITE_NODE_ENV !== "production" || !companyId) {
    return null;
  }

  return (
    <>
      <Divider orientation="horizontal" />
      <IntegrationSettings />
    </>
  );
};

const UserSettings = () => {
  useScreen(UserSettingsScreenDefinition);

  return (
    <SimpleGrid column={1}>
      <ViewContainer compact>
        <NotificationSettings />
        <IntegrationSettings_ONLY_ENABLED_ON_PRODUCTION />
        <AdvancedSettings />
      </ViewContainer>
    </SimpleGrid>
  );
};

export { UserSettings };
