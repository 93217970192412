import { VStack } from "@chakra-ui/react";
import { useEffect, useMemo } from "react";
import { Company } from "@intentsify/types";
import { getIntegrationWithLogo } from "./IntegrationsModal.utils";
import { IntegrationProvider } from "./IntegrationProvider/IntegrationProvider";
import { useEstablishWorkatoMapping } from "./mutations/useEstablishWorkatoMapping";

const useEstablishWorkatoMappingAutomatically = (
  companyId: Company["companyId"]
) => {
  const { mutate: establishWorkatoMapping } = useEstablishWorkatoMapping();

  useEffect(() => {
    establishWorkatoMapping(companyId);
  }, [establishWorkatoMapping, companyId]);
};

const IntegrationsModalBodyContent = (props: { company: Company }) => {
  const integrations = useMemo(
    () => getIntegrationWithLogo(props.company.integrations),
    [props.company.integrations]
  );

  useEstablishWorkatoMappingAutomatically(props.company.companyId);

  return (
    <>
      {integrations.map((integration) => (
        <IntegrationProvider
          key={integration.provider}
          integration={integration}
          companyId={props.company.companyId}
        />
      ))}
    </>
  );
};

export const IntegrationsModalBody = (props: { company: Company }) => {
  return (
    <VStack spacing="6">
      <IntegrationsModalBodyContent {...props} />
    </VStack>
  );
};
