import { useColorMode } from "@chakra-ui/react";
import { isDefined } from "@intentsify/utils";
import groupBy from "lodash/groupBy";
import { DateTime } from "luxon";
import { useCallback, useMemo } from "react";
import { Colour, colours, isModifiableColour } from "theme";
import { getChartColorsByPalette } from "../Charts.utils";
import { TimeseriesProps } from "./Timeseries";
import { generateTimeValuesSeq } from "./Timeseries.utils";

const timeseriesPallete = colours.reduce((prev, curr) => {
  return {
    ...prev,
    [curr]: {
      light: getChartColorsByPalette("light", curr),
      dark: getChartColorsByPalette("dark", curr),
    },
  };
}, {} as Record<Colour, { dark: string[]; light: string[] }>);

export const useGetColour = () => {
  const { colorMode } = useColorMode();

  return useCallback(
    (colour: Colour) => {
      const c = timeseriesPallete[colour];
      if (isModifiableColour(colour)) {
        return getChartColorsByPalette(colorMode, colour);
      }
      return colorMode === "light" ? c.light : c.dark;
    },
    [colorMode]
  );
};

export const createGetColour = (used: Colour[]) => {
  const picked: Colour[] = [];
  let colorOffset = 0;

  return () => {
    const availableColors = Object.entries(timeseriesPallete) as Array<
      [Colour, { dark: string[]; light: string[] }]
    >;

    if (availableColors.length === picked.length) {
      // reset
      picked.length = 0;
      colorOffset += 100;
    }

    const left = availableColors.filter(
      (i) => ![...used, ...picked].includes(i[0])
    );

    const pick = left[0];
    picked.push(pick[0]);
    return (colorOffset ? `${pick[0]}.100` : pick[0]) as Colour;
  };
};

interface UseTimeseriesDataOptions {
  markers?: TimeseriesProps["markers"];
  timeGap?: number;
}

export const useTimeseriesData = (
  leftAxisBars: TimeseriesProps["leftAxisBars"] = [],
  rightAxisBars: TimeseriesProps["rightAxisBars"] = [],
  leftAxisLines: TimeseriesProps["leftAxisLines"] = [],
  rightAxisLines: TimeseriesProps["rightAxisLines"] = [],
  { markers = [], timeGap }: UseTimeseriesDataOptions
) => {
  const coloursUsed = useMemo(() => {
    return [
      ...leftAxisBars,
      ...rightAxisBars,
      ...leftAxisLines,
      ...rightAxisLines,
    ]
      .flatMap((i) => i.legend)
      .map((i) => i.color)
      .filter(isDefined);
  }, [leftAxisBars, rightAxisBars, leftAxisLines, rightAxisLines]);

  const getColour = createGetColour(coloursUsed);

  const leftAxisValues = useMemo(() => {
    return [
      ...leftAxisBars.flatMap((i) => i.data).map((i) => i.value),
      ...leftAxisLines.flatMap((i) => i.data).map((i) => i.value),
    ];
  }, [leftAxisBars, leftAxisLines]);

  const rightAxisValues = useMemo(() => {
    return [
      ...rightAxisBars.flatMap((i) => i.data).map((i) => i.value),
      ...rightAxisLines.flatMap((i) => i.data).map((i) => i.value),
    ];
  }, [rightAxisBars, rightAxisLines]);

  const timeValues = useMemo(() => {
    const dates = [
      ...leftAxisBars,
      ...rightAxisBars,
      ...leftAxisLines,
      ...rightAxisLines,
    ]
      .flatMap((i) => i.data)
      .map((i) => i.isoDate)
      .concat(markers.map((i) => i.isoDate));

    if (typeof timeGap !== "number") {
      return [...new Set(dates)].sort(
        (a, b) =>
          DateTime.fromISO(a, { zone: "utc" }).valueOf() -
          DateTime.fromISO(b, { zone: "utc" }).valueOf()
      );
    } else {
      return generateTimeValuesSeq(timeGap, dates);
    }
  }, [
    leftAxisBars,
    rightAxisBars,
    leftAxisLines,
    rightAxisLines,
    markers,
    timeGap,
  ]);

  const sortedLeftAxisBars = useMemo(() => {
    return leftAxisBars.map((i) => {
      return {
        data: [...i.data].sort(
          (a, b) =>
            DateTime.fromISO(a.isoDate, { zone: "utc" }).valueOf() -
            DateTime.fromISO(b.isoDate, { zone: "utc" }).valueOf()
        ),
        legend: {
          ...i.legend,
          color: i.legend.color ?? getColour(),
          muteColor: i.legend.muteColor ?? false,
        },
      };
    });
  }, [getColour, leftAxisBars]);

  const sortedRightAxisBars = useMemo(() => {
    return rightAxisBars.map((i) => {
      return {
        data: [...i.data].sort(
          (a, b) =>
            DateTime.fromISO(a.isoDate, { zone: "utc" }).valueOf() -
            DateTime.fromISO(b.isoDate, { zone: "utc" }).valueOf()
        ),
        legend: {
          ...i.legend,
          color: i.legend.color ?? getColour(),
          muteColor: i.legend.muteColor ?? false,
        },
      };
    });
  }, [getColour, rightAxisBars]);

  const sortedLeftAxisLines = useMemo(() => {
    return leftAxisLines.map((i) => {
      return {
        data: [...i.data].sort(
          (a, b) =>
            DateTime.fromISO(a.isoDate, { zone: "utc" }).valueOf() -
            DateTime.fromISO(b.isoDate, { zone: "utc" }).valueOf()
        ),
        legend: {
          ...i.legend,
          color: i.legend.color ?? getColour(),
          muteColor: i.legend.muteColor ?? false,
        },
      };
    });
  }, [getColour, leftAxisLines]);

  const sortedRightAxisLines = useMemo(() => {
    return rightAxisLines.map((i) => {
      return {
        data: [...i.data].sort(
          (a, b) =>
            DateTime.fromISO(a.isoDate, { zone: "utc" }).valueOf() -
            DateTime.fromISO(b.isoDate, { zone: "utc" }).valueOf()
        ),
        legend: {
          ...i.legend,
          color: i.legend.color ?? getColour(),
          muteColor: i.legend.muteColor ?? false,
        },
      };
    });
  }, [getColour, rightAxisLines]);

  const barItemsWithAxis = useMemo(() => {
    return [
      ...sortedLeftAxisBars.flatMap((i) => {
        return i.data.map((d) => ({
          ...d,
          legend: i.legend,
          axis: "left" as const,
        }));
      }),
      ...sortedRightAxisBars.flatMap((i) => {
        return i.data.map((d) => ({
          ...d,
          legend: i.legend,
          axis: "right" as const,
        }));
      }),
    ];
  }, [sortedLeftAxisBars, sortedRightAxisBars]);

  return {
    leftAxisValues,
    rightAxisValues,
    timeValues,
    sorted: {
      leftAxisBars: sortedLeftAxisBars,
      rightAxisBars: sortedRightAxisBars,
      leftAxisLines: sortedLeftAxisLines,
      rightAxisLines: sortedRightAxisLines,
    },
    groupedLegendItems: groupBy(
      [
        ...sortedLeftAxisLines,
        ...sortedRightAxisLines,
        ...sortedLeftAxisBars,
        ...sortedRightAxisBars,
      ],
      (item) => item.legend.group ?? ""
    ),
    barItemsWithAxis,
  };
};
