import { Text, useDisclosure } from "@chakra-ui/react";
import { TargetPersona } from "@intentsify/types";
import { pluralize } from "@intentsify/utils";
import { Button } from "components";
import { useConfirm } from "components/ConfirmDialog/useConfirm";
import { TargetPersonasForm } from "./Form";
import {
  TargetPersonasModal,
  TargetPersonasModalBody,
  TargetPersonasModalHeader,
} from "./Modal";
import { useUpdateTargetPersonas } from "./useUpdateTargetPersonas";

export const BatchUpdateModal = (props: {
  targetPersonas: TargetPersona[];
}) => {
  const confirm = useConfirm();
  const disclosure = useDisclosure();
  const updateReviewedAssets = useUpdateTargetPersonas(disclosure.onClose);

  return (
    <>
      <TargetPersonasModal {...disclosure}>
        <TargetPersonasModalHeader>
          Bulk edit {props.targetPersonas.length}{" "}
          {pluralize(
            props.targetPersonas.length,
            "target persona",
            "target personas"
          )}
          <Text
            fontSize="sm"
            mt="2"
            color="blackAlpha.700"
            _dark={{ color: "whiteAlpha.700" }}
          >
            {props.targetPersonas.map((r) => r.name).join(", ")}
          </Text>
        </TargetPersonasModalHeader>

        <TargetPersonasModalBody>
          <TargetPersonasForm
            omitName
            isLoading={updateReviewedAssets.isLoading}
            onSubmit={async (data) => {
              const targetPersonas = pluralize(
                props.targetPersonas.length,
                "target persona",
                "target personas"
              );

              if (
                !(await confirm({
                  title: "Do you really want to bulk edit?",
                  description: `You are about to update ${props.targetPersonas.length} ${targetPersonas}. This action cannot be undone.`,
                  confirmText: `Yes, update ${props.targetPersonas.length} ${targetPersonas}`,
                }))
              ) {
                return;
              }

              updateReviewedAssets.mutate(
                props.targetPersonas.map((tp) => ({ ...tp, ...data }))
              );
            }}
          />
        </TargetPersonasModalBody>
      </TargetPersonasModal>

      <Button onClick={disclosure.onOpen}>
        Update {props.targetPersonas.length} target{" "}
        {pluralize(props.targetPersonas.length, "persona", "personas")}
      </Button>
    </>
  );
};
