import { LeadPerformanceScreen } from "types";
import { createScreen } from "utils/createScreen";

const CampaignLeadPerformanceScreenDefinition = createScreen({
  name: LeadPerformanceScreen,
  path: "/dashboard/campaign-pacing/campaign-lead-performance/:campaignId",
  routePath: [
    "/campaign-pacing/campaign-lead-performance/:campaignId",
    "/campaign-pacing/campaign-lead-performance",
  ],
  accessPath: "campaign.detailedPerformance",
});

export { CampaignLeadPerformanceScreenDefinition };
