import { GetModulePermissionsRO } from "@intentsify/dto";
import { AuthenticatedUser } from "@intentsify/types";
import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { Endpoints, apiService } from "api";
import { Duration } from "luxon";
import { useUser } from "store/store.hooks";
import { useAppStore } from "store/useAppStore";
import { AsyncReturnType } from "type-fest";

const getUserPermissionModules = async () => {
  const { data: permissionModules } = await apiService.get<
    GetModulePermissionsRO["permissionModules"]
  >(Endpoints.Permissions.Get.PermissionModules);

  return permissionModules;
};

type UseGetUserPermissionModulesOptions = UseQueryOptions<
  AsyncReturnType<typeof getUserPermissionModules>
>;

export const useUserPermissionModulesConfig = (
  queryOptions?: UseGetUserPermissionModulesOptions
) => {
  const setImpersonation = useAppStore.use.setImpersonation();
  const impersonation = useAppStore.use.impersonation();
  const setUser = useAppStore.use.setUser();
  const user = useUser() as AuthenticatedUser & {
    permissions: unknown;
  };

  const enabled =
    Boolean(user) &&
    (typeof queryOptions?.enabled === "undefined"
      ? true
      : queryOptions?.enabled);

  return {
    enabled,
    queryKey: ["getUserPermissionModules", user?.userId],
    queryFn: getUserPermissionModules,
    keepPreviousData: true,
    staleTime: Duration.fromObject({ minutes: 10 }).as("millisecond"),
    onSuccess: (data) => {
      const { permissions: _permissions, ...restUser } = user;
      const nextUserData: AuthenticatedUser = {
        ...restUser,
        permissionModules: data,
      };

      if (impersonation) {
        setImpersonation({ impersonates: nextUserData });
      } else {
        setUser(nextUserData);
      }
    },
  } satisfies UseGetUserPermissionModulesOptions;
};

export const useUserPermissionModules = (
  queryOptions?: UseGetUserPermissionModulesOptions
) => {
  return useQuery(useUserPermissionModulesConfig(queryOptions));
};
