import { ColumnDef } from "@tanstack/react-table";
import { FiFileText } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { PublisherForList } from "@intentsify/types";
import { SinglePublisherScreenDefinition } from "../SinglePublisher/SinglePublisher.definition";
import { ActionItems } from "types";
import { Actions, EmptyCell } from "components";

interface ActionCellProps {
  id: number;
}

const ActionsCell = ({ id }: ActionCellProps) => {
  const navigate = useNavigate();

  const items: ActionItems[] = [
    {
      label: SinglePublisherScreenDefinition.name,
      accessPath: "publisher",
      icon: <FiFileText />,
      onClick: () => navigate(SinglePublisherScreenDefinition.navigate({ id })),
    },
  ];

  return <Actions items={items} />;
};

const publishersTableColumns: ColumnDef<PublisherForList>[] = [
  {
    header: "ID",
    accessorKey: "userId",
  },

  {
    header: "Name",
    accessorKey: "displayName",
  },

  {
    header: "Company",
    accessorKey: "company",
  },
  {
    header: "Actions",

    enableSorting: false,
    cell: ({ row }) => {
      return row.original?.userId ? (
        <ActionsCell id={row.original.userId} />
      ) : (
        <EmptyCell />
      );
    },
  },
];

export { publishersTableColumns };
