import { TrendingTopicsAndKeywordsScreen } from "../../../../types";
import { createScreen } from "utils/createScreen";

const TrendingTopicsAndKeywordsScreenDefinition = createScreen({
  name: TrendingTopicsAndKeywordsScreen,
  path: "/dashboard/campaigns-trending-topics-and-keywords/:campaignId",
  routePath: [
    "/campaigns-trending-topics-and-keywords/",
    "/campaigns-trending-topics-and-keywords/:campaignId",
  ],
  accessPath: "campaign.accountsTopicsAndKeywords",
});

export { TrendingTopicsAndKeywordsScreenDefinition };
