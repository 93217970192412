import { useMemo } from "react";
import { useGetHasAccessTo } from "@intentsify/authorization/dist/react";
import { linkGroups } from "./SideBar.linkGroups";

const useLinkGroups = () => {
  const hasAccessTo = useGetHasAccessTo();

  return useMemo(
    () =>
      linkGroups
        .map((linkGroup) => ({
          ...linkGroup,
          items: linkGroup.items.filter((item) => hasAccessTo(item.accessPath)),
        }))
        .filter((linkGroup) => linkGroup.items.length > 0),
    [hasAccessTo]
  );
};

export { useLinkGroups };
