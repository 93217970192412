import { Flex, Text } from "@chakra-ui/layout";
import { optOutSupportEmail } from "./supportEmail";

export function OptOutDisplayError() {
  return (
    <Flex display="flex" flexDir="column" w="full" maxWidth="lg" mx="auto">
      <Text fontSize="xl">An error occurred</Text>
      <Text
        color="blackAlpha.600"
        _dark={{ color: "whiteAlpha.600" }}
        style={{ textWrap: "balance" } as any}
      >
        Please try again later. If the problem persists, please contact support
        at <a href={`mailto:${optOutSupportEmail}`}>{optOutSupportEmail}</a>.
      </Text>
    </Flex>
  );
}
