import {
  chakra,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  SimpleGrid,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { resetPasswordRequest } from "./ResetPasswordForm.requests";
import { BackToLoginLink } from ".";
import { Button } from "components";
import { LoginScreenDefinition } from "auth/screens/Login/Login.definition";

const validationSchema = z.object({
  email: z.string().email(),
});

type FieldValues = z.infer<typeof validationSchema>;

const ResetPasswordForm = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FieldValues>({
    mode: "onChange",
    resolver: zodResolver(validationSchema),
  });

  const { mutate, isLoading } = useMutation(resetPasswordRequest, {
    onSuccess: () => {
      toast({
        title: "Email with reset password link was sent to the provided email.",
        status: "success",
      });
      navigate(LoginScreenDefinition.navigate());
    },
  });

  const onSubmit = (values: FieldValues) => {
    mutate(values);
  };

  return (
    <Flex justifyContent="center">
      <chakra.form
        onSubmit={(data) => {
          handleSubmit(onSubmit)(data);
        }}
        w="100%"
      >
        <SimpleGrid columns={1} spacing={4}>
          <FormControl isInvalid={!!errors?.email?.message} isRequired>
            <FormLabel color="white">Email</FormLabel>
            <Input
              {...register("email")}
              bg="gray.100"
              color="gray.600"
              _placeholder={{ color: "gray.400" }}
              type="email"
              name="email"
              placeholder="Email"
            />
            <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
          </FormControl>

          <BackToLoginLink />

          <Button
            size="md"
            type="submit"
            fullWidth
            isLoading={isLoading}
            isDisabled={!!errors.email || isLoading}
          >
            Reset Password
          </Button>
        </SimpleGrid>
      </chakra.form>
    </Flex>
  );
};

export { ResetPasswordForm };
