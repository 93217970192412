import { Flex } from "@chakra-ui/react";
import { useHasAccessTo } from "@intentsify/authorization/dist/react";
import { UserForList } from "@intentsify/types";
import { useQuery } from "@tanstack/react-query";
import { Button, Search, Table, ViewContainer } from "components";
import { PaginationType } from "components/Table/Table/Table.types";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router";
import { useTrackSearched } from "tracking/useTrackSearched";
import { FetchDataParamsWithToken } from "types";
import { useScreen } from "utils";
import { CreateUserScreenDefinition } from "../CreateUser/CreateUser.definition";
import { userTableColumns } from "./Users.columns";
import { UsersScreenDefinition } from "./Users.definition";
import { defaultListPaginatedUsersParams, listUsers } from "./Users.requests";

const Users = () => {
  useScreen(UsersScreenDefinition);
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);

  const handleRedirectToCreateUser = () => {
    navigate(CreateUserScreenDefinition.navigate());
  };

  const [fetchDataParams, setFetchDataParams] = useState<
    FetchDataParamsWithToken<keyof UserForList>
  >(defaultListPaginatedUsersParams);

  const { data, isFetching } = useQuery(["listUsers", fetchDataParams], () =>
    listUsers(fetchDataParams)
  );

  const handleOnFetchDataChange = useCallback(
    (params: FetchDataParamsWithToken<keyof UserForList>) => {
      setFetchDataParams(params);
    },
    []
  );

  const hasAccessToCreateUser = useHasAccessTo("user.create");
  const trackSearched = useTrackSearched();

  return (
    <>
      <ViewContainer>
        <Flex alignItems="center">
          <Search
            currentValue={searchValue}
            onSearch={(v) => {
              setSearchValue(v);
              trackSearched({ term: v, collocation: "Users table" });
            }}
          />

          <Flex grow={1} />

          {hasAccessToCreateUser && (
            <Button variant="primary-teal" onClick={handleRedirectToCreateUser}>
              Create User
            </Button>
          )}
        </Flex>

        <Table
          variant="intentsifyNew"
          stretch={false}
          searchValue={searchValue}
          defaultSort={[{ id: "userId", desc: true }]}
          isFetching={isFetching}
          columns={userTableColumns}
          data={data?.users || []}
          onFetchDataChange={handleOnFetchDataChange}
          resetPaginationDependencies={[searchValue]}
          paginationType={PaginationType.TOKEN}
          nextPageToken={data?.nextPageToken}
        />
      </ViewContainer>
    </>
  );
};

export { Users };
