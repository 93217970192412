import { ListPixelIdentifiersRO } from "@intentsify/dto";
import { apiService, Endpoints } from "api";
import { Item } from "types";

export const getCampaignPixelIds = async (): Promise<Item<number>[]> => {
  const { data } = await apiService.get<ListPixelIdentifiersRO>(
    Endpoints.Signals.Get.PixelIdentifiers
  );

  return data.pixelIdentifiers.map((pixel) => ({
    value: pixel.pixelId,
    label: pixel.displayName,
  }));
};
