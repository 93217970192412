import { TusStorage, createTusUpload } from "@intentsify/tus/dist/react";
import { Endpoints } from "api";

export const bulkEditUpload = createTusUpload({
  autoResumeUploads: false,
  uploadUrl: new URL(
    `${process.env.VITE_REACT_APP_API_URL ?? `http://localhost:3001`}${
      Endpoints.AccountsUpload.All.Upload
    }`
  ),
  storage: new TusStorage("bulkEdit"),
});
