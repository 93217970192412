import { Flex, Th, Tr, useColorModeValue } from "@chakra-ui/react";
import {
  HeaderGroup as ReactTableHeaderGroup,
  Header as ReactTableHeader,
  flexRender,
  RowData,
} from "@tanstack/react-table";

interface SimpleHeaderProps<Data extends RowData, V> {
  header: ReactTableHeader<Data, V>;
}

const SimpleHeader = <Data extends RowData, V>({
  header,
}: SimpleHeaderProps<Data, V>) => {
  const color = useColorModeValue("gray.300", "gray.500");

  return (
    <Th bg="transparent" color={color} pt="0" pl="0" pr="0" pb="2">
      <Flex alignItems="center">
        {header?.isPlaceholder
          ? null
          : flexRender(header.column.columnDef.header, header.getContext())}
      </Flex>
    </Th>
  );
};

interface SimpleHeaderGroupProps<Data extends RowData> {
  headerGroup: ReactTableHeaderGroup<Data>;
}

export const SimpleHeaderGroup = <Data extends RowData>({
  headerGroup,
}: SimpleHeaderGroupProps<Data>) => {
  return (
    <Tr position="sticky" top="0" rounded={"md"} zIndex="2">
      {headerGroup.headers.map((header) => (
        <SimpleHeader key={header.id} header={header} />
      ))}
    </Tr>
  );
};
