import { memo } from "react";
import {
  ColumnDef,
  getCoreRowModel,
  RowData,
  useReactTable,
} from "@tanstack/react-table";
import {
  Flex,
  FlexProps,
  Table as ChakraTable,
  Tbody,
  Td,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useColumns } from "../Table/useColumns";
import { SimpleHeaderGroup } from "./SimpleHeaderGroup";
import { SimpleRow } from "./SimpleRow";
import { NonIdealStateOptions, useNonIdealState } from "./useNonIdealState";

export interface SimpleTablePublicProps {
  containerProps?: FlexProps;
  nonIdealState?: NonIdealStateOptions;
}

interface SimpleTableProps<Data extends RowData>
  extends SimpleTablePublicProps {
  data: Data[];
  columns: ColumnDef<Data>[];
}

const SimpleTableInner = <Data extends RowData>(
  props: SimpleTableProps<Data>
) => {
  const columns = useColumns<Data>(props.columns);
  const table = useReactTable({
    columns,
    data: props.data,
    getCoreRowModel: getCoreRowModel(),
  });
  const { rows } = table.getRowModel();

  const NonIdealStateComponent = useNonIdealState(props.nonIdealState);
  const NonIdealState = memo(NonIdealStateComponent);

  return (
    <Flex
      direction="column"
      fontWeight="md"
      rounded="md"
      shadow="sm"
      maxWidth="100%"
      {...props.containerProps}
    >
      <ChakraTable position="relative" size="sm">
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <SimpleHeaderGroup key={headerGroup.id} headerGroup={headerGroup} />
          ))}
        </Thead>

        <Tbody>
          {rows.length === 0 && (
            <Tr>
              <Td
                pl="0"
                pr="0"
                py="2"
                colSpan={columns.length}
                borderColor="transparent"
              >
                <NonIdealState />
              </Td>
            </Tr>
          )}

          {rows.map((row) => (
            <SimpleRow key={row.id} row={row} />
          ))}
        </Tbody>
      </ChakraTable>
    </Flex>
  );
};

export const SimpleTable = memo(SimpleTableInner) as typeof SimpleTableInner;
