import { useCallback, useRef } from "react";
import { Simplify } from "type-fest";
import {
  PublicConfirmDialogState,
  useSetConfirmDialogState,
} from "./ConfirmDialog.state";
import {
  makeDialogId,
  useSetConfirmDialogIdsState,
} from "./ConfirmDialog.stateIds";

/**
 * Hook to show a confirm dialog.
 *
 * @example
 * ```tsx
 * const DeleteButton = () => {
 *   const confirm = useConfirm();
 *
 *   const handleDelete = async () => {
 *     if (await confirm({
 *       title: "Delete this thing?",
 *       description: "Are you sure you want to delete this thing?",
 *       confirmText: "Delete",
 *     })) {
 *       // delete the thing
 *     }
 *   };
 *
 *   return <Button onClick={handleDelete}>Delete</Button>;
 * };
 * ```
 *
 * @example default dialog options
 * ```tsx
 * const DeleteButton = () => {
 *   const confirm = useConfirm({ confirmText: "Delete" });
 *
 *   const handleDelete = async () => {
 *     if (await confirm({
 *       title: "Delete this thing?",
 *       description: "Are you sure you want to delete this thing?",
 *     })) {
 *       // delete the thing
 *     }
 *   };
 *
 *   return <Button onClick={handleDelete}>Delete</Button>;
 * };
 * ```
 */
export const useConfirm = (
  defaultDialog?: Simplify<PublicConfirmDialogState>
) => {
  const dialogId = useRef(makeDialogId());
  const setConfirmDialogIdsState = useSetConfirmDialogIdsState();
  const setConfirmDialogState = useSetConfirmDialogState(dialogId.current);

  return useCallback(
    (dialog?: Simplify<PublicConfirmDialogState>): Promise<boolean> => {
      setConfirmDialogIdsState(
        (state) => new Set([...state, dialogId.current])
      );

      return new Promise<boolean>((resolve) => {
        setConfirmDialogState((state) => ({
          ...state,
          ...defaultDialog,
          ...dialog,
          promise: { resolve },
        }));
      });
    },
    [defaultDialog, setConfirmDialogIdsState, setConfirmDialogState]
  );
};
