import {
  Box,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from "@chakra-ui/react";
import { TempAccountFileDTO } from "@intentsify/dto";
import { Button } from "components";
import { useState } from "react";
import { useComponentColors } from "theme";
import { DEFAULT_LOOKALIKE_DOMAINS, MAX_LOOKALIKE_DOMAINS } from "./SmarTAL";
import { useSmarTAL } from "./useSmarTAL";

const SmarTALForm = ({
  selectedFile,
}: {
  selectedFile: TempAccountFileDTO | undefined;
}) => {
  const componentColors = useComponentColors();
  const { mutateAsync: requestSmarTAL, isLoading: isSubmitting } = useSmarTAL();
  const [maxLookalikeDomains, setMaxLookalikeDomains] = useState(
    DEFAULT_LOOKALIKE_DOMAINS
  );

  const onSubmit = () => {
    if (!selectedFile) {
      return;
    }

    requestSmarTAL({
      file: selectedFile,
      maxLookalikeDomains,
    });
  };

  return (
    <Box>
      <FormControl mt={4}>
        <FormLabel
          fontSize="sm"
          fontWeight="md"
          color={componentColors.form.formLabelColor}
        >
          Maximum # of lookalike domains to be returned
        </FormLabel>
        <NumberInput
          shadow="sm"
          size="sm"
          rounded="md"
          width="2xs"
          max={MAX_LOOKALIKE_DOMAINS}
          min={1}
          step={50}
          value={maxLookalikeDomains}
          onChange={(num) => setMaxLookalikeDomains(Number(num))}
        >
          <NumberInputField />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </FormControl>

      <Divider mt={4} />

      <Flex mt={4} justifyContent="flex-end">
        <Button
          size="md"
          variant="primary-teal"
          isLoading={isSubmitting}
          isDisabled={!selectedFile}
          onClick={onSubmit}
        >
          Process SmartTAL
        </Button>
      </Flex>
    </Box>
  );
};

export { SmarTALForm };
