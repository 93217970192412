import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { ProgrammaticDomainsFileDetailsScreenDefinition } from "./ProgrammaticDomainsFileDetails.definition";
import { getProgrammaticDomainFileWithSegmentsById } from "./ProgrammaticDomainsFileDetails.requests";
import { FileDetails } from "./components";
import { useScreen } from "utils";
import { Loader } from "components/Loader/Loader";
import { ViewContainer } from "components";

const ProgrammaticDomainsDetails = () => {
  useScreen(ProgrammaticDomainsFileDetailsScreenDefinition);

  const { programmaticDomainsFileId } =
    useParams<"programmaticDomainsFileId">();

  if (!programmaticDomainsFileId) {
    throw new Error("404");
  }

  const { data: programmaticDomainsFile } = useQuery(
    [`getProgrammaticDomainsFileById_${programmaticDomainsFileId}`],
    () =>
      getProgrammaticDomainFileWithSegmentsById(
        Number(programmaticDomainsFileId)
      )
  );

  return (
    <>
      {programmaticDomainsFile ? (
        <>
          <ViewContainer>
            <FileDetails programmaticDomainsFile={programmaticDomainsFile} />
          </ViewContainer>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export { ProgrammaticDomainsDetails };
