import { Flex } from "@chakra-ui/react";
import { HasAccess } from "@intentsify/authorization/dist/react";
import { CompanyForList } from "@intentsify/types";
import { Button, Search, Table, ViewContainer } from "components";
import { PaginationType } from "components/Table/Table/Table.types";
import {
  defaultListPaginatedCompaniesParams,
  usePaginatedCompanies,
} from "queries/companies/usePaginatedCompanies";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTrackSearched } from "tracking/useTrackSearched";
import { FetchDataParamsWithToken } from "types";
import { useScreen } from "utils";
import { CreateCompanyScreenDefinition } from "../CreateCompany/CreateCompany.definition";
import { useCompaniesTableColumns } from "./Companies.columns";
import { CompaniesScreenDefinition } from "./Companies.definition";

const Companies = () => {
  useScreen(CompaniesScreenDefinition);
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const companiesTableColumns = useCompaniesTableColumns();

  const [fetchDataParams, setFetchDataParams] = useState<
    FetchDataParamsWithToken<keyof CompanyForList>
  >(defaultListPaginatedCompaniesParams);

  const { data, isFetching } = usePaginatedCompanies(fetchDataParams);

  const handleOnFetchDataChange = useCallback(
    (params: FetchDataParamsWithToken<keyof CompanyForList>) => {
      setFetchDataParams(params);
    },
    []
  );

  const trackSearched = useTrackSearched();

  return (
    <ViewContainer>
      <Flex alignItems="center">
        <Search
          currentValue={searchValue}
          onSearch={(v) => {
            setSearchValue(v);
            trackSearched({ term: v, collocation: "Companies table" });
          }}
        />
        <Flex grow={1} />
        <HasAccess to="company.create">
          <Button
            variant="primary-teal"
            onClick={() => navigate(CreateCompanyScreenDefinition.navigate())}
          >
            {CreateCompanyScreenDefinition.name}
          </Button>
        </HasAccess>
      </Flex>

      <Table
        variant="intentsifyNew"
        searchValue={searchValue}
        defaultSort={[
          {
            id: "companyId",
            desc: true,
          },
        ]}
        columns={companiesTableColumns}
        isFetching={isFetching}
        data={data?.companies || []}
        onFetchDataChange={handleOnFetchDataChange}
        paginationType={PaginationType.TOKEN}
        nextPageToken={data?.nextPageToken}
        resetPaginationDependencies={[searchValue]}
      />
    </ViewContainer>
  );
};

export { Companies };
