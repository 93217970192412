import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  AudienceSegment,
  AudienceSegmentDefinition,
  AudienceSegmentOption,
  audienceSegmentSchema,
} from "@intentsify/types";
import { Button, ErrorBoundary, Modal } from "components";
import { useForm } from "react-hook-form";
import { useComponentColors } from "theme";
import { useCreateAudienceSegment } from "../../AudienceSegments.requests";

type AudienceSegmentsModalProps = {
  companyId: number;
  onCreate: (value: AudienceSegmentOption) => void;
  isOpen: boolean;
  onClose: () => void;
  definition: AudienceSegmentDefinition;
};

const AudienceSegmentsModal = ({
  isOpen,
  onCreate,
  onClose,
  definition,
  companyId,
}: AudienceSegmentsModalProps) => {
  const componentColors = useComponentColors();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<AudienceSegment>({
    mode: "onChange",
    resolver: zodResolver(audienceSegmentSchema),
  });

  const { mutate, isLoading } = useCreateAudienceSegment(onCreate);

  const onSubmit = (data: AudienceSegment) => {
    mutate({
      companyId,
      params: { ...data, definition },
    });
    onClose();
    setValue("name", "");
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
        setValue("name", "");
      }}
      title="Save As"
      body={
        <ErrorBoundary>
          <Stack>
            <Flex
              as="form"
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onSubmit={handleSubmit(onSubmit)}
            >
              <FormControl isRequired>
                <FormLabel
                  fontSize="sm"
                  fontWeight="md"
                  color={componentColors.form.formLabelColor}
                >
                  Segment Name
                </FormLabel>
                <Input
                  {...register("name")}
                  type="text"
                  placeholder="Name"
                  shadow="sm"
                  size="sm"
                  rounded="md"
                  autoComplete="off"
                  isRequired
                />
              </FormControl>
              <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
            </Flex>
          </Stack>
          <Flex marginY={6} justifyContent="flex-end"></Flex>
        </ErrorBoundary>
      }
      primaryButton={
        <Button
          variant="primary-teal"
          isLoading={isLoading}
          isDisabled={!!Object.keys(errors).length}
          onClick={() => {
            mutate({
              companyId,
              params: { ...getValues(), definition },
            });
            onClose();
            setValue("name", "");
          }}
        >
          Save
        </Button>
      }
    />
  );
};

export { AudienceSegmentsModal };
