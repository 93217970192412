import { MostEngagedAccountsRO, WeeklySnapshotDTO } from "@intentsify/dto";
import { apiService, Endpoints } from "api";

export const getMostEngagedAccounts = async (
  id: number,
  filters: WeeklySnapshotDTO
): Promise<MostEngagedAccountsRO> => {
  const { data } = await apiService.get<MostEngagedAccountsRO>(
    Endpoints.Campaigns.Get.WeeklySnapshot.MostEngagedAccounts(id),
    {
      params: {
        yearTo: filters.yearTo,
        weekTo: filters.weekTo,
      },
    }
  );

  return data;
};
