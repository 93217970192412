import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Flex, HStack } from "@chakra-ui/react";
import { IconButton } from "components";

type TokenPaginationProps = {
  onPrevPage: () => void;
  isPrevDisabled: boolean;
  onNextPage: () => void;
  isNextDisabled: boolean;
};

const TokenPagination = ({
  isPrevDisabled,
  isNextDisabled,
  onPrevPage,
  onNextPage,
}: TokenPaginationProps) => {
  return (
    <Flex
      key="pagination"
      w="100%"
      bg="transparent"
      p={2}
      alignItems="center"
      justifyContent="flex-end"
      borderBottomRadius={4}
      overflow="hidden"
    >
      <HStack>
        <IconButton
          variant="pagination"
          tooltip="Go to the previous page"
          aria-label="Previous page"
          icon={<ChevronLeftIcon />}
          onClick={onPrevPage}
          isDisabled={isPrevDisabled}
          tooltipPlacement="bottom-end"
        />
        <IconButton
          variant="pagination"
          tooltip="Go to the next page"
          aria-label="Next page"
          icon={<ChevronRightIcon />}
          onClick={onNextPage}
          isDisabled={isNextDisabled}
          tooltipPlacement="bottom-end"
        />
      </HStack>
    </Flex>
  );
};

export { TokenPagination };
