import { UseQueryOptions, useQuery } from "@tanstack/react-query";
import { Duration } from "luxon";
import { DataPackage } from "@intentsify/types";
import { AsyncReturnType } from "type-fest";
import { Endpoints, apiService } from "api";

const queryKey = ["dataPackages"] as const;

const getDataPackages = async () => {
  const { data } = await apiService.get<DataPackage[]>(
    Endpoints.Permissions.Get.DataPackages
  );

  return data;
};

type UseUserAgencyCompanyQueryOptions = UseQueryOptions<
  AsyncReturnType<typeof getDataPackages>
>;

export const useDataPackages = (
  queryOptions?: UseUserAgencyCompanyQueryOptions
) => {
  return useQuery({
    queryKey,
    queryFn: getDataPackages,
    staleTime: Duration.fromObject({ minutes: 10 }).as("millisecond"),
    ...queryOptions,
  });
};
