import { Accordion, Box, HStack, SimpleGrid, Text } from "@chakra-ui/react";
import { useHasAccessTo } from "@intentsify/authorization/dist/react";
import { useQuery } from "@tanstack/react-query";
import { Loader } from "components";
import { MdCable } from "react-icons/md";
import { getCompanyById } from "screens/Admin/screens/Companies/screens/EditCompany/EditCompany.requests";
import { useUser } from "store/store.hooks";
import { listWorkatoConnections } from "./IntegrationSettings.requests";
import { IntegrationConnection } from "./components/IntegrationConnection";

const IntegrationSettings = () => {
  const user = useUser();

  const hasAccessToIntegrationSettings = useHasAccessTo(
    "company.establishCompanyIntegration"
  );

  const companyId = Number(user?.companyId);

  const {
    data: company,
    isLoading: isCompanyLoading,
    isFetched: isCompanyFetched,
  } = useQuery(
    ["userIntegrationsGetCompanyById", companyId],
    async () => getCompanyById(String(companyId)),
    {
      enabled: hasAccessToIntegrationSettings,
    }
  );

  const workatoCustomerId = company?.workatoCustomerId;

  const { data: workatoConnections } = useQuery(
    ["listWorkatoConnections", workatoCustomerId, companyId],
    async () =>
      listWorkatoConnections({
        companyId,
        workatoCustomerId: Number(company?.workatoCustomerId),
      }),
    { enabled: hasAccessToIntegrationSettings && Boolean(workatoCustomerId) }
  );

  if (!hasAccessToIntegrationSettings) {
    return <></>;
  }

  return (
    <Box>
      <Box>
        <HStack mt={4}>
          <MdCable />
          <Text mt={1} mb={1} fontWeight="bold" fontSize="2xl">
            Integrations
          </Text>
        </HStack>
        <Text color="gray.500" fontSize="sm" mb={3} ml={6}>
          Select the integration you want to set up and follow the instructions.
        </Text>
      </Box>
      {isCompanyLoading && <Loader />}
      {isCompanyFetched && Boolean(workatoCustomerId) && (
        <Box ml={4} mt={4} width="100%">
          <SimpleGrid columns={1} spacingY={5}>
            <Accordion id="integrationsList" allowToggle>
              {workatoConnections?.map((connection) => (
                <IntegrationConnection
                  key={`${connection.provider}_${connection.name}`}
                  connection={connection}
                  workatoCustomerId={Number(workatoCustomerId)}
                  companyId={companyId}
                />
              ))}
            </Accordion>
          </SimpleGrid>
        </Box>
      )}
      {isCompanyFetched &&
        (!workatoCustomerId ||
          (Boolean(workatoCustomerId) && workatoConnections?.length === 0)) && (
          <Box ml={7} mt={4}>
            <Text fontWeight="bold" fontSize="md">
              No integrations available for your company
            </Text>
          </Box>
        )}
    </Box>
  );
};

export { IntegrationSettings };
