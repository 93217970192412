import { Domain } from "@intentsify/types";
import { apiService, Endpoints } from "api";

export const verifyDomains = async ({
  domains,
  fileName,
}: {
  domains: string[];
  fileName: string;
  toMergeWith?: Domain[];
}) => {
  const result = await apiService.post<Domain[]>(
    Endpoints.Domains.Post.VerifyDomains,
    {
      domains,
    }
  );

  return {
    fileName,
    domains: result.data,
  };
};
