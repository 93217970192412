import { UsersScreen } from "types";
import { createScreen } from "utils/createScreen";

const UsersScreenDefinition = createScreen({
  name: UsersScreen,
  path: "/admin/users",
  routePath: ["/"],
  accessPath: "user",
});

export { UsersScreenDefinition };
