import {
  Theme as ChakraTheme,
  ColorProps,
  getToken,
  useColorMode,
  useTheme,
} from "@chakra-ui/react";
import { useMemo } from "react";

export type CardColorScheme =
  | "yellow"
  | "white"
  | "titleBrand"
  | "titleBlue"
  | "titleGreen"
  | "titleYellow"
  | "titleTeal";
type NoDataColorScheme = "grey";

type CardPallete = Record<string, ColorProps["color"]>;
type ThemeDefintion = { light: CardPallete; dark: CardPallete };
type Theme = Record<CardColorScheme | NoDataColorScheme, ThemeDefintion>;

const theme = {
  titleBrand: {
    light: {
      titleBg: "brand.500",
      bg: "white",
      border: "gray.100",
      title: "white",
      divider: "transparent",
      helpIcon: "white",
    },
    dark: {
      titleBg: "brand.500",
      bg: "brand.300",
      border: "brand.400",
      title: "gray.100",
      divider: "transparent",
    },
  },
  titleBlue: {
    light: {
      titleBg: "blue.500",
      bg: "white",
      border: "gray.100",
      title: "white",
      divider: "transparent",
      helpIcon: "white",
    },
    dark: {
      titleBg: "blue.700",
      bg: "brand.300",
      border: "brand.400",
      title: "gray.100",
      divider: "transparent",
    },
  },
  titleGreen: {
    light: {
      titleBg: "green.500",
      bg: "white",
      border: "gray.100",
      title: "white",
      divider: "transparent",
      helpIcon: "white",
    },
    dark: {
      titleBg: "green.700",
      bg: "brand.300",
      border: "brand.400",
      title: "gray.100",
      divider: "transparent",
    },
  },
  titleYellow: {
    light: {
      titleBg: "yellow.500",
      bg: "white",
      border: "gray.100",
      title: "white",
      divider: "transparent",
      helpIcon: "white",
    },
    dark: {
      titleBg: "yellow.700",
      bg: "brand.300",
      border: "brand.400",
      title: "gray.100",
      divider: "transparent",
    },
  },
  titleTeal: {
    light: {
      titleBg: "teal.500",
      bg: "white",
      border: "gray.100",
      title: "white",
      divider: "transparent",
      helpIcon: "white",
    },
    dark: {
      titleBg: "teal.700",
      bg: "brand.300",
      border: "brand.400",
      title: "gray.100",
      divider: "transparent",
    },
  },
  yellow: {
    light: {
      bg: "yellow.500",
      border: "gray.100",
      title: "white",
      divider: "white",
      helpIcon: "white",
    },
    dark: {
      bg: "yellow.700",
      border: "yellow.900",
      title: "white",
      divider: "white",
      helpIcon: "white",
    },
  },
  white: {
    light: {
      bg: "white",
      border: "gray.100",
      title: "gray.700",
      divider: "gray.200",
      helpIcon: "#6C7B93",
    },
    dark: {
      bg: "brand.300",
      border: "brand.400",
      title: "white",
      divider: "brand.400",
      helpIcon: "brand.400",
    },
  },
  grey: {
    light: {
      bg: "gray.25",
      border: "gray.200",
      title: "gray.700",
      divider: "gray.200",
    },
    dark: {
      bg: "brand.300",
      border: "brand.400",
      title: "white",
      divider: "brand.400",
    },
  },
} satisfies Theme;

export const usePalette = (
  isEnabled: boolean,
  cardColorScheme: CardColorScheme | NoDataColorScheme
) => {
  const { colorMode } = useColorMode();
  const chakraTheme = useTheme<ChakraTheme>();

  return useMemo(() => {
    const cardPallete = isEnabled
      ? theme[cardColorScheme][colorMode]
      : theme.grey[colorMode];

    return getResolvedPallete(cardPallete, chakraTheme);
  }, [isEnabled, cardColorScheme, colorMode, chakraTheme]);
};

const getResolvedPallete = (
  cardPallete: CardPallete,
  chakraTheme: ChakraTheme
) => {
  const resolvedPalleteEntries = Object.entries(cardPallete).map(
    ([key, value]) => [key, getToken("colors", value as string)(chakraTheme)]
  );

  return Object.fromEntries(resolvedPalleteEntries) as Record<string, string>;
};
