import { Box } from "@chakra-ui/react";
import { defaultStyles } from "@visx/tooltip";
import { TooltipInPortalProps } from "@visx/tooltip/lib/hooks/useTooltipInPortal";
import { FC, ReactNode } from "react";
import { TimeseriesItem, TimeseriesItemDataRecord } from "types";
import { useChartColors } from "utils";
import { TimeseriesProps } from "../Timeseries";
import { TimeseriesTooltipProps } from "./TimeseriesTooltip/TimeseriesTooltip.types";

type TooltipProps<K extends string, DK extends string> = {
  TooltipInPortal: FC<TooltipInPortalProps>;
  tooltipRenderer: (props: TimeseriesTooltipProps<K, DK>) => ReactNode;
  tooltipTop: number;
  tooltipLeft: number;
  uniqueKey: string;
  tooltipData: TimeseriesItem<K, TimeseriesItemDataRecord<DK>>;
  dataMainKey: K;
  data: TimeseriesItem<K, TimeseriesItemDataRecord<DK>>[];
  dataKeyLabels?: Record<DK, string> | undefined;
  timeseries: TimeseriesProps<K, DK>["timeseries"];
};

const Tooltip = <K extends string, DK extends string>({
  tooltipRenderer,
  TooltipInPortal,
  tooltipLeft,
  tooltipTop,
  uniqueKey,
  tooltipData,
  dataMainKey,
  data,
  dataKeyLabels,
  timeseries,
}: TooltipProps<K, DK>) => {
  const { labelColor, tooltipBackground, tooltipBorderColor } =
    useChartColors();

  return (
    <Box p={1}>
      <TooltipInPortal
        key={Math.random()}
        top={tooltipTop}
        left={tooltipLeft}
        style={{
          ...defaultStyles,
          background: tooltipBackground,
          borderRadius: "var(--chakra-radii-md)",
          border: `1px solid ${String(tooltipBorderColor)}`,
          boxShadow: "var(--chakra-shadows-lg)",
          zIndex: 10000,
        }}
      >
        <Box p={1}>
          {tooltipRenderer({
            tooltipData,
            dataMainKey,
            dataKeyLabels,
            timeseries,
            data,
            textColor: labelColor,
            uniqueKey,
          })}
        </Box>
      </TooltipInPortal>
    </Box>
  );
};

export { Tooltip };
