import { DateRange } from "@intentsify/types";
import { atom } from "recoil";

export const mostResearchedTopicsDateRangeAtom = atom<DateRange>({
  key: `campaignsMostResearchedTopicsDateRange`,
  default: DateRange.LastFourWeeks,
});

export const mostResearchedKeywordsDateRangeAtom = atom<DateRange>({
  key: `campaignsMostResearchedKeywordsDateRange`,
  default: DateRange.LastFourWeeks,
});
