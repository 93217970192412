import { SetPasswordScreen } from "types";
import { createScreen } from "utils/createScreen";

const SetPasswordScreenDefinition = createScreen({
  name: SetPasswordScreen,
  path: "/auth/set-password",
  routePath: ["/set-password"],
});

export { SetPasswordScreenDefinition };
