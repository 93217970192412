import {
  Component,
  PropsWithChildren,
  cloneElement,
  isValidElement,
} from "react";
import { isCustomApiError } from "types";

export class BaseErrorBoundary extends Component<
  PropsWithChildren<{
    fallback: React.ReactNode;
    customApiErrorFallback?: React.ReactNode;
  }>,
  { hasError: boolean; error: unknown }
> {
  state = { hasError: false, error: undefined as unknown };
  static getDerivedStateFromError(error: unknown) {
    return { error, hasError: true };
  }
  render() {
    if (
      this.props.customApiErrorFallback &&
      isValidElement(this.props.customApiErrorFallback) &&
      this.state.hasError &&
      isCustomApiError(this.state.error)
    ) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      return cloneElement(this.props.customApiErrorFallback, {
        error: this.state.error,
      } as any);
    }

    if (this.state.hasError) {
      return this.props.fallback;
    }

    return this.props.children;
  }
}
