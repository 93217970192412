import { FirmoType } from "@intentsify/types";

export type FirmoTechnoOption = {
  label: string;
  value: string;
};

export const firmoOptions = [
  {
    label: "Country",
    value: FirmoType.COUNTRY,
  },
  {
    label: "Employee Range",
    value: FirmoType.EMPLOYEE_RANGE,
  },
  {
    label: "Revenue Range",
    value: FirmoType.REVENUE_RANGE,
  },
  {
    label: "US State",
    value: FirmoType.STATE,
  },
  {
    label: "Industry",
    value: FirmoType.INDUSTRY,
  },
  {
    label: "Company Name",
    value: FirmoType.COMPANY,
  },
  {
    label: "Zip Code",
    value: FirmoType.ZIP,
  },
] satisfies FirmoTechnoOption[];
