import { LoginScreen } from "types/Screen";
import { createScreen } from "utils/createScreen";

const LoginScreenDefinition = createScreen({
  name: LoginScreen,
  path: "/auth/login",
  routePath: ["/login"],
});

export { LoginScreenDefinition };
