import {
  BuyerResearchStageMovementRO,
  WeeklySnapshotDTO,
} from "@intentsify/dto";
import { apiService, Endpoints } from "api";

export const getBuyerResearchStageMovement = async (
  id: number,
  filters: WeeklySnapshotDTO
): Promise<BuyerResearchStageMovementRO[]> => {
  const { data } = await apiService.get<BuyerResearchStageMovementRO[]>(
    Endpoints.Campaigns.Get.WeeklySnapshot.BuyerResearchStageMovement(id),
    {
      params: {
        yearTo: filters.yearTo,
        weekTo: filters.weekTo,
      },
    }
  );

  return data;
};
