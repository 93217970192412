import { GetCompanyRO } from "@intentsify/dto";
import { CompanyForGet } from "@intentsify/types";
import { apiService, Endpoints } from "api";
import { EditCompanyFormSchema } from "./EditCompanyFormSchema";

export const editCompany = async ({ id, ...data }: EditCompanyFormSchema) =>
  await apiService.put(Endpoints.Companies.Put.EditCompany(id), data);

export const getCompanyById = async (
  companyId: string
): Promise<CompanyForGet> => {
  const { data } = await apiService.get<GetCompanyRO>(
    Endpoints.Companies.Get.ById(companyId)
  );

  return data.company;
};
