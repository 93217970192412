import {
  CloseButton,
  Input,
  InputGroup,
  InputRightElement,
  SpaceProps,
  Stack,
  Text,
} from "@chakra-ui/react";
import { isDefined } from "@intentsify/utils";
import { useRef, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { useDebounce, useMount, usePrevious } from "react-use";
import { IconButton, IconButtonProps } from "../IconButton";

type SearchProps = {
  currentValue?: string;
  onSearch?: (v: string) => void;
  onChange?: (v: string) => void;
  placeholder?: string;
  autoFocus?: boolean;
  mr?: SpaceProps["mr"];
  mb?: SpaceProps["mb"];
  w?: string;
  size?: "sm" | "md";
  isDisabled?: boolean;
  maxLength?: number;
  focusOnRender?: boolean;
  iconButtonVariant?: IconButtonProps["variant"];
};

const getSizeProps = (size: SearchProps["size"]) => {
  switch (size) {
    case "sm":
      return {
        inputSize: "sm",
        iconSize: "xs",
      };
    case "md":
    default:
      return {
        inputSize: "md",
        iconSize: "sm",
      };
  }
};

const Search = ({
  currentValue,
  onSearch,
  onChange,
  placeholder = "Search",
  autoFocus = false,
  mb = 4,
  mr = 0,
  w = "400px",
  size = "md",
  isDisabled = false,
  maxLength = 64,
  focusOnRender = false,
  iconButtonVariant = "solid",
}: SearchProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState(currentValue ?? "");
  const isInvalid = value?.length === maxLength;
  const maxLengthReached = value?.length === maxLength;

  const prevValue = usePrevious(value);

  const onSubmit = () => {
    if (onSearch && !isInvalid) {
      onSearch(value.trim());
    }
  };

  useMount(() => {
    setTimeout(() => {
      if (focusOnRender && inputRef.current) {
        inputRef.current.focus();
      }
    }, 0);
  });

  useDebounce(
    () => {
      /**
       * useDebounce will execute on initial render, regardless of if anything changed.
       * This can lead to side effects when `onChange` is called without a reason. This is prevented
       * by checking if prev value exists.
       */
      if (onChange && isDefined(prevValue)) {
        onChange(value);
      }
    },
    200,
    [value]
  );

  const { inputSize, iconSize } = getSizeProps(size);

  return (
    <Stack w={w} mb={mb} mr={mr} direction="column">
      <InputGroup size="md" w={w}>
        <Input
          value={value}
          isInvalid={maxLengthReached}
          isDisabled={isDisabled}
          size={inputSize}
          autoFocus={autoFocus}
          ref={inputRef}
          w={w}
          maxLength={maxLength}
          type="text"
          placeholder={placeholder}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onSubmit();
            }
          }}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          rounded="md"
        />
        <InputRightElement
          justifyContent="flex-end"
          alignItems="center"
          pr={1}
          height="100%"
        >
          {value.length > 0 && (
            <CloseButton
              onClick={() => {
                setValue("");

                if (onSearch) {
                  onSearch("");
                }

                if (onChange) {
                  onChange("");
                }
              }}
              size="sm"
              mx={2}
            />
          )}

          <IconButton
            variant={iconButtonVariant}
            isDisabled={isDisabled || isInvalid}
            tooltipPlacement="right"
            size={iconSize}
            tooltip={placeholder}
            icon={<FiSearch />}
            onClick={() => {
              if (onSearch) {
                onSearch(value);
              }
            }}
          />
        </InputRightElement>
      </InputGroup>

      {maxLengthReached && (
        <Text as={"span"} fontSize="xs" textAlign="left" color={"red.300"}>
          The maximum number of characters for the search is {maxLength}
        </Text>
      )}
    </Stack>
  );
};

export { Search };
