import { chakra, Flex, Icon, Text, TooltipProps } from "@chakra-ui/react";
import {
  decimalToPercentage,
  isDefined,
  toNumberDisplayValue,
} from "@intentsify/utils";
import { Badge, Card, CardProps } from "components";
import { IconType } from "react-icons";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { getArrowType, getColorScheme } from "./utils";

export type StatCardProps<
  T extends number | undefined | null = number | undefined | null
> = {
  title: string;
  description?: string;
  caption?: string;
  titleTooltip?: string;
  titleTooltipPlacement?: TooltipProps["placement"];
  value: T;
  valueFormatter?: (value: number) => string;
  previousPeriodChange?: T;
  previousPeriodLabel?: string;
  colorScheme?: CardProps["colorScheme"];
  isLoading?: boolean;
  hasData?: boolean;
  noDataMessage?: string;
  noDataIcon?: IconType;
  noValueMessage?: string;
};

const StatCard = <
  T extends number | undefined | null = number | undefined | null
>({
  value,
  title,
  description,
  caption,
  valueFormatter = toNumberDisplayValue,
  previousPeriodChange,
  previousPeriodLabel = "vs Previous period:",
  titleTooltip,
  titleTooltipPlacement,
  colorScheme = "white",
  isLoading = false,
  hasData = true,
  noDataMessage,
  noDataIcon,
  noValueMessage = "n/a",
}: StatCardProps<T>) => {
  const arrowType = getArrowType(previousPeriodChange);

  return (
    <Card
      title={title}
      titleTooltip={titleTooltip}
      titleTooltipPlacement={titleTooltipPlacement}
      isLoading={isLoading}
      colorScheme={colorScheme}
      hasData={hasData}
      noDataMessage={noDataMessage}
      noDataIcon={noDataIcon}
    >
      <Flex
        flexGrow={1}
        alignItems="center"
        justifyContent="center"
        gap="0.1rem"
        flexDir="column"
      >
        <Flex flexGrow={1} alignItems="center" justifyContent="center">
          <Text
            textAlign="center"
            fontSize="3xl"
            fontWeight="semibold"
            lineHeight="1.25"
          >
            {value || value === 0 ? valueFormatter(value) : noValueMessage}
          </Text>
        </Flex>

        <Flex flexDir="column" alignItems="center" justifyContent="center">
          {description && (
            <Text textAlign="center" fontSize="sm" fontWeight="semibold">
              {description}
            </Text>
          )}

          {caption && (
            <Text
              textAlign="center"
              fontSize="sm"
              fontWeight="semibold"
              color="blackAlpha.500"
              _dark={{
                color: "whiteAlpha.600",
              }}
            >
              {caption}
            </Text>
          )}
        </Flex>
      </Flex>

      <Flex height={5} justifyContent="center">
        <Text as="div" fontSize="11px" fontWeight="normal">
          {isDefined(previousPeriodChange) && (
            <Flex alignItems={"center"}>
              {previousPeriodLabel}
              <Badge ml={1} colorScheme={getColorScheme(arrowType)}>
                <Flex alignItems="center">
                  {!arrowType && (
                    <>
                      {" "}
                      0%
                      <chakra.span opacity={0.5}>-</chakra.span>
                    </>
                  )}
                  {arrowType && (
                    <>
                      {" "}
                      {toNumberDisplayValue(
                        decimalToPercentage(previousPeriodChange ?? 0)
                      )}
                      %
                      {arrowType === "increase" ? (
                        <Icon as={FiChevronUp} w={4} h={4} />
                      ) : (
                        <Icon as={FiChevronDown} w={4} h={4} />
                      )}
                    </>
                  )}
                </Flex>
              </Badge>
            </Flex>
          )}
        </Text>
      </Flex>
    </Card>
  );
};

export { StatCard };
