import { apiService, Endpoints } from "api";
import { UserFormState } from "../../components";

export const editUser = (userId: number) => async (values: UserFormState) => {
  const { data: user } = await apiService.put(
    Endpoints.Users.Put.Edit(userId),
    values
  );

  return user;
};
