import { Flex } from "@chakra-ui/react";
import { isDefined } from "@intentsify/utils";
import { useMemo } from "react";
import { AgencyFormData } from "./BusinessEntityForm";
import { Select, useCompanies } from "components";

type CompaniesSelectorProps = {
  selected: AgencyFormData["companyIds"];
  onChange: (data: AgencyFormData["companyIds"]) => void;
};

const CompaniesSelector = ({ selected, onChange }: CompaniesSelectorProps) => {
  const { companies, isLoading } = useCompanies();
  const { companiesList, companiesMap } = useMemo(() => {
    const companiesList =
      companies?.map((company) => ({
        value: company.companyId,
        label: company.displayName,
      })) ?? [];

    const companiesMap = new Map<
      number,
      {
        value: number;
        label: string;
      }
    >();

    companiesList.forEach((i) => companiesMap.set(i.value, i));

    return {
      companiesList,
      companiesMap,
    };
  }, [companies]);

  const value = useMemo(() => {
    return selected.map((i) => companiesMap.get(i)).filter(isDefined);
  }, [selected, companiesMap]);

  return (
    <Flex
      data-testId="companies-selector"
      w={"400px"}
      flexDir={"column"}
      justify="space-between"
    >
      <Select
        isMulti={true}
        isDisabled={isLoading}
        isLoading={isLoading}
        isClearable={true}
        placeholder="Select companies"
        options={companiesList}
        value={value}
        onChange={(data) => onChange(data.map((i) => i.value))}
      />
    </Flex>
  );
};

export { CompaniesSelector };
