import { Flex } from "@chakra-ui/react";
import { ErrorBoundary } from "components";
import { DisplayPerformanceFilters } from "../../DisplayPerformance";
import {
  BuyerResearchStageEngagement,
  CreativeFatigueMap,
  CreativeWithHighestEngagement,
  EngagementTestingResults,
  OverallVideoViews,
  VideoCompletionRate,
} from "./components";

type CreativeEngagementProps = {
  id: string;
  filters: DisplayPerformanceFilters;
};

const CreativeEngagement = ({ id, filters }: CreativeEngagementProps) => {
  return (
    <>
      <Flex w="100%" gap={4} mb={4} minH="350px">
        <ErrorBoundary>
          <CreativeWithHighestEngagement campaignId={id} filters={filters} />
        </ErrorBoundary>
        <ErrorBoundary>
          <BuyerResearchStageEngagement campaignId={id} filters={filters} />
        </ErrorBoundary>
      </Flex>

      <Flex w="100%" gap={4} mb={4} minH="350px">
        <ErrorBoundary>
          <CreativeFatigueMap campaignId={id} filters={filters} />
        </ErrorBoundary>
        <ErrorBoundary>
          <OverallVideoViews campaignId={id} filters={filters} />
        </ErrorBoundary>
      </Flex>

      <Flex w="100%" gap={4} mb={4} minH="350px">
        <ErrorBoundary>
          <EngagementTestingResults campaignId={id} filters={filters} />
        </ErrorBoundary>
        <ErrorBoundary>
          <VideoCompletionRate campaignId={id} filters={filters} />
        </ErrorBoundary>
      </Flex>
    </>
  );
};

export { CreativeEngagement };
