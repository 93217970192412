import { UseQueryOptions } from "@tanstack/react-query";
import { listCompanies } from "api";
import { Duration } from "luxon";
import { AsyncReturnType } from "type-fest";

const queryKey = ["companiesList"] as const;

type UseCompanyQueryOptions = UseQueryOptions<
  AsyncReturnType<typeof listCompanies>
>;

export const useCompaniesConfig = (queryOptions?: UseCompanyQueryOptions) => {
  return {
    queryKey,
    queryFn: () => listCompanies(),
    staleTime: Duration.fromObject({ minutes: 5 }).as("millisecond"),
    ...queryOptions,
  } satisfies UseCompanyQueryOptions;
};
