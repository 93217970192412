import { Version } from "types";
import { StateCreator } from "zustand";

type MiscStoreState = {
  version: Version | undefined;
  sideBarCollapsed: boolean;
  signalTrendStatusMessageDisabled: boolean;
  uploadContentTipCount: number;
  showUploadContentTip: boolean;
};

type MiscStoreActions = {
  setVersion: (version: Version | undefined) => void;
  setSideBarCollapsed: (value: boolean) => void;
  setSignalTrendStatusMessageDisabled: (value: boolean) => void;
  incrementUploadContentTipCount: (value?: number) => void;
  setShowUploadContentTip: (value: boolean) => void;
};

export type MiscStore = MiscStoreState & MiscStoreActions;

export const defaultMiscState: MiscStoreState = {
  sideBarCollapsed: false,
  signalTrendStatusMessageDisabled: false,
  version: undefined,
  uploadContentTipCount: 0,
  showUploadContentTip: true,
};

export const createMiscSlice: StateCreator<
  MiscStore,
  [["zustand/immer", never]]
> = (set, get) => ({
  ...defaultMiscState,
  setVersion: (version: Version | undefined) =>
    set((state: MiscStore) => {
      state.version = version;
    }),
  setSideBarCollapsed: (value: boolean) =>
    set((state: MiscStore) => {
      state.sideBarCollapsed = value;
    }),
  setSignalTrendStatusMessageDisabled: (value: boolean) =>
    set((state: MiscStore) => {
      state.signalTrendStatusMessageDisabled = value;
    }),
  incrementUploadContentTipCount: (by = 1) =>
    set((state: MiscStore) => {
      state.uploadContentTipCount = get().uploadContentTipCount + by;
    }),
  setShowUploadContentTip: (value: boolean) =>
    set((state: MiscStore) => {
      state.showUploadContentTip = value;
    }),
});
