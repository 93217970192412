import axios from "axios";
import {
  makeApiAxiosServiceProxy,
  makeApiFetchServiceProxy,
} from "./makeApiServiceProxy";

export const apiService = makeApiAxiosServiceProxy(
  axios.create({
    baseURL: process.env.VITE_REACT_APP_API_URL,
    withCredentials: true,
  })
);

export const apiFetchService = makeApiFetchServiceProxy(fetch);
