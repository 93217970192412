import { Nullable, WorkatoConnectionProvider } from "@intentsify/types";

export type WorkatoConnection = {
  provider: WorkatoConnectionProvider;
  id: number;
  name: string;
  description: Nullable<string>;
  authorizedAt: Nullable<string>;
  authorizationStatus: Nullable<string>;
  authorizationError: Nullable<string>;
  createdAt: string;
  updatedAt: string;
  externalId: Nullable<string>;
  folderId: number;
  parentId: Nullable<number>;
};

export enum WorkatoEventType {
  CONNECTION_STATUS_CHANGE = "connectionStatusChange",
  ERROR = "error",
  HEIGHT_CHANGE = "heightChange",
}

export type WorkatoEvent = {
  type: WorkatoEventType;
  payload: {
    connected?: boolean;
    message?: string;
    height?: number;
    provider?: WorkatoConnectionProvider;
  };
};
