/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Heading,
  Link,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { SerializableCsvValidationResults } from "@intentsify/utils";
import { Button, Modal, TableVirtualised } from "components";
import { useMemo } from "react";
import { useDownloadCsvLink } from "shared/hooks/useDownloadCsvLink";
import {
  duplicatedColumns,
  fixedColumns,
  invalidRowsColumns,
} from "./resultsModalColumns";

const Subtitle = ({
  validationResults,
  columnsTemplate,
}: {
  validationResults: SerializableCsvValidationResults;
  columnsTemplate: Record<string, string>;
}) => {
  const template = useMemo(() => [columnsTemplate], [columnsTemplate]);
  const [downloadCsvLink] = useDownloadCsvLink(template);

  const {
    rowCount: totalRowCount,
    validRowCount,
    fixedCount,
    invalidRowCount,
    duplicatedCount,
    invalidColumns,
  } = validationResults;

  const hasErrors =
    totalRowCount > validRowCount ||
    invalidRowCount > 0 ||
    duplicatedCount > 0 ||
    fixedCount > 0;

  if (invalidColumns) {
    return (
      <>
        <Text mb={4}>
          The uploaded file contains invalid columns and cannot be processed.
        </Text>

        <Text>Please note:</Text>

        <UnorderedList>
          <ListItem>
            <Text>
              you must provide the required columns. Other columns are optional,
            </Text>
          </ListItem>
          <ListItem>
            <Text>
              you can{" "}
              <Link
                download="template.csv"
                href={downloadCsvLink}
                color="teal"
                fontWeight="semibold"
              >
                download the template from here{" "}
              </Link>
              for additional reference on formatting.
            </Text>
          </ListItem>
        </UnorderedList>
      </>
    );
  }

  return hasErrors ? (
    <>
      <Text mb={4}>
        You've uploaded <b>{totalRowCount}</b> rows, out of which{" "}
        <b>{validRowCount}</b> are valid. Please inspect validation results
        below. You can either cancel or proceed.
      </Text>

      <Text>Invalid rows will be disregarded.</Text>
      <Text>Rows that can be fixed, will be fixed automatically.</Text>
      <Text>Duplicate rows will be disregarded.</Text>
    </>
  ) : (
    <Text>
      You've uploaded <b>{totalRowCount}</b> rows, out of which{" "}
      <b>{validRowCount}</b> are valid.
    </Text>
  );
};

const Body = ({
  validationResults,
}: {
  validationResults: SerializableCsvValidationResults;
}) => {
  const {
    rowCount: totalRowCount,
    validRowCount,
    fixedCount,
    invalidRowCount,
    duplicatedCount,
    invalidColumns,
    fixed,
    invalidRows,
    duplicated,
  } = validationResults;

  const hasErrors =
    totalRowCount > validRowCount ||
    invalidRowCount > 0 ||
    duplicatedCount > 0 ||
    fixedCount > 0;

  if (invalidColumns) {
    return (
      <Box>
        <Heading as="h2" size="sm">
          Acceptable columns:
        </Heading>

        {invalidColumns.acceptable.map((i, index) => {
          const isRequired = i[1].required;

          return (
            <Box key={index}>
              {i[0]} {isRequired && <>(required)</>}
            </Box>
          );
        })}

        <Heading mt={4} as="h2" size="sm">
          Received columns:
        </Heading>
        {invalidColumns.received.map((i, index) => {
          return <Box key={index}>{i}</Box>;
        })}
      </Box>
    );
  }

  return (
    <Box>
      {hasErrors && (
        <Accordion allowToggle>
          <AccordionItem isDisabled={fixed.length === 0}>
            <AccordionButton>
              <Flex alignItems="center" gap={2}>
                {fixedCount > fixed.length
                  ? `Automatically fixed (showing ${fixed.length} out of total ${fixedCount})`
                  : `Automatically fixed (${fixed.length})`}
                <AccordionIcon />
              </Flex>
            </AccordionButton>
            <AccordionPanel px={0}>
              <TableVirtualised
                isSortDisabled
                data={fixed}
                columns={fixedColumns}
                containerProps={{ width: "100%", height: "250px" }}
              />
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem isDisabled={duplicated.length === 0}>
            <AccordionButton>
              <Flex alignItems="center" gap={2}>
                {duplicatedCount > duplicated.length
                  ? `Duplicates removed (showing ${duplicated.length} out of total ${duplicatedCount})`
                  : `Duplicates removed (${duplicated.length})`}
                <AccordionIcon />
              </Flex>
            </AccordionButton>
            <AccordionPanel px={0}>
              <TableVirtualised
                isSortDisabled
                data={duplicated}
                columns={duplicatedColumns}
                containerProps={{ width: "100%", height: "250px" }}
              />
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem isDisabled={invalidRows.length === 0}>
            <AccordionButton>
              <Flex alignItems="center" gap={2}>
                {invalidRowCount > invalidRows.length
                  ? `Invalid rows (showing ${invalidRows.length} out of total ${invalidRowCount})`
                  : `Invalid rows (${invalidRows.length})`}
                <AccordionIcon />
              </Flex>
            </AccordionButton>
            <AccordionPanel px={0}>
              <TableVirtualised
                isSortDisabled
                data={invalidRows}
                columns={invalidRowsColumns}
                containerProps={{ width: "100%", height: "250px" }}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      )}
    </Box>
  );
};

export const ValidationResultsModal = (props: {
  isOpen: boolean;
  onCancel: () => void;
  onProceed: () => void;
  validationResults: {
    data: SerializableCsvValidationResults;
    done: boolean;
  };
  columnsTemplate: Record<string, string>;
}) => {
  const { validRowCount, invalidColumns } = props.validationResults.data;

  return (
    <Modal
      size="2xl"
      title="Validation Results"
      isOpen={props.isOpen}
      onClose={props.onCancel}
      secondaryButton={
        !invalidColumns && (
          <Button
            aria-label="Cancel"
            variant="secondary"
            onClick={props.onCancel}
          >
            Cancel
          </Button>
        )
      }
      primaryButton={
        !invalidColumns ? (
          <Button
            isDisabled={validRowCount < 1}
            aria-label="Proceed"
            variant="primary"
            isLoading={!props.validationResults.done}
            onClick={props.onProceed}
          >
            Proceed
          </Button>
        ) : (
          <Button aria-label="OK" variant="primary" onClick={props.onCancel}>
            Exit
          </Button>
        )
      }
      subtitle={
        <Subtitle
          columnsTemplate={props.columnsTemplate}
          validationResults={props.validationResults.data}
        />
      }
      body={<Body validationResults={props.validationResults.data} />}
    />
  );
};
