import { apiService, Endpoints } from "api";

export const setPasswordRequest = async ({
  userId,
  password,
  token,
}: {
  userId: string;
  password: string;
  token: string;
}) => {
  const { data } = await apiService.put(
    Endpoints.Users.Put.SetPassword(userId),
    {
      token,
      password,
    }
  );

  return data;
};
