import { BusinessEventOption, Node } from "@intentsify/types";
import { useMemo } from "react";

export type BusinessEventNode = Node<undefined, string>;

export const useBusinessEventsTree = (
  businessEvents: BusinessEventOption[] | undefined
) => {
  const businessEventsTree: BusinessEventNode[] = useMemo(() => {
    if (!businessEvents) {
      return [];
    }

    const nodes = businessEvents.reduce(
      (
        acc: { [key: string]: BusinessEventNode[] },
        event: BusinessEventOption
      ) => {
        acc["Category"].push(event);
        return acc;
      },
      { Category: [] }
    );

    const tree: BusinessEventNode[] = Object.keys(nodes).map((category) => ({
      label: category,
      value: category,
      children: nodes[category],
      meta: { category: "", summary: "" },
    }));

    return tree;
  }, [businessEvents]);

  return businessEventsTree;
};
