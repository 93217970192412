import { CompanyLeadPacingScreen } from "../../../../types";
import { createScreen } from "utils/createScreen";

const CompanyLeadPacingScreenDefinition = createScreen({
  name: CompanyLeadPacingScreen,
  path: "/dashboard/campaigns-overview/:companyId",
  routePath: ["/campaigns-overview", "/campaigns-overview/:companyId"],
  accessPath: "campaign.intentAnalysisWithSpotlight",
});

export { CompanyLeadPacingScreenDefinition };
