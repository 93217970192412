import { Loader, MenuWithContent } from "components";
import { MenuWithContentItem } from "types";

type StepsProps = {
  currentStep: number;
  items: Omit<MenuWithContentItem, "onClick">[];
  isLoading: boolean;
};

const Steps = ({ items, currentStep, isLoading }: StepsProps) => {
  return (
    <MenuWithContent
      stretch
      currentItemIndex={currentStep - 1}
      loaderComponent={<Loader />}
      isLoading={isLoading}
      items={items.map((item, index) => ({
        ...item,
        nextItemLabel: items[index + 1]?.label,
        label: `${index + 1}. ${item.label}`,
      }))}
    />
  );
};

export { Steps };
