import { AccessPath } from "@intentsify/authorization";
import { ParamParseKey } from "react-router";
import { IsEmptyObject } from "type-fest";

type UnsafeAppScreen = {
  name: string;
  info?: string;
  path: string;
  routePath: string[];
};

type AppScreen = UnsafeAppScreen & {
  accessPath: AccessPath;
};

// TODO: remove concept of screens
export const createScreen = <Name extends string, Path extends string>({
  name,
  path,
  info,
  routePath,
}: (UnsafeAppScreen | AppScreen) & { name: Name; path: Path }) => {
  type Params = Record<ParamParseKey<Path>, string | number | undefined>;
  type Navigate = IsEmptyObject<Params> extends true
    ? () => string
    : (params: Params) => string;

  const navigate = (params: Params | undefined) => {
    if (!params) {
      return path;
    }
    const entries = Object.entries(params);

    return entries
      .reduce((prev, curr) => {
        return prev.replace(`:${curr[0]}`, String(curr[1] ?? "")) as Path;
      }, path)
      .replace(/\/+$/, "");
  };

  return {
    name,
    routePath,
    path,
    info,
    navigate: navigate as Navigate,
  };
};
