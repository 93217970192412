import { createScreen } from "utils/createScreen";
import { TrendingAccountsScreen } from "../../../../types";

const TrendingAccountsScreenDefinition = createScreen({
  name: TrendingAccountsScreen,
  path: "/dashboard/intent-models-trending-accounts/:campaignId",
  routePath: [
    "/intent-models-trending-accounts/",
    "/intent-models-trending-accounts/:campaignId",
  ],
  accessPath: "campaign.trendingAccounts",
});

export { TrendingAccountsScreenDefinition };
