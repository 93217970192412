import { SaasRoleName } from "@intentsify/types";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { useUser } from "store/store.hooks";

export const useInvalidatePortalQueries = () => {
  const queryClient = useQueryClient();
  const user = useUser();

  return useCallback(() => {
    queryClient.invalidateQueries({
      predicate: (query) => {
        if (
          ["getUserPermissionModules", "userAgencyCompany"].includes(
            query.queryKey[0] as string
          )
        ) {
          return false;
        }

        // we don't need to invalidate these for admins
        // because it doesn't change anything for them
        // meaning the data is the same
        // so there's no reason to refetch it
        if (
          user?.saasRole === SaasRoleName.Admin &&
          ["campaignsList", "userCampaignsSummary", "companiesList"].includes(
            query.queryKey[0] as string
          )
        ) {
          return false;
        }

        return true;
      },
    });
  }, [user?.saasRole, queryClient]);
};
