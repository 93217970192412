import { TrendingTopicsRO } from "@intentsify/dto";
import { Option } from "@intentsify/types";
import { isPopulatedArray } from "@intentsify/utils";
import first from "lodash/first";
import last from "lodash/last";
import orderBy from "lodash/orderBy";
import uniqBy from "lodash/uniqBy";
import { DateTime } from "luxon";
import { useMemo, useState } from "react";
import { TimeseriesItem, TimeseriesRange, TimeseriesResponse } from "types";
import { useDeepEffect } from "utils";

type TopicTimeseriesItem = TimeseriesItem<"label", { value: number }>;
type TopicsTimeseriesResponse = TimeseriesResponse<TopicTimeseriesItem>;

const createTimeseries = (
  earliestDate: string,
  latestDate: string,
  list: TimeseriesRange[]
): TimeseriesRange[] => {
  if (!isPopulatedArray(list)) {
    return createTimeseries(earliestDate, latestDate, [
      {
        start: earliestDate,
        end: DateTime.fromISO(earliestDate, { zone: "utc" })
          .endOf("week")
          .toISO(),
      },
    ]);
  }

  const latestItem = last(list) as TimeseriesRange;

  if (latestItem.start === latestDate) {
    return list;
  }

  return createTimeseries(earliestDate, latestDate, [
    ...list,
    {
      start: DateTime.fromISO(latestItem.start, { zone: "utc" })
        .plus({ week: 1 })
        .startOf("week")
        .toISO(),
      end: DateTime.fromISO(latestItem.end, { zone: "utc" })
        .plus({ week: 1 })
        .endOf("week")
        .toISO(),
    },
  ]);
};

export const useTrendingTopicsTimeseriesData = (
  data: TrendingTopicsRO[]
): {
  selectedTopics: string[];
  setSelectedTopics: React.Dispatch<React.SetStateAction<string[]>>;
  options: Option<string>[];
  chartData: TopicsTimeseriesResponse;
} => {
  const [selectedTopics, setSelectedTopics] = useState<string[]>([]);

  const highestRankedTopics = useMemo(() => {
    const unique = uniqBy(data, (i) => i.topic);
    const sorted = orderBy(unique, (i) => i.slopeRank);

    return sorted.slice(0, 5).map((i) => i.topic);
  }, [data]);

  useDeepEffect(() => {
    setSelectedTopics(highestRankedTopics);
  }, [highestRankedTopics]);

  const relevantRows = data.filter((i) => selectedTopics.includes(i.topic));

  const sortedDates = useMemo(() => {
    return relevantRows
      .map((i) => DateTime.fromISO(i.date, { zone: "utc" }))
      .sort()
      .map((i) => i.toISO());
  }, [relevantRows]);

  const earliestDate = first(sortedDates) as string;
  const latestDate = last(sortedDates) as string;

  const options = useMemo(() => {
    const unique = uniqBy(data, (i) => i.topic);
    const sorted = orderBy(unique, (i) => i.slopeRank);

    return sorted.map((i) => ({
      value: i.topic,
      label: i.topic,
    }));
  }, [data]);

  return {
    selectedTopics,
    setSelectedTopics,
    options,

    chartData: {
      data: selectedTopics.map((topic) => {
        const topicRows = relevantRows.filter((r) => r.topic === topic);

        return {
          label: topic,
          timeseries: topicRows.map((r) => ({
            isoDate: r.date,
            data: {
              value: r.signals,
            },
          })),
        };
      }),
      timeseries: createTimeseries(earliestDate, latestDate, []),
    },
  };
};
