import { atom, useRecoilValue, useSetRecoilState } from "recoil";
import type { Opaque } from "type-fest";
import { nanoid } from "utils/nanoid";

export type DialogId = Opaque<string, "DialogId">;

const confirmDialogIdsState = atom<Set<DialogId>>({
  default: new Set(),
  key: "confirmDialogIds",
});

export const makeDialogId = () => {
  return nanoid() as DialogId;
};

export const useConfirmDialogIdsStateValue = () => {
  return useRecoilValue(confirmDialogIdsState);
};

export const useSetConfirmDialogIdsState = () => {
  return useSetRecoilState(confirmDialogIdsState);
};
