import { Tag, TagCloseButton, TagLabel } from "@chakra-ui/react";

type DynamicInputItemProps<T extends string | number> = {
  item: T;
  removeItem: (item: T) => void;
};

const DynamicInputItem = <T extends string | number>({
  item,
  removeItem,
}: DynamicInputItemProps<T>) => {
  return (
    <Tag mr={1} mt={1}>
      <TagLabel>{item}</TagLabel>
      <TagCloseButton onClick={() => removeItem(item)} />
    </Tag>
  );
};

export { DynamicInputItem };
