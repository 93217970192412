import { Text } from "@chakra-ui/react";
import { Tooltip } from "components";
import React from "react";
import { muteColor } from "utils/colors";

type TextWithTooltipProps = {
  renderTooltip: () => React.ReactNode;
  children: React.ReactNode | string | null | boolean;
};

export const TextWithTooltip = ({
  renderTooltip,
  children,
}: TextWithTooltipProps) => {
  return (
    <Tooltip aria-label="tooltip" label={renderTooltip()}>
      <Text
        cursor="default"
        textDecorationStyle="dotted"
        textDecorationLine="underline"
        textDecorationColor={muteColor("currentColor", 0.7)}
        textUnderlineOffset={2}
        tabIndex={0}
        as="span"
      >
        {children}
      </Text>
    </Tooltip>
  );
};
