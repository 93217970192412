import { UserForList } from "@intentsify/types";
import { useQuery } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import { PaginationType } from "components/Table/Table/Table.types";
import { useCallback, useState } from "react";
import { userTableColumns } from "screens/Admin/screens/Users/screens/Users/Users.columns";
import {
  defaultListPaginatedUsersParams,
  listUsers,
} from "screens/Admin/screens/Users/screens/Users/Users.requests";
import { useAppStore } from "store/useAppStore";
import { useTrackSearched } from "tracking/useTrackSearched";
import { FetchDataParamsWithToken } from "types";
import { Button } from "../Button";
import { Search } from "../Search";
import { Table } from "../Table";

const getImpersonateColumn = (onClick: (userId: number) => void) => {
  const impersonateColumn: ColumnDef<UserForList>[] = [
    {
      header: "Actions",
      cell: ({ row }) => {
        return (
          <Button
            variant="primary"
            onClick={() =>
              row.original?.userId ? onClick(row.original.userId) : undefined
            }
          >
            Select
          </Button>
        );
      },
    },
  ];

  return impersonateColumn;
};

type ImpersonationTableProps = {
  onEnableImpersonation: (userId: number) => void;
};

const ImpersonationUsersTable = ({
  onEnableImpersonation,
}: ImpersonationTableProps) => {
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const trackSearched = useTrackSearched();

  const setIsImpersonationModalOpen =
    useAppStore.use.setIsImpersonationModalOpen();

  const [fetchDataParams, setFetchDataParams] = useState<
    FetchDataParamsWithToken<keyof UserForList>
  >(defaultListPaginatedUsersParams);

  const handleOnFetchDataChange = useCallback(
    (params: FetchDataParamsWithToken<keyof UserForList>) => {
      setFetchDataParams(params);
    },
    []
  );

  const { data, isFetching } = useQuery(["listUsers", fetchDataParams], () =>
    listUsers(fetchDataParams)
  );

  const userColumnsWithoutActions = [
    ...userTableColumns.filter(
      (c) => c.header?.toString().toLocaleLowerCase() !== "actions"
    ),
    ...getImpersonateColumn((userId) => {
      setIsImpersonationModalOpen(false);
      onEnableImpersonation(userId);
    }),
  ];

  return (
    <>
      <Search
        currentValue={searchValue}
        onSearch={(v) => {
          setSearchValue(v);
          trackSearched({ term: v, collocation: "Impersonation table" });
        }}
      />
      <Table
        variant="intentsifyNew"
        searchValue={searchValue}
        defaultSort={[
          {
            id: "userId",
            desc: true,
          },
        ]}
        columns={userColumnsWithoutActions}
        isFetching={isFetching}
        data={data?.users || []}
        onFetchDataChange={handleOnFetchDataChange}
        resetPaginationDependencies={[searchValue]}
        paginationType={PaginationType.TOKEN}
        nextPageToken={data?.nextPageToken}
      />
    </>
  );
};

export { ImpersonationUsersTable };
