import { useColorModeValue } from "@chakra-ui/color-mode";
import {
  Box,
  Center,
  CloseButton,
  Divider,
  Icon,
  Portal,
  Spinner,
  Tag,
  TagCloseButton,
  TagLabel,
  useColorMode,
  useStyles,
} from "@chakra-ui/react";
import { Option as IntentsifyOption } from "@intentsify/types";
import { FiChevronDown } from "react-icons/fi";
import { GroupBase, Props } from "react-select";
import { ListItem } from "./ListItem";
import { MenuList } from "./MenuList";
import { SimpleControl } from "./SimpleControl";

type UseSelectComponentsOptions = {
  menuListTestId?: string;
};

export const useSimpleSelectComponents = <
  Option extends IntentsifyOption,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
  CompiledProps extends Props<Option, IsMulti, Group> = Props<
    Option,
    IsMulti,
    Group
  >
>(
  options?: UseSelectComponentsOptions
) => {
  const { colorMode } = useColorMode();

  return {
    Control: SimpleControl,
    MenuList: (props) => (
      <MenuList<Option, IsMulti, Group>
        {...props}
        testId={options?.menuListTestId}
      />
    ),
    Option: (props) => <ListItem {...props} colorMode={colorMode} />,
    MultiValueContainer: ({ children }) => (
      <Tag height="22px" size="sm" m="2px">
        {children}
      </Tag>
    ),
    MultiValueLabel: ({ children }) => <TagLabel>{children}</TagLabel>,
    MultiValueRemove: ({ children, innerProps }) => {
      const { ref, className: _className, ...restInnerProps } = innerProps;

      return (
        <Box ref={ref} {...restInnerProps}>
          <TagCloseButton>{children}</TagCloseButton>
        </Box>
      );
    },
    IndicatorSeparator: (props) => {
      const styles = props.getStyles("indicatorSeparator", props);
      return (
        <Divider
          orientation="vertical"
          opacity={Number(styles.opacity) || 0.2}
        />
      );
    },
    ClearIndicator: ({ innerProps }) => {
      const { ref, ...restInnerProps } = innerProps;

      return (
        <Box ref={ref} {...restInnerProps}>
          <CloseButton size="sm" mx={2} />
        </Box>
      );
    },
    DropdownIndicator: ({ innerProps }) => {
      const { addon } = useStyles();

      return (
        <Center
          {...innerProps}
          sx={{
            ...addon,
            h: "100%",
            borderRadius: 0,
            borderWidth: 0,
            background: "none",
            cursor: "pointer",
          }}
        >
          <Icon
            color={useColorModeValue("gray.700", "gray.200")}
            as={FiChevronDown}
            h={5}
            w={5}
          />
        </Center>
      );
    },
    MenuPortal: ({ children }) => <Portal>{children}</Portal>,
    GroupHeading: ({ children }) => {
      const { groupTitle } = useStyles();

      return <Box sx={groupTitle}>{children}</Box>;
    },

    LoadingIndicator: () => {
      const color = useColorModeValue("gray.500", "gray.100");

      return <Spinner mr={2} color={color} size="sm" />;
    },
  } satisfies CompiledProps["components"];
};
