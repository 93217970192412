import { Link, Image, chakra, Flex, Box } from "@chakra-ui/react";
import { useState } from "react";
import { MdZoomIn } from "react-icons/md";
import { CreativePlaceholder } from "./CreativePlaceholder";
import { Modal, Video } from "components";

type CreativeProps = {
  creativeUrl: string;
  creativeClickUrl?: string;
  showIframe: boolean;
  containerProps?: {
    width: number;
    height: number;
  };
  isVideoCreative?: boolean;
};

const Creative = ({
  creativeUrl,
  creativeClickUrl,
  showIframe,
  containerProps,
  isVideoCreative = false,
}: CreativeProps) => {
  const [isIframePreviewOpen, setIsIframePreviewOpen] = useState(false);

  if (creativeUrl === "") {
    return <CreativePlaceholder />;
  }

  if (isVideoCreative) {
    return (
      <Video
        options={{
          autoplay: true,
          controls: true,
          muted: true,
          sources: [
            {
              src: "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
              type: "video/mp4",
            },
          ],
          width: 250,
        }}
        ima={{
          adTagUrl: creativeUrl,
        }}
      />
    );
  }

  if (showIframe) {
    return (
      <>
        <Box overflow="hidden" maxW={"100%"} maxH={"150px"} position="relative">
          <Flex
            onClick={() => setIsIframePreviewOpen(true)}
            p={1}
            mb={1}
            fontSize="xs"
            w="100%"
            alignItems="center"
            justifyContent="center"
            bg="rgba(0,0,0,0.1)"
            _hover={{
              background: "rgba(0,0,0,0.2)",
            }}
            cursor="pointer"
          >
            <MdZoomIn fontSize="1.5rem" />
            Click here to see full preview
          </Flex>
          <chakra.iframe
            src={creativeUrl}
            overflow="hidden"
            scrolling="no"
            sandbox="allow-popups allow-popups-to-escape-sandbox allow-pointer-lock allow-same-origin allow-scripts"
            {...containerProps}
          />
        </Box>

        <Modal
          size="viewContainer"
          isOpen={isIframePreviewOpen}
          onClose={() => setIsIframePreviewOpen(false)}
          body={
            <Flex p={4} alignItems="center" justifyContent="center">
              <chakra.iframe
                width="100%"
                height="80vh"
                src={creativeUrl}
                overflow="hidden"
                sandbox="allow-popups allow-popups-to-escape-sandbox allow-pointer-lock allow-same-origin allow-scripts"
              />
            </Flex>
          }
        />
      </>
    );
  }

  return (
    <>
      {creativeClickUrl ? (
        <Link href={creativeClickUrl} isExternal>
          <Image maxW={"100%"} maxH={"150px"} src={creativeUrl} />
        </Link>
      ) : (
        <Image maxW={"100%"} maxH={"150px"} src={creativeUrl} />
      )}
    </>
  );
};

export { Creative };
