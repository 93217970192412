const getFileSizeFromUrl = async (url: string): Promise<string | null> => {
  return new Promise((resolve) => {
    const xhr = new XMLHttpRequest();
    xhr.open("HEAD", url, true);

    xhr.onreadystatechange = function () {
      if (this.readyState === this.DONE) {
        resolve(xhr.getResponseHeader("Content-Length"));
      }
    };
    xhr.send();
  });
};

const getFileNameFromUrl = (url: string): string | undefined => {
  try {
    const urlWithoutQuery = url.split("?")[0];

    const fileName = urlWithoutQuery.split("/").pop();
    if (!fileName) {
      return undefined;
    }

    const decodedFileName = decodeURIComponent(fileName);

    return decodedFileName;
  } catch (error) {
    console.error("Error extracting filename from URL:", error);
    return undefined;
  }
};

export { getFileNameFromUrl, getFileSizeFromUrl };
