import { Skeleton, useColorModeValue } from "@chakra-ui/react";
import { SaasRoleName } from "@intentsify/types";
import { useUserAgencyCompany } from "queries/companies/useUserAgencyCompany";
import { useInvalidatePortalQueries } from "queries/useInvalidatePortalQueries";
import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import {
  DigitalForecastingScreenDefinition,
  LeadPacingScreenDefinition,
} from "screens";
import { ContentLibraryDefinition } from "screens/ContentLibrary/ContentLibrary.definition";
import { TargetPersonasDefinition } from "screens/TargetPersonas/TargetPersonas.definition";
import { useUser } from "store/store.hooks";
import { useAppStore } from "store/useAppStore";
import { ProgrammaticDomainsUploadScreen } from "types";
import { contentLibraryUpload } from "upload/contentLibraryUpload";
import { screenAtom } from "utils";
import { UserAgencyCompanySelect } from "./UserAgencyCompanySelect";

export const UserAgencyCompanySelector = () => {
  const startColor = useColorModeValue("blackAlpha.800", "blackAlpha.500");
  const endColor = useColorModeValue("blackAlpha.500", "blackAlpha.200");
  const isUploadingToContentLibrary = contentLibraryUpload.useIsUploading();

  const user = useUser();
  const impersonation = useAppStore.use.impersonation();

  const invalidatePortalQueries = useInvalidatePortalQueries();
  // when user is in impersonation we need to fetch
  // the company of the impersonated user
  useUserAgencyCompany({
    onSuccess: () => {
      if (impersonation) {
        invalidatePortalQueries();
      }
    },
  });

  const screen = useRecoilValue(screenAtom);

  // Show the company selector for admins on the lead pacing screen
  // because this selector is used to select the company for the lead pacing screen
  if (
    user?.saasRole === SaasRoleName.Admin &&
    screen.name !== LeadPacingScreenDefinition.name &&
    screen.name !== ContentLibraryDefinition.name &&
    screen.name !== TargetPersonasDefinition.name &&
    screen.name !== ProgrammaticDomainsUploadScreen &&
    screen.name !== DigitalForecastingScreenDefinition.name
  ) {
    return null;
  }

  if (user?.saasRole !== SaasRoleName.Admin && !user?.agencyId) {
    return null;
  }

  return (
    <Suspense
      fallback={
        <Skeleton
          startColor={startColor}
          endColor={endColor}
          h="32.39px"
          w="180px"
          borderRadius="md"
        />
      }
    >
      <UserAgencyCompanySelect isDisabled={isUploadingToContentLibrary} />
    </Suspense>
  );
};
