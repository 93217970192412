import { PaginatedResponse } from "@intentsify/types";
import {
  PageTracking,
  PageTrackingFetchDataParams,
} from "./PageTracking.types";
import { apiService, Endpoints } from "api";
import { getPaginatedQuery } from "utils";

export const listPaginatedPageTracking = async (
  params: PageTrackingFetchDataParams
): Promise<PaginatedResponse<PageTracking>> => {
  const query = getPaginatedQuery<PageTracking, Record<never, never>>(
    Endpoints.PageTracking.Get.All,
    params
  );
  const { data } = await apiService.get(query);
  return data;
};
