import {
  Box,
  Spinner,
  Text,
  useColorModeValue,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import { isPopulatedArray } from "@intentsify/utils";
import { GroupBase, MenuListProps } from "react-select";
import { Virtuoso } from "react-virtuoso";
import { ITEM_HEIGHT } from "./Select.const";

const MenuList = <
  Option = unknown,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: MenuListProps<Option, IsMulti, Group> & { testId?: string }
) => {
  const { children, maxHeight, testId } = props;

  const menuStyles = useMultiStyleConfig("Menu", {});

  const minHeight = Array.isArray(children) ? children.length * ITEM_HEIGHT : 0;
  const listHeight = minHeight > maxHeight ? maxHeight : minHeight;

  const { isLoading } = props.selectProps;

  const VirtuosoItem = ({ item }: { item: React.ReactNode }) => {
    if (!item) {
      return null;
    }
    return <div>{item}</div>;
  };

  const Footer = () => {
    const spinnerColor = useColorModeValue("gray.500", "gray.100");

    if (isLoading) {
      return (
        <Box
          style={{
            padding: "0.5rem",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Spinner color={spinnerColor} size="sm" />
        </Box>
      );
    }

    return null;
  };

  return (
    <Box
      data-testId={testId ? `${testId}-list-wrapper` : undefined}
      sx={{
        ...menuStyles.list,
        w: "100%",
      }}
    >
      {isPopulatedArray(children) ? (
        <Virtuoso
          totalCount={children.length}
          style={{ height: listHeight }}
          endReached={props.selectProps?.onFetchDataChange}
          overscan={200}
          itemContent={(index) => {
            return <VirtuosoItem item={children[index]} />;
          }}
          components={{
            Footer: Footer,
          }}
        />
      ) : (
        <Text fontSize="sm" color="gray.100" textAlign="center">
          No options
        </Text>
      )}
    </Box>
  );
};

export { MenuList };
