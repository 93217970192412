import { ChakraProvider } from "@chakra-ui/react";
import { SimpleErrorBoundary } from "components";
import { PropsWithChildren } from "react";
import { RecoilRoot } from "recoil";
import { AllRoutes } from "routes/AllRoutes";
import { intentsifyTheme } from "theme";
import { bulkEditUpload } from "upload/bulkEditUpload";
import { talUpload } from "upload/talUpload";
import { SocketContextProvider } from "webSocket/socketContext";
import { PortalQueryClientProvider } from "../queryClient/PortalQueryClientProvider";
import { blocklistUpload } from "../upload/blocklistUpload";
import { contentLibraryUpload } from "../upload/contentLibraryUpload";
import { talToolsUpload } from "../upload/talToolsUpload";
import { targetPersonaUpload } from "../upload/targetPersonaUpload";
import { AuthorizationProvider } from "./AuthorizationProvider";

export const Providers = (props: PropsWithChildren) => {
  return (
    <RecoilRoot>
      <PortalQueryClientProvider>
        <ChakraProvider theme={intentsifyTheme}>
          <SimpleErrorBoundary>
            <AuthorizationProvider>
              <contentLibraryUpload.Provider>
                <talToolsUpload.Provider>
                  <targetPersonaUpload.Provider>
                    <talUpload.Provider>
                      <blocklistUpload.Provider>
                        <bulkEditUpload.Provider>
                          <SocketContextProvider>
                            <AllRoutes />
                            {props.children}
                          </SocketContextProvider>
                        </bulkEditUpload.Provider>
                      </blocklistUpload.Provider>
                    </talUpload.Provider>
                  </targetPersonaUpload.Provider>
                </talToolsUpload.Provider>
              </contentLibraryUpload.Provider>
            </AuthorizationProvider>
          </SimpleErrorBoundary>
        </ChakraProvider>
      </PortalQueryClientProvider>
    </RecoilRoot>
  );
};
