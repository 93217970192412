import { Integration, WorkatoConnectionProvider } from "@intentsify/types";
import { isDefined } from "@intentsify/utils";
import { getWorkatoProviderLogoUrl } from "utils/getWorkatoProviderLogoUrl";

const allPossibleProviderOptions: Integration[] = Object.values(
  WorkatoConnectionProvider
)
  .map((provider) => ({ provider, id: 0, connectionId: 0, createdAt: "" }))
  .filter(
    ({ provider }) =>
      provider !== WorkatoConnectionProvider.PROVIDER_UNSPECIFIED
  );

export type IntegrationWithLogo = Integration & {
  logoUrl: string;
};

export const getIntegrationWithLogo = (
  currentCompanyIntegrations: Integration[]
): IntegrationWithLogo[] => {
  return allPossibleProviderOptions
    .map((possibleIntegration) => {
      const existingIntegration = currentCompanyIntegrations.find(
        ({ provider: existingProviderIntegration }) =>
          existingProviderIntegration === possibleIntegration.provider
      );

      if (existingIntegration) {
        return {
          ...existingIntegration,
          logoUrl: getWorkatoProviderLogoUrl(existingIntegration.provider),
        };
      }

      return {
        ...possibleIntegration,
        logoUrl: getWorkatoProviderLogoUrl(possibleIntegration.provider),
      };
    })
    .filter(isDefined);
};
