import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from "@chakra-ui/react";
import { AggregatedTopLocations, Option } from "@intentsify/types";
import { Loader, NoData, Select } from "components";
import { ReactElement, useCallback, useState } from "react";
import { useTopLocations } from "screens/Campaigns/screens/CampaignDetails/CampaignDetailsTable/CampaignDetailsTable.hooks";
import { TopLocationsSignalsTable } from "./TopLocationsSignalTable";

type TopLocationsProps = {
  campaignId: number;
  domainId: number;
  weekNumber: number;
  yearNumber: number;
};

const TopLocations = ({
  campaignId,
  domainId,
  weekNumber,
  yearNumber,
}: TopLocationsProps) => {
  const [location, setLocation] = useState<Option | null>(null);

  const topLocationsData = useTopLocations({
    campaignId,
    domainId,
    weekNumber,
    yearNumber,
  });

  const getTabs = useCallback(
    (locationsMap: AggregatedTopLocations, location: string) => {
      const signals = locationsMap[location] || {};
      const tabPanels: ReactElement[] = [];
      const tabs: string[] = [];

      Object.keys(signals).forEach((signal) => {
        tabs.push(signal);
        tabPanels.push(
          <TopLocationsSignalsTable
            key={signal}
            data={Object.values(locationsMap[location][signal])}
          />
        );
      });

      return {
        tabs,
        tabPanels,
      };
    },
    []
  );

  if (topLocationsData.isLoading || topLocationsData.isRefetching) {
    return <Loader minHeight={0} height="200" />;
  }

  if (!topLocationsData.data) {
    return <NoData />;
  }

  const locationOptions: Option[] = Object.keys(topLocationsData.data).map(
    (location) => ({
      label: location,
      value: location,
    })
  );

  const { tabs, tabPanels } = getTabs(
    topLocationsData.data,
    String(location?.value) || ""
  );

  return (
    <VStack align="stretch" minH={400}>
      <Select
        isMulti={false}
        value={location}
        placeholder="Select location"
        options={locationOptions}
        onChange={(selectedOption) => {
          setLocation(selectedOption);
        }}
      />
      {location && (
        <Tabs isLazy colorScheme="teal">
          <TabList>
            {tabs.map((title, index) => (
              <Tab key={index}>
                <Text fontWeight="semibold">{title}</Text>
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            {tabPanels.map((item, index) => (
              <TabPanel key={index} px={0}>
                {item}
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      )}
    </VStack>
  );
};

export { TopLocations };
