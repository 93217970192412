import { Flex, Text } from "@chakra-ui/react";
import { ChangeIndicator } from "components";

type ValueWithStatProps = {
  currentWeek: number;
  previousWeek: number;
  tooltipDescription?: string;
  display?: "number" | "percentage" | "no-display";
  inverted?: boolean;
};

const ValueWithStat = ({
  currentWeek,
  previousWeek,
  display,
  inverted,
  tooltipDescription,
}: ValueWithStatProps) => {
  return (
    <Flex alignItems="center">
      <Text mr={2}>{currentWeek}</Text>

      <ChangeIndicator
        value={currentWeek}
        previousValue={previousWeek}
        display={display}
        inverted={inverted}
        tooltipDescription={tooltipDescription}
      />
    </Flex>
  );
};

export { ValueWithStat };
