import { Flex, Icon, useColorModeValue } from "@chakra-ui/react";
import { GenericUserJobRecord, GenericUserJobStatus } from "@intentsify/types";
import { formatDate } from "@intentsify/utils";
import { ColumnDef } from "@tanstack/react-table";
import { Badge, EMPTY_CELL, IconButton } from "components";
import { FaFileCsv } from "react-icons/fa";
import { FiDownload } from "react-icons/fi";
import { useTrackFileDownloaded } from "tracking/useTrackFileDownloaded";

export const useColumns = (): Array<ColumnDef<GenericUserJobRecord>> => {
  const trackFileDownloaded = useTrackFileDownloaded();
  const csvIconColor = useColorModeValue("gray.600", "gray.200");

  const columns: Array<ColumnDef<GenericUserJobRecord>> = [
    {
      header: "File Name",
      accessorKey: "fileName",
      enableSorting: false,
      cell: ({ row }) => {
        const filename = row.original.filename;

        if (!filename) {
          return EMPTY_CELL;
        }

        return (
          <Flex alignItems="center">
            <Icon color={csvIconColor} w={4} h={4} mr={2} as={FaFileCsv} />
            {filename}
          </Flex>
        );
      },
    },
    {
      header: "Status",
      accessorKey: "state",
      enableSorting: false,
      cell: ({ row }) => {
        const status = row.original.status;

        if (row.original.status === GenericUserJobStatus.SUCCESS) {
          return <Badge colorScheme="green">{status}</Badge>;
        }

        if (row.original.status === GenericUserJobStatus.ERROR) {
          return <Badge colorScheme="red">{status}</Badge>;
        }

        return <Badge colorScheme="blue">{status}</Badge>;
      },
    },

    {
      header: "Created At",
      accessorKey: "createdAt",
      cell: ({ row }) => {
        return formatDate({
          date: new Date(row.original.createdAt),
        });
      },
    },
    {
      header: "Download",
      enableSorting: false,
      cell: ({ row }) => {
        const filename = row.original.filename;

        if (row.original.status !== GenericUserJobStatus.SUCCESS || !filename) {
          return EMPTY_CELL;
        }

        return (
          <IconButton
            as="a"
            icon={<Icon as={FiDownload} fontSize="md" />}
            href={row.original.downloadURL}
            onClick={() =>
              trackFileDownloaded({
                label: "Business Data Append",
                fileName: filename,
              })
            }
            tooltip="Download file"
            target="_blank"
          />
        );
      },
    },
  ];

  return columns;
};
