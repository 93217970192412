import { ProgrammaticDomainsScreen } from "types";
import { createScreen } from "utils/createScreen";

const ProgrammaticDomainsScreenDefinition = createScreen({
  name: ProgrammaticDomainsScreen,
  path: "/media-activation/programmatic-segments/:view",
  routePath: ["/"],
  accessPath: "programmaticCampaign.programmaticDomain",
});

export { ProgrammaticDomainsScreenDefinition };
