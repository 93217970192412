import { DateRange, Option, SplitBy } from "@intentsify/types";
import { atom, selector, useResetRecoilState } from "recoil";
import { ViewEnum } from "screens/IntentAnalysis/screens/CampaignsTrendingDomains/CampaignsTrendingDomains.types";
import { TopTrendingDomainsOptions } from "./TopTrendingAccounts.types";

export const topTrendingDomainsDateRangeAtom = atom<DateRange>({
  key: `topTrendingDomainsDateRange`,
  default: DateRange.LastFourWeeks,
});

export const topTrendingDomainsSplitByAtom = atom<SplitBy>({
  key: `topTrendingDomainsSplitByAtom`,
  default: SplitBy.OneWeek,
});

export const topTrendingDomainsViewAtom = atom<ViewEnum>({
  key: `topTrendingDomainsViewAtom`,
  default: ViewEnum.TimeSeries,
});

export const topTrendingSelectedDomainsAtom = atom<{
  campaignId: number | undefined;
  data: Array<Option<number>>;
}>({
  key: `topTrendingSelectedDomainsAtom`,
  default: {
    campaignId: undefined,
    data: [],
  },
});

export const trendingDomainsOptionsStateAtom = atom<TopTrendingDomainsOptions>({
  key: `TrendingDomainsOptionsStateAtom`,
  default: {
    isLoading: true,
    topTrendingDomains: [],
  },
});

export const topTrendingDomainsStateSelector = selector({
  key: "topTrendingDomainsStateSelector",
  get: ({ get }) => {
    const { isLoading, topTrendingDomains } = get(
      trendingDomainsOptionsStateAtom
    );

    const selectedDateRange = get(topTrendingDomainsDateRangeAtom);
    const selectedSplitBy = get(topTrendingDomainsSplitByAtom);
    const selectedView = get(topTrendingDomainsViewAtom);
    const selectedDomains = get(topTrendingSelectedDomainsAtom);

    return {
      isLoading,
      topTrendingDomainsOptions: topTrendingDomains,

      selectedDateRange,
      selectedSplitBy,
      selectedView,
      selectedDomains,
    };
  },
});

export const useResetTopTrendingState = () => {
  const list = [
    useResetRecoilState(trendingDomainsOptionsStateAtom),
    useResetRecoilState(topTrendingDomainsDateRangeAtom),
    useResetRecoilState(topTrendingDomainsSplitByAtom),
    useResetRecoilState(topTrendingDomainsViewAtom),
    useResetRecoilState(topTrendingSelectedDomainsAtom),
  ];

  return () => {
    list.forEach((f) => f());
  };
};
