import { useEffect } from "react";
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Stack,
  UseDisclosureReturn,
  useToast,
} from "@chakra-ui/react";
import {
  OnDemandCumulativeReportType,
  ReportType,
  ReportTypeLabel,
} from "@intentsify/types";
import { ErrorBoundary } from "../ErrorBoundary";
import { Controller, useForm } from "react-hook-form";
import { Button } from "../Button";
import { useComponentColors } from "theme";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useRequestCumulativeCampaignReport } from "../../mutations/campaigns/useRequestCumulativeCampaignReport";
import { RangePicker } from "../RangePicker";
import { useTrackRequestReporting } from "../../tracking/useTrackRequestReporting";
import { DateTime } from "luxon";
import { Modal } from "../Modal";
import { useQuery } from "@tanstack/react-query";
import { getAdSolutionsReportDateRange } from "./RequestOnDemandCumulativeCampaignReport.requests";
import { Loader } from "../Loader";

type Props = {
  campaignId: number;
  reportType: OnDemandCumulativeReportType;
  campaignStartDate: string | undefined;
  campaignEndDate: string | undefined;
} & Pick<UseDisclosureReturn, "isOpen" | "onClose">;

const onDemandCumulativeReportTypeValues = [
  ReportType.CumulativeIntentFeedBRS,
  ReportType.CumulativeIntentFeedTopics,
  ReportType.CumulativeSitePixelData,
  ReportType.CumulativeAdSolutions,
] as const;

export const requestOnDemandCumulativeReportSchema = z.object({
  campaignId: z.number(),
  type: z.enum(onDemandCumulativeReportTypeValues),
  selectedDate: z.object({
    startDate: z.string(),
    endDate: z.string(),
  }),
});

export type RequestOnDemandCumulativeReport = z.infer<
  typeof requestOnDemandCumulativeReportSchema
>;

export const RequestOnDemandCumulativeCampaignReport = ({
  campaignId,
  campaignStartDate,
  campaignEndDate,
  reportType,
  onClose,
  isOpen,
}: Props) => {
  const { data: adSolutionsReportDateRange, isFetching } = useQuery({
    queryKey: ["getAdSolutionsReportDateRange", campaignId],
    queryFn: async () => getAdSolutionsReportDateRange(campaignId),
    enabled: reportType === ReportType.CumulativeAdSolutions,
  });

  // if it is Cumulative Ad Solutions report we use dates from Beeswax
  const startDate =
    reportType !== ReportType.CumulativeAdSolutions ||
    !adSolutionsReportDateRange?.startDate
      ? campaignStartDate
      : adSolutionsReportDateRange.startDate;
  const endDate =
    reportType !== ReportType.CumulativeAdSolutions ||
    !adSolutionsReportDateRange?.endDate
      ? campaignEndDate
      : adSolutionsReportDateRange.endDate;

  const now = DateTime.now();

  const startDateJDate = startDate ? DateTime.fromISO(startDate) : now;
  const endDateJSDate = endDate ? DateTime.fromISO(endDate) : now;

  const startDateWithTime = DateTime.fromObject({
    day: startDateJDate.day,
    month: startDateJDate.month,
    year: startDateJDate.year,
    hour: now.hour,
    minute: now.minute,
    second: now.second,
  });
  const endDateWithTime = DateTime.fromObject({
    day: endDateJSDate.day,
    month: endDateJSDate.month,
    year: endDateJSDate.year,
    hour: now.hour,
    minute: now.minute,
    second: now.second,
  });

  const dateRangePickerStartDate = startDateWithTime.toISO();
  const dateRangePickerEndDate =
    endDateWithTime.startOf("day") <= now.startOf("day")
      ? endDateWithTime.toISO()
      : now.toISO();

  const toast = useToast();
  const componentColors = useComponentColors();
  const trackRequestReporting = useTrackRequestReporting();

  const {
    formState: { errors },
    control,
    getValues,
    resetField,
    setValue,
  } = useForm<RequestOnDemandCumulativeReport>({
    mode: "onChange",
    resolver: zodResolver(requestOnDemandCumulativeReportSchema),
    defaultValues: {
      type: reportType,
      campaignId: campaignId,
    },
  });

  const { mutate: requestOnDemandCumulativeReportMutation, isLoading } =
    useRequestCumulativeCampaignReport({
      onSuccess: () => {
        toast({
          title: "Report requested.",
          status: "success",
        });
      },
    });

  const dailyReports: OnDemandCumulativeReportType[] = [
    ReportType.CumulativeSitePixelData,
    ReportType.CumulativeAdSolutions,
  ];

  const label = ReportTypeLabel[reportType];

  useEffect(() => {
    setValue("selectedDate", {
      startDate: dateRangePickerStartDate,
      endDate: dateRangePickerEndDate,
    });
  }, [setValue, dateRangePickerStartDate, dateRangePickerEndDate]);

  return (
    <Modal
      overflow="visible"
      title={label}
      isOpen={isOpen}
      onClose={() => {
        resetField("campaignId");
        resetField("selectedDate");
        resetField("type");
        onClose();
      }}
      size="2xl"
      body={
        <Flex pb="4">
          <ErrorBoundary>
            {isFetching ? (
              <Loader label="" minHeight={"100px"} height="100px" />
            ) : (
              <Stack width="100%">
                <FormControl
                  isInvalid={!!errors?.selectedDate?.message}
                  isRequired
                >
                  <FormLabel
                    fontSize="sm"
                    fontWeight="md"
                    color={componentColors.form.formLabelColor}
                  >
                    {dailyReports.includes(reportType) ? "Day" : "Week"}
                  </FormLabel>

                  <Controller
                    control={control}
                    name="selectedDate"
                    render={({ field: { onChange, value } }) => {
                      return (
                        <RangePicker
                          mode={
                            dailyReports.includes(reportType) ? "day" : "week"
                          }
                          selected={{
                            start: value.startDate,
                            end: value.endDate,
                          }}
                          onChange={(e) => {
                            onChange({
                              startDate: e.start,
                              endDate: e.end,
                            });
                          }}
                          disabledDays={[{ gt: now.toUTC() }]}
                        />
                      );
                    }}
                  />
                  <FormErrorMessage>
                    {errors?.selectedDate?.message}
                  </FormErrorMessage>
                </FormControl>
              </Stack>
            )}
          </ErrorBoundary>
        </Flex>
      }
      primaryButton={
        <Button
          variant="primary-teal"
          isLoading={isLoading}
          onClick={() => {
            const { campaignId, type, selectedDate } = getValues();
            requestOnDemandCumulativeReportMutation({
              id: campaignId,
              params: {
                type: type,
                startDate: selectedDate.startDate.slice(0, 10),
                endDate: selectedDate.endDate.slice(0, 10),
              },
            });
            trackRequestReporting({
              campaignId,
              requestingDeck: type,
            });
            resetField("campaignId");
            resetField("selectedDate");
            resetField("type");
            onClose();
          }}
        >
          Process
        </Button>
      }
    ></Modal>
  );
};
