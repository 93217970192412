import { Box, Flex, Portal, Stack, Text } from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useMeasure, useWindowSize } from "react-use";
import { FixedSizeGrid as Grid } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import { useComponentColors } from "theme";
import { useTrackSearched } from "tracking/useTrackSearched";
import { BaseMetaData, Item } from "types";
import { useDeepEffect } from "utils";
import { Loader } from "../Loader";
import { NoData } from "../NoData";
import { Search } from "../Search";
import { SelectedTags } from "../SelectedTags";
import { SelectedTagsHeader } from "../SelectedTagsHeader";
import { Cell } from "./Cell";

const MIN_COLUMN_WIDTH = 400;

type WindowedCheckboxProps<
  T extends Item<M>,
  M extends BaseMetaData = BaseMetaData
> = {
  compact?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  data: T[];
  checkedItems: T[];
  disabledItems?: T[];
  onCheck: (item: T) => void;
  subject: string;
  customTitle?: string;
  customSubtitle?: string;
  onLoadMore?: (startIndex: number, stopIndex: number) => void;
  resetInfiniteLoaderDeps?: any[];
  showSelected?: boolean;
  showSearch?: boolean;
  metaNodeProperty?: keyof T["metaData"];
  onReset?: () => void;
  onSearch?: (value: string) => void;
};

const WindowedCheckbox = <
  T extends Item<M>,
  M extends BaseMetaData = BaseMetaData
>({
  compact = false,
  isDisabled = false,
  isLoading = false,
  data,
  onCheck,
  checkedItems,
  disabledItems = [],
  subject,
  customTitle,
  customSubtitle,
  onLoadMore = () => undefined,
  resetInfiniteLoaderDeps = [],
  showSelected = true,
  showSearch = true,
  metaNodeProperty,
  onReset,
  onSearch,
}: WindowedCheckboxProps<T, M>) => {
  const [list, setList] = useState<T[]>(data);
  const [key, setKey] = useState(Date.now());
  const [wrapperRef, { width, height }] = useMeasure<HTMLDivElement>();
  const componentColors = useComponentColors();

  /**
   * react-window grid cannot be responsive, so we watch the changes in window dimensions, if it changes
   * we hide react-window grid for a split second, take the wrapper width and re-render it with new width.
   */
  const [shouldRecalculateSize, setShouldRecalculateSize] = useState(false);
  const { width: windowWidth } = useWindowSize();

  useEffect(() => {
    setShouldRecalculateSize(true);
  }, [windowWidth]);

  useEffect(() => {
    setShouldRecalculateSize(false);
  }, [width]);

  useEffect(() => {
    setList(data);
    isFetching.current = false;
  }, [data]);

  const ref = useRef<HTMLDivElement>(null);
  const isFetching = useRef<boolean>(false);

  const resetScroll = () => setKey(Date.now());

  const localSearchHandler = useCallback(
    (value: string) => {
      setList(
        data.filter(
          (entry) =>
            entry.label &&
            entry.label.toLowerCase().includes(value.toLowerCase())
        )
      );

      resetScroll();
    },
    [data]
  );

  // Infinite loader needs the total item count to be larger then data length,
  // otherwise it will not trigger the next page to load. We add the "10" but it could
  // be anything. It still works fine but could be improved with an actual precise count.
  const itemCount = data.length + 10;

  /**
   * To tell if an item is loaded we need to maintain a map:
   * - create an array (length === total item count)
   * - fill it with false by default
   * - fill it again but overwrite it with true for items you already have
   */
  const isItemLoadedDataMap = useMemo(() => {
    return new Map(
      Array(itemCount)
        .fill(false)
        .fill(true, 0, data.length)
        .map((item, index) => [index, item])
    );
  }, [data, itemCount]);

  const infiniteLoaderRef = useRef<null | InfiniteLoader>(null);
  const hasMountedRef = useRef(false);
  const trackSearched = useTrackSearched();

  useDeepEffect(() => {
    if (hasMountedRef.current && resetInfiniteLoaderDeps.length > 0) {
      if (infiniteLoaderRef.current) {
        infiniteLoaderRef.current.resetloadMoreItemsCache();
        // Reset scroll e.g.: when data filters changed
        resetScroll();
      }
    }
    hasMountedRef.current = true;
  }, resetInfiniteLoaderDeps);

  const columnCount = Math.floor(width / MIN_COLUMN_WIDTH);
  const rowCount = Math.ceil(itemCount / columnCount);
  const columnWidth = width / columnCount;

  if (compact) {
    return (
      <Stack>
        {showSelected && (
          <Box>
            <SelectedTagsHeader
              mb="2"
              hideCount
              title={customTitle ?? `Selected ${subject}`}
              items={checkedItems}
              onReset={onReset}
            />
          </Box>
        )}

        {showSearch && (
          <>
            <Portal containerRef={ref}>
              <Search
                onSearch={(v) => {
                  (onSearch || localSearchHandler)(v);
                  trackSearched({
                    term: v,
                    collocation: "Windowed checkbox tree search",
                  });
                }}
              />
            </Portal>
            <div ref={ref} />
          </>
        )}

        {data.length === 0 ? (
          <>
            {isLoading && <Loader height="400px" />}
            {!isLoading && <NoData height="400px" />}
          </>
        ) : (
          <Box ref={wrapperRef} height={400}>
            {width && height && !shouldRecalculateSize && (
              <Flex>
                <InfiniteLoader
                  ref={infiniteLoaderRef}
                  isItemLoaded={(index) => isItemLoadedDataMap.get(index)}
                  itemCount={itemCount}
                  loadMoreItems={(startIndex, stopIndex) => {
                    if (isFetching.current || data.length === 0 || isDisabled) {
                      return undefined;
                    }

                    isFetching.current = true;
                    onLoadMore(startIndex, stopIndex);
                  }}
                >
                  {({ onItemsRendered, ref }) => (
                    <Grid
                      key={key}
                      ref={ref}
                      onItemsRendered={(gridProps) => {
                        onItemsRendered({
                          overscanStartIndex:
                            gridProps.overscanRowStartIndex * columnCount,
                          overscanStopIndex:
                            gridProps.overscanRowStopIndex * columnCount,
                          visibleStartIndex:
                            gridProps.visibleRowStartIndex * columnCount,
                          visibleStopIndex:
                            gridProps.visibleRowStopIndex * columnCount,
                        });
                      }}
                      style={{
                        overflowX: "hidden",
                        overflowY: isDisabled ? "hidden" : "auto",
                        border:
                          "1px solid var(--chakra-colors-chakra-border-color)",
                        borderRadius: "var(--chakra-radii-md)",
                      }}
                      columnCount={columnCount}
                      columnWidth={columnWidth}
                      height={height}
                      rowCount={rowCount}
                      rowHeight={40}
                      width={width / 2}
                      itemData={{
                        list,
                        columnCount,
                        checkedItems,
                        disabledItems,
                        isLoading,
                        maxWidth: MIN_COLUMN_WIDTH,
                        disableAll: isDisabled,
                        metaNodeProperty,
                      }}
                    >
                      {Cell(onCheck, "4")}
                    </Grid>
                  )}
                </InfiniteLoader>

                <Box ml="8" overflowY="auto" maxH="400px" maxW="50%">
                  <Text
                    color={componentColors.form.formLabelColor}
                    fontSize="sm"
                    mb="2"
                  >
                    Selected categories ({checkedItems.length || 0})
                  </Text>

                  <SelectedTags
                    items={checkedItems}
                    subject={subject}
                    onRemoveByItem={onCheck}
                    customTitle={customSubtitle}
                  />
                </Box>
              </Flex>
            )}
          </Box>
        )}
      </Stack>
    );
  }

  return (
    <Stack>
      {showSelected && (
        <Box maxW="100%">
          <SelectedTagsHeader
            title={customTitle ?? `Selected ${subject}`}
            items={checkedItems}
            onReset={onReset}
          />

          <SelectedTags
            items={checkedItems}
            subject={subject}
            onRemoveByItem={onCheck}
            customTitle={customSubtitle}
          />
        </Box>
      )}

      {showSearch && (
        <>
          <Portal containerRef={ref}>
            <Search
              onSearch={(v) => {
                (onSearch || localSearchHandler)(v);
                trackSearched({
                  term: v,
                  collocation: "Windowed checkbox tree search",
                });
              }}
            />
          </Portal>
          <div ref={ref} />
        </>
      )}

      {data.length === 0 ? (
        <>
          {isLoading && <Loader height="400px" />}
          {!isLoading && <NoData height="400px" />}
        </>
      ) : (
        <div ref={wrapperRef} style={{ height: 400, flex: "1 1 auto" }}>
          {width && height && !shouldRecalculateSize && (
            <InfiniteLoader
              ref={infiniteLoaderRef}
              isItemLoaded={(index) => isItemLoadedDataMap.get(index)}
              itemCount={itemCount}
              loadMoreItems={(startIndex, stopIndex) => {
                if (isFetching.current || data.length === 0 || isDisabled) {
                  return undefined;
                }

                isFetching.current = true;
                onLoadMore(startIndex, stopIndex);
              }}
            >
              {({ onItemsRendered, ref }) => (
                <Grid
                  key={key}
                  ref={ref}
                  onItemsRendered={(gridProps) => {
                    onItemsRendered({
                      overscanStartIndex:
                        gridProps.overscanRowStartIndex * columnCount,
                      overscanStopIndex:
                        gridProps.overscanRowStopIndex * columnCount,
                      visibleStartIndex:
                        gridProps.visibleRowStartIndex * columnCount,
                      visibleStopIndex:
                        gridProps.visibleRowStopIndex * columnCount,
                    });
                  }}
                  style={{
                    overflowX: "hidden",
                    overflowY: isDisabled ? "hidden" : "auto",
                  }}
                  columnCount={columnCount}
                  columnWidth={columnWidth}
                  height={height}
                  rowCount={rowCount}
                  rowHeight={40}
                  width={!shouldRecalculateSize ? width : 0}
                  itemData={{
                    list,
                    columnCount,
                    checkedItems,
                    disabledItems,
                    isLoading,
                    maxWidth: MIN_COLUMN_WIDTH,
                    disableAll: isDisabled,
                    metaNodeProperty,
                  }}
                >
                  {Cell(onCheck)}
                </Grid>
              )}
            </InfiniteLoader>
          )}
        </div>
      )}
    </Stack>
  );
};

export { WindowedCheckbox };
