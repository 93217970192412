import { Flex } from "@chakra-ui/layout";
import MapGL, { MapLayerMouseEvent, MapRef } from "react-map-gl";
import { ReactNode, RefObject } from "react";
import { useColorMode } from "@chakra-ui/react";
import { useMapStyle } from "./Map.hooks";
import { MapLegendLayerToggle } from "./components";
import { ErrorBoundary, Loader } from "components";
import { WithChildren } from "types";

export const MAP_LEGEND_HEIGHT = 28;

type Props = {
  layersIds: string[];
  onClick: (e: MapLayerMouseEvent) => void;
  onMouseMove: (e: MapLayerMouseEvent) => void;
  isLoading: boolean;
  isGlobe?: boolean;
  height: string;
  mapRef: RefObject<MapRef>;
  renderLegend: () => ReactNode;
  renderSources: () => ReactNode;
  initialBounds?: [number, number, number, number] | undefined;
};

const Map = ({
  layersIds,
  onClick,
  onMouseMove,
  isGlobe = false,
  isLoading,
  height,
  mapRef,
  renderLegend,
  renderSources,
  initialBounds,
}: WithChildren<Props>) => {
  const { colorMode } = useColorMode();
  const mapStyle = useMapStyle(colorMode);

  if (isLoading) {
    return <Loader minHeight={0} height={height} />;
  }

  return (
    <ErrorBoundary>
      <Flex marginY={1}>{renderLegend()}</Flex>
      <MapGL
        projection={isGlobe ? "globe" : "mercator"}
        mapStyle={mapStyle}
        interactiveLayerIds={layersIds}
        attributionControl={false}
        style={{ width: "100%", height }}
        mapboxAccessToken="pk.eyJ1IjoiZ3RiOGtiYWR6djl3ZmEwdHlhIiwiYSI6ImNsZGVyZWViMzBiMDczcHQ1dm80N2pvZjEifQ.czm3-RCTbAQzrKCbdyP2-g"
        onMouseMove={onMouseMove}
        ref={mapRef}
        onClick={onClick}
        initialViewState={{
          bounds: initialBounds,
          fitBoundsOptions: {
            maxZoom: 5,
            padding: 50,
          },
        }}
      >
        {renderSources()}
      </MapGL>
    </ErrorBoundary>
  );
};

Map.MapLegendLayerToggle = MapLegendLayerToggle;

export { Map };
