import { Box, Flex } from "@chakra-ui/layout";
import { Text as ChakraText, useColorMode } from "@chakra-ui/react";
import { DateRangeFormat } from "@intentsify/types";
import {
  formatDate,
  formatDateRange,
  isDefined,
  isPopulatedArray,
  toNumberDisplayValue,
} from "@intentsify/utils";
import { AxisBottom, AxisLeft, Orientation } from "@visx/axis";
import { localPoint } from "@visx/event";
import { Group } from "@visx/group";
import { ParentSize } from "@visx/responsive";
import { scaleBand, scaleLinear, scaleLog } from "@visx/scale";
import { Bar, Line } from "@visx/shape";
import { Text } from "@visx/text";
import { useTooltip, useTooltipInPortal } from "@visx/tooltip";
import { ScaleBand, ScaleLinear } from "d3-scale";
import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { animated, useSpring } from "react-spring";
import { useMeasure } from "react-use";
import { getChartColours } from "theme";
import { TimeseriesItemDataRecord } from "types";
import { useChartColors } from "utils";
import { ErrorBoundary } from "../../ErrorBoundary";
import { Loader } from "../../Loader";
import { NoData } from "../../NoData";
import { BasicTooltip } from "../BasicTooltip";
import {
  EXPORT_LEGEND_WIDTH,
  EXPORT_TITLE_HEIGHT,
  MIN_HEIGHT,
} from "../Charts.const";
import { formatTickValue, getWrapperDirection } from "../Charts.utils";
import { TimeseriesProps } from "./Timeseries";
import { useTimeseriesData } from "./Timeseries.hooks";
import { findXAxisLabelFrequency } from "./Timeseries.utils";
import { DataSources, Legend } from "./components";

type BarsProps<Keys extends string, DataKeys extends string> = TimeseriesProps<
  Keys,
  DataKeys
>;

const Bars = <K extends string, T extends string>({
  timeseries,
  data,
  dataMainKey,
  dataKeyLabels,
  id,
  title,
  exportMode = undefined,
  disableLegend = false,
  disableLegendTooltip = true,
  legendItemActions,
  disableDataSources = false,
  legendPosition = undefined,
  isLoading = false,
  dateFormat = DateRangeFormat.StartDateMonth,
  margins = {
    t: 20,
    b: 80,
    r: 10,
    l: 60,
  },
  stretch = false,
  noDataMessage,
  pallete,
  shufflePallete = false,
  includePalleteShades = false,
  fixedTooltip = false,
  scale = "linear",
  minHeight = MIN_HEIGHT,
}: BarsProps<K, T>) => {
  const [uniqueKey] = useState(Math.random().toString());

  const [ref, { width: containerWidth }] = useMeasure<HTMLDivElement>();

  const { dataWithUniqueKeys, allValues, dataKeys, timeValues } =
    useTimeseriesData(data, timeseries, uniqueKey);

  const timeScale = useRef<ScaleBand<number> | undefined>();
  const valuesScale = useRef<ScaleLinear<number, number, never> | undefined>();

  const { labelColor, axisColors } = useChartColors();

  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip<{
    isoDate: string;
    data: TimeseriesItemDataRecord<T>;
  }>();

  const { colorMode } = useColorMode();
  const chartColors = useMemo(() => {
    return getChartColours(
      colorMode,
      pallete,
      shufflePallete,
      includePalleteShades
    );
  }, [colorMode, pallete, shufflePallete, includePalleteShades]);

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    detectBounds: !fixedTooltip,
    scroll: true,
  });

  const resetInteractions = useCallback(() => {
    if (tooltipOpen) {
      hideTooltip();
    }
  }, [tooltipOpen, hideTooltip]);

  useEffect(() => {
    window.addEventListener("scroll", resetInteractions);

    return () => {
      window.removeEventListener("scroll", resetInteractions);
    };
  }, [resetInteractions]);

  const [{ scale: springScale }] = useSpring(
    {
      from: { scale: 0 },
      to: { scale: 1 },
      reset: true,
    },
    [isLoading]
  );

  if (isLoading) {
    return <Loader minHeight={minHeight} stretch={stretch} />;
  }

  if (!isPopulatedArray(dataWithUniqueKeys)) {
    return <NoData message={noDataMessage} stretch={stretch} />;
  }

  const legendPositionComputed = legendPosition
    ? legendPosition
    : containerWidth > 1200
    ? "right"
    : "bottom";

  const AnimatedBar = animated(Bar);

  if (dataWithUniqueKeys.length > 1) {
    // eslint-disable-next-line no-console
    console.warn(
      "Timeseries Bar variant currently does not support multiple data points."
    );
  }

  return (
    <ErrorBoundary>
      <Flex ref={ref} width="100%" height="100%">
        <Flex
          flexDirection={getWrapperDirection(legendPositionComputed)}
          width="100%"
        >
          <Flex
            width="100%"
            flexGrow={stretch ? 1 : 0}
            minHeight={minHeight}
            overflow="hidden"
          >
            <ParentSize>
              {(parent) => {
                const { width, height } = parent;

                const baseYMax = height - margins.t - margins.b;
                const baseXMax = width - margins.l - margins.r;

                const yMax = !exportMode
                  ? baseYMax
                  : baseYMax - EXPORT_TITLE_HEIGHT;
                const xMax = !exportMode
                  ? baseXMax
                  : baseXMax - EXPORT_LEGEND_WIDTH;

                timeScale.current = scaleBand<number>({
                  range: [0, xMax],
                  round: true,
                  domain: timeValues,
                  padding: 0.4,
                });

                valuesScale.current =
                  scale === "linear"
                    ? scaleLinear<number>({
                        domain: [0, Math.max(...allValues)],
                        range: [yMax, 0],
                        nice: true,
                      })
                    : scaleLog<number>({
                        domain: [
                          Math.min(...allValues),
                          Math.max(...allValues),
                        ],
                        range: [yMax, 0],
                      });

                const xLabelFrequency = findXAxisLabelFrequency({
                  width: xMax,
                  itemsCount: timeValues.length,
                });

                return (
                  <Box
                    width={width}
                    height={height}
                    position="relative"
                    onMouseLeave={() => resetInteractions()}
                  >
                    <svg
                      width={width}
                      height={height}
                      ref={containerRef}
                      id={id}
                      style={{
                        fontFamily: "var(--chakra-fonts-body)",
                      }}
                    >
                      <rect
                        x={0}
                        y={0}
                        width={width}
                        height={height}
                        fill={"transparent"}
                        id="wrapper"
                        onMouseMove={(e: React.MouseEvent) => {
                          if (
                            e.currentTarget.getAttribute("id") === "wrapper"
                          ) {
                            resetInteractions();
                          }
                        }}
                      />

                      {exportMode && title && (
                        <Group top={30} left={22}>
                          <Text
                            verticalAnchor="middle"
                            fontSize={22}
                            fontWeight="semibold"
                            fill={labelColor}
                          >
                            {title}
                          </Text>
                        </Group>
                      )}

                      <Group>
                        <g
                          transform={`translate(${margins.l},${
                            !exportMode
                              ? margins.t
                              : margins.t + EXPORT_TITLE_HEIGHT
                          })`}
                        >
                          {dataWithUniqueKeys[0].timeseries.map((d) => {
                            if (!valuesScale.current || !timeScale.current) {
                              return null;
                            }

                            const barWidth = timeScale.current.bandwidth();
                            const barHeight =
                              yMax -
                              (valuesScale.current(
                                d.data[dataKeys[0]] as number
                              ) ?? 0);

                            const barX = timeScale.current(
                              DateTime.fromISO(d.isoDate).toUTC().valueOf()
                            );

                            return (
                              <AnimatedBar
                                key={Math.random()}
                                x={barX}
                                y={springScale.to((s) => yMax - s * barHeight)}
                                width={barWidth}
                                height={springScale.to((s) => s * barHeight)}
                                fill={chartColors[0]}
                                onMouseMove={(event) => {
                                  if (event.target) {
                                    const coords = localPoint(
                                      // @ts-ignore
                                      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                                      event.target.ownerSVGElement,
                                      event
                                    );
                                    if (coords) {
                                      showTooltip({
                                        tooltipLeft: coords.x,
                                        tooltipTop: coords.y,
                                        tooltipData: d,
                                      });
                                    }
                                  }
                                }}
                                onMouseLeave={() => {
                                  hideTooltip();
                                }}
                              />
                            );
                          })}

                          <Text
                            x={-yMax / 2}
                            y={-margins.l + 12}
                            textAnchor="middle"
                            verticalAnchor="end"
                            transform="rotate(-90)"
                            fontSize={14}
                            fill={labelColor}
                          >
                            {dataKeys
                              .map((k) =>
                                dataKeyLabels ? dataKeyLabels[k] : k
                              )
                              .join(" / ")}
                          </Text>

                          <AxisLeft
                            stroke={axisColors}
                            tickStroke={axisColors}
                            scale={valuesScale.current}
                            labelProps={{ fill: labelColor }}
                            tickFormat={
                              scale === "log"
                                ? (v) => {
                                    const asString = `${String(v)}`;
                                    // label only major ticks
                                    return asString.match(/^[.01?[\]]*$/)
                                      ? formatTickValue(Number(v))
                                      : "";
                                  }
                                : (v) => formatTickValue(Number(v))
                            }
                            numTicks={10}
                            tickComponent={(props) => {
                              const {
                                formattedValue,
                                x,
                                y,
                                textAnchor,
                                verticalAnchor,
                                dx,
                                dy,
                              } = props;

                              return (
                                <Text
                                  x={x}
                                  y={y}
                                  dx={dx}
                                  dy={dy}
                                  fontSize={12}
                                  textAnchor={textAnchor}
                                  verticalAnchor={verticalAnchor}
                                  fill={labelColor}
                                >
                                  {formattedValue}
                                </Text>
                              );
                            }}
                          />

                          <AxisBottom
                            top={yMax}
                            orientation={Orientation.bottom}
                            tickValues={timeValues}
                            scale={timeScale.current}
                            // @ts-ignore
                            tickFormat={(val) => val}
                          >
                            {(props) => {
                              const tickLabelSize = 10;
                              const tickRotate = -45;

                              const { axisFromPoint, axisToPoint } = props;

                              return (
                                <g>
                                  <Line
                                    from={axisFromPoint}
                                    to={axisToPoint}
                                    stroke={axisColors}
                                  />

                                  {props.ticks.map((tick, i) => {
                                    const series = timeseries.find(
                                      (s) =>
                                        DateTime.fromISO(s.start)
                                          .toUTC()
                                          .valueOf() ===
                                        Number(tick.formattedValue)
                                    );

                                    const label = series
                                      ? formatDateRange(series, dateFormat)
                                      : undefined;

                                    const tickX = tick.to.x;
                                    const tickY = tick.to.y + 10;

                                    return (
                                      <Group
                                        key={`vx-tick-${String(
                                          tick.value
                                        )}-${i}`}
                                        className={"vx-axis-tick"}
                                      >
                                        <Line
                                          from={tick.from}
                                          to={tick.to}
                                          stroke={axisColors}
                                        />

                                        {i % xLabelFrequency === 0 && (
                                          <text
                                            cursor="default"
                                            transform={`translate(${tickX}, ${tickY}) rotate(${tickRotate})`}
                                            fontSize={tickLabelSize}
                                            textAnchor="end"
                                            fill={labelColor}
                                          >
                                            {label}
                                          </text>
                                        )}
                                      </Group>
                                    );
                                  })}
                                </g>
                              );
                            }}
                          </AxisBottom>
                        </g>
                      </Group>

                      {exportMode && (
                        <Group
                          width={`${EXPORT_LEGEND_WIDTH}px`}
                          top={margins.t + EXPORT_TITLE_HEIGHT}
                          left={width - margins.r / 2 - EXPORT_LEGEND_WIDTH}
                        >
                          {data.map((item, index) => {
                            return (
                              <>
                                <rect
                                  x={0}
                                  y={index * 32 - 8}
                                  width={16}
                                  height={16}
                                  fill={chartColors[index]}
                                />

                                <Text
                                  verticalAnchor="middle"
                                  fontSize={14}
                                  key={index}
                                  width={EXPORT_LEGEND_WIDTH}
                                  x={24}
                                  y={index * 32}
                                  fill={labelColor}
                                >
                                  {item[dataMainKey]}
                                </Text>
                              </>
                            );
                          })}
                        </Group>
                      )}
                    </svg>
                    {tooltipOpen &&
                      tooltipData &&
                      isDefined(tooltipLeft) &&
                      isDefined(tooltipTop) && (
                        <BasicTooltip
                          tooltipRenderer={({ textColor, tooltipData }) => {
                            return (
                              <ChakraText fontSize="medium" color={textColor}>
                                <b>
                                  {formatDate({
                                    date: DateTime.fromISO(
                                      tooltipData.isoDate
                                    ).toJSDate(),
                                  })}
                                  :{" "}
                                </b>

                                {toNumberDisplayValue(
                                  Number(tooltipData.data[dataKeys[0]])
                                )}
                              </ChakraText>
                            );
                          }}
                          TooltipInPortal={TooltipInPortal}
                          tooltipLeft={tooltipLeft}
                          tooltipTop={tooltipTop}
                          tooltipData={tooltipData}
                        />
                      )}
                  </Box>
                );
              }}
            </ParentSize>
          </Flex>

          {!disableLegend && !exportMode && (
            <Flex
              flexDirection="column"
              width={legendPositionComputed === "bottom" ? "100%" : undefined}
              mt={legendPositionComputed === "bottom" ? 5 : undefined}
              ml={legendPositionComputed === "right" ? 5 : undefined}
            >
              {!disableDataSources && (
                <DataSources
                  uniqueKey={uniqueKey}
                  timeseries={timeseries}
                  data={dataWithUniqueKeys}
                  dataKeyLabels={dataKeyLabels}
                  legendPosition={legendPositionComputed}
                />
              )}

              <Legend
                disableLegendTooltip={disableLegendTooltip}
                dataMainKey={dataMainKey}
                uniqueKey={uniqueKey}
                legendPosition={legendPositionComputed}
                data={dataWithUniqueKeys}
                chartColors={chartColors}
                legendItemActions={legendItemActions}
              />
            </Flex>
          )}
        </Flex>
      </Flex>
    </ErrorBoundary>
  );
};

export { Bars };
