import { useToast } from "@chakra-ui/react";
import {
  BatchUpdateReviewedAssetsClientDTO,
  BatchUpdateReviewedAssetsRO,
  ListReviewedAssetsRO,
} from "@intentsify/dto";
import { pluralize } from "@intentsify/utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Endpoints, apiService } from "api";

export const useUpdateReviewedAssets = (onSuccess?: () => void) => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["contentLibrary", "reviewedAssets", "update"],
    mutationFn: async (
      reviewedAssets: BatchUpdateReviewedAssetsClientDTO[]
    ) => {
      const { data } = await apiService.put<BatchUpdateReviewedAssetsRO>(
        Endpoints.ContentLibrary.ReviewedAssets.Put.Batch,
        reviewedAssets
      );

      return data.reviewedAssets;
    },
    onSuccess: (data) => {
      toast({
        title: `${pluralize(data.length, "Asset", "Assets")} updated`,
        status: "success",
      });

      onSuccess?.();

      queryClient.setQueriesData<ListReviewedAssetsRO>(
        { queryKey: ["contentLibrary", "reviewedAssets"], exact: false },
        (oldData) => ({
          ...oldData,
          reviewedAssets: (oldData?.reviewedAssets || []).map(
            (reviewedAsset) =>
              data.find(
                (updatedAsset) => updatedAsset.id === reviewedAsset.id
              ) ?? reviewedAsset
          ),
        })
      );
    },
  });
};
