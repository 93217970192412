import {
  BusinessUnit,
  CampaignForList,
  SortDirection,
} from "@intentsify/types";
import { FetchDataParamsWithToken } from "types";

export type ListPaginatedCampaignsParams = FetchDataParamsWithToken<
  keyof CampaignForList
> & {
  isFavorite: boolean;
  includeCampaignsInDraft?: boolean;
};

export const defaultListPaginatedCampaignsParams: ListPaginatedCampaignsParams =
  {
    isFavorite: false,
    includeCampaignsInDraft: false,
    pageToken: "",
    order_by: "campaignId",
    order: SortDirection.DESC,
  };

export type CompanyBusinessUnitsFetchDataParams = FetchDataParamsWithToken<
  keyof BusinessUnit
>;

export const defaultListPaginatedCompanyBusinessUnitsParams: CompanyBusinessUnitsFetchDataParams =
  {
    search: undefined,
    order_by: "displayName",
    order: SortDirection.ASC,
    pageToken: "",
  };
