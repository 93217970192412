import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  chakra,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { isDefined, isPopulatedArray } from "@intentsify/utils";
import { useQuery } from "@tanstack/react-query";
import { Button, Filter, WeekPicker, WeekPickerProps } from "components";
import { DateTime } from "luxon";
import { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useComponentColors } from "theme";
import { z } from "zod";
import {
  getAvailableSpotlightDeckAccounts,
  getAvailableSpotlightDeckWeeks,
} from "./RequestSpotlightReportModal.requests";

type ModalBodyProps = {
  onSubmit: (params: { accounts: number[]; selectedWeek: string }) => void;
  campaignId: number;
  isSubmitting: boolean;
};

const validationSchemaZod = z.object({
  selectedWeek: z.string(),
  accounts: z.number().array().nonempty().max(10),
});

const ModalBody = ({ onSubmit, campaignId, isSubmitting }: ModalBodyProps) => {
  const componentColors = useComponentColors();

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setValue,
  } = useForm<{
    selectedWeek: string;
    accounts: number[];
  }>({
    mode: "onChange",
    resolver: zodResolver(validationSchemaZod),
    defaultValues: {
      selectedWeek: undefined,
      accounts: [],
    },
  });

  const { data: weeksAvailable, isLoading: isLoadingWeeksAvailable } = useQuery(
    ["getAvailableSpotlightDeckWeeks", campaignId],
    () => getAvailableSpotlightDeckWeeks({ campaignId })
  );

  useEffect(() => {
    if (!weeksAvailable || !isPopulatedArray(weeksAvailable?.weeks)) {
      return;
    }

    const sortedDates = weeksAvailable.weeks
      .map((i) =>
        DateTime.fromObject({
          weekYear: i.yearNumber,
          weekNumber: i.weekNumber,
        })
      )
      .sort((a, b) => a.toMillis() - b.toMillis())
      .map((i) => i.startOf("week"));

    const last = sortedDates[sortedDates.length - 1];

    setValue("selectedWeek", last.toISO());
  }, [weeksAvailable, setValue]);

  const selectedWeek = watch("selectedWeek");
  const accountsState = watch("accounts");

  const { data: accounts, isLoading: isLoadingAccounts } = useQuery(
    [
      "getAvailableSpotlightDeckAccounts",
      campaignId,
      JSON.stringify(weeksAvailable),
      selectedWeek,
    ],
    () =>
      getAvailableSpotlightDeckAccounts({
        campaignId,
        weekNumber: DateTime.fromISO(selectedWeek).weekNumber,
        yearNumber: DateTime.fromISO(selectedWeek).weekYear,
      }),
    { enabled: Boolean(weeksAvailable) && Boolean(selectedWeek) }
  );

  const onlyEnabledWeeks: WeekPickerProps["disabledDays"] = useMemo(() => {
    if (!weeksAvailable || !isPopulatedArray(weeksAvailable?.weeks)) {
      return [];
    }

    const sortedDates = weeksAvailable.weeks
      .map((i) =>
        DateTime.fromObject({
          weekYear: i.yearNumber,
          weekNumber: i.weekNumber,
        })
      )
      .sort((a, b) => a.toMillis() - b.toMillis())
      .map((i) => i.startOf("week"));

    const earliest = {
      lt: sortedDates[0].startOf("week").minus({ days: 1 }).endOf("week"),
    };
    const latest = {
      gt: sortedDates[sortedDates.length - 1].endOf("week"),
    };

    const createWeekSequence = (
      start: DateTime,
      end: DateTime,
      sequence: DateTime[]
    ): DateTime[] => {
      if (sequence.length === 0) {
        return createWeekSequence(start, end, [start]);
      }

      if (sequence[sequence.length - 1].valueOf() >= end.valueOf()) {
        return sequence;
      }

      const latest = sequence[sequence.length - 1];

      return createWeekSequence(start, end, [
        ...sequence,
        latest.plus({ weeks: 1 }),
      ]);
    };

    const weekSequence = createWeekSequence(
      sortedDates[0].startOf("week"),
      sortedDates[sortedDates.length - 1].startOf("week"),
      []
    );

    const gaps = weekSequence
      .map((date) => {
        const exists = sortedDates.find((i) => {
          return i.startOf("week").valueOf() === date.startOf("week").valueOf();
        });

        if (!exists) {
          return {
            lt: date.startOf("week"),
            gt: date.endOf("week"),
          };
        }

        return undefined;
      })
      .filter(isDefined);

    return [earliest, ...gaps, latest];
  }, [weeksAvailable]);

  return (
    <chakra.form
      onSubmit={(data) => {
        handleSubmit(onSubmit)(data);
      }}
      w="100%"
    >
      <Box mb={4}>
        <FormControl isInvalid={!!errors?.selectedWeek?.message} isRequired>
          <FormLabel
            fontSize="sm"
            fontWeight="md"
            color={componentColors.form.formLabelColor}
          >
            Week
          </FormLabel>

          <Controller
            control={control}
            name="selectedWeek"
            render={({ field: { onChange, value } }) => (
              <WeekPicker
                isLoading={isLoadingWeeksAvailable}
                selected={value}
                onChange={({ weekNumber, yearNumber }) =>
                  onChange(
                    DateTime.fromObject({
                      weekYear: yearNumber,
                      weekNumber,
                    }).toISO()
                  )
                }
                disabledDays={onlyEnabledWeeks}
              />
            )}
          />
          <FormErrorMessage>{errors?.selectedWeek?.message}</FormErrorMessage>
        </FormControl>
      </Box>

      <Box mb={4}>
        <FormControl isInvalid={!!errors?.accounts?.message} isRequired>
          <FormLabel
            fontSize="sm"
            fontWeight="md"
            color={componentColors.form.formLabelColor}
          >
            Accounts
          </FormLabel>

          <Controller
            control={control}
            name="accounts"
            render={({ field: { onChange } }) => (
              <Filter
                minW={"400px"}
                options={accounts ?? []}
                isLoading={
                  (isLoadingWeeksAvailable || isLoadingAccounts) &&
                  Boolean(selectedWeek)
                }
                isDisabled={isLoadingWeeksAvailable || isLoadingAccounts}
                placeholder="Select accounts"
                onFilterValuesChange={(val) => {
                  const newIds = val.map((i) => i.value).sort();
                  const currentIds = accountsState.sort();

                  if (newIds.join() === currentIds.join()) {
                    return;
                  }

                  onChange(newIds);
                }}
              />
            )}
          />
          <FormErrorMessage>{errors?.accounts?.message}</FormErrorMessage>
        </FormControl>
      </Box>

      <Flex justifyContent="flex-end" mb={4}>
        <Button isLoading={isSubmitting} variant="primary" type="submit">
          Submit
        </Button>
      </Flex>
    </chakra.form>
  );
};

export { ModalBody };
