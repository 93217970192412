import { CampaignForList } from "@intentsify/types";
import { useQuery } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import { defaultListPaginatedCampaignsParams } from "api";
import { Actions, Search, Table } from "components";
import { PaginationType } from "components/Table/Table/Table.types";
import { useCallback, useMemo, useState } from "react";
import { FaLink, FaUnlink } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { useTrackSearched } from "tracking/useTrackSearched";
import { ActionItems, FetchDataParamsWithToken } from "types";
import { listPublisherCampaigns } from "../../../Publishers/Publishers.requests";
import { useUnassignCampaignMutation } from "../../SinglePublisher.hook";
import { getAssignCampaignsTableColumns } from "./AssignCampaignsTable.columns";
import {
  listAllCampaigns,
  useAssignCampaignMutation,
} from "./AssignCampaignsTable.requests";

const RowActions = ({ id: campaignId }: { id: number }) => {
  const { id: publisherId } = useParams<"id">();

  if (!publisherId) {
    throw Error("404");
  }

  const { assignCampaignMutation } = useAssignCampaignMutation(
    publisherId,
    campaignId
  );

  const { unassignCampaignMutation } = useUnassignCampaignMutation(
    publisherId,
    campaignId
  );

  const fetchDataParams = {
    ...defaultListPaginatedCampaignsParams,
    search: String(campaignId),
  };

  const { data, isFetching } = useQuery(
    ["campaignAssignmentState", fetchDataParams],
    () => listPublisherCampaigns(publisherId, fetchDataParams)
  );

  if (!publisherId) {
    return null;
  }

  const isLoading =
    isFetching ||
    assignCampaignMutation.isLoading ||
    unassignCampaignMutation.isLoading;

  const isAssigned = Boolean(data?.campaigns.length);

  const items: ActionItems[] = [
    isAssigned
      ? {
          isLoading,
          label: "Unassign Campaign",
          accessPath: "publisher",
          icon: <FaUnlink />,
          onClick: () => unassignCampaignMutation.mutate(campaignId),
        }
      : {
          isLoading,
          label: "Assign Campaign",
          accessPath: "publisher",
          icon: <FaLink />,
          onClick: () => assignCampaignMutation.mutate(campaignId),
        },
  ];

  return <Actions items={items} />;
};

const PublisherAssignCampaignsTable = () => {
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);

  const columns = useMemo<ColumnDef<CampaignForList>[]>(
    () => getAssignCampaignsTableColumns(RowActions),
    []
  );

  const [fetchDataParams, setFetchDataParams] = useState<
    FetchDataParamsWithToken<keyof CampaignForList>
  >(defaultListPaginatedCampaignsParams);

  const { data, isFetching } = useQuery(
    ["assignCampaignsList", fetchDataParams],
    () => listAllCampaigns(fetchDataParams),
    {
      enabled: Boolean(searchValue),
    }
  );

  const handleOnFetchDataChange = useCallback(
    (params: FetchDataParamsWithToken<keyof CampaignForList>) => {
      setFetchDataParams(params);
    },
    []
  );

  const trackSearched = useTrackSearched();

  return (
    <>
      <Search
        placeholder={"Search campaigns"}
        currentValue={searchValue}
        onSearch={(v) => {
          setSearchValue(v);
          trackSearched({
            term: v,
            collocation: "Assign campaigns to publisher table",
          });
        }}
        autoFocus={true}
      />

      {searchValue && (
        <Table
          variant="intentsifyNew"
          searchValue={searchValue}
          defaultSort={[{ id: "campaignId", desc: true }]}
          columns={columns}
          isFetching={isFetching}
          data={data?.campaigns || []}
          onFetchDataChange={handleOnFetchDataChange}
          resetPaginationDependencies={[searchValue]}
          paginationType={PaginationType.TOKEN}
          nextPageToken={data?.nextPageToken}
        />
      )}
    </>
  );
};

export { PublisherAssignCampaignsTable };
