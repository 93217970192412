import { useColorModeValue } from "@chakra-ui/color-mode";
import { useTheme } from "@chakra-ui/react";
import { Option as IntentsifyOption } from "@intentsify/types";
import { GroupBase, Props } from "react-select";

export const useSimpleSelectStyles = <
  Option extends IntentsifyOption,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
  CompiledProps extends Props<Option, IsMulti, Group> = Props<
    Option,
    IsMulti,
    Group
  >
>() => {
  const chakraTheme = useTheme();
  const placeholderColor = useColorModeValue("gray.500", "#ffffff1d");
  const inputTextColor = useColorModeValue("gray.800", "whiteAlpha.900");
  const singleSelectColor = useColorModeValue("gray.200", "gray.400");
  const simpleControlBg = useColorModeValue("whiteAlpha.200", "whiteAlpha.200");

  return {
    placeholder: (base) => {
      return {
        ...base,
        color: placeholderColor,
        fontSize: chakraTheme.fontSizes.sm,
        fontWeight: "normal",
        whiteSpace: "nowrap",
      };
    },
    menu: (base) => {
      return {
        ...base,
        backgroundColor: "transparent",
        boxShadow: "none",
        zIndex: 3,
      };
    },
    input: (base) => {
      return {
        ...base,
        paddingTop: 0,
        paddingBottom: 0,
        color: inputTextColor,
      };
    },
    control: (base) => {
      return { ...base, backgroundColor: simpleControlBg };
    },
    indicatorSeparator: (base) => {
      return { ...base, opacity: 0 };
    },
    valueContainer: (base) => {
      return { ...base, padding: "0 0.7rem", minHeight: "1.9rem" };
    },
    singleValue: (base) => {
      return {
        ...base,
        color: singleSelectColor,
        fontSize: chakraTheme.fontSizes.sm,
      };
    },
  } satisfies CompiledProps["styles"];
};
