import { Text, useDisclosure } from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getUserById } from "api";
import { Button, Loader, Modal, ViewContainer } from "components";
import { useNavigate, useParams } from "react-router-dom";
import { useScreen } from "utils";
import { UserForm } from "../../components";
import { UsersScreenDefinition } from "../Users";
import { createUser } from "../Users.requets";
import { CloneUserScreenDefinition } from "./CloneUser.definition";

const CloneUser = () => {
  const { id: userId } = useParams<"id">();
  useScreen(CloneUserScreenDefinition);
  const queryClient = useQueryClient();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation(createUser, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["listUsers"] });

      onOpen();
    },
  });

  if (!userId) {
    throw new Error("404");
  }

  const { data } = useQuery(["getUserById", Number(userId)], () =>
    getUserById(userId)
  );

  if (!data?.user) {
    return (
      <ViewContainer>
        <Loader />
      </ViewContainer>
    );
  }

  const {
    businessUnits,
    companyId,
    displayName,
    email,
    saasRoleId,
    businessEntityId,
    isSalesRepresentative,
    isCampaignManager,
  } = data.user;

  return (
    <>
      <ViewContainer>
        <UserForm
          initialState={{
            businessUnits,
            companyId,
            displayName,
            email,
            saasRoleId,
            businessEntityId,
            isSalesRepresentative,
            isCampaignManager,
          }}
          isLoading={isLoading}
          onSubmit={mutate}
          submitLabel="Clone"
        />
      </ViewContainer>

      <Modal
        title="User has been cloned"
        body={
          <Text>
            We will send a confirmation email with a link for creating password
            on the address provided in the form.
          </Text>
        }
        primaryButton={
          <Button
            aria-label="Close"
            onClick={() => {
              onClose();
              navigate(UsersScreenDefinition.navigate());
            }}
          >
            Close
          </Button>
        }
        isOpen={isOpen}
        onClose={() => {
          onClose();
          navigate(UsersScreenDefinition.navigate());
        }}
      />
    </>
  );
};

export { CloneUser };
