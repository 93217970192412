import { Endpoints } from "api";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  DownloadItem,
  DownloadItemStatus,
  useDownloadFile,
  useScreen,
} from "utils";
import { DownloadScreenDefinition } from "./Download.definition";
import { DownloadStatus } from "./DownloadStatus";

const useMostRecentDownloadStatus = (filename: string | undefined) => {
  const { getDownloadingStatus } = useDownloadFile();

  const [mostRecentStatus, setMostRecentStatus] = useState<
    DownloadItem["status"] | undefined
  >();

  if (filename) {
    const status = getDownloadingStatus(filename);

    if (status && status !== mostRecentStatus) {
      setMostRecentStatus(status);
    }
  }

  return mostRecentStatus;
};

// TODO: This is workaround for duplicated route in DownloadPDFReport screen. This component must be removed
const Download = () => {
  useScreen(DownloadScreenDefinition);

  const { reportType } = useParams<"reportType">();
  const campaignId = Number(useParams<"campaignId">().campaignId);
  const { correlationId } = useParams<"correlationId">();

  const { download } = useDownloadFile();
  const downloadingStatus = useMostRecentDownloadStatus(correlationId);

  useEffect(() => {
    if (
      reportType &&
      campaignId &&
      correlationId &&
      downloadingStatus !== DownloadItemStatus.IN_PROGRESS
    ) {
      download({
        url: Endpoints.Download.Get.File({
          bucket: reportType,
          s3File: String(campaignId),
          filename: correlationId,
        }),
        fileName: correlationId,
        fileExtension: "",
      });
    }
    // We want it to trigger once only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportType, campaignId, correlationId]);

  return <DownloadStatus status={downloadingStatus} />;
};

export { Download, useMostRecentDownloadStatus };
