import { Flex, useColorModeValue, useToken } from "@chakra-ui/react";
import {
  HasAccess,
  useHasAccessTo,
} from "@intentsify/authorization/dist/react";
import {
  AnyDomainEnhancementStrategy,
  CampaignAccounts,
  DomainEnhancementStrategy,
} from "@intentsify/types";
import { BarChart } from "components";
import { LuReplace } from "react-icons/lu";
import {
  RiCheckboxCircleFill,
  RiCloseCircleFill,
  RiQuestionFill,
} from "react-icons/ri";
import { DomainValidationEntry } from "./DomainValidationEntry";

type DomainsValidationProps = {
  accountsSummary: Omit<
    Required<CampaignAccounts>["accountsSummary"],
    "totalAccountsCount"
  >;
  domainEnhancementStrategy: AnyDomainEnhancementStrategy;
  onDownload?: (
    type: "passed" | "failed" | "unknown" | "expanded" | "replaced"
  ) => void;
};

const DomainsValidation = ({
  accountsSummary,
  domainEnhancementStrategy,
  onDownload,
}: DomainsValidationProps) => {
  const passedColor = useToken(
    "colors",
    useColorModeValue("green.500", "green.100")
  );
  const badColor = useToken("colors", useColorModeValue("red.500", "red.100"));
  const unknownColor = useToken(
    "colors",
    useColorModeValue("orange.500", "orange.100")
  );
  const deColor = useToken("colors", useColorModeValue("blue.500", "blue.100"));
  const hasAccessToDomainReplacement = useHasAccessTo(
    "campaign.domainReplacement"
  );

  const {
    passedCount,
    failedCount,
    unknownCount,
    domainEnhancementReplaceCount,
  } = accountsSummary;

  const chartData = [
    {
      label: "Passed",
      value: passedCount,
      passed: passedCount,
    },
    {
      label: "Failed",
      value: failedCount,
      passed: failedCount,
    },
    {
      label: "Unknown",
      value: unknownCount,
      passed: unknownCount,
    },
    domainEnhancementStrategy === DomainEnhancementStrategy.replace &&
      hasAccessToDomainReplacement && {
        label: "Replaced",
        value: domainEnhancementReplaceCount,
        passed: domainEnhancementReplaceCount,
      },
  ].filter(Boolean);

  return (
    <Flex w="100%">
      <Flex flexDirection="column" marginTop="10px" w="100%">
        <DomainValidationEntry
          typeContent="Passed"
          count={passedCount}
          icon={RiCheckboxCircleFill}
          iconColor="green.500"
          onDownload={passedCount > 0 ? onDownload : undefined}
        />

        <DomainValidationEntry
          typeContent="Failed"
          count={failedCount}
          icon={RiCloseCircleFill}
          iconColor="red.500"
          onDownload={failedCount > 0 ? onDownload : undefined}
        />

        <DomainValidationEntry
          hideDivider={
            domainEnhancementStrategy === DomainEnhancementStrategy.unspecified
          }
          typeContent="Unknown"
          count={unknownCount}
          icon={RiQuestionFill}
          iconColor="orange.500"
          onDownload={unknownCount > 0 ? onDownload : undefined}
        />

        <HasAccess to="campaign.domainReplacement">
          {domainEnhancementStrategy === DomainEnhancementStrategy.replace && (
            <DomainValidationEntry
              hideDivider
              typeContent="Replaced"
              count={domainEnhancementReplaceCount ?? 0}
              icon={LuReplace}
              iconColor="blue.500"
              onDownload={
                (domainEnhancementReplaceCount ?? 0) > 0
                  ? onDownload
                  : undefined
              }
            />
          )}
        </HasAccess>
      </Flex>

      <BarChart
        orientation="horizontal"
        showXAxis={false}
        margins={{ l: 0, b: 0, r: 30, t: 10 }}
        // TODO: will be fixed in ts 5.5
        data={chartData as any}
        keys={
          [
            "passed",
            "bad",
            "unknown",
            hasAccessToDomainReplacement &&
              domainEnhancementReplaceCount &&
              "replaced",
          ].filter(Boolean) as string[]
        }
        colorRange={
          hasAccessToDomainReplacement && domainEnhancementReplaceCount
            ? [passedColor, badColor, unknownColor, deColor]
            : [passedColor, badColor, unknownColor]
        }
        minHeight={"0"}
      />
    </Flex>
  );
};

export { DomainsValidation };
