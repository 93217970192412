import { CircularProgress, Flex, Text } from "@chakra-ui/react";
import { toNumberDisplayValue } from "@intentsify/utils";
import { useQuery } from "@tanstack/react-query";
import { Card } from "components";
import { DisplayPerformanceFilters } from "screens/CampaignPacing/screens/DisplayPerformance";
import { getOverallViewability } from "./OverallViewability.requests";

type OverallViewabilityProps = {
  campaignId: string;
  filters: DisplayPerformanceFilters;
};

const OverallViewability = ({
  campaignId,
  filters,
}: OverallViewabilityProps) => {
  const { data, isFetching } = useQuery(
    ["getOverallViewability", campaignId, filters],
    () => getOverallViewability(campaignId, filters)
  );

  return (
    <Card
      titleTooltip="50% of the ad unit in view for a minimum of 1 second for standard ad formats such as leaderboards and MPUs."
      isLoading={isFetching}
      title="Overall Viewability"
      titleTooltipPlacement="bottom-end"
    >
      <Flex
        flexDir="column"
        alignItems="center"
        justifyContent="center"
        grow={1}
      >
        <Flex minH="55px" alignItems="center" justifyContent="center">
          {data?.viewability && (
            <>
              <CircularProgress
                color="#6EB21F"
                value={data.viewability * 100}
                size={20}
                thickness={16}
                mr={4}
              />

              <Text fontSize="4xl" fontWeight="bold" lineHeight={1}>
                {toNumberDisplayValue(data.viewability * 100)}%
              </Text>
            </>
          )}
        </Flex>
      </Flex>
    </Card>
  );
};

export { OverallViewability };
