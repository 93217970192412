import { useEffect } from "react";
import { useAppStore } from "store/useAppStore";
import { ampli } from "./amplitude";

export const useIdentifyAmplitudeUser = () => {
  const user = useAppStore.use.user();

  useEffect(() => {
    if (!user || !ampli.isLoaded) {
      return;
    }

    ampli.identify(`${user.userId}`, {
      displayName: user.displayName,
      email: user.email,
      companyId: user.companyId,
      agencyId: user.agencyId,
      businessEntityId: user.businessEntityId,
      role: user.saasRole,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, ampli.isLoaded]);
};
