/* eslint-disable react-hooks/rules-of-hooks */
import { Flex, Text } from "@chakra-ui/layout";
import { Button as ChakraButton, useDisclosure } from "@chakra-ui/react";
import { DraftAsset } from "@intentsify/types";
import { ColumnDef } from "@tanstack/react-table";
import { Button, IconButton, IndeterminateCheckbox } from "components";
import { useConfirm } from "components/ConfirmDialog/useConfirm";
import { DateTime } from "luxon";
import { useCallback, useMemo } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import { CompletedAssetForm } from "../Completed/CompletedAssetForm";

import { formatDate } from "@intentsify/utils";
import { useUser } from "store/store.hooks";
import { ExtensionIcon } from "../ExtensionIcon";
import {
  ContentLibraryModal,
  ContentLibraryModalBody,
  ContentLibraryModalHeader,
} from "../Modal";
import { useDeleteDraftAssets } from "./useDeleteDraftAssets";
import { useReviewDraftAsset } from "./useReviewDraftAsset";

export const useColumns = (removeSelection: (id: string | number) => void) => {
  const getIsUploadedByCurrentUser = useGetIsUploadedByCurrentUser();

  return useMemo<ColumnDef<DraftAsset>[]>(
    () => [
      {
        id: "select",
        header: ({ table }) => (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        ),
      },
      {
        header: "Name",
        accessor: "name",
        cell: ({ row }) => {
          const extension = row.original.name.split(".").pop();

          return (
            <ChakraButton
              as="a"
              target="_blank"
              href={row.original.downloadUrl}
              variant="link"
              fontSize="sm"
              fontWeight="normal"
              color="black"
              _dark={{ color: "white" }}
            >
              <Flex alignItems="center" columnGap="3">
                <ExtensionIcon extension={extension} />
                <Text>{row.original.name}</Text>
              </Flex>
            </ChakraButton>
          );
        },
      },
      {
        header: "Uploaded at",
        accessor: "uploadedAt",
        cell: ({ row }) => (
          <Text>
            {formatDate({
              date: DateTime.fromSQL(row.original.uploadedAt).toJSDate(),
            })}
          </Text>
        ),
      },
      {
        header: "Uploaded by",
        accessor: "uploadedBy",
        cell: ({ row }) => {
          if (getIsUploadedByCurrentUser(row.original.uploadedBy)) {
            return <Text>{row.original.uploadedByDisplayName} (You)</Text>;
          }

          return <Text>{row.original.uploadedByDisplayName}</Text>;
        },
      },
      {
        id: "actions",
        cell: ({ row }) => {
          const disclosure = useDisclosure();
          const reviewDraftAsset = useReviewDraftAsset(
            row.original.id,
            disclosure.onClose
          );
          const deleteDraftAssets = useDeleteDraftAssets(removeSelection);
          const confirm = useConfirm();

          return (
            <>
              <ContentLibraryModal {...disclosure}>
                <ContentLibraryModalHeader>
                  Review {row.original.name}
                </ContentLibraryModalHeader>

                <ContentLibraryModalBody>
                  <CompletedAssetForm
                    isLoading={reviewDraftAsset.isLoading}
                    defaultValues={{ name: row.original.name.split(".")[0] }}
                    onSubmit={(data) => {
                      reviewDraftAsset.mutate(data);
                    }}
                  />
                </ContentLibraryModalBody>
              </ContentLibraryModal>

              <Flex gap="2">
                <IconButton
                  tooltip="Delete asset"
                  colorScheme="red"
                  variant="ghost"
                  openDelay={500}
                  icon={<FaTrash />}
                  isLoading={deleteDraftAssets.isLoading}
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onClick={async () => {
                    if (
                      !(await confirm({
                        title: "Delete asset",
                        description: `Are you sure you want to delete ${row.original.name}?`,
                        confirmText: "Delete",
                      }))
                    ) {
                      return;
                    }

                    deleteDraftAssets.mutate([row.original.id]);
                  }}
                />

                <Button
                  variant="outline-teal"
                  leftIcon={<FaEdit />}
                  onClick={disclosure.onOpen}
                >
                  Review
                </Button>
              </Flex>
            </>
          );
        },
      },
    ],
    [getIsUploadedByCurrentUser, removeSelection]
  );
};

const useGetIsUploadedByCurrentUser = () => {
  const user = useUser();

  return useCallback(
    (uploadedBy: number) => {
      return uploadedBy === user?.userId;
    },
    [user?.userId]
  );
};
