import {
  Box,
  Flex,
  FlexboxProps,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";

type ScreenBarProps = {
  filters: Array<{
    label?: string;
    node: React.ReactNode;
    width: number | string;
  }>;
  justifyContent?: FlexboxProps["justifyContent"];
};

const ScreenBar = ({
  filters,
  justifyContent = "flex-start",
}: ScreenBarProps) => {
  const bg = useColorModeValue("white", "brand.375");

  return (
    <Flex p={1} bg={bg} flexDirection="column">
      <Flex justifyContent={justifyContent}>
        {filters.map((f, i) => {
          const { label, node, width } = f;
          return (
            <Flex
              key={i}
              alignItems="center"
              px={2}
              py={2}
              borderRadius={4}
              mr={1}
            >
              {label && (
                <Text
                  fontSize="sm"
                  mr={2}
                  opacity="0.8"
                  ml={1}
                  fontWeight="bold"
                >
                  {label}:
                </Text>
              )}
              <Box w={`${width}px`}>{node}</Box>
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
};

export { ScreenBar };
