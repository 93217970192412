import { ButtonGroup, Divider, Flex, Text } from "@chakra-ui/react";
import { isTalProcessing } from "@intentsify/utils";
import { Button, Tooltip } from "components";
import { PropsWithChildren, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useRecoilValue } from "recoil";
import { appErrors } from "utils";
import { getLocalRelativeUrl } from "utils/url";
import {
  useCampaign,
  useNavigateToCampaigns,
} from "../../CampaignsWizard.hooks";
import { MutateCampaignDomainsParams } from "../../CampaignsWizard.requests";
import { CreateCampaignScreenDefinition } from "../../CreateCampaign.definition";
import { SubmitTrigger } from "../SettingsStep/SettingsForm";
import { DomainsForm } from "./DomainsForm";
import { DomainsStepState } from "./DomainsStep.state";
import { Summary } from "./Summary";

type DomainsStepProps = {
  editing: boolean;
  onSubmit: (params: MutateCampaignDomainsParams) => void;
  nextItemLabel?: string;
};

const DomainsStep = ({
  onSubmit,
  editing,
  nextItemLabel,
  children,
}: PropsWithChildren<DomainsStepProps>) => {
  const [accountsCount, setAccountsCount] = useState<number | undefined>();

  const formRef = useRef<SubmitTrigger | undefined>();
  const isLoading = useRecoilValue(DomainsStepState.isLoadingFirmographicsAtom);
  const navigate = useNavigate();
  const step = Number(useParams<"step">().step);
  const campaignId = Number(useParams<"campaignId">().campaignId);
  const navigateToCampaigns = useNavigateToCampaigns();
  const { campaignSettings, campaignsDomains, campaign } = useCampaign({
    campaignId,
  });

  if (!campaign) {
    return;
  }

  const isProcessing = isTalProcessing(campaign.state);

  return (
    <>
      <DomainsForm
        ref={formRef}
        domainsSettings={campaignsDomains}
        campaignSettings={campaignSettings}
        onSubmit={onSubmit}
        onAccountsCountChange={setAccountsCount}
      />

      {children}

      {!editing && !isProcessing ? (
        <Flex mt={20} alignItems="center" justifyContent="center">
          <Text maxW="850px">
            Account summary will be available once TAL processing is complete.
            Processing begins after completing all the steps of intent model
            build. You'll receive an email notification and in-app notification
            when your TAL is fully processed.
          </Text>
        </Flex>
      ) : (
        <Summary />
      )}

      <Divider mt={20} mb={4} />

      <Flex justifyContent="flex-end">
        {editing ? (
          <ButtonGroup>
            {!isProcessing && (
              <Button
                size="md"
                isDisabled={isLoading}
                onClick={() =>
                  formRef.current?.submit({ shouldExitOnSuccess: true })
                }
              >
                Save and Exit
              </Button>
            )}

            {isProcessing && (
              <Button
                size="md"
                isDisabled={isLoading}
                onClick={() => {
                  // for security reason we need to ensure that there no external URLs present
                  const from = getLocalRelativeUrl(
                    new URLSearchParams(location.search).get("from") ?? ""
                  );
                  if (from) {
                    navigate(from);
                  } else {
                    navigateToCampaigns();
                  }
                }}
              >
                Exit
              </Button>
            )}

            <Tooltip
              aria-label={
                isProcessing ? appErrors["409TALAlreadyProcessing"] : undefined
              }
              label={
                isProcessing ? appErrors["409TALAlreadyProcessing"] : undefined
              }
            >
              <Button
                size="md"
                isDisabled={isLoading || isProcessing || accountsCount === 0}
                variant="primary-teal"
                onClick={() =>
                  formRef.current?.submit({ shouldExitOnSuccess: false })
                }
              >
                Save Changes
              </Button>
            </Tooltip>
          </ButtonGroup>
        ) : (
          <ButtonGroup>
            <Button
              size="md"
              onClick={() => {
                navigate(
                  CreateCampaignScreenDefinition.navigate({
                    campaignId,
                    step: Number(step) - 1,
                  })
                );
              }}
            >
              Previous
            </Button>

            <Button
              size="md"
              isDisabled={isLoading || accountsCount === 0}
              variant="primary-teal"
              onClick={() =>
                formRef.current?.submit({ shouldExitOnSuccess: false })
              }
            >
              Next{nextItemLabel ? `: ${nextItemLabel}` : ""}
            </Button>
          </ButtonGroup>
        )}
      </Flex>
    </>
  );
};
export { DomainsStep };
