import { Button, Modal } from "components";
import { Blocker } from "react-router";

const UnsavedChangesModal = ({ blocker }: { blocker: Blocker }) => {
  return (
    <Modal
      onClose={() => blocker.reset?.()}
      isOpen={blocker.state === "blocked"}
      title="Unsaved Changes"
      body={
        <>Are you sure you want to leave? You will lose all unsaved work.</>
      }
      primaryButton={
        <Button variant="primary" onClick={() => blocker.proceed?.()}>
          Leave
        </Button>
      }
      secondaryButton={
        <Button variant="secondary" onClick={() => blocker.reset?.()}>
          Cancel
        </Button>
      }
    />
  );
};

export { UnsavedChangesModal };
