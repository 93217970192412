import { Box, Flex, Text } from "@chakra-ui/layout";
import { Spinner } from "@chakra-ui/react";
import { SignalSelectionSource } from "@intentsify/types";
import { useQuery } from "@tanstack/react-query";
import { Endpoints, apiService } from "api";
import { Button } from "components";
import { useState } from "react";
import { useRecoilValue } from "recoil";
import { useDebounceValue } from "shared/hooks/useDebounceValue";
import { ampli } from "tracking/amplitude";
import { topicIdsSelector } from "../../KeywordsAndTopicsStep.state";
import { ErrorBoundary } from "./ErrorBoundary";
import { Suggestion } from "./Suggestion";
import { defaultTransition, viewKeyframesWithScale } from "./animations";

type TopicSuggestionsProps = {
  id: number;
};

export const TopicSuggestions = (props: TopicSuggestionsProps) => {
  const topicIds = useRecoilValue(topicIdsSelector);

  // It doesn't make sense to show suggestions if there are no topics or too many topics
  // 1000 is definitely too many topics because it's too broad of a context
  if (topicIds.length === 0 || topicIds.length > 1000) {
    return null;
  }

  return (
    <ErrorBoundary>
      <UnsafeTopicSuggestions {...props} />
    </ErrorBoundary>
  );
};

const UnsafeTopicSuggestions = (props: TopicSuggestionsProps) => {
  const [seeMore, setSeeMore] = useState(false);
  const topicIds = useRecoilValue(topicIdsSelector);
  const topicSuggestions = useTopicSuggestions(props.id, topicIds);

  return (
    <Box
      mt="2"
      maxH={topicSuggestions.isLoading ? "22px" : "600px"}
      overflow="hidden"
      transition={defaultTransition}
    >
      <Flex gap="1.5" role="group">
        <Flex justifyContent="center" gap="1.5">
          {topicSuggestions.isLoading && (
            <Spinner
              height="14px"
              width="14px"
              mt="0.32rem"
              color="blackAlpha.700"
              _dark={{
                color: "whiteAlpha.700",
              }}
            />
          )}

          <Text
            fontStyle="italic"
            fontSize="sm"
            lineHeight="1.67"
            color="blackAlpha.800"
            whiteSpace="nowrap"
            _groupHover={{ color: "black" }}
            transition={defaultTransition}
            _dark={{
              color: "whiteAlpha.800",
              _groupHover: {
                color: "white",
              },
            }}
          >
            {topicSuggestions.isLoading
              ? "Loading suggestions"
              : "Topic suggestions:"}
          </Text>
        </Flex>

        <Box position="relative" mt="-0.06rem" w="full" h="full">
          <Box
            maxW={
              seeMore
                ? "full"
                : {
                    base: "30vmax",
                    sm: "30vmax",
                    md: "50vmax",
                    lg: "55vmax",
                    xl: "60vmax",
                  }
            }
            maxHeight={seeMore ? "20rem" : "1.5rem"}
            whiteSpace={seeMore ? "normal" : "nowrap"}
            overflow="hidden"
            transition={defaultTransition}
          >
            {topicSuggestions.data?.map(
              (suggestion, suggestionIndex, suggestions) => (
                <Box
                  display="inline-block"
                  key={suggestion.id}
                  animation={`${viewKeyframesWithScale} 0.84s ${
                    0.04 * suggestionIndex
                  }s  both cubic-bezier(0.22, 1, 0.36, 1)`}
                >
                  <Suggestion
                    campaignId={props.id}
                    topic={{
                      topicId: suggestion.id,
                      topic: suggestion.topic,
                      source: SignalSelectionSource.Suggestion,
                    }}
                  >
                    {suggestion.topic}
                  </Suggestion>

                  {suggestions.length - 1 > suggestionIndex && (
                    <Text
                      as="span"
                      color="whiteAlpha.800"
                      animation={`${viewKeyframesWithScale} 0.84s ${
                        0.04 * suggestionIndex
                      }s both cubic-bezier(0.22, 1, 0.36, 1)`}
                    >
                      ,
                    </Text>
                  )}
                </Box>
              )
            )}
          </Box>

          {!topicSuggestions.isLoading && (
            <Box
              position="absolute"
              top="-10%"
              bottom="0"
              right="0"
              left="0"
              opacity={seeMore ? 0 : 1}
              pointerEvents="none"
              transition={defaultTransition}
            >
              <Box
                position="absolute"
                top={0}
                bottom={0}
                right={0}
                w="25%"
                pointerEvents="none"
                bgGradient="linear(to-r, transparent, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 1))"
                _dark={{
                  bgGradient:
                    "linear(to-r, transparent, rgba(29, 46, 64, 1), rgba(29, 46, 64, 1), rgba(29, 46, 64, 1))",
                }}
              />
              <Box
                position="absolute"
                right="3vmax"
                pointerEvents={seeMore ? "none" : "auto"}
              >
                <Button
                  variant="ghost"
                  onClick={() => {
                    setSeeMore(true);
                    ampli.seeMoreTopicSuggestions({ campaignId: props.id });
                  }}
                >
                  See more
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Flex>
    </Box>
  );
};

const useTopicSuggestions = (id: number, topicIds: number[]) => {
  const debouncedTopicIds = useDebounceValue(topicIds, 1000);

  return useQuery({
    queryKey: ["topicSuggestions", id, debouncedTopicIds],
    queryFn: async () => {
      const { data } = await apiService.post<{ id: number; topic: string }[]>(
        Endpoints.Suggestions.Topics.Post.List,
        topicIds
      );

      return data;
    },
    retry: 3,
    retryDelay: 3000,
  });
};
