import { useMemo } from "react";
import { useLocation } from "react-router";

/**
 * We use this hook to determine if the user is editing or creating a campaign.
 *
 * This is used to change queryClient behaviour to make sure we don't refetch
 * data when the user is editing or creating a campaign.
 *
 * We don't want to lose the user's progress and we don't have a way to save
 * the user's progress yet.
 */
export const useIsUserEditingOrCreatingCampaign = () => {
  const location = useLocation();

  return useMemo(() => {
    // https://regex101.com/r/nyA4Af/2
    if (location.pathname.match(/\/campaigns\/(.*)\/(edit|wizard)\/(.*)/gi)) {
      return true;
    }

    // https://regex101.com/r/RAtf7Y/1
    if (location.pathname.match(/\/campaigns\/create\/(.*)/gi)) {
      return true;
    }

    return false;
  }, [location.pathname]);
};
