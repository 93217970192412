import {
  UseMutationOptions,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { Endpoints, apiService } from "api";
import { AsyncReturnType } from "type-fest";

const removeCompany = (id: number | string) =>
  apiService.delete(Endpoints.Companies.Delete.ById(id));

type UseRemoveCompanyMutationOptions = UseMutationOptions<
  AsyncReturnType<typeof removeCompany>,
  unknown,
  number | string
>;

export const useRemoveCompany = (options?: UseRemoveCompanyMutationOptions) => {
  const queryClient = useQueryClient();

  return useMutation(removeCompany, {
    ...options,
    onSuccess: (...args) => {
      queryClient.invalidateQueries({ queryKey: ["companiesList"] });
      options?.onSuccess?.(...args);
    },
  });
};
