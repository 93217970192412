import { Flex } from "@chakra-ui/layout";
import { Text as ChakraText, useColorMode } from "@chakra-ui/react";
import {
  isDefined,
  isPopulatedArray,
  toNumberDisplayValue,
} from "@intentsify/utils";
import { AxisBottom, AxisLeft } from "@visx/axis";
import { localPoint } from "@visx/event";
import { Group } from "@visx/group";
import { ParentSize } from "@visx/responsive";
import { scaleBand, scaleLinear } from "@visx/scale";
import { Bar } from "@visx/shape";
import { Text } from "@visx/text";
import { useTooltip, useTooltipInPortal } from "@visx/tooltip";
import { ScaleBand, ScaleLinear } from "d3-scale";
import omit from "lodash/omit";
import { useMemo, useRef } from "react";
import { animated, useSpring } from "react-spring";
import { useChartColors } from "utils";
import { Loader } from "../../Loader";
import { NoData } from "../../NoData";
import { BasicTooltip } from "../BasicTooltip";
import { EXPORT_TITLE_HEIGHT, X_AXIS_LABEL_LINE_HEIGHT } from "../Charts.const";
import { BarChartProps } from "./BarChart";
import { BarChartItem } from "./BarChart.types";
import { getBarChartColor } from "./BarChart.utils";
import {
  estimateLinesNeededForLabelXAxis,
  splitLongLabels,
} from "./estimateSpaceForLabel";

const Vertical = ({
  isLoading,
  data: rawData,
  margins = {
    t: 20,
    b: 20,
    r: 10,
    l: 80,
  },
  stretch = false,
  showXAxis = true,
  noDataMessage,
  valueAxisLabel,
  labelAxisLabel,
  valueNumTicks,
  id,
  exportMode,
  title,
  minHeight,
  maxXAxisHeight,
  tooltipRenderer,
}: Omit<BarChartProps, "orientation"> & { maxXAxisHeight?: number }) => {
  const itemsScale = useRef<ScaleBand<string> | undefined>();
  const valueScale = useRef<ScaleLinear<number, number, never> | undefined>();
  const xAxisBandwith = itemsScale.current ? itemsScale.current.bandwidth() : 0;

  const { labelColor, axisColors } = useChartColors();

  const data = useMemo((): (BarChartItem & { formattedLabel?: string })[] => {
    return itemsScale.current
      ? splitLongLabels(rawData, {
          fontSize: 12,
          widthAvailable: xAxisBandwith,
          lineHeight: X_AXIS_LABEL_LINE_HEIGHT,
          maxMultiLineHeight: maxXAxisHeight ? maxXAxisHeight : undefined,
        })
      : rawData;
  }, [rawData, xAxisBandwith, maxXAxisHeight]);

  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip<BarChartItem>();

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    detectBounds: true,
    scroll: true,
  });

  const { colorMode } = useColorMode();
  const barFill = getBarChartColor(colorMode);

  const [{ scale }] = useSpring(
    {
      from: { scale: 0 },
      to: { scale: 1 },
      reset: true,
    },
    [isLoading]
  );

  if (isLoading) {
    return <Loader minHeight={minHeight} stretch={stretch} />;
  }

  if (!isPopulatedArray(data)) {
    return (
      <NoData message={noDataMessage} height={minHeight} stretch={stretch} />
    );
  }

  const handleMouseOver = (event: any, item: BarChartItem) => {
    if (event.target) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const coords = localPoint(event.target.ownerSVGElement, event);
      if (coords) {
        showTooltip({
          tooltipLeft: coords.x,
          tooltipTop: coords.y,
          tooltipData: item,
        });
      }
    }
  };

  const AnimatedBar = animated(Bar);

  return (
    <Flex
      overflowY="auto"
      width="100%"
      height="100%"
      borderRadius="10px"
      minHeight={minHeight}
    >
      <Flex width="100%" minHeight={minHeight} flexGrow={stretch ? 1 : 0}>
        <ParentSize>
          {(parent) => {
            const { width, height } = parent;

            if (width <= 0) {
              return null;
            }

            const xMax = width - margins.l - margins.r;

            itemsScale.current = scaleBand<string>({
              range: [0, xMax],
              round: true,
              domain: data.map((i) => i.label),
              paddingInner: 0.2,
              paddingOuter: 0.2,
            });

            const spaceForXAxisLabels =
              margins.b +
              estimateLinesNeededForLabelXAxis(
                data.flatMap((i) => i.formattedLabel ?? i.label),
                {
                  fontSize: 12,
                  widthAvailable: xAxisBandwith,
                }
              ).lines *
                X_AXIS_LABEL_LINE_HEIGHT;

            const baseYMax = height - margins.t - margins.b;
            const yMax = !exportMode
              ? baseYMax - spaceForXAxisLabels
              : baseYMax - EXPORT_TITLE_HEIGHT - spaceForXAxisLabels;

            const isAllZeros = data.every((item) => item.value === 0);

            valueScale.current = scaleLinear<number>({
              range: [0, yMax],
              round: true,
              // https://github.com/airbnb/visx/issues/473
              domain: isAllZeros
                ? [1, 0]
                : [Math.max(...data.map((i) => i.value)), 0],
            });

            return (
              <svg
                width={width}
                height={height}
                ref={containerRef}
                id={id}
                style={{
                  fontFamily: "var(--chakra-fonts-body)",
                }}
              >
                <rect
                  x={0}
                  y={0}
                  width={width}
                  height={height}
                  fill={"transparent"}
                />

                {exportMode && title && (
                  <Group top={30} left={22}>
                    <Text
                      verticalAnchor="middle"
                      fontSize={22}
                      fontWeight="semibold"
                      fill={labelColor}
                    >
                      {title}
                    </Text>
                  </Group>
                )}

                <g
                  transform={`translate(${margins.l},${
                    !exportMode ? margins.t : margins.t + EXPORT_TITLE_HEIGHT
                  })`}
                >
                  <Group>
                    {data.map((d, index) => {
                      if (!valueScale.current || !itemsScale.current) {
                        return null;
                      }

                      const barWidth = itemsScale.current.bandwidth();

                      const barHeight = yMax - valueScale.current(d.value ?? 0);
                      const barX = itemsScale.current(d.label);

                      return (
                        <AnimatedBar
                          key={`bar-${d.value}-${index}`}
                          x={barX}
                          y={scale.to((s) => yMax - s * barHeight)}
                          width={barWidth}
                          height={scale.to((s) => s * barHeight)}
                          fill={barFill}
                          onMouseMove={(e) => {
                            handleMouseOver(e, data[index]);
                          }}
                          onMouseLeave={() => {
                            hideTooltip();
                          }}
                        />
                      );
                    })}
                  </Group>

                  {valueAxisLabel && (
                    <Text
                      x={-yMax / 2}
                      y={-margins.l + 12}
                      textAnchor="middle"
                      verticalAnchor="end"
                      transform="rotate(-90)"
                      fontSize={14}
                      fill={labelColor}
                    >
                      {valueAxisLabel}
                    </Text>
                  )}

                  <AxisLeft
                    numTicks={valueNumTicks}
                    tickComponent={(props) => {
                      return (
                        <Text {...omit(props, "formattedValue")} x={props.x}>
                          {props.formattedValue}
                        </Text>
                      );
                    }}
                    stroke={axisColors}
                    scale={valueScale.current}
                    hideTicks
                    tickFormat={(v) => String(v)}
                    tickLabelProps={() => ({
                      fill: labelColor,
                      fontSize: 12,
                      textAnchor: "end",
                      verticalAnchor: "middle",
                    })}
                  />

                  {showXAxis && (
                    <>
                      <AxisBottom
                        stroke={axisColors}
                        tickStroke={axisColors}
                        top={yMax}
                        scale={itemsScale.current}
                        tickComponent={(props) => {
                          return (
                            <Text
                              {...omit(props, "formattedValue")}
                              y={props.y - 5}
                              width={xAxisBandwith}
                            >
                              {data.find(
                                (row) => row.label === props.formattedValue
                              )?.formattedLabel ?? props.formattedValue}
                            </Text>
                          );
                        }}
                        tickLabelProps={() => ({
                          fill: labelColor,
                          fontSize: 12,
                          textAnchor: "middle",
                          verticalAnchor: "start",
                          lineHeight: X_AXIS_LABEL_LINE_HEIGHT,
                        })}
                      />

                      {labelAxisLabel && (
                        <Text
                          x={xMax / 2}
                          y={baseYMax}
                          textAnchor="middle"
                          verticalAnchor="start"
                          fontSize={14}
                          fill={labelColor}
                        >
                          {labelAxisLabel}
                        </Text>
                      )}
                    </>
                  )}
                </g>

                {tooltipOpen &&
                  tooltipData &&
                  isDefined(tooltipLeft) &&
                  isDefined(tooltipTop) && (
                    <BasicTooltip
                      tooltipRenderer={({ textColor, tooltipData }) => {
                        if (tooltipRenderer) {
                          return <>{tooltipRenderer(textColor, tooltipData)}</>;
                        }
                        return (
                          <ChakraText fontSize="medium" color={textColor}>
                            <strong>{tooltipData.label}:</strong>{" "}
                            {toNumberDisplayValue(tooltipData.value)}
                          </ChakraText>
                        );
                      }}
                      TooltipInPortal={TooltipInPortal}
                      tooltipLeft={tooltipLeft}
                      tooltipTop={tooltipTop}
                      tooltipData={tooltipData}
                    />
                  )}
              </svg>
            );
          }}
        </ParentSize>
      </Flex>
    </Flex>
  );
};

export { Vertical };
