import { BoxProps, ButtonProps } from "@chakra-ui/react";

export const sharedLinkProps: BoxProps & ButtonProps = {
  cursor: "pointer",
  transition: `
    background-color 95ms cubic-bezier(0.33, 1, 0.68, 1),
    color 95ms cubic-bezier(0.33, 1, 0.68, 1)
  `,
  _hover: { background: "brand.400" },
  _active: { background: "brand.300" },
};
