import { Box } from "@chakra-ui/react";
import { TempAccountFileDTO } from "@intentsify/dto";
import { BulkEditCampaignsSubject } from "@intentsify/types";
import { TabItem, TableVirtualised } from "components";
import { useMemo } from "react";
import { DomainsUploadTus } from "shared/components/DomainsUploadTus/DomainsUploadTus";
import { UploadedFileEntry } from "shared/components/UploadedFiles/UploadedFileEntry";
import { UploadedFiles } from "shared/components/UploadedFiles/UploadedFiles";
import { UploadedFilesWrapper } from "shared/components/UploadedFiles/UploadedFilesWrapper";
import { KeywordItem } from "types/Keyword";
import { bulkEditUpload } from "upload/bulkEditUpload";
import { getContentsFromTab } from "./BulkEdit.utils";
import { KeywordsUpload } from "./KeywordsUpload";
import { TopicsUpload } from "./TopicsUpload";

export const useTabItems = ({
  accountsFile,
  uploadedKeywords,
  uploadedTopics,
  setUploadedKeywords,
  setUploadedTopics,
  setAccountsFile,
}: {
  accountsFile: TempAccountFileDTO | undefined;
  uploadedKeywords: KeywordItem[];
  uploadedTopics: string[];
  setUploadedKeywords: React.Dispatch<React.SetStateAction<KeywordItem[]>>;
  setUploadedTopics: (topics: string[]) => void;
  setAccountsFile: React.Dispatch<
    React.SetStateAction<TempAccountFileDTO | undefined>
  >;
}) => {
  const topicsForTable = useMemo(() => {
    return uploadedTopics?.map((i) => ({ topic: i })) ?? [];
  }, [uploadedTopics]);

  const items: TabItem[] = [
    {
      title: getContentsFromTab(BulkEditCampaignsSubject.TAL).label,
      body: (
        <>
          <DomainsUploadTus
            tusUpload={bulkEditUpload}
            onFileUpload={(tempFileName, fileName, validationResults) => {
              setAccountsFile({
                tempFileName,
                fileName,
                accountsCount: validationResults.validRowCount,
              });
            }}
          />

          {accountsFile && (
            <UploadedFilesWrapper variant="plain">
              <UploadedFiles hideUploadedAt={false}>
                <UploadedFileEntry
                  hideDownload
                  hideUploadedAt={false}
                  key={accountsFile.fileName}
                  file={accountsFile}
                  onDeleteIconClick={() => {
                    setAccountsFile(undefined);
                  }}
                />
              </UploadedFiles>
            </UploadedFilesWrapper>
          )}
        </>
      ),
    },
    {
      title: getContentsFromTab(BulkEditCampaignsSubject.Keywords).label,
      body: (
        <>
          <KeywordsUpload onChange={setUploadedKeywords} />
          {uploadedKeywords && (
            <Box mt={4} mb={4}>
              <TableVirtualised<KeywordItem>
                data={uploadedKeywords}
                columns={[
                  {
                    accessorKey: "label",
                    header: (arg) => {
                      const { flatRows } = arg.table.getRowModel();
                      return `Keywords (${flatRows.length})`;
                    },
                    enableSorting: false,
                    cell: (arg) => arg.getValue(),
                  },
                ]}
                containerProps={{ width: "100%", height: "200px" }}
              />
            </Box>
          )}
        </>
      ),
    },
    {
      title: getContentsFromTab(BulkEditCampaignsSubject.Topics).label,
      body: (
        <>
          <TopicsUpload onChange={setUploadedTopics} />
          {uploadedTopics && (
            <Box mt={4} mb={4}>
              <TableVirtualised
                data={topicsForTable}
                columns={[
                  {
                    accessorKey: "topic",
                    header: (arg) => {
                      const { flatRows } = arg.table.getRowModel();
                      return `Topics (${flatRows.length})`;
                    },
                    enableSorting: false,
                    cell: (arg) => arg.getValue(),
                  },
                ]}
                containerProps={{ width: "100%", height: "200px" }}
              />
            </Box>
          )}
        </>
      ),
    },
  ];

  return items;
};
