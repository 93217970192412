import { Flex } from "@chakra-ui/layout";
import { List, ListItem } from "@chakra-ui/react";
import { SignalType, signalLabelsSingular } from "@intentsify/types";
import { formatDateRange, isDefined } from "@intentsify/utils";
import { ColumnDef, Row } from "@tanstack/react-table";
import { CellWithChange, CollapsingCell } from "components";
import { TimeseriesRange } from "types";
import {
  SingleAccountDetailsItem,
  SingleAccountDetailsTimeseriesResponse,
} from "../../SingleAccountDetails.types";

const getSeriesColumns = (
  seriesKey: string,
  previousKey: string | undefined,
  timeseriesRange: TimeseriesRange,
  signalType: SignalType
) => {
  const enableLocations = ![
    SignalType.SiteVisitor,
    SignalType.Display,
  ].includes(signalType);

  const showCount = SignalType.Topics != signalType;

  const formattedSeriesHeader = formatDateRange(timeseriesRange);

  const column: ColumnDef<SingleAccountDetailsItem> = {
    header: formattedSeriesHeader,
    columns: [
      showCount
        ? {
            header: "Count",
            enableSorting: false,
            cell: ({ row }: { row: Row<SingleAccountDetailsItem> }) => {
              const data = row.original?.timeseries.find(
                (s) => s.isoDate === seriesKey
              );

              const previousSeries = previousKey
                ? row.original?.timeseries.find(
                    (s) => s.isoDate === previousKey
                  )
                : undefined;

              return (
                <CellWithChange
                  value={data?.data.value}
                  previousValue={previousSeries?.data.value}
                />
              );
            },
          }
        : undefined,

      enableLocations
        ? {
            header: "Locations",
            enableSorting: false,
            cell: ({ row }: { row: Row<SingleAccountDetailsItem> }) => {
              const data = row.original?.timeseries.find(
                (s) => s.isoDate === seriesKey
              );

              if (!data?.data.location) {
                return "-";
              }

              const locations = data.data.location.sort(
                (a, b) => b.value - a.value
              );

              return locations.length > 3 ? (
                <CollapsingCell>
                  <List>
                    {locations.map((location, index) => (
                      <ListItem whiteSpace="nowrap" key={index}>
                        {location.label}{" "}
                        {showCount ? `(${location.value})` : ""}
                      </ListItem>
                    ))}
                  </List>
                </CollapsingCell>
              ) : (
                <List>
                  {locations.map((location, index) => (
                    <ListItem whiteSpace="nowrap" key={index}>
                      {location.label} {showCount ? `(${location.value})` : ""}
                    </ListItem>
                  ))}
                </List>
              );
            },
          }
        : undefined,
    ].filter(isDefined),
  };

  return column;
};

export const getSingleAccountDetailsColumns = (
  timeseries: SingleAccountDetailsTimeseriesResponse["timeseries"],
  signalType: SignalType
) => {
  const seriesColumns = timeseries.map((series, i) =>
    getSeriesColumns(series.start, timeseries[i - 1]?.start, series, signalType)
  );

  const columns: Array<ColumnDef<SingleAccountDetailsItem>> = [
    {
      header: signalLabelsSingular[signalType],
      enableSorting: false,
      cell: ({ row }) => {
        return (
          <Flex minW="200px" alignItems="center">
            {row.original?.label}
          </Flex>
        );
      },
    },
    ...seriesColumns,
  ];

  return columns;
};
