import { useEffect, useMemo, useState } from "react";
import { BaseMetaData, Item } from "types";
import { useDeepEffect } from "utils";

type UseWindowedCheckbox<
  T extends Item<M>,
  M extends BaseMetaData = BaseMetaData
> = {
  checked: T[];
  disabled?: T[];
  onChange?: (items: T[]) => void;
};

const useWindowedCheckbox = <
  T extends Item<M>,
  M extends BaseMetaData = BaseMetaData
>({
  checked,
  disabled = [],
  onChange,
}: UseWindowedCheckbox<T, M>) => {
  const [checkedItems, setCheckedItems] = useState<T[]>([]);
  const [disabledItems, setDisabledItems] = useState<T[]>([]);

  useEffect(() => {
    if (onChange) {
      onChange(checkedItems);
    }
  }, [onChange, checkedItems]);

  const checkedValues = useMemo(() => {
    return checkedItems.map((i) => i.value);
  }, [checkedItems]);

  const disabledValues = useMemo(() => {
    return disabledItems.map((i) => i.value);
  }, [disabledItems]);

  useDeepEffect(() => {
    const withoutDisabled = checkedItems.filter(
      (i) => !disabledValues.includes(i.value)
    );

    setCheckedItems(withoutDisabled);
  }, [disabledValues, checkedItems]);

  useDeepEffect(() => {
    setCheckedItems(checked);
  }, [checked]);

  useDeepEffect(() => {
    setDisabledItems(disabled);
  }, [disabled]);

  const onCheck = (item: T) => {
    if (checkedValues.includes(item.value)) {
      setCheckedItems((prev) => prev.filter((p) => p.value !== item.value));
    } else {
      setCheckedItems((prev) => [...prev, item]);
    }
  };

  const onCheckBulk = (items: Array<T>) => {
    const notCheckedNotDisabled = items.filter((i) => {
      return (
        !checkedValues.includes(i.value) && !disabledValues.includes(i.value)
      );
    });

    setCheckedItems((prev) => [...prev, ...notCheckedNotDisabled]);
  };

  const onReset = () => {
    setCheckedItems([]);
  };

  return { checkedItems, disabledItems, onCheck, onCheckBulk, onReset };
};

export { useWindowedCheckbox };
