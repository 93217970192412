import { Center, Text } from "@chakra-ui/layout";
import { UploadContent } from "../UploadContent";

export const NoData = () => (
  <Center
    flexDir="column"
    gap="6"
    mt="24"
    textAlign="center"
    maxW="sm"
    mx="auto"
  >
    <Center flexDir="column" gap="1.5">
      <Text fontSize="2xl">No draft assets found.</Text>

      <Text
        color="blackAlpha.700"
        _dark={{
          color: "whiteAlpha.700",
        }}
      >
        Drag and drop files or click the button below to upload them to the
        content library.
      </Text>
    </Center>

    <UploadContent />
  </Center>
);
