import { Box, Flex } from "@chakra-ui/layout";
import { LegendItem, LegendLabel, LegendOrdinal } from "@visx/legend";
import { scaleOrdinal } from "@visx/scale";
import { getLegendDirection } from "../Charts.utils";
import { StackedGroupedBarChartProps } from "./StackedGroupedBarChart";
import { Text } from "@chakra-ui/react";

type StackedGroupedBarChartLegendProps = Pick<
  StackedGroupedBarChartProps,
  "legendPosition" | "legendConfig" | "legendTitle"
>;

const Legend = ({
  legendPosition,
  legendConfig,
  legendTitle,
}: StackedGroupedBarChartLegendProps) => {
  const legendGlyphSize = 14;

  const colorScale = scaleOrdinal<string, string>(legendConfig);

  return (
    <LegendOrdinal
      scale={colorScale}
      labelFormat={(label) => `${String(label)}`}
    >
      {(labels) => (
        <Flex justifyContent="center" wrap="wrap">
          {legendTitle && <Text>{legendTitle}</Text>}
          <div
            style={{
              display: "flex",
              flexDirection: getLegendDirection(legendPosition),
              width: "100%",
              flexWrap: "wrap",
              justifyContent:
                legendPosition === "bottom" ? "center" : undefined,
            }}
          >
            {labels.map((label, i) => {
              return (
                <Flex alignItems="center" key={`legend-quantile-${i}`}>
                  <LegendItem>
                    <Flex
                      width="100%"
                      alignItems="center"
                      borderRadius={4}
                      px={3}
                    >
                      <Box mr={2}>
                        <svg width={legendGlyphSize} height={legendGlyphSize}>
                          <rect
                            fill={label.value}
                            width={legendGlyphSize}
                            height={legendGlyphSize}
                          />
                        </svg>
                      </Box>
                      <LegendLabel align="left">
                        <Box whiteSpace="nowrap" fontSize="small">
                          {label.datum}
                        </Box>
                      </LegendLabel>
                    </Flex>
                  </LegendItem>

                  <Flex grow={1}></Flex>
                </Flex>
              );
            })}
          </div>
        </Flex>
      )}
    </LegendOrdinal>
  );
};

export { Legend };
