import { Blocker } from "react-router";

import { Button, Modal } from "components";

const ExitCreationModal = ({ blocker }: { blocker: Blocker }) => {
  return (
    <Modal
      onClose={() => blocker.reset?.()}
      isOpen={blocker.state === "blocked"}
      title="Warning"
      body={
        <>
          You are about to exit the intent model build prior to completing the
          all steps and clicking “Finish”. The campaign will remain in a draft
          state until completing all steps of the intent model build.
        </>
      }
      primaryButton={
        <Button variant="primary" onClick={() => blocker.proceed?.()}>
          Proceed
        </Button>
      }
      secondaryButton={
        <Button variant="secondary" onClick={() => blocker.reset?.()}>
          Cancel
        </Button>
      }
    />
  );
};

export { ExitCreationModal };
