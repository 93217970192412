import { Box, Button, Grid, Icon, useTheme } from "@chakra-ui/react";
import { transparentize } from "polished";
import { resolvePath, useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { MotionBox, MotionText } from "../../../FramerMotionChakra";
import { LinkGroup as ILinkGroup } from "./SideBar.linkGroups";
import { defaultStringTransition } from "components/FramerMotionChakra/transitions";

interface LinkGroupCollapsedProps {
  linkGroup: ILinkGroup;
}

const LinkGroup = ({ linkGroup }: LinkGroupCollapsedProps) => {
  const theme = useTheme();
  const location = useLocation();

  return (
    <MotionBox
      layoutId={`${linkGroup.name}container`}
      key={linkGroup.name}
      transition={defaultStringTransition}
      display="flex"
      flexDirection="column"
    >
      <MotionText
        layout="position"
        layoutId={`${linkGroup.name}name`}
        transition={defaultStringTransition}
        transformTemplate={({ x, y, rotate, scale }: Record<string, number>) =>
          `rotate(${rotate}deg) translate(${x}px,${y}px) scale(${scale})`
        }
        fontSize="0.85rem"
        textTransform="uppercase"
        color="gray.500"
        fontWeight="bold"
        transform="rotate(-0deg)"
        ml="4"
        mb="1"
        sx={{
          writingMode: "horizontal-lr",
        }}
      >
        {linkGroup.name.toLocaleUpperCase()}
      </MotionText>

      <Grid rowGap="0">
        {linkGroup.items.map((linkItem) => {
          const path = resolvePath(linkItem.path);
          // https://github.com/remix-run/react-router/blob/fd1a20b15d03b3726a882b6d76235b356715b59c/packages/react-router-dom/index.tsx#L324
          const isActive =
            path.pathname === location.pathname ||
            (location.pathname.startsWith(path.pathname) &&
              location.pathname.charAt(path.pathname.length) === "/");

          return (
            <Box
              key={`${linkGroup.name}${linkItem.name}${linkItem.path}`}
              position="relative"
              w="100%"
            >
              {isActive && (
                <MotionBox
                  layoutId="underline"
                  position="absolute"
                  top="0"
                  bottom="0"
                  left="0"
                  right="0"
                  p="1"
                  w="100%"
                  h="100%"
                  background={transparentize(isActive ? 0.87 : 1)(
                    theme.colors.teal[500] as string
                  )}
                  _hover={{ background: "brand.400" }}
                  _active={{ background: "brand.300" }}
                  sx={{
                    "::before": {
                      content: '""',
                      position: "absolute",
                      top: 0,
                      left: 0,
                      height: "100%",
                      width: "2%",
                      background: "teal.500",
                    },
                  }}
                />
              )}

              <Button
                as={NavLink}
                to={linkItem.path}
                leftIcon={
                  <MotionBox
                    layout
                    layoutId={`${linkGroup.name}${linkItem.name}${linkItem.path}`}
                    transition={defaultStringTransition}
                    mt="1"
                  >
                    <Icon
                      as={linkItem.icon}
                      w={5}
                      h={5}
                      color={isActive ? "white" : "gray.500"}
                    />
                  </MotionBox>
                }
                size="md"
                fontSize="0.8rem"
                justifyContent="flex-start"
                py="1"
                w="100%"
                borderRadius="0"
                whiteSpace="break-spaces"
                fontWeight="normal"
                background="none"
                _hover={{
                  background: transparentize(isActive ? 1 : 0.2)(
                    theme.colors.brand[400] as string
                  ),
                }}
                _active={{
                  background: transparentize(isActive ? 1 : 0.2)(
                    theme.colors.brand[300] as string
                  ),
                }}
                // color={isActive ? "teal.500" : "gray.500"}
                color="white"
              >
                {linkItem.name}
              </Button>
            </Box>
          );
        })}
      </Grid>
    </MotionBox>
  );
};

export { LinkGroup };
