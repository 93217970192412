import { useHasAccessTo } from "@intentsify/authorization/dist/react";
import { BuyingGroupInsight } from "@intentsify/types";
import { useQuery } from "@tanstack/react-query";
import { Endpoints, apiService } from "api";

export function useBuyingGroupInsight(campaignId: number) {
  const hasAccess = useHasAccessTo("buyingGroup.insights");

  return useQuery({
    enabled: hasAccess,
    queryKey: [campaignId, "buyingGroupInsight"],
    queryFn: async () => {
      const { data } = await apiService.get<BuyingGroupInsight | null>(
        Endpoints.Campaigns.Get.BuyingGroupInsights(campaignId)
      );

      return data;
    },
  });
}
