import { sanitizeFileName } from "@intentsify/utils";

export const getUploadStatus = ({
  progress,
  isUploading,
  isLoadingValidationResults,
  hasValidationResults,
  shouldFetchValidation,
}: {
  progress: {
    progress: number;
    secondsRemaining: number;
    isFinalizing: boolean;
  };
  isUploading: boolean;
  isLoadingValidationResults: boolean;
  hasValidationResults: boolean;
  shouldFetchValidation: boolean;
}) => {
  switch (true) {
    case isLoadingValidationResults:
      return {
        status: "Fetching validation results...",
      };
    case !progress.isFinalizing && isUploading:
      return {
        status: "Uploading file...",
        statusSubtitle:
          isNaN(progress.secondsRemaining) || progress.secondsRemaining <= 0
            ? undefined
            : `About ${Math.round(
                progress.secondsRemaining
              )} seconds remaining.`,
      };

    case progress.isFinalizing:
      return {
        status: "Saving file...",
      };
    case shouldFetchValidation && !hasValidationResults:
      return {
        status: "Validating file...",
      };
  }
};

export const getUniqueFileName = (
  fileName: string,
  usedFileNames: string[]
) => {
  const sanitizedFileName = `${sanitizeFileName(fileName.slice(0, -4))}.csv`;

  if (!usedFileNames.includes(sanitizedFileName)) {
    return sanitizedFileName;
  }

  const getName = (number: number): string => {
    const fileNameWithoutExtension = fileName.slice(0, -4);
    const newFileName = `${sanitizeFileName(
      fileNameWithoutExtension
    )}(${number}).csv`;

    if (!usedFileNames.includes(newFileName)) {
      return newFileName;
    }

    return getName(number + 1);
  };

  return getName(1);
};
