import { useColorModeValue } from "@chakra-ui/color-mode";
import { Icon as CharkaIcon } from "@chakra-ui/icon";
import { Flex, Text } from "@chakra-ui/layout";
import { PropsWithChildren } from "react";
import { AiOutlineFile } from "react-icons/ai";

const Container = ({ children }: PropsWithChildren) => (
  <Flex
    py="6"
    gap="2"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    color="gray.400"
  >
    {children}
  </Flex>
);

interface NoFilesUploadedNonIdealStateProps {
  onCtaClick: () => void;
}

export const NoFilesUploadedNonIdealState = (
  props: NoFilesUploadedNonIdealStateProps
) => {
  const ctaColor = useColorModeValue("gray.500", "gray.300");
  const ctaColorInteraction = useColorModeValue("gray.700", "gray.100");
  const ctaColorTextDecoration = useColorModeValue("gray.200", "gray.600");

  return (
    <Container>
      <Flex gap="1.5" fontWeight="semibold" fontSize="md">
        <CharkaIcon as={AiOutlineFile} />
        <Text>No files uploaded</Text>
      </Flex>

      <Text fontSize="sm">
        Maybe try to{" "}
        <Text
          as="span"
          cursor="pointer"
          textDecoration="underline"
          textDecorationColor={ctaColorTextDecoration}
          color={ctaColor}
          onClick={props.onCtaClick}
          _hover={{ color: ctaColorInteraction }}
          _focus={{ color: ctaColorInteraction }}
          _active={{ color: ctaColor }}
        >
          upload new files
        </Text>
        ?
      </Text>
    </Container>
  );
};
