import {
  BackgroundProps,
  Box,
  Modal as ChakraModal,
  ModalProps as ChakraModalProps,
  Flex,
  Heading,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { CSSProperties } from "react";
import { ErrorBoundary } from "../ErrorBoundary";

interface ModalProps extends Omit<ChakraModalProps, "children" | "onClose"> {
  title?: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  body?: string | React.ReactNode;
  primaryButton?: React.ReactNode;
  secondaryButton?: React.ReactNode;
  canClose?: boolean;
  backgroundColor?: BackgroundProps["backgroundColor"];
  onClose?: ChakraModalProps["onClose"];
  overflow?: CSSProperties["overflow"];
}

const Modal = ({
  title,
  subtitle,
  body,
  primaryButton,
  secondaryButton,
  size = "md",
  canClose = true,
  backgroundColor = undefined,
  onClose = () => undefined,
  overflow = "auto",
  isOpen,
  ...restProps
}: ModalProps) => {
  return (
    <ChakraModal
      isOpen={isOpen}
      isCentered
      closeOnEsc={canClose}
      closeOnOverlayClick={canClose}
      size={size}
      onClose={onClose}
      {...restProps}
    >
      <ModalOverlay />
      <ModalContent
        maxH="90vh"
        marginBottom={0}
        marginTop={0}
        bg={backgroundColor}
        overflow={overflow}
      >
        {title && (
          <ModalHeader>
            <Heading mt={2} as="h1" fontSize="2xl" pr={4}>
              {title}
            </Heading>

            {subtitle && (
              <Box mt={2} fontSize="md" fontWeight="normal">
                {subtitle}
              </Box>
            )}
          </ModalHeader>
        )}
        {canClose && <ModalCloseButton />}
        <ModalBody>
          <ErrorBoundary>{body}</ErrorBoundary>
        </ModalBody>
        {(primaryButton || secondaryButton) && (
          <ModalFooter>
            <Flex flexDirection="row" w="100%">
              <Box>{secondaryButton}</Box>
              <Box flexGrow={1} />
              <Box>{primaryButton}</Box>
            </Flex>
          </ModalFooter>
        )}
      </ModalContent>
    </ChakraModal>
  );
};

export { Modal };
