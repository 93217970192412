import { Box, Flex, Stat, StatArrow } from "@chakra-ui/react";
import { SpaceProps } from "@chakra-ui/styled-system";
import { Tooltip } from "components/Tooltip";

const ChangeIndicator = ({
  value,
  previousValue,
  spaceProps,
  tooltipDescription,
  display = "number",
  inverted = false,
}: {
  value?: number;
  previousValue?: number;
  spaceProps?: SpaceProps;
  tooltipDescription?: string;
  display?: "number" | "percentage" | "no-display";
  inverted?: boolean;
}) => {
  if (!value || !previousValue) {
    return null;
  }

  const delta = value - previousValue;

  if (delta === 0) {
    return null;
  }

  const shouldNotDisplay = display === "no-display";

  const getArrowType = () => {
    if (delta > 0) {
      return inverted ? "decrease" : "increase";
    }

    if (delta < 0) {
      return inverted ? "increase" : "decrease";
    }
  };

  const getDisplayAs = () => {
    if (shouldNotDisplay) {
      return null;
    }

    if (display === "number") {
      return Math.abs(delta);
    }

    if (display === "percentage" || !display) {
      const asPercent = ((value / previousValue) * 100 - 100).toFixed(1);
      return `${asPercent}%`;
    }
  };

  return (
    <Tooltip
      aria-label="Change indicator"
      label={tooltipDescription ?? undefined}
    >
      <Stat {...spaceProps}>
        <Flex alignItems="center">
          <StatArrow mr={shouldNotDisplay ? 0 : 1} type={getArrowType()} />
          <Box>{getDisplayAs()}</Box>
        </Flex>
      </Stat>
    </Tooltip>
  );
};

export { ChangeIndicator };
