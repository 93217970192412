import { Route, Routes } from "react-router";
import { Navigate } from "react-router-dom";
import {
  CampaignLeadPerformance,
  CampaignLeadPerformanceScreenDefinition,
  CampaignsTrendingDomains,
  CampaignsTrendingSignals,
  CompanyLeadPacing,
  CompanyLeadPacingScreenDefinition,
  LeadPacing,
  LeadPacingScreenDefinition,
  DisplayPerformance,
  DisplayPerformanceScreenDefinition,
  TrendingAccountsScreenDefinition,
  TrendingTopicsAndKeywordsScreenDefinition,
  WeeklySnapshot,
  WeeklySnapshotScreenDefinition,
} from "screens";

const IntentAnalysisRouter = () => {
  return (
    <Routes>
      {WeeklySnapshotScreenDefinition.routePath.map((path) => (
        <Route key={path} path={path} element={<WeeklySnapshot />} />
      ))}

      {CompanyLeadPacingScreenDefinition.routePath.map((path) => (
        <Route key={path} path={path} element={<CompanyLeadPacing />} />
      ))}

      {LeadPacingScreenDefinition.routePath.map((path) => (
        <Route key={path} path={path} element={<LeadPacing />} />
      ))}

      {CampaignLeadPerformanceScreenDefinition.routePath.map((path) => (
        <Route key={path} path={path} element={<CampaignLeadPerformance />} />
      ))}

      {TrendingAccountsScreenDefinition.routePath.map((path) => (
        <Route key={path} path={path} element={<CampaignsTrendingDomains />} />
      ))}

      {TrendingTopicsAndKeywordsScreenDefinition.routePath.map((path) => (
        <Route key={path} path={path} element={<CampaignsTrendingSignals />} />
      ))}

      {DisplayPerformanceScreenDefinition.routePath.map((path) => (
        <Route key={path} path={path} element={<DisplayPerformance />} />
      ))}

      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>
  );
};

export { IntentAnalysisRouter };
