import { CoverageByEmployeeSizeRO, WeeklySnapshotDTO } from "@intentsify/dto";
import { apiService, Endpoints } from "api";

export const getCoverageByEmployeeSize = async (
  id: number,
  filters: WeeklySnapshotDTO
): Promise<any[]> => {
  const { data } = await apiService.get<CoverageByEmployeeSizeRO[]>(
    Endpoints.Campaigns.Get.WeeklySnapshot.CoverageByEmployeeSize(id),
    {
      params: {
        yearTo: filters.yearTo,
        weekTo: filters.weekTo,
      },
    }
  );

  return data;
};
