import { InfoOutlineIcon } from "@chakra-ui/icons";
import { Center, Flex, HStack, Icon, Text } from "@chakra-ui/react";
import { Company, Option, Unsafe } from "@intentsify/types";
import { Button, Tooltip } from "components";
import { useState } from "react";
import { CampaignSelector } from "shared/components";
import { IntegrationWithLogo } from "../../IntegrationsModal.utils";
import { ProviderCard, TagCampaign } from "./ProviderCard";
import { useSaveCampaigns } from "./mutations/useSaveCampaigns";
import { useCampaignsDiff } from "./useCampaignsDiff";

interface EditingCampaignsProviderProps {
  companyId: Company["companyId"];
  integration: IntegrationWithLogo;
  defaultCampaignsAssigned?: Unsafe<TagCampaign[]>;
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
}

export const EditingCampaignsProvider = (
  props: EditingCampaignsProviderProps
) => {
  const [campaigns, setCampaigns] = useState<TagCampaign[]>(
    props.defaultCampaignsAssigned || []
  );

  const campaignsDiff = useCampaignsDiff(
    props.defaultCampaignsAssigned || [],
    campaigns
  );

  const [isSavingCampaigns, saveCampaigns] = useSaveCampaigns(
    props.companyId,
    props.integration.id,
    campaignsDiff
  );

  const onCampaignChange = (campaignOption: Option<number> | undefined) => {
    if (campaignOption) {
      setCampaigns((campaigns) => [
        ...campaigns,
        { campaignId: campaignOption.value, displayName: campaignOption.label },
      ]);
    }
  };

  return (
    <ProviderCard.Root
      flexDirection="column"
      spacing={campaigns.length === 0 ? 0 : 10}
    >
      <ProviderCard.Header {...props}>
        <Center ml="-3">
          <Tooltip
            aria-label={`Assign campaigns to ${props.integration.provider} integration`}
            label="Please assign one or more campaigns to this integration using campaign select to the right"
          >
            <Icon as={InfoOutlineIcon} color="yellow.200" fontSize="lg" />
          </Tooltip>
        </Center>

        <Flex flex="1" justifyContent="flex-end">
          <CampaignSelector
            placeholder="Select intent models..."
            onChange={onCampaignChange}
          />
        </Flex>
      </ProviderCard.Header>

      <Flex w="100%" flexWrap="wrap">
        <ProviderCard.TagCampaigns
          campaigns={campaigns}
          onRemoveClick={(campaignId) => {
            setCampaigns((campaigns) =>
              campaigns.filter((c) => c.campaignId !== campaignId)
            );
          }}
        />

        {(campaigns.length > 0 || props.isEditing) && (
          <HStack w="100%" justifyContent="flex-end" spacing="2" mt="10">
            <Button
              variant="ghost"
              onClick={() => {
                props.setIsEditing(false);
                setCampaigns(props.defaultCampaignsAssigned || []);
              }}
            >
              Cancel
            </Button>

            <Button
              variant="primary-teal"
              isDisabled={
                campaigns.length === 0 ||
                (campaignsDiff.added.length === 0 &&
                  campaignsDiff.deleted.length === 0)
              }
              isLoading={isSavingCampaigns}
              onClick={() => {
                saveCampaigns();
              }}
            >
              <Text pt="1px">Save campaigns</Text>
            </Button>
          </HStack>
        )}
      </Flex>
    </ProviderCard.Root>
  );
};
