import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { WeeklySnapshotDTO, CoverageByTopIndustriesRO } from "@intentsify/dto";
import { getCoverageByTopIndustries } from "./CoverageByTopIndustries.requests";
import { Card, MIN_MODAL_CONTENT_HEIGHT, PieChart } from "components";
import { ChartExport, useExportableChart } from "shared/components";

type Props = {
  campaignId: number;
  filters: WeeklySnapshotDTO;
};

const CoverageByTopIndustries = ({ campaignId, filters }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const { data, isFetching } = useQuery<CoverageByTopIndustriesRO>(
    ["getCoverageByTopIndustries", campaignId, filters],
    () => getCoverageByTopIndustries(campaignId, filters)
  );

  const chartData = (data?.coverageByTopIndustries ?? []).map((el) => ({
    label: el.industry,
    value: el.countAccounts,
  }));

  const chart = useExportableChart({
    title: "Active Account Coverage Of Top 10 Industries",
    campaignId: 1,
    chart: (
      <PieChart
        minHeight={isExpanded ? MIN_MODAL_CONTENT_HEIGHT : undefined}
        stretch
        fixedRatio={[1, 1]}
        legendPosition="bottom"
        data={chartData}
        shouldSort={false}
        displayAsPercent={true}
      />
    ),
  });

  return (
    <Card
      onExpandedChange={setIsExpanded}
      isLoading={isFetching}
      title={chart.title}
      isExpandable
      hasData={!isFetching && Boolean(chartData.length)}
      actions={
        <ChartExport
          size="small"
          campaignId={campaignId}
          title={chart.title}
          onExportPNG={chart.downloadAsPng}
          data={chartData}
        />
      }
    >
      {chart.component}
      {chart.exportComponent}
    </Card>
  );
};

export { CoverageByTopIndustries };
