import { useColorModeValue, useTheme } from "@chakra-ui/react";

export const useChartColors = () => {
  const chakraTheme = useTheme();

  const axisColors = useColorModeValue(
    chakraTheme.colors.gray[400],
    chakraTheme.colors.gray[400]
  );

  const tooltipBackground = useColorModeValue(
    chakraTheme.colors.gray[25],
    chakraTheme.colors.gray[700]
  );

  const tooltipBorderColor = useColorModeValue(
    chakraTheme.colors.gray[200],
    "rgba(255,255,255, 0.2)"
  );

  const pieValueColor = useColorModeValue(
    "white",
    chakraTheme.colors.gray[800]
  );

  const labelColor = useColorModeValue(chakraTheme.colors.gray[800], "white");

  return {
    axisColors,

    tooltipBackground,
    tooltipBorderColor,
    labelColor,
    pieValueColor,
  };
};
