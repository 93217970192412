import { Box, BoxProps, Center } from "@chakra-ui/react";
import { defaultStringTransition } from "components/FramerMotionChakra/transitions";
import { useMedia } from "react-use";
import { useAppStore } from "store/useAppStore";
import { MotionBox } from "../FramerMotionChakra";
import { ReactComponent as LogoSvg } from "./assets/logo.svg";
import { ReactComponent as LogoLightDarkSvg } from "./assets/logo_lightdark.svg";
import { ReactComponent as LogoSymbolSvg } from "./assets/logo_symbol.svg";

const LogoBoxed = ({
  adaptToDarkMode,
  ...props
}: BoxProps & {
  adaptToDarkMode?: boolean;
}) => (
  <Box {...props}>{adaptToDarkMode ? <LogoLightDarkSvg /> : <LogoSvg />}</Box>
);

interface LogoProps {
  logoFullSizeProps?: BoxProps;
  logoSmallSizeProps?: BoxProps;
  logoCollapsedProps?: BoxProps;
  adaptToDarkMode?: boolean;
}

const Logo = ({
  logoFullSizeProps,
  logoSmallSizeProps,
  logoCollapsedProps,
  adaptToDarkMode,
}: LogoProps) => {
  const sideBarCollapsed = useAppStore.use.sideBarCollapsed();
  const isMobileSize = !useMedia("(min-width: 48em)");

  if (isMobileSize) {
    return (
      <LogoBoxed {...logoSmallSizeProps} adaptToDarkMode={adaptToDarkMode} />
    );
  }

  return (
    <MotionBox layout transition={defaultStringTransition}>
      {sideBarCollapsed && (
        <Center {...logoCollapsedProps}>
          <LogoSymbolSvg />
        </Center>
      )}

      {!sideBarCollapsed && <LogoBoxed {...logoFullSizeProps} />}
    </MotionBox>
  );
};

export { Logo, LogoBoxed };
