import { ErrorBoundary } from "components";
import { MIN_HEIGHT } from "../Charts.const";
import { BarChartItem } from "./BarChart.types";
import { Horizontal } from "./Horizontal";
import { Vertical } from "./Vertical";
import { ReactNode } from "react";

export const defaultKeys = ["value"];

export type BarChartProps = {
  orientation: "horizontal" | "vertical";
  isLoading?: boolean;
  data: BarChartItem[];
  margins?: { t: number; b: number; r: number; l: number };
  stretch?: boolean;
  showXAxis?: boolean;
  noDataMessage?: string;
  valueAxisLabel?: string;
  labelAxisLabel?: string;
  id?: string;
  title?: string;
  exportMode?: true;
  colorRange?: string[];
  minHeight?: string;
  keys?: string[];
  valueNumTicks?: number;
  maxXAxisHeight?: number;
  tooltipRenderer?: (textColor: string, props: BarChartItem) => ReactNode;
};

const BarChart = ({
  orientation,
  isLoading,
  data,
  margins,
  stretch = false,
  showXAxis = true,
  noDataMessage,
  valueAxisLabel,
  labelAxisLabel,
  id,
  title,
  colorRange,
  exportMode = undefined,
  minHeight = MIN_HEIGHT,
  valueNumTicks = 6,
  keys = defaultKeys,
  maxXAxisHeight,
  tooltipRenderer,
}: BarChartProps) => {
  const Component = orientation === "horizontal" ? Horizontal : Vertical;

  return (
    <ErrorBoundary>
      <Component
        minHeight={minHeight}
        colorRange={colorRange}
        exportMode={exportMode}
        id={id}
        title={title}
        isLoading={isLoading}
        data={data}
        margins={margins}
        stretch={stretch}
        showXAxis={showXAxis}
        noDataMessage={noDataMessage}
        valueAxisLabel={valueAxisLabel}
        labelAxisLabel={labelAxisLabel}
        valueNumTicks={valueNumTicks}
        keys={keys}
        maxXAxisHeight={maxXAxisHeight}
        tooltipRenderer={tooltipRenderer}
      />
    </ErrorBoundary>
  );
};

export { BarChart };
