import { Flex } from "@chakra-ui/layout";
import { SortDirection, TargetPersona } from "@intentsify/types";
import { RowSelectionState } from "@tanstack/react-table";
import { Table as ComponentsTable, NoData, useRowSelection } from "components";
import { PaginationType } from "components/Table/Table/Table.types";
import { useState } from "react";
import { FetchDataParamsWithToken } from "types";
import { BatchDeleteButton } from "./BatchDeleteButton";
import { BatchUpdateModal } from "./BatchUpdateModal";
import { useDeleteTargetPersonas } from "./useDeleteTargetPersonas";
import { useTargetPersonaColumns } from "./useTargetPersonaColumns";
import { useTargetPersonas } from "./useTargetPersonas";

type TableProps = {
  searchValue?: string;
};

export function TargetPersonasTable(props: TableProps) {
  const [params, setParams] = useState<
    FetchDataParamsWithToken<keyof TargetPersona>
  >({ pageToken: "", order: SortDirection.DESC, order_by: "updatedAt" });

  const targetPersonas = useTargetPersonas({
    ...params,
    search: props.searchValue,
  });

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const uniqueKeyAccessor = (i: TargetPersona) => i.id;
  const columns = useTargetPersonaColumns();

  const { selectedRows, removeSelection } = useRowSelection<TargetPersona>(
    rowSelection,
    setRowSelection,
    targetPersonas.data?.targetPersonas,
    uniqueKeyAccessor
  );
  const deleteTargetPersonas = useDeleteTargetPersonas(removeSelection);
  const selectedIds = selectedRows.map((row) => row.id);

  return (
    <>
      <ComponentsTable
        selectable
        customNoData={<NoData />}
        variant="intentsifyPlain"
        searchValue={props.searchValue}
        defaultSort={[{ id: "updatedAt", desc: true }]}
        columns={columns}
        data={targetPersonas.data?.targetPersonas ?? []}
        paginationType={PaginationType.TOKEN}
        nextPageToken={targetPersonas.data?.nextPageToken}
        isFetching={targetPersonas.isFetching}
        onFetchDataChange={setParams}
        uniqueKeyAccessor={uniqueKeyAccessor}
        resetPaginationDependencies={[
          props.searchValue,
          params.order,
          params.order_by,
        ]}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
      />

      <Flex>
        {selectedRows.length > 0 && (
          <Flex gap="4">
            <BatchUpdateModal targetPersonas={selectedRows} />
            <BatchDeleteButton
              idsToDelete={selectedIds}
              isLoading={deleteTargetPersonas.isLoading}
              onDelete={() => deleteTargetPersonas.mutate(selectedIds)}
            />
          </Flex>
        )}
      </Flex>
    </>
  );
}
