import { useToken } from "@chakra-ui/system";
import { LayerProps } from "react-map-gl";
import { LayerVisibility } from "../ImpressionsAndTopicsMap.types";

export const useImpressionsClusterLayerProps = (
  visibility: LayerVisibility
): LayerProps => {
  return {
    type: "circle",
    layout: {
      visibility: visibility,
    },
    filter: ["has", "point_count"],
    paint: {
      "circle-color": useToken("colors", "teal.400"),
      "circle-radius": ["step", ["get", "point_count"], 10, 25, 20, 750, 30],
      "circle-opacity": 0.9,
    },
  };
};
