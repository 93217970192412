import { Option } from "@intentsify/types";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  singleAccountDetailsFiltersAtom,
  singleAccountDetailsLocationOptionsAtom,
  singleAccountDetailsNamesOptionsAtom,
} from "../../SingleAccountDetails.state";
import { Filter, FiltersContainer, FiltersContainerItem } from "components";

const Filters = () => {
  const [filters, setFilters] = useRecoilState(singleAccountDetailsFiltersAtom);
  const nameOptions = useRecoilValue(singleAccountDetailsNamesOptionsAtom);
  const locationOptions = useRecoilValue(
    singleAccountDetailsLocationOptionsAtom
  );

  const updateFilters = (val: Record<string, Option[]>) => {
    setFilters({ ...filters, ...val });
  };

  const items: FiltersContainerItem[] = [
    {
      component: (
        <Filter
          isLoading={false}
          options={nameOptions}
          label="Name"
          placeholder="Select name"
          onFilterValuesChange={(option) => {
            updateFilters({ names: option });
          }}
        />
      ),
    },
    {
      component: (
        <Filter
          isLoading={false}
          options={locationOptions}
          label="Location"
          placeholder="Select location"
          onFilterValuesChange={(option) => {
            updateFilters({ locations: option });
          }}
        />
      ),
    },
  ];

  return <FiltersContainer items={items} />;
};

export { Filters };
