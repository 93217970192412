import { useCallback } from "react";
import { useLocation } from "react-router";
import { FileDownloadedProperties, ampli } from "./amplitude";

type TrackFileDownloadedOptions = {
  label: FileDownloadedProperties["label"];
  fileName: FileDownloadedProperties["fileName"];
};

export const useTrackFileDownloaded = () => {
  const location = useLocation();

  return useCallback(
    ({ label, fileName }: TrackFileDownloadedOptions) => {
      ampli.fileDownloaded({
        label,
        fileName,
        pagePath: location.pathname,
      });
    },
    [location.pathname]
  );
};
