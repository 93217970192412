import { Flex } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { ParentSize } from "@visx/responsive";
import { Card, MIN_MODAL_CONTENT_HEIGHT } from "components";
import orderBy from "lodash/orderBy";
import { useMemo } from "react";
import { DisplayPerformanceFilters } from "screens/CampaignPacing/screens/DisplayPerformance/DisplayPerformance";
import { getBuyerResearchStageEngagement } from "./BuyerResearchStageEngagement.requests";
import {
  BuyerResearchStageEngagementItem,
  BuyerResearchStageEngagementTable,
} from "./BuyerResearchStageEngagementTable";

type BuyerResearchStageEngagementProps = {
  campaignId: string;
  filters: DisplayPerformanceFilters;
};

const BuyerResearchStageEngagement = ({
  campaignId,
  filters,
}: BuyerResearchStageEngagementProps) => {
  const { data, isFetching } = useQuery(
    ["getBuyerResearchStageEngagement", campaignId, filters],
    () => getBuyerResearchStageEngagement(campaignId, filters)
  );

  const formatted = useMemo(() => {
    if (!data) {
      return undefined;
    }

    const sorted = orderBy(
      data.buyerResearchStageEngagement,
      ["totalStandard"],
      "desc"
    );

    const accountsInBuyerResearchStage = {
      accountsInDecision: sorted[0]?.decisionAccounts ?? 0,
      accountsInConsideration: sorted[0]?.considerationAccounts ?? 0,
      accountsInInterest: sorted[0]?.interestAccounts ?? 0,
      accountsInAwareness: sorted[0]?.awarenessAccounts ?? 0,
    };

    const items: BuyerResearchStageEngagementItem[] = sorted.map((i) => {
      return {
        name: i.creativeName,
        decision: i.decisionStandard ?? 0,
        consideration: i.considerationStandard ?? 0,
        interest: i.interestStandard ?? 0,
        awareness: i.awarenessStandard ?? 0,
      };
    });

    return { accountsInBuyerResearchStage, items };
  }, [data]);

  return (
    <Card
      isLoading={isFetching}
      title={"Creative Engagement by Buyer Research Stage"}
      isExpandable
      titleTooltip="Normalized data illustrates collective engagement on each creative ad performed by target accounts in respective buyer research stages. Based on this heat map, one can view which creative ad is most relevant and/or engaging to a given buyer research stage. "
    >
      {(isExpanded) => (
        <Flex
          flexDir="column"
          h={isExpanded ? MIN_MODAL_CONTENT_HEIGHT : "100%"}
        >
          {formatted && (
            <ParentSize>
              {(parent) => {
                const { width, height } = parent;

                return (
                  <Flex w={width} height={height} fontSize="xs">
                    <BuyerResearchStageEngagementTable
                      accountsInBuyerResearchStage={
                        formatted.accountsInBuyerResearchStage
                      }
                      data={formatted.items}
                    />
                  </Flex>
                );
              }}
            </ParentSize>
          )}
        </Flex>
      )}
    </Card>
  );
};

export { BuyerResearchStageEngagement };
