import { Divider } from "@chakra-ui/layout";
import {
  CampaignKeywordsAndTopics,
  SignalSelectionSource,
} from "@intentsify/types";
import { forwardRef, useEffect, useImperativeHandle } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { MutateCampaignKeywordsAndTopicsParams } from "../../../CampaignsWizard.requests";
import { NavigationBlockers } from "../../NavigationBlockers/NavigationBlockers";
import { SubmitTrigger } from "../../SettingsStep/SettingsForm";
import {
  useKeywordsAndTopicsQueries,
  useKeywordsAndTopicsState,
  useWatchKeywordsAndTopics,
} from "../KeywordsAndTopics.hooks";
import {
  isLoadingKeywordsAndTopicsStepSelector,
  isLoadingKeywordsAtom,
  isLoadingTopicsAtom,
} from "../KeywordsAndTopicsStep.state";
import { Configure } from "./Configure/Configure";
import { useSummaryTable } from "./SummaryTable/SummaryTable";
import { TopicSuggestions } from "./TopicSuggestions/TopicSuggestions";

type KeywordsAndTopicsFormProps = {
  id: number;
  editing: boolean;
  campaignKeywordsAndTopics: CampaignKeywordsAndTopics;
  onSubmit: (params: MutateCampaignKeywordsAndTopicsParams) => void;
};

export const KeywordsAndTopicsForm = forwardRef<
  SubmitTrigger | undefined,
  KeywordsAndTopicsFormProps
>(({ id, editing, campaignKeywordsAndTopics, onSubmit }, ref) => {
  const { categoryIndex, SummaryTable } = useSummaryTable();
  const setIsLoadingTopics = useSetRecoilState(isLoadingTopicsAtom);
  const setIsLoadingKeywords = useSetRecoilState(isLoadingKeywordsAtom);

  const isLoading = useRecoilValue(isLoadingKeywordsAndTopicsStepSelector);

  const { state } = useKeywordsAndTopicsState();
  const { keywords, topics } = useKeywordsAndTopicsQueries();

  useEffect(() => {
    setIsLoadingKeywords(keywords.isLoading);
    setIsLoadingTopics(topics.isLoading);
  }, [
    keywords.isLoading,
    topics.isLoading,
    setIsLoadingKeywords,
    setIsLoadingTopics,
  ]);

  useImperativeHandle(ref, () => ({
    submit({ shouldExitOnSuccess }) {
      onSubmit({
        newSettings: {
          id,
          keywords: state.selectedKeywords.map((k) => ({
            keyword: k.label,
            keywordId: k.value as number,
            source: k.metaData?.source as SignalSelectionSource,
          })),
          topics: state.selectedTopics,
        },
        shouldExitOnSuccess,
      });
    },
  }));

  const haveChanged = useWatchKeywordsAndTopics(
    campaignKeywordsAndTopics,
    state.selectedKeywords,
    state.selectedTopics
  );

  return (
    <>
      <NavigationBlockers
        isUnsavedChangesBlockerEnabled={haveChanged && !isLoading}
      />

      <SummaryTable />
      {categoryIndex === 0 && (
        <>
          <TopicSuggestions id={id} />
          <Divider mt="2" />
        </>
      )}

      <Configure
        id={id}
        editing={editing}
        campaignKeywordsAndTopics={campaignKeywordsAndTopics}
      />
    </>
  );
});
