import { Icon, IconProps, SystemStyleObject } from "@chakra-ui/react";

type ExpanderIconProps = {
  isOpen: boolean;
  iconProps?: Pick<IconProps, "w" | "h">;
  onClick?: () => void;
};

const ExpanderIcon = ({ isOpen, iconProps, onClick }: ExpanderIconProps) => {
  const iconStyles: SystemStyleObject = {
    opacity: 1,
    transform: isOpen ? "rotate(-180deg)" : undefined,
    transition: "transform 0.2s",
    transformOrigin: "center",
  };

  return (
    //@ts-ignore
    <Icon
      onClick={onClick}
      viewBox="0 0 24 24"
      aria-hidden
      className={"chakra-accordion__icon"}
      __css={iconStyles}
      cursor="pointer"
      {...(iconProps ? iconProps : {})}
    >
      <path
        fill="currentColor"
        d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"
      />
    </Icon>
  );
};

export { ExpanderIcon };
