import { Button } from "@chakra-ui/button";
import { Flex, Text } from "@chakra-ui/layout";
import { Icon, MenuItem, useToast } from "@chakra-ui/react";
import { DelayedTooltip } from "components/Tooltip/DelayedTooltip";
import { useSignOut } from "mutations/user/useSignOut";
import {
  MdOutlineDoubleArrow,
  MdOutlineLogout,
  MdSettings,
} from "react-icons/md";
import { useNavigate } from "react-router";
import { UserSettingsScreenDefinition } from "screens";
import { useAppStore } from "store/useAppStore";
import { useUserProfile } from "../../../../../../../utils/useUserProfile";

export const Header = () => {
  const { displayName, email } = useUserProfile();

  return (
    <Flex w="full" direction="column" gap="4">
      {/* <TrialIndication /> */}

      <Flex w="full" justifyContent="space-between">
        <Flex direction="column">
          <Text fontWeight="bold" color="white" _light={{ color: "black" }}>
            {displayName}
          </Text>

          <Text fontSize="sm" color="gray.100" _light={{ color: "gray.800" }}>
            {email}
          </Text>
        </Flex>

        <Flex gap="0.5">
          <ExpandCollapseSidebarAction />
          <GoToSettingsAction />
          <LogOutAction />
        </Flex>
      </Flex>
    </Flex>
  );
};

const ExpandCollapseSidebarAction = () => {
  const sideBarCollapsed = useAppStore.use.sideBarCollapsed();
  const setSideBarCollapsed = useAppStore.use.setSideBarCollapsed();

  return (
    <DelayedTooltip
      aria-label="Toggle sidebar width"
      offset={[0, 8]}
      label={`${sideBarCollapsed ? "Expand" : "Collapse"} sidebar`}
    >
      <MenuItem
        as={Button}
        variant="ghost"
        bg="transparent"
        aria-label={`${sideBarCollapsed ? "Expand" : "Collapse"} sidebar`}
        h="8"
        w="8"
        minW="auto"
        transform={sideBarCollapsed ? "rotate(0deg)" : "rotate(180deg)"}
        onClick={() => setSideBarCollapsed(!sideBarCollapsed)}
      >
        <Icon as={MdOutlineDoubleArrow} h="4" w="4" />
      </MenuItem>
    </DelayedTooltip>
  );
};

const GoToSettingsAction = () => {
  const navigate = useNavigate();

  return (
    <DelayedTooltip
      aria-label="Go to user settings"
      offset={[0, 8]}
      label="Settings"
    >
      <MenuItem
        as={Button}
        variant="ghost"
        bg="transparent"
        aria-label="Settings"
        h="8"
        w="8"
        minW="auto"
        onClick={() => {
          navigate(UserSettingsScreenDefinition.navigate());
        }}
      >
        <Icon as={MdSettings} h="4" w="4" />
      </MenuItem>
    </DelayedTooltip>
  );
};

const LogOutAction = () => {
  const toast = useToast();
  const signOut = useSignOut({
    onSuccess: () => {
      toast.closeAll();

      toast({
        title: "See you again soon!",
        status: "success",
      });
    },
  });

  return (
    <DelayedTooltip aria-label="Log out" offset={[0, 8]} label="Log out">
      <MenuItem
        as={Button}
        variant="ghost"
        bg="transparent"
        aria-label="Log out"
        h="8"
        w="8"
        minW="auto"
        onClick={() => {
          void signOut();
        }}
      >
        <Icon as={MdOutlineLogout} h="4" w="4" />
      </MenuItem>
    </DelayedTooltip>
  );
};
