import { Box } from "@chakra-ui/react";
import { Tooltip } from "components";
import { ComponentProps, useLayoutEffect, useRef, useState } from "react";

type OverflowableTextProps = {
  children: string;
  ["aria-label"]: string;
} & Omit<ComponentProps<typeof Box>, "children">;

export const OverflowableText = ({
  children,
  "aria-label": ariaLabel,
  ...rest
}: OverflowableTextProps) => {
  const [isTooltipDisabled, setIsTooltipDisabled] = useState(true);
  const boxRef = useRef<HTMLDivElement | null>(null);
  useLayoutEffect(() => {
    if (boxRef.current) {
      const boxEl = boxRef.current;
      if (boxEl.offsetWidth < boxEl.scrollWidth) {
        setIsTooltipDisabled(false);
      }
    }
  }, []);

  return (
    <Tooltip
      aria-label={ariaLabel}
      label={children}
      isDisabled={isTooltipDisabled}
    >
      <Box ref={boxRef} overflow="hidden" textOverflow="ellipsis" {...rest}>
        {children}
      </Box>
    </Tooltip>
  );
};
