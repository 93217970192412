import {
  DateRange,
  defaultDateRangeFilters,
  Option,
  SplitBy,
} from "@intentsify/types";
import { isPopulatedArray } from "@intentsify/utils";
import {
  DateRangeFilter,
  Filter,
  FiltersContainer,
  FiltersContainerItem,
  SplitByFilter,
} from "components";
import { useParams } from "react-router-dom";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import {
  performingDomainsOptionsStateAtom,
  topPerformingDomainsDateRangeAtom,
  topPerformingDomainsSplitByAtom,
  topPerformingDomainsStateSelector,
  topPerformingSelectedDomainsAtom,
} from "../../TopPerformingDomains.state";

const Filters = () => {
  const campaignId = Number(useParams<"campaignId">().campaignId);
  const {
    selectedDomains,
    isLoading,
    topPerformingDomainsOptions,
    selectedSplitBy,
    selectedDateRange,
  } = useRecoilValue(topPerformingDomainsStateSelector);

  const setSelectedDomains = useSetRecoilState(
    topPerformingSelectedDomainsAtom
  );
  const resetOptions = useResetRecoilState(performingDomainsOptionsStateAtom);
  const setSplitBy = useSetRecoilState(topPerformingDomainsSplitByAtom);
  const setDateRange = useSetRecoilState(topPerformingDomainsDateRangeAtom);

  const onSelectedDomainsChange = (options: Array<Option<number>>) => {
    setSelectedDomains({ campaignId, data: options });
  };

  const hasNoOptions = !isPopulatedArray(topPerformingDomainsOptions);

  const items: FiltersContainerItem[] = [
    {
      component: (
        <Filter
          label={"Accounts"}
          options={topPerformingDomainsOptions}
          isLoading={isLoading}
          isDisabled={isLoading || hasNoOptions}
          placeholder="Select accounts"
          onFilterValuesChange={(options) => {
            onSelectedDomainsChange(options);
          }}
          selected={selectedDomains.data ?? []}
        />
      ),
      size: "xl",
    },
    {
      component: (
        <DateRangeFilter<DateRange>
          isLoading={isLoading}
          isDisabled={isLoading}
          dateRange={selectedDateRange}
          options={defaultDateRangeFilters.dateRangeOptions}
          onChange={(value) => {
            resetOptions();
            setDateRange(value);
          }}
        />
      ),
    },
    {
      component: (
        <SplitByFilter<SplitBy>
          onChange={(value) => setSplitBy(value)}
          options={defaultDateRangeFilters.splitByOptions}
          value={selectedSplitBy}
          isLoading={isLoading}
          isDisabled={isLoading || hasNoOptions}
        />
      ),
    },
  ];

  return <FiltersContainer items={items} />;
};

export { Filters };
