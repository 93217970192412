import { Text as ChakraText } from "@chakra-ui/react";
import { SimpleSeriesTable } from "../SimpleSeriesTable";
import { TimeseriesTooltipProps } from "./TimeseriesTooltip.types";

const TimeseriesTooltip = <K extends string, DK extends string>({
  tooltipData,
  dataMainKey,
  dataKeyLabels,
  timeseries,
  data,
  textColor,
}: TimeseriesTooltipProps<K, DK>) => {
  return (
    <>
      <ChakraText
        fontSize="medium"
        pl={1}
        mt={2}
        mb={3}
        fontWeight="bold"
        color={textColor}
      >
        {tooltipData[dataMainKey]}
      </ChakraText>
      <SimpleSeriesTable
        timeseries={timeseries}
        item={tooltipData}
        dataKeyLabels={dataKeyLabels}
        data={data}
      />
    </>
  );
};

export { TimeseriesTooltip };
