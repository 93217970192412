import { Center, HStack, Text } from "@chakra-ui/react";
import { WeeklySnapshotRangeDTO } from "@intentsify/dto";
import { decimalToPercentage, toNumberDisplayValue } from "@intentsify/utils";
import { Card, Carousel, Creative } from "components";
import { MdAnalytics } from "react-icons/md";
import { useTopCreativePerformance } from "./TopCreativePerformance.state";

type TopCreativePerformanceProps = {
  campaignId: number;
  filters: WeeklySnapshotRangeDTO;
};

export const TopCreativePerformance = ({
  campaignId,
  filters,
}: TopCreativePerformanceProps) => {
  const { isTopCreativesFetching, topCreatives } = useTopCreativePerformance(
    campaignId,
    filters
  );
  const slides =
    topCreatives.map(({ imageUrl, ctr, showIFrame, isVideoCreative }) => ({
      content: (
        <Creative
          creativeUrl={imageUrl}
          isVideoCreative={isVideoCreative}
          showIframe={showIFrame}
        />
      ),

      description: (
        <HStack p={0}>
          <HStack>
            <Text fontSize={"14px"} fontWeight={"light"}>
              CTR:
            </Text>
            <Text fontSize={"14px"} fontWeight={"semibold"}>
              {ctr
                ? toNumberDisplayValue(decimalToPercentage(ctr), { digits: 2 })
                : "-"}
              %
            </Text>
          </HStack>
        </HStack>
      ),
      link: imageUrl,
    })) || [];

  return (
    <Card
      isLoading={isTopCreativesFetching}
      title="Top 5 Creative Performance By CTR"
      hasData={Boolean(topCreatives.length)}
      noDataMessage="This visualization is allocated for display programs only."
      noDataIcon={MdAnalytics}
    >
      <Center width="full" height="100%">
        <Carousel
          slides={slides}
          maxWidth="24rem"
          collocation="Top 5 Creative Performance By CTR"
        />
      </Center>
    </Card>
  );
};
