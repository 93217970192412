import { Route, Routes } from "react-router";
import { Navigate } from "react-router-dom";
import {
  ProgrammaticDomains,
  ProgrammaticDomainsDetails,
  ProgrammaticDomainsUpload,
} from "screens/MediaActivation/screens/ProgrammaticDomains/screens";
import { AutomatedProgrammaticSegmentForm } from "screens/MediaActivation/screens/ProgrammaticDomains/screens/AutomatedProgrammaticSegmentForm/AutomatedProgrammaticSegmentForm";
import { AutomatedProgrammaticSegmentDefinition } from "screens/MediaActivation/screens/ProgrammaticDomains/screens/AutomatedProgrammaticSegmentForm/AutomatedProgrammaticSegmentForm.definition";
import { EditAutomatedProgrammaticSegment } from "screens/MediaActivation/screens/ProgrammaticDomains/screens/EditAutomatedProgrammaticSegment/EditAutomatedProgrammaticSegment";
import { EditAutomatedProgrammaticSegmentDefinition } from "screens/MediaActivation/screens/ProgrammaticDomains/screens/EditAutomatedProgrammaticSegment/EditAutomatedProgrammaticSegment.definition";
import { ProgrammaticDomainsScreenDefinition } from "screens/MediaActivation/screens/ProgrammaticDomains/screens/ProgrammaticDomains/ProgrammaticDomains.definition";
import { ProgrammaticDomainsFileDetailsScreenDefinition } from "screens/MediaActivation/screens/ProgrammaticDomains/screens/ProgrammaticDomainsDetails/ProgrammaticDomainsFileDetails.definition";
import { ProgrammaticDomainsUploadScreenDefinition } from "screens/MediaActivation/screens/ProgrammaticDomains/screens/ProgrammaticDomainsUpload/ProgrammaticDomainsUpload.definition";

const ProgrammaticDomainsRouter = () => {
  return (
    <Routes>
      {ProgrammaticDomainsScreenDefinition.routePath.map((path) => (
        <Route key={path} path={path} element={<ProgrammaticDomains />} />
      ))}

      {ProgrammaticDomainsFileDetailsScreenDefinition.routePath.map((path) => (
        <Route
          key={path}
          path={path}
          element={<ProgrammaticDomainsDetails />}
        />
      ))}

      {ProgrammaticDomainsUploadScreenDefinition.routePath.map((path) => (
        <Route key={path} path={path} element={<ProgrammaticDomainsUpload />} />
      ))}

      {AutomatedProgrammaticSegmentDefinition.routePath.map((path) => (
        <Route
          key={path}
          path={path}
          element={<AutomatedProgrammaticSegmentForm mode="create" />}
        />
      ))}

      {EditAutomatedProgrammaticSegmentDefinition.routePath.map((path) => (
        <Route
          key={path}
          path={path}
          element={<EditAutomatedProgrammaticSegment />}
        />
      ))}

      <Route path="*" element={<Navigate to="/404" />} />
    </Routes>
  );
};

export { ProgrammaticDomainsRouter };
