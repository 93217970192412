import { ConfirmDialog } from "./ConfirmDialog";
import { useConfirmDialogIdsStateValue } from "./ConfirmDialog.stateIds";

/**
 * Renders all ConfirmDialogs in the app.
 */
export const ConfirmDialogRoot = () => {
  const confirmDialogIds = useConfirmDialogIdsStateValue();

  return (
    <>
      {[...confirmDialogIds.values()].map((id) => (
        <ConfirmDialog key={id} dialogId={id} />
      ))}
    </>
  );
};
