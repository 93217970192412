import { ButtonGroup } from "@chakra-ui/react";
import { Button } from "components";

type CreateControlsProps = {
  onNext: () => void;
  isDisabled: boolean;
  onNextLabel?: string;
  onPrevious: (() => void) | undefined;
};

const CreateControls = ({
  onNext,
  onPrevious,
  isDisabled,
  onNextLabel = "Next",
}: CreateControlsProps) => {
  return (
    <ButtonGroup>
      {onPrevious && (
        <Button size="md" onClick={onPrevious}>
          Previous
        </Button>
      )}

      <Button
        size="md"
        isDisabled={isDisabled}
        variant="primary-teal"
        onClick={onNext}
      >
        {onNextLabel}
      </Button>
    </ButtonGroup>
  );
};

export { CreateControls };
