import { CreateAutomatedSegmentUploadScreen } from "types";
import { createScreen } from "utils/createScreen";

const EditAutomatedProgrammaticSegmentDefinition = createScreen({
  name: CreateAutomatedSegmentUploadScreen,
  path: "/media-activation/programmatic-segments/automated/:id",
  routePath: ["/automated/:id"],
  accessPath: "programmaticCampaign.programmaticDomain",
});

export { EditAutomatedProgrammaticSegmentDefinition };
