import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Avatar,
  Box,
  Flex,
  HStack,
  SimpleGrid,
  Spinner,
  Text,
} from "@chakra-ui/react";
import startCase from "lodash/startCase";
import { useCallback, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getWorkatoToken } from "../IntegrationSettings.requests";
import {
  WorkatoConnection,
  WorkatoEvent,
  WorkatoEventType,
} from "../IntegrationSettings.types";
import { Badge } from "components";
import { getWorkatoProviderLogoUrl } from "utils/getWorkatoProviderLogoUrl";

const IFRAME_DEFAULT_HEIGHT = 500;

const IntegrationConnection = ({
  companyId,
  workatoCustomerId,
  connection: { id, provider, name },
}: {
  companyId: number;
  workatoCustomerId: number;
  connection: WorkatoConnection;
}) => {
  const [isConnected, setConnected] = useState<boolean>(false);
  const [iframeHeight, setIframeHeight] = useState<number>(
    IFRAME_DEFAULT_HEIGHT
  );
  const [connectionStatusLoading, setConnectionStatusLoading] =
    useState<boolean>(true);

  const { data: token } = useQuery(
    ["getWorkatoToken", companyId, provider],
    async () =>
      getWorkatoToken({
        companyId,
        workatoCustomerId: Number(workatoCustomerId),
      }),
    { enabled: Boolean(workatoCustomerId) }
  );

  const handleOnReceiveMessage = useCallback(
    (event: { data: string }) => {
      if (!(typeof event?.data === "string")) {
        return;
      }

      const data: WorkatoEvent = JSON.parse(event.data);

      const { type, payload } = data;
      switch (true) {
        case type === WorkatoEventType.CONNECTION_STATUS_CHANGE &&
          provider === payload.provider:
          setConnected(Boolean(payload.connected));
          setConnectionStatusLoading(false);
          break;
        case type === WorkatoEventType.ERROR:
          console.error(payload.message);
          break;
        case type === WorkatoEventType.HEIGHT_CHANGE:
          setIframeHeight(payload.height ?? IFRAME_DEFAULT_HEIGHT);
          break;
        default:
          // eslint-disable-next-line no-console
          console.log("Unsupported Workato Message.");
          break;
      }
    },
    [provider]
  );

  useEffect(() => {
    window.addEventListener("message", handleOnReceiveMessage);

    return () => {
      window.removeEventListener("message", handleOnReceiveMessage);
    };
  }, [handleOnReceiveMessage]);

  return (
    <AccordionItem key={`${name}_${provider}`}>
      <h2>
        <AccordionButton>
          <Avatar src={getWorkatoProviderLogoUrl(provider)} />
          <Box flex="1" textAlign="left" ml={5}>
            <HStack maxWidth={600}>
              <SimpleGrid columns={2}>
                <Box w="95px">
                  <Text fontWeight="bold">
                    {startCase(provider.toLowerCase())}
                  </Text>
                </Box>
                <Box w="300px">
                  <Text color="gray.500" fontSize="sm">
                    {name}
                  </Text>
                </Box>
              </SimpleGrid>
            </HStack>
          </Box>
          <Box mr={5}>
            {connectionStatusLoading ? (
              <Flex>
                <Spinner ml={3} size="sm" />
              </Flex>
            ) : (
              <Badge colorScheme={isConnected ? "green" : "red"}>
                {isConnected ? "Connected" : "Not Connected"}
              </Badge>
            )}
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4} minHeight={1000}>
        <Box p={4} bgColor={"white"} borderRadius={4}>
          <iframe
            id={`workato_${provider}_${name}`}
            width={"100%"}
            height={iframeHeight}
            src={`https://www.workato.com/direct_link/embedded/connections/${id}?workato_dl_token=${String(
              token
            )}`}
          />
        </Box>
      </AccordionPanel>
    </AccordionItem>
  );
};

export { IntegrationConnection };
