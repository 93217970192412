import { UserSettingsScreen } from "types";
import { createScreen } from "utils/createScreen";

const UserSettingsScreenDefinition = createScreen({
  name: UserSettingsScreen,
  path: "/settings",
  routePath: ["/"],
  accessPath: "miscellaneous.ownUserSettings",
});

export { UserSettingsScreenDefinition };
