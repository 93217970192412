import { CreateCompanyScreenDefinition } from "./CreateCompany.definition";
import { CreateCompanyForm } from "./CreateCompanyForm";
import { ViewContainer } from "components";
import { useScreen } from "utils";

const CreateCompany = () => {
  useScreen(CreateCompanyScreenDefinition);

  return (
    <ViewContainer>
      <CreateCompanyForm />
    </ViewContainer>
  );
};

export { CreateCompany };
