export type ListPaginatedTalToolsRecordsParams = FetchDataParamsWithToken<
  keyof GenericUserJobRecord
> & { type: GenericUserJobType };

import { GenericUserJobsFilesRO } from "@intentsify/dto";
import {
  GenericUserJobRecord,
  GenericUserJobType,
  SortDirection,
} from "@intentsify/types";
import { apiService, Endpoints } from "api";
import { FetchDataParamsWithToken } from "types";

export const defaultParams: ListPaginatedTalToolsRecordsParams = {
  pageToken: "",
  order_by: "createdAt",
  order: SortDirection.ASC,
  type: GenericUserJobType.COMPANY_NAME_TO_DOMAIN,
};

export const listPaginatedTalToolsRecords = async (
  params: ListPaginatedTalToolsRecordsParams
): Promise<GenericUserJobsFilesRO> => {
  const { data } = await apiService.get<GenericUserJobsFilesRO>(
    Endpoints.GenericUserJobs.Get.Files,
    { params }
  );

  return data;
};
