import {
  AccountsInBuyerResearchStageStatisticsRO,
  WeeklySnapshotDTO,
} from "@intentsify/dto";
import { apiService, Endpoints } from "api";

export const getAccountAnalysisStats = async (
  campaignId: number,
  filters: WeeklySnapshotDTO
) => {
  const { data } = await apiService.get<
    AccountsInBuyerResearchStageStatisticsRO[]
  >(
    Endpoints.Campaigns.Get.WeeklySnapshot.AccountsInBuyerResearchStageStatistics(
      campaignId
    ),
    {
      params: {
        yearTo: filters.yearTo,
        weekTo: filters.weekTo,
      },
    }
  );

  return data;
};
