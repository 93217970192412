import {
  chakra,
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  Input,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Modal } from "components";
import { useForm } from "react-hook-form";
import { useComponentColors } from "theme";
import { z } from "zod";
import { addBusinessUnit } from "./NewBusinessUnit.requests";

const validationSchema = z.object({
  displayName: z.string().min(3),
});

type FieldValues = z.infer<typeof validationSchema>;

type NewBusinessUnitProps = {
  companyId: number;
  isOpen: boolean;
  onClose: () => void;
};

const NewBusinessUnit = ({
  companyId,
  isOpen,
  onClose,
}: NewBusinessUnitProps) => {
  const componentColors = useComponentColors();
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FieldValues>({
    mode: "onChange",
    resolver: zodResolver(validationSchema),
  });

  const { mutate, isLoading } = useMutation(addBusinessUnit, {
    onSuccess: () => {
      onClose();
      queryClient.invalidateQueries({ queryKey: ["businessUnits"] });
    },
  });

  const onSubmit = (values: FieldValues) => {
    mutate({
      ...values,
      companyId,
    });
  };

  return (
    <Modal
      title="New Subsidiary"
      subtitle="Subsidiaries, also known as business units, are individual sectors underneath a company that can be used to limit and/or include permissions and access."
      body={
        <chakra.form borderTopRadius={4} borderRadius={4}>
          <FormControl
            isInvalid={!!errors?.displayName?.message}
            as={GridItem}
            isRequired
          >
            <FormLabel
              color={componentColors.form.formLabelColor}
              fontSize="sm"
              fontWeight="md"
            >
              Subsidiary Name
            </FormLabel>

            <Input
              {...register("displayName")}
              type="name"
              name="displayName"
              placeholder="Subsidiary Name"
              shadow="sm"
              size="sm"
              w="300px"
              rounded="md"
            />

            <FormErrorMessage>{errors?.displayName?.message}</FormErrorMessage>
          </FormControl>
        </chakra.form>
      }
      isOpen={isOpen}
      onClose={onClose}
      secondaryButton={
        <Button aria-label="Cancel" mr={4} onClick={onClose}>
          Cancel
        </Button>
      }
      primaryButton={
        <Button
          aria-label="Add Subsidiary"
          variant="primary-teal"
          onClick={() => {
            handleSubmit(onSubmit)();
          }}
          isLoading={isLoading}
        >
          Add
        </Button>
      }
    />
  );
};

export { NewBusinessUnit };
