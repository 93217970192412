import { AgreementScreen } from "types";
import { createScreen } from "utils/createScreen";

const AgreementScreenDefinition = createScreen({
  name: AgreementScreen,
  path: "/auth/agreement",
  routePath: ["/agreement"],
  accessPath: "miscellaneous.ownUserSettings",
});

export { AgreementScreenDefinition };
