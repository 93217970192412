import {
  AvailableSpotlightDeckAccountsRO,
  CampaignBuyingGroupSettingsDTO,
  GetUserCampaignsSummaryRO,
  GetUserRO,
  ListActiveCampaignsRO,
  ListBusinessUnitsRO,
  ListCampaignsRO,
  ListCompaniesRO,
  ListKeywordsRO,
  ListPastCampaignsRO,
  ListSaasRolesRO,
  RequestSpotlightDeckDTO,
} from "@intentsify/dto";

import { createStandaloneToast } from "@chakra-ui/react";

import {
  ActiveCampaignForList,
  BusinessEntity,
  Campaign,
  CampaignBrief,
  CampaignForList,
  CampaignMeta,
  DateRange,
  PastCampaignForList,
  SaasRole,
} from "@intentsify/types";
import { router } from "App.router";
import {
  CompanyBusinessUnitsFetchDataParams,
  Endpoints,
  apiService,
  defaultListPaginatedCompanyBusinessUnitsParams,
} from "api";
import { queryClient } from "queryClient/PortalQueryClientProvider";
import { sessionExpiredToastOptions } from "shared/sessionExpiredToastOptions";
import { useAppStore } from "store/useAppStore";
import { DateWeekAndYear, FetchDataParamsWithToken } from "types";
import { PaginatedKeywordsParams } from "types/Keyword";
import { isOfType } from "utils/isOfType";
import { LoginScreenDefinition } from "../auth/screens/Login/Login.definition";

export const listRoles = async (): Promise<SaasRole[]> => {
  const {
    data: { roles },
  } = await apiService.get<ListSaasRolesRO>(Endpoints.Roles.Get.All);

  return roles;
};

export const getUserById = async (userId: string): Promise<GetUserRO> => {
  const { data } = await apiService.get<Promise<GetUserRO>>(
    Endpoints.Users.Get.ById(userId)
  );
  return data;
};

export const listCompanyBusinessUnits = async (
  companyId: number,
  params: CompanyBusinessUnitsFetchDataParams = defaultListPaginatedCompanyBusinessUnitsParams
): Promise<ListBusinessUnitsRO> => {
  const { data } = await apiService.get<ListBusinessUnitsRO>(
    Endpoints.Companies.Get.ListBusinessUnits(companyId),
    { params }
  );

  return data;
};

export const listCompanies = async (params?: {
  hasCampaigns?: boolean;
}): Promise<ListCompaniesRO> => {
  const { data } = await apiService.get<ListCompaniesRO>(
    Endpoints.Companies.Get.All,
    {
      params,
    }
  );
  return data;
};

export const signOutRequest = async (
  params = { showSessionExpiredToast: true }
) => {
  if (!useAppStore.getState().user) {
    return;
  }

  await apiService.post(Endpoints.Auth.Post.LogOut);
  router.navigate(LoginScreenDefinition.navigate());

  useAppStore.getState().reset();
  queryClient.clear();

  if (params.showSessionExpiredToast) {
    const { toast } = createStandaloneToast();
    toast(sessionExpiredToastOptions);
  }
};

export const refreshAccessToken = async () => {
  await apiService.get(Endpoints.Auth.Get.Refresh);
};

export const cloneCampaign = async (id: number): Promise<Campaign> => {
  const { data } = await apiService.post(Endpoints.Campaigns.Post.Clone(id));
  return data;
};

export const listPaginatedCampaigns = async (
  params: FetchDataParamsWithToken<keyof CampaignForList>
): Promise<ListCampaignsRO> => {
  const { data } = await apiService.get<ListCampaignsRO>(
    Endpoints.Campaigns.Get.Paginated,
    { params }
  );
  return data;
};

export const getUserCampaignsSummary = async (params: {
  search: string | undefined;
  isFavorite: boolean;
}): Promise<GetUserCampaignsSummaryRO> => {
  const { data } = await apiService.get<GetUserCampaignsSummaryRO>(
    Endpoints.Campaigns.Get.UserCampaignsSummary,
    { params }
  );
  return data;
};

export const listActiveCampaigns = async (
  companyId: number,
  params: FetchDataParamsWithToken<keyof ActiveCampaignForList>
): Promise<ListActiveCampaignsRO> => {
  const { data } = await apiService.get<ListActiveCampaignsRO>(
    Endpoints.Companies.Get.ListActiveCampaigns(companyId),
    { params }
  );
  return data;
};

export const listPastCampaigns = async (
  companyId: number,
  params: FetchDataParamsWithToken<keyof PastCampaignForList>
): Promise<ListPastCampaignsRO> => {
  const { data } = await apiService.get<ListPastCampaignsRO>(
    Endpoints.Companies.Get.ListPastCampaigns(companyId),
    { params }
  );
  return data;
};

export const getCampaignBrief = async (id: number): Promise<CampaignBrief> => {
  const { data } = await apiService.get(Endpoints.Campaigns.Get.Brief(id));

  return data;
};

export const getCampaignBuyingGroup = async (
  id: number
): Promise<CampaignBuyingGroupSettingsDTO> => {
  const { data } = await apiService.get(
    Endpoints.Campaigns.Get.BuyingGroup(id)
  );

  return data;
};

export const getCampaignMeta = async (
  id: string | number,
  date: DateRange | DateWeekAndYear
): Promise<CampaignMeta> => {
  if (isOfType<DateWeekAndYear>(date, "weekNumber")) {
    const { data } = await apiService.get(Endpoints.Campaigns.Get.Meta(id), {
      params: { weekNumber: date.weekNumber, yearNumber: date.yearNumber },
      timeout: 30000, // 30 seconds
    });

    return data;
  }
  const { data } = await apiService.get(Endpoints.Campaigns.Get.Meta(id), {
    params: { dateRange: date },
    timeout: 30000, // 30 seconds,
  });

  return data;
};

export const listKeywords = async (
  params: PaginatedKeywordsParams
): Promise<ListKeywordsRO> => {
  const { data } = await apiService.get<ListKeywordsRO>(
    Endpoints.Signals.Get.Keywords,
    { params }
  );
  return data;
};

export const listPaginatedKeywords =
  () => async (params: PaginatedKeywordsParams) => {
    const { keywords, nextPageToken } = await listKeywords(params);

    return {
      nextPageToken,
      results: keywords.map((keyword) => ({
        value: keyword.displayName,
        label: keyword.displayName,
      })),
    };
  };

export const getBusinessEntityById = async (
  id: number
): Promise<BusinessEntity> => {
  const { data } = await apiService.get<BusinessEntity>(
    Endpoints.BusinessEntities.Get.ById(id)
  );

  return data;
};

export const requestSpotlightDeck = async (params: RequestSpotlightDeckDTO) => {
  const { data } = await apiService.post<AvailableSpotlightDeckAccountsRO>(
    Endpoints.Reports.Spotlight.Post.RequestSpotlightDeck,
    params
  );

  return data;
};
