import { useToast } from "@chakra-ui/react";
import {
  BatchUpdateTargetPersonasClientDTO,
  BatchUpdateTargetPersonasRO,
  ListTargetPersonasRO,
} from "@intentsify/dto";
import { pluralize } from "@intentsify/utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Endpoints, apiService } from "api";

export const useUpdateTargetPersonas = (onSuccess?: () => void) => {
  const toast = useToast();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["targetPersonas", "update"],
    mutationFn: async (
      personasToUpdate: BatchUpdateTargetPersonasClientDTO[]
    ) => {
      const { data } = await apiService.put<BatchUpdateTargetPersonasRO>(
        Endpoints.TargetPersonas.Put.Batch,
        personasToUpdate
      );

      return data.targetPersonas;
    },
    onSuccess: (data) => {
      toast({
        title: `Target ${pluralize(
          data.length,
          "persona",
          "personas"
        )} updated`,
        status: "success",
      });
      onSuccess?.();

      queryClient.setQueriesData<ListTargetPersonasRO>(
        { queryKey: ["targetPersonas"], exact: false },
        (oldData) => ({
          ...oldData,
          targetPersonas: (oldData?.targetPersonas || []).map(
            (persona) =>
              data.find((updatedPersona) => updatedPersona.id === persona.id) ??
              persona
          ),
        })
      );
    },
  });
};
