/* eslint-disable react-hooks/rules-of-hooks */
import { Flex } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/react";
import { formatDateRange } from "@intentsify/utils";
import { ColumnDef } from "@tanstack/react-table";
import { CellWithChange, IconButton } from "components";
import { accountLogoFallbackImage } from "const";
import { FiFileText } from "react-icons/fi";
import { useParams } from "react-router";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { accountDetailsAtom } from "screens/IntentAnalysis/screens/CampaignsTrendingDomains/CampaignsTrendingDomains.state";
import { TimeseriesRange } from "types";
import { topTrendingDomainsDateRangeAtom } from "../../TopTrendingAccounts.state";
import {
  TopTrendingDomainsItem,
  TopTrendingDomainsWithTimeseriesResponse,
} from "../../TopTrendingAccounts.types";

const getSeriesColumns = (
  seriesKey: string,
  previousKey: string | undefined,
  timeseriesRange: TimeseriesRange
): ColumnDef<TopTrendingDomainsItem> => {
  const formattedSeriesHeader = formatDateRange(timeseriesRange);

  return {
    header: formattedSeriesHeader,
    columns: [
      {
        header: "Signals",
        enableSorting: false,
        cell: ({ row }) => {
          const data = row.original?.timeseries.find(
            (s) => s.isoDate === seriesKey
          );

          const previousSeries = previousKey
            ? row.original?.timeseries.find((s) => s.isoDate === previousKey)
            : undefined;

          return (
            <CellWithChange
              value={data?.data.value}
              previousValue={previousSeries?.data.value}
            />
          );
        },
      },
    ],
  };
};

export const getColumns = (
  timeseries: TopTrendingDomainsWithTimeseriesResponse["timeseries"]
) => {
  const seriesColumns = timeseries.map((series, i) =>
    getSeriesColumns(series.start, timeseries[i - 1]?.start, series)
  );

  const columns: Array<ColumnDef<TopTrendingDomainsItem>> = [
    {
      header: "Account",
      enableSorting: false,
      cell: ({ row }) => {
        const selectedDateRange = useRecoilValue(
          topTrendingDomainsDateRangeAtom
        );
        const setAccountDetails = useSetRecoilState(accountDetailsAtom);
        const campaignId = Number(useParams<"campaignId">().campaignId);

        return (
          <Flex alignItems="center">
            <Image
              minW="35px"
              boxSize="35px"
              objectFit="contain"
              src={String(row.original?.logoUrl)}
              mr={4}
              fallbackSrc={accountLogoFallbackImage}
            />
            {row.original?.label}

            <IconButton
              ml={2}
              size="xs"
              tooltip="View account details"
              variant="outline"
              icon={<FiFileText />}
              onClick={(e) => {
                e.stopPropagation();

                if (campaignId) {
                  setAccountDetails({
                    campaignId: Number(campaignId),
                    account: String(row.original?.label),
                    dateRange: selectedDateRange,
                  });
                }
              }}
            />
          </Flex>
        );
      },
    },
    ...seriesColumns,
  ];

  return columns;
};
