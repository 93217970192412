import { Flex } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { Option } from "@intentsify/types";
import { listBusinessEntities } from "./BusinessEntitySelector.requests";
import { Select } from "components";

type BusinessEntitySelectorProps = {
  selected?: number;
  onChange: (selection: Option | null) => void;
  isDisabled?: boolean;
  isClearable?: boolean;
  width?: string;
  resetSelectionDependencies?: (number | undefined)[];
};

const BussinesEntitySelector = ({
  selected,
  onChange,
  isDisabled,
  isClearable = true,
  width = "400px",
}: BusinessEntitySelectorProps) => {
  const { data, isLoading } = useQuery(["allBusinessEntities"], () =>
    listBusinessEntities()
  );

  const { list, businessEntityMap } = useMemo(() => {
    const list =
      data?.map((i) => ({
        value: i.id,
        label: i.displayName,
      })) ?? [];

    const businessEntityMap = new Map<
      number,
      {
        value: number;
        label: string;
      }
    >();

    list.forEach((i) => businessEntityMap.set(i.value, i));

    return {
      list,
      businessEntityMap,
    };
  }, [data]);

  const value = selected ? businessEntityMap.get(selected) : undefined;

  return (
    <Flex
      data-testId="business-entity-selector"
      w={width}
      flexDir={"column"}
      justify="space-between"
    >
      <Select
        testId="businessEntitySelector"
        isMulti={false}
        isDisabled={isLoading || isDisabled}
        isLoading={isLoading}
        isClearable={isClearable}
        placeholder="Select a business entity"
        options={list}
        value={value}
        onChange={onChange}
      />
    </Flex>
  );
};

export { BussinesEntitySelector };
