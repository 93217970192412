import { ListUsersRO } from "@intentsify/dto";
import { SortDirection, UserForList } from "@intentsify/types";
import { apiService, Endpoints } from "api";
import { FetchDataParamsWithToken } from "types";

const listUsers = async (
  params: FetchDataParamsWithToken<
    keyof UserForList
  > = defaultListPaginatedUsersParams
): Promise<ListUsersRO> => {
  const { data } = await apiService.get<ListUsersRO>(Endpoints.Users.Get.List, {
    params,
  });
  return data;
};

const defaultListPaginatedUsersParams: FetchDataParamsWithToken<
  keyof UserForList
> = {
  pageToken: "",
  order_by: "userId",
  order: SortDirection.DESC,
};

const removeUser = async (id: number | string) =>
  await apiService.delete(Endpoints.Users.Delete(id));

export { defaultListPaginatedUsersParams, listUsers, removeUser };
