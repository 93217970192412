import { CheckCircleIcon } from "@chakra-ui/icons";
import { Box, Flex, Text } from "@chakra-ui/layout";
import { Icon } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { Endpoints, apiService } from "api";
import { Loader } from "components";
import { BaseErrorBoundary } from "components/ErrorBoundary/SimpleErrorBoundary";
import { useEffect } from "react";
import { useParams } from "react-router";
import { isCustomApiError } from "types";
import { OptOutDisplayError } from "./OptOutDisplayError";
import { OptOutError } from "./OptOutError";

export function OptOutDelete() {
  const { token } = useParams<"token">();
  if (!token || typeof token !== "string") {
    throw new Error("Invalid token");
  }

  const confirmDelete = useConfirmDelete();

  useEffect(() => {
    confirmDelete.mutate(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <Flex flexDir="column" gap="10">
      <BaseErrorBoundary
        fallback={<OptOutDisplayError />}
        customApiErrorFallback={
          <OptOutError error={confirmDelete.failureReason} />
        }
      >
        <Box w="full" maxWidth="lg" mx="auto" pt="4vh">
          {confirmDelete.isLoading && <Loader />}
          <OptOutError error={confirmDelete.failureReason} />
          {confirmDelete.isSuccess && (
            <Flex
              flexDir="column"
              justifyContent="center"
              alignItems="center"
              gap="6"
            >
              <Flex w="full" justifyContent="center" alignItems="center">
                <Icon as={CheckCircleIcon} color="teal.500" fontSize="3rem" />
              </Flex>

              <Flex
                flexDir="column"
                justifyContent="center"
                alignItems="center"
                gap="2"
              >
                <Text fontSize="xl">
                  Your Delete request has been submitted.
                </Text>
                <Text
                  color="blackAlpha.600"
                  _dark={{ color: "whiteAlpha.600" }}
                >
                  We will process your request shortly. We will send you an
                  email when your request has been processed.
                </Text>
              </Flex>
            </Flex>
          )}
        </Box>
      </BaseErrorBoundary>
    </Flex>
  );
}

function useConfirmDelete() {
  return useMutation({
    mutationFn: async (token: string) => {
      return await apiService(
        Endpoints.PersonalInfo.ConfirmDeleteRequest(token),
        { method: "PUT" }
      );
    },
    retry(failureCount, error) {
      if (isCustomApiError(error)) {
        return false;
      }

      return true;
    },
  });
}
