import { TriangleDownIcon } from "@chakra-ui/icons";
import { Flex } from "@chakra-ui/react";
import { isDefined } from "@intentsify/utils";
import { localPoint } from "@visx/event";
import { Group } from "@visx/group";
import { ParentSize } from "@visx/responsive";
import { scaleLinear } from "@visx/scale";
import { Bar, Line } from "@visx/shape";
import { Text as VisxText } from "@visx/text";
import { useTooltip, useTooltipInPortal } from "@visx/tooltip";
import React, { useMemo, useState } from "react";
import { useChartColors } from "utils";
import { BasicTooltip } from "../BasicTooltip";
import { measureTextWidth, trimTextByWidth } from "../Charts.utils";
import { useGetColour } from "../TimeseriesNew";

interface TornadoChartItem {
  name: string;
  leftScore: number;
  rightScore: number;
}

interface TornadoChartDataSide {
  items: TornadoChartItem[];
  maxReferenceScore: number;
}

type Side = "left" | "right";
type TooltipData = {
  sortSide: Side;
  hoverSide: Side;
  name: string;
};

export type TornadoChartData = [
  left: TornadoChartDataSide,
  right: TornadoChartDataSide
];
export type TornadoChartLabels = [leftLabel: string, rightLabel: string];

interface TornadoChartProps {
  data: TornadoChartData;
  labels: TornadoChartLabels;
  title?: string;
  minHeight?: React.ComponentProps<typeof Flex>["minHeight"];
  id?: string;
  exportMode?: boolean;
  defaultSortSide?: Side;
  tooltipRenderer: (data: TooltipData) => React.ReactElement;
}

const AXIS_HEIGHT = 3;
const LINE_MARGIN = 10;
const TOP_ROW_HEIGHT = 20;
const BAR_MARGIN = 4;
const LABEL_WIDTH_MIN = 200;
const LABEL_WIDTH_MAX = 300;
const LABELS_PADDING = 20;
const LABEL_FONT_SIZE = 14;
const HEADER_FONT_SIZE = 16;
const SORT_ICON_SCALE = 0.6;
const CHART_EXPORT_H_PADDING = 10;
const CHART_EXPORT_V_PADDING = 10;

export const TornadoChart = ({
  data: [leftSide, rightSide],
  labels: [leftLabel, rightLabel],
  title,
  minHeight,
  id,
  defaultSortSide = "left",
  tooltipRenderer,
  exportMode = false,
}: TornadoChartProps) => {
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    detectBounds: true,
    scroll: true,
  });
  const {
    showTooltip,
    tooltipLeft,
    tooltipData,
    tooltipTop,
    tooltipOpen,
    hideTooltip,
  } = useTooltip<TooltipData>();

  const [sortSide, setSortSide] = useState<Side>(defaultSortSide);
  const getSideItem = (name: string) =>
    (sortSide === "left" ? leftSide : rightSide).items.find(
      (item) => item.name === name
    ) ?? {
      name,
      leftScore: 0,
      rightScore: 0,
    };
  const leftLabelSize = useMemo(
    () => measureTextWidth(leftLabel, { fontSize: HEADER_FONT_SIZE }),
    [leftLabel]
  );
  const rightLabelSize = useMemo(
    () => measureTextWidth(rightLabel, { fontSize: HEADER_FONT_SIZE }),
    [rightLabel]
  );
  const getColour = useGetColour();
  const leftColour = getColour("blue");
  const rightColour = getColour("green");

  const { axisColors, labelColor } = useChartColors();

  const itemNames = useMemo(() => {
    return (sortSide === "left" ? leftSide : rightSide).items.map(
      ({ name }) => name
    );
  }, [leftSide, rightSide, sortSide]);

  const maxNameWidth = useMemo(() => {
    return (
      Math.max(
        ...[...leftSide.items, ...rightSide.items]
          .map(({ name }) => name)
          .map((name) => measureTextWidth(name, { fontSize: LABEL_FONT_SIZE }))
      ) + LABEL_FONT_SIZE
    );
  }, [leftSide, rightSide]);

  const onMouseEnter = (
    e: React.MouseEvent<SVGElement, MouseEvent>,
    side: Side,
    name: string
  ) => {
    const target = e.target as unknown as SVGElement | null;
    if (!target?.ownerSVGElement) {
      return;
    }
    const coords = localPoint(target.ownerSVGElement, e);
    if (coords) {
      showTooltip({
        tooltipLeft: coords.x,
        tooltipTop: coords.y,
        tooltipData: {
          sortSide,
          hoverSide: side,
          name,
        },
      });
    }
  };

  return (
    <Flex overflowY="auto" width="100%" height="100%" minHeight={minHeight}>
      <Flex width="100%">
        <ParentSize>
          {({ width, height }) => {
            const widthWithPadding = exportMode
              ? width - CHART_EXPORT_H_PADDING * 2
              : width;
            const heightWithPadding = exportMode
              ? height - CHART_EXPORT_V_PADDING * 2 - TOP_ROW_HEIGHT
              : height;
            const mainLeftOffset = exportMode ? CHART_EXPORT_H_PADDING : 0;
            const mainTopOffset = exportMode
              ? TOP_ROW_HEIGHT + CHART_EXPORT_V_PADDING
              : 0;
            const layoutProposedLabelWidth = width
              ? Math.min(
                  Math.max(Math.floor(widthWithPadding / 3), LABEL_WIDTH_MIN),
                  LABEL_WIDTH_MAX
                )
              : LABEL_WIDTH_MAX;
            const innerLabelsWidth =
              maxNameWidth > LABEL_WIDTH_MIN && maxNameWidth < LABEL_WIDTH_MAX
                ? maxNameWidth
                : layoutProposedLabelWidth;
            const outerLabelsWidth = innerLabelsWidth + LABELS_PADDING;
            const sideWidth = (widthWithPadding - outerLabelsWidth) / 2;
            const availableChartHeight =
              heightWithPadding - TOP_ROW_HEIGHT - LINE_MARGIN * 2;
            const calculatedRowHeight = Math.floor(
              availableChartHeight / itemNames.length
            );

            const leftScale = scaleLinear<number>({
              range: [0, sideWidth],
              round: true,
              domain: [0, leftSide.maxReferenceScore],
            });

            const rightScale = scaleLinear<number>({
              range: [0, sideWidth],
              round: true,
              domain: [0, rightSide.maxReferenceScore],
            });

            return (
              <svg
                width={width}
                height={height}
                id={id}
                ref={containerRef}
                style={{
                  fontFamily: "var(--chakra-fonts-body)",
                  color: labelColor,
                }}
              >
                {exportMode && (
                  <VisxText
                    textAnchor="middle"
                    fill={labelColor}
                    y={TOP_ROW_HEIGHT / 2}
                    x={width / 2}
                    dy="1em"
                    fontWeight="bold"
                    cursor="pointer"
                  >
                    {title}
                  </VisxText>
                )}
                <Group left={mainLeftOffset} top={mainTopOffset}>
                  <Group left={sideWidth / 2}>
                    <VisxText
                      textAnchor="middle"
                      fill={labelColor}
                      dy="1em"
                      onClick={() => setSortSide("left")}
                      fontWeight="bold"
                      cursor="pointer"
                    >
                      {leftLabel}
                    </VisxText>
                    {sortSide === "left" && (
                      <Group
                        transform={`translate(${
                          leftLabelSize / 2 + HEADER_FONT_SIZE / 2
                        } ${
                          (HEADER_FONT_SIZE * SORT_ICON_SCALE) / 2
                        }) scale(${SORT_ICON_SCALE})`}
                      >
                        <TriangleDownIcon
                          fill={labelColor}
                          viewBox=" "
                          aria-label="sorted descending"
                        />
                      </Group>
                    )}
                  </Group>

                  <Line
                    x1={0}
                    y1={TOP_ROW_HEIGHT + LINE_MARGIN}
                    y2={TOP_ROW_HEIGHT + LINE_MARGIN}
                    x2={sideWidth}
                    stroke={axisColors}
                    strokeWidth={AXIS_HEIGHT}
                  />

                  <Group left={outerLabelsWidth + sideWidth + sideWidth / 2}>
                    <VisxText
                      fill={labelColor}
                      textAnchor="middle"
                      cursor="pointer"
                      fontSize={HEADER_FONT_SIZE}
                      dy="1em"
                      onClick={() => setSortSide("right")}
                      fontWeight="bold"
                    >
                      {rightLabel}
                    </VisxText>
                    {sortSide === "right" && (
                      <Group
                        transform={`translate(${
                          rightLabelSize / 2 + HEADER_FONT_SIZE / 2
                        } ${
                          (HEADER_FONT_SIZE * SORT_ICON_SCALE) / 2
                        }) scale(${SORT_ICON_SCALE})`}
                      >
                        <TriangleDownIcon
                          fill={labelColor}
                          viewBox=" "
                          aria-label="sorted descending"
                        />
                      </Group>
                    )}
                  </Group>

                  <Line
                    x1={outerLabelsWidth + sideWidth}
                    y1={TOP_ROW_HEIGHT + LINE_MARGIN}
                    y2={TOP_ROW_HEIGHT + LINE_MARGIN}
                    x2={widthWithPadding}
                    strokeWidth={AXIS_HEIGHT}
                    stroke={axisColors}
                  />

                  <Group top={TOP_ROW_HEIGHT + LINE_MARGIN * 2}>
                    {itemNames.map((name, index) => {
                      const xItemWidth = leftScale(getSideItem(name).leftScore);
                      return (
                        <Bar
                          key={name}
                          y={calculatedRowHeight * index + BAR_MARGIN}
                          x={sideWidth - xItemWidth}
                          height={calculatedRowHeight - BAR_MARGIN * 2}
                          fill={leftColour[0]}
                          onMouseEnter={(e) => onMouseEnter(e, "left", name)}
                          onMouseLeave={hideTooltip}
                          width={xItemWidth}
                        />
                      );
                    })}
                  </Group>

                  <Group
                    top={TOP_ROW_HEIGHT + LINE_MARGIN * 2}
                    left={sideWidth}
                  >
                    {itemNames.map((name, index) => (
                      <VisxText
                        key={name}
                        textAnchor="middle"
                        x={outerLabelsWidth / 2}
                        y={
                          calculatedRowHeight * index + calculatedRowHeight / 2
                        }
                        fill={labelColor}
                        dy="0.25em"
                        fontFamily={"var(--chakra-fonts-body)"}
                        fontSize={LABEL_FONT_SIZE}
                      >
                        {trimTextByWidth(name, {
                          fontSize: LABEL_FONT_SIZE,
                          maxWidth: innerLabelsWidth,
                        })}
                      </VisxText>
                    ))}
                  </Group>

                  <Group
                    left={sideWidth + outerLabelsWidth}
                    top={TOP_ROW_HEIGHT + LINE_MARGIN * 2}
                  >
                    {itemNames.map((name, index) => (
                      <Bar
                        key={name}
                        y={calculatedRowHeight * index + BAR_MARGIN}
                        height={calculatedRowHeight - BAR_MARGIN * 2}
                        fill={rightColour[0]}
                        onMouseEnter={(e) => onMouseEnter(e, "right", name)}
                        onMouseLeave={hideTooltip}
                        width={rightScale(getSideItem(name).rightScore)}
                      />
                    ))}
                  </Group>
                </Group>

                {tooltipOpen &&
                  tooltipData &&
                  isDefined(tooltipLeft) &&
                  isDefined(tooltipTop) && (
                    <BasicTooltip
                      TooltipInPortal={TooltipInPortal}
                      tooltipLeft={tooltipLeft}
                      tooltipTop={tooltipTop}
                      tooltipData={tooltipData}
                      tooltipRenderer={({ textColor, tooltipData }) => {
                        if (!tooltipData) {
                          return null;
                        }
                        return (
                          <Flex color={textColor} flexDir="column">
                            {tooltipRenderer(tooltipData)}
                          </Flex>
                        );
                      }}
                    />
                  )}
              </svg>
            );
          }}
        </ParentSize>
      </Flex>
    </Flex>
  );
};
