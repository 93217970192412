import { EditBusinessEntityScreen } from "types";
import { createScreen } from "utils/createScreen";

const EditBusinessEntityScreenDefinition = createScreen({
  name: EditBusinessEntityScreen,
  path: "/admin/business-entities/edit/:id",
  routePath: ["/edit/:id"],
  accessPath: "businessEntities",
});

export { EditBusinessEntityScreenDefinition };
