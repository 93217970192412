import { ButtonGroup } from "@chakra-ui/react";
import { IconButton, IconButtonProps } from "../IconButton";

export type ViewSelectorItem<V extends string> = {
  label: string;
  icon: IconButtonProps["icon"];
  value: V;
  onClick: (value: V) => void;
};

type ViewSelectorProps<V extends string> = {
  selected?: V;
  items: Array<ViewSelectorItem<V>>;
};

const ViewSelector = <T extends string>({
  selected,
  items,
}: ViewSelectorProps<T>) => {
  return (
    <>
      <ButtonGroup size="sm" isAttached variant="outline">
        {items.map((item) => {
          const { label, onClick, value, icon } = item;

          const isActive = selected === value;

          return (
            <IconButton
              tooltipShouldWrapChildren={false}
              tooltip={label}
              isActive={isActive}
              key={value}
              icon={icon}
              onClick={() => onClick(value)}
              variant="outline"
            />
          );
        })}
      </ButtonGroup>
    </>
  );
};

export { ViewSelector };
