import { Box, Flex, List, ListItem } from "@chakra-ui/layout";
import { formatDateRange, isPopulatedArray } from "@intentsify/utils";
import { ColumnDef } from "@tanstack/react-table";
import { CellWithChange, CollapsingCell, EmptyCell } from "components";
import { TimeseriesRange } from "types";
import {
  KeywordsItem,
  TrendingKeywordsWithTimeseriesResponse,
} from "../../TrendingKeywords.types";

const getSeriesColumns = (
  seriesKey: string,
  previousKey: string | undefined,
  timeseriesRange: TimeseriesRange
): ColumnDef<KeywordsItem> => {
  const formattedSeriesHeader = formatDateRange(timeseriesRange);

  return {
    header: formattedSeriesHeader,
    columns: [
      {
        header: "Signals",
        enableSorting: false,

        cell: ({ row }) => {
          const data = row.original?.timeseries.find(
            (s) => s.isoDate === seriesKey
          );

          const previousSeries = previousKey
            ? row.original?.timeseries.find((s) => s.isoDate === previousKey)
            : undefined;

          return (
            <CellWithChange
              value={data?.data.value}
              previousValue={previousSeries?.data.value}
            />
          );
        },
      },
      {
        header: "Accounts",
        cell: ({ row }) => {
          const data = row.original?.timeseries.find(
            (s) => s.isoDate === seriesKey
          );

          const accounts = data?.data.accounts.slice(0, 15) as {
            account: string;
            count: number;
          }[];

          const previousSeries = previousKey
            ? row.original?.timeseries.find((s) => s.isoDate === previousKey)
            : undefined;

          const previousAccounts = previousSeries?.data.accounts;

          if (!isPopulatedArray(accounts)) {
            return <EmptyCell />;
          }

          return accounts.length > 3 ? (
            <CollapsingCell startingHeight={64}>
              <List lineHeight={1.55}>
                {accounts.map((account, index) => {
                  const accountFromPreviousWeek = previousAccounts?.find(
                    (a) => a.account === account.account
                  );

                  return (
                    <ListItem whiteSpace="nowrap" key={index}>
                      <Flex alignItems={"center"}>
                        <Box>
                          {account.account} ({account.count})
                        </Box>
                        <CellWithChange
                          renderValue={false}
                          value={account.count}
                          previousValue={accountFromPreviousWeek?.count}
                        />
                      </Flex>
                    </ListItem>
                  );
                })}
              </List>
            </CollapsingCell>
          ) : (
            <List>
              {accounts.map((account, index) => (
                <ListItem whiteSpace="nowrap" key={index}>
                  {account.account} ({account.count})
                </ListItem>
              ))}
            </List>
          );
        },
      },
    ],
  };
};

export const getSignalChangeColumns = (
  timeseries: TrendingKeywordsWithTimeseriesResponse["timeseries"]
) => {
  const seriesColumns = timeseries.map((series, i) =>
    getSeriesColumns(series.start, timeseries[i - 1]?.start, series)
  );

  const columns: Array<ColumnDef<KeywordsItem>> = [
    {
      header: "Keyword",
      enableSorting: false,
      cell: ({ row }) => {
        return (
          <Flex whiteSpace="nowrap" height="35px" alignItems="center">
            {row.original?.label}
          </Flex>
        );
      },
    },
    ...seriesColumns,
  ];

  return columns;
};
