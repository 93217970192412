import { Box, Flex, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import { Option, ProgrammaticCampaign, SortDirection } from "@intentsify/types";
import { useQuery } from "@tanstack/react-query";
import {
  Badge,
  ButtonGroup,
  FilterAsyncTokenBased,
  FiltersContainer,
  Loader,
  RangePicker,
  ScreenBar,
  ViewContainer,
} from "components";
import { DateTime } from "luxon";
import { useState } from "react";
import { useNavigate, useParams } from "react-router";
import { NoEntitySelected } from "screens/CampaignPacing/components";
import { CampaignEnded } from "shared/components";
import { FetchDataParamsWithToken, Nullable } from "types";
import { useScreen } from "utils";
import { DisplayPerformanceScreenDefinition } from "./DisplayPerformance.definition";
import {
  getProgrammaticCampaign,
  listPaginatedProgrammaticCampaigns,
} from "./DisplayPerformance.requests";
import { getProgrammaticCampaignDatesBasedOnStatus } from "./DisplayPerformance.utils";
import {
  AccountsPerformance,
  CampaignEngagement,
  CreativeEngagement,
} from "./components";

type CampaignOption = Option<number | string> & { companyId: number | string };

enum DisplayPerformanceView {
  CampaignEngagement = "campaign-engagement",
  Accounts = "accounts",
  CreativeEngagement = "creative-engagement",
}

export type DisplayPerformanceFilters = {
  yearFrom: number;
  weekFrom: number;
  yearTo: number;
  weekTo: number;
};

const defaultFetchDataParams: FetchDataParamsWithToken<
  keyof ProgrammaticCampaign
> = {
  order: SortDirection.ASC,
  order_by: "isEnded",
  pageToken: "",
};

const listCampaigns =
  () =>
  async (
    params: FetchDataParamsWithToken<keyof ProgrammaticCampaign, undefined> & {
      pageSize?: number;
    }
  ) => {
    const { data, nextPageToken } = await listPaginatedProgrammaticCampaigns(
      params
    );

    return {
      nextPageToken,
      results: data.map((c) => ({
        value: c.id,
        label: c.displayName,
        companyId: c.companyId,
        meta: {
          renderListItemAdditionalContent: () => {
            const text = c.isEnded ? "Ended" : "Active";
            const backgroundColor = c.isEnded ? "brand.50" : "green.600";
            return (
              <Badge
                height="23px"
                lineHeight="23px"
                width={55}
                textAlign="center"
                backgroundColor={backgroundColor}
              >
                {text}
              </Badge>
            );
          },
        },
      })),
    };
  };

const DisplayPerformance = () => {
  useScreen(DisplayPerformanceScreenDefinition);
  const navigate = useNavigate();
  const { programmaticCampaignId, view } = useParams<
    "programmaticCampaignId" | "view"
  >();

  const [filters, setFilters] = useState<
    DisplayPerformanceFilters | undefined
  >();

  const [selectedCampaignOption, setSelectedCampaignOption] =
    useState<CampaignOption>();

  const [selectedCampaign, setSelectedCampaign] =
    useState<Nullable<ProgrammaticCampaign>>(null);

  const selectedView =
    (view as DisplayPerformanceView | undefined) ||
    DisplayPerformanceView.Accounts;

  const topbarColor = useColorModeValue("white", "brand.375");
  const colorGrey = useColorModeValue("gray.25", "brand.375");

  const handleChange = (c: CampaignOption | undefined) => {
    // if campaign is selected but user selects the same one
    if (
      selectedCampaignOption?.value &&
      c?.value === selectedCampaignOption.value
    ) {
      return;
    }

    // If campaign id changed, reset everything
    if (c?.value !== programmaticCampaignId) {
      setFilters(undefined);
      setSelectedCampaign(null);
    }

    // If no campaign is selected
    if (!c) {
      setSelectedCampaignOption(undefined);
      setSelectedCampaign(null);
      navigate(
        DisplayPerformanceScreenDefinition.navigate({
          programmaticCampaignId: undefined,
          view: undefined,
        })
      );
    } else {
      setFilters(undefined);
      setSelectedCampaignOption(c);
      navigate(
        DisplayPerformanceScreenDefinition.navigate({
          programmaticCampaignId: c.value,
          view: selectedView,
        })
      );
    }
  };

  const { isFetching: isFetchingCampaign } = useQuery(
    [`programmatic-campaign`, programmaticCampaignId],
    () => {
      return getProgrammaticCampaign(String(programmaticCampaignId));
    },
    {
      enabled: !!programmaticCampaignId,
      onSuccess: (data) => {
        setSelectedCampaignOption({
          value: data.id,
          label: data.displayName,
          companyId: data.companyId,
        });

        setSelectedCampaign(data);

        setFilters((prevState) => {
          const dates = getProgrammaticCampaignDatesBasedOnStatus({
            weekTo: data.weekTo,
            yearTo: data.yearTo,
            weekFrom: data.weekFrom,
            yearFrom: data.yearFrom,
            isEnded: data.isEnded,
          });

          return {
            ...prevState,
            ...dates,
          };
        });
      },
    }
  );

  const title = !programmaticCampaignId
    ? "Overall Digital Performance Reporting"
    : selectedCampaignOption?.label || "";

  return (
    <ViewContainer noPadding withBackground stretch>
      <Flex width="full" backgroundColor={topbarColor}>
        <Box maxWidth="1640px" width="full">
          <ScreenBar
            filters={[
              {
                width: "400",
                node: (
                  <Flex pl={1}>
                    <Text fontWeight={"bold"}>{title}</Text>
                    {programmaticCampaignId && selectedCampaign?.isEnded && (
                      <CampaignEnded />
                    )}
                  </Flex>
                ),
              },
              {
                label: "Campaigns",
                width: "450",
                node: (
                  <Box data-testid="campaign-selector">
                    <FilterAsyncTokenBased
                      isMulti={false}
                      currentValue={selectedCampaignOption}
                      defaultOptions={[]}
                      dataRequest={listCampaigns()}
                      placeholder="Select a campaign"
                      onFilterValuesChange={handleChange}
                      params={defaultFetchDataParams}
                      noDataTooltip="There are currently no display campaigns associated with your programs"
                    />
                  </Box>
                ),
              },
            ]}
            justifyContent="space-between"
          />
        </Box>
      </Flex>
      <Flex
        flexWrap="wrap"
        borderTop={2}
        borderTopStyle={"solid"}
        borderTopColor={colorGrey}
      >
        <Box
          paddingX={2}
          backgroundColor={topbarColor}
          flexBasis="100%"
          minHeight="56px"
        >
          {programmaticCampaignId && filters && (
            <FiltersContainer
              items={[
                {
                  component: (
                    <Stack>
                      <Text fontSize="xs" fontWeight="semibold">
                        Date range:
                      </Text>

                      <RangePicker
                        minW="100%"
                        isDisabled={isFetchingCampaign}
                        placement="bottom-start"
                        mode="week"
                        selected={{
                          start: DateTime.fromObject(
                            {
                              weekNumber: filters.weekFrom,
                              weekYear: filters.yearFrom,
                            },
                            { zone: "utc" }
                          ).toISO(),
                          end: DateTime.fromObject(
                            {
                              weekNumber: filters.weekTo,
                              weekYear: filters.yearTo,
                            },
                            { zone: "utc" }
                          ).toISO(),
                        }}
                        onChange={(val) => {
                          const start = DateTime.fromISO(val.start, {
                            zone: "utc",
                          });
                          const end = DateTime.fromISO(val.end, {
                            zone: "utc",
                          });

                          setFilters((prev) => {
                            return {
                              ...prev,
                              weekFrom: start.weekNumber,
                              yearFrom: start.year,
                              weekTo: end.weekNumber,
                              yearTo: end.year,
                            };
                          });
                        }}
                        disabledDays={[
                          {
                            lt: DateTime.fromObject({
                              weekNumber: selectedCampaign?.weekFrom,
                              weekYear: selectedCampaign?.yearFrom,
                            })
                              .startOf("week")
                              .setZone("utc"),
                          },
                          {
                            gt: DateTime.fromObject({
                              weekNumber: selectedCampaign?.weekTo,
                              weekYear: selectedCampaign?.yearTo,
                            })
                              .endOf("week")
                              .setZone("utc"),
                          },
                        ]}
                      />
                    </Stack>
                  ),
                },
                {
                  size: "xl",
                  component: (
                    <Stack w="280px">
                      <Text fontSize="xs" fontWeight="semibold">
                        Engagement by:
                      </Text>
                      <ButtonGroup
                        selectedValue={selectedView}
                        setSelectedValue={(view) => {
                          navigate(
                            DisplayPerformanceScreenDefinition.navigate({
                              programmaticCampaignId: programmaticCampaignId,
                              view,
                            })
                          );
                        }}
                        items={[
                          {
                            label: "Accounts",
                            value: DisplayPerformanceView.Accounts,
                          },
                          {
                            label: "Campaign",
                            value: DisplayPerformanceView.CampaignEngagement,
                          },
                          {
                            label: "Creative",
                            value: DisplayPerformanceView.CreativeEngagement,
                          },
                        ]}
                      />
                    </Stack>
                  ),
                },
              ]}
              withMarginBottom={false}
            />
          )}
        </Box>
        <Box width={"full"} maxWidth="1640px">
          {!programmaticCampaignId ? (
            <NoEntitySelected entityName="campaign" />
          ) : (
            <>
              {!filters ? (
                <Loader />
              ) : (
                <ViewContainer>
                  {selectedView ===
                    DisplayPerformanceView.CampaignEngagement && (
                    <CampaignEngagement
                      campaignName={title}
                      filters={filters}
                      id={programmaticCampaignId}
                    />
                  )}

                  {selectedView === DisplayPerformanceView.Accounts && (
                    <AccountsPerformance
                      campaignName={title}
                      filters={filters}
                      id={programmaticCampaignId}
                      companyId={selectedCampaignOption?.companyId}
                    />
                  )}

                  {selectedView ===
                    DisplayPerformanceView.CreativeEngagement && (
                    <CreativeEngagement
                      filters={filters}
                      id={programmaticCampaignId}
                    />
                  )}
                </ViewContainer>
              )}
            </>
          )}
        </Box>
      </Flex>
    </ViewContainer>
  );
};

export { DisplayPerformance };
