import { InfoIcon } from "@chakra-ui/icons";
import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import {
  FirmographicsFilters,
  MAX_FIRMOGRAPHICS_ALLOWED,
} from "@intentsify/types";
import { isPopulatedArray, toNumberDisplayValue } from "@intentsify/utils";
import { Tooltip, ViewContainer } from "components";
import { defaultFirmoFilters } from "components/Firmographics/Firmographics.const";
import { Firmographics } from "components/FirmographicsNew";
import { Section } from "components/Section";
import { useState } from "react";
import { useUploadedFiles } from "screens/Tools/screens/TalPreparationTools/hooks/useUploadedFiles";
import { DomainsUploadTus } from "shared/components/DomainsUploadTus/DomainsUploadTus";
import { UploadedFileEntry } from "shared/components/UploadedFiles/UploadedFileEntry";
import { UploadedFiles } from "shared/components/UploadedFiles/UploadedFiles";
import { UploadedFilesWrapper } from "shared/components/UploadedFiles/UploadedFilesWrapper";
import { blocklistUpload } from "upload/blocklistUpload";
import { talToolsUpload } from "upload/talToolsUpload";
import { useScreen } from "utils";
import { DigitalForecastingScreenDefinition } from "./DigitalForecasting.definition";
import { Forecast } from "./components/Forecast";

const DigitalForecasting = () => {
  useScreen(DigitalForecastingScreenDefinition);

  const [firmographicsFilters, setFirmographicsFilters] =
    useState<FirmographicsFilters>(defaultFirmoFilters);

  const { files, filesForList, setFiles } = useUploadedFiles();

  const {
    filesForList: blocklistedFilesList,
    files: blocklistedFiles,
    setFiles: setBlocklistedFiles,
  } = useUploadedFiles();

  return (
    <ViewContainer>
      <Flex direction="column" gap="4">
        <Heading as="h2" size="sm">
          Step 1: Build an audience
        </Heading>

        <Box>
          <Section name="Upload a list">
            <DomainsUploadTus
              tusUpload={talToolsUpload}
              uploadedFileNames={files.map((i) => i.fileName)}
              onFileUpload={(tempFileName, fileName, validationResults) => {
                const newFile = {
                  tempFileName,
                  fileName,
                  accountsCount: validationResults.validRowCount,
                };

                setFiles((prev) => {
                  return [
                    ...prev.filter((file) => file.fileName !== fileName),
                    newFile,
                  ];
                });
              }}
            />

            {isPopulatedArray(filesForList) && (
              <UploadedFilesWrapper variant="plain">
                <UploadedFiles hideUploadedAt>
                  {filesForList.map((uploadedFileEntryProps) => (
                    <UploadedFileEntry
                      hideDownload
                      hideUploadedAt
                      key={uploadedFileEntryProps.file.fileName}
                      file={uploadedFileEntryProps.file}
                    />
                  ))}
                </UploadedFiles>
              </UploadedFilesWrapper>
            )}
          </Section>

          <Box zIndex={1} position={"relative"}>
            <Section
              name="Select from firmographics"
              tooltip={
                <Tooltip
                  aria-label="Select from firmographics"
                  label={`Select domains using firmographics. These domains can be used in addition to the uploaded file or, you can use firmographics exclusively. Selection is capped at ${toNumberDisplayValue(
                    MAX_FIRMOGRAPHICS_ALLOWED
                  )} domains.`}
                >
                  <InfoIcon w={4} h={4} ml={2} mt={-1} />
                </Tooltip>
              }
            >
              <Firmographics
                firmographicsFilters={firmographicsFilters}
                onFirmographicsFiltersChange={setFirmographicsFilters}
              />
            </Section>
          </Box>

          <Section name="Upload a blocklist">
            <DomainsUploadTus
              tusUpload={blocklistUpload}
              uploadedFileNames={blocklistedFiles.map((i) => i.fileName)}
              onFileUpload={(tempFileName, fileName, validationResults) => {
                const newFile = {
                  tempFileName,
                  fileName,
                  accountsCount: validationResults.validRowCount,
                };

                setBlocklistedFiles((prev) => {
                  return [
                    ...prev.filter((file) => file.fileName !== fileName),
                    newFile,
                  ];
                });
              }}
            />
            {isPopulatedArray(blocklistedFilesList) && (
              <UploadedFilesWrapper variant="plain">
                <UploadedFiles hideUploadedAt>
                  {blocklistedFilesList.map((uploadedFileEntryProps) => (
                    <UploadedFileEntry
                      hideDownload
                      hideUploadedAt
                      key={uploadedFileEntryProps.file.fileName}
                      file={uploadedFileEntryProps.file}
                    />
                  ))}
                </UploadedFiles>
              </UploadedFilesWrapper>
            )}
          </Section>
        </Box>

        <Box>
          <Heading as="h2" size="sm" mb={2}>
            Step 2: Complete Forecasting Details
          </Heading>

          <Text>Impressions Forecasting for Account and Device Targeting</Text>

          <Forecast
            files={files}
            blocklistedFiles={blocklistedFiles}
            firmographicsFilters={firmographicsFilters}
            onReset={() => {
              setFiles([]);
              setBlocklistedFiles([]);
              setFirmographicsFilters(defaultFirmoFilters);
            }}
          />
        </Box>
      </Flex>
    </ViewContainer>
  );
};

export { DigitalForecasting };
