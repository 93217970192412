import { ViewContainer } from "components";
import { Route, Routes } from "react-router";
import { useScreen } from "utils";
import { Completed } from "./Completed/Completed";
import { ContentLibraryDefinition } from "./ContentLibrary.definition";
import { Drafts } from "./Drafts/Drafts";
import { TopBar } from "./TopBar";

export const ContentLibrary = () => {
  useScreen(ContentLibraryDefinition);

  return (
    <>
      <TopBar />

      <ViewContainer>
        <Routes>
          <Route path="/completed" element={<Completed />} />
          <Route path="/drafts" element={<Drafts />} />
        </Routes>
      </ViewContainer>
    </>
  );
};
