import { AccessPath } from "@intentsify/authorization";

export const AuthRoot = "Authentication" as const;
export const CampaignsRoot = "Intent Models" as const;
export const CampaignPerformanceRoot = "Campaign Performance" as const;
export const IntentAnalysisRoot = "Intent Analysis" as const;
export const AdminRoot = "Admin" as const;
export const MediaActivationRoot = "Media Activation" as const;
export const ProgrammaticDomainsRoot = "Programmatic Domains" as const;
export const PublishersRoot = "Publishers" as const;
export const UsersRoot = "Users" as const;
export const CompaniesRoot = "Companies" as const;
export const BusinessEntitiesRoot = "Business Entities" as const;
export const SettingsRoot = "Settings" as const;
export const OptOutRoot = "OptOutRoot" as const;

export const LoginScreen = "Sign In" as const;
export const AgreementScreen = "Agreement" as const;
export const ResetPasswordScreen = "Reset Password" as const;
export const SetPasswordScreen = "Set Password" as const;
export const YourCampaignsScreen = "My Intent Models" as const;
export const ContentLibraryScreen = "Content Library" as const;
export const TargetPersonasScreen = "Target Personas" as const;
export const ToolsRoot = "Tools" as const;
export const TalPreparationToolsScreen = "TAL Preparation" as const;
export const CampaignDetailsScreen = "Intent Model Details" as const;
export const CreateCampaignScreen = "Create Intent Model" as const;
export const EditCampaignScreen = "Edit Intent Model" as const;
export const DeliveredLeadsScreen = "Delivered Leads" as const;
export const CompanyLeadPacingScreen = "Lead Pacing" as const;
export const DisplayPerformanceScreen = "Display Performance" as const;
export const LeadPacingScreen = "Lead Pacing" as const;
export const LeadPerformanceScreen = "Lead Performance" as const;
export const TrendingAccountsScreen = "Trending Accounts" as const;
export const TrendingTopicsAndKeywordsScreen =
  "Accounts Topics And Keywords" as const;
export const WeeklySnapshotScreen = "Weekly Snapshot" as const;
export const CompaniesScreen = "Companies" as const;
export const CreateCompanyScreen = "Create Company" as const;
export const EditCompanyScreen = "Edit Company" as const;
export const BusinessEntitiesScreen = "Business Entities" as const;
export const CreateBusinessEntityScreen = "Create Business Entity" as const;
export const EditBusinessEntityScreen = "Edit Business Entity" as const;
export const DigitalForecastingScreen = "Impression Forecasting" as const;
export const PageTrackingScreen = "Site Pixel Tracking" as const;
export const PublishersScreen = "Publishers" as const;
export const SinglePublisherScreen = "Publisher Details" as const;
export const UsersScreen = "Users" as const;
export const CloneUserScreen = "Clone User" as const;
export const CreateUserScreen = "Create User" as const;
export const EditUserScreen = "Edit User" as const;
export const ProgrammaticDomainsScreen = "Programmatic Segments" as const;
export const ProgrammaticDomainsFileDetailsScreen = "Details" as const;
export const ProgrammaticDomainsUploadScreen = "Upload" as const;
export const CreateAutomatedSegmentUploadScreen =
  "Create Automated Segment Upload" as const;
export const UserSettingsScreen = "User Settings" as const;
export const DownloadScreen = "Download" as const;
export const DownloadReportScreen = "Download PDF Report" as const;
export const OptOutDisplayScreen = "Opt Out Display" as const;
export const OptOutEditScreen = "Edit personal information" as const;
export const OptOutOfSaleOrSharingScreen = "Confirm Opt Out" as const;
export const OptOutOfProcessingScreen =
  "Confirm Opt Out of Processing" as const;
export const OptOutDelete = "Confirm Delete" as const;
export const DownloadTalToolsScreen = "Download Tal Tools" as const;
export const DownloadContentLibraryAssetScreen =
  "Download Content Library Asset" as const;
export const ImpersonationTransitionScreen =
  "Impersonation Transition" as const;
export const ContactSearchScreen = "Contact Search" as const;

type Root =
  | typeof AuthRoot
  | typeof CampaignsRoot
  | typeof CampaignPerformanceRoot
  | typeof IntentAnalysisRoot
  | typeof AdminRoot
  | typeof ToolsRoot
  | typeof MediaActivationRoot
  | typeof ProgrammaticDomainsRoot
  | typeof PublishersRoot
  | typeof UsersRoot
  | typeof CompaniesRoot
  | typeof BusinessEntitiesRoot
  | typeof SettingsRoot
  | typeof OptOutRoot;

export type Screen =
  | typeof YourCampaignsScreen
  | typeof ContentLibraryScreen
  | typeof CampaignDetailsScreen
  | typeof CreateCampaignScreen
  | typeof EditCampaignScreen
  | typeof DeliveredLeadsScreen
  | typeof LeadPacingScreen
  | typeof LeadPerformanceScreen
  | typeof CompanyLeadPacingScreen
  | typeof TrendingAccountsScreen
  | typeof TrendingTopicsAndKeywordsScreen
  | typeof WeeklySnapshotScreen
  | typeof CompaniesScreen
  | typeof CreateCompanyScreen
  | typeof EditCompanyScreen
  | typeof BusinessEntitiesScreen
  | typeof CreateBusinessEntityScreen
  | typeof EditBusinessEntityScreen
  | typeof DigitalForecastingScreen
  | typeof PageTrackingScreen
  | typeof PageTrackingScreen
  | typeof PublishersScreen
  | typeof SinglePublisherScreen
  | typeof UsersScreen
  | typeof CloneUserScreen
  | typeof CreateUserScreen
  | typeof EditUserScreen
  | typeof ProgrammaticDomainsScreen
  | typeof ProgrammaticDomainsFileDetailsScreen
  | typeof ProgrammaticDomainsUploadScreen
  | typeof CreateAutomatedSegmentUploadScreen
  | typeof LoginScreen
  | typeof AgreementScreen
  | typeof ResetPasswordScreen
  | typeof SetPasswordScreen
  | typeof UserSettingsScreen
  | typeof DownloadScreen
  | typeof DownloadReportScreen
  | typeof DisplayPerformanceScreen
  | typeof TargetPersonasScreen
  | typeof OptOutDisplayScreen
  | typeof OptOutEditScreen
  | typeof OptOutOfSaleOrSharingScreen
  | typeof OptOutOfProcessingScreen
  | typeof OptOutDelete
  | typeof TalPreparationToolsScreen
  | typeof DownloadTalToolsScreen
  | typeof ImpersonationTransitionScreen
  | typeof ContactSearchScreen
  | typeof DownloadContentLibraryAssetScreen;

export type UnsafeScreenDefinition<
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  T extends Record<string, string | number | undefined> = never
> = {
  name: Screen;
  path: string;
  routePath: string[];
};

export type ScreenDefinition<
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  T extends Record<string, string | number | undefined> = never
> = UnsafeScreenDefinition & {
  accessPath: AccessPath;
};

export type UnsafeScreenRoot<
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  T extends Record<string, string | number> = never
> = {
  name: Root;
  routePath: string[];
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type ScreenRoot<T extends Record<string, string | number> = never> =
  UnsafeScreenRoot & {
    accessPath: AccessPath;
  };
