import { Box, Flex } from "@chakra-ui/layout";
import { ErrorBoundary } from "components/ErrorBoundary";
import { LogoBoxed } from "components/Logo";
import { PropsWithChildren } from "react";
import { Link } from "react-router-dom";

export function PublicLayout(props: PropsWithChildren) {
  return (
    <ErrorBoundary>
      <Flex
        bg="white"
        _dark={{ bg: "brand.450" }}
        py="8"
        minH="100vh"
        flexDir="column"
        gap="20"
        pb="20vh"
      >
        <Flex w="full" maxWidth="lg" mx="auto" flexDir="column">
          <Box w="180px" ml="-8px">
            <Link to="https://intentsify.io" target="_blank">
              <LogoBoxed
                adaptToDarkMode
                position="relative"
                borderRadius="sm"
                overflow="hidden"
                _before={{
                  content: "''",
                  position: "absolute",
                  top: "0",
                  bottom: "0",
                  left: 0,
                  right: 0,
                  backgroundColor: "transparent",
                  mixBlendMode: "lighten",
                }}
                _hover={{
                  _before: { backgroundColor: "blackAlpha.500" },
                }}
                _dark={{
                  _hover: { _before: { backgroundColor: "whiteAlpha.200" } },
                }}
              />
            </Link>
          </Box>
        </Flex>

        {props.children}
      </Flex>
    </ErrorBoundary>
  );
}
