import { InfoIcon } from "@chakra-ui/icons";
import { Box, Flex, Text, useToast } from "@chakra-ui/react";
import {
  BlacklistedFirmographic,
  CampaignDomainSource,
  DomainStatus,
  DomainsData,
  Firmographic,
  FirmographicsFilters,
  MAX_FIRMOGRAPHICS_ALLOWED,
  PaginatedResponse,
} from "@intentsify/types";
import { isPopulatedArray } from "@intentsify/utils";
import { useQuery } from "@tanstack/react-query";
import { DataToCsvDownload } from "components/DataToCsvDownload";
import {
  Dispatch,
  SetStateAction,
  memo,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FiTrash2 } from "react-icons/fi";
import { useNavigate } from "react-router";
import { DomainsUploadLegacy } from "shared/components";
import { DomainsUploadScreenType } from "shared/components/DomainsUploadLegacy/DomainsUpload.types";
import { useComponentColors } from "theme";
import { handleApiError, useDeepEffect } from "utils";
import { Button } from "../Button";
import { IconButton } from "../IconButton";
import { Modal } from "../Modal";
import { Tooltip } from "../Tooltip";
import { defaultFirmoFilters, defaultFirmoParams } from "./Firmographics.const";
import { listPaginatedFirmographics } from "./Firmographics.requests";
import { filtersOrNull } from "./Firmographics.utils";
import { Filters } from "./components";

type FirmographicsProps = {
  /**
   * used as prefix for csv filename
   */
  campaignId?: number;
  subject?: string;
  selectedFirmographics?: Array<Firmographic | Partial<Firmographic>>;
  firmographicsFilters: FirmographicsFilters;
  setFirmographicsFilters: Dispatch<SetStateAction<FirmographicsFilters>>;
  setSelectedFirmographics: (data: Firmographic[]) => void;
  blacklisted: BlacklistedFirmographic[];
  onChangeBlacklisted: (data: BlacklistedFirmographic[]) => void;
  setIsLoadingFirmographics?: Dispatch<SetStateAction<boolean>>;
  onClearSelection?: () => void;
};

/**
 * @deprecated this component is still used by DigitalForecasting.
 * DigitalForecasting needs to be refactored and this removed.
 * We should do it like it's done in: DomainsForm/components/Firmographics/Firmographics.tsx
 */
const Firmographics = memo(
  ({
    campaignId,
    selectedFirmographics,
    subject,
    blacklisted,
    onChangeBlacklisted,
    firmographicsFilters,
    setFirmographicsFilters,
    setSelectedFirmographics,
    setIsLoadingFirmographics,
    onClearSelection,
  }: FirmographicsProps) => {
    const navigate = useNavigate();
    const toast = useToast();
    const [_, setTotal] = useState<number | undefined>();
    const [isBlacklistOpen, setIsBlacklistOpen] = useState<boolean>(false);
    const componentColors = useComponentColors();

    const hasAnyFilters = useMemo(() => {
      return Boolean(filtersOrNull(firmographicsFilters));
    }, [firmographicsFilters]);

    const { isFetching, refetch: fetchFirmos } = useQuery(
      ["firmographicsList", firmographicsFilters],
      () => {
        const promise = listPaginatedFirmographics()({
          ...defaultFirmoParams,
          filters: firmographicsFilters,
          per_page: hasAnyFilters ? MAX_FIRMOGRAPHICS_ALLOWED : 10,
          page: 1,
        });

        return promise;
      },
      {
        enabled: false,
        onSuccess: (data: PaginatedResponse<Firmographic>) => {
          if (hasAnyFilters) {
            setSelectedFirmographics(data.results);
          }

          setTotal(data.meta.total_items);
        },
        onError: (err) => {
          handleApiError(err, navigate, toast);
        },
      }
    );

    useDeepEffect(() => {
      setSelectedFirmographics([]);
    }, [firmographicsFilters]);

    useEffect(() => {
      if (setIsLoadingFirmographics) {
        setIsLoadingFirmographics(isFetching);
      }
    }, [isFetching, setIsLoadingFirmographics]);

    const firmographicsCsvFileName = subject
      ? `${subject} - firmographics.csv`
      : "Firmographics.csv";

    const blacklistedDomainsData: DomainsData = useMemo(() => {
      return {
        domains: blacklisted.map((b) => ({
          displayName: b.domain,
          domainId: 0,
          domainStatus: DomainStatus.Good,
          source: CampaignDomainSource.Uploaded,
        })),
        fileName: `blacklist${
          campaignId ? ` (${String(campaignId)})` : ""
        }.csv`,
      };
    }, [blacklisted, campaignId]);

    return (
      <>
        <Box w="100%">
          <Flex mb={4} w="100%">
            <Flex
              alignItems="center"
              w="100%"
              color={componentColors.form.formLabelColor}
            >
              <Text fontWeight="semibold">Select from firmographics</Text>
              <Tooltip
                aria-label="Select from firmographics"
                label={`Select domains using firmographics. These domains can be used in addition to the uploaded file or, you can use firmographics exclusively.`}
              >
                <InfoIcon w={4} h={4} ml={2} />
              </Tooltip>

              {subject && (
                <Button
                  onClick={() => setIsBlacklistOpen(!isBlacklistOpen)}
                  size="xs"
                  ml={4}
                >
                  Blocklist ({blacklisted.length})
                </Button>
              )}
            </Flex>

            {!subject && (
              <Flex w="100%" justifyContent="flex-end">
                <Button
                  onClick={() => setIsBlacklistOpen(!isBlacklistOpen)}
                  size="xs"
                  ml={4}
                >
                  Blocklist ({blacklisted.length})
                </Button>
              </Flex>
            )}

            {subject && (
              <>
                <Flex grow={1} />

                <DataToCsvDownload
                  fileName={firmographicsCsvFileName}
                  data={selectedFirmographics || []}
                  downloadLabel="Firmographics"
                  iconSize={"xs"}
                  withLabel
                  subject="domain"
                  subjectPlural="domains"
                  tooltipPlacement="bottom-end"
                />

                <Flex alignItems="center">
                  {onClearSelection && (
                    <IconButton
                      isDisabled={!isPopulatedArray(selectedFirmographics)}
                      ml={2}
                      variant="solid"
                      size="xs"
                      tooltip="Remove firmographics"
                      icon={<FiTrash2 />}
                      onClick={onClearSelection}
                      tooltipPlacement="bottom-end"
                    />
                  )}
                </Flex>
              </>
            )}
          </Flex>

          <Filters
            setFirmographicsFilters={(val) => {
              /**
               * If filters are empty (default) we want to store them as null to avoid
               * storing empty arrays in the database
               */
              const newFilters = filtersOrNull({
                ...firmographicsFilters,
                ...val,
              });

              if (!newFilters) {
                setSelectedFirmographics([]);
              }

              setFirmographicsFilters(newFilters || defaultFirmoFilters);
            }}
            filters={firmographicsFilters}
          />

          <Flex w="100%" justifyContent="flex-end">
            <Button
              isDisabled={isFetching || !hasAnyFilters}
              isLoading={isFetching}
              mb={4}
              variant="secondary"
              // Refetch in react-query does not return void
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={() => fetchFirmos()}
            >
              Process Domains Lookup
            </Button>
          </Flex>
        </Box>

        <Modal
          isOpen={isBlacklistOpen}
          size="5xl"
          title="Domains Blocklist"
          subtitle="Uploaded domains will be excluded from the firmographics selection."
          body={
            <DomainsUploadLegacy
              domainsData={blacklistedDomainsData}
              domainsUploadScreenType={
                DomainsUploadScreenType.FIRMOGRAPHICS_VIEW
              }
              onUploadedDomainsChange={(data) => {
                const blacklisted = data?.domains
                  ? data.domains.map((d) => ({
                      campaignId: campaignId ?? 0,
                      domain: d.displayName,
                    }))
                  : [];

                onChangeBlacklisted(blacklisted);
              }}
            />
          }
          onClose={() => setIsBlacklistOpen(false)}
          primaryButton={
            <Button onClick={() => setIsBlacklistOpen(false)}>Close</Button>
          }
        />
      </>
    );
  }
);

export { Firmographics };
