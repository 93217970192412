import { isPopulatedArray } from "@intentsify/utils";
import mapKeys from "lodash/mapKeys";
import startCase from "lodash/startCase";
import * as Papa from "papaparse";
import { useEffect, useState } from "react";

export type DownloadCsvLinkDataEntry = Record<
  string,
  string | number | undefined | null
>;

export const makeDownloadCsvLink = (
  data: DownloadCsvLinkDataEntry[]
): [string, Blob] => {
  const dataWithStartCaseKeys = data.map((item) =>
    mapKeys(item, (_, key) => startCase(key))
  );

  const csv = Papa.unparse(dataWithStartCaseKeys);
  const blob = new Blob([csv], { type: "text/plain" });

  return [window.URL.createObjectURL(blob), blob];
};

export const useDownloadCsvLink = (
  data?: DownloadCsvLinkDataEntry[]
): [string, number] => {
  const [downloadLink, setDownloadLink] = useState("");
  const [blobSize, setBlobSize] = useState(0);

  useEffect(() => {
    if (!isPopulatedArray(data)) {
      return;
    }

    const [downloadLink, blob] = makeDownloadCsvLink(data);

    setDownloadLink(downloadLink);
    setBlobSize(blob.size);
  }, [data]);

  return [downloadLink, blobSize];
};
