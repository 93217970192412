import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  Icon,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { RiFilterFill, RiFilterLine } from "react-icons/ri";

type FiltersContainerProps = {
  children: React.ReactNode;
  title: string;
  areFiltersApplied?: boolean;
  resultsCount?: number;
  isLoadingResults?: boolean;
};

const FiltersContainer = ({
  children,
  title,
  resultsCount,
  areFiltersApplied,
  isLoadingResults,
}: FiltersContainerProps) => {
  return (
    <Accordion width="100%" mt={4} mb={4} allowToggle>
      <AccordionItem>
        <AccordionButton fontSize={"sm"}>
          <Flex w={"100%"} alignItems={"center"}>
            <Flex alignItems="center" gap={2}>
              <Icon
                width={"32px"}
                height={"32px"}
                as={areFiltersApplied ? RiFilterFill : RiFilterLine}
                color={"teal.500"}
              />
              <Text fontWeight={"semibold"}>{title}</Text>
            </Flex>
            <Flex flex={1} />
            <Text fontWeight={"semibold"}>
              {isLoadingResults ? (
                <Spinner mr={2} size={"sm"} />
              ) : (
                <>Results count: {resultsCount}</>
              )}
            </Text>
            <AccordionIcon width={"32px"} height={"32px"} />
          </Flex>
        </AccordionButton>
        <AccordionPanel>
          <Flex direction="column" gap={4}>
            {children}
          </Flex>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export { FiltersContainer };
