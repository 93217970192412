import {
  MostResearchedTopicsRO,
  WeeklySnapshotRangeDTO,
} from "@intentsify/dto";
import { apiService, Endpoints } from "api";
import { TopicScoreModel } from "@intentsify/types";

export const getMostResearchedTopics = async (
  campaignId: number,
  filters: WeeklySnapshotRangeDTO,
  scoreModel?: TopicScoreModel,
  limit?: number
) => {
  const { data } = await apiService.get<MostResearchedTopicsRO[]>(
    Endpoints.Campaigns.Get.WeeklySnapshot.MostResearchedTopics(campaignId),
    {
      params: {
        ...filters,
        scoreModel,
        limit,
      },
    }
  );

  return data;
};
