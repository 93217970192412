import { SignalSelectionSource } from "@intentsify/types";
import { isDefined } from "@intentsify/utils";
import { useEffect } from "react";
import { useKeywordsAndTopicsState } from "screens/Campaigns/screens/CampaignsWizard/components/KeywordsAndTopicsStep/KeywordsAndTopics.hooks";
import { ResultsTableEntry } from "../ResultsTable.data";
import { ResultsTableTab } from "../ResultsTable.tabs";

type ResultsTableEntryWithoutScore = Omit<ResultsTableEntry, "score">;

const getSelectedEntries = (
  entries: ResultsTableEntryWithoutScore[],
  selectedEntries: ResultsTableEntryWithoutScore[]
): Record<number, boolean> => {
  const unsafeEntryItems = entries.map((entry, index) => {
    const isSelected = selectedEntries
      .map((selectedEntry) => selectedEntry.name.toLowerCase())
      .includes(entry.name.toLowerCase());

    if (!isSelected) {
      return;
    }

    return [index, true];
  });

  return Object.fromEntries(unsafeEntryItems.filter(isDefined));
};

interface UseSyncSelectionFromRecoilOptions {
  tab: ResultsTableTab;
  onSync: (rowSelection: Record<number, boolean>) => void;
}

export const useSyncSelectionFromRecoil = (
  options: UseSyncSelectionFromRecoilOptions
) => {
  const { state } = useKeywordsAndTopicsState();

  useEffect(() => {
    if (options.tab === ResultsTableTab.Keywords) {
      const resultsTableEntries =
        state.autodiscoverySettings.autodiscoveryKeywords.map((k) => ({
          name: k.keyword,
        }));

      const selectedResultsTableEntries = state.selectedKeywords
        .filter(
          (k) => k.metaData?.source === SignalSelectionSource.Autodiscovery
        )
        .map((k) => ({ name: k.label }));

      options.onSync(
        getSelectedEntries(resultsTableEntries, selectedResultsTableEntries)
      );
    }

    if (options.tab === ResultsTableTab.Topics) {
      const resultsTableEntries =
        state.autodiscoverySettings.autodiscoveryTopics.map((t) => ({
          name: t.topic,
        }));

      const selectedResultsTableEntries = state.selectedTopics
        .filter((t) => t.source === SignalSelectionSource.Autodiscovery)
        .map((t) => ({ name: t.topic }));

      options.onSync(
        getSelectedEntries(resultsTableEntries, selectedResultsTableEntries)
      );
    }

    if (options.tab === ResultsTableTab.Products) {
      const resultsTableEntries =
        state.autodiscoverySettings.autodiscoveryProducts.map((p) => ({
          name: p.topic,
        }));

      const selectedResultsTableEntries = state.selectedTopics
        .filter((t) => t.source === SignalSelectionSource.Autodiscovery)
        .map((t) => ({ name: t.topic }));

      options.onSync(
        getSelectedEntries(resultsTableEntries, selectedResultsTableEntries)
      );
    }
    // Disabling rule to avoid the need to pass options memoised
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    options.tab,
    options.onSync,
    state.autodiscoverySettings.autodiscoveryKeywords,
    state.autodiscoverySettings.autodiscoveryProducts,
    state.autodiscoverySettings.autodiscoveryTopics,
    state.selectedKeywords,
    state.selectedTopics,
  ]);
};
