import { atomFamily } from "recoil";
import { PieChartItem } from "./PieChart.types";

export const legendHoverAtomFamily = atomFamily<
  PieChartItem | undefined,
  string
>({
  key: "pieLegendHoverAtom",
  default: undefined,
});

export const disabledLegendItemsFamily = atomFamily<
  Array<PieChartItem>,
  string
>({
  key: "pieDisabledLegendItems",
  default: [],
});
