import { Flex, useDisclosure, VStack } from "@chakra-ui/react";
import { GetFiltersRO } from "@intentsify/dto";
import {
  AudienceSegmentDefinition,
  AudienceSegmentOption,
  defaultAudienceSegmentDefinition,
} from "@intentsify/types";
import { Button, Select } from "components";
import { useState } from "react";
import { FiTrash2 } from "react-icons/fi";
import { useListAudienceSegments } from "./AudienceSegments.requests";
import { AudienceSegmentsForm } from "./components/AudienceSegmentsForm";
import { DeleteAudienceSegmentsModal } from "./components/DeleteAudienceSegmentsModal/DeleteAudienceSegmentsModal";

type AudienceSegmentsProps = {
  filtersOptions: GetFiltersRO;
  companyId: number;
  onApplyAudienceSegment: (value: AudienceSegmentDefinition) => void;
};

const AudienceSegments = ({
  filtersOptions,
  companyId,
  onApplyAudienceSegment,
}: AudienceSegmentsProps) => {
  const audienceSegments = useListAudienceSegments(companyId);

  const [selectedAudienceSegment, setSelectedAudienceSegment] =
    useState<AudienceSegmentOption | null>(null);
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <VStack p={2}>
      <Flex
        alignItems={"center"}
        justifyContent={"flex-end"}
        gap={4}
        width={"100%"}
      >
        <Select
          placeholder="Select Segment"
          value={selectedAudienceSegment || null}
          options={audienceSegments.data ?? []}
          isMulti={false}
          onChange={(value) => setSelectedAudienceSegment(value)}
        />
        <Flex grow={1} />
        <Button
          size="sm"
          variant="warning"
          leftIcon={<FiTrash2 />}
          onClick={onOpen}
          isDisabled={!selectedAudienceSegment}
        >
          Delete
        </Button>
      </Flex>

      <AudienceSegmentsForm
        companyId={companyId}
        selectedAudienceSegmentId={selectedAudienceSegment?.value || null}
        onCreate={(newAudienceSegment) =>
          setSelectedAudienceSegment(newAudienceSegment)
        }
        onApplyAudienceSegment={onApplyAudienceSegment}
        filtersOptions={filtersOptions}
        initialValues={{
          ...defaultAudienceSegmentDefinition,
          ...(selectedAudienceSegment?.meta?.definition ?? {}),
        }}
      />

      <DeleteAudienceSegmentsModal
        isOpen={isOpen}
        onClose={onClose}
        companyId={companyId}
        id={Number(selectedAudienceSegment?.value)}
        isDisabled={!selectedAudienceSegment}
        onDelete={() => {
          setSelectedAudienceSegment(null);
          audienceSegments.refetch();
        }}
      />
    </VStack>
  );
};

export { AudienceSegments };
