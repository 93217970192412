import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  chakra,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, WeekPicker } from "components";
import { DateTime } from "luxon";
import { useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useCampaignBrief } from "shared/components/campaign/Campaign.state";
import { useComponentColors } from "theme";
import { z } from "zod";

export function RequestProgramReviewForm(props: {
  campaignId: number;
  isLoading?: boolean;
  onSubmit: (params: { selectedDate: string }) => void;
}) {
  const today = DateTime.now();
  const componentColors = useComponentColors();
  const { campaignBrief, campaignBriefIsLoading } = useCampaignBrief(
    props.campaignId
  );

  const RequestProgramReviewData = useMemo(() => {
    return z.object({
      selectedDate: z
        .string()
        .refine((date) => DateTime.fromISO(date) <= today.endOf("week"), {
          message: "The selected week cannot be from the future.",
        }),
    });
  }, [today]);

  type RequestProgramReviewData = z.infer<typeof RequestProgramReviewData>;

  const selectedDate = useMemo(() => {
    const defaultDate = today.minus({ week: 1 }).toISO();

    if (!campaignBrief?.campaignEndDate) {
      return defaultDate;
    }

    return campaignBrief?.campaignEndDate > defaultDate
      ? defaultDate
      : campaignBrief?.campaignEndDate;
  }, [campaignBrief?.campaignEndDate, today]);

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<RequestProgramReviewData>({
    mode: "onChange",
    resolver: zodResolver(RequestProgramReviewData),
    defaultValues: { selectedDate },
  });

  useEffect(() => {
    reset({ selectedDate });
  }, [campaignBrief?.campaignEndDate, reset, selectedDate]);

  return (
    <chakra.form
      w="100%"
      onSubmit={(data) => {
        handleSubmit(props.onSubmit)(data);
      }}
    >
      <Box mb={4}>
        <FormControl isInvalid={!!errors?.selectedDate?.message} isRequired>
          <FormLabel
            fontSize="sm"
            fontWeight="md"
            color={componentColors.form.formLabelColor}
          >
            Week
          </FormLabel>

          <Controller
            control={control}
            name="selectedDate"
            render={({ field: { onChange, value } }) => (
              <WeekPicker
                isLoading={campaignBriefIsLoading}
                selected={value}
                onChange={({ weekNumber, yearNumber }) =>
                  onChange(
                    DateTime.fromObject({
                      weekNumber,
                      weekYear: yearNumber,
                    }).toISO()
                  )
                }
              />
            )}
          />
          <FormErrorMessage>{errors?.selectedDate?.message}</FormErrorMessage>
        </FormControl>
      </Box>

      <Flex justifyContent="flex-end">
        <Button
          variant="primary-teal"
          type="submit"
          isLoading={props.isLoading}
          isDisabled={campaignBriefIsLoading}
        >
          Submit
        </Button>
      </Flex>
    </chakra.form>
  );
}
