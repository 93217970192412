import { PageTrackingScreen } from "types";
import { createScreen } from "utils/createScreen";

const PageTrackingScreenDefinition = createScreen({
  name: PageTrackingScreen,
  path: "/media-activation/site-pixel-tracking",
  routePath: ["/site-pixel-tracking"],
  accessPath: "pageTracking",
});

export { PageTrackingScreenDefinition };
