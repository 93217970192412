import { useColorModeValue } from "@chakra-ui/color-mode";
import { Box, Flex } from "@chakra-ui/layout";
import { useTheme } from "@chakra-ui/system";
import { Line } from "@visx/shape";
import { useRecoilState, useSetRecoilState } from "recoil";
import { getLegendDirection } from "../../Charts.utils";
import { TimeseriesProps } from "../Timeseries";
import { useTimeseriesData } from "../Timeseries.hooks";
import {
  disabledDataKeysFamily,
  legendDataSourceHoverFamily,
} from "../Timeseries.state";

type DataSourcesProps = Pick<
  TimeseriesProps<string, string>,
  "data" | "timeseries" | "dataKeyLabels" | "legendPosition"
> & {
  uniqueKey: string;
};

const DataSources = ({
  data,
  timeseries,
  uniqueKey,
  dataKeyLabels,
  legendPosition,
}: DataSourcesProps) => {
  const chakraTheme = useTheme();
  const { dataKeys, lineStyles } = useTimeseriesData(
    data,
    timeseries,
    uniqueKey
  );

  const setHoverDataSource = useSetRecoilState(
    legendDataSourceHoverFamily(uniqueKey)
  );

  const [disabledDataKeys, setDisabledDataSource] = useRecoilState(
    disabledDataKeysFamily(uniqueKey)
  );

  const legendItemHoverBg = useColorModeValue(
    "blackAlpha.100",
    "whiteAlpha.100"
  );

  if (dataKeys.length < 2) {
    return null;
  }

  return (
    <Flex mb={2} flexDirection={getLegendDirection(legendPosition)}>
      {dataKeys
        .map((k) =>
          dataKeyLabels
            ? { label: dataKeyLabels[k], key: k }
            : { label: k, key: k }
        )
        .map((item) => {
          const style = lineStyles.find((s) => s.dateKey === item.key)?.style;

          const isDisabled = disabledDataKeys.includes(item.key);

          return (
            <Flex
              key={item.key}
              alignItems="center"
              borderRadius={4}
              _hover={{ background: legendItemHoverBg }}
              px={3}
              py={1}
              cursor="pointer"
              opacity={isDisabled ? 0.2 : 1}
              onClick={() => {
                if (!isDisabled) {
                  setDisabledDataSource([...disabledDataKeys, item.key]);
                } else {
                  setDisabledDataSource(
                    disabledDataKeys.filter((key) => key !== item.key)
                  );
                }
              }}
              onMouseOver={() => {
                setHoverDataSource(item.key);
              }}
              onMouseLeave={() => {
                setHoverDataSource(undefined);
              }}
            >
              <Flex mr={2} alignItems="center">
                <svg width={30} height={4}>
                  <Line
                    style={{
                      strokeWidth: "5px",
                      stroke: chakraTheme.colors.gray[400],
                      ...(style ? style : undefined),
                    }}
                    to={{ x: 30, y: 0 }}
                  />
                </svg>
              </Flex>
              <Box whiteSpace="nowrap" fontSize="medium">
                {item.label}
              </Box>
            </Flex>
          );
        })}
    </Flex>
  );
};

export { DataSources };
