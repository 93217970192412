import { Text } from "@chakra-ui/layout";
import { Button } from "components";
import { HttpStatus } from "types";
import { CustomApiError, formatApiError } from "utils";

interface UploadKeywordsValidationErrorProps {
  error: CustomApiError;
  onProceedAnyway: () => void;
}

export const UploadKeywordsValidationError = ({
  error,
  onProceedAnyway,
}: UploadKeywordsValidationErrorProps) => {
  return (
    <>
      {formatApiError(
        new CustomApiError("Keyword validation error", {
          statusCode: HttpStatus.BAD_REQUEST,
          message: error.response.message,
        }),
        "There was an error while uploading the file. Please verify its correctness."
      )}

      <Text mt={3} mb={2} textAlign="left" fontWeight="500">
        If you want to ignore invalid keywords and proceed anyway,{" "}
        <Button onClick={onProceedAnyway} variant="link" size="md">
          click here
        </Button>
        . Invalid keywords will be disregarded.
      </Text>
    </>
  );
};
