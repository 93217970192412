import {
  CampaignTopic,
  NodeDefinition,
  NodeValue,
  SignalSelectionSource,
} from "@intentsify/types";
import { getLeafNodes } from "@intentsify/utils";
import { NoData } from "components";
import { Tree, useTree } from "components/Tree";
import { useCallback } from "react";
import { SelectionMode } from "types/Keyword";
import {
  useKeywordsAndTopicsQueries,
  useKeywordsAndTopicsState,
} from "../../../KeywordsAndTopics.hooks";

export const ManualSelectionTopics = () => {
  const { topics } = useKeywordsAndTopicsQueries();
  const { state, actions } = useKeywordsAndTopicsState();
  const tree = useTree({
    expanded: [],
    checked: state.campaignKeywordsAndTopics.topics.map(
      (t: CampaignTopic) => t.topicId
    ),
  });

  const onCheck = useCallback(
    (_: NodeValue[], checkedNode?: NodeDefinition) => {
      const topicsToSelect = state.selectedTopics.filter(
        (t) =>
          t.topicId === checkedNode?.value &&
          t.source === SignalSelectionSource.Manual
      );

      if (checkedNode) {
        const addedTopics = getLeafNodes([checkedNode]).map((node) => ({
          topic: node.label,
          topicId: Number(node.value),
          source: SignalSelectionSource.Manual,
        }));

        topicsToSelect.push(...addedTopics);
      }

      actions.setSelectedTopics(
        topicsToSelect,
        checkedNode?.checked ? SelectionMode.SELECT : SelectionMode.UNSELECT
      );
    },
    [actions, state.selectedTopics]
  );

  if (topics.aggregated.length === 0) {
    return <NoData />;
  }

  return (
    <Tree
      subject="topics"
      nodes={topics.aggregated}
      expanded={tree.expandedValues}
      checked={state.selectedTopics
        .filter((t) => t.source === SignalSelectionSource.Manual)
        .map((t) => Number(t.topicId))}
      onCheck={onCheck}
      onReset={() =>
        actions.setSelectedTopics(
          state.selectedTopics.filter(
            (t) => t.source === SignalSelectionSource.Manual
          ),
          SelectionMode.UNSELECT
        )
      }
      onExpand={(expanded) => tree.setExpandedValues(expanded)}
    />
  );
};
