import { CreateCampaignScreen } from "types";
import { createScreen } from "utils/createScreen";

const CreateCampaignScreenDefinition = createScreen({
  name: CreateCampaignScreen,
  path: `/intent-models/create/:step/:campaignId`,
  routePath: ["/create/:step", "/create/:step/:campaignId"],
  accessPath: "campaign.create",
});

export { CreateCampaignScreenDefinition };
