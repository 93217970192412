import {
  UseQueryOptions,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { AsyncReturnType } from "type-fest";
import { Duration } from "luxon";
import { Endpoints, apiService } from "api";

type UserAgencyCompanyRO = {
  companyId: number;
  companyDisplayName: string;
  dataPackageId: number;
  dataPackageName: string;
};

const queryKey = ["userAgencyCompany"] as const;

const getUserAgencyCompany = async () => {
  const { data } = await apiService.get<UserAgencyCompanyRO>(
    Endpoints.UserAgencyCompany.Get
  );

  return data;
};

type UseUserAgencyCompanyQueryOptions = UseQueryOptions<
  AsyncReturnType<typeof getUserAgencyCompany>
>;

export const useInvalidateUserAgencyCompany = () => {
  const queryClient = useQueryClient();

  return () => {
    queryClient.invalidateQueries({ queryKey });
  };
};

export const useUserAgencyCompanyConfig = (
  queryOptions?: UseUserAgencyCompanyQueryOptions
) => {
  return {
    queryKey,
    queryFn: getUserAgencyCompany,
    staleTime: Duration.fromObject({ minutes: 10 }).as("millisecond"),
    ...queryOptions,
  } satisfies UseUserAgencyCompanyQueryOptions;
};

export const useUserAgencyCompany = (
  queryOptions?: UseUserAgencyCompanyQueryOptions
) => {
  return useQuery(useUserAgencyCompanyConfig(queryOptions));
};
