import { Button, Text } from "@chakra-ui/react";
import { Modal } from "components";
import { useDeleteAudienceSegment } from "../../AudienceSegments.requests";

type DeleteAudienceSegmentsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  companyId: number;
  id: number;
  isDisabled: boolean;
  onDelete: () => void;
};

const DeleteAudienceSegmentsModal = ({
  isOpen,
  onClose,
  companyId,
  id,
  isDisabled,
  onDelete,
}: DeleteAudienceSegmentsModalProps) => {
  const { mutate } = useDeleteAudienceSegment(companyId, id, onDelete);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Delete Segment"
      body={<Text>{"Are you sure you want to delete this segment?"}</Text>}
      primaryButton={
        <Button
          colorScheme="red"
          onClick={() => {
            mutate();
            onClose();
          }}
          isDisabled={isDisabled}
        >
          {"Delete"}
        </Button>
      }
      secondaryButton={
        <Button variant="outline" onClick={onClose} isDisabled={isDisabled}>
          {"Cancel"}
        </Button>
      }
    />
  );
};

export { DeleteAudienceSegmentsModal };
