import { Checkbox } from "@chakra-ui/react";
import { HTMLProps, useEffect, useRef } from "react";

export const IndeterminateCheckbox = ({
  indeterminate,
  className = "",
  checked,
  onChange,
  isDisabled = false,
}: {
  indeterminate?: boolean;
  isDisabled?: boolean;
} & HTMLProps<HTMLInputElement>) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const ref = useRef<HTMLInputElement>(null!);

  useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !checked && indeterminate;
    }
  }, [ref, indeterminate, checked]);

  return (
    <Checkbox
      isChecked={checked}
      ref={ref}
      className={className + " cursor-pointer"}
      isIndeterminate={indeterminate}
      onChange={onChange}
      isDisabled={isDisabled}
    />
  );
};
