import { ListItem, UnorderedList } from "@chakra-ui/react";
import React, { useMemo } from "react";
import groupBy from "lodash/groupBy";
import { TextWithTooltip } from "components";

interface TopLocationsProps<Location extends { location: string }> {
  locations: Location[];
  groupBy: Exclude<keyof Location, "location">;
  renderTooltip: (locations: Location[]) => React.ReactNode;
  renderItem?: (location: Location) => React.ReactNode;
}
export const TopLocations = <Location extends { location: string }>({
  locations,
  groupBy: groupByField,
  renderTooltip,
  renderItem,
}: TopLocationsProps<Location>) => {
  const groupedLocations = useMemo(
    () =>
      Object.entries(groupBy(locations, groupByField)).map(
        ([location, records]) => ({
          location,
          records,
        })
      ),
    [locations, groupByField]
  );
  return (
    <UnorderedList>
      {groupedLocations.map(({ location, records }) => (
        <ListItem key={location} mb={1}>
          <TextWithTooltip renderTooltip={() => renderTooltip(records)}>
            {renderItem
              ? renderItem(
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  locations.find(
                    (originalLocation) =>
                      originalLocation[groupByField] === location
                  )!
                )
              : location}
          </TextWithTooltip>
        </ListItem>
      ))}
    </UnorderedList>
  );
};
