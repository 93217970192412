import {
  NotificationSetting,
  NotificationTransportMethods,
  NotificationTypes,
} from "@intentsify/types";
import { apiService, Endpoints } from "api";

export const getUserNotificationSettings = async (userId: number) => {
  const { data } = await apiService.get<NotificationSetting[]>(
    Endpoints.Users.Get.Settings.Notifications(userId)
  );

  return data;
};

export const setUserNotificationSetting = async ({
  userId,
  notificationType,
  disableNotification,
  transportMethod,
}: {
  userId: number;
  notificationType: NotificationTypes;
  disableNotification: boolean;
  transportMethod: NotificationTransportMethods;
}) => {
  await apiService.post<NotificationSetting[]>(
    Endpoints.Users.Post.Settings.Notifications(userId),
    { notificationType, disableNotification, transportMethod }
  );
};
