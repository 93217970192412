import { CreateCompanyScreen } from "types";
import { createScreen } from "utils/createScreen";

const CreateCompanyScreenDefinition = createScreen({
  name: CreateCompanyScreen,
  path: "/admin/companies/create",
  routePath: ["/create"],
  accessPath: "company.create",
});

export { CreateCompanyScreenDefinition };
