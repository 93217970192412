import { ScaleLinear, ScaleTime } from "d3-scale";
import { useRecoilValue } from "recoil";
import { TimeseriesProps } from "../Timeseries";
import { useStrokeColor, useTimeseriesData } from "../Timeseries.hooks";
import {
  disabledDataKeysFamily,
  disabledLegendItemsFamily,
} from "../Timeseries.state";
import { Line } from "./Line";
import { TimeseriesItem, TimeseriesItemDataRecord } from "types";

type LinesProps<K extends string, T extends string> = {
  uniqueKey: string;
  data: TimeseriesItem<K, TimeseriesItemDataRecord<T>>[];
  timeScale: ScaleTime<number, number, never> | undefined;
  valuesScale: ScaleLinear<number, number, never> | undefined;
  showTooltip: (args: {
    tooltipLeft: number;
    tooltipTop: number;
    tooltipData: TimeseriesItem<string, TimeseriesItemDataRecord<string>>;
  }) => void;
  hideTooltip: () => void;
  chartColors: string[];
  timeseries: TimeseriesProps<K, T>["timeseries"];
  snapToDateTime: boolean;
  fixedTooltip: boolean;
  margins: TimeseriesProps<K, T>["margins"];
};

const Lines = <K extends string, T extends string>({
  data,
  uniqueKey,
  timeScale,
  valuesScale,
  showTooltip,
  hideTooltip,
  chartColors,
  timeseries,
  snapToDateTime,
  fixedTooltip,
  margins,
}: LinesProps<K, T>) => {
  const color = useStrokeColor(uniqueKey);

  const disabledLegendItems = useRecoilValue(
    disabledLegendItemsFamily(uniqueKey)
  );

  const disabledDataKeys = useRecoilValue(disabledDataKeysFamily(uniqueKey));

  const { dataKeys, lineStyles } = useTimeseriesData(
    data,
    timeseries,
    uniqueKey
  );

  return (
    <>
      {data.map((item, i) => {
        return dataKeys.map((dataKey) => {
          const isDisabled =
            disabledLegendItems.find(
              (i) => i.timeseriesItemId === item.timeseriesItemId
            ) || disabledDataKeys.includes(dataKey);

          const strokeColor = color(item, dataKey, chartColors, i);

          if (isDisabled) {
            return null;
          }

          return (
            <Line
              data={data}
              style={lineStyles.find((s) => s.dateKey === dataKey)?.style}
              key={i}
              item={item}
              uniqueKey={uniqueKey}
              strokeColor={strokeColor}
              dataKey={dataKey}
              timeScale={timeScale}
              valuesScale={valuesScale}
              showTooltip={showTooltip}
              hideTooltip={hideTooltip}
              timeseries={timeseries}
              snapToDateTime={snapToDateTime}
              fixedTooltip={fixedTooltip}
              margins={margins}
            />
          );
        });
      })}
    </>
  );
};

export { Lines };
