import {
  ProgrammaticDomainFileForList,
  SortDirection,
} from "@intentsify/types";
import { ListProgrammaticDomainFilesRO } from "@intentsify/dto";
import { apiService, Endpoints } from "api";
import { FetchDataParamsWithToken } from "types";

export const defaultListProgrammaticDomainFilesParams: FetchDataParamsWithToken<
  keyof ProgrammaticDomainFileForList
> = {
  pageToken: "",
  order_by: "programmaticDomainsFileId",
  order: SortDirection.DESC,
};

export const listProgrammaticDomainFiles = async (
  params: FetchDataParamsWithToken<
    keyof ProgrammaticDomainFileForList
  > = defaultListProgrammaticDomainFilesParams
): Promise<ListProgrammaticDomainFilesRO> => {
  const { data } = await apiService.get(
    Endpoints.ProgrammaticDomains.Get.Paginated,
    {
      params,
    }
  );
  return data;
};
