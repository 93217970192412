import { Text } from "@chakra-ui/layout";
import { Checkbox } from "@chakra-ui/react";
import { Search } from "components";
import { useState } from "react";
import { useTrackSearched } from "tracking/useTrackSearched";

export const useSearch = () => {
  const [searchValue, setSearchValue] = useState("");
  const trackSearched = useTrackSearched();

  return {
    value: searchValue,
    Input: () => (
      <Search
        mb={0}
        currentValue={searchValue}
        onSearch={(value: string) => {
          setSearchValue(value);
          trackSearched({ term: value, collocation: "Drafts assets table" });
        }}
      />
    ),
  };
};

export const useShowCurrentUserDrafts = () => {
  const [showCurrentUserDrafts, setShowCurrentUserDrafts] = useState(false);

  return {
    value: showCurrentUserDrafts,
    Input: () => (
      <Checkbox
        isChecked={showCurrentUserDrafts}
        onChange={(e) => setShowCurrentUserDrafts(e.target.checked)}
      >
        <Text fontSize="sm">Show only my drafts</Text>
      </Checkbox>
    ),
  };
};
