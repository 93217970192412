import { AxiosRequestConfig } from "axios";
import { ApiError } from "types";

/**
 * CustomApiError class allows us to throw an Error enriched with request/response meta data.
 *
 * It works like this:
 * - CustomApiError is created in the Axios interceptor and passed to react-query
 * - useQuery throws it into ErrorBoundary
 *  - CustomApiError reaches the console as an unhandled exception
 *  - Sentry picks it up from the console, ExtraErrorDataIntegration extracts the meta data from the error
 * - useMutation do not throw into ErrorBoundary, so Sentry reporting is done in handleApiError()
 */
export class CustomApiError extends Error {
  readonly response: ApiError;
  readonly method: string | undefined;
  readonly params: unknown;
  readonly url: string | undefined;
  readonly data: unknown;

  constructor(
    message: string,
    apiError: ApiError,
    config?: AxiosRequestConfig | undefined,
    url?: string
  ) {
    super(message);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, CustomApiError);
    }

    this.name = "ClientRequestError";
    this.response = apiError;
    this.params = config?.params;
    this.url = url;
    this.method = config?.method;
    this.data = config?.data;
  }
}
