import { UseToastOptions } from "@chakra-ui/react";

export const sessionExpiredToastOptions = {
  duration: 15000,
  title: "Session expired",
  status: "warning",
  isClosable: true,
  description:
    "Due to security reasons, you've been logged out. Please log in to continue using Intentsify.",
} satisfies UseToastOptions;
