import * as Papa from "papaparse";
import mapKeys from "lodash/mapKeys";
import { useEffect, useState } from "react";
import startCase from "lodash/startCase";

type DataEntry = Record<string, string | number | undefined | null>;

export const useDownloadCsvLink = (data?: DataEntry[]): [string, number] => {
  const [downloadLink, setDownloadLink] = useState("");
  const [blobSize, setBlobSize] = useState(0);

  useEffect(() => {
    const dataWithUppercaseKeys = (data || []).map((item) =>
      mapKeys(item, (_, key) => startCase(key))
    );

    const csv = Papa.unparse(dataWithUppercaseKeys);
    const blob = new Blob([csv], { type: "text/plain" });

    setBlobSize(blob.size);
    setDownloadLink(window.URL.createObjectURL(blob));
  }, [data]);

  return [downloadLink, blobSize];
};
