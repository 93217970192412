/* eslint-disable @typescript-eslint/no-unsafe-argument */
import {
  Table as ChakraTable,
  Flex,
  LayoutProps,
  Tbody,
  Text,
  Thead,
} from "@chakra-ui/react";
import { ChakraProps } from "@chakra-ui/system";
import { Meta } from "@intentsify/types";
import { ErrorBoundary } from "@sentry/react";
import {
  ColumnDef,
  ExpandedState,
  PaginationState,
  RowSelectionState,
  Row as RowType,
  SortingState,
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Button } from "components";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { FetchDataParams } from "types";
import { useDeepEffect } from "utils";
import { Loader } from "../../Loader";
import { NoData } from "../../NoData";
import { HeaderGroup, Pagination, Row, TokenPagination } from "../components";
import { PaginationType } from "./Table.types";
import {
  getFetchDataParams,
  getTablePaginationParams,
  getTokenFetchDataParams,
} from "./Table.utils";
import { useTokenPagination } from "./hooks";

type TableProps<T extends Record<string, unknown>> = {
  data: T[];
  meta?: Meta; // Meta is undefined while request is not fulfilled.
  columns: ColumnDef<T>[];
  searchValue?: string;
  noDataMessage?: ReactNode;
  customNoData?: ReactNode;
  showNoData?: boolean;

  isFetching?: boolean;
  isScrollable?: boolean;
  isExpandable?: boolean;
  paginationType?: PaginationType;
  nextPageToken?: string;
  stretch?: boolean;
  bg?: string;
  overflow?: LayoutProps["overflow"];
  maxWidth?: string;
  variant?: "intentsifyPlain" | "intentsifyClean" | "intentsifyNew";
  hideHead?: boolean;
  hidePagination?: boolean;
  hidePageSize?: boolean;
  defaultSort?: SortingState;
  defaultPageSize?: number;

  isControlledByAPI?: boolean;
  total?: number;
  onFetchDataChange?: (params: FetchDataParams<keyof T> | any) => void;
  renderExpandableRowComponent?: (row: RowType<T>) => React.ReactNode;

  /**
   * An array of useEffect dependencies you can include to force reseting pagination
   * to first page. For example, you have a global campaingId filter, when it changes
   * you want the table to re-render on the first page.
   */
  resetPaginationDependencies?: any[];
  borderRadius?: ChakraProps["borderRadius"];
  headerCellVerticalAlign?: ChakraProps["verticalAlign"];
  isCellContentCentered?: boolean;
} & RowSelectionProps<T>;

export type RowSelectionProps<T extends Record<string, unknown>> =
  | {
      selectable: true;
      rowSelection: RowSelectionState;
      setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>;
      uniqueKeyAccessor: (item: T) => T[keyof T];
    }
  | {
      selectable?: false;
      rowSelection?: RowSelectionState;
      setRowSelection?: React.Dispatch<React.SetStateAction<RowSelectionState>>;
      uniqueKeyAccessor?: (item: T) => T[keyof T];
    };

const Table = <T extends Record<string, unknown>>({
  data,
  meta,
  columns,
  searchValue,
  noDataMessage,
  customNoData,
  showNoData = true,

  paginationType = PaginationType.LIMIT_OFFSET,
  nextPageToken = "",
  isFetching = false,
  isScrollable = true,
  isExpandable = false,
  stretch = false,
  bg = "inherit",
  overflow = "hidden",
  maxWidth = undefined,
  variant,
  hideHead = false,
  hidePagination = false,
  hidePageSize = false,
  defaultSort,
  defaultPageSize = 10,

  isControlledByAPI = true,
  total,
  onFetchDataChange,

  renderExpandableRowComponent,

  resetPaginationDependencies = [],
  borderRadius = "md",
  headerCellVerticalAlign,
  isCellContentCentered = false,

  selectable,
  uniqueKeyAccessor,
  setRowSelection,
  rowSelection,
}: TableProps<T>) => {
  const tableVariant = variant
    ? variant
    : isExpandable
    ? "intentsifyExpanded"
    : "intentsifyStriped";

  const [sorting, setSorting] = useState<SortingState>(
    defaultSort ? defaultSort : []
  );

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: defaultPageSize,
  });

  const [expanded, setExpanded] = useState<ExpandedState>({});

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const instance = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
      pagination,
      expanded,
      rowSelection,
    },
    defaultColumn: {
      minSize: 0,
      size: 0,
    },
    enableRowSelection: selectable,
    enableMultiRowSelection: selectable,
    getRowId: uniqueKeyAccessor
      ? (item) => String(uniqueKeyAccessor(item))
      : undefined,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    onExpandedChange: setExpanded,
    manualSorting: isControlledByAPI,
    manualFiltering: isControlledByAPI,
    ...getTablePaginationParams(isControlledByAPI, meta),
  });

  const tokenPagination = useTokenPagination(
    instance.getState().pagination.pageIndex
  );

  tokenPagination.useUpdateToken(nextPageToken);

  useEffect(() => {
    const search = searchValue === "" ? undefined : searchValue;
    instance.setGlobalFilter(search);
  }, [searchValue, instance]);

  const globalFilter = instance.getState().globalFilter;

  useDeepEffect(() => {
    if (isControlledByAPI) {
      if (paginationType === PaginationType.LIMIT_OFFSET) {
        const fetchDataParams = getFetchDataParams<T>({
          pageIndex,
          pageSize,
          sortBy: sorting,
          globalFilter: globalFilter,
        });

        onFetchDataChange?.(fetchDataParams);
      } else if (paginationType === PaginationType.TOKEN) {
        const fetchDataParams = getTokenFetchDataParams<T>({
          pageToken: tokenPagination.currentToken,
          sortBy: sorting,
          globalFilter,
        });

        onFetchDataChange?.(fetchDataParams);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sorting,
    globalFilter,
    pageIndex,
    pageSize,
    onFetchDataChange,
    isControlledByAPI,
  ]);

  useDeepEffect(() => {
    setPagination({
      pageIndex: 0,
      pageSize: pagination.pageSize,
    });

    if (paginationType === PaginationType.LIMIT_OFFSET) {
      const fetchDataParams = getFetchDataParams<T>({
        pageIndex: 0,
        pageSize,
        sortBy: sorting,
        globalFilter,
      });

      onFetchDataChange?.(fetchDataParams);
    } else {
      const fetchDataParams = getTokenFetchDataParams<T>({
        pageToken: "",
        sortBy: sorting,
        globalFilter,
      });

      onFetchDataChange?.(fetchDataParams);
    }
  }, [...resetPaginationDependencies, sorting]);

  if (isFetching) {
    return <Loader stretch={stretch} />;
  }

  const selected = Object.keys(instance.getState().rowSelection ?? {});

  return (
    <ErrorBoundary>
      <Flex
        bg={data.length ? bg : undefined}
        rounded={borderRadius}
        maxWidth={maxWidth}
        overflow={overflow}
        direction="column"
        flexGrow={stretch ? 1 : undefined}
        justifyContent={stretch ? "space-between" : undefined}
      >
        {!data.length && showNoData ? (
          <>
            {customNoData ? (
              customNoData
            ) : (
              <NoData stretch={stretch} message={noDataMessage} />
            )}
          </>
        ) : (
          <div
            style={
              isScrollable
                ? {
                    overflowX: "auto",
                    height: "100%",
                    willChange: "scroll-position",
                  }
                : { overflow: "none", height: "100%" }
            }
          >
            <ChakraTable
              fontWeight={"md"}
              display={{
                base: "block",
                md: "table",
              }}
              sx={{
                "@media print": {
                  display: "table",
                },
                overflowY: "scroll",
                height: stretch ? "100%" : "auto",
              }}
              size="sm"
              variant={tableVariant}
              shadow={"sm"}
            >
              {!hideHead && (
                <Thead>
                  {instance.getHeaderGroups().map((headerGroup) => (
                    <HeaderGroup
                      key={headerGroup.id}
                      headerGroup={headerGroup}
                      isCentered={isCellContentCentered}
                      verticalAlign={headerCellVerticalAlign}
                    />
                  ))}
                </Thead>
              )}

              <Tbody>
                {instance.getRowModel().rows.map((row) => (
                  <Row<T>
                    key={row.id}
                    row={row}
                    renderExpandableRowComponent={renderExpandableRowComponent}
                    colSpan={columns.length + 1}
                    isCentered={isCellContentCentered}
                  />
                ))}
              </Tbody>
            </ChakraTable>
          </div>
        )}

        <Flex alignItems="center">
          {Boolean(selected.length) && (
            <Flex alignItems="center">
              <Text whiteSpace="nowrap" fontSize="md">
                Rows selected: <b>{selected.length}</b>
              </Text>
              <Button
                onClick={() => {
                  setRowSelection?.({});
                }}
                ml={2}
                variant="outline"
                size="xs"
              >
                Clear
              </Button>
            </Flex>
          )}

          {!hidePagination && paginationType === PaginationType.TOKEN && (
            <TokenPagination
              onPrevPage={() =>
                instance.setPageIndex(
                  instance.getState().pagination.pageIndex - 1
                )
              }
              isPrevDisabled={instance.getState().pagination.pageIndex === 0}
              onNextPage={() =>
                instance.setPageIndex(
                  instance.getState().pagination.pageIndex + 1
                )
              }
              isNextDisabled={nextPageToken === ""}
            />
          )}

          {!hidePagination &&
            paginationType === PaginationType.LIMIT_OFFSET && (
              <Pagination
                key={Math.random()}
                hidePageSize={hidePageSize}
                total={
                  total ||
                  instance.getPageCount() *
                    instance.getState().pagination.pageSize
                }
                current={instance.getState().pagination.pageIndex + 1}
                pageSize={instance.getState().pagination.pageSize}
                goToPage={(page) => instance.setPageIndex(page)}
                setPageSize={(size) => instance.setPageSize(size)}
              />
            )}
        </Flex>
      </Flex>
    </ErrorBoundary>
  );
};

export { Table };
