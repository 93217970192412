import { Flex, Text } from "@chakra-ui/layout";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  brsOptions,
  ContentType,
  Option,
  PreferredChannel,
  ReviewedAssetPartialUpdate,
  ReviewedAssetUpdate,
} from "@intentsify/types";
import { Button, Select } from "components";
import { Controller, DefaultValues, useForm } from "react-hook-form";
import { CampaignSelector } from "shared/components";

export const CompletedAssetForm = (props: {
  omitName?: boolean;
  optional?: boolean;
  defaultValues?: DefaultValues<ReviewedAssetUpdate>;
  onSubmit: (
    data: Omit<ReviewedAssetUpdate, "campaigns"> & {
      campaignIds: number[];
    }
  ) => void | Promise<void>;
  isLoading?: boolean;
}) => {
  const form = useForm<ReviewedAssetUpdate>({
    resolver: zodResolver(
      // TODO: infer this type from the props
      props.optional ? ReviewedAssetPartialUpdate : ReviewedAssetUpdate
    ),
    defaultValues: props.defaultValues
      ? {
          contentType: ContentType.Unspecified,
          preferredChannel: PreferredChannel.Unspecified,
          buyerResearchStages: [],
          campaigns: [],
          ...props.defaultValues,
        }
      : undefined,
  });
  const errors = form.formState.errors as typeof form.formState.errors & {
    general?: { message: string };
  };

  return (
    <Flex
      as="form"
      gap="4"
      py="2"
      flexDir="column"
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onSubmit={form.handleSubmit(({ campaigns, ...restData }) => {
        props.onSubmit({
          ...restData,
          campaignIds: campaigns?.map((campaign) => campaign.id) || [],
        });
      })}
    >
      <Flex mt="-4" gap="4">
        {!props.omitName && (
          <FormControl
            isInvalid={!!errors?.name?.message}
            isRequired={!props.optional}
          >
            <FormLabel>Name</FormLabel>
            <Input
              {...form.register("name")}
              placeholder="Asset name"
              shadow="sm"
              size="sm"
              rounded="md"
              autoComplete="off"
            />
            <FormErrorMessage>{errors?.name?.message}</FormErrorMessage>
          </FormControl>
        )}

        <FormControl isInvalid={Boolean(errors.campaigns?.message)}>
          <FormLabel>Intent models</FormLabel>
          <Controller
            name="campaigns"
            control={form.control}
            render={({ field }) => (
              <CampaignSelector
                isMulti
                showOnlyCurrentUserCompanyCampaigns
                selected={
                  field.value?.map((campaign) => ({
                    value: campaign.id,
                    label: campaign.name,
                  })) as unknown as Option<number>
                }
                onChange={(options) =>
                  field.onChange(
                    (options as unknown as Option<number>[]).map((option) => ({
                      id: option.value,
                      name: option.label,
                    }))
                  )
                }
              />
            )}
          />

          <FormErrorMessage>{errors.campaigns?.message}</FormErrorMessage>
        </FormControl>
      </Flex>

      <Flex>
        <FormControl isInvalid={Boolean(errors.contentType?.message)}>
          <FormLabel>Content Type</FormLabel>
          <Controller
            name="contentType"
            control={form.control}
            render={({ field }) => (
              <Select
                isMulti={false}
                placeholder="Select Content Type"
                options={contentTypeOptions}
                value={contentTypeOptions.find(
                  (option) => option.value === field.value
                )}
                onChange={(value) =>
                  field.onChange(value?.value ?? ContentType.Unspecified)
                }
              />
            )}
          />

          <FormErrorMessage>{errors.contentType?.message}</FormErrorMessage>
        </FormControl>

        <FormControl
          isInvalid={Boolean(errors.buyerResearchStages?.message)}
          mx={4}
        >
          <FormLabel>Buyer Research Stage(s)</FormLabel>
          <Controller
            name="buyerResearchStages"
            control={form.control}
            render={({ field }) => (
              <Select
                isMulti
                placeholder="Select Stage(s)"
                options={brsOptions}
                value={brsOptions.filter((option) =>
                  field.value?.includes(option.value)
                )}
                onChange={(value) => {
                  if (value && Array.isArray(value)) {
                    field.onChange(value.map((option) => option.value));
                  }
                }}
              />
            )}
          />

          <FormErrorMessage>
            {errors.buyerResearchStages?.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors.preferredChannel?.message)}>
          <FormLabel>Preferred channel</FormLabel>
          <Controller
            name="preferredChannel"
            control={form.control}
            render={({ field }) => (
              <Select
                isMulti={false}
                placeholder="Select preferred channel"
                options={preferredChannelOptions}
                value={preferredChannelOptions.find(
                  (option) => option.value === field.value
                )}
                onChange={(value) =>
                  field.onChange(value?.value ?? PreferredChannel.Unspecified)
                }
              />
            )}
          />

          <FormErrorMessage>
            {errors.preferredChannel?.message}
          </FormErrorMessage>
        </FormControl>
      </Flex>

      <Flex alignItems="center" gap="4" alignSelf="end" mt="8">
        {errors.general?.message && (
          <Text color="red.500" fontSize="sm" _dark={{ color: "red.200" }}>
            {errors.general?.message}
          </Text>
        )}

        <Button
          type="submit"
          variant="primary-teal"
          isLoading={props.isLoading}
        >
          Save
        </Button>
      </Flex>
    </Flex>
  );
};

const contentTypeOptions = Object.values(ContentType)
  .filter((contentType) => contentType !== "Unspecified")
  .map((contentType) => ({ value: contentType, label: contentType }));

const preferredChannelOptions = Object.values(PreferredChannel)
  .filter((preferredChannel) => preferredChannel !== "Unspecified")
  .map((preferredChannel) => ({
    value: preferredChannel,
    label: preferredChannel,
  }));
