import { Text } from "@chakra-ui/react";
import { Company } from "@intentsify/types";
import { Button } from "components";
import { IntegrationWithLogo } from "../../IntegrationsModal.utils";
import { useEstablishWorkatoIntegration } from "../../mutations/useEstablishWorkatoIntegration";
import { ProviderCard } from "./ProviderCard";

interface DisabledProviderProps {
  companyId: Company["companyId"];
  integration: IntegrationWithLogo;
}

export const DisabledProvider = (props: DisabledProviderProps) => {
  const establishWorkatoIntegration = useEstablishWorkatoIntegration(
    props.companyId,
    props.integration.provider
  );

  return (
    <ProviderCard.Root>
      <ProviderCard.Header integration={props.integration}>
        <Button
          isLoading={establishWorkatoIntegration.isLoading}
          variant="primary-teal"
          onClick={() => {
            establishWorkatoIntegration.mutate();
          }}
        >
          <Text pt="1px">Enable</Text>
        </Button>
      </ProviderCard.Header>
    </ProviderCard.Root>
  );
};
