import { Center, Divider, HStack, Text } from "@chakra-ui/react";
import { toNumberDisplayValue } from "@intentsify/utils";
import { useQuery } from "@tanstack/react-query";
import { Card, Carousel, Creative } from "components";
import { DisplayPerformanceFilters } from "screens/CampaignPacing/screens/DisplayPerformance";
import { topCreativePerformance } from "./TopCreativePerformance.requests";

type TopCreativePerformanceProps = {
  campaignId: string;
  filters: DisplayPerformanceFilters;
};

const TopCreativePerformance = ({
  campaignId,
  filters,
}: TopCreativePerformanceProps) => {
  const { data, isFetching } = useQuery(
    [
      "impressionsClicksPerformance-topCreativePerformance",
      campaignId,
      filters,
    ],
    () => topCreativePerformance(campaignId, filters)
  );

  const slides =
    data?.topCreativePerformance.map((c) => ({
      content: (
        <Creative
          creativeUrl={c.creativeUrl}
          creativeClickUrl={c.creativeClickUrl}
          showIframe={c.showIframe}
        />
      ),

      description: (
        <HStack p={0}>
          <HStack>
            <Text fontSize={"14px"} fontWeight={"light"}>
              Greatest CTR:
            </Text>
            <Text fontSize={"14px"} fontWeight={"semibold"}>
              {c.greatestCTR
                ? toNumberDisplayValue(c.greatestCTR, { digits: 2 })
                : "-"}
              %
            </Text>
          </HStack>
          <Divider h={8} orientation="vertical" />
          <HStack>
            <Text fontSize={"14px"} fontWeight={"light"}>
              CTR vs Others:
            </Text>
            <Text fontSize={"14px"} fontWeight={"semibold"}>
              {c.greatestCTRvsOthers
                ? toNumberDisplayValue(c.greatestCTRvsOthers, { digits: 2 })
                : "-"}
              %
            </Text>
          </HStack>
        </HStack>
      ),
      link: c.creativeUrl,
    })) || [];

  return (
    <Card isLoading={isFetching} title={"Top Creative Performance"}>
      <Center width="full" height="100%">
        <Carousel
          slides={slides}
          maxWidth="24rem"
          collocation="Top Creative Performance"
        />
      </Center>
    </Card>
  );
};

export { TopCreativePerformance };
