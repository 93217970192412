import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
} from "@chakra-ui/react";
import { ProgrammaticDomainFile } from "@intentsify/types";
import { useComponentColors } from "theme";

type AutomatedProgrammaticSegmentUploadsProps = {
  segmentUploads: ProgrammaticDomainFile[];
};

const AutomatedProgrammaticSegmentUploads = ({
  segmentUploads,
}: AutomatedProgrammaticSegmentUploadsProps) => {
  const componentColors = useComponentColors();

  return (
    <>
      <Text
        mt={4}
        color={componentColors.form.formLabelColor}
        fontSize="lg"
        fontWeight="bold"
      >
        Segment Upload
      </Text>

      <Accordion allowMultiple>
        {segmentUploads.map((segmentUpload, index) => (
          <AccordionItem key={index}>
            <h2>
              <AccordionButton>
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  fontWeight={"semibold"}
                >
                  # {segmentUploads.length - index}{" "}
                  {index === 0 ? "(Current)" : ""}
                </Box>
                <Text> Created: {segmentUpload.createdAt}</Text>
                <AccordionIcon ml={8} />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Text>Tag: {segmentUpload.tag}</Text>
              <Text>Region: {segmentUpload.region}</Text>
              <Text>Processing Token: {segmentUpload.processingToken}</Text>
              {segmentUpload.ipAddressesCount > 0 && (
                <Text>IP Address Count: {segmentUpload.ipAddressesCount}</Text>
              )}
              {segmentUpload.userIdsCount > 0 && (
                <Text>MAIDS Count: {segmentUpload.maidsCount}</Text>
              )}
              {segmentUpload.maidsCount > 0 && (
                <Text>User IDs Count: {segmentUpload.userIdsCount}</Text>
              )}
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </>
  );
};

export { AutomatedProgrammaticSegmentUploads };
