import {
  BatchDeleteDraftAssetsClientDTO,
  ListDraftAssetsRO,
} from "@intentsify/dto";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Endpoints, apiService } from "api";

export const useDeleteDraftAssets = (
  removeSelection: (id: string | number) => void
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["contentLibrary", "draftAssets", "delete"],
    mutationFn: async (
      draftAssetIds: BatchDeleteDraftAssetsClientDTO["draftAssetIds"]
    ) => {
      await apiService.delete(
        Endpoints.ContentLibrary.DraftAssets.Delete.Batch,
        { data: { draftAssetIds } }
      );
    },

    onSuccess: (data, draftAssetIds) => {
      draftAssetIds.forEach((id) => removeSelection(id));

      queryClient.setQueriesData<ListDraftAssetsRO>(
        { queryKey: ["contentLibrary", "draftAssets"], exact: false },
        (oldData) => ({
          ...oldData,
          draftAssets:
            oldData?.draftAssets?.filter(
              (asset) => !draftAssetIds.includes(asset.id)
            ) || [],
        })
      );
    },
  });
};
