import {
  Flex,
  Spinner,
  Stack,
  Text,
  useColorModeValue,
  useMultiStyleConfig,
} from "@chakra-ui/react";

import {
  AutoComplete,
  AutoCompleteItem,
  AutoCompleteList,
  AutoCompleteInput as ChocAutoCompleteInput,
} from "@choc-ui/chakra-autocomplete";

type AutoCompleteInputProps = {
  isDisabled?: boolean;
  isLoading?: boolean;
  label?: string;
  placeholder: string;
  suggestions: string[];
  inputValue: string;
  onInputValueChange: (value: string) => void;
  onSelection: (value: string) => void;
};

const AutoCompleteInput = ({
  isDisabled,
  label,
  isLoading,
  placeholder,
  inputValue,
  onInputValueChange,
  suggestions,
  onSelection,
}: AutoCompleteInputProps) => {
  const menuStyles = useMultiStyleConfig("Menu", {});
  const spinnerColor = useColorModeValue("gray.500", "gray.100");

  return (
    <Stack w="400px">
      {label && (
        <Text fontSize="xs" fontWeight="semibold">
          {label}:
        </Text>
      )}

      <AutoComplete
        isLoading={isLoading}
        onSelectOption={(params) => {
          const value = params.item.value as string;
          onSelection(value);
        }}
        emptyState={
          <Flex justifyContent="center">
            <Text fontSize="sm" color="gray">
              No options found
            </Text>
          </Flex>
        }
      >
        <ChocAutoCompleteInput
          placeholder={placeholder}
          isDisabled={isDisabled}
          autoComplete="off"
          size="sm"
          fontSize="sm"
          rounded="md"
          variant="outline"
          value={inputValue}
          onChange={(e) => {
            const value = e.target.value;
            onInputValueChange(value);
          }}
          loadingIcon={
            <Flex
              h="32px"
              w="32px"
              justifyContent="center"
              alignItems={"center"}
              position="absolute"
              top={0}
              right={0}
            >
              <Spinner m={0} p={0} color={spinnerColor} size="sm" />
            </Flex>
          }
        />
        <AutoCompleteList border="inherit" sx={menuStyles.list}>
          {suggestions.map((domain) => (
            <AutoCompleteItem
              key={domain}
              value={domain}
              __css={menuStyles.item}
              borderRadius={0}
              _focus={{
                background: "var(--chakra-colors-gray-100)",
              }}
              m={0}
              py={2}
            >
              {domain}
            </AutoCompleteItem>
          ))}
        </AutoCompleteList>
      </AutoComplete>
    </Stack>
  );
};

export { AutoCompleteInput };
