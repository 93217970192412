import { Divider, Heading, SimpleGrid, Stack, Text } from "@chakra-ui/react";
import {
  ProgrammaticDomainFileWithSegments,
  SegmentState,
} from "@intentsify/types";
import { SegmentDetails } from "./SegmentDetails";

type FileDetailsProps = {
  programmaticDomainsFile: ProgrammaticDomainFileWithSegments;
};

const FileDetails = ({ programmaticDomainsFile }: FileDetailsProps) => {
  return (
    <SimpleGrid columns={1}>
      <Heading pb={4} as="h2" size="md">
        Programmatic Segments File Details
      </Heading>
      <Stack direction="row">
        <Text fontWeight="semibold">Created date:</Text>
        <Text fontWeight="light">
          {programmaticDomainsFile.createdAt.slice(0, 10)}
        </Text>
      </Stack>
      <Stack direction="row">
        <Text fontWeight="semibold">Company:</Text>
        <Text fontWeight="light">{programmaticDomainsFile.companyName}</Text>
      </Stack>
      <Stack direction="row">
        <Text fontWeight="semibold">Processing token:</Text>
        <Text fontWeight="light">
          {programmaticDomainsFile.processingToken}
        </Text>
      </Stack>
      <Stack direction="row">
        <Text fontWeight="semibold">Account ID:</Text>
        <Text fontWeight="light">
          {programmaticDomainsFile.accountId || " "}
        </Text>
      </Stack>
      <Stack direction="row">
        <Text fontWeight="semibold">Tag:</Text>
        <Text fontWeight="light">{programmaticDomainsFile.tag || " "}</Text>
      </Stack>
      <Stack direction="row">
        <Text fontWeight="semibold">Region:</Text>
        <Text fontWeight="light">{programmaticDomainsFile.region || " "}</Text>
      </Stack>
      <Stack direction="row">
        <Text fontWeight="semibold">IP addresses count:</Text>
        <Text fontWeight="light">
          {programmaticDomainsFile.ipAddressesCount || " "}
        </Text>
      </Stack>
      <Stack direction="row">
        <Text fontWeight="semibold">User IDs count:</Text>
        <Text fontWeight="light">
          {programmaticDomainsFile.userIdsCount || " "}
        </Text>
      </Stack>
      <Stack direction="row">
        <Text fontWeight="semibold">ISP Data?:</Text>
        <Text fontWeight="light">
          {programmaticDomainsFile.hasIspData ? "Yes" : "No"}
        </Text>
      </Stack>
      <Stack direction="row">
        <Text fontWeight="semibold">Beeswax segment ID:</Text>
        <Text fontWeight="light">
          {programmaticDomainsFile.beeswaxSegmentIds || " "}
        </Text>
      </Stack>
      {programmaticDomainsFile.ipAddressSegmentState ===
        SegmentState.PROCESSED ||
        (programmaticDomainsFile.userIdSegmentState ===
          SegmentState.PROCESSED && (
          <>
            <Divider mt={2} mb={2} />
            <SimpleGrid columns={2}>
              {programmaticDomainsFile.segments.map((segment) => (
                <Stack key={String(segment.segmentType)} direction={"column"}>
                  <SegmentDetails programmaticDomainSegment={segment} />
                </Stack>
              ))}
            </SimpleGrid>
          </>
        ))}
    </SimpleGrid>
  );
};

export { FileDetails };
