import {
  Box,
  chakra,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  SimpleGrid,
  useToast,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { AuthenticatedUserRO } from "@intentsify/dto";
import { useMutation } from "@tanstack/react-query";
import { AgreementScreenDefinition, ResetPasswordScreenDefinition } from "auth";
import { Button } from "components";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useNavigate } from "react-router";
import { YourCampaignsScreenDefinition } from "screens";
import { useUser } from "store/store.hooks";
import { useAppStore } from "store/useAppStore";
import { isCustomApiError } from "types";
import { handleApiError } from "utils";
import { z } from "zod";
import { signInRequest } from "./LoginForm.requests";
import { ResetPasswordLink } from "./ResetPasswordLink";

const validationSchema = z.object({
  email: z.string().email(),
  password: z.string().min(8),
});

type FieldValues = z.infer<typeof validationSchema>;

const LoginForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FieldValues>({
    mode: "onChange",
    resolver: zodResolver(validationSchema),
  });

  const user = useUser();
  const setUser = useAppStore.use.setUser();

  const [loginAttemptCount, setLoginAttemptCount] = useState(0);

  const navigate = useNavigate();
  const toast = useToast();

  const { mutate, isSuccess, isLoading } = useMutation(signInRequest, {
    onSuccess: ({ user }: AuthenticatedUserRO) => {
      setUser(user);

      if (user.isActive) {
        toast.closeAll();
        toast({
          title: "Welcome back!",
          status: "success",
        });
      }
    },
    /**
     * We want to handle invalid credentials in a special way.
     * If it's not 400InvalidCredentials let it be handled by handleApiError.
     */
    onError: (err) => {
      if (isCustomApiError(err)) {
        if (err.response.internalCode === "400InvalidCredentials") {
          setLoginAttemptCount(loginAttemptCount + 1);

          if (loginAttemptCount < 3) {
            toast({
              title: "Invalid email address or password.",
              status: "info",
            });

            return;
          }

          toast({
            title: (
              <>
                Invalid email address or password. Did you forget your password?
                Click
                <Link
                  onClick={() => {
                    toast.closeAll();
                    navigate(ResetPasswordScreenDefinition.navigate());
                  }}
                >
                  {" "}
                  here{" "}
                </Link>
                to reset.
              </>
            ),
            status: "info",
            isClosable: true,
            duration: 25 * 1000,
          });

          return;
        }
      }

      handleApiError(err, navigate, toast);
    },
  });

  const onSubmit = (values: FieldValues) => {
    mutate(values);
  };

  useEffect(() => {
    if (isSuccess && !user?.isActive) {
      toast({
        title:
          "Before continuing you have to agree to the terms and conditions.",
        status: "info",
      });
    }
  }, [isSuccess, user?.isActive, toast]);

  if (isSuccess) {
    return (
      <Navigate
        to={
          user?.isActive
            ? YourCampaignsScreenDefinition.navigate()
            : AgreementScreenDefinition.navigate()
        }
      />
    );
  }

  return (
    <Flex justifyContent="center">
      <chakra.form
        onSubmit={(data) => {
          handleSubmit(onSubmit)(data);
        }}
        w="100%"
      >
        <SimpleGrid columns={1} spacing={4}>
          <FormControl isInvalid={!!errors?.email?.message} isRequired>
            <FormLabel color="white">Email</FormLabel>
            <Input
              {...register("email")}
              bg="gray.100"
              color="gray.600"
              _placeholder={{ color: "gray.400" }}
              type="email"
              name="email"
              placeholder="Email"
            />
            <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors?.password?.message} isRequired>
            <FormLabel color="white">Password</FormLabel>
            <Input
              {...register("password")}
              bg="gray.100"
              color="gray.600"
              _placeholder={{ color: "gray.400" }}
              type="password"
              placeholder="Password"
              name="password"
            />
            <FormErrorMessage>{errors?.password?.message}</FormErrorMessage>
          </FormControl>

          <Box>
            <ResetPasswordLink />
          </Box>

          <Button
            size="md"
            type="submit"
            fullWidth
            isLoading={isLoading}
            isDisabled={!!errors.email || !!errors.password || isLoading}
          >
            Login
          </Button>
        </SimpleGrid>
      </chakra.form>
    </Flex>
  );
};

export { LoginForm };
