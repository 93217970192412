import { useMemo } from "react";
import { ColorMode } from "@chakra-ui/react";

const useMapStyle = (colorMode: ColorMode) => {
  const mapStyle = useMemo(() => {
    if (colorMode === "light") {
      return "mapbox://styles/gtb8kbadzv9wfa0tya/clafbuyj8001w14pdiyavulsb";
    }

    return "mapbox://styles/gtb8kbadzv9wfa0tya/clafddorj000e14r04cjai6ai";
  }, [colorMode]);

  return mapStyle;
};

export { useMapStyle };
