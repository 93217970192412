import { CampaignState } from "@intentsify/types";
import { matchPath, useBlocker, useLocation, useParams } from "react-router";
import { useRecoilValue } from "recoil";
import { useCampaign } from "../../CampaignsWizard.hooks";
import { CreateCampaignScreenDefinition } from "../../CreateCampaign.definition";
import { isSavingWizardAtom } from "../Controls";
import { ExitCreationModal } from "./ExitCreationModal/ExitCreationModal";
import { UnsavedChangesModal } from "./UnsavedChangesModal/UnsavedChangesModal";

const NavigationBlockers = ({
  isUnsavedChangesBlockerEnabled,
}: {
  isUnsavedChangesBlockerEnabled: boolean;
}) => {
  const isSaving = useRecoilValue(isSavingWizardAtom);
  const campaignId = Number(useParams<"campaignId">().campaignId);
  const location = useLocation();

  const { campaign } = useCampaign({
    campaignId: Number(campaignId),
  });

  const shouldBlockUnsavedChanges = isUnsavedChangesBlockerEnabled && !isSaving;
  const shouldBlockCreationExit =
    matchPath(CreateCampaignScreenDefinition.path, location.pathname) &&
    campaign?.state === CampaignState.Creating &&
    !isSaving;

  const blocker = useBlocker(({ nextLocation, currentLocation }) => {
    const isCampaignCreationScreen = matchPath(
      CreateCampaignScreenDefinition.path,
      currentLocation.pathname
    );

    const isUserLeavingCreationScreen = !matchPath(
      CreateCampaignScreenDefinition.path,
      nextLocation.pathname
    );

    if (
      isCampaignCreationScreen &&
      isUserLeavingCreationScreen &&
      shouldBlockCreationExit
    ) {
      return true;
    }

    if (shouldBlockUnsavedChanges) {
      return true;
    }

    return false;
  });

  if (shouldBlockUnsavedChanges) {
    return <UnsavedChangesModal blocker={blocker} />;
  }

  if (shouldBlockCreationExit) {
    return <ExitCreationModal blocker={blocker} />;
  }
};

export { NavigationBlockers };
