import {
  CampaignAutodiscoveryStatus,
  CampaignKeywordsAndTopics,
  CampaignTopic,
  Dynamic1Status,
} from "@intentsify/types";
import { DefaultValue, atom, selector } from "recoil";
import { KeywordItem } from "types/Keyword";
import {
  AutodiscoverySettings,
  Dynamic1State,
} from "./KeywordsAndTopicsStep.types";

const keywordsAndTopicsStateAtom = atom<CampaignKeywordsAndTopics>({
  key: "keywordsAndTopicsStateAtom",
  default: {
    keywords: [],
    topics: [],
    autodiscoveryDescription: "",
    autodiscoveryUrls: [],
    autodiscoveryKeywords: [],
    autodiscoveryTopics: [],
    autodiscoveryProducts: [],
    autodiscoveryStatus: CampaignAutodiscoveryStatus.STATE_UNSPECIFIED,
    autodiscoveryFiles: [],
    dynamicOneStatus: Dynamic1Status.Unspecified,
  },
});

const keywordsAtom = atom<KeywordItem[]>({
  key: "keywordsAtom",
  default: [],
});

const isLoadingKeywordsAtom = atom<boolean>({
  key: "isLoadingKeywordsAtom",
  default: false,
});

const topicsAtom = atom<CampaignTopic[]>({
  key: "topicsAtom",
  default: [],
});

const isLoadingTopicsAtom = atom<boolean>({
  key: "isLoadingTopicsAtom",
  default: false,
});

const isMutatingAutodiscoveryFilesAtom = atom<boolean>({
  key: "isMutatingAutodiscoveryFilesAtom",
  default: false,
});

const autodiscoverySettingsAtom = atom<AutodiscoverySettings>({
  key: "autodiscoverySettingsAtom",
  default: {
    autodiscoveryDescription: "",
    autodiscoveryUrls: [],
    autodiscoveryKeywords: [],
    autodiscoveryTopics: [],
    autodiscoveryProducts: [],
    autodiscoveryStatus: CampaignAutodiscoveryStatus.STATE_UNSPECIFIED,
    autodiscoveryFiles: [],
  },
});

const dynamic1Atom = atom<Dynamic1State>({
  key: "dynamic1Atom",
  default: {
    processingStatus: Dynamic1Status.Unspecified,
  },
});

const keywordsAndTopicsStateSelector = selector<CampaignKeywordsAndTopics>({
  key: "keywordsAndTopicsStateSelector",
  get: ({ get }) => {
    return get(keywordsAndTopicsStateAtom);
  },
  set: ({ set, reset }, newValue) => {
    if (newValue instanceof DefaultValue) {
      reset(keywordsAndTopicsStateAtom);
      return;
    }
    const {
      keywords,
      topics,
      autodiscoveryDescription,
      autodiscoveryUrls,
      autodiscoveryKeywords,
      autodiscoveryTopics,
      autodiscoveryProducts,
      autodiscoveryStatus,
      autodiscoveryFiles,
      dynamicOneStatus,
    } = newValue;

    set(keywordsAndTopicsStateAtom, {
      keywords,
      topics,
      autodiscoveryDescription,
      autodiscoveryUrls,
      autodiscoveryKeywords,
      autodiscoveryTopics,
      autodiscoveryProducts,
      autodiscoveryStatus,
      autodiscoveryFiles,
      dynamicOneStatus,
    });
  },
});

const keywordsSelector = selector<KeywordItem[]>({
  key: "keywordsSelector",
  get: ({ get }) => {
    return get(keywordsAtom);
  },
  set: ({ set, reset }, newValues) => {
    if (newValues instanceof DefaultValue || newValues.length === 0) {
      reset(keywordsAtom);
    } else if (newValues.length) {
      set(
        keywordsAtom,
        newValues.map((v) => ({ ...v, label: v.label.toLowerCase() }))
      );
    }
  },
});

const topicsSelector = selector<CampaignTopic[]>({
  key: "topicsSelector",
  get: ({ get }) => {
    return get(topicsAtom);
  },
  set: ({ set, reset }, newValues) => {
    if (newValues instanceof DefaultValue || newValues.length === 0) {
      reset(topicsAtom);
    } else if (newValues.length) {
      set(topicsAtom, newValues);
    }
  },
});

const topicIdsSelector = selector<number[]>({
  key: "topicIdsSelector",
  get: ({ get }) => {
    return get(topicsAtom).map((t) => t.topicId);
  },
});

const isLoadingKeywordsAndTopicsStepSelector = selector<boolean>({
  key: "keywordsAndTopicsState_isLoadingSelector",
  get: ({ get }) => {
    return (
      get(isLoadingKeywordsAtom) ||
      get(isLoadingTopicsAtom) ||
      get(isMutatingAutodiscoveryFilesAtom)
    );
  },
});

const autodiscoverySettingsStateSelector = selector<AutodiscoverySettings>({
  key: "autodiscoverySettingsSelector",
  get: ({ get }) => {
    return get(autodiscoverySettingsAtom);
  },
  set: ({ set, reset }, newValue) => {
    if (newValue instanceof DefaultValue) {
      reset(autodiscoverySettingsAtom);
    } else {
      set(autodiscoverySettingsAtom, {
        ...newValue,
      });
    }
  },
});

const dynamic1StateSelector = selector<Dynamic1State>({
  key: "dynamic1StateSelector",
  get: ({ get }) => {
    return get(dynamic1Atom);
  },
  set: ({ set, reset }, newValue) => {
    if (newValue instanceof DefaultValue) {
      reset(dynamic1Atom);
    } else {
      set(dynamic1Atom, {
        processingStatus: newValue.processingStatus,
      });
    }
  },
});

export {
  autodiscoverySettingsAtom,
  autodiscoverySettingsStateSelector,
  dynamic1StateSelector,
  isLoadingKeywordsAndTopicsStepSelector,
  isLoadingKeywordsAtom,
  isLoadingTopicsAtom,
  isMutatingAutodiscoveryFilesAtom,
  keywordsAndTopicsStateSelector,
  keywordsAtom,
  keywordsSelector,
  topicIdsSelector,
  topicsSelector,
};
