import { Flex, Spinner, ToastId, useToast } from "@chakra-ui/react";
import { apiService } from "api";
import { useEffect, useRef } from "react";
import { atom, useRecoilState } from "recoil";
import { BaseFilter, FetchDataParams } from "types";
import { paramsToQueryUrl } from "./paramsToQueryUrl";

export enum DownloadItemStatus {
  IN_PROGRESS = "inProgress",
  ERROR = "error",
  COMPLETED = "completed",
}

export type DownloadItem = {
  key: number;
  status: DownloadItemStatus;
  fileName: string;
  fileExtension?: string;
};

const downloadsAtom = atom<DownloadItem[]>({
  key: `downloadsAtom`,
  default: [],
});

export const useDownloadFile = () => {
  const [downloading, setDownloading] = useRecoilState(downloadsAtom);

  const download = async <T,>({
    url,
    fileName,
    fileExtension,
    params,
    data,
    method = "GET",
  }: {
    url: string;
    fileName: string;
    fileExtension?: string;
    params?:
      | Omit<FetchDataParams<unknown, BaseFilter<T>>, "page" | "per_page">
      | Record<string, string | number>;
    data?: Record<string, unknown>;
    method?: "GET" | "POST";
  }) => {
    setDownloading((prev) => {
      return [
        ...prev,
        ...[
          {
            key: Date.now(),
            status: DownloadItemStatus.IN_PROGRESS,
            fileName,
            fileExtension,
          },
        ],
      ];
    });

    try {
      await apiService({
        url: params
          ? `${url}?${
              paramsToQueryUrl({ params, includeFirstAmpersand: false }) || ""
            }`
          : url,
        method,
        responseType: "blob",
        data,
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;

        link.setAttribute(
          "download",
          `${fileName}${fileExtension ? `.${fileExtension}` : ""}`
        );
        document.body.appendChild(link);
        link.click();
      });
    } catch {
      setDownloading((prev) => {
        return prev.map((i) => {
          if (i.fileName === fileName) {
            return {
              ...i,
              status: DownloadItemStatus.ERROR,
            };
          }

          return i;
        });
      });

      return;
    }

    setDownloading((prev) => {
      return prev.map((i) => {
        if (i.fileName === fileName) {
          return {
            ...i,
            status: DownloadItemStatus.COMPLETED,
          };
        }

        return i;
      });
    });
  };

  const getDownloadingStatus = (fileName: string) => {
    const file = downloading.filter((file) => file.fileName === fileName)[0];
    return file?.status;
  };

  return { downloading, download, setDownloading, getDownloadingStatus };
};

export const DownloadToastManager = () => {
  const { downloading, setDownloading } = useDownloadFile();
  const toast = useToast();
  const toasts = useRef<ToastId[]>([]);

  useEffect(() => {
    downloading.forEach((item) => {
      const { fileName, status, key, fileExtension } = item;

      if (!toasts.current.includes(key) && status === "inProgress") {
        toast({
          duration: null,
          id: key,
          description: (
            <Flex alignItems={"center"}>
              Downloading {fileName}
              {fileExtension ? `.${fileExtension}` : ""}{" "}
              <Flex>
                <Spinner ml={3} size="sm" />
              </Flex>
            </Flex>
          ),
          status: "info",
        });

        toasts.current.push(key);
      }

      if (toasts.current.includes(key)) {
        if (item.status === "completed") {
          toast.update(key, {
            duration: null,
            isClosable: true,
            description: `Downloading complete: ${fileName}${
              fileExtension ? `.${fileExtension}` : ""
            }`,
            status: "success",
          });

          toasts.current = toasts.current.filter((f) => f !== key);

          setDownloading((prev) => {
            return prev.filter((i) => i.key !== key);
          });
        }

        if (item.status === "error") {
          toast.update(key, {
            duration: null,
            isClosable: true,
            description: `Error downloading file: ${fileName}.${
              fileExtension ? `.${fileExtension}` : ""
            }`,
            status: "error",
          });

          toasts.current = toasts.current.filter((f) => f !== key);

          setDownloading((prev) => {
            return prev.filter((i) => i.key !== key);
          });
        }
      }
    });
  }, [downloading, toast, setDownloading]);

  return null;
};
