import { Box, TypographyProps } from "@chakra-ui/react";
import { useUnreadCount } from "../../Notifications.hooks";

type NotificationsCounterProps = {
  onClick: () => void;
};

const getCountFontSize = (count: number): TypographyProps["fontSize"] => {
  switch (true) {
    case count > 99:
      return "8px";
    case count > 9:
      return "x-small";
    default:
      return "small";
  }
};

const NotificationsCounter = ({ onClick }: NotificationsCounterProps) => {
  const unreadCount = useUnreadCount();

  if (unreadCount === 0) {
    return;
  }

  return (
    <Box
      fontWeight="bold"
      letterSpacing="tighter"
      fontSize={getCountFontSize(unreadCount)}
      background="gray.50"
      color="brand.450"
      borderColor="brand.450"
      borderWidth="2px"
      display="flex"
      alignItems="center"
      justifyContent="center"
      style={{
        position: "absolute",
        top: -4,
        right: -4,
        whiteSpace: "nowrap",
        width: "22px",
        height: "22px",
        borderRadius: "100%",
      }}
      onClick={onClick}
    >
      {unreadCount > 99 ? "99+" : unreadCount}
    </Box>
  );
};

export { NotificationsCounter };
