import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { AsyncReturnType } from "type-fest";
import { DataPackageMap } from "@intentsify/types";
import { Endpoints, apiService } from "api";

const selectUserAgencyCompany = async ({
  companyId,
}: {
  companyId: number;
}) => {
  const { data } = await apiService.post<DataPackageMap>(
    Endpoints.UserAgencyCompany.Post.Select(companyId)
  );

  return data;
};

type UseSelectUserAgencyCompanyMutationOptions = UseMutationOptions<
  AsyncReturnType<typeof selectUserAgencyCompany>,
  unknown,
  Parameters<typeof selectUserAgencyCompany>[number]
>;

export const useSelectUserAgencyCompany = (
  mutationOptions?: UseSelectUserAgencyCompanyMutationOptions
) => {
  return useMutation({
    ...mutationOptions,
    mutationFn: selectUserAgencyCompany,
    mutationKey: ["selectUserAgencyCompany"],
  });
};
