import { Box, Flex, Text } from "@chakra-ui/react";
import { DateTime } from "luxon";
import { useMemo } from "react";
import { decimalToPercentage } from "@intentsify/utils";
import { Nullable } from "@intentsify/types";
import { getSparklineChartColor } from "../../YourCampaigns.utils";
import { Sparkline } from "components";

type Props = {
  trendingAccountCoverage: number;
  trendingAccountCoverageResults: [
    Nullable<number>,
    Nullable<number>,
    Nullable<number>,
    Nullable<number>
  ];
};
export const TrendingAccountCoverageCell = ({
  trendingAccountCoverage,
  trendingAccountCoverageResults,
}: Props) => {
  const sparklineColor = useMemo(() => {
    if (
      trendingAccountCoverageResults[2] === null ||
      trendingAccountCoverageResults[3] === null
    ) {
      return undefined;
    }

    return getSparklineChartColor(
      trendingAccountCoverageResults[2],
      trendingAccountCoverageResults[3]
    );
  }, [trendingAccountCoverageResults]);

  const hasDataForAtLeastLastTwoWeeks = trendingAccountCoverageResults
    .slice(-2)
    .every((el) => el !== null);

  return (
    <Flex width="200px" alignItems="center" justifyContent="center" gap={1}>
      <Box width="45px">
        <Text>{decimalToPercentage(trendingAccountCoverage, 0)}%</Text>
      </Box>
      <Flex
        flex="1 0 auto"
        height={30}
        justifyContent="center"
        alignItems="center"
      >
        {!hasDataForAtLeastLastTwoWeeks ? (
          <Text fontStyle="italic" color="gray.500" fontSize={12}>
            No historical data available
          </Text>
        ) : (
          <Sparkline
            data={trendingAccountCoverageResults.map((el, index) => ({
              value: el,
              isoDate: DateTime.now()
                .minus({ weeks: 4 - index })
                .startOf("week")
                .toString(),
            }))}
            margins={{ top: 5, right: 5, bottom: 5, left: 5 }}
            lineWidth={1.5}
            color={sparklineColor}
            beginAtZero={false}
          />
        )}
      </Flex>
    </Flex>
  );
};
