import { PublishersScreen } from "types";
import { createScreen } from "utils/createScreen";

const PublishersScreenDefinition = createScreen({
  name: PublishersScreen,
  path: "/admin/publishers",
  routePath: ["/"],
  accessPath: "publisher",
});

export { PublishersScreenDefinition };
