const CreativePlaceholder = () => {
  return (
    <svg
      width="150"
      height="100"
      viewBox="0 0 150 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M150 0H0V100H150V0ZM143.479 6.06V79.685L111.471 49.155L89.5728 66.67L53.5726 40.49L6.52104 83.335V6.06H143.479ZM6.52104 93.94V91.79L54.0514 48.485L89.7773 74.485L111.089 57.47L143.484 88.365V93.94H6.52104Z"
        fill="#6C7B93"
      />
    </svg>
  );
};

export { CreativePlaceholder };
