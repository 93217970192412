/* eslint-disable react-hooks/rules-of-hooks */
import { Box, Center, Flex, Link, Text } from "@chakra-ui/layout";
import {
  Button as ChakraButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";
import {
  ContentType,
  PreferredChannel,
  ReviewedAsset,
} from "@intentsify/types";
import { formatDate, isPopulatedArray } from "@intentsify/utils";
import { ColumnDef } from "@tanstack/react-table";
import {
  Button,
  EMPTY_CELL,
  EmptyCell,
  IndeterminateCheckbox,
} from "components";
import { useConfirm } from "components/ConfirmDialog/useConfirm";
import { DelayedTooltip } from "components/Tooltip/DelayedTooltip";
import { DateTime } from "luxon";
import { useCallback, useMemo } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import { FiMoreVertical } from "react-icons/fi";
import { Link as ReactRouterLink } from "react-router-dom";
import { CampaignDetailsScreenDefinition } from "screens/Campaigns";
import { useUser } from "store/store.hooks";
import { ExtensionIcon } from "../ExtensionIcon";
import {
  ContentLibraryModal,
  ContentLibraryModalBody,
  ContentLibraryModalHeader,
} from "../Modal";
import { CompletedAssetForm } from "./CompletedAssetForm";
import { useDeleteReviewedAssets } from "./useDeleteReviewedAssets";
import { useUpdateReviewedAssets } from "./useUpdateReviewedAssets";

export const useColumns = (removeSelection: (id: string | number) => void) => {
  const getIsReviewedByCurrentUser = useGetIsReviewedByCurrentUser();
  const updateReviewedAssets = useUpdateReviewedAssets();

  return useMemo<ColumnDef<ReviewedAsset>[]>(
    () => [
      {
        id: "select",
        header: ({ table }) => (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <IndeterminateCheckbox
            {...{
              checked: row.getIsSelected(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        ),
      },
      {
        id: "actions",
        cell: ({ row }) => {
          const disclosure = useDisclosure();
          const deleteReviewedAssets = useDeleteReviewedAssets(removeSelection);

          const confirm = useConfirm();

          return (
            <Box mx="-20px">
              <Menu matchWidth={false}>
                <DelayedTooltip
                  aria-label="Completed assets actions"
                  label="Edit or delete asset"
                >
                  <MenuButton
                    as={Button}
                    isLoading={deleteReviewedAssets.isLoading}
                    variant="ghost"
                    p="2"
                    m="0"
                    h="auto"
                    size="sm"
                  >
                    <Center gap="1">
                      <FiMoreVertical />
                    </Center>
                  </MenuButton>
                </DelayedTooltip>

                <MenuList>
                  <MenuItem
                    as={Button}
                    justifyContent="flex-start"
                    variant="ghost"
                    bg="transparent"
                    aria-label="Delete asset"
                    color="red.500"
                    minW="auto"
                    _dark={{ color: "red.100" }}
                    leftIcon={<FaTrash />}
                    isLoading={deleteReviewedAssets.isLoading}
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={async () => {
                      if (
                        !(await confirm({
                          title: "Delete asset",
                          description: `Are you sure you want to delete ${row.original.name}?`,
                          confirmText: "Delete",
                        }))
                      ) {
                        return;
                      }

                      deleteReviewedAssets.mutate([row.original.id]);
                    }}
                  >
                    Delete
                  </MenuItem>

                  <MenuItem
                    as={Button}
                    justifyContent="flex-start"
                    variant="ghost"
                    bg="transparent"
                    aria-label="Review asset"
                    minW="auto"
                    leftIcon={<FaEdit />}
                    onClick={disclosure.onOpen}
                  >
                    Review
                  </MenuItem>
                </MenuList>
              </Menu>

              <ContentLibraryModal {...disclosure}>
                <ContentLibraryModalHeader>
                  Edit {row.original.name}
                </ContentLibraryModalHeader>

                <ContentLibraryModalBody>
                  <CompletedAssetForm
                    isLoading={updateReviewedAssets.isLoading}
                    defaultValues={{
                      ...row.original,
                      name: row.original.name.split(".")[0],
                    }}
                    onSubmit={(data) => {
                      updateReviewedAssets.mutate([
                        {
                          ...data,
                          id: row.original.id,
                          name: `${data.name}.${
                            row.original.name.split(".")[1]
                          }`,
                        },
                      ]);
                    }}
                  />
                </ContentLibraryModalBody>
              </ContentLibraryModal>
            </Box>
          );
        },
      },
      {
        header: "Name",
        accessor: "name",
        cell: ({ row }) => {
          return (
            <ReviewedAssetColumnName
              name={row.original.name}
              url={row.original.downloadUrl}
            />
          );
        },
      },
      {
        header: "Content type",
        accessor: "contentType",
        cell: ({ row }) => {
          const { contentType } = row.original;

          if (contentType === ContentType.Unspecified) {
            return EMPTY_CELL;
          }

          return <Text>{contentType}</Text>;
        },
      },
      {
        header: "Intent models",
        accessor: "campaigns",
        cell: ({ row }) => {
          const { campaigns } = row.original;

          if (!isPopulatedArray(campaigns)) {
            return EMPTY_CELL;
          }

          return (
            <Flex>
              {campaigns.map((campaign) => (
                <Center
                  key={campaign.id}
                  borderRadius="md"
                  bg="blackAlpha.100"
                  _dark={{ bg: "brand.450" }}
                >
                  <Link
                    p="1"
                    w="max-content"
                    as={ReactRouterLink}
                    to={CampaignDetailsScreenDefinition.navigate({
                      campaignId: campaign.id,
                      view: undefined,
                    })}
                  >
                    ({campaign.id}) {campaign.name}
                  </Link>
                </Center>
              ))}
            </Flex>
          );
        },
      },
      {
        header: "Preferred Tactic",
        accessor: "preferredChannel",
        cell: ({ row }) => {
          const { preferredChannel } = row.original;

          if (preferredChannel === PreferredChannel.Unspecified) {
            return EMPTY_CELL;
          }

          return <Text>{preferredChannel}</Text>;
        },
      },
      {
        header: "Buyer Research Stage(s)",
        accessor: "buyerResearchStages",
        cell: ({ row }) => {
          const { buyerResearchStages } = row.original;

          if (!isPopulatedArray(buyerResearchStages)) {
            return EMPTY_CELL;
          }

          return <Text>{buyerResearchStages.join(", ")}</Text>;
        },
      },
      {
        header: "Uploaded By",
        accessor: "reviewedBy",
        cell: ({ row }) => {
          const uploadedByDisplayName = row.original.uploadedByDisplayName;

          if (!uploadedByDisplayName) {
            return EMPTY_CELL;
          }

          if (getIsReviewedByCurrentUser(row.original.reviewedBy)) {
            return <Text>{uploadedByDisplayName} (You)</Text>;
          }

          return <Text>{uploadedByDisplayName}</Text>;
        },
      },
      {
        header: "Last Reviewed By",
        accessor: "reviewedByDisplayName",
        cell: ({ row }) => {
          if (getIsReviewedByCurrentUser(row.original.reviewedBy)) {
            return <Text>{row.original.reviewedByDisplayName} (You)</Text>;
          }

          return <Text>{row.original.reviewedByDisplayName}</Text>;
        },
      },
      {
        header: "Last Updated",
        accessor: "updatedAt",
        cell: ({ row }) => {
          if (!row.original.updatedAt) {
            return <EmptyCell />;
          }

          return (
            <Text>
              {formatDate({
                date: DateTime.fromSQL(row.original.updatedAt).toJSDate(),
              })}
            </Text>
          );
        },
      },
    ],
    [getIsReviewedByCurrentUser, removeSelection, updateReviewedAssets]
  );
};

export function ReviewedAssetColumnName(props: { name: string; url: string }) {
  const extension = props.name.split(".").pop();

  return (
    <ChakraButton
      as="a"
      target="_blank"
      href={props.url}
      variant="link"
      fontSize="sm"
      fontWeight="normal"
      color="black"
      _dark={{ color: "white" }}
    >
      <Flex alignItems="center" columnGap="3">
        <ExtensionIcon extension={extension} />
        <Text>{props.name}</Text>
      </Flex>
    </ChakraButton>
  );
}

const useGetIsReviewedByCurrentUser = () => {
  const user = useUser();

  return useCallback(
    (reviewedBy: number) => {
      return reviewedBy === user?.userId;
    },
    [user?.userId]
  );
};
