import { Endpoints } from "api";
import { useEffect } from "react";
import { useParams } from "react-router";
import { useMostRecentDownloadStatus } from "screens/Download/Download";
import { DownloadItemStatus, useDownloadFile, useScreen } from "utils";
import { ampli } from "../../tracking/amplitude";
import { DownloadStatus } from "../Download/DownloadStatus";
import { DownloadContentLibraryAssetDefinition } from "./DownloadContentLibraryAsset.definition";

const DownloadContentLibraryAsset = () => {
  useScreen(DownloadContentLibraryAssetDefinition);

  const { fileName } = useParams<"fileName">();
  const { id } = useParams<"id">();

  if (!fileName || !id) {
    throw new Error("404");
  }

  const { download } = useDownloadFile();
  const downloadingStatus = useMostRecentDownloadStatus(fileName);

  useEffect(() => {
    if (downloadingStatus !== DownloadItemStatus.IN_PROGRESS) {
      download({
        url: Endpoints.ContentLibrary.Download(fileName, id),
        fileName,
      });
      ampli.fileDownloaded({
        fileName,
        label: "content library asset",
        pagePath: window.location.pathname,
      });
    }

    // We want it to trigger once only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <DownloadStatus status={downloadingStatus} />;
};

export { DownloadContentLibraryAsset };
