import { Option } from "@intentsify/types";
import { apiService, Endpoints } from "api";
import { paramsToQueryUrl } from "utils";
import { PerformanceFilters } from "./CampaignLeadPerformance";

type LeadPerformance = {
  byCompanySize: Option<number>[];
  byJobFunction: Option<number>[];
  byJobLevel: Option<number>[];
  byIndustry: Option<number>[];
  byAsset: Option<number>[];
};

type LeadPerformanceFilters = {
  usStates: Option<number>[];
  jobLevels: Option<string>[];
  industries: Option<string>[];
  campaignCreateDate: string;
};

export const getLeadPerformance = async ({
  campaignId,
  filters,
}: {
  campaignId: number;
  filters: PerformanceFilters;
}): Promise<LeadPerformance> => {
  const { data } = await apiService.get(
    `${Endpoints.Campaigns.Get.Leads.Performance(
      campaignId
    )}?${paramsToQueryUrl({
      params: { filters },
      includeFirstAmpersand: false,
    })}`
  );
  return data;
};

export const getLeadPerformanceFilters = async ({
  campaignId,
  filters,
}: {
  campaignId: number;
  filters: {
    startWeekNumber: number;
    startYearNumber: number;
    endWeekNumber: number;
    endYearNumber: number;
  };
}): Promise<LeadPerformanceFilters> => {
  const { data } = await apiService.get(
    `${Endpoints.Campaigns.Get.Leads.PerformanceFilters(
      campaignId
    )}?${paramsToQueryUrl({
      params: { filters },
      includeFirstAmpersand: false,
    })}`
  );
  return data;
};
