import { ScaleBand } from "d3-scale";
import { Line } from "@visx/shape";
import { Text } from "@visx/text";
import { scaleTime } from "@visx/scale";
import { FC, Fragment, ReactElement, useMemo } from "react";
import { useTooltip } from "@visx/tooltip";
import { isDefined } from "@intentsify/utils";
import { TooltipInPortalProps } from "@visx/tooltip/lib/hooks/useTooltipInPortal";
import { BasicTooltip } from "../../BasicTooltip";
import {
  MarkerLineType,
  lineTypeToDashArray,
  MarkerNamePlacement,
} from "./Markers.utils";

export interface MarkersProps {
  markers: Array<{
    labels: Array<{
      name: string;
      placement: MarkerNamePlacement;
      lineType?: MarkerLineType;
      color?: string;
    }>;
    description: React.ReactElement;
    isoDate: string;
    lineType?: MarkerLineType;
    color?: string;
    width?: number;
  }>;
  timeScale: ScaleBand<string>;
  height: number;
  offsetLeft: number;
  offsetTop: number;
  tooltipInPortal: FC<TooltipInPortalProps>;
}

export const Markers = ({
  markers,
  timeScale: steppedTimeScale,
  height,
  tooltipInPortal,
  offsetLeft,
  offsetTop,
}: MarkersProps) => {
  const {
    showTooltip,
    tooltipLeft,
    tooltipData,
    tooltipTop,
    tooltipOpen,
    hideTooltip,
  } = useTooltip<ReactElement>();

  const continuousTimeScale = useMemo(() => {
    const domain = steppedTimeScale.domain();
    const bandwith = steppedTimeScale.bandwidth();
    return scaleTime({
      range: [
        (steppedTimeScale(domain[0]) as number) + bandwith / 2,
        (steppedTimeScale(domain[domain.length - 1]) as number) + bandwith / 2,
      ],
      domain: [domain[0], domain[domain.length - 1]].map((d) => new Date(d)),
    });
  }, [steppedTimeScale]);

  return (
    <>
      {markers.map((marker) => {
        const x = continuousTimeScale(new Date(marker.isoDate));
        const bottomLabel = marker.labels.find(
          ({ placement }) => placement === "bottom"
        );
        const topLabel = marker.labels.find(
          ({ placement }) => placement === "top"
        );
        const hasBottomLabel = bottomLabel !== undefined;
        const hasTopLabel = topLabel !== undefined;
        const hasBothLabels = hasBottomLabel && hasTopLabel;

        const labelHeight = 20;
        const labelOffset = 4;
        const markerTooltipLeft = x + offsetLeft;
        const isHighlighted = tooltipOpen && tooltipData === marker.description;
        const strokeWidth = marker.width ?? 1;
        const highlightStrokeWidth = strokeWidth + 2;

        const yFrom = hasTopLabel ? labelHeight - offsetTop + labelOffset : 0;
        const yTo = hasBottomLabel ? height - labelHeight : height;
        const yMid = height / 2;
        const calculatedStrokeWidth = isHighlighted
          ? highlightStrokeWidth
          : strokeWidth;
        const fontSize = 12;
        const mouseEvents = {
          onMouseEnter: (e: React.MouseEvent<SVGElement>) => {
            const target = e.target as unknown as SVGElement | null;
            if (!target?.ownerSVGElement) {
              return;
            }

            showTooltip({
              tooltipLeft: markerTooltipLeft,
              tooltipTop:
                e.clientY - target.ownerSVGElement.getBoundingClientRect().y,
              tooltipData: marker.description,
            });
          },
          onMouseLeave: () => {
            hideTooltip();
          },
        };
        const fontWeight = isHighlighted ? "bold" : undefined;

        return (
          <Fragment key={marker.isoDate}>
            {hasTopLabel && (
              <Text
                x={x}
                y={0}
                textAnchor="middle"
                fill={topLabel.color ?? marker.color}
                fontWeight={fontWeight}
                fontSize={fontSize}
                cursor="default"
                {...mouseEvents}
              >
                {topLabel.name}
              </Text>
            )}
            <Line
              from={{
                x,
                y: yFrom,
              }}
              to={{
                x,
                y: hasBothLabels ? yMid : yTo,
              }}
              stroke={topLabel?.color ?? bottomLabel?.color ?? marker.color}
              strokeWidth={calculatedStrokeWidth}
              strokeDasharray={lineTypeToDashArray(
                topLabel?.lineType ??
                  bottomLabel?.lineType ??
                  (marker.lineType as MarkerLineType)
              )}
              {...mouseEvents}
            />
            {hasBothLabels && (
              <Line
                from={{
                  x,
                  y: yMid,
                }}
                to={{
                  x,
                  y: yTo,
                }}
                stroke={bottomLabel?.color ?? marker.color}
                strokeWidth={calculatedStrokeWidth}
                strokeDasharray={lineTypeToDashArray(
                  bottomLabel?.lineType ?? (marker.lineType as MarkerLineType)
                )}
                {...mouseEvents}
              />
            )}
            {hasBottomLabel && (
              <Text
                x={x}
                y={height - labelOffset}
                textAnchor="middle"
                fill={bottomLabel.color ?? marker.color}
                fontWeight={fontWeight}
                fontSize={fontSize}
                cursor="default"
                {...mouseEvents}
              >
                {bottomLabel.name}
              </Text>
            )}
          </Fragment>
        );
      })}

      {tooltipOpen &&
        tooltipData &&
        isDefined(tooltipLeft) &&
        isDefined(tooltipTop) && (
          <BasicTooltip
            TooltipInPortal={tooltipInPortal}
            tooltipLeft={tooltipLeft}
            tooltipTop={tooltipTop}
            tooltipData={tooltipData}
          />
        )}
    </>
  );
};
