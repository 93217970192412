import {
  BaseSignalRow,
  Dynamic1Row,
  SiteVisitorSignalRow,
} from "@intentsify/types";
import { ColumnDef } from "@tanstack/react-table";
import { EmptyCell } from "components";
import { ValueWithStat } from "../ValueWithStat";

export const locationColumn: ColumnDef<BaseSignalRow> = {
  header: "Locations",
  accessorKey: "location",
  cell: ({ row }) => {
    return row.original?.location.filter((l) => l).join(" | ");
  },
};

const baseWeekStageColumn: ColumnDef<BaseSignalRow> = {
  header: "Current Stage",
  accessorKey: "baseWeekStage",
  cell: ({ row }) => {
    if (!row?.original?.baseWeekStage) {
      return <EmptyCell />;
    }

    return row?.original.baseWeekStage;
  },
};

const previousWeekStageColumn: ColumnDef<BaseSignalRow> = {
  header: "Previous Stage",
  accessorKey: "previousWeekStage",
  cell: ({ row }) => {
    if (!row?.original?.previousWeekStage) {
      return <EmptyCell />;
    }

    return row?.original.previousWeekStage;
  },
};

const DYNAMIC1_SIGNAL_ROW_ACCESSOR = "Rank";

export const getColumns = ({
  showOnlyName,
  showLocation = false,
  showStage = false,
}: {
  showOnlyName: boolean;
  showLocation?: boolean;
  showStage?: boolean;
}): Array<ColumnDef<BaseSignalRow>> => {
  if (showOnlyName) {
    return [
      {
        header: "Name",
        accessorKey: "name",
      },
      locationColumn,
    ];
  }

  const columns: Array<ColumnDef<BaseSignalRow>> = [
    {
      header: "Name",
      accessorKey: "name",
    },
    {
      header: "Current Score",
      accessorKey: "baseWeekScore",
      cell: ({ row }) => {
        if (row.original?.baseWeekScore && row.original?.previousWeekScore) {
          const isDynamic1Signal =
            row.original?.name === DYNAMIC1_SIGNAL_ROW_ACCESSOR;
          return (
            <ValueWithStat
              display={isDynamic1Signal ? "no-display" : "number"}
              inverted={isDynamic1Signal}
              currentWeek={row.original?.baseWeekScore}
              previousWeek={row.original?.previousWeekScore}
              tooltipDescription={
                isDynamic1Signal
                  ? "Accounts are ranked based on relevancy of research activities to your campaign messaging."
                  : undefined
              }
            />
          );
        }

        if (row.original?.baseWeekScore) {
          return `${row.original?.baseWeekScore} (New)`;
        }

        return null;
      },
    },
    {
      header: "Previous Score",
      accessorKey: "previousWeekScore",
    },
  ];

  if (showLocation) {
    columns.push(locationColumn);
  }

  if (showStage) {
    columns.splice(2, 0, baseWeekStageColumn);
    columns.splice(4, 0, previousWeekStageColumn);
  }

  return columns;
};

export const getDynamic1Columns = (): Array<ColumnDef<Dynamic1Row>> => {
  const columns: Array<ColumnDef<Dynamic1Row>> = [
    {
      header: "Name",
      accessorKey: "name",
    },
    {
      header: "Current (week)",
      accessorKey: "baseWeekScore",
      cell: ({ row }) => {
        if (row.original?.baseWeekScore && row.original?.previousWeekScore) {
          return (
            <ValueWithStat
              display="no-display"
              inverted={true}
              currentWeek={row.original?.baseWeekScore}
              previousWeek={row.original?.previousWeekScore}
              tooltipDescription="Accounts are ranked based on relevancy of research activities to your campaign messaging."
            />
          );
        }

        if (row.original?.baseWeekScore) {
          return `${row.original?.baseWeekScore} (New)`;
        }

        return null;
      },
    },
    {
      header: "Previous (week)",
      accessorKey: "previousWeekScore",
    },
  ];

  return columns;
};

export const getColumnsSiteVisitorAnDisplay = (): Array<
  ColumnDef<SiteVisitorSignalRow>
> => {
  return [
    {
      header: "Name",
      accessorKey: "name",
    },
    {
      header: "Week",
      accessorKey: "baseWeekScore",
    },
    {
      header: "30 days",
      accessorKey: "score30Days",
    },
    {
      header: "60 days",
      accessorKey: "score60Days",
    },
    {
      header: "Total",
      accessorKey: "scoreTotal",
    },
  ];
};
