import { isDefined, isPopulatedArray } from "@intentsify/utils";
import { BaseFilter, BaseFilterRecordValue, FetchDataParams } from "types";
import { normalizeFilters } from "./normalizeFilters";

/**
 * Use to combine FetchDataParams and BaseFilter objects.
 * E.g.: you have set of constant params but you also have
 * mutable filters and you want to merge them togheter before
 * making a request.
 *
 * Removes empty arrays.
 */
export const combineParamsAndFilters = <
  T,
  P extends Partial<FetchDataParams<unknown, BaseFilter<T>>>,
  F extends BaseFilter<T>
>(
  params: P,
  filters?: F
) => {
  if (!filters) {
    return params;
  }

  const f = Object.keys(filters)
    .map((k) => {
      // @ts-ignore
      const filterItem = filters[k];
      if (Array.isArray(filterItem)) {
        if (!isPopulatedArray(filterItem)) {
          return undefined;
        }

        return [k, filterItem];
      }

      return [k, filterItem];
    })
    .filter(isDefined) as [string, BaseFilterRecordValue][];

  return {
    ...params,
    filters: normalizeFilters(Object.fromEntries(f.filter((f) => f[1]))),
  };
};
