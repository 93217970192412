import {
  Box,
  Table as ChakraTable,
  Flex,
  FlexProps,
  Tbody,
  Td,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { css } from "@emotion/react";
import { SortDirection } from "@intentsify/types";
import {
  ColumnDef,
  OnChangeFn,
  RowData,
  RowSelectionState,
  SortingState,
  Updater,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { memo, useEffect, useRef } from "react";
import { useVirtual } from "react-virtual";
import { NoData } from "../../NoData";
import { HeaderGroup, Row } from "../components";
import { useColumns } from "../useColumns";
import { useSorting } from "../useSorting";

export type Selectable =
  | boolean
  | "checkbox-multi-select"
  | "clickable-single-select";

interface TableVirtualisedProps<Data extends RowData> {
  data: Data[];
  columns: ColumnDef<Data>[];
  selectable?: Selectable;
  clearSelectionOnSort?: boolean;
  rowSelection?: RowSelectionState;
  setRowSelection?: OnChangeFn<RowSelectionState>;
  defaultSortByColumn?: keyof Data;
  defaultSortDirection?: SortDirection;
  containerProps?: FlexProps;
  onSortingChange?: (sorting: Updater<SortingState>) => void;
  options?: boolean;
  hideHeader?: boolean;
  showRowNumbers?: boolean;
  variant?: string;
  isSortDisabled?: boolean;
  enableSortingRemoval?: boolean;
}

const getColumns = <Data extends RowData>(
  columns: ColumnDef<Data>[],
  options: { showRowNumbers: boolean }
) => {
  const rowNumberColumn: ColumnDef<Data> = {
    id: "rowNumber",
    header: "",
    cell: (info) => <b>{info.row.index + 1}.</b>,
  };

  if (options.showRowNumbers) {
    return [rowNumberColumn, ...columns];
  }

  return columns;
};

const TableVirtualisedInner = <Data extends RowData>({
  data,
  columns,
  selectable = false,
  clearSelectionOnSort = true,
  rowSelection,
  setRowSelection,
  defaultSortByColumn,
  defaultSortDirection,
  containerProps,
  onSortingChange,
  hideHeader = false,
  showRowNumbers = false,
  variant = "corporate",
  isSortDisabled = false,
  enableSortingRemoval = true,
}: TableVirtualisedProps<Data>) => {
  const tableColumns = useColumns<Data>(
    getColumns(columns, { showRowNumbers }),
    {
      selectable,
      selectColumnProps: { size: 48, maxSize: 48 },
    }
  );

  const [sorting, setSorting] = useSorting<Data>(
    defaultSortByColumn,
    defaultSortDirection
  );

  useEffect(() => {
    if (defaultSortByColumn && !isSortDisabled) {
      setSorting([
        {
          id: String(defaultSortByColumn),
          desc: defaultSortDirection === SortDirection.DESC,
        },
      ]);
    }
  }, [defaultSortByColumn, defaultSortDirection, setSorting, isSortDisabled]);

  const table = useReactTable({
    data,
    columns: tableColumns,
    state: { sorting: !isSortDisabled ? sorting : undefined, rowSelection },
    enableRowSelection: Boolean(selectable),
    enableMultiRowSelection: Boolean(selectable),
    enableSortingRemoval,
    onRowSelectionChange: (rowSelectionState) => {
      if (selectable) {
        setRowSelection?.(rowSelectionState);
      }
    },
    onSortingChange: !isSortDisabled
      ? (e) => {
          if (selectable && clearSelectionOnSort) {
            setRowSelection?.({});
          }

          setSorting(e);
          onSortingChange?.(e);
        }
      : undefined,
    getCoreRowModel: getCoreRowModel(),
  });

  const { rows } = table.getRowModel();

  const tableContainerRef = useRef<HTMLDivElement>(null);
  const { virtualItems: virtualRows, totalSize } = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: 20,
  });

  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
      : 0;

  const clickableRowsCss = css`
    tr {
      cursor: pointer;
    }
    tr:hover {
      background: rgba(95, 221, 188, 0.1);
    }
  `;

  if (data.length === 0) {
    return <NoData stretch height={0} />;
  }

  return (
    <Flex
      direction="column"
      fontWeight="md"
      rounded="md"
      shadow="sm"
      maxWidth="100%"
      {...containerProps}
    >
      <Box
        ref={tableContainerRef}
        overflow="auto"
        willChange="scroll-position"
        css={selectable === "clickable-single-select" ? clickableRowsCss : ""}
      >
        <ChakraTable position="relative" size="sm" variant={variant}>
          {!hideHeader && (
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <HeaderGroup
                  isSticky
                  isSortDisabled={isSortDisabled}
                  key={headerGroup.id}
                  headerGroup={headerGroup}
                />
              ))}
            </Thead>
          )}

          <Tbody>
            {paddingTop > 0 && (
              <Tr>
                <Td height={`${paddingTop}px`}></Td>
              </Tr>
            )}

            {virtualRows.map((virtualRow) => {
              const row = rows[virtualRow.index];

              return (
                <Row
                  selected={selectable ? row.getIsSelected() : undefined}
                  onClick={(
                    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>
                  ) => {
                    if (selectable === "clickable-single-select") {
                      table.resetRowSelection();
                    }
                    row.getToggleSelectedHandler()(event);
                  }}
                  key={row.id}
                  row={row}
                  colSpan={tableColumns.length + 1}
                  selectable={selectable}
                />
              );
            })}

            {paddingBottom > 0 && (
              <Tr>
                <Td height={`${paddingBottom}px`} />
              </Tr>
            )}
          </Tbody>
        </ChakraTable>
      </Box>
    </Flex>
  );
};

export const TableVirtualised = memo(
  TableVirtualisedInner
) as typeof TableVirtualisedInner;
