import { InfoIcon } from "@chakra-ui/icons";
import {
  Box,
  Checkbox,
  HStack,
  Icon,
  SimpleGrid,
  Stack,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useHasAccessTo } from "@intentsify/authorization/dist/react";
import {
  NotificationTransportMethods,
  NotificationTypes,
} from "@intentsify/types";
import { isPopulatedArray } from "@intentsify/utils";
import { useMutation } from "@tanstack/react-query";
import { Tooltip } from "components";
import capitalize from "lodash/capitalize";
import startCase from "lodash/startCase";
import { useState } from "react";
import { MdNotificationsNone } from "react-icons/md";
import { useUser } from "store/store.hooks";
import { useAppStore } from "store/useAppStore";
import { useComponentColors } from "theme";
import { useDeepEffect } from "utils";
import {
  getUserNotificationSettings,
  setUserNotificationSetting,
} from "./NotificationSettings.requests";
import { SerializedNotificationSetting } from "./NotificationSettings.types";
import {
  getTransportMethodDescription,
  serializeNotificationSettings,
} from "./NotificationSettings.utils";

const NotificationSettings = () => {
  const user = useUser();
  const userId = Number(user?.userId);
  const settings = user?.settings;
  const setUserNotificationSettings =
    useAppStore.use.setUserNotificationSettings();

  const hasAccessToNotifications = useHasAccessTo(
    "miscellaneous.notifications"
  );

  const toast = useToast();
  const componentColors = useComponentColors();

  const [notificationSettings, setNotificationSettings] = useState<
    SerializedNotificationSetting[]
  >([]);

  const { mutate } = useMutation(setUserNotificationSetting, {
    onSuccess: async () => {
      const notificationSettings = await getUserNotificationSettings(
        Number(userId)
      );

      setUserNotificationSettings(notificationSettings);

      toast({
        title: `The notification setting was updated successfully.`,
        status: "success",
      });
    },
  });

  useDeepEffect(() => {
    if (settings?.notifications && isPopulatedArray(settings.notifications)) {
      setNotificationSettings(
        serializeNotificationSettings(settings.notifications)
      );
    }
  }, [settings?.notifications]);

  const handleCheckboxChange = (
    notificationType: NotificationTypes,
    disableNotification: boolean,
    transportMethod: NotificationTransportMethods
  ) => {
    const userNotificationSettings = notificationSettings.map(
      (notificationSetting) => {
        const { settings, notificationId } = notificationSetting;

        if (notificationType === notificationId) {
          const newSettings = settings.map((setting) => {
            if (setting.transportMethod === transportMethod) {
              return { ...setting, disabledNotification: disableNotification };
            }
            return setting;
          });

          return { ...notificationSetting, settings: newSettings };
        }
        return notificationSetting;
      }
    );

    setNotificationSettings(userNotificationSettings);

    mutate({
      userId,
      notificationType,
      disableNotification,
      transportMethod,
    });
  };

  if (!hasAccessToNotifications) {
    return <></>;
  }

  return (
    <Box>
      <VStack alignItems="flex-start" spacing={2}>
        <HStack spacing={2}>
          <Icon
            as={MdNotificationsNone}
            width="1.63rem"
            height="1.63rem"
            color="gray.400"
          />

          <Text fontWeight="bold" fontSize="2xl">
            Notifications
          </Text>
        </HStack>

        <Text color="gray.500" fontSize="sm">
          Choose how you receive notifications. Enable or disable a given type
          of notification. <br />
          The changes will take effect immediately.
        </Text>
      </VStack>

      <Box ml={4} mt={4} maxWidth="1100px">
        <SimpleGrid columns={1} spacingY={5}>
          {notificationSettings?.map(
            ({
              notificationId,
              settings,
              notificationName,
              notificationDescription,
            }) => (
              <Stack
                key={`stack_${notificationName}`}
                mb={4}
                p={4}
                rounded="lg"
                shadow="md"
                bg={componentColors.form.stackBg}
              >
                <>
                  <Box>
                    <Text fontWeight="bold" fontSize="md">
                      {startCase(notificationName)}
                    </Text>
                    <Text
                      color="gray.500"
                      fontSize="sm"
                      mb={4}
                      mt={4}
                      maxWidth={800}
                    >
                      {notificationDescription}
                    </Text>
                  </Box>
                  <HStack>
                    {settings.map(
                      ({ disabledNotification, transportMethod }) => (
                        <Box
                          mr={4}
                          key={`b_${notificationId}_${transportMethod}`}
                        >
                          <HStack>
                            <Checkbox
                              isChecked={!disabledNotification}
                              id={`cb_${notificationId}_${transportMethod}`}
                              onChange={() => {
                                handleCheckboxChange(
                                  notificationId,
                                  !disabledNotification,
                                  transportMethod
                                );
                              }}
                              pl={0}
                            />
                            <Text fontWeight="bold" fontSize="sm">
                              {capitalize(transportMethod)}
                            </Text>

                            <Tooltip
                              aria-label={`Notification description for ${transportMethod}`}
                              label={getTransportMethodDescription(
                                transportMethod
                              )}
                            >
                              <InfoIcon w={3} h={3} ml={2} />
                            </Tooltip>
                          </HStack>
                        </Box>
                      )
                    )}
                  </HStack>
                </>
              </Stack>
            )
          )}
        </SimpleGrid>
      </Box>
    </Box>
  );
};

export { NotificationSettings };
