import { CampaignTopic, Node } from "@intentsify/types";
import { Endpoints, apiService } from "api";

export const getTopicsAggregated = async (): Promise<Node[]> => {
  const { data } = await apiService.get<Node[]>(
    Endpoints.Signals.Get.TopicsAggregated
  );
  return data;
};

export const getAllTopics = async (): Promise<CampaignTopic[]> => {
  const { data } = await apiService.get<CampaignTopic[]>(
    Endpoints.Signals.Get.Topics
  );
  return data;
};
