import {
  Avatar,
  Center,
  ChakraProps,
  Flex,
  HStack,
  Spinner,
  StackProps,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Campaign } from "@intentsify/types";
import startCase from "lodash/startCase";
import { PropsWithChildren } from "react";
import { IntegrationWithLogo } from "../../IntegrationsModal.utils";

const ProviderRoot = ({
  children,
  ...props
}: PropsWithChildren<StackProps>) => {
  return (
    <VStack
      p="3"
      w="100%"
      spacing="3"
      border="1px solid"
      borderColor="whiteAlpha.100"
      borderRadius="lg"
      {...props}
    >
      {children}
    </VStack>
  );
};

const ProviderHeader = ({
  integration,
  color = "whiteAlpha.700",
  isLoading,
  children,
}: PropsWithChildren<{
  integration: IntegrationWithLogo;
  color?: ChakraProps["color"];
  isLoading?: boolean;
}>) => {
  return (
    <HStack w="100%" spacing="3">
      <Avatar
        height="2.25rem"
        width="2.25rem"
        src={integration.logoUrl}
        ignoreFallback
      />

      <Text fontSize="xl" color={color}>
        {startCase(integration.provider.toLowerCase())}
      </Text>

      <Center>{isLoading && <Spinner size="sm" />}</Center>

      <Flex flex="1" justifyContent="flex-end">
        {children}
      </Flex>
    </HStack>
  );
};

export interface TagCampaign {
  campaignId: Campaign["campaignId"];
  displayName: Campaign["displayName"];
}

const ProviderTagCampaigns = (props: {
  campaigns: TagCampaign[];
  onRemoveClick?: (campaignId: Campaign["campaignId"]) => void;
}) => {
  return (
    <>
      {props.campaigns.map((campaign) => (
        <Tag
          key={campaign.campaignId}
          bg="whiteAlpha.100"
          borderRadius="full"
          variant="subtle"
          mb="2"
          mr="2"
        >
          <TagLabel textColor="gray.25">{campaign.displayName}</TagLabel>

          {props.onRemoveClick && (
            <TagCloseButton
              onClick={() => {
                props.onRemoveClick?.(campaign.campaignId);
              }}
            />
          )}
        </Tag>
      ))}
    </>
  );
};

export const ProviderCard = {
  Root: ProviderRoot,
  Header: ProviderHeader,
  TagCampaigns: ProviderTagCampaigns,
};
