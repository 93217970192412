import {
  AudienceSegmentDefinition,
  defaultAudienceSegmentDefinition,
} from "@intentsify/types";
import { atom } from "recoil";

export const AudienceSegmentsState = {
  audienceSegmentDefinitionAtom: atom<AudienceSegmentDefinition>({
    key: `audienceSegmentsState_audienceSegmentDefinitionAtom`,
    default: defaultAudienceSegmentDefinition,
  }),
};
