import { Box, ColorMode, Flex } from "@chakra-ui/react";
import {
  CSSWithMultiValues,
  RecursiveCSSObject,
  useMultiStyleConfig,
} from "@chakra-ui/system";
import { GroupBase, OptionProps as SelectOptionProps } from "react-select";
import { Option as IntentsifyOption } from "@intentsify/types";
import { ITEM_HEIGHT } from "./Select.const";
import { colors } from "theme";

interface ItemProps extends CSSWithMultiValues {
  _disabled: CSSWithMultiValues;
  _focus: CSSWithMultiValues;
}

const ListItem = <
  Option extends IntentsifyOption,
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: SelectOptionProps<Option, IsMulti, Group> & { colorMode: ColorMode }
) => {
  const {
    innerRef,
    innerProps,
    children,
    isFocused,
    isDisabled,
    data,
    colorMode,
  } = props;
  const { meta }: Record<string, any> = data;

  const menuStyles = useMultiStyleConfig("Menu", {});

  return (
    <Box
      // Required for E2E tests
      className="select-list-item"
      role="button"
      sx={{
        ...menuStyles.item,
        w: "100%",
        textAlign: "left",
        bg: isFocused
          ? colorMode === "light"
            ? "gray.100"
            : "whiteAlpha.100"
          : "transparent",
        ...(isDisabled &&
          (menuStyles.item as RecursiveCSSObject<ItemProps>)._disabled),
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        height: `${ITEM_HEIGHT}px`,
        display: "flex",
        justifyContent: "space-between",
      }}
      _hover={{
        bg: colorMode === "light" ? colors.gray[100] : colors.brand[100],
      }}
      ref={innerRef}
      {...innerProps}
      {...(isDisabled && { disabled: true })}
    >
      <Flex w="100%" justifyContent="space-between">
        <Flex flex={1} minWidth={0}>
          <Box
            mr={2}
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
          >
            {children}
          </Box>
        </Flex>

        <Flex>
          {typeof meta?.renderListItemAdditionalContent === "function" && (
            <>{meta?.renderListItemAdditionalContent()}</>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

export { ListItem };
