import { Link, Text } from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import { LoginScreenDefinition } from "auth/screens/Login/Login.definition";

const BackToLoginLink = () => {
  return (
    <Text display="block" color={"gray.100"} _hover={{ color: "green.200" }}>
      <Link
        as={ReactRouterLink}
        style={{ textDecoration: "none" }}
        to={LoginScreenDefinition.navigate()}
      >
        Back to login
      </Link>
    </Text>
  );
};

export { BackToLoginLink };
