import { Box, Flex } from "@chakra-ui/layout";
import { useColorModeValue } from "@chakra-ui/react";
import { LegendItem, LegendLabel, LegendOrdinal } from "@visx/legend";
import { scaleOrdinal } from "@visx/scale";
import { OverflowableText } from "components/Text";
import { useRecoilState, useSetRecoilState } from "recoil";
import { getLegendDirection } from "../Charts.utils";
import { PieChartProps } from "./PieChart";
import {
  disabledLegendItemsFamily,
  legendHoverAtomFamily,
} from "./PieChart.state";
import { PieColor } from "./PieChart.utils";

type LegendProps = { colors: PieColor[]; uniqueKey: string } & Pick<
  PieChartProps,
  "data" | "legendPosition"
>;

const Legend = ({ uniqueKey, data, legendPosition, colors }: LegendProps) => {
  const setHoverItem = useSetRecoilState(legendHoverAtomFamily(uniqueKey));

  const ordinalColorScale = scaleOrdinal({
    domain: data.map((item) => item.label),
    range: data.map((_, i) => colors[i]),
  });

  const [disabledLegendItems, setDisabledLegendItems] = useRecoilState(
    disabledLegendItemsFamily(uniqueKey)
  );

  const legendGlyphSize = 14;

  const legendItemHoverBg = useColorModeValue(
    "blackAlpha.100",
    "whiteAlpha.100"
  );

  return (
    <LegendOrdinal
      scale={ordinalColorScale}
      labelFormat={(label) => `${String(label)}`}
    >
      {(labels) => (
        <div
          style={{
            display: "flex",
            flexDirection: getLegendDirection(legendPosition),
            width: "100%",
            flexWrap: "wrap",
          }}
        >
          {labels.map((label, i) => {
            const isDisabled = disabledLegendItems.find(
              (i) => i.label === data[label.index].label
            );

            const color = colors[i];

            if (!color) {
              return null;
            }

            return (
              <Flex
                alignItems="center"
                key={`legend-quantile-${i}`}
                maxW="100%"
              >
                <LegendItem
                  style={{
                    maxWidth: "100%",
                  }}
                  onMouseOver={() => {
                    if (!isDisabled) {
                      setHoverItem(data[label.index]);
                    }
                  }}
                  onMouseLeave={() => {
                    setHoverItem(undefined);
                  }}
                >
                  <Flex
                    maxW="100%"
                    alignItems="center"
                    borderRadius={4}
                    _hover={{ background: legendItemHoverBg }}
                    px={3}
                    cursor="pointer"
                    opacity={isDisabled ? 0.2 : 1}
                    onClick={() => {
                      if (!isDisabled) {
                        setDisabledLegendItems([
                          ...disabledLegendItems,
                          data[label.index],
                        ]);
                        setHoverItem(undefined);
                      } else {
                        setDisabledLegendItems(
                          disabledLegendItems.filter(
                            (i) => i.label !== data[label.index].label
                          )
                        );
                      }
                    }}
                  >
                    <Box mr={2}>
                      <svg width={legendGlyphSize} height={legendGlyphSize}>
                        <rect
                          fill={color.value}
                          width={legendGlyphSize}
                          height={legendGlyphSize}
                        />
                      </svg>
                    </Box>
                    <LegendLabel
                      align="left"
                      flex={1}
                      style={{ overflow: "hidden" }}
                    >
                      <OverflowableText
                        aria-label="Legend"
                        whiteSpace="nowrap"
                        fontSize="small"
                      >
                        {label.datum}
                      </OverflowableText>
                    </LegendLabel>
                  </Flex>
                </LegendItem>

                <Flex grow={1}></Flex>
              </Flex>
            );
          })}
        </div>
      )}
    </LegendOrdinal>
  );
};

export { Legend };
