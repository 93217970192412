import { Center, Heading } from "@chakra-ui/react";
import { BuyerResearchStage, SortDirection } from "@intentsify/types";
import { Progress } from "components";
import { useMemo } from "react";
import {
  Signal,
  SignalsMap,
} from "screens/Campaigns/screens/CampaignDetails/CampaignDetails.types";
import { useAvailableSignals } from "screens/Campaigns/screens/CampaignDetails/CampaignDetailsTable/CampaignDetailsTable.hooks";
import { usePrefetchReviewedAssets } from "screens/ContentLibrary/Completed/useReviewedAssets";
import { SignalsTabs } from "../../SignalsTabs";

type ExpandedRowProps = {
  isExpanded: boolean;
  signals: Signal[];
  campaignId: number;
  domainId: number;
  weekNumber: number;
  yearNumber: number;
  buyerResearchStage: BuyerResearchStage;
};

const ExpandedRow = ({
  signals,
  campaignId,
  domainId,
  weekNumber,
  yearNumber,
  buyerResearchStage,
}: ExpandedRowProps) => {
  usePrefetchReviewedAssets({
    campaignIds: [campaignId],
    buyerResearchStages: [buyerResearchStage],
    order: SortDirection.DESC,
    order_by: "updatedAt",
    pageToken: "",
  });

  const { data: availableSignals, isLoading } = useAvailableSignals({
    campaignId: campaignId,
    domainId: domainId,
    weekNumber,
    yearNumber,
  });

  const signalsMap = useMemo(
    () =>
      signals.reduce<SignalsMap>((acc, signal) => {
        acc[signal.signalId] = signal;
        return acc;
      }, {}),
    [signals]
  );

  if (isLoading) {
    return <Progress isIndeterminate colorScheme="blue" />;
  }

  if (!availableSignals) {
    return null;
  }

  return availableSignals.length > 0 ? (
    <SignalsTabs
      signalsMap={signalsMap}
      domainSignals={availableSignals}
      campaignId={campaignId}
      domainId={domainId}
      weekNumber={weekNumber}
      yearNumber={yearNumber}
      buyerResearchStage={buyerResearchStage}
    />
  ) : (
    <Center>
      <Heading as="h2" size="sm" pb={4}>
        There is no signals for this account
      </Heading>
    </Center>
  );
};

export { ExpandedRow };
