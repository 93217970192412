import { Text } from "@chakra-ui/react";
import { WeeklySnapshotRangeDTO } from "@intentsify/dto";
import { decimalToPercentage } from "@intentsify/utils";
import { useQuery } from "@tanstack/react-query";
import {
  Card,
  MIN_MODAL_CONTENT_HEIGHT,
  TornadoChart,
  TornadoChartData,
  TornadoChartLabels,
} from "components";
import { useMemo, useState } from "react";
import {
  ChartExport,
  useExportableChart,
} from "../../../../../../../../shared/components";
import { getContentAlignmentAnalysis } from "./ContentAlignmentAnalysis.requests";

type ContentAlignmentAnalysisProps = {
  campaignId: number;
  filters: WeeklySnapshotRangeDTO;
};

const sideLabels = [
  "TAL Research Focus",
  "Your Content Focus",
] as TornadoChartLabels;

const TOPICS_LIMIT = 10;

export const ContentAlignmentAnalysis = ({
  campaignId,
  filters,
}: ContentAlignmentAnalysisProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { data, isFetching } = useQuery(
    ["getContentAlignmentAnalysis", campaignId, filters],
    () => getContentAlignmentAnalysis(campaignId, filters, TOPICS_LIMIT)
  );

  const chartData = useMemo(() => {
    if (!data) {
      return [
        {
          items: [],
          maxReferenceScore: 0,
        },
        {
          items: [],
          maxReferenceScore: 0,
        },
      ] as TornadoChartData;
    }

    const { topicScoreRecords, autoDiscoveryScoreRecords } = data;
    const leftMaxReferenceScore = Math.max(
      ...topicScoreRecords.map(({ valueLeft }) => valueLeft)
    );
    const rightMaxReferenceScore = Math.max(
      ...autoDiscoveryScoreRecords.map(({ valueLeft }) => valueLeft)
    );
    return [
      {
        items: topicScoreRecords.map(({ label, valueLeft, valueRight }) => ({
          name: label,
          leftScore: valueLeft,
          rightScore: valueRight,
        })),
        maxReferenceScore: leftMaxReferenceScore,
      },
      {
        items: autoDiscoveryScoreRecords.map(
          ({ label, valueLeft, valueRight }) => ({
            name: label,
            leftScore: valueRight,
            rightScore: valueLeft,
          })
        ),
        maxReferenceScore: rightMaxReferenceScore,
      },
    ] as TornadoChartData;
  }, [data]);
  const csvData = useMemo(() => {
    if (!data) {
      return [];
    }
    const topicNames = Array.from(
      new Set(
        [...data.topicScoreRecords, ...data.autoDiscoveryScoreRecords].map(
          ({ label }) => label
        )
      )
    );

    return topicNames.map((topic) => {
      const topicRecord = data.topicScoreRecords.find(
        ({ label }) => label === topic
      );
      const autoDiscoveryRecord = data.autoDiscoveryScoreRecords.find(
        ({ label }) => label === topic
      );
      return {
        topic,
        topicScore: topicRecord?.valueLeft ?? autoDiscoveryRecord?.valueRight,
        autoDiscoveryScore:
          autoDiscoveryRecord?.valueLeft ?? topicRecord?.valueRight,
      };
    });
  }, [data]);

  const tornadoChart = useExportableChart({
    title: "Content Alignment Analysis",
    campaignId,
    chart: (
      <TornadoChart
        minHeight={isExpanded ? MIN_MODAL_CONTENT_HEIGHT : undefined}
        data={chartData}
        labels={sideLabels}
        defaultSortSide="left"
        tooltipRenderer={({ sortSide, hoverSide, name }) => {
          if (!data) {
            return <></>;
          }
          const sideIndex = sortSide === "left" ? 0 : 1;
          const hoverSideIndex = hoverSide === "left" ? 0 : 1;
          const otherSortSideIndex = sideIndex === 0 ? 1 : 0;
          const sortDataSide =
            data[
              sortSide === "left"
                ? "topicScoreRecords"
                : "autoDiscoveryScoreRecords"
            ];
          const hoverDataSide =
            data[
              hoverSide === "left"
                ? "topicScoreRecords"
                : "autoDiscoveryScoreRecords"
            ];
          const record = sortDataSide.find(({ label }) => label === name);

          if (!record) {
            return <></>;
          }

          const currentRank = record.rankLeft;
          const otherRank = record.rankRight;
          const currentMax = hoverDataSide[0].valueLeft;

          const currentPercentage = decimalToPercentage(
            (sortSide === hoverSide ? record.valueLeft : record.valueRight) /
              currentMax,
            0
          );
          return (
            <>
              <Text>{name}</Text>
              <Text>
                <Text as="strong">Ranked #{currentRank}</Text> in{" "}
                {sideLabels[sideIndex]} vs <Text as="strong">#{otherRank}</Text>{" "}
                in {sideLabels[otherSortSideIndex]}
              </Text>
              <Text>
                <Text as="strong">
                  Scored in the {currentPercentage} percentile
                </Text>{" "}
                in {sideLabels[hoverSideIndex]}
              </Text>
            </>
          );
        }}
      />
    ),
  });
  return (
    <Card
      onExpandedChange={setIsExpanded}
      title={tornadoChart.title}
      isLoading={isFetching}
      isExpandable
      expandedSize="viewContainer"
      hasData={
        !isFetching &&
        Boolean(chartData?.[0].items.length) &&
        Boolean(chartData?.[1].items.length)
      }
      titleTooltip="AI-generated, aggregate comparison of your TAL's research focus vs. your content’s focus by topic, used to ensure asset alignment for campaigns and/or identify content gaps."
      noDataMessage="Trending data is configuring and will be available after two weeks from campaign launch."
      actions={
        <ChartExport
          size="small"
          campaignId={campaignId}
          title={tornadoChart.title}
          onExportPNG={tornadoChart.downloadAsPng}
          data={csvData}
        />
      }
    >
      {tornadoChart.component}
      {tornadoChart.exportComponent}
    </Card>
  );
};
