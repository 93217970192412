import { ResetPasswordScreen } from "types";
import { createScreen } from "utils/createScreen";

const ResetPasswordScreenDefinition = createScreen({
  name: ResetPasswordScreen,
  path: "/auth/reset-password",
  routePath: ["/reset-password"],
});

export { ResetPasswordScreenDefinition };
