import { Flex } from "@chakra-ui/react";
import { StatCard, StatCardProps } from "./StatCard";

type StatCardsContainerProps = {
  items: StatCardProps[];
};

const StatCardsContainer = ({ items }: StatCardsContainerProps) => {
  return (
    <Flex wrap={"wrap"} gap="4" mb={4} w="100%">
      {items.map((item, index) => {
        return (
          <Flex key={index} flex={1}>
            <StatCard {...item} />
          </Flex>
        );
      })}
    </Flex>
  );
};

export { StatCardsContainer };
