import { useToken } from "@chakra-ui/system";
import {
  CampaignImpressionsByLocationRO,
  CampaignTopicsByLocationRO,
} from "@intentsify/dto";
import { Map } from "components";
import { useCallback, useRef } from "react";
import { GeoJSONSource, MapLayerMouseEvent, MapRef } from "react-map-gl";
import { useToggle } from "react-use";
import { LayerVisibility } from "./ImpressionsAndTopicsMap.types";
import {
  impressionsClusterLayerId,
  impressionsPointsLayerId,
  impressionsSourceId,
  topicsClusterLayerId,
  topicsPointsLayerId,
} from "./config";
import {
  useCampaignImpressionsByLocation,
  useCampaignTopicsByLocation,
  useMapInitialBounds,
} from "./hooks";
import { ImpressionsLayers } from "./impressions";
import { useImpressionsPointsPopup } from "./impressions/useImpressionsPointsPopup";
import { TopicsLayers } from "./topics";
import { useTopicsPointsPopup } from "./topics/useTopicsPointsPopup";

interface ImpressionsMapProps<T> {
  id: string | number;
  isGlobe: boolean;
  filters: T;
  getImpressionsByLocation: (
    id: string | number,
    filters: T
  ) => Promise<CampaignImpressionsByLocationRO>;
  getTopicsByLocation: (
    id: string | number,
    filters: T
  ) => Promise<CampaignTopicsByLocationRO>;
  height: number;
}

const ImpressionsAndTopicsMap = <
  T extends {
    yearFrom: number;
    yearTo: number;
    weekFrom: number;
    weekTo: number;
  }
>({
  id,
  filters,
  isGlobe,
  getImpressionsByLocation,
  getTopicsByLocation,
  height,
}: ImpressionsMapProps<T>) => {
  const impressionsByLocation = useCampaignImpressionsByLocation<T>({
    id,
    filters,
    fetchData: getImpressionsByLocation,
  });
  const mapRef = useRef<MapRef>(null);

  const hasImpressionsByLocation =
    impressionsByLocation.data.impressionsByLocation.length > 0;

  const { ImpressionsPopup, handleMouseMove: handleOnImpressionMouseMove } =
    useImpressionsPointsPopup();

  const topicsByLocation = useCampaignTopicsByLocation({
    id,
    filters,
    fetchData: getTopicsByLocation,
  });
  const hasTopicsByLocation = topicsByLocation.data.topicsByLocation.length > 0;
  const { TopicsPopup, handleMouseMove: handleOnTopicMouseMove } =
    useTopicsPointsPopup();

  const initialBounds = useMapInitialBounds([
    ...impressionsByLocation.data.impressionsByLocation,
    ...topicsByLocation.data.topicsByLocation,
  ]);

  const [isTopicsLayerEnabled, setIsTopicsLayerEnabled] = useToggle(true);
  const [isImpressionsLayerEnabled, setIsImpressionsLayerEnabled] =
    useToggle(true);

  const impressionsClustersColor = useToken("colors", "teal.700");
  const topicsClustersColor = useToken("colors", "orange.500");

  const isLoadingData =
    impressionsByLocation.isFetching || topicsByLocation.isFetching;

  const handleClick = (e: MapLayerMouseEvent) => {
    if (!mapRef.current) {
      return;
    }

    const map = mapRef.current;

    const feature = e.features?.[0];
    const clusterId = feature?.properties?.cluster_id;

    if (!clusterId) {
      return;
    }

    const mapboxSource = mapRef.current.getSource(
      impressionsSourceId
    ) as GeoJSONSource;

    mapboxSource.getClusterExpansionZoom(clusterId as number, (err, zoom) => {
      if (err) {
        return;
      }

      const coordinates = (feature?.geometry as any)?.coordinates;

      if (!coordinates || coordinates.filter(Boolean).length === 0) {
        return;
      }

      map.easeTo({
        zoom,
        duration: 500,
        center: coordinates,
      });
    });
  };

  const handleMouseMove = (e: MapLayerMouseEvent) => {
    isImpressionsLayerEnabled && handleOnImpressionMouseMove(e);
    isTopicsLayerEnabled && handleOnTopicMouseMove(e);
  };

  const renderLegend = useCallback(() => {
    return (
      <>
        <Map.MapLegendLayerToggle
          layerName="Impressions"
          isEnabled={isImpressionsLayerEnabled}
          toggleIsEnabled={setIsImpressionsLayerEnabled}
          color={impressionsClustersColor}
        />

        <Map.MapLegendLayerToggle
          layerName="Topics"
          isEnabled={isTopicsLayerEnabled}
          toggleIsEnabled={setIsTopicsLayerEnabled}
          color={topicsClustersColor}
        />
      </>
    );
  }, [
    impressionsClustersColor,
    isImpressionsLayerEnabled,
    isTopicsLayerEnabled,
    setIsImpressionsLayerEnabled,
    setIsTopicsLayerEnabled,
    topicsClustersColor,
  ]);

  const renderSources = useCallback(() => {
    return (
      <>
        <ImpressionsLayers
          impressionsByLocation={impressionsByLocation}
          visibility={
            isImpressionsLayerEnabled && hasImpressionsByLocation
              ? LayerVisibility.visible
              : LayerVisibility.none
          }
        >
          <ImpressionsPopup offset={[0, -10]} />
        </ImpressionsLayers>
        <TopicsLayers
          topicsByLocation={topicsByLocation}
          visibility={
            isTopicsLayerEnabled && hasTopicsByLocation
              ? LayerVisibility.visible
              : LayerVisibility.none
          }
        >
          <TopicsPopup offset={[0, -10]} />
        </TopicsLayers>
      </>
    );
  }, [
    ImpressionsPopup,
    TopicsPopup,
    hasImpressionsByLocation,
    hasTopicsByLocation,
    impressionsByLocation,
    isImpressionsLayerEnabled,
    isTopicsLayerEnabled,
    topicsByLocation,
  ]);

  return (
    <Map
      layersIds={[
        topicsClusterLayerId,
        topicsPointsLayerId,
        impressionsClusterLayerId,
        impressionsPointsLayerId,
        impressionsPointsLayerId,
      ]}
      renderLegend={renderLegend}
      renderSources={renderSources}
      onClick={handleClick}
      onMouseMove={handleMouseMove}
      isLoading={isLoadingData}
      height={`${height}px`}
      mapRef={mapRef}
      isGlobe={isGlobe}
      initialBounds={
        hasImpressionsByLocation || hasTopicsByLocation
          ? initialBounds
          : undefined
      }
    />
  );
};

export { ImpressionsAndTopicsMap };
