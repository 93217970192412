import { Box, HStack, Portal, VStack } from "@chakra-ui/react";
import {
  Node,
  NodeDefinition,
  NodeValue,
  OnCheckNode,
  OnExpandNode,
} from "@intentsify/types";
import { isPopulatedArray } from "@intentsify/utils";
import isEqual from "lodash/isEqual";
import noop from "lodash/noop";
import { ReactNode, useRef, useState } from "react";
import { Virtuoso } from "react-virtuoso";
import useConstructor from "shared/hooks/useConstructor";
import usePrevious from "shared/hooks/usePrevious";
import { useTrackSearched } from "tracking/useTrackSearched";
import { WithChildren } from "types";
import { Search } from "../Search";
import { NodeModel } from "./Node.model";
import { CheckModel } from "./Tree.const";
import { ExpandAll } from "./components/ExpandAll";
import { Selected } from "./components/Selected";
import { TreeNode } from "./components/TreeNode";

export type TreeProps = WithChildren<{
  nodes: Node[];
  subject: string;
  size?: "md" | "sm";
  checkModel?: CheckModel.LEAF | CheckModel.ALL;
  checked: NodeValue[];
  disabled?: boolean;
  expandDisabled?: boolean;
  expandOnClick?: boolean;
  expanded: NodeValue[];
  showSelected?: boolean;
  showSearch?: boolean;
  searchWidth?: string;
  searchPlaceholder?: string;
  onlyLeafCheckboxes?: boolean;
  optimisticToggle?: boolean;
  showExpandAll?: boolean;
  showNodeTitle?: boolean;
  nameAsArray?: boolean;
  onCheck?: (checked: Array<NodeValue>, node?: NodeDefinition) => void;
  onClick?: (node: NodeDefinition) => void;
  onExpand?: (expanded: Array<NodeValue>, node?: NodeDefinition) => void;
  onReset?: () => void;
  focusOnRender?: boolean;
  searchAsYouType?: boolean;
}>;

/*  Heavily inspired by:
 *  https://github.com/jakezatecky/react-checkbox-tree
 */

const Tree = ({
  nodes,
  subject,
  size = "md",
  checkModel = CheckModel.LEAF,
  checked = [],
  disabled = false,
  expandDisabled = false,
  expandOnClick = false,
  expanded = [],
  showSelected = true,
  showSearch = true,
  searchWidth = "100%",
  searchPlaceholder,
  onlyLeafCheckboxes = false,
  optimisticToggle = true,
  showExpandAll = false,
  showNodeTitle = false,
  onCheck = noop,
  onClick = noop,
  onExpand = noop,
  onReset,
  focusOnRender = false,
  searchAsYouType = false,
}: TreeProps) => {
  const treeProps = {
    nodes,
    checkModel,
    checked,
    disabled,
    expandDisabled,
    expandOnClick,
    expanded,
    subject,
    onlyLeafCheckboxes,
    optimisticToggle,
    showExpandAll,
    showNodeTitle,
    onCheck,
    onClick,
    onExpand,
  };

  const [model, setModel] = useState<NodeModel>();
  const [nodesFiltered, setNodesFiltered] = useState(nodes);
  const prevProps = usePrevious(treeProps);
  const searchRef = useRef<HTMLDivElement>(null);

  useConstructor(() => {
    const model = new NodeModel(treeProps);

    model.flattenNodes(nodes);

    model.deserializeLists({
      checked,
      expanded,
    });

    setModel(model);
  });

  model?.setProps(treeProps);

  // Since flattening nodes is an expensive task, only update when there is a node change
  if (!isEqual(prevProps?.nodes, nodes) || prevProps?.disabled !== disabled) {
    model?.reset();
    model?.flattenNodes(nodes);
  }

  model?.flattenNodes(nodes);
  model?.deserializeLists({
    checked: checked,
    expanded: expanded,
  });

  const onNodeCheck = (nodeInfo: OnCheckNode) => {
    if (model) {
      const modelClone = model.clone();
      const node = modelClone.getNode(nodeInfo.value);

      modelClone.toggleChecked(nodeInfo, nodeInfo.checked, checkModel);

      treeProps.onCheck(modelClone.serializeList("checked"), {
        ...node,
        ...nodeInfo,
      });

      setModel(modelClone);
    }
  };

  const onNodeExpand = (nodeInfo: OnExpandNode) => {
    if (model) {
      const modelClone = model.clone();
      const node = modelClone.getNode(nodeInfo.value);

      modelClone.toggleNode(nodeInfo.value, "expanded", nodeInfo.expanded);
      treeProps.onExpand(modelClone.serializeList("expanded"), {
        ...node,
        ...nodeInfo,
      });
    }
  };

  const onExpandAll = () => {
    expandAllNodes();
  };

  const onCollapseAll = () => {
    expandAllNodes(false);
  };

  const expandAllNodes = (expand = true) => {
    model &&
      treeProps.onExpand(
        model.clone().expandAllNodes(expand).serializeList("expanded")
      );
  };

  const determineShallowCheckState = (node: NodeDefinition | Node) => {
    const flatNode = model?.getNode(node.value);

    if (flatNode?.isLeaf) {
      return flatNode.checked ? 1 : 0;
    }

    if (isEveryChildChecked(node)) {
      return 1;
    }

    if (isSomeChildChecked(node)) {
      return 2;
    }

    return 0;
  };

  const isEveryChildChecked = (node: NodeDefinition | Node) => {
    return node.children?.every(
      (child) => model?.getNode(child.value)?.checkState === 1
    );
  };

  const isSomeChildChecked = (node: NodeDefinition | Node) => {
    return node.children?.some(
      (child) => model && model.getNode(child.value)?.checkState > 0
    );
  };

  const renderTreeNodes = (
    flatNodeList: ReactNode[],
    nodes?: NodeDefinition[] | Node[]
  ) => {
    if (!model || !isPopulatedArray(nodes)) {
      return [];
    }

    let childNodes: ReactNode[] = [];

    nodes.forEach((node: NodeDefinition | Node) => {
      const key = node.value;
      const flatNode = model.getNode(node.value);
      const showCheckbox = onlyLeafCheckboxes ? flatNode?.isLeaf : false;

      const expanded = model.getNode(key).expanded;

      // Determine the check state after all children check states have been determined
      // This is done during rendering as to avoid an additional loop during the
      // deserialization of the `checked` property
      flatNode.checkState = determineShallowCheckState(node);

      if (node.children) {
        renderTreeNodes(childNodes, node.children);
      }

      flatNode.checkState = determineShallowCheckState(node);

      flatNodeList.push(
        <TreeNode
          key={key}
          checked={flatNode.checkState}
          disabled={flatNode.disabled}
          expandOnClick={expandOnClick}
          expanded={flatNode.expanded}
          label={node.label}
          optimisticToggle={optimisticToggle}
          isLeaf={flatNode.isLeaf}
          isParent={flatNode.isParent}
          showCheckbox={showCheckbox}
          treeDepth={flatNode.treeDepth}
          value={node.value}
          onCheck={onNodeCheck}
          onExpand={onNodeExpand}
          title={node.title}
        />
      );

      if (expanded && childNodes) {
        flatNodeList.push(...childNodes);
      }

      childNodes = [];
    });

    return flatNodeList;
  };

  const filterTree = (filterText: string) => {
    // Reset nodes back to unfiltered state
    if (!filterText) {
      setNodesFiltered(nodes);
      expandAllNodes(false);
      return;
    }

    const nodesFiltered = nodes.reduce(filterNodes(filterText), []);

    setNodesFiltered(nodesFiltered);

    if (filterText.length > 0) {
      expandAllNodes(true);
    }
  };

  const filterNodes =
    (filterText: string) => (filtered: Node[], node: Node) => {
      const children = (node.children || []).reduce(
        filterNodes(filterText),
        []
      );

      const match =
        node.label.toLocaleLowerCase().indexOf(filterText.toLocaleLowerCase()) >
          -1 ||
        node.value
          .toString()
          .toLocaleLowerCase()
          .indexOf(filterText.toLocaleLowerCase()) > -1;

      if (match || children.length) {
        if (match) {
          filtered.push({ ...node });
        } else {
          filtered.push({ ...node, children });
        }
      }

      return filtered;
    };

  const flatNodes = renderTreeNodes([], nodesFiltered);
  const trackSearched = useTrackSearched();

  return (
    <HStack display="flex" align="start" spacing={6} w="100%">
      <VStack flex="1" h="380px" overflowY="auto" p="1px">
        {showSearch && (
          <>
            <Portal containerRef={searchRef}>
              <Search
                placeholder={searchPlaceholder}
                focusOnRender={focusOnRender}
                mb={0}
                mr={0}
                size={size}
                w={searchWidth}
                iconButtonVariant="ghost"
                {...(searchAsYouType
                  ? { onChange: filterTree }
                  : {
                      onSearch: (v) => {
                        filterTree(v);
                        trackSearched({
                          term: v,
                          collocation: "Checkbox tree search",
                        });
                      },
                    })}
              />
            </Portal>

            <Box w="100%" ref={searchRef} />
          </>
        )}
        <Box w="100%" display="flex" flexDirection="column" overflowY="auto">
          <Virtuoso
            style={{ height: "400px" }}
            totalCount={flatNodes.length}
            itemContent={(index) => <Box>{flatNodes[index]}</Box>}
          />
        </Box>
      </VStack>

      {model && showSelected && (
        <Box flex="1.75" mb={2}>
          <Selected
            model={model}
            checked={checked}
            subject={subject}
            onNodeCheck={onNodeCheck}
            onReset={onReset}
          />
        </Box>
      )}

      {showExpandAll && (
        <ExpandAll onExpandAll={onExpandAll} onCollapseAll={onCollapseAll} />
      )}
    </HStack>
  );
};
export { Tree };
