import { SignalSelectionSource } from "@intentsify/types";
import { WindowedCheckbox } from "components";
import { useCallback, useMemo } from "react";
import { Item } from "types";
import { SelectionMode } from "types/Keyword";
import {
  useKeywordsAndTopicsQueries,
  useKeywordsAndTopicsState,
} from "../../../KeywordsAndTopics.hooks";

const useSortItemsByLabel = (items: Item[]) =>
  useMemo(() => items.sort((a, b) => a.label.localeCompare(b.label)), [items]);

export const ManualSelectionKeywords = () => {
  const { state, actions } = useKeywordsAndTopicsState();
  const { keywords } = useKeywordsAndTopicsQueries();
  const sortedKeywordsData = useSortItemsByLabel(keywords.data);

  const onCheck = useCallback(
    (item: Item) => {
      const alreadySelected = state.selectedKeywords.find(
        (k) =>
          k.label === item.label.toLowerCase() &&
          k.metaData?.source === SignalSelectionSource.Manual
      );

      actions.setSelectedKeywords(
        [
          {
            label: item.label.toLowerCase(),
            value: Number(item.value),
            metaData: { source: SignalSelectionSource.Manual },
          },
        ],
        alreadySelected ? SelectionMode.UNSELECT : SelectionMode.SELECT
      );

      return;
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [actions.setSelectedKeywords, state.selectedKeywords]
  );

  return (
    <WindowedCheckbox
      isLoading={keywords.isLoading}
      subject="keywords"
      data={sortedKeywordsData}
      checkedItems={state.selectedKeywords.filter(
        (k) => k.metaData?.source === SignalSelectionSource.Manual
      )}
      onCheck={onCheck}
      onLoadMore={keywords.onLoadMore}
      onSearch={(value) => keywords.onSearch(value)}
      onReset={() =>
        actions.setSelectedKeywords(
          state.selectedKeywords.filter(
            (k) => k.metaData?.source === SignalSelectionSource.Manual
          ),
          SelectionMode.UNSELECT
        )
      }
    />
  );
};
