import { useMemo } from "react";
import {
  DataPackageFancyName,
  DataPackageNames,
  Option,
} from "@intentsify/types";
import { Select } from "components";
import { useDataPackages } from "queries/permissions/useDataPackages";

type DataPackageSelectorProps = {
  value?: number;
  onChange: (selection: Option | null) => void;
};

export const DataPackageSelector = ({
  value,
  onChange,
}: DataPackageSelectorProps) => {
  const dataPackages = useDataPackages();

  const options = useMemo(
    () =>
      dataPackages.data?.map((i) => ({
        value: i.id,
        label: DataPackageFancyName[i.name as DataPackageNames],
      })) ?? [],
    [dataPackages.data]
  );
  const selectedValue = useMemo(
    () => options.find((option) => option.value === value),
    [options, value]
  );

  return (
    <Select
      isClearable
      isMulti={false}
      isLoading={dataPackages.isLoading}
      placeholder="Select data package"
      options={options}
      value={selectedValue}
      onChange={onChange}
    />
  );
};
