import { useToast } from "@chakra-ui/react";
import { Company } from "@intentsify/types";
import { useCallback } from "react";
import { useQueryClient } from "@tanstack/react-query";
import type { CampaignsDiff } from "../useCampaignsDiff";
import { useAssignCampaigns } from "./useAssignCampaigns";
import { useUnAssignCampaigns } from "./useUnAssignCampaigns";

export const useSaveCampaigns = (
  companyId: Company["companyId"],
  integrationId: number,
  campaignsDiff: CampaignsDiff
) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const { mutateAsync: assignCampaigns, isLoading: isAssigningCampaigns } =
    useAssignCampaigns(companyId, integrationId);
  const { mutateAsync: unAssignCampaigns, isLoading: isUnAssigningCampaigns } =
    useUnAssignCampaigns(companyId, integrationId);

  const isLoading = isAssigningCampaigns || isUnAssigningCampaigns;

  return [
    isLoading,
    useCallback(async () => {
      const mutationPromises: Promise<unknown>[] = [];

      if (campaignsDiff.added.length > 0) {
        mutationPromises.push(assignCampaigns(campaignsDiff.added));
      }

      if (campaignsDiff.deleted.length > 0) {
        mutationPromises.push(unAssignCampaigns(campaignsDiff.deleted));
      }

      await Promise.all(mutationPromises);

      toast({
        title: "Campaigns saved successfully",
        status: "success",
      });

      // todo add optimistic updates
      queryClient.invalidateQueries({
        queryKey: ["campaignsAssignedToIntegrations", companyId],
      });
    }, [
      assignCampaigns,
      campaignsDiff.added,
      campaignsDiff.deleted,
      companyId,
      queryClient,
      toast,
      unAssignCampaigns,
    ]),
  ] as const;
};
