import { Box } from "@chakra-ui/react";
import { Button, ButtonVariant, IconButton, Tooltip } from "components";
import { ActionItem as ActionItemType } from "../../types/Actions";

type ActionItemProps = ActionItemType & { index: number };

const ActionItem = (props: ActionItemProps) => {
  const {
    label,
    icon,
    onClick,
    dividerBefore,
    index,
    isLoading,
    isDisabled,
    isDisabledTooltip,
    size = "sm",
    tootlipPlacement = "bottom",
    variant,
    iconOnly = true,
  } = props;
  const notFirst = index !== 0;

  return (
    <>
      {notFirst && dividerBefore && <Box />}
      <Tooltip
        aria-label="More actions"
        shouldWrapChildren
        label={isDisabled ? isDisabledTooltip : label}
        placement={tootlipPlacement}
        isDisabled={iconOnly || (!iconOnly && !isDisabled)}
      >
        {iconOnly ? (
          <IconButton
            tooltipPlacement={tootlipPlacement}
            tooltip={isDisabled ? `${isDisabledTooltip || ""}` : label}
            icon={icon}
            size={size}
            onClick={onClick}
            isLoading={isLoading}
            isDisabled={isDisabled}
            variant={variant}
          />
        ) : (
          <Button
            leftIcon={icon}
            variant={variant as ButtonVariant}
            isLoading={isLoading}
            isDisabled={isDisabled}
            onClick={onClick}
          >
            {label}
          </Button>
        )}
      </Tooltip>
    </>
  );
};

export { ActionItem };
