import { Flex } from "@chakra-ui/layout";
import { formatDateRange } from "@intentsify/utils";
import { ColumnDef } from "@tanstack/react-table";
import { TrendingTopicsWithTimeseriesResponse } from "../../TrendingTopics.types";
import { CellWithChange } from "components";
import { TimeseriesItem, TimeseriesRange } from "types";

const getSeriesColumns = (
  seriesKey: string,
  previousKey: string | undefined,
  timeseriesRange: TimeseriesRange
): ColumnDef<TimeseriesItem> => {
  const formattedSeriesHeader = formatDateRange(timeseriesRange);

  return {
    header: formattedSeriesHeader,
    columns: [
      {
        header: "Signals",
        cell: ({ row }) => {
          const data = row.original?.timeseries.find(
            (s) => s.isoDate === seriesKey
          );

          const previousSeries = previousKey
            ? row.original?.timeseries.find((s) => s.isoDate === previousKey)
            : undefined;

          return (
            <CellWithChange
              value={data?.data.value}
              previousValue={previousSeries?.data.value}
            />
          );
        },
      },
    ],
  };
};

export const getMostActiveTopicsColumns = (
  timeseries: TrendingTopicsWithTimeseriesResponse["timeseries"]
) => {
  const seriesColumns = timeseries.map((series, i) =>
    getSeriesColumns(series.start, timeseries[i - 1]?.start, series)
  );

  const columns: Array<ColumnDef<TimeseriesItem>> = [
    {
      header: "Keyword",
      enableSorting: false,
      cell: ({ row }) => {
        return (
          <Flex whiteSpace="nowrap" height="35px" alignItems="center">
            {row.original?.label}
          </Flex>
        );
      },
    },
    ...seriesColumns,
  ];

  return columns;
};
