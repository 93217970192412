import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  chakra,
  useToast,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { CreatePersonalInfoRequestDTO } from "@intentsify/dto";
import { Turnstile } from "@marsidev/react-turnstile";
import { useMutation } from "@tanstack/react-query";
import { Endpoints, apiService } from "api";
import { Button, Select, Tooltip } from "components";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  OptOutFormValues,
  defaultOptOutFormValues,
  optOutReasonOptions,
} from "./FormValues";

export function OptOutForm() {
  const createPersonalInfoRequest = useCreatePersonalInfoRequest();
  const [hasPassedChallenge, setHasPassedChallenge] = useState(false);
  const form = useForm<OptOutFormValues>({
    resolver: zodResolver(OptOutFormValues),
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: defaultOptOutFormValues,
  });

  const challengeTooltip = hasPassedChallenge
    ? ""
    : "In order to submit the form, please click the checkbox on the left and follow the instructions";

  return (
    <chakra.form
      display="flex"
      flexDir="column"
      gap="4"
      onSubmit={(e) => {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        const token = formData.get("cf-turnstile-response");
        if (!token || typeof token !== "string") {
          throw new Error("No token found in form data");
        }

        form.handleSubmit((data) => {
          createPersonalInfoRequest.mutate({
            ...data,
            cfTurnstileToken: token,
          });
        })(e);
      }}
    >
      <FormControl
        isInvalid={Boolean(form.formState.errors.email?.message)}
        isRequired
      >
        <FormLabel>Email</FormLabel>
        <Input
          {...form.register("email")}
          type="email"
          required
          placeholder="john.doe@example.com"
        />
        <FormErrorMessage>
          {form.formState.errors.email?.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl
        isInvalid={Boolean(form.formState.errors.firstName?.message)}
        isRequired
      >
        <FormLabel>First name</FormLabel>
        <Input
          {...form.register("firstName")}
          type="text"
          required
          placeholder="John"
        />
        <FormErrorMessage>
          {form.formState.errors.firstName?.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl
        isInvalid={Boolean(form.formState.errors.lastName?.message)}
        isRequired
      >
        <FormLabel>Last name</FormLabel>
        <Input
          {...form.register("lastName")}
          type="text"
          required
          placeholder="Doe"
        />
        <FormErrorMessage>
          {form.formState.errors.lastName?.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl
        isInvalid={Boolean(form.formState.errors.reason?.message)}
        isRequired
      >
        <FormLabel>Opt Out preference</FormLabel>
        <Controller
          name="reason"
          control={form.control}
          render={({ field }) => (
            <Select
              size="md"
              isMulti={false}
              placeholder="Select a reason"
              options={optOutReasonOptions}
              value={optOutReasonOptions.find((c) => c.value === field.value)}
              onChange={(option) => {
                if (!option) {
                  form.setValue("reason", "unspecified");
                  return;
                }

                form.setValue("reason", option.value);
              }}
            />
          )}
        />
        <FormErrorMessage>
          {form.formState.errors.reason?.message}
        </FormErrorMessage>
      </FormControl>

      <Flex flexDir="column" gap="8" mt="4">
        <Text
          lineHeight="1.35"
          fontSize="sm"
          color="blackAlpha.600"
          _dark={{ color: "whiteAlpha.600" }}
          style={
            {
              textWrap: "pretty",
              // Text-wrap is supported in all major browsers
              // but the types are not updated to reflect that
            } as React.CSSProperties
          }
        >
          By clicking on the "Submit" button below, you confirm that the
          information provided holds truth and accuracy, granting Intentsify LLC
          the right to contact you to verify your identity and process your
          request.
        </Text>

        <Flex justifyContent="space-between">
          <Turnstile
            siteKey={String(process.env.VITE_CF_TURNSTILE_SITE_KEY)}
            onSuccess={() => setHasPassedChallenge(true)}
          />
          <Flex alignSelf="end">
            <Tooltip aria-label={challengeTooltip} label={challengeTooltip}>
              <Button
                isLoading={createPersonalInfoRequest.isLoading}
                isDisabled={!hasPassedChallenge}
                variant="primary-teal"
                type="submit"
                px="5"
                py="5"
              >
                Submit
              </Button>
            </Tooltip>
          </Flex>
        </Flex>
      </Flex>
    </chakra.form>
  );
}

const useCreatePersonalInfoRequest = () => {
  const toast = useToast();

  return useMutation({
    mutationFn: async (params: CreatePersonalInfoRequestDTO) => {
      await apiService.post(Endpoints.PersonalInfo.Create, params);
    },
    onSuccess: () => {
      toast({
        title: `Your request has been sent.`,
        status: "success",
      });
    },
  });
};
