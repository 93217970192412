import { EditCompanyScreen } from "types";
import { createScreen } from "utils/createScreen";

const EditCompanyScreenDefinition = createScreen({
  name: EditCompanyScreen,
  path: "/admin/companies/edit/:id",
  routePath: ["/edit/:id"],
  accessPath: "company.edit",
});

export { EditCompanyScreenDefinition };
