import { UseToastOptions } from "@chakra-ui/react";
import { HttpStatus, isCustomApiError } from "types";

export const appErrors: { [key: string]: string } = {
  SomethingWentWrong: "Something went wrong.",
  "409UserAlreadyExists": "User already exists.",
  "409CompanyAlreadyExists": "Company already exists.",
  "409KeywordAlreadyExists": "Keyword with passed value already exists.",
  "409DomainAlreadyExists": "Domain already exists.",
  "409ReportAlreadyRequested":
    "It looks like you've already requested a PDF export for this campaign. Please wait until processing is complete and the export is ready.",
  "400ResetPasswordTokenExpired":
    "Your password reset link has expired. Please request another link by clicking “Reset your password” again on the login page to get the valid password reset link.",
  "404CampaignDoesNotExist": "Campaign does not exist.",
  "500InternalServerError": "Internal server error.",
  "400NoTopicsInCsv": "No topics in the uploaded CSV.",
  "400BadRequest": "Something went wrong.",
  "400TooManyFirmoFilters":
    "Too many firmographic filter values were selected. We support up to 450 values at the moment",
  "404NotFound": "Not found.",
  "404CompanyDoesNotExist": "Company does not exist.",
  "500PermissionMethodDoesNotExist": "Permission method does not exist",
  "401UserDoesNotHavePermissionsSet": "User does not have the permission set.",
  "404ProgrammaticDomainsFileDoesNotExist":
    "Programmatic segments file does not exist.",
  "400InvalidKeywords": "Invalid keywords.",
  "400NoKeywordsInCsv": "No keywords in the uploaded CSV.",
  "500RoleDoesNotHaveDefaultModules": "Role does not have defalt modules.",
  "500CouldNotAssignDefaultModules": "Could not assign default modules.",
  "404UserDoesNotExist": "User does not exist.",
  "400ResetPasswordTokenNotFound":
    "Your password was already set. Please reset password again if you want to set up a new password.",
  "400ResetPasswordTokenInvalid":
    "Invalid reset password token. Please request another by clicking “Reset your password” on the login page to get the valid password reset link.",
  "409HiddenCampaign":
    "Hidden campaigns cannot be edited. Please, make the campaign visible to edit selected fields.",
  "422FavoriteCampaignLimit":
    "Max limit of favorite campaigns has been reached.",
  "409TALAlreadyProcessing":
    "Account modifications are not possible during processing.",
};

export const getAppError = (message: string) => {
  return appErrors[message] || "Something went wrong";
};

export const getToastSettingsFromHttpStatusCode = (
  statusCode: HttpStatus
): Partial<UseToastOptions> => {
  switch (true) {
    case statusCode >= 400 && statusCode < 500:
      return { status: "warning", duration: 15000, isClosable: true };
    case statusCode >= 500:
      return { status: "error", isClosable: true };
    default:
      return { status: "error", isClosable: true };
  }
};

export const shouldRetryUnlessStatus =
  (...statuses: HttpStatus[]) =>
  (failureCount: number, error: unknown) =>
    isCustomApiError(error) &&
    !statuses.includes(error.response.statusCode) &&
    failureCount < 2;
