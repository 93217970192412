import { Box } from "@chakra-ui/react";
import { useGetHasAccessTo } from "@intentsify/authorization/dist/react";
import {
  GenericUserJobType,
  RoomEventType,
  SocketRoom,
} from "@intentsify/types";
import { isDefined } from "@intentsify/utils";
import { useQueryClient } from "@tanstack/react-query";
import { ButtonGroup, ViewContainer } from "components";
import { useSearchParams } from "react-router-dom";
import { useUser } from "store/store.hooks";
import { useScreen } from "utils";
import { useRoomEvent } from "webSocket";
import { TalPreparationToolsDefinition } from "./TalPreparationTools.definition";
import { BusinessDataAppend } from "./components/BusinessDataAppend/BusinessDataAppend";
import { CompanyNameToDomain } from "./components/CompanyNameToDomain/CompanyNameToDomain";
import { SmarTAL } from "./components/SmarTAL/SmarTAL";

const useTabs = () => {
  const hasAccessTo = useGetHasAccessTo();

  const tabs = [
    hasAccessTo("talPreparationTools.companyNameToDomain")
      ? {
          label: "Company Name to Domain",
          value: GenericUserJobType.COMPANY_NAME_TO_DOMAIN,
        }
      : undefined,
    hasAccessTo("talPreparationTools.businessDataAppend")
      ? {
          label: "Business Data Append",
          value: GenericUserJobType.BUSINESS_DATA_APPEND,
        }
      : undefined,
    hasAccessTo("talPreparationTools.smarTAL")
      ? {
          label: "SmartTAL",
          value: GenericUserJobType.SMARTAL,
        }
      : undefined,
  ].filter(isDefined);

  return tabs;
};

const TAB_PARAM = "tab";

export const TalPreparationTools = () => {
  useScreen(TalPreparationToolsDefinition);
  const queryClient = useQueryClient();
  const user = useUser();
  const tabs = useTabs();
  const [searchParams, setSearchParams] = useSearchParams();
  const tabParam = searchParams.get(TAB_PARAM) ?? "";
  const selectedTab = Object.values(GenericUserJobType).includes(
    tabParam as GenericUserJobType
  )
    ? tabParam
    : tabs[0]?.value ?? (undefined as GenericUserJobType | undefined);

  useRoomEvent(
    `${SocketRoom.TAL_TOOLS}_${user?.userId || ""}`,
    RoomEventType.TAL_TOOLS_PROCESSING_UPDATE,
    (event) => {
      queryClient.invalidateQueries({
        queryKey: ["talTools", event.data.file.type],
        exact: false,
      });
    }
  );

  return (
    <ViewContainer>
      <ButtonGroup
        selectedValue={selectedTab}
        setSelectedValue={(value) => setSearchParams({ [TAB_PARAM]: value })}
        items={tabs}
      />
      <Box>
        {selectedTab === GenericUserJobType.BUSINESS_DATA_APPEND && (
          <BusinessDataAppend />
        )}
        {selectedTab === GenericUserJobType.COMPANY_NAME_TO_DOMAIN && (
          <CompanyNameToDomain />
        )}
        {selectedTab === GenericUserJobType.SMARTAL && <SmarTAL />}
      </Box>
    </ViewContainer>
  );
};
