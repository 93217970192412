import { useToast, UseToastOptions } from "@chakra-ui/react";
import { BusinessUnit } from "@intentsify/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import { Actions, EmptyCell } from "components";
import { DateTime } from "luxon";
import { useState } from "react";
import { FiEdit3, FiTrash2 } from "react-icons/fi";
import { useNavigate } from "react-router";
import { ActionItems, HttpStatus, isCustomApiError } from "types";
import {
  CustomApiError,
  formatApiError,
  getToastSettingsFromHttpStatusCode,
  handleApiError,
} from "utils";
import { removeBusinessUnit } from "./BusinessUnits.requests";
import { EditBusinessUnit } from "./components";

interface ActionsCellProps {
  companyId: number;
  unitId: number;
  unitName: string;
}

const ActionsCell = ({ companyId, unitId, unitName }: ActionsCellProps) => {
  const [isEditBusinessUnitOpen, setIsEditBusinessUnitOpen] = useState(false);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const toast = useToast();

  const { mutate: remove, isLoading: isLoadingRemove } = useMutation(
    removeBusinessUnit(companyId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["businessUnits"] });
      },

      onError: (err) => {
        if (isCustomApiError(err)) {
          if (err.response.internalCode === "409BusinessUnitInUse") {
            const toastConfig: Partial<UseToastOptions> =
              getToastSettingsFromHttpStatusCode(err.response.statusCode);

            toast({
              title: formatApiError(
                // If we pass the original err it will be interpreted using internalCode
                // and the error messages won't be shown
                new CustomApiError("Validation error", {
                  statusCode: HttpStatus.BAD_REQUEST,
                  message: err.response.message,
                }),
                `Business unit cannot by removed because it's still assigned to some users. Please review the list below.`
              ),
              ...toastConfig,
              duration: null,
            });

            return;
          }
        }

        handleApiError(err, navigate, toast);
      },
    }
  );

  const items: ActionItems[] = [
    {
      label: "Edit Subsidiary",
      accessPath: "company.editBusinessUnit",
      icon: <FiEdit3 />,
      onClick: () => setIsEditBusinessUnitOpen(true),
    },
    {
      label: "Delete Subsidiary",
      accessPath: "company.deleteBusinessUnit",
      icon: <FiTrash2 />,
      onClick: () => remove(unitId),
      isLoading: isLoadingRemove,
    },
  ];

  return (
    <>
      <Actions items={items} />
      <EditBusinessUnit
        isOpen={isEditBusinessUnitOpen}
        onClose={() => setIsEditBusinessUnitOpen(false)}
        companyId={companyId}
        unitId={unitId}
        unitName={unitName}
      />
    </>
  );
};

export const getColumns = (): ColumnDef<BusinessUnit>[] => {
  return [
    {
      header: "Name",
      accessorKey: "displayName",
    },
    {
      header: "Created at",
      accessorKey: "createdAt",
      cell: ({ row }) => {
        return row.original?.createdAt ? (
          DateTime.fromISO(row.original.createdAt).toISODate()
        ) : (
          <EmptyCell />
        );
      },
    },
    {
      header: "Actions",
      enableSorting: false,
      cell: ({ row }) => {
        if (!row.original) {
          return <EmptyCell />;
        }

        return (
          <ActionsCell
            companyId={row.original?.companyId}
            unitId={row.original?.businessUnitId}
            unitName={row.original?.displayName}
          />
        );
      },
    },
  ];
};
