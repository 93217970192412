import { SocketErrorReason, SocketEvent } from "@intentsify/types";
import { refreshAccessToken, signOutRequest } from "api";
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Socket, io } from "socket.io-client";
import { useUser } from "store/store.hooks";

type EventSubscription = {
  uuid: string;
  event: SocketEvent;
  onMessage: (arg: any) => void;
};

type QueuedEvent = { type: SocketEvent; data: unknown };

const useEventsQueue = (socket: Socket | undefined) => {
  const [queue, setQueue] = useState<QueuedEvent[]>([]);

  const [queueReady, setQueueReady] = useState(false);

  useEffect(() => {
    if (socket?.connected) {
      setQueueReady(true);
    }
  }, [socket?.connected]);

  useEffect(() => {
    const event = queue[0];

    if (queueReady && socket?.connected && event) {
      setQueueReady(false);

      socket.emit(event.type, event.data, () => {
        setQueue((events) => {
          return events.slice(1, Infinity);
        });
        setQueueReady(true);
      });
    }
  }, [queue, queueReady, socket, socket?.connected]);

  const emitQueuedEvent = useCallback(
    (event: QueuedEvent) => {
      setQueue((prev) => [...prev, event]);
    },
    [setQueue]
  );

  return {
    emitQueuedEvent,
  };
};

export const SocketContext = createContext<{
  socket?: Socket;
  connected: boolean;
  setEventSubscriptions?: React.Dispatch<
    React.SetStateAction<EventSubscription[]>
  >;
  emitQueuedEvent?: (event: QueuedEvent) => void;
}>({
  socket: undefined,
  connected: false,
  setEventSubscriptions: undefined,
  emitQueuedEvent: undefined,
});

export const SocketContextProvider = ({ children }: PropsWithChildren) => {
  const socket = useRef<Socket>();
  const eventSubscriptionsRef = useRef<EventSubscription[]>();

  const [connected, setConnected] = useState(false);
  const [eventSubscriptions, setEventSubscriptions] = useState<
    EventSubscription[]
  >([]);

  const { emitQueuedEvent } = useEventsQueue(socket.current);

  /**
   * Store `eventSubscriptions` as ref so `onAny()` callback always has the latest object.
   */
  useEffect(() => {
    eventSubscriptionsRef.current = eventSubscriptions;
  }, [eventSubscriptions]);

  const user = useUser();

  useEffect(() => {
    if (!process.env.VITE_WS_URL) {
      throw Error("VITE_WS_URL env variable must be specified");
    }

    if (!socket.current && user) {
      socket.current = io(process.env.VITE_WS_URL, {
        secure: true,
        withCredentials: true,
        transports: ["websocket"],
        reconnection: true,
        reconnectionAttempts: 10,
      });

      socket.current.on("connect", () => {
        console.debug("ws connected");
        setConnected(true);
      });

      socket.current.on("connect_error", (error: Error) => {
        console.debug("ws connect_error, message:", error.message);
        setConnected(false);
      });

      socket.current.on("disconnect", (reason: string) => {
        console.debug(`ws disconencted: ${reason}`);
        setConnected(false);
      });

      socket.current.io.on("reconnect", () => {
        console.debug("ws reconnected");
        setConnected(true);
      });

      socket.current.io.on("reconnect_error", (error) => {
        console.debug("ws reconnect_error, message:", error.message);
      });

      socket.current.io.on("reconnect_failed", () => {
        console.debug("ws reconnect_failed");
      });

      socket.current.io.on("reconnect_attempt", () => {
        console.debug("ws reconnect attempt");
      });

      socket.current.on("error", (error) => {
        if (error.cause === SocketErrorReason.UNAUTHORIZED) {
          try {
            const refresh = async () => {
              await refreshAccessToken();
              socket.current?.connect();
            };

            refresh();
          } catch {
            void signOutRequest();
          }
        } else {
          console.error(error);
        }
      });

      socket.current.onAny((event, data) => {
        eventSubscriptionsRef.current?.forEach((sub) => {
          if (sub.event === event) {
            sub.onMessage(data);
          }
        });
      });
    }

    return () => {
      if (socket.current) {
        socket.current.disconnect();
        socket.current = undefined;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Boolean(user)]);

  return (
    <SocketContext.Provider
      value={{
        socket: socket.current,
        connected,
        setEventSubscriptions,
        emitQueuedEvent,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};
