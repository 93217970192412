import { DeliveredLeadsScreen } from "../../../../types";
import { createScreen } from "utils/createScreen";

const CampaignsLeadsScreenDefinition = createScreen({
  name: DeliveredLeadsScreen,
  path: "/campaigns/:campaignId/leads",
  routePath: ["/:campaignId/leads"],
  accessPath: "campaign.leadGeneration",
});

export { CampaignsLeadsScreenDefinition };
